import ScoringConfigurationList from './ScoringConfigurationList';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  fetchScoringConfigurationList,
  createScoringConfiguration,
  openScoringConfigurationFormModal,
  closeScoringConfigurationFormModal,
  updateScoringConfiguration,
  deleteScoringConfiguration
} from '../../../actions/scoring_configuration_actions';

function mapStateToProps(state) {
  return {
    configs: state.scoringConfigurations.configs,
    meta: state.scoringConfigurations.meta,
    loading: state.requestState.requestScoringConfigurationListSend,
    canCreateScoringExclusions: state.authentication.currentUser.canCreateScoringExclusions,
    canUpdateScoringExclusions: state.authentication.currentUser.canUpdateScoringExclusions,
    canDestroyScoringExclusions: state.authentication.currentUser.canDestroyScoringExclusions,
    categories: state.globalSetting.orgData.categories,
    geographicLocations: state.globalSetting.orgData.geographic_locations,
    suppliers: state.globalSetting.orgData.suppliers,
    modalSubmitLoading: (
      state.requestState.requestScoringConfigurationCreateSend ||
      state.requestState.requestScoringConfigurationUpdateSend
    ),
    modalDestroyLoading: state.requestState.requestScoringConfigurationDeleteSend,
    formModalIsOpen: state.scoringConfigurations.formModalIsOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSchedulePointOfSaleConfigurationList: bindActionCreators(fetchScoringConfigurationList, dispatch),
    createConfig: bindActionCreators(createScoringConfiguration, dispatch),
    openFormModal: bindActionCreators(openScoringConfigurationFormModal, dispatch),
    closeFormModal: bindActionCreators(closeScoringConfigurationFormModal, dispatch),
    updateConfig: bindActionCreators(updateScoringConfiguration, dispatch),
    deleteConfig: bindActionCreators(deleteScoringConfiguration, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ScoringConfigurationList);