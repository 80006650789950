export const getYears = () => {
  const yearOptions = [];

  for (let i = 0; i < 10; i++) {
    yearOptions.push(new Date().getFullYear() - i);
  }

  const years = [];
  for (const valor of yearOptions) {
    years.push({
      value: valor,
      title: valor
    });
  }
  return years;
};

export const getSuppliers = list => {
  const supplier = [];
  if (list) {
    list.map(s => {
      const data = {
        value: s.id,
        title: s.name
      };
      supplier.push(data);
    });
  }
  return supplier;
};

export const monthOptions = [
  { value: '1', title: 'Enero' },
  { value: '2', title: 'Febrero' },
  { value: '3', title: 'Marzo' },
  { value: '4', title: 'Abril' },
  { value: '5', title: 'Mayo' },
  { value: '6', title: 'Junio' },
  { value: '7', title: 'Julio' },
  { value: '8', title: 'Agosto' },
  { value: '9', title: 'Septiembre' },
  { value: '10', title: 'Octubre' },
  { value: '11', title: 'Noviembre' },
  { value: '12', title: 'Diciembre' }
];

export const saleType = [
  { value: 'total', title: 'TODAS' },
  { value: 'tradicional', title: 'TRADICIONAL' },
  { value: 'cardif', title: 'CARDIF' }
];
