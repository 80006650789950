import React from 'react';
import PropTypes from 'prop-types';
import _replace from 'lodash/replace';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ActivityIndicator from '../../shared/ActivityIndicator';
import TypographyStyles from '../../../styles/typography';

const image = require('../../../assets/icons/404.png');
const brilla_logo = require('../../../assets/images/logo_brilla002.png');

const styles = theme => ({
  rootBase: {
    margin: '10% 0 10% 0',
    textAlign: 'center'
    //backgroundColor: 'white !important'
  },
  loadingIndicator: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 1
  },
  imageIcon: {
    width: '50% !important'
  },
  brillaIcon: {
    width: '40% !important',
    margin: '25px'
  },
  message: {
    color: '#818A7B',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  }
});

export const Message = ({ classes, message, isLoading }) => (
  <div className={classes.rootBase}>
    {isLoading && <ActivityIndicator className={classes.loadingIndicator} />}
    <Grid container>
      <Grid item sm={6} xs={12}>
        <img className={classes.imageIcon} src={image} />
      </Grid>

      <Grid item sm={4} xs={12}>
        <div>
          <img className={classes.brillaIcon} src={brilla_logo} />
          <h2 className={classes.message}>{message}</h2>
        </div>
      </Grid>
    </Grid>
  </div>
);

Message.propTypes = {
  message: PropTypes.string.isRequired
};

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles)
)(Message);
