import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import LabeledCheckbox from './LabeledCheckbox';
import MultiSelectOptions from './MultiSelectOptions';
import MultiSelectItems from './MultiSelectItems';
import ActivityIndicator from '../../ActivityIndicator';
import { groupItems } from './group_functions';
import { removeAccents } from '../../../../helpers/utilityFunctions';

class MultiSelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allOptionsSelected: false,
      searchCriteria: '',
      selectedIDS: [],
      groupSelectedIDS: []
    };
  }

  componentDidMount() {
    this.timer = null;
    const selectedIDS = this.props.selectedItems.map(selectedItem => {
      return selectedItem.id;
    });
    this.setState({ selectedIDS });
    this.setGroupHeaderAndAllCheckboxesState(selectedIDS);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedItems !== this.props.selectedItems) {
      const selectedIDS = this.props.selectedItems.map(selectedItem => {
        return selectedItem.id;
      });
      this.setState({ selectedIDS });
    }
  }

  handleSearchInput = event => {
    clearTimeout(this.timer);
    this.setState({ searchCriteria: event.target.value });
    this.timer = setTimeout(this.filterItems, 500);
  };

  filterItems = () => {
    const selectedIDS = this.state.selectedIDS;
    let groupSelectedIDS = this.state.groupSelectedIDS;
    let allOptionsSelected;
    let allItemsInGroup;
    const items = this.props.items;
    const criteria = removeAccents(this.state.searchCriteria).toLowerCase();
    const filteredItems = items.filter(item =>
      removeAccents(item.label)
        .toLowerCase()
        .includes(criteria)
    );
    if (
      filteredItems.filter(item => selectedIDS.indexOf(item.id) === -1).length >
      0
    ) {
      allOptionsSelected = false;
      const groups = [...new Set(filteredItems.map(item => item.group))];
      groups.forEach(element => {
        allItemsInGroup = filteredItems.filter(item => item.group === element);
        if (
          allItemsInGroup.filter(item => selectedIDS.indexOf(item) === -1)
            .length > 0
        ) {
          groupSelectedIDS = groupSelectedIDS.filter(item => item !== element);
        } else {
          if (groupSelectedIDS.indexOf(element) === -1) {
            groupSelectedIDS.push(element);
          }
        }
      });
    } else {
      allOptionsSelected = true;
    }
    this.setState({ filteredItems, allOptionsSelected, groupSelectedIDS });
  };

  handleCheckBox = name => event => {
    let selectedIDS = [];
    let groupSelectedIDS = [];
    const filteredItems = this.state.filteredItems;
    const items =
      filteredItems && filteredItems.length > 0
        ? this.state.filteredItems
        : this.props.items;
    if (event.target.checked) {
      this.props.changeItems(items);
      selectedIDS = items.map(item => {
        return item.id;
      });
      groupSelectedIDS = [...new Set(items.map(item => item.group))];
    } else {
      groupSelectedIDS = [];
      this.props.changeItems([]);
    }
    this.setState({
      [name]: event.target.checked,
      selectedIDS,
      groupSelectedIDS
    });
  };

  deleteSelectedItem = selectedItem => {
    let selectedItems = this.props.selectedItems;
    let selectedIDS = this.state.selectedIDS;
    let groupSelectedIDS = this.state.groupSelectedIDS;
    selectedItems = selectedItems.filter(item => item.id !== selectedItem.id);
    selectedIDS = selectedIDS.filter(id => id !== selectedItem.id);
    groupSelectedIDS = groupSelectedIDS.filter(
      item => item !== selectedItem.group
    );
    this.props.changeItems(selectedItems);
    this.setState({ allOptionsSelected: false, selectedIDS, groupSelectedIDS });
  };

  deleteAllItems = () => {
    if (!this.props.loadingOptions) {
      this.props.changeItems([]);
      this.setState({
        allOptionsSelected: false,
        selectedIDS: [],
        groupSelectedIDS: []
      });
    }
  };

  handleCheckList = selectedItem => {
    let selectedIDS = this.state.selectedIDS;
    let selectedItems = this.props.selectedItems;
    let groupSelectedIDS = this.state.groupSelectedIDS;
    let allItemsInGroup;
    const filteredItems = this.state.filteredItems;
    const items =
      filteredItems && filteredItems.length > 0
        ? this.state.filteredItems
        : this.props.items;
    if (selectedItem.isGroup) {
      allItemsInGroup = items.filter(item => item.group === selectedItem.id);
      if (
        allItemsInGroup.filter(item => selectedIDS.indexOf(item.id) === -1)
          .length > 0
      ) {
        if (groupSelectedIDS.indexOf(selectedItem.id) === -1) {
          groupSelectedIDS.push(selectedItem.id);
        }
        selectedItems = allItemsInGroup.concat(
          selectedItems.filter(item => item.group !== selectedItem.id)
        );
      } else {
        groupSelectedIDS = groupSelectedIDS.filter(
          item => item !== selectedItem.id
        );
        selectedItems = selectedItems.filter(
          item => item.group !== selectedItem.id
        );
      }
      selectedIDS = selectedItems.map(selectedItems => {
        return selectedItems.id;
      });
    } else {
      const checked = selectedIDS.includes(selectedItem.id);
      if (checked) {
        selectedItems = selectedItems.filter(
          item => item.id !== selectedItem.id
        );
        selectedIDS = selectedIDS.filter(id => id !== selectedItem.id);
      } else {
        selectedItems.push(selectedItem);
        selectedIDS.push(selectedItem.id);
      }
    }
    this.props.changeItems(selectedItems);
    this.setGroupHeaderAndAllCheckboxesState(selectedIDS);
  };

  setGroupHeaderAndAllCheckboxesState = selectedIDS => {
    const filteredItems = this.state.filteredItems;
    let groupSelectedIDS = this.state.groupSelectedIDS;
    let allOptionsSelected;
    const items =
      filteredItems && filteredItems.length > 0
        ? this.state.filteredItems
        : this.props.items;
    const groupIDS = [...new Set(items.map(item => item.group))];
    if (items.filter(item => selectedIDS.indexOf(item.id) === -1).length > 0) {
      allOptionsSelected = false;
      groupIDS.forEach(groupId => {
        const allItemsInGroup = items.filter(item => item.group === groupId);
        if (
          allItemsInGroup.filter(item => selectedIDS.indexOf(item.id) === -1)
            .length > 0
        ) {
          groupSelectedIDS = groupSelectedIDS.filter(item => item !== groupId);
        } else {
          groupSelectedIDS.push(groupId);
        }
      });
    } else {
      allOptionsSelected = true;
    }
    this.setState({ allOptionsSelected, selectedIDS, groupSelectedIDS });
  };

  render() {
    const {
      nameSingular,
      namePlural,
      group,
      height,
      classes,
      items,
      selectedItems,
      selectAllOption,
      selectAllOptionByGroup,
      loadingOptions
    } = this.props;
    const {
      allOptionsSelected,
      groupSelectedIDS,
      searchCriteria,
      selectedIDS,
      filteredItems
    } = this.state;
    if (!selectedIDS) {
      return <ActivityIndicator />;
    }
    const textMessages = {
      searchPlaceholder: `Buscar...`,
      noItemsMessage: `Sin ${namePlural || 'items'}...`,
      noneSelectedMessage: `Ningun ${nameSingular || 'item'} seleccionado`, // `Selecciona ${namePlural||'items'} para el usuario`,
      selectedMessage: `${
        selectedItems.length > 1 ? namePlural : nameSingular
      } seleccionado${selectedItems.length > 1 ? 's' : ''}`,
      selectAllMessage: 'Todos',
      clearAllMessage: 'Quitar todos',
      disabledItemsTooltip: 'Solo puedes seleccionar 1 item de cada tipo'
    };
    return (
      <div style={height ? { height: height } : { height: 400 }}>
        <div className={classes.wrapper}>
          <div className={classes.section}>
            <div className={classes.subSection}>
              <input
                className={classes.subSection_search}
                type="text"
                value={searchCriteria}
                placeholder={textMessages.searchPlaceholder}
                onChange={this.handleSearchInput}
              />
            </div>
            {selectAllOption && (
              <div
                className={classNames(
                  classes.subSection,
                  classes.subSection_selectAll
                )}
              >
                <LabeledCheckbox
                  label={textMessages.selectAllMessage}
                  checked={allOptionsSelected}
                  handleChange={this.handleCheckBox('allOptionsSelected')}
                  allSelector
                  isGrouped={true}
                />
              </div>
            )}
            {loadingOptions ? (
              <ActivityIndicator />
            ) : filteredItems && searchCriteria ? (
              <MultiSelectOptions
                items={group ? groupItems(filteredItems) : filteredItems}
                handleChange={this.handleCheckList}
                selectedIDS={selectedIDS}
                selectAllOptionByGroup={selectAllOptionByGroup}
                allGroupsSelected={allOptionsSelected}
                groupSelectedIDS={groupSelectedIDS}
                isGrouped={selectAllOptionByGroup ? true : false}
              />
            ) : (
              <MultiSelectOptions
                items={group ? groupItems(items) : items}
                handleChange={this.handleCheckList}
                selectedIDS={selectedIDS}
                selectAllOptionByGroup={selectAllOptionByGroup}
                allGroupsSelected={allOptionsSelected}
                groupSelectedIDS={groupSelectedIDS}
                isGrouped={selectAllOptionByGroup ? true : false}
              />
            )}
          </div>
          <div className={classNames(classes.section, { border: 0 })}>
            <div
              className={classNames(
                classes.subSection,
                classes.subSection_totalSelected
              )}
            >
              {selectedItems.length === 0 ? (
                <div className={classes.selectedCounter}>
                  {textMessages.noneSelectedMessage}
                </div>
              ) : (
                <div className={classes.selectedAndClean}>
                  <div className={classes.selectedCounter}>
                    {`${selectedItems.length} ${textMessages.selectedMessage}`}
                  </div>
                  <div
                    className={classes.deleteSelected}
                    onClick={() => this.deleteAllItems()}
                  >
                    {textMessages.clearAllMessage}
                  </div>
                </div>
              )}
            </div>
            <MultiSelectItems
              selectedItems={group ? groupItems(selectedItems) : selectedItems}
              handleChange={this.deleteSelectedItem}
              namePlural={namePlural}
              loadingOptions={loadingOptions}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    background: '#ffffff',
    border: '1px solid #dddddd',
    color: '#666666',
    overflow: 'hidden',
    height: '100%'
  },
  section: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRight: '1px solid #dddddd'
  },
  subSection: {
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #dddddd'
  },
  subSection_search: {
    height: '45px',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    outline: 'none',
    border: 0,
    fontSize: '16px',
    background: 'transparent',
    fontFamily: theme.typography.fontFamily
  },
  subSection_selectAll: {
    height: '40px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#f3f3f3'
    }
  },
  subSection_totalSelected: {
    padding: '0 12px',
    color: '#000000'
  },
  selectedAndClean: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  selectedCounter: {
    display: 'inline-block',
    fontFamily: theme.typography.fontFamily
  },
  deleteSelected: {
    display: 'inline-block',
    color: '#3f51b5',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily
  }
});

MultiSelectBox.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  changeItems: PropTypes.func.isRequired,
  nameSingular: PropTypes.string,
  namePlural: PropTypes.string,
  group: PropTypes.bool,
  height: PropTypes.number,
  loadingOptions: PropTypes.bool
};
export default withStyles(styles, { withTheme: true })(MultiSelectBox);

// EXAMPLE OF ITEMS group=true
// const items = [
//   { id: 0, label: "item 1", group:"group1" },
//   { id: 2, label: "item 2", group:"group1", disabled: true },
//   { id: 3, label: "item 3", group:"group2", disabled: false },
//   { id: 4, label: "item 4", group:"group2"}
// ]

// OTHER ATTRIBUTES
//   <MultiSelect
//       responsiveHeight = {800}
//       withGrouping = {group?true:false}
//       ...
//   />

// OPTIONAL FUNCTIONALITY: MESSAGES WITH PERSONALIZE ENTITY NAMES
// searchPlaceholder: `Buscar ${namePlural}...`,
//       noItemsMessage: `Sin ${namePlural||'items'}...`,
//       noneSelectedMessage: `Ningun ${nameSingular||'item'} seleccionado`, // `Selecciona ${namePlural||'items'} para el usuario`,
//       selectedMessage: `${selectedItems.length>1?namePlural:nameSingular} selecionado${selectedItems.length>1?'s':''}`,
//       selectAllMessage: "Todos",
//       clearAllMessage: "Limpiar todos",
//       disabledItemsTooltip: "Solo puedes seleccionar 1 item de cada tipo"
