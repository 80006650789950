import { ROLES_SET, ROLES_SET_LIST, ROLES_CLEAR } from '../constants/action_constants';

const initialState = {
  roles: [],
  rolesList: []
};

export function role(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case ROLES_SET:
      return {
        ...state,
        roles: action.data,
        meta: action.meta
      };
    case ROLES_SET_LIST:
      return {
        ...state,
        rolesList: action.data,
        meta: action.meta
      };
    case ROLES_CLEAR:
      return {
        ...state,
        roles: initialState.roles
      };
    default:
      return state;
  }
}
