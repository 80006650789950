import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import StepperButtons from '../../shared/StepperButtons';

import ActivityIndicator from '../../shared/ActivityIndicator';
import Camera from '../../shared/Camera';

class DigitalSaleRegisterIdentity extends PureComponent {
  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;
  webcamRef = React.createRef();
  constructor(props) {
    super(props);

    this.setCameraDetections = this.setCameraDetections.bind(this);
    this.setDocumentShouldBeHorizontal = this.setDocumentShouldBeHorizontal.bind(
      this
    );
  }

  setCameraDetections(detections, faceSmiling = null) {
    let newState = { detections: detections };
    if (faceSmiling) {
      newState = { ...newState, faceSmiling: faceSmiling };
    }
    this.setState(newState);
  }

  setDocumentShouldBeHorizontal(documentShouldBeHorizontal) {
    this.setState({
      documentShouldBeHorizontal: documentShouldBeHorizontal
    });
  }

  state = {
    checked: true,
    debtorCode: '',
    detections: null,
    disabledNext: false,
    captureValid: false,
    faceSmiling: false,
    documentShouldBeHorizontal: null
  };

  componentDidMount = async () => {
    this.props.setCurrentStep(this.currentStep);
  };

  componentDidUpdate = () => {
    if (!this.props.isBackCapturedDebtorDocument) {
      return;
    }
    if (!this.state.captureValid) {
      if (this.state.faceSmiling) {
        this.setState({ captureValid: true });
      }
    }
    if (this.state.detections) {
      if (this.state.detections.length === 1) {
        this.toogledisabledNext(false);
      } else {
        this.toogledisabledNext(true);
      }
    } else {
      this.toogledisabledNext(true);
    }
  };

  toogledisabledNext(endState) {
    if (this.state.disabledNext !== endState) {
      this.setState({ disabledNext: endState });
    }
  }

  getCaptureStep = () => {
    const { isFrontCapturedDebtorDocument = false } = this.props;
    return isFrontCapturedDebtorDocument ? 1 : 0;
  };

  render() {
    const {
      classes,
      isFrontCapturedDebtorDocument = false,
      isBackCapturedDebtorDocument = false,
      debtorIdentificationType,
      debtorIdentification,
      serverErrors = {},
      stepIsLoading,
      contract
    } = this.props;

    const capture = () => {
      const { process, routes } = this.props;
      const imageSrc = this.webcamRef.current.getScreenshot();
      if (isBackCapturedDebtorDocument) {
        this.props.registerDigitalSaleIdentityFace(
          {
            identification_type: debtorIdentificationType,
            identification: debtorIdentification + '',
            contract: contract,
            photo: imageSrc.replace('data:image/jpeg;base64', '')
          },
          routes[this.nextStep],
          process
        );
      } else {
        if (this.state.documentShouldBeHorizontal) {
          const canvas = document.getElementById('c');
          const ctx = canvas.getContext('2d');
          const image = new Image();
          image.src = imageSrc;
          image.onload = function() {
            canvas.width = image.height;
            canvas.height = image.width;
            ctx.rotate((270 * Math.PI) / 180);
            ctx.translate(-canvas.height, 0);
            ctx.drawImage(image, 0, 0);
            callback(canvas.toDataURL(), 'png');
          };
        } else {
          callback(imageSrc, 'png');
        }
      }
    };

    const silentCapture = imageSrc => {
      this.props.saveGestureValidation({
        contract: contract,
        identification_type: debtorIdentificationType,
        identification: debtorIdentification + '',
        photo: imageSrc.replace('data:image/png;base64', ''),
        type: 'registration'
      });
    };

    const callback = (base64data, ext) => {
      this.props.registerDigitalSaleIdentityDocument({
        capture_step: this.getCaptureStep(),
        contract: this.props.contract,
        identification_type: debtorIdentificationType,
        identification: debtorIdentification + '',
        photo: base64data.replace('data:image/' + ext + ';base64', ''),
        ext
      });
    };

    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Fragment>
            <Camera
              isFrontCapturedDebtorDocument={isFrontCapturedDebtorDocument}
              isBackCapturedDebtorDocument={isBackCapturedDebtorDocument}
              captureValid={this.state.captureValid}
              detections={this.state.detections}
              disabledNext={this.state.disabledNext}
              documentShouldBeHorizontal={this.state.documentShouldBeHorizontal}
              setDocumentShouldBeHorizontal={this.setDocumentShouldBeHorizontal}
              setCameraDetections={this.setCameraDetections}
              webcamRef={this.webcamRef}
              capture={capture}
              silentCapture={silentCapture}
              capturingDocument={true}
              extension={'png'}
            />
            {stepIsLoading && (
              <ActivityIndicator className={classes.loadingIndicator} />
            )}
            <StepperButtons
              onSubmit={capture}
              goBack={this.props.goBack}
              nextText="Capturar"
              disabledNext={
                isBackCapturedDebtorDocument &&
                (this.state.disabledNext || !this.state.captureValid)
              }
            />
          </Fragment>
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  loadingIndicator: {
    position: 'fixed',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 1
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  }
});

DigitalSaleRegisterIdentity.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(DigitalSaleRegisterIdentity);
