import DashboardPage from './DashboardPage';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { logout } from '../../../actions/authentication_actions';
import { withRouter } from 'react-router-dom';
import { clearFinancing } from '../../../actions/portal_financing_actions';
import { openQueryPromissoryModal } from '../../../actions/query_promissory_actions';
import { openQueryQuotaModal } from '../../../actions/query_quota_actions';
import { openQueryScoringQuotaModal } from '../../../actions/query_scoring_quota_action';
import { openQueryMegaQuotaModal } from '../../../actions/query_mega_quota_actions';
import { openCalculateInstalmentsModal } from '../../../actions/calculate_instalments_actions';
import { openQueryInventoryModal } from '../../../actions/query_inventory_actions';
import { openRegisterScheduleModal } from '../../../actions/register_schedule_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';
import { updateUser } from '../../../actions/user_actions';
import {
  fetchNotifications,
  notificationMarkAsSeen
} from '../../../actions/notifications_actions';
import {
  fetchSessionData,
  updatePasswordWithToken
} from '../../../actions/session_actions';

import {
  fetchAnnouncements,
  toggleAnnouncementAction,
  announcementMarkAsSeen
} from '../../../actions/announcements_actions';

import {
  openDeployModal,
  closeDeployModal
} from '../../../actions/session_actions';

function mapStateToProps(state) {
  return {
    permissions: state.authentication.permissions,
    currentUser: state.authentication.currentUser,
    freelanceRoles: state.globalSetting.orgData.asesor_freelance_roles,
    asesorRoles: state.globalSetting.orgData.asesor_fnb_roles,
    requestOrgDataSend: state.requestState.requestOrgDataSend,
    requestAnnouncementsSend: state.requestState.requestAnnouncementsSend,
    requestChangePasswordSend: state.requestState.requestUpdatePasswordSend,
    orgData: state.globalSetting.orgData,
    notifications: state.notification.previewList,
    companyCode: state.sessionData.companyName,
    announcements: state.announcement.previewList,
    isDeployModalOpen: state.sessionData.deployModalIsOpen,
    actionData: state.sessionData.deployModalData,
    companyName: state.globalSetting.orgData.company_name,
    orgUserDataAuthorization:
      state.globalSetting.orgData.get_user_data_authorization
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePasswordWithToken: bindActionCreators(
      updatePasswordWithToken,
      dispatch
    ),
    logout: bindActionCreators(logout, dispatch),
    clearFinancing: bindActionCreators(clearFinancing, dispatch),
    menuFunctions: {
      openQueryPromissoryModal: bindActionCreators(
        openQueryPromissoryModal,
        dispatch
      ),
      openQueryQuotaModal: bindActionCreators(openQueryQuotaModal, dispatch),
      openQueryScoringQuotaModal: bindActionCreators(
        openQueryScoringQuotaModal,
        dispatch
      ),
      openQueryMegaQuotaModal: bindActionCreators(
        openQueryMegaQuotaModal,
        dispatch
      ),
      openCalculateInstalmentsModal: bindActionCreators(
        openCalculateInstalmentsModal,
        dispatch
      ),
      openQueryInventoryModal: bindActionCreators(
        openQueryInventoryModal,
        dispatch
      ),
      openRegisterScheduleModal: bindActionCreators(
        openRegisterScheduleModal,
        dispatch
      )
    },
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    fetchNotifications: bindActionCreators(fetchNotifications, dispatch),
    fetchSessionData: bindActionCreators(fetchSessionData, dispatch),
    fetchAnnouncements: bindActionCreators(fetchAnnouncements, dispatch),
    toggleAnnouncementAction: bindActionCreators(
      toggleAnnouncementAction,
      dispatch
    ),
    notificationMarkAsSeen: bindActionCreators(
      notificationMarkAsSeen,
      dispatch
    ),
    announcementMarkAsSeen: bindActionCreators(
      announcementMarkAsSeen,
      dispatch
    ),
    openDeployModal: bindActionCreators(openDeployModal, dispatch),
    closeDeployModal: bindActionCreators(closeDeployModal, dispatch),
    updateUser: bindActionCreators(updateUser, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DashboardPage);
