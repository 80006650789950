import React, { PureComponent } from 'react';
import combineStyles from '../../../../helpers/combineStyles';
import TypographyStyles from '../../../../styles/typography';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { STATUSES as SALE_STATUSES } from './constanst';

const styles = theme => ({
  statusLabel: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#FEB00B',
    fontFamily: theme.typography.font.main,
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '20px'
  },
  movementLabel: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#FEB00B',
    fontFamily: theme.typography.font.main,
    fontSize: '11px',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '20px'
  },
  RETURN_COMMENT: {
    color: 'rgba(12,31,44,0.6)',
    backgroundColor: 'rgba(12,31,44,0.1)',
  },
  [SALE_STATUSES.DELIVERED]: {
    backgroundColor: 'rgba(0,195,150,0.08)',
    color: '#00C396'
  },
  [SALE_STATUSES.APPROVED]: {
    backgroundColor: 'rgba(0,195,150,0.08)',
    color: '#00C396'
  },
  [SALE_STATUSES.EXCEPTION]: {
    backgroundColor: 'rgba(219,73,69,0.08)',
    color: theme.palette.primary.main
  },
  [SALE_STATUSES.REVOKED]: {
    backgroundColor: 'rgba(219,73,69,0.08)',
    color: theme.palette.primary.main
  },
  [SALE_STATUSES.CANCELED]: {
    backgroundColor: 'rgba(219,73,69,0.08)',
    color: theme.palette.primary.main
  },
  [SALE_STATUSES.RETURNED]: {
    backgroundColor: 'rgba(219,73,69,0.08)',
    color: theme.palette.primary.main
  },
  [SALE_STATUSES.PARTIALLY_RETURNED]: {
    backgroundColor: 'rgba(219,73,69,0.08)',
    color: theme.palette.primary.main
  }
});

export class StatusLabel extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  };

  render() {
    const { label, classes, type, isMovement = false } = this.props;
    const textColor = _get(classes, type, {});
    console.log(textColor);
    return (
      <span className={classnames(isMovement ? classes.movementLabel :classes.statusLabel, textColor)}>
        {label}
      </span>
    );
  }
}

export default withStyles(combineStyles(styles, TypographyStyles))(StatusLabel);
