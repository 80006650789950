import { ACTION_TYPES } from './NotificationItem.constant';

const MOCKED_DATA = [
  {
    id: 1,
    title: '[HC] Shipment #100118',
    headerMessage: 'was cancelled by Admin Name.',
    createdAt: '3 minutes ago',
    metadata: {
      type: ACTION_TYPES.LINK,
      url: '/shipments/100118',
      message: ''
    },
    status: 'new'
  },
  {
    id: 2,
    title: '[HC] Admin Name',
    headerMessage: 'added a note to Shipment #100123.',
    createdAt: '1 hour ago',
    metadata: {
      type: ACTION_TYPES.LINK,
      url: '/shipments/100123',
      message: ''
    },
    status: 'new'
  },
  {
    id: 3,
    title: '[HC] Admin Name',
    headerMessage: 'posted a message:',
    createdAt: '2 hours ago',
    bodyMessage:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    metadata: {
      type: ACTION_TYPES.MODAL,
      url: '',
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    status: 'read'
  },
  {
    id: 4,
    title: '[HC] Admin Name',
    headerMessage: 'posted a message:',
    createdAt: '2 hours ago',
    bodyMessage:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    metadata: {
      type: ACTION_TYPES.MODAL,
      url: '',
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    status: 'read'
  },
  {
    id: 5,
    title: '[HC] Shipment #100119',
    headerMessage: 'was cancelled by Admin Name.',
    createdAt: 'Yesterday',
    bodyMessage: '',
    metadata: {
      type: ACTION_TYPES.LINK,
      url: '/shipments/100119',
      message: ''
    },
    status: 'done'
  },
  {
    id: 7,
    title: '[HC] Admin Name',
    headerMessage: 'updated the status of Shipment #100115.',
    createdAt: 'JAN 1, 2019',
    bodyMessage: '',
    metadata: {
      type: ACTION_TYPES.LINK,
      url: '/shipments/100115',
      message: ''
    },
    status: 'read'
  },
  {
    id: 8,
    title: '[HC] Shipment #100120',
    headerMessage: 'is delayed.',
    createdAt: 'DEC 28, 2018',
    bodyMessage: '',
    metadata: {
      type: ACTION_TYPES.LINK,
      url: '/shipments/100120',
      message: ''
    },
    status: 'read'
  },
  {
    id: 9,
    title: '[HC] Shipment #100121',
    headerMessage: 'was cancelled by the carrier.',
    createdAt: 'DEC 28, 2018',
    bodyMessage: '',
    metadata: {
      type: ACTION_TYPES.LINK,
      url: '/shipments/100121',
      message: ''
    },
    status: 'done'
  },
  {
    id: 10,
    title: '[HC] Admin Name',
    headerMessage: 'updated the status of Shipment #908761223.',
    createdAt: 'DEC 28, 2018',
    bodyMessage: '',
    metadata: {
      type: ACTION_TYPES.MODAL,
      url: '',
      message: ''
    },
    status: 'done'
  },
  {
    id: 11,
    title: '[HC] Shipment #100122',
    headerMessage: 'was cancelled by the carrier.',
    createdAt: 'DEC 28, 2018',
    bodyMessage: '',
    metadata: {
      type: ACTION_TYPES.LINK,
      url: '/shipments/100122',
      message: ''
    },
    status: 'read'
  },
  {
    id: 12,
    title: '[HC] Shipment #100123',
    headerMessage: 'was cancelled by the carrier.',
    createdAt: 'DEC 28, 2018',
    bodyMessage: '',
    metadata: {
      type: ACTION_TYPES.LINK,
      url: '/shipments/100123',
      message: ''
    },
    status: 'read'
  }
];

export default MOCKED_DATA;
