import _get from 'lodash/get';

export const initialValuesSanitizer = props => {
  const { debtor, cosigner } = props;
  const debtorData = debtor
    ? {
        debtorName: _get(debtor, 'name', ''),
        debtorLastname: _get(debtor, 'last_name', ''),
        debtorDoctype: _get(debtor, 'debtor_doctype_id', ''),
        debtorDocid: _get(debtor, 'identification', ''),
        debtorEmail: _get(debtor, 'email', ''),
        debtorPhone: _get(debtor, 'phone', ''),
        debtorCellphone: _get(debtor, 'mobile', '')
      }
    : {};
  const cosignerData = cosigner
    ? {
        cosignerName: _get(cosigner, 'name', ''),
        cosignerLastname: _get(cosigner, 'last_name', ''),
        cosignerDoctype: _get(cosigner, 'cosigner_doctype_id', ''),
        cosignerDocid: _get(cosigner, 'identification', ''),
        cosignerEmail: _get(cosigner, 'email', ''),
        cosignerPhone: _get(cosigner, 'phone', ''),
        cosignerCellphone: _get(cosigner, 'mobile', '')
      }
    : {};
  return {...debtorData, ...cosignerData};
};

export const submitSanitizer = (values, saleDetails, process) => {
  return {
    debtor: {
      phone: values.debtorPhone,
      mobile: values.debtorCellphone,
      email: values.debtorEmail
    },
    cosigner: values.cosignerDocid ? {
      phone: values.cosignerPhone,
      mobile: values.cosignerCellphone,
      email: values.cosignerEmail
    } : null,
    saleDetails: {
      totalValue: saleDetails.articles.reduce(
        (accumulator, item) => accumulator + item.value * item.amount * (process === 'remoteTeleshopping' ? item.num_monthly_instalments : 1),
        0
      ),
      downPayment: saleDetails.downPayment,
      pointOfSaleID: saleDetails.pointOfSaleID,
      salesmanId: saleDetails.salesmanId
    },
    articles: saleDetails.articles,
  };
};
