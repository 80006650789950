import BrandList from './FinancialPlanList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchFinancialPlans } from '../../../actions/financial_plan_actions';

function mapStateToProps(state) {
  return {
    financialPlans: state.financialPlans.financialPlans,
    meta: state.financialPlans.meta,
    loading: state.requestState.requestFinancialPlansSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFinancialPlans: bindActionCreators(fetchFinancialPlans, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BrandList);
