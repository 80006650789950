import { _moneyFormat } from '../../../helpers/utilityFunctions';
import _get from 'lodash/get';
import { quotaTransfer } from '../../../reducers/quota_transfer_reducer';
export const columns = [
  {
    title: '# Venta',
    name: 'saleNumber',
    componentType: 'Link'
  },
  {
    title: 'Aliado',
    name: 'supplier_pretty'
  },
  {
    title: '# Contrato Recibe',
    name: 'receiving_contract_id'
  },
  {
    title: '# Contrato Cede',
    name: 'giving_contract_id'
  },
  {
    title: 'Tipo',
    name: 'type'
  },
  {
    title: 'Valor',
    name: 'transferTotal'
  },
  {
    title: 'Estado',
    name: 'status'
  }
];

export const linkColumns = ['saleNumber'];
export const currencyColumns = ['transferTotal'];

const statusTranslation = {
  reviewed: 'Revisado',
  registered: 'Registrado',
  revoked: 'Anulado',
  approved: 'Aprobado'
};

const typeTranslation = {
  union: 'Unión',
  transfer: 'Traslado'
};

export const mapData = data =>
  data.map(datum => {
    let givingContract = '';
    datum.quota_transfers.forEach(
      (quotaTransfer, i) =>
        (givingContract +=
          i < datum.quota_transfers.length - 1
            ? `${quotaTransfer.contract.external_code}, `
            : quotaTransfer.contract.external_code)
    );

    return {
      ...datum,
      type: typeTranslation[datum.quota_transfers[0].transfer_type],
      saleNumber: datum.id,
      transferTotal:
        _moneyFormat(
          datum.quota_transfers.reduce(
            (acc, transfer) => acc + transfer.value,
            0
          )
        ) || '--',
      receiving_contract_id: _get(datum, 'contract_id', '--'),
      giving_contract_id: givingContract || '--',
      supplier_pretty: _get(datum, 'supplier.name', '--'),
      status: statusTranslation[datum.quota_transfers[0].status],
      saleNumberLinkTo: `/sales/${datum.id}`
    };
  });
