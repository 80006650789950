import {
  USERS_SET,
  USER_INFO_SET,
  USER_NAMES_SET,
  USER_CREATE_INFO_SET,
  USER_CREATE_INFO_CLEAR,
  USER_CREATE_ERROR,
  USER_CLEAR_ERROR
} from '../constants/action_constants';

const initialState = {
  userList: [],
  meta: null,
  userInfo: null,
  creationInfo: null,
  errors: {}
};

export function user(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case USERS_SET:
      return {
        ...state,
        userList: action.data,
        meta: action.meta
      };
    case USER_NAMES_SET:
      return {
        ...state,
        userNames: action.data
      };
    case USER_INFO_SET:
      return {
        ...state,
        userInfo: action.data
      };
    case USER_CREATE_INFO_SET:
      return {
        ...state,
        creationInfo: action.data
      };
    case USER_CREATE_INFO_CLEAR:
      return {
        ...state,
        creationInfo: action.data
      };
    case USER_CREATE_ERROR:
      return {
        ...state,
        errors: action.errors
      };
    case USER_CLEAR_ERROR:
      return {
        ...state,
        errors: action.data
      };
    default:
      return state;
  }
}
