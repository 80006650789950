import { Grid, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import combineStyles from '../../../helpers/combineStyles';
import styles from './LoginLayout.styles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';

class LoginLayout extends Component {
  render() {
    const { children, classes } = this.props;

    return (
      <div style={{ height: '100%', overflowY: 'hidden' }}>
        <Grid container spacing={0} style={{ height: '100%' }}>
          <Grid item xs={12} sm className={classes.imageContainer}>
            <img
              className={classes.whiteCorner}
              src="/logos/white-left-corner.svg"
            />
            <img className={classes.brillaLogo} src="/logos/logo-brilla.svg" />
          </Grid>
          <Grid item xs={12} sm>
            {children}
          </Grid>
        </Grid>
      </div>
    );
  }
}

LoginLayout.propTypes = {};

export default withStyles(
  combineStyles(styles, ButtonStyles, TypographyStyles)
)(LoginLayout);
