import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import Grid from '@material-ui/core/Grid';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import SpacingStyles from '../../../styles/helpers/spacing';
import TypographyStyles from '../../../styles/typography';
import InsuranceInfoStyles from './__styles__/InsuranceInfo.styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import formatMoney from '../../../helpers/formatMoney';

import EditInsuranceModal from './Modals/EditInsuranceModal';
import CancelInsuranceModal from './Modals/CancelInsuranceModal';

import { statusOptions, typeOptions, lifePlanOptions } from '../Insurance/options';

export class InsuranceInfo extends React.Component {
  state = {
    editInsuranceFormOpen: false,
    cancelInsuranceFormOpen: false
  };

  _handleOpenModal = name => this.setState({ [name]: true });
  _handleCloseModal = name => this.setState({ [name]: false });

  handleCancel = values => {
    this.props.onCancel({
      userId: this.props.currentUser.id,
      user: `${this.props.currentUser.firstName} ${
        this.props.currentUser.lastName
      }`,
      ...values
    });
  };

  handleEdit = values => {
    this.props.onEdit({
      user: `${this.props.currentUser.firstName} ${
        this.props.currentUser.lastName
      }`,
      ...values
    });
  };

  render() {
    const { classes, insurance, clientDetail, currentUser } = this.props;
    return (
      <section className={classes.headerRoot}>
        <div className={classes.insuranceDetails}>
          <h1 className={classes.headline8}>{'Información de servicio'}</h1>
          {insurance.status == 'active' &&
            (insurance.externalCode && insurance.productId) && insurance.type === 'funeral' &&
            currentUser.canModifyServices && (
              <div>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    this._handleOpenModal('cancelInsuranceFormOpen')
                  }
                  className={classes.button}
                  classes={{
                    label: classes.buttonLabel
                  }}
                >
                  {'Cancelar'}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this._handleOpenModal('editInsuranceFormOpen')}
                  className={classes.button}
                  classes={{
                    label: classes.buttonLabel
                  }}
                >
                  {'Modificar'}
                </Button>
              </div>
            )}
        </div>
        <div className={classes.details}>
          <Grid container spacing={16} className={classes.contactData}>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{'Contrato asociado:'}</p>
              <p className={classes.body2}>{insurance.contractId || '--'}</p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{'Tipo de servicio:'}</p>
              <p className={classes.body2}>
              {`${typeOptions[insurance.type]}${clientDetail.planId ? ` - ${lifePlanOptions[clientDetail.planId]}`: ''}` || '--'}
              </p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{`${'Valor'}:`}</p>
              <p className={classes.body2}>
                {formatMoney(insurance.totalValue) || '--'}
              </p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{`${'Estado'}:`}</p>
              <p className={classes.body2}>
                {statusOptions[insurance.status] || '--'}
              </p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{`${'Código externo'}:`}</p>
              <p className={classes.body2}>
                {insurance.externalCode || 'En proceso de asignación...'}
              </p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{`${'ID de producto'}:`}</p>
              <p className={classes.body2}>
                {insurance.productId || 'En proceso de asignación...'}
              </p>
            </Grid>
          </Grid>
        </div>
        {this.state.editInsuranceFormOpen && (
          <EditInsuranceModal
            onSave={this.handleEdit}
            onClose={() => this._handleCloseModal('editInsuranceFormOpen')}
            isSubmitting={this.props.requestSend}
          />
        )}
        {this.state.cancelInsuranceFormOpen && (
          <CancelInsuranceModal
            onSave={this.handleCancel}
            onClose={() => this._handleCloseModal('cancelInsuranceFormOpen')}
            isSubmitting={this.props.requestSend}
            cancellationCausals={this.props.cancellationCausals}
          />
        )}
      </section>
    );
  }
}

export default withStyles(
  combineStyles(InsuranceInfoStyles, ButtonStyles, TypographyStyles)
)(InsuranceInfo);
