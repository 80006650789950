import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import SimpleTableCell from '../../shared/Tables/SimpleTableCell';

class CustomTableCell extends PureComponent {
  render() {
    const { classes, className, ...props } = this.props;
    return (
      <SimpleTableCell
        {...props}
        className={classnames(classes.cell, className)}
      />
    );
  }
}

CustomTableCell.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

const styles = theme => ({
  cell: {
    paddingLeft: `${theme.spacing.unit}px !important`,
    paddingRight: `${theme.spacing.unit}px !important`
  }
});

export default withStyles(styles)(CustomTableCell);
