import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManualQuotaRevision from './ManualQuotaRevision';
import {
  manualQuotaRevision,
  manualQuotaDeactivate
} from '../../../actions/manual_quota_actions';
function mapStateToProps(state) {
  return {
    canReviewManualQuota: state.authentication.currentUser.canReviewManualQuota,
    canApproveManualQuota:
      state.authentication.currentUser.canApproveManualQuota,
    canRevokeManualQuota: state.authentication.currentUser.canRevokeManualQuota,
    canListManualQuota: state.authentication.currentUser.canListManualQuota,
    canDeactivateManualQuota:
      state.authentication.currentUser.canDeactivateManualQuota,
    requestSend: state.requestState['requestManual-Quota-RevisionSend']
  };
}

function mapDispatchToProps(dispatch) {
  return {
    manualQuotaRevision: bindActionCreators(manualQuotaRevision, dispatch),
    manualQuotaDeactivate: bindActionCreators(manualQuotaDeactivate, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualQuotaRevision);
