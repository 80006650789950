import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import Button from '@material-ui/core/Button';
import OtpInput from 'react-otp-input';
import { withStyles } from '@material-ui/core/styles';

import ProgressButton from '../Buttons/ProgressButton';
import ButtonStyles from '../../../styles/button';
import Modal from '../Modals/Modal';

import combineStyles from '../../../helpers/combineStyles';


import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ActivityIndicator from '../ActivityIndicator';
import Timer from '../Timer';


const validationSchema = Yup.object().shape({
  otp: Yup.string().test(
    'len',
    'Debe tener exactamente 4 caracteres',
    val => val && val.toString().length === 4
  )
})

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 3.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  attributes: {
    color: '#2C3C47 !important',
    fontWeight: '500 !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  padding: {
    paddingTop: `${theme.spacing.unit * 11}px !important`
  },
  paddingConfirmation: {
    paddingTop: `${theme.spacing.unit * 3.25}px !important`
  },
  otpbody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: `${theme.spacing.unit * 7.375}px !important`,
    color: '#707070'
  },
  otpInputStyle: {
    width: '3rem !important',
    margin: '20px 1rem',
    borderBottom: '2px solid #707070',
    color: '#2C3C47',
    fontFamily: 'Roboto',
    fontSize: '50px'
  },
  mobileDot: {
    padding: '0px',
    top: '24px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '1000',
    position: 'absolute',
    boxSizing: 'border-box',
    background: 'transparent'
  },
  mobileDots: {
    width: '12px !important',
    height: '12px !important',
    margin: '0 4px !important',
    borderRadius: '3px !important'
  },
  mobileDotActive: {
    backgroundColor: '#707070'
  },
  modalRoot: {
    maxWidth: '100vw',
    width: '700px',
    height: '560.600px'
  },
  modalBody: {
    padding: `${theme.spacing.unit * 10}px ${theme.spacing.unit * 6}px 0 ${theme
      .spacing.unit * 6}px`,
    height: '80%',
    position: 'relative'
  },
  modalFooter: {
    margin: `${theme.spacing.unit * 7}px ${theme.spacing.unit * 5}px ${theme
      .spacing.unit * 1.75}px ${theme.spacing.unit * 5}px`
  },
  buttonChange: {
    padding: '0px'
  },
  pointer: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  body1_: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.main,
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    lineHeight: '28px',
    margin: 0,
    textAlign: 'center',
  },
  item: {
    marginTop: 12,
    marginBottom: 12,
    textAlign: 'center'
  },
  actionContainer: {
    textAlign: 'center',
    width: '100%',
    marginTop: 24,
    marginBottom: 24
  },
  codeAction: {
    fontSize: 20,
    color: theme.palette.primary.dark,
    fontWeight: 500,
    cursor: 'pointer',
    margin: '1em auto'
  }
});

class OtpValidationField extends PureComponent {
  RESEND_FLAG_SECONDS_DELAY = 120;
  timer = null;

  state = {
    activeStep: 0,
    input: '',
    isOpenedOtpModel: false,
    counter: this.RESEND_FLAG_SECONDS_DELAY,
    canResend: false,
  };

  componentDidMount() {
    this.resetResendFlag();
  }

  componentDidUpdate() {
    if (this.state.counter <= 0) {
      clearInterval(this.timer);
      this.timer = null;
      this.setState({ canResend: true });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  _handleCloseOpenedOtpModel = (isOpenedOtpModel) => {
    this.setState({ isOpenedOtpModel, counter: this.RESEND_FLAG_SECONDS_DELAY, canResend: false });
    clearInterval(this.timer);
    this.resetResendFlag();
  };

  _handleChangeOtp = (otp, handleChange, onChangeCustom) => {
    const evt = { target: { value: otp, name: 'otp' } };
    handleChange(evt);
    onChangeCustom && onChangeCustom(otp);
  };

  _handleValidateUniqueMobile = () => {
    const { mobile,
      debtorIdentification,
      sendOtp,
      entityType
    } = this.props;

    const params = {
      mobile,
      debtorIdentification,
    }
    this.props.validateUniqueMobile(params).then(() => {
      if (this.props.uniqueMobileValidation) {
        this._handleSendOtp(mobile, sendOtp, entityType)
      }
    });
  };

  _handleSendOtp = (mobile, sendOtp, entityType) => {
    this.setState({ isOpenedOtpModel: true });
    const params = {
      mobile,
      entityType: entityType,
      entityId: 1,
      customMessage: `Al dar el código de verificación, usted acepta las políticas de tratamiento de datos personales que puede consultar en: ${this.props.termsAndConditionsUrl} #Brilla`
    }
    sendOtp(params).then(() => {
      this._handleCloseOpenedOtpModel(true);
    });
  };

  _handleValidateOtp = (mobile, otp, validateOtp) => {
    validateOtp({
      mobile,
      otp
    }).then((data) => {
      if (data.valid) this.setState({ isOpenedOtpModel: false });
    });
  }

  handleResend = (mobile, sendOtp, entityType) => {
    if (!this.state.canResend) {
      return;
    }
    this.setState({ counter: this.RESEND_FLAG_SECONDS_DELAY, canResend: false });
    this.resetResendFlag();
    this._handleSendOtp(mobile, sendOtp, entityType);
  };

  resetResendFlag = () => {
    const countdown = c => c - 1;
    const interval = setInterval(() => {
      this.setState({ counter: countdown(this.state.counter) });
    }, 1000);
    this.timer = interval;
  };

  renderBody(values, handleChange, mobile, entityType) {
    const {
      classes,
      isModal,
      onChangeCustom = () => null,
      sendOtp
    } = this.props;
    return (
      <Fragment>
        {isModal && (
          <div className={classes.titleWrapper}>
            <p className={classnames(classes.headline6, classes.title)}>
              Confirmación
            </p>
          </div>
        )}

        <div className={classes.paddingConfirmation}>
          <p className={isModal ? classes.body1 : classes.body1_}>
            <p>
              Ingresa el código de 4 dígitos enviado por SMS al celular{' '}
              <strong>{this.state.input}</strong>
            </p>
          </p>
          <div className={classes.otpbody}>
            <Field
              name="otp"
              render={({ field, form }) => {
                return (
                  <OtpInput
                    numInputs={4}
                    isInputNum={true}
                    onChange={otp =>
                      this._handleChangeOtp(otp, handleChange, onChangeCustom)
                    }
                    value={values.otp}
                    field={field}
                    form={form}
                    shouldAutoFocus={true}
                    inputStyle={classnames(classes.otpInputStyle)}
                  />
                );
              }}
            />
            {!this.state.canResend && (
              <div className={classes.item} variant="caption">
                Si el mensaje no te ha llegado en 2 minutos, puedes volver a enviar
                el mensaje de texto con el código verificación
              </div>
            )}
            <div className={classes.actionContainer}>
              <span
                id="PhoneVerificationDialog_span_resendVerification"
                onClick={() => this.handleResend(mobile, sendOtp, entityType)}
                className={classnames(classes.subtitle2, classes.pointer)}
              >
                <Timer counter={this.state.counter} />
              </span>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  render() {
    const {
      classes,
      open = true,
      mobile,
      requestOtpSend,
      requestValidateOtpSend,
      errors,
      resetOtpInfoResponse,
      otpSent,
      mobileValidated,
      entityType,
      isModal = true,
      disabled,
    } = this.props;
    let disableInconfirmation = false;
    return (
      <React.Fragment>
        {errors && (
          <TextField
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: requestOtpSend ? (
                <ActivityIndicator size={24} />
              ) : null
            }}
            fullWidth
            error={!!errors.mobile}
            helperText={errors.mobile}
            {...this.props}
            disabled={(otpSent && mobileValidated) || disabled}
          />
        )}
        {isModal && (
          <ProgressButton
            className={classnames(classes.button, classes.action)}
            classes={{
              label: classes.buttonLabel
            }}
            disabled={mobile.toString().length !== 10 || (otpSent && mobileValidated)}
            variant="outlined"
            color="secondary"
            onClick={this._handleValidateUniqueMobile}
          >
            {'Validar'}
          </ProgressButton>
        )}
        {(!isModal || (this.state.isOpenedOtpModel && !requestOtpSend && !mobileValidated)) && (
          <Formik
            initialValues={{ otp: '' }}
            onSubmit={async values => this._handleValidateOtp(mobile, values.otp, this.props.validateOtp)}
            onReset={(values, actions) => {
              resetOtpInfoResponse();
              this._handleCloseOpenedOtpModel(false);
              actions.resetForm();
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            render={({
              values,
              handleChange,
              handleSubmit,
              handleReset
            }) => {
              if (this.state.input != '') {
                if (values.otp != null) {
                  if (values.otp.length < 4) {
                    disableInconfirmation = true;
                  } else {
                    disableInconfirmation = false;
                  }
                }
              }
              return (
                isModal ? (
                  <Modal
                    open={open}
                    hideBackdrop={true}
                    onClose={this._handleOnCloseAll}
                    classes={{
                      root: classes.modalRoot,
                      body: classes.modalBody,
                      footer: classes.modalFooter
                    }}
                    body={
                      this.renderBody(values, handleChange, mobile, entityType)
                    }
                    footer={
                      <Fragment>
                        <Button
                          className={classes.button}
                          onClick={handleReset}
                          color={'secondary'}
                          classes={{ label: classes.buttonLabel }}
                        >
                          {'Cancelar'}
                        </Button>
                        <ProgressButton
                          onClick={handleSubmit}
                          className={classes.button}
                          classes={{
                            disabled: classes.buttonDisabled,
                            label: classes.buttonLabel
                          }}
                          disabled={
                            requestValidateOtpSend ||
                            disableInconfirmation
                          }
                          color={'secondary'}
                          isSubmitting={requestValidateOtpSend}
                        >
                          Confirmar
                        </ProgressButton>
                      </Fragment>
                    }
                  />
                ) : (
                  this.renderBody(values, handleChange, mobile, entityType)
                )

              );
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(
  combineStyles(
    ButtonStyles,
    styles,
    LayoutStyles,
    TypographyStyles,
    SpacingStyles
  )
)(OtpValidationField);

