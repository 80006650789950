import * as Yup from 'yup';
import moment from 'moment-timezone';

export const validationSchema = (companyName) => Yup.object().shape({
  name: Yup.string().nullable().required('Nombre es requerido'),
  option: Yup.string().nullable().required('Tipo es requerido'),
  value: Yup.string().required('Valor es requerido'),
  startAt: Yup.date().nullable().required('Fecha de Inicio es requerida'),
  endAt: Yup.date().when('startAt', (start_at, schema) => {
      return schema.min(moment(startAt).add(1, 'days'), 'Fecha de Finalización tiene que ser mayor a la Fecha de inicio');
  }).required('Fecha de Finalización es requerida'),
});

export default validationSchema;
