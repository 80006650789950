import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classnames from 'classnames';
import {
  Typography,
  withStyles,
  Button,
  Dialog,
  DialogContentText
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { orangeHighlight, white } from '../../../constants/style';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

class ConfirmDialog extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
    buttonTextCancel: PropTypes.string,
    buttonTextConfirm: PropTypes.string
  };

  render() {
    const {
      open = true,
      title,
      text,
      onClose,
      disableBackdropClick = false,
      onConfirm,
      buttonTextCancel,
      buttonTextConfirm,
      classes,
      customBase
    } = this.props;
    return (
      <Dialog
        fullWidth
        // TransitionComponent={Transition}
        open={open}
        onClose={onClose}
        disableBackdropClick={disableBackdropClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classnames(classes.dialogBase, customBase)
        }}
      >
        <DialogTitle id="alert-dialog-title" onClose={onClose}>
          {title || 'Confirmación'}
        </DialogTitle>
        <DialogContent style={{ minHeight: 150 }}>          
            <DialogContentText id="alert-dialog-description">
              {text || '¿Estás seguro que deseas realizar esta acción?'}
            </DialogContentText>          
        </DialogContent>
        <DialogActions>
          {buttonTextCancel && (
            <Button onClick={onClose} color="secondary" autoFocus>
              {buttonTextCancel}
            </Button>
          )}
          <Button onClick={onConfirm} color="secondary" autoFocus>
            {buttonTextConfirm || 'Sí'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = () => ({
  button: {
    backgroundColor: orangeHighlight,
    color: white
  },
  dialogContainer: {},
  dialogBase: {
    borderRadius: '2px',
    width: 600
  }
});

export default compose(withStyles(styles, { withTheme: true }))(ConfirmDialog);
