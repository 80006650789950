import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import _isFunction from 'lodash/isFunction';
import classnames from 'classnames';

import AutocompleteStyles from './Autocomplete.styles';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import FormikPropsValidations from './FormikPropsValidations';
import combineStyles from './../../../helpers/combineStyles';
import FieldValidationPopup from '../Poppers/FieldValidation';
import TypographyStyles from '../../../styles/typography';
import { AUTOCOMPLETE_MAX_RESULTS } from '../../../constants/input_types';
import LoadingIndicator from '../LoadingIndicator';

export class Autocomplete extends Component {
  static propTypes = {
    getSuggestions: PropTypes.func.isRequired,
    maxResults: PropTypes.number,
    getSuggestionValue: PropTypes.func,
    onSuggestionSelected: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    name: props => FormikPropsValidations('name', props),
    field: PropTypes.object,
    form: PropTypes.object,
    helperText: PropTypes.string,
    validateField: PropTypes.func,
    autoWidth: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
  };
  state = {
    value: this.props.value,
    suggestions: [],
    fieldName: this.props.name || _get(this.props, 'field.name'),
    fieldError: '',
    anchorEl: null
  };
  componentDidMount() {
    this._setFieldError();
  }
  componentDidUpdate(prevProps) {
    const { value, validateField } = this.props;
    const { fieldName } = this.state;
    if (_get(prevProps, 'form.errors') !== _get(this.props, 'form.errors')) {
      this._setFieldError();
    }
    if (value !== prevProps.value && _isFunction(validateField)) {
      validateField(fieldName, value);
    }
  }
  _setFieldError = () => {
    const { fieldName } = this.state;
    const { form = {} } = this.props;
    const { errors } = form;
    const error = _get(errors, fieldName, '');
    this.setState({ fieldError: error });
  };

  renderInputComponent = inputProps => {
    const {
      classes,
      inputRef = () => {},
      ref,
      type = 'text',
      ...other
    } = inputProps;
    const {
      label,
      value,
      helperText,
      placeholder,
      error,
      disabled,
      loading = false
    } = this.props;
    const { fieldName, fieldError } = this.state;
    const defaultHelperText = null;

    return (
      <TextField
        fullWidth
        variant="outlined"
        name={fieldName}
        label={label}
        value={value}
        debounce={0}
        onMouseEnter={this._handleShowError}
        onFocus={this._handleShowError}
        onMouseOut={this._handleHideError}
        placeholder={placeholder}
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.focused
          }
        }}
        InputProps={{
          endAdornment: loading && <LoadingIndicator endInputLoading={true} />,
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            root: classes.outlinedInput,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline
          }
        }}
        // eslint-disable-next-line
        inputProps={{
          type
        }}
        {...other}
        FormHelperTextProps={{
          classes: {
            root: classes.formHelperTextRoot
          }
        }}
        error={!!fieldError || !!error}
        helperText={helperText || defaultHelperText}
        disabled={disabled || false}
      />
    );
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);
    const moreInfo = suggestion.info;
    const { classes, rowStyle } = this.props;
    return (
      <Fragment>
        <MenuItem classes={{ root: classes.menuItemRoot }} selected={isHighlighted} component="div">
          <div className={classnames(classes.suggestionRow, rowStyle)}>
            {suggestion.icon && (
              <span className={classes.suggestionIcon}>
                <suggestion.icon fontSize="inherit" color="inherit" />
              </span>
            )}
            {parts.map((part, index) => {
              return part.highlight ? (
                <span key={String(index)} className={classes.boldWeight}>
                  {part.text}
                </span>
              ) : (
                <strong key={String(index)} className={classes.normalWeight}>
                  {part.text}
                </strong>
              );
            })}
            {moreInfo && (
              <span className={classes.suggestionMore}>{moreInfo}</span>
            )}
          </div>
        </MenuItem>
      </Fragment>
    );
  };

  _handleGetSuggestions = async value => {
    const inputValue = value.trim();
    const {
      getSuggestions,
      maxResults = AUTOCOMPLETE_MAX_RESULTS
    } = this.props;
    const suggestions = await getSuggestions(value);
    this.setState({
      suggestions:
        inputValue.length === 0 ? [] : suggestions.slice(0, maxResults)
    });
  };

  debouncedLoadSuggestions = _debounce(this._handleGetSuggestions, 500);

  handleSuggestionsFetchRequested = ({ value }) => {
    this.debouncedLoadSuggestions(value);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = name => (_event, { newValue }) => {
    const { onChange, validateField, form = null } = this.props;
    const { fieldError, fieldName } = this.state;
    if (fieldError && _isFunction(validateField)) {
      validateField(name, newValue);
    }
    onChange(newValue);
    this.setState({
      [name]: newValue
    });
    if (form) {
      form.setFieldTouched(fieldName);
    }
  };
  _handleBlur = ({ target: { value, name } }) => {
    const { validateField } = this.props;
    if (_isFunction(validateField)) {
      validateField(name, value);
    }
  };
  _handleSuggestionSelected = (_event, { suggestion }) => {
    const { onSuggestionSelected } = this.props;
    onSuggestionSelected(suggestion);
  };
  _handleShowError = event => {
    const { fieldError } = this.state;
    if (fieldError) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };
  _handleHideError = () => {
    this.setState({ anchorEl: null });
  };
  _handleGetSuggestionValue = value => {
    const { getSuggestionValue } = this.props;
    if (_isFunction(getSuggestionValue)) {
      return getSuggestionValue(value);
    } else {
      return value.label;
    }
  };
  render() {
    const {
      classes,
      value,
      autoWidth = false,
      type,
      focusInputOnSuggestionClick = true
    } = this.props;
    const { fieldError, anchorEl } = this.state;
    const fieldValue = value !== this.state.value ? value : this.state.value;
    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this._handleGetSuggestionValue,
      onSuggestionSelected: this._handleSuggestionSelected,
      renderSuggestion: this.renderSuggestion,
      focusInputOnSuggestionClick: focusInputOnSuggestionClick
    };
    const defaultWidth = this.popperNode ? this.popperNode.clientWidth : null;
    const resultWidth = autoWidth ? autoWidth : defaultWidth;

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            value: fieldValue,
            onChange: this.handleChange('firstName'),
            onBlur: this._handleBlur,
            inputRef: node => {
              this.popperNode = node;
            },
            InputLabelProps: {
              classes: {
                root: classes.label,
                focused: classes.focused
              }
            },
            type
          }}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
            containerOpen: classes.suggestionContainer,
            container: classes.suggestionContainer
          }}
          renderSuggestionsContainer={options => (
            <Popper
              anchorEl={this.popperNode}
              open={Boolean(options.children)}
              className={classes.suggestionPopper}
              placement={'bottom-start'}
            >
              <Paper
                square
                {...options.containerProps}
                style={{
                  width: resultWidth
                }}
              >
                {options.children}
              </Paper>
            </Popper>
          )}
        />
        <FieldValidationPopup anchorEl={anchorEl} message={fieldError} />
      </div>
    );
  }
}

export default withStyles(combineStyles(AutocompleteStyles, TypographyStyles))(
  Autocomplete
);
