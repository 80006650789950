import SalesReturnList from './SalesReturnList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchSalesRevoke,
  changeReturnStatus,
  returnArticlesSalesMassive
} from '../../../actions/sales_revoke_actions';
import { fetchGeographicLocations } from '../../../actions/geographic_location_actions';
function mapStateToProps(state) {
  const { salesRevoke, meta } = state.salesRevoke;
  return {
    salesRevoke,
    meta,
    requestSend: state.requestState.requestSend,
    requestChangeReturnStatusSend: state.requestState.requestChangeReturnStatusSend,
    canReviewReturnSale: state.authentication.currentUser.canReviewReturnSale,
    canApproveReturnSale: state.authentication.currentUser.canApproveReturnSale,
    canRevokeReturnSale: state.authentication.currentUser.canRevokeReturnSale,
    geographicLocations: state.geographicLocation.list,
    companyName: state.globalSetting.orgData.company_name,
    canReturnArticlesSalesMassive:
      state.authentication.currentUser.canReturnArticlesSalesMassive
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSalesRevoke: bindActionCreators(fetchSalesRevoke, dispatch),
    changeReturnStatus: bindActionCreators(changeReturnStatus, dispatch),
    returnArticlesSalesMassive: bindActionCreators(
      returnArticlesSalesMassive,
      dispatch
    ),
    fetchGeographicLocations: bindActionCreators(
      fetchGeographicLocations,
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SalesReturnList);
