import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import GridItem from '../../../shared/GridItem';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import { Formik, Field } from 'formik';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../ModalStyles';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import CustomDialog from '../../../shared/Modals/CustomDialog';
import ChippedAutocompleteInput from '../../../shared/Inputs/ChippedAutocomplete';

class FormModal extends Component {
  state = {
    supplier: ''
  };

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  _getSuggestions = (array, value) => {
    const items = array.map(item => ({
      label: `${item.id} - ${item.name || item.description}`,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');
    return suggestions;
  }

  _handleSave = params => {
    if (this._configIsNew()) {
      this.props.createConfig({
        fields: params.values,
        perPage: params.rowsPerPage
      });
    } else {
      this.props.updateConfig({
        fields: params.values,
        perPage: params.rowsPerPage,
        page: params.currentPage
      });
    }
  }

  _modalProps = (handleSubmit, onDelete, handleReset, onClose, values) => {
    if (!this._configIsNew() && this.props.canDestroyLocationExceptions) {
      return {
        onConfirm: () => handleSubmit(),
        title: 'Modificar Excepción',
        buttonText: 'Guardar',
        buttonSecondaryText: true
          ? 'Eliminar'
          : null,
        onConfirmSecondary: () => {
          onDelete({ id: values.id });
        }
      };
    }
    return {
      title: 'Nueva Excepción',
      buttonText: 'Guardar',
      onConfirm: () => handleSubmit()
    };
  };

  _nameVerification = string => {
    return string === '--'
      ? ''
      : string
  }

  _configIsNew = () => {
    const { config } = this.props;
    return config.suppliers === undefined;
  }


  render() {
    const {
      onClose,
      onDelete,
      classes,
      submitLoading,
      modalDestroyLoading,
      geographicLocations,
      suppliers,
      rowsPerPage,
      currentPage,
      config
    } = this.props;
    return (
      <div>
        <Formik
          initialValues={{
            ...config,
            suppliers: config.suppliers ? config.suppliers.map(s => ({ label: s.name, value: s.id })) : []
          }}
          onSubmit={values => this._handleSave({ values, rowsPerPage, currentPage })}
          onReset={(values, actions) => {
            actions.resetForm();
          }}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
            handleReset,
            dirty
          }) => {
            return (
              <CustomDialog
                open={true}
                loading={submitLoading}
                loadingSecondary={modalDestroyLoading}
                disabled={submitLoading || !dirty || (!values.geographicLocationId &&
                  !values.supplierId)}
                disableBackdropClick={false}
                headerSecondary
                onClose={() => {
                  handleReset();
                  onClose();
                }}
                {...this._modalProps(handleSubmit, onDelete, handleReset, onClose, values)}
                classes={{
                  root: classes.dialogRoot
                }}
                confirmDialog={true}
                titleConfirm="Mensaje Importante"
                textConfirm="Esta a punto de eliminar esta excepción"
              >
                <Fragment>
                  <Grid container spacing={16}>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="geographic_location"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <AutocompleteInput
                              id="geographic_location"
                              name="geographic_location"
                              label="Zona Geográfica"
                              margin="normal"
                              variant="outlined"
                              error={touched.geographicLocationId && !!errors.geographicLocationId}
                              helperText={touched.geographicLocationId && errors.geographicLocationId}
                              value={this._nameVerification(values.geographicLocationName)}
                              onChange={change => {
                                setFieldValue('geographicLocationId', null);
                                setFieldValue('geographicLocationName', change);
                              }}
                              suggestions={geographicLocations}
                              getSuggestions={value =>
                                this._getSuggestions(geographicLocations, value)
                              }
                              onSuggestionSelected={geographicLocation => {
                                setFieldValue('geographicLocationName', geographicLocation.label);
                                setFieldValue('geographicLocationId', geographicLocation.value);
                              }}
                              field={field}
                              form={form}
                              classes={{
                                root: classes.autocomplete
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="suppliers"
                        render={({ field, form }) => {
                          return (
                            <ChippedAutocompleteInput
                              getSuggestions={value =>
                                this._getSuggestions(suppliers, value)
                              }
                              value={this.state.supplier}
                              values={values.suppliers}
                              onChange={evt => {
                                const {
                                  target: { value }
                                } = evt;
                                this.setState({ supplier: value });
                                handleChange(evt);
                              }}
                              field={field}
                              form={form}
                              label={'Proveedores'}
                              classes={{
                                root: classes.autocomplete
                              }}
                            />);
                        }}
                      />
                    </GridItem>
                  </Grid>
                </Fragment>
              </CustomDialog>
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(FormModal);