/* eslint-disable complexity */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Modal from '../../../shared/Modals/Modal';
import GridItem from '../../../shared/GridItem';
import Button from '@material-ui/core/Button';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../../helpers/mapListToOptions';
import { Formik, Field } from 'formik';
import _forEach from 'lodash/forEach';
import { withStyles } from '@material-ui/core/styles';
import validationSchema from './FinancialDetailValidations';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import classnames from 'classnames';
import _get from 'lodash/get';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import { styles } from './styles';

class FinancialDetailEditCreate extends Component {
  state = {
    financialPlan: '',
    category: ''
  };

  componentDidUpdate(prevProps) {
    if (prevProps.apiErrors !== this.props.apiErrors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.apiErrors));
    }
  }

  _checkEmpty = value => {
    if (!value || value === '--' || value === 'TODOS') {
      return null;
    } else {
      return parseInt(value);
    }
  };

  _handleSave = values => {
    const { detailedPlan, onSave } = this.props;
    const {
      category,
      financialPlan,
      contractType,
      location,
      saleChannel,
      stratum
    } = values;
    const params = {
      categoryId: this._checkEmpty(category),
      geographicLocationId: this._checkEmpty(location),
      contractTypeId: this._checkEmpty(contractType),
      contractStratumId: this._checkEmpty(stratum),
      saleChannelId: this._checkEmpty(saleChannel),
      financialPlanId: this._checkEmpty(financialPlan),
      isNew: detailedPlan.isNew,
      id: _get(detailedPlan, 'id', null)
    };
    onSave(params);
  };

  _getSuggestions = async (array, value) => {
    const items = array.map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v));
    return errors;
  };

  _fixContractTypes = contractTypes => {
    return contractTypes.filter(contract_type => {
      return contract_type.id != -1
    });
  };

  _fixLocations = locations => {
    let loc = locations.filter(location => {
      return location.id != -1
    });
    return [{
          key: 'nullValue',
          id: null,
          description: 'TODOS'
        },
        ... loc
      ]
  };

  render() {
    const {
      classes,
      open,
      loading,
      financialPlanNames = [],
      categoryNames = [],
      contractTypeNames = [],
      locationNames = [],
      saleChannelNames = [],
      detailedPlan = {}
    } = this.props;

    const newContraTypesNames = this._fixContractTypes(contractTypeNames);

    const newLocationNames = this._fixLocations(locationNames);

    return (
      <div>
        <Formik
          ref={ref => (this.formik = ref)}
          enableReinitialize
          initialValues={{
            financialPlan: detailedPlan.planID || '',
            category: detailedPlan.categoryID || '',
            categoryName: detailedPlan.category || '',
            contractType: detailedPlan.contractTypeID || 'TODOS',
            location: detailedPlan.locationID || '',
            locationName: detailedPlan.location || '',
            saleChannel: detailedPlan.saleChannelID || 'TODOS',
            stratum: detailedPlan.contractTypeID
              ? detailedPlan.stratumID || 'TODOS'
              : '',
            isNew: detailedPlan.isNew
          }}
          validationSchema={validationSchema}
          onSubmit={this._handleSave}
          onReset={(values, actions) => {
            actions.resetForm();
            this._handleOnClose();
          }}
          render={({
            values,
            errors,
            touched,
            dirty,
            handleSubmit,
            handleChange,
            handleReset,
            setFieldValue
          }) => (
            <Modal
              open={open}
              onClose={this._handleOnClose}
              classes={{
                footer: classes.modalFooter
              }}
              body={
                <Fragment>
                  <div className={classes.titleWrapper}>
                    <p className={classnames(classes.headline6, classes.title)}>
                      {values.isNew
                        ? 'Nueva Asociación'
                        : 'Modificar Asociación'}
                    </p>
                  </div>
                  <Grid container spacing={16}>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="financialPlan"
                        onChange={handleChange}
                        render={() => {
                          return (
                            <SimpleSelect
                              name={'financialPlan'}
                              value={values.financialPlan}
                              label={'Plan de Financiación'}
                              options={mapListToOptions(financialPlanNames, {
                                property: 'description',
                                withId: false
                              })}
                              onChange={change => {
                                setFieldValue(
                                  'financialPlan',
                                  change.target.value
                                );
                              }}
                              error={
                                touched.financialPlan &&
                                (!!errors.financialPlan ||
                                  !!errors.financial_plan ||
                                  !!errors.financial_plan_id)
                              }
                              helperText={
                                touched.financialPlan &&
                                (errors.financialPlan ||
                                  errors.financial_plan ||
                                  errors.financial_plan_id)
                              }
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="category"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <AutocompleteInput
                              id="category"
                              name="category"
                              label="Categoría"
                              margin="normal"
                              variant="outlined"
                              error={
                                touched.category &&
                                (!!errors.category || !!errors.category_id)
                              }
                              helperText={
                                touched.category &&
                                (errors.category || errors.category_id)
                              }
                              value={
                                this.state.category || values.categoryName || ''
                              }
                              onChange={change => {
                                setFieldValue('categoryName', '');
                                setFieldValue('category', '');
                                this.setState({ category: change });
                              }}
                              suggestions={categoryNames}
                              getSuggestions={value =>
                                this._getSuggestions(categoryNames, value)
                              }
                              onSuggestionSelected={item => {
                                setFieldValue('categoryName', item.label);
                                setFieldValue('category', item.value);
                              }}
                              field={field}
                              form={form}
                              classes={{
                                root: classes.autocomplete
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="contractType"
                        onChange={handleChange}
                        render={() => {
                          return (
                            <SimpleSelect
                              name={'contractType'}
                              value={values.contractType}
                              label={'Tipo de Contrato'}
                              options={[
                                {
                                  key: 'nullValue',
                                  id: null,
                                  value: 'TODOS',
                                  label: 'TODOS'
                                },
                                ...mapListToOptions(newContraTypesNames, {
                                  withId: false
                                })
                              ]}
                              onChange={evt => {
                                setFieldValue('contractType', evt.target.value);
                                setFieldValue('stratum', '');
                              }}
                              error={
                                touched.contractType &&
                                (!!errors.contractType ||
                                  !!errors.contract_type ||
                                  !!errors.contractTypeId)
                              }
                              helperText={
                                touched.contractType &&
                                (errors.contractType ||
                                  errors.contract_type ||
                                  errors.contractTypeId)
                              }
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="location"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <AutocompleteInput
                              id="location"
                              name="location"
                              label="Ubicación Geográfica"
                              margin="normal"
                              variant="outlined"
                              error={
                                touched.location &&
                                (!!errors.location ||
                                  !!errors.geographic_location ||
                                  !!errors.geographicLocationId)
                              }
                              helperText={
                                touched.location &&
                                (errors.location ||
                                  errors.geographic_location ||
                                  errors.geographicLocationId)
                              }
                              value={
                                this.state.location || values.locationName || ''
                              }
                              onChange={change => {
                                setFieldValue('locationName', '');
                                setFieldValue('location', '');
                                this.setState({ location: change });
                              }}
                              suggestions={newLocationNames}
                              getSuggestions={value =>
                                this._getSuggestions(newLocationNames, value)
                              }
                              onSuggestionSelected={item => {
                                setFieldValue('locationName', item.label);
                                setFieldValue('location', item.value);
                              }}
                              field={field}
                              form={form}
                              classes={{
                                root: classes.autocomplete
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="saleChannel"
                        onChange={handleChange}
                        render={() => {
                          return (
                            <SimpleSelect
                              name={'saleChannel'}
                              value={values.saleChannel}
                              label={'Canal de Venta'}
                              options={[
                                {
                                  key: 'nullValue',
                                  id: null,
                                  value: 'TODOS',
                                  label: 'TODOS'
                                },
                                ...mapListToOptions(saleChannelNames, {
                                  withId: false
                                })
                              ]}
                              onChange={handleChange}
                              error={
                                touched.saleChannel &&
                                (!!errors.saleChannel ||
                                  !!errors.sale_channel ||
                                  !!errors.saleChannelId)
                              }
                              helperText={
                                touched.saleChannel &&
                                (errors.saleChannel ||
                                  errors.sale_channel ||
                                  errors.saleChannelId)
                              }
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem item xs={12} sm={6}>
                      <Field
                        name="stratum"
                        onChange={handleChange}
                        render={() => {
                          return (
                            <SimpleSelect
                              disabled={
                                !values.contractType ||
                                values.contractType === 'TODOS'
                              }
                              name={'stratum'}
                              value={values.stratum}
                              label={'Estrato'}
                              options={[
                                {
                                  key: 'nullValue',
                                  id: null,
                                  value: 'TODOS',
                                  label: 'TODOS'
                                },
                                ...mapListToOptions(
                                  values.contractType !== 'TODOS' &&
                                    !!values.contractType
                                    ? newContraTypesNames.find(
                                        contract =>
                                          contract.id == values.contractType
                                      ).contract_strata
                                    : [],
                                  { withId: false }
                                )
                              ]}
                              onChange={handleChange}
                              error={
                                touched.stratum &&
                                (!!errors.stratum ||
                                  !!errors.contract_stratum ||
                                  !!errors.contractStratumId)
                              }
                              helperText={
                                touched.stratum &&
                                (errors.stratum ||
                                  errors.contract_stratum ||
                                  errors.contractStratumId)
                              }
                            />
                          );
                        }}
                      />
                    </GridItem>
                  </Grid>
                </Fragment>
              }
              footer={
                <Fragment>
                  <div className={classes.spacer} />
                  <Button
                    className={classes.button}
                    onClick={handleReset}
                    color={'secondary'}
                    classes={{ label: classes.buttonLabel }}
                  >
                    {'Cancelar'}
                  </Button>
                  <ProgressButton
                    onClick={handleSubmit}
                    className={classes.button}
                    classes={{
                      disabled: classes.buttonDisabled,
                      label: classes.buttonLabel
                    }}
                    color={'secondary'}
                    disabled={!dirty || loading}
                    isSubmitting={loading}
                  >
                    {'Guardar'}
                  </ProgressButton>
                </Fragment>
              }
            />
          )}
        />
      </div>
    );
  }
}

FinancialDetailEditCreate.propTypes = {
  open: PropTypes.bool
};

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(FinancialDetailEditCreate);
