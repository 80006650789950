import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Formik, Field, validateYupSchema, yupToFormErrors } from 'formik';
import DialogForm from '../../../shared/Modals/DialogForm';
import { validationSchema } from './UserValidations';
import Checkbox from '../../../shared/Inputs/Checkbox';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import mapListToOptions from '../../../../helpers/mapListToOptions';
import { doctypes } from '../../../../constants/enums';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import GridItem from '../../../shared/GridItem';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';

export class UserCreateEdit extends Component {
  state = {
    supplier: '',
    selectedSupplier: false,
    role: '',
    selectedRole: false,
    selectedRoleId: null,
    isAdmin: this.props.currentUser.canCreateUsers,
    isCoordinador: this.props.currentUser.canCreateSellers,
    canCreateNonAdminUsers: this.props.currentUser.canCreateNonAdminUsers,
    isHugeSurfaceCoordinator: this.props.currentUser
      .canCreateSellerAsHugeSurfaceCoordinator,
    freelanceID: null,
    hugeSurfaceCoordinatorId: null
  };

  handleOnClose = () => {
    const { onClose, clearCreationInfo, userClearError } = this.props;
    onClose();
    clearCreationInfo();
    userClearError();
  };

  getRolesIds = rolesFilter => {
    const { roles } = this.props;
    const filtered = roles.filter(item => {
      return rolesFilter.some(role => {
        return role === item.name;
      });
    });
    const ids = filtered.map(item => item.id);
    return ids;
  };

  componentDidMount() {
    this.setState({
      freelanceID: this.getRolesIds(this.props.freelanceRoles),
      hugeSurfaceCoordinatorId: this.getRolesIds(
        this.props.hugeSurfaceCoordinatorRoles
      )
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this.handleErrors(this.props.errors));
    }
  }

  handleOnValidate = async user => {
    const { onValidate } = this.props;
    const {
      roleId,
      supplierId,
      freelance,
      firstName,
      lastName,
      email,
      typeOfIdentification,
      identification
    } = user;
    const isFreelance = freelance || this.state.freelanceID.includes(roleId);
    onValidate({
      isFreelance,
      firstName,
      lastName,
      email,
      supplierId: supplierId || null,
      typeOfIdentification,
      identification,
      roleId: Number(roleId)
    });
  };

  handleOnSave = async user => {
    const { onSave } = this.props;
    const {
      roleId,
      supplierId,
      freelance,
      firstName,
      lastName,
      email,
      typeOfIdentification,
      identification
    } = user;
    const isFreelance = freelance || this.state.freelanceID.includes(roleId);
    onSave({
      isFreelance,
      firstName: firstName.toUpperCase(),
      lastName: lastName.toUpperCase(),
      email: email.toLowerCase(),
      supplierId: supplierId || null,
      typeOfIdentification,
      identification,
      roleId: Number(roleId)
    });
  };

  getSuggestions = async value => {
    const suppliers = this.props.suppliers
      .filter(item => item.active === true)
      .map(supplier => ({
        label: supplier.name,
        value: supplier.id
      }));

    const suggestions = filterListFuzzyly(value, suppliers, 'label', 6);

    return Promise.resolve(suggestions);
  };

  getRoleSuggestions = async value => {
    const roles = this.props.roles
      .filter(item => item.name !== 'sign_in')
      .map(role => ({
        label: role.displayName,
        value: role.id
      }));

    const suggestions = filterListFuzzyly(value, roles, 'label');
    return Promise.resolve(suggestions);
  };

  handleCheckBox(values, setFieldValue) {
    setFieldValue('freelance', !values.freelance);
    if (!values.freelance) {
      setFieldValue('supplierName', '');
      setFieldValue('supplierId', '');
      setFieldValue('roleId', this.props.salesmanRolesIds[1].toString());
      this.setState({
        supplier: '',
        selectedSupplier: false
      });
    } else {
      setFieldValue('roleId', this.props.salesmanRolesIds[0].toString());
    }
  }

  handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  userTypeOptions = () => {
    const types = [
      {
        key: true,
        value: true,
        label: 'Sí'
      },
      {
        key: false,
        value: false,
        label: 'No'
      }
    ];
    return types;
  };

  handleRoles = (setFieldValue, change) => {
    const actualRol = this.props.roles.filter(
      item => item.displayName == change
    );
    setFieldValue('roleName', '');
    setFieldValue('roleId', '');
    this.setState({
      role: change,
      selectedRole: false,
      selectedRoleId: null
    });
    if (
      actualRol.length > 0 &&
      (this.state.freelanceID.includes(actualRol[0].id) ||
        this.state.hugeSurfaceCoordinatorId.includes(actualRol[0].id))
    ) {
      setFieldValue('supplierName', '');
      setFieldValue('supplierId', '');
      this.setState({
        supplier: '',
        selectedSupplier: false
      });
    }
  };

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      creationInfo,
      serverErrors = {},
      roles,
      suppliers
    } = this.props;

    const modifiedRoles = roles.filter(item => item.name !== 'sign_in');
    const modifiedSuppliers = suppliers.filter(item => item.active === true);
    const isCoordinador = (this.state.isCoordinador || this.state.isHugeSurfaceCoordinator);

    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        validationSchema={validationSchema(this.props.osfMaxIdLength)}
        initialValues={{
          supplierName: '',
          supplierId: '',
          firstName: '',
          lastName: '',
          email: '',
          roleName: '',
          roleId: isCoordinador && modifiedRoles.length == 0 ? this.props.salesmanRolesIds[0].toString() : '',
          typeOfIdentification: 'Cédula',
          identification: '',
          freelance: false
        }}
        onSubmit={(values, actions) => {
          console.log(values);
          if (!creationInfo) {
            this.handleOnValidate(values);
          } else {
            this.handleOnSave(values);
          }
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this.handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          handleReset,
          dirty,
          setFieldValue
        }) => (
          <DialogForm
            title={
              <p className={classnames(classes.headline6, classes.title)}>
                {!creationInfo
                  ? 'Crear Usuario'
                  : 'Datos del usuario a registrar'}
              </p>
            }
            open={open}
            onClose={this.handleOnClose}
            disableBackdropClick={true}
            footer={
              <Fragment>
                <Button
                  className={classes.button}
                  onClick={
                    creationInfo ? this.props.clearCreationInfo : handleReset
                  }
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {creationInfo ? 'Atrás' : 'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={!dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Enviar'}
                </ProgressButton>
              </Fragment>
            }
          >
            {!creationInfo ? (
              <Fragment>
                <Grid container spacing={16}>
                  <GridItem xs={6}>
                    <SimpleSelect
                      className={classes.formControl}
                      allowMargin={false}
                      value={values.typeOfIdentification}
                      onChange={handleChange}
                      name="typeOfIdentification"
                      label="Tipo de identificación"
                      options={mapListToOptions(doctypes, {}, true)}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <OutlinedTextField
                      name="identification"
                      onChange={handleChange}
                      className={classes.formControl}
                      value={values.identification}
                      label={'Identificación'}
                      fullWidth
                      error={
                        (touched.identification && !!errors.identification) ||
                        (serverErrors && !!serverErrors.identification)
                      }
                      helperText={
                        (touched.identification && errors.identification) ||
                        (serverErrors &&
                          serverErrors.identification &&
                          serverErrors.identification)
                      }
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <OutlinedTextField
                      name="firstName"
                      onChange={handleChange}
                      className={classes.formControl}
                      value={values.firstName}
                      label={'Nombres'}
                      fullWidth
                      error={
                        (touched.firstName && !!errors.firstName) ||
                        (serverErrors && !!serverErrors.firstName)
                      }
                      helperText={
                        (touched.firstName && errors.firstName) ||
                        (serverErrors &&
                          serverErrors.firstName &&
                          serverErrors.firstName)
                      }
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <OutlinedTextField
                      name="lastName"
                      onChange={handleChange}
                      className={classes.formControl}
                      value={values.lastName}
                      label={'Apellidos'}
                      fullWidth
                      error={
                        (touched.lastName && !!errors.lastName) ||
                        (serverErrors && !!serverErrors.lastName)
                      }
                      helperText={
                        (touched.lastName && errors.lastName) ||
                        (serverErrors &&
                          serverErrors.lastName &&
                          serverErrors.lastName)
                      }
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <OutlinedTextField
                      name="email"
                      onChange={handleChange}
                      className={classes.formControl}
                      value={values.email}
                      label={'Correo Eléctronico'}
                      fullWidth
                      type={'email'}
                      error={
                        (touched.email && !!errors.email) ||
                        (serverErrors && !!serverErrors.email)
                      }
                      helperText={
                        (touched.email && errors.email) ||
                        (serverErrors &&
                          serverErrors.email &&
                          serverErrors.email)
                      }
                    />
                  </GridItem>
                  {(this.state.isAdmin ||
                    this.state.canCreateNonAdminUsers ||
                    this.state.isHugeSurfaceCoordinator) && (
                      <GridItem xs={12}>
                        <Field
                          name="supplierId"
                          onChange={handleChange}
                          render={({ field, form }) => {
                            return (
                              <AutocompleteInput
                                id="supplierId"
                                name="supplierId"
                                label="Proveedor (opcional)"
                                margin="normal"
                                variant="outlined"
                                disabled={
                                  this.state.freelanceID.includes(
                                    this.state.selectedRoleId
                                  ) ||
                                  this.state.hugeSurfaceCoordinatorId.includes(
                                    this.state.selectedRoleId
                                  ) ||
                                  values.freelance
                                }
                                error={
                                  (touched.supplierId && !!errors.supplierId) ||
                                  (serverErrors && !!serverErrors.supplierId)
                                }
                                helperText={
                                  (touched.supplierId && errors.supplierId) ||
                                  (serverErrors &&
                                    serverErrors.supplierId &&
                                    serverErrors.supplierId)
                                }
                                value={this.state.supplier}
                                onChange={change => {
                                  setFieldValue('supplierName', '');
                                  setFieldValue('supplierId', '');
                                  this.setState({
                                    supplier: change,
                                    selectedSupplier: false
                                  });
                                }}
                                suggestions={modifiedSuppliers}
                                getSuggestions={this.getSuggestions}
                                onSuggestionSelected={supplier => {
                                  setFieldValue('supplierName', supplier.label);
                                  setFieldValue('supplierId', supplier.value);
                                  this.setState({ selectedSupplier: true });
                                }}
                              />
                            );
                          }}
                        />
                      </GridItem>
                    )}
                  {(!isCoordinador && (this.state.isAdmin ||
                    this.state.canCreateNonAdminUsers)) && (
                      <GridItem xs={12}>
                        <Field
                          name="roleId"
                          render={({ field, form }) => {
                            return (
                              <AutocompleteInput
                                id="roleId"
                                name="roleId"
                                label="Rol"
                                margin="normal"
                                variant="outlined"
                                value={this.state.role}
                                error={
                                  (touched.roleId && !!errors.roleId) ||
                                  (serverErrors && !!serverErrors.roleId)
                                }
                                helperText={
                                  (touched.roleId && errors.roleId) ||
                                  (serverErrors &&
                                    serverErrors.roleId &&
                                    serverErrors.roleId)
                                }
                                onChange={change =>
                                  this.handleRoles(setFieldValue, change)
                                }
                                suggestions={modifiedRoles}
                                getSuggestions={this.getRoleSuggestions}
                                onSuggestionSelected={role => {
                                  this.setState({
                                    selectedRole: true,
                                    selectedRoleId: role.value
                                  });
                                  setFieldValue('roleName', role.label);
                                  setFieldValue('roleId', role.value);
                                }}
                              />
                            );
                          }}
                        />
                      </GridItem>
                    )}
                  {isCoordinador && (
                    <GridItem xs={12}>
                      {modifiedRoles.length > 0 ? (
                        <Field
                          name="roleId"
                          render={() => {
                            return (
                              <AutocompleteInput
                                id="roleId"
                                name="roleId"
                                label="Rol"
                                margin="normal"
                                variant="outlined"
                                value={this.state.role}
                                error={
                                  (touched.roleId && !!errors.roleId) ||
                                  (serverErrors && !!serverErrors.roleId)
                                }
                                helperText={
                                  (touched.roleId && errors.roleId) ||
                                  (serverErrors &&
                                    serverErrors.roleId &&
                                    serverErrors.roleId)
                                }
                                onChange={change =>
                                  this.handleRoles(setFieldValue, change)
                                }
                                suggestions={modifiedRoles}
                                getSuggestions={this.getRoleSuggestions}
                                onSuggestionSelected={role => {
                                  this.setState({
                                    selectedRole: true,
                                    selectedRoleId: role.value
                                  });
                                  setFieldValue('roleName', role.label);
                                  setFieldValue('roleId', role.value);
                                }}
                              />
                            );
                          }}
                        />
                      ) : (
                        <Field
                          onChange={handleChange}
                          render={() => {
                            return (
                              <Checkbox
                                name={'freelance'}
                                value={values.freelance}
                                label={'Asesor Freelance'}
                                onChange={() =>
                                  this.handleCheckBox(values, setFieldValue)
                                }
                                helperText={
                                  '*Una vez seleccionado el tipo de asesor no se puede revertir el cambio'
                                }
                              />
                            );
                          }}
                        />
                      )}
                    </GridItem>
                  )}
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid container spacing={8}>
                  <GridItem xs={6}>
                    <p className={classes.headline7}>Nombre:</p>
                  </GridItem>
                  <GridItem xs={6}>
                    <p style={{ margin: '0' }}>
                      {creationInfo.firstName} {creationInfo.lastName}
                    </p>
                  </GridItem>
                  <GridItem xs={6}>
                    <p className={classes.headline7}>Tipo de identificación:</p>
                  </GridItem>
                  <GridItem xs={6}>
                    <p style={{ margin: '0' }}>
                      {creationInfo.typeOfIdentification}
                    </p>
                  </GridItem>
                  <GridItem xs={6}>
                    <p className={classes.headline7}>Identificación:</p>
                  </GridItem>
                  <GridItem xs={6}>
                    <p style={{ margin: '0' }}>{creationInfo.identification}</p>
                  </GridItem>
                  <GridItem xs={6}>
                    <p className={classes.headline7}>Email:</p>
                  </GridItem>
                  <GridItem xs={6}>
                    <p style={{ margin: '0' }}>{creationInfo.email}</p>
                  </GridItem>
                  {creationInfo.supplierName && (
                    <>
                      <GridItem xs={6}>
                        <p className={classes.headline7}>Proveedor:</p>
                      </GridItem>
                      <GridItem xs={6}>
                        <p style={{ margin: '0' }}>
                          {creationInfo.supplierName}
                        </p>
                      </GridItem>
                    </>
                  )}
                  <GridItem xs={6}>
                    <p className={classes.headline7}>Rol:</p>
                  </GridItem>
                  <GridItem xs={6}>
                    <p style={{ margin: '0' }}>{creationInfo.roleName}</p>
                  </GridItem>
                </Grid>
              </Fragment>
            )}
          </DialogForm>
        )}
      />
    );
  }
}

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  formControl: {
    marginTop: '0px'
  }
});

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(UserCreateEdit);
