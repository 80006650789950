import * as Yup from 'yup';
const email = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/,
      'Nombre y apellido'
    )
    .required('El campo es requerido'),
  identificationType: Yup.string().required('El campo es requerido'),
  identification: Yup.string()
    .when('identificationType', {
      is: '1',
      then: Yup.string()
        .matches(
          /^[0-9]+$/,
          'Tipo de documento no admite caracteres especiales o letras.'
        )
        .required('El campo es requerido')
    })
    .when('identificationType', {
      is: '2',
      then: Yup.string()
        .matches(
          /^[A-Z-a-z-0-9-]+$/,
          'Tipo de documento no admite caracteres especiales.'
        )
        .required('El campo es requerido')
    })
    .when('identificationType', {
      is: '3',
      then: Yup.string()
        .matches(
          /^[A-Z-a-z-0-9-]+$/,
          'Tipo de documento no admite caracteres especiales.'
        )
        .required('El campo es requerido')
    })
    .when('identificationType', {
      is: '5',
      then: Yup.string()
        .matches(
          /^[0-9-]+$/,
          'Tipo de documento solo admite números y el caracter - '
        )
        .required('El campo es requerido')
    }),
  contract: Yup.number()
    .required('El campo es requerido')
    .min(1, 'Ingresa un contrato válido.'),
  email: Yup.string()
    .when('registerScheduleWithEmail', {
      is: true,
      then: Yup.string().email('Debe ingresar un email valido.')
        .required('El campo es requerido')
    })
    .when('registerScheduleWithEmail', {
      is: false,
      then: Yup.string().email('Debe ingresar un email valido.')
    }),
  mobile: Yup.string()
    .required('El campo es requerido')
    .matches(/^([0-9]{10})$/, 'El número ingresado debe tener 10 dígitos.'),
  line: Yup.string().required('El campo es requerido'),
  subline: Yup.string().required('El campo es requerido'),
  pointOfSaleName: Yup.string().required('El campo es requerido'),
  receptionMedium: Yup.string().required('El campo es requerido')
});
