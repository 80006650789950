import _pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

const queryData = filters => {
    const {
        hasCoDebtor,
        contract,
        identification,
        identificationType,
        coDebtorContract,
        coDebtorIdentification,
        coDebtorIdentificationType,
        debtorBirthday,
        coDebtorBirthday,
        debtorDocumentExpeditionDate,
        coDebtorDocumentExpeditionDate,
        mobile,
        mobileConfirmedAt
    } = filters;

    const sanitizedFilters = {
        debtorContractNumber: contract.toString(),
        debtorIdentification: identification.toString(),
        debtorIdentificationType: identificationType,
        debtorBirthdate: moment(debtorBirthday).format('YYYY/MM/DD'),
        debtorExpeditionDate: moment(debtorDocumentExpeditionDate).format('YYYY/MM/DD'),
        cosignerContractNumber: hasCoDebtor === true ? coDebtorContract.toString() : null,
        cosignerIdentification: hasCoDebtor === true ? coDebtorIdentification.toString() : null,
        cosignerIdentificationType: hasCoDebtor === true ? coDebtorIdentificationType : null,
        cosignerBirthdate: hasCoDebtor === true ? moment(coDebtorBirthday).format('YYYY/MM/DD') : null,
        cosignerExpeditionDate: hasCoDebtor === true ? moment(coDebtorDocumentExpeditionDate).format('YYYY/MM/DD') : null,
        mobile,
        mobileConfirmedAt: mobileConfirmedAt
    };

    return _pickBy(sanitizedFilters, filter => !!filter);
};

export default queryData;