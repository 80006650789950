import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import parseQuerystring from '../../../helpers/parseQuerystring';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import TextFieldInput from '../../shared/Inputs/TextField';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';

import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import { statusOptions } from './dropDownOptions';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';

class PromissoryFilters extends PureComponent {
  state = {
    promissoryNumber: '',
    corporation: '',
    status: 'todos'
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    // console.log('query', query);
    this.setState({
      ...this.state,
      ...query
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _getSuggestions = async value => {
    const corporations = this.props.corporations.map(corporation => ({
      label: corporation.name,
      value: corporation.id
    }));

    const suggestions = filterListFuzzyly(value, corporations, 'label');

    return Promise.resolve(suggestions);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter),
      'promissoryNumber',
      'corporation',
      'status'
    );
    return filterParams;
  };

  render() {
    const { classes, corporations } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <AutocompleteInput
                  id="corporation"
                  name="corporation"
                  label="Nombre Empresa"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.corporation || ''}
                  onChange={change => {
                    if (this.state.corporation && !change) {
                      this._handleSelectChange({
                        target: { name: 'corporation', value: '' }
                      });
                    }
                    this.setState({
                      corporation: change,
                      selectedCorporation: false
                    });
                  }}
                  suggestions={corporations}
                  getSuggestions={this._getSuggestions}
                  onSuggestionSelected={corporation => {
                    this._handleSelectChange({
                      target: { name: 'corporation', value: corporation.label }
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldInput
                  id="promissoryNumber"
                  name="promissoryNumber"
                  value={this.state.promissoryNumber}
                  onChange={this._handleChange}
                  label="# Pagaré dentro del rango"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type={'number'}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <OutlinedSelectChippedInput
                  label={'Estado'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'status'}
                  options={statusOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'Status'}
                  value={this.state.status}
                  withChip={false}
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

PromissoryFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(PromissoryFilters);
