export const Stores = {
    electroao: {
        baseValue: 'Base',
        category: 'Categoria',
        code: 'CodigoProducto',
        description: 'Descripcion',
        discount: 'ValorDescuento',
        image: 'Imagen',
        ivaValue: 'ValorIva',
        name: 'NombreProducto',
        stock: 'Existencia',
        totalValue: 'ValorTotal',
    },
    samir: {
        baseValue: 'precio',
        category: 'desc_grupo',
        code: 'referencia',
        description: 'caracteristicas',
        discount: 'descuento',
        image: 'imagen',
        ivaValue: 'iva',
        name: 'nombre_web',
        nameAlt: 'nombre',
        shortDescription: 'descripcion_corta',
        stock: 'stock',
        totalValue: 'precio_total',
        weight: 'peso',
        weightUnit: 'unidad_de_peso'
    }
};

export const samirWarehouses = {
    bod_samir8: 'Barranquilla Zona Calle 30 Cra. 8',
    bod_samir1: 'Barranquilla Zona Centro',
    bod_ferremayor: 'Barranquilla Zona Centro',
    bod_ferresakk: 'Barranquilla Zona Centro',
    bod_bodemayor: 'Barranquilla Zona Centro',
    bod_luzkal: 'Barranquilla Zona Centro',
    bod_caribeverde: 'Barranquilla Zona Circunvalar',
    bod_plaza: 'Barranquilla Zona Cordialidad',
    bod_norte: 'Barranquilla Zona Norte',
    bod_simonbolivar: 'Barranquilla Zona Simón Bolívar',
    bod_cienaga: 'Ciénaga (Magdalena)',
    bod_malambo: 'Malambo (Atlántico)',
    bod_santotomas: 'Santo Tomás (Atlántico)',
    bod_soledad: 'Soledad (Atlántico)',
    bod_valledupar: 'Valledupar (Cesar)',
};