const styles = theme => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing.unit * 3,
    position: 'relative',
    '&:hover .menu': {
      display: 'block'
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },
  detail: {
    flex: 1,
    paddingRight: theme.spacing.unit * 6
  },
  name: {
    marginBottom: [theme.spacing.unit * 0.5, '!important'],
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  },
  fileName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 140
  },
  actionButton: {
    color: theme.palette.primary.main,
    padding: 2,
    marginRight: theme.spacing.unit * 1.5,
    '&:last-child': {
      marginRight: 0
    }
  },
  menuItemRoot: {
    color: 'rgba(12,31,44,0.87)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    backgroundColor: '#FFF !important',
    height: '40px',
    padding: [[0, theme.spacing.unit * 2]],
    '&:hover': {
      backgroundColor: 'whitesmoke !important'
    },
  },
  menuItemSelected: {
    backgroundColor: '#ebebeb !important'
  },
  actions: {
    position: 'absolute',
    right: -12,
    top: 0,
    display: 'none'
  },
  fileTypeContainer: {
    marginRight: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileTypeIcon: {
    color: 'rgba(12,31,44,0.38)'
  },
  optionsIcon: {
    transform: 'rotate(90deg)',
    color: theme.palette.secondary.main
  },
  menu: {
    width: 128
  }
});

export default styles;
