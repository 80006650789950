import React, { PureComponent, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from './PointsOfSale.list';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';

const styles = theme => ({
  tag: {
    margin: 0,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: 'inline-block',
    padding: [[theme.spacing.unit * 0.5, theme.spacing.unit * 1]],
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#FEB00B',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:last-child': {
      marginRight: 0
    }
  },
  contents: {
    maxHeight: '298px !important',
    overflowY: 'auto !important',
    padding: '0 !important'
  },
  noItems: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '68px',
    margin: 0,
    padding: '0 24px !important',
  }
});

export class PointsOfSale extends PureComponent {
  render() {
    const { classes, isLoading, pointsOfSale = [], canViewPOS } = this.props;

    return (
      <FormItemContainer
        title={'Puntos de Venta'}
        customContents={classes.contents}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div>
            {!pointsOfSale.length ? (
              <p className={classes.noItems}>
                No hay puntos de venta asociados al proveedor
              </p>
            ) : (
              <List pointsOfSale={pointsOfSale} canViewPOS={canViewPOS} />
            )}
          </div>
        )}
      </FormItemContainer>
    );
  }
}

export default withStyles(
  combineStyles(ButtonStyles, TypographyStyles, styles)
)(PointsOfSale);
