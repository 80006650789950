import { ROLES_SET, ROLES_SET_LIST } from '../constants/action_constants';
import { newAlert } from './alert_actions';
import RequestService from '../services/request_service';
import { sendRequest, completeRequest } from './request_state';
const requestService = new RequestService();

//* PURE ACTION FUNCTIONS
function setRoles(items) {
  return {
    type: ROLES_SET,
    data: items
  };
}

function setAllRoles(items) {
  return {
    type: ROLES_SET_LIST,
    data: items
  };
}

//* VIEW ACTION FUNCTIONS
export function fetchRoles(params = {}) {
  return dispatch => {
    dispatch(sendRequest('Roles'));
    return requestService.get('roles', params).then(
      payload => {
        dispatch(setRoles(payload.data));
        dispatch(completeRequest('Roles'));
      },
      error => {
        error.json().then(e => {
          e.message && dispatch(newAlert('error', 'ERROR:', e.message));
        });
        dispatch(completeRequest('Roles'));
      }
    );
  };
}

export function fetchAllRoles(params = {}) {
  return dispatch => {
    dispatch(sendRequest('Roles'));
    return requestService.get('roles/list_roles', params).then(
      payload => {
        dispatch(setAllRoles(payload.data));
        dispatch(completeRequest('Roles'));
      },
      error => {
        error.json().then(e => {
          e.message && dispatch(newAlert('error', 'ERROR:', e.message));
        });
        dispatch(completeRequest('Roles'));
      }
    );
  };
}
