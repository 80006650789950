import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import TextFieldInput from '../../../shared/Inputs/TextField';
import DateFieldInput from '../../../shared/Inputs/DateField';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import _forEach from 'lodash/forEach';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import CampaignFormValidation from './CampaignForm.validation';
import ChippedAutocompleteInput from '../../../shared/Inputs/ChippedAutocomplete';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  customForm: {
    width: '70%',
    maxHeight: '90%',
    overflow: 'auto',
  },
});

export class CampaignFormModal extends Component {
  state = {
    supplier: '',
    category: '',
    geographic_location: '',
    sale_channel: '',
    contract_stratum: ''
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnSave = async campaign => {
    const { onSave } = this.props;
    onSave(campaign);
  };

  _getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  _handleDelete = (id) => {
    const { onDelete } = this.props;
    onDelete(id);
  };

  render() {
    const { classes, open = true, isSubmitting, campaign = {} } = this.props;

    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        validationSchema={CampaignFormValidation}
        initialValues={{
          ...campaign,
          suppliers: campaign.suppliers.map(s => ({ label: s.name, value: s.id })),
          geographicLocations: campaign.geographicLocations.map(gl => ({ label: gl.description, value: gl.id })),
          categories: campaign.categories.map(c => ({ label: c.name, value: c.id })),
          saleChannels: campaign.saleChannels.map(sc => ({ label: sc.name, value: sc.id })),
          contractStratus: campaign.contractStratus.map(cs => ({ label: cs.name, value: cs.id }))
        }}
        onSubmit={(values, actions) => {
          this._handleOnSave(values);
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              footer: classes.modalFooter,
              customForm: classes.customForm
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {values.isNew ? 'Crear Campaña' : 'Editar Campaña'}
                  </p>
                </div>
                <Grid container spacing={16}>
                  <GridItem container xs={12} sm={6}>
                    <GridItem xs={12}>
                    <Field
                      name="name"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <TextFieldInput
                            value={values.name}
                            label={'Nombre'}
                            fullWidth
                            field={field}
                            form={form}
                          />
                        );
                      }}
                    />
                    </GridItem>
                    <GridItem xs={12}>
                    <Field
                      name="minimumValue"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <TextFieldInput
                            value={values.minimumValue}
                            label={'Valor mínimo'}
                            fullWidth
                            field={field}
                            form={form}
                            startAdornment={ <AttachMoneyIcon /> }
                            mask={'currency'}
                          />
                        );
                      }}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                    <Field
                      name="minimumQuota"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <TextFieldInput
                            value={values.minimumQuota}
                            label={'Cuotas mínimas'}
                            fullWidth
                            field={field}
                            form={form}
                            type='number'
                          />
                        );
                      }}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                    <Field
                      name="maxQuota"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <TextFieldInput
                            value={values.maxQuota}
                            label={'Cuotas máximas'}
                            fullWidth
                            field={field}
                            form={form}
                            type='number'
                          />
                        );
                      }}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                    <Field
                      name="startAt"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <DateFieldInput
                            value={values.startAt || null}
                            label={'Fecha de Inicio'}
                            fullWidth
                            field={field}
                            form={form}
                          />
                        );
                      }}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                    <Field
                      name="endAt"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <DateFieldInput
                            value={values.endAt || null}
                            label={'Fecha de Finalización'}
                            fullWidth
                            field={field}
                            form={form}
                          />
                        );
                      }}
                    />
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <GridItem xs={12} sm={12}>
                    <Field
                      name="suppliers"
                      render={({ field, form }) => (
                        <ChippedAutocompleteInput
                          getSuggestions={value =>
                            this._getSuggestions('suppliers', value)
                          }
                          value={this.state.supplier}
                          values={values.suppliers}
                          onChange={evt => {
                            const {
                              target: { value }
                            } = evt;
                            this.setState({ supplier: value });
                            handleChange(evt);
                          }}
                          field={field}
                          form={form}
                          label={'Proveedores'}
                        />
                      )}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                    <Field
                      name="geographicLocations"
                      render={({ field, form }) => (
                        <ChippedAutocompleteInput
                          getSuggestions={value =>
                            this._getSuggestions('geographicLocations', value)
                          }
                          value={this.state.geographic_location}
                          values={values.geographicLocations}
                          onChange={evt => {
                            const {
                              target: { value }
                            } = evt;
                            this.setState({ geographic_location: value });
                            handleChange(evt);
                          }}
                          field={field}
                          form={form}
                          label={'Ubicaciones Geográficas'}
                        />
                      )}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                    <Field
                      name="categories"
                      render={({ field, form }) => (
                        <ChippedAutocompleteInput
                          getSuggestions={value =>
                            this._getSuggestions('categories', value)
                          }
                          value={this.state.category}
                          values={values.categories}
                          onChange={evt => {
                            const {
                              target: { value }
                            } = evt;
                            this.setState({ category: value });
                            handleChange(evt);
                          }}
                          field={field}
                          form={form}
                          label={'Categorias'}
                        />
                      )}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <Field
                        name="saleChannels"
                        render={({ field, form }) => (
                          <ChippedAutocompleteInput
                            getSuggestions={value =>
                              this._getSuggestions('saleChannels', value)
                            }
                            value={this.state.sale_channel}
                            values={values.saleChannels}
                            onChange={evt => {
                              const {
                                target: { value }
                              } = evt;
                              this.setState({ sale_channel: value });
                              handleChange(evt);
                            }}
                            field={field}
                            form={form}
                            label={'Canales de venta'}
                          />
                        )}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <Field
                        name="contractStratus"
                        render={({ field, form }) => (
                          <ChippedAutocompleteInput
                            getSuggestions={value =>
                              this._getSuggestions('contractStrata', value)
                            }
                            value={this.state.contract_stratum}
                            values={values.contractStratus}
                            onChange={evt => {
                              const {
                                target: { value }
                              } = evt;
                              this.setState({ contract_stratum: value });
                              handleChange(evt);
                            }}
                            field={field}
                            form={form}
                            label={'Estratos'}
                          />
                        )}
                      />
                    </GridItem>
                  </GridItem>
                </Grid>
              </Fragment>
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={!dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Guardar'}
                </ProgressButton>
              </Fragment>
            }
          />
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(CampaignFormModal);
