import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';

import RegisterSinister from './RegisterSinister';
import {
  registerSiniter,
  toggleSaleAction
} from '../../../actions/sales_actions';

function mapStateToProps(state) {
  return {
    open: state.sales[`actionRegister-SinisterOpen`],
    requestSend: state.requestState['requestRegister-SinisterSend'],
    sinisterRegisterCauses: state.globalSetting.orgData.sinister_register_causes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    registerSiniter: bindActionCreators(registerSiniter, dispatch),
    toggleSaleAction: bindActionCreators(toggleSaleAction, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSinister);
