const styles = theme => ({
  tableRoot: {
    boxShadow: 'none',
  },
  table: {
    minWidth: '100% !important',
    '& tr td, & tr th': {
      borderBottom: 0
    },
    '& tr:first-child th': {
      borderTop: '1px solid rgba(255, 255, 255, 0.12)'
    },
    '& tr:last-child td': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)'
    }
  },
  tableHeaderRow: {
    height: 48
  },
  tableCellHeader: {
    // color: 'rgba(255,255,255,0.6) !important',
    // color: 'red',
    fontWeight: '400 !important'
    // '& span': {
    //   '&:focus, &:hover': {
    //     // color: 'rgba(255,255,255,0.6) !important'
    //     color: 'rgba(0,0,0,0.6)'
    //   }
    // }
  },
  title: {
    flex: 1,
    color: 'rgba(12,31,44,0.87)',
    fontSize: 16,
    marginTop: 0,
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  tableCell: {
    // color: '#FFFFFF !important',
    color: 'rgba(0,0,0,0.6)',
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    position: 'relative',
    padding: 0,
    paddingRight: theme.spacing.unit * 1.5,
    '&:first-child': {
      paddingLeft: theme.spacing.unit * 3
    },
    '&:last-child': {
      paddingRight: theme.spacing.unit * 1.5
    },
    '& span': {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  redTableCell: {
    color: '#DB4845 !important'
  },
  tableCellTooltip: {
    maxWidth: '150%',
  },
  noDataCell: {
    // color: '#FFF',
    color: 'rgba(0,0,0,0.6)',
    borderBottom: 0
  },
  selectRateRadioButton: {
    padding: '0 !important'
  },
  radioButtonRoot: {
    // color: 'rgba(255,255,255,0.6) !important'
    color: 'rgba(0,0,0,0.6)'
  },
  radioButtonChecked: {
    // color: 'rgb(255,255,255) !important'
    color: 'rgba(0,0,0,0.6)'
  },
  rowSelected: {
    '& td': {
      borderTop: 0,
      backgroundColor: theme.palette.primary.main
    },
    '& td:first-child': {
      borderRadius: '2px 0 0 0'
    },
    '& td:last-child': {
      borderRadius: '0 2px 0 0'
    }
  },
  tableRowDetailRow: {
    '& td': {
      background: 'transparent',
      padding: '0 !important'
    }
  },
  tooltipButton: {
    padding: 0,
    color: 'rgba(255,255,255,0.6)',
    '&:hover': {
      color: '#FFFFFF'
    },
    position: 'absolute',
    right: 20,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  tooltip: {
    backgroundColor: '#2A3C47',
    color: '#FFFFFF !important',
    // color: 'rgba(0,0,0,0.6)',
    padding: theme.spacing.unit * 1
  },
  tooltipPopper: {
    top: `-${theme.spacing.unit}px !important`
  },
  pagingPanel: {
    padding: 0,
    height: 24,
    marginTop: theme.spacing.unit * 2,
    // '& div, & span, & button, & svg': {
    //   // color: 'rgba(255,255,255,0.6)'
    //   color: 'rgba(12, 31, 44, 0.6)'
    // },
    '& > div > span': {
      fontSize: '12px',
      lineHeight: 16,
      letterSpacing: '0.1px',
      paddingRight: theme.spacing.unit * 3
    }
  },
  tableCellRoot: {
    padding: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:first-child': {
      paddingLeft: theme.spacing.unit * 3
    },
    '&:last-child': {
      paddingRight: theme.spacing.unit * 1.5,
      textAlign: 'left'
    },
    '& span': {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    ['@media (max-width:899px)']: {
      padding: [[theme.spacing.unit * 1.75, theme.spacing.unit * 3], '!important'],
      display: 'block',
      textAlign: 'right',
      '&::before':{
        content: 'attr(data-label)',
        float: 'left',
        color: 'rgba(12,31,44,0.6)',
        fontSize: 12,
        lineHeight: '16px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        letterSpacing: '0.1px'
      },
      '&:last-child': {
        textAlign: 'right !important'
      }
    }
  }
});

export default styles;
