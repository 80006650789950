import LoginPage from './LoginPage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../../../actions/authentication_actions';
import { newAlert } from '../../../actions/alert_actions';
import {
  fetchSessionData,
  confirmTokenRecaptchaInvisible,
  recaptchaValidationActivation
} from '../../../actions/session_actions';

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  return {
    loggedIn,
    loading: state.requestState.requestLoginSend,
    loadingRecaptchaInvisible:
      state.requestState.requestConfirmTokenRecaptchaInvisibleSend,
    companyCode: state.sessionData.companyName,
    confirmCaptcha: state.sessionData.confirmCaptcha,
    isRecaptchaValidationActive: state.sessionData.isRecaptchaValidationActive
  };
}
function mapDispatchToProps(dispatch) {
  return {
    login: bindActionCreators(login, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchSessionData: bindActionCreators(fetchSessionData, dispatch),
    recaptchaValidationActivation: bindActionCreators(recaptchaValidationActivation, dispatch),
    confirmTokenRecaptchaInvisible: bindActionCreators(
      confirmTokenRecaptchaInvisible,
      dispatch
    )
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
