import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import QueryQuota from './QueryQuota';
import { newAlert } from '../../../actions/alert_actions';
import {
  queryQuotas,
  closeQueryQuotaModal,
  resetQueryData
} from '../../../actions/query_quota_actions';

function mapStateToProps(state) {
  return {
    loading: state.requestState.requestQueryQuotasSend,
    serverErrors: state.requestState.requestErrorsOnQueryQuotas,
    quotas: state.queryQuotas.quotas,
    isOpen: state.queryQuotas.isQueryQuotaModalOpen,
    hasSearched: state.queryQuotas.hasSearched,
    clientIsRollover: state.queryQuotas.clientIsRollover
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    queryQuotas: bindActionCreators(queryQuotas, dispatch),
    closeModal: bindActionCreators(closeQueryQuotaModal, dispatch),
    resetQueryData: bindActionCreators(resetQueryData, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QueryQuota);
