import { SET_PRODUCT_IDS, SET_SECURE_PRODUCT_MOVEMENTS } from '../constants/action_constants';
import { sendRequest, completeRequest } from './request_state';
import RequestService from '../services/request_service';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { movementsFilterSanitizer, secureProductSanitizer } from '../helpers/sanitizers/secureProductMovementsSanitizer';
import snakecaseKeys from 'snakecase-keys';
import { newAlert, serverStatusError } from './alert_actions';

const requestService = new RequestService('node');

//* PURE ACTION FUNCTIONS (This ones call the reducer)
function success(data, meta) {
  return {
    type: SET_SECURE_PRODUCT_MOVEMENTS,
    data: data,
    meta: meta
  };
}

function setProduct(data, meta) {
  return {
    type: SET_PRODUCT_IDS,
    data: data,
    meta: meta
  };
}

// * INDEX *
export const fetchSecureProductMovements = (params = {}) => async dispatch => {
  dispatch(sendRequest("SecureProductMovementList"));
  history.replace(`/secure-product-movement?${stringifyQuery(params)}`);
  try {
    const response = await requestService.get(
      'secure-product-movements',
      movementsFilterSanitizer(params),
      true
    );
    const payload = snakecaseKeys(response);
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest("SecureProductMovementList"));
  }
};

export const getSecureProducts = (params = {}) => async dispatch => {
  dispatch(sendRequest('Contracts'));
  try {
    const payload = await requestService.post(
      'contracts/getSecureProductByContract',
      params,
      true
    );
    if (payload.data.length == 0) {
      dispatch(newAlert('error', 'ERROR:', 'El contrato no tiene productos afianzados.'));
      dispatch(setProduct(payload.data));
      return;
    }
    dispatch(setProduct(payload.data));
  } catch (errors) {
    return errors.json().then(e => {
      if (e.errors) {
        e.errors.base && dispatch(newAlert('error', 'ERROR:', e.errors.base));
        dispatch(setProduct([]));
      }
      return e;
    });
  } finally {
    dispatch(completeRequest('Contracts'));
  }
};

export const resetProductData = () => async dispatch => {
  dispatch(setProduct([]));
};

export const updateSecureProduct = (body = {}) => async dispatch => {
  dispatch(sendRequest('SecureProductMovements'));
  try {
    const selectedProduct = body.product_data.filter(product => product.productId == body.product_id);
    const payload = await requestService.post(
      'secure-product-movements',
      secureProductSanitizer({ block: selectedProduct[0].block, body }),
      true
    );
    dispatch(newAlert('success', 'sucess', payload.data.response));
    history.replace(`/secure-product-movement/update`);
    dispatch(fetchSecureProductMovements(body.params));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('SecureProductMovements'));
  }
};
