import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/styles';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonStyles from '../../../styles/button';
import LoadingIndicator from '../../shared/LoadingIndicator';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

const emptyStateImg = require('../../../assets/images/aspiring-supplier-list-empty-state.svg');

class SupplierProposalsList extends Component {
  state = {
    rowsPerPage: sessionStorage.getItem('SupplierProposalListRowsPerPage')
      ? parseInt(sessionStorage.getItem('SupplierProposalListRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    page: sessionStorage.getItem('SupplierProposalListPage')
      ? parseInt(sessionStorage.getItem('SupplierProposalListPage'), 0)
      : 0
  }

  componentDidMount() {
    this.props.fetchSupplierProposals({
      page: this.state.page,
      rowsPerPage: this.state.rowsPerPage
    });
  }

  render() {
    const {
      proposals,
      classes,
      currentUser,
      fetchingProposals,
      fetchSupplierProposals,
      totalCount,
      goToProposal,
      goToCreatePage
    } = this.props;
    const {
      page, rowsPerPage
    } = this.state;
    const showEmptyState = _isEmpty(proposals);
    return (
      <Grid container justify='center'>
        <Grid container item xs={10}>
          <Header
            classes={classes}
            showEmptyState={showEmptyState}
            currentUser={currentUser}
            goToCreatePage={goToCreatePage}
          />
          {
            fetchingProposals ? (
              <Grid item>
                <LoadingIndicator />
              </Grid>
            ) : (
              showEmptyState ? (
                <EmptyState
                  classes={classes}
                  currentUser={currentUser}
                  goToCreatePage={goToCreatePage}
                />
              ) : (
                <Grid item xs={12}>
                  <Table
                    name={proposals.length === 1 ? 'Propuesta' : 'Propuestas'}
                    columns={columns}
                    data={mapData(proposals)}
                    onChangePage={
                      newPage => {
                        fetchSupplierProposals({
                          page: newPage,
                          rowsPerPage
                        })
                        sessionStorage.setItem(
                          'SupplierProposalListPage', newPage
                        );
                        this.setState({ page: newPage });
                      }
                    }
                    onChangePageRowsPerPage={
                      newRowsPerPage => {
                        fetchSupplierProposals({
                          page,
                          rowsPerPage: newRowsPerPage
                        })
                        sessionStorage.setItem(
                          'SupplierProposalListRowsPerPage', newRowsPerPage
                        );
                        this.setState({ rowsPerPage: newRowsPerPage });
                      }
                    }
                    page={page}
                    rowsPerPage={rowsPerPage}
                    totalCount={totalCount}
                    loading={fetchingProposals}
                    onClickOnRow={proposal => goToProposal({ id: proposal.id })}
                  />
                </Grid>
              )
            )
          }
        </Grid>
      </Grid>
    );
  };
}

const Header = props => {
  const { classes, showEmptyState, currentUser, goToCreatePage } = props;
  const showCreateButton = !showEmptyState && currentUser.canCreateSupplierProposals;
  return (
    <Grid container item justify='space-between' alignItems='center' className={classes.headerContainer} xs={12}>
      <Grid item xs={6}>
        <Typography variant='h3'>Propuestas</Typography>
      </Grid>
      {
        showCreateButton && (
          <Grid container item xs={3} justify='flex-end'>
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={() => goToCreatePage()}
            >
              Crear Propuesta
            </Button>
          </Grid>
        )
      }
    </Grid>
  );
};

const EmptyState = props => {
  const { classes, currentUser, goToCreatePage } = props;
  const canCreateProposals = currentUser.canCreateSupplierProposals;
  const message = canCreateProposals ? (
    'Aún no cuentas con propuestas creadas. Crea una para que la visualices aquí.'
  ) : 'Aún no cuentas con propuestas.';
  return (
    <Grid container item justify='center'>
      <Grid container item justify='center'>
        <img src={emptyStateImg} />
      </Grid>
      <Grid container item justify='center' xs={6} className={classes.emptyStateMessageContainer}>
        <Typography variant='h5' align='center'>{message}</Typography>
      </Grid>
      {
        canCreateProposals && (
          <Grid container item justify='center'>
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={() => goToCreatePage()}
            >
              Crear Propuesta
            </Button>
          </Grid>
        )
      }
    </Grid>
  );
};

export default withStyles(
  styles, ButtonStyles, { withTheme: true }
)(SupplierProposalsList);