import CorporationList from './CorporationList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchCorporations } from '../../../actions/corporations_actions';

function mapStateToProps(state) {
  return {
    corporations: state.corporations.corporations,
    meta: state.corporations.meta,
    requestSend: state.requestState.requestCorporationsSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCorporations: bindActionCreators(fetchCorporations, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CorporationList);
