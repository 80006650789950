const drawerWidth = 240;
const newsDrawerWidth = 400;
const toolbarHeight = 64;
const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    height: '100%'
  },
  list: {
    width: drawerWidth
  },
  drawer: {
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  titleText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#ffebee',
    display: 'inline-flex',
    alignItems: 'center'
  },
  appBar: {
    backgroundColor: process.env.REACT_APP_SENTRY_DSN ? theme.palette.primary.main : '#0071d9',
    marginLeft: drawerWidth,
    height: toolbarHeight,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  toolbar: {
    backgroundColor: process.env.REACT_APP_SENTRY_DSN ? theme.palette.primary.main : '#0071d9',
    height: toolbarHeight
  },
  toolbarTop: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end'
    }
  },
  drawerPaper: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: drawerWidth
  },
  newsDrawerPaper: {
    overflowX: 'hidden',
    width: newsDrawerWidth
  },
  paperAnchorDockedLeft: {
    borderRight: 0
  },
  content: {
    position: 'relative',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: toolbarHeight,
    padding: theme.spacing.unit * 3,
    overflowX: 'hidden'
  },
  header: {
    marginTop: 10,
    marginLeft: 20,
    height: 40,
    width: 90
  },
  menuText: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    lineHeight: '20px'
  },
  secondaryMenuText: {
    paddingLeft: `${theme.spacing.unit * 7}px !important`
  },
  menuDefaultColor: {
    color: 'rgba(255,255,255,0.54)'
  },
  menuRow: {
    borderRadius: '4px',
    height: 40,
    marginBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2.5,
    paddingRight: theme.spacing.unit * 2.5
  },
  primaryNavRoot: {
    paddingLeft: theme.spacing.unit * 0.5,
    paddingRight: theme.spacing.unit * 0.5,
    maxHeight: `calc(100vh - ${toolbarHeight}px)`,
    overflow: 'overlay'
  },
  primaryMenuActive: {
    borderRadius: '4px'
  },
  groupExpanded: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  },
  collapseMenuIcon: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  sliderPopUp: {
    margin: 10,
    padding: 0
  }
});

export default styles;
