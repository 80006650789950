export const statusOptions = {
  active: 'Activo',
  cancelled: 'Cancelado'
};

export const typeOptions = {
  funeral: 'Exequial',
  life: 'Practiseguro'
};

export const lifePlanOptions = {
  483: 'Plan Silver',
  484: 'Plan Gold', 
  505: 'Plan Diamond'
};
