import _pickBy from 'lodash/pickBy';
import { MANUAL_QUOTA_STATUS } from '../../constants/enums';

const manualQuotaFilters = filters => {
  const { contract, status, date_range, page, perPage } = filters;
  const range = date_range ? date_range.split(' - ') : [];

  const sanitizedFilters = {
    initialDate: range[0] || date_range,
    finalDate: range[1] || date_range,
    contractId: contract,
    page,
    perPage,
    status: MANUAL_QUOTA_STATUS[status],
    active: status === 'approved' ? true : (status === 'deactivated' ? false : undefined)
  };

  return _pickBy(sanitizedFilters, filter => !!filter || filter == 0);
};

export default manualQuotaFilters;
