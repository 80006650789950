const styles = theme => ({
  menuItem: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 'auto',
    padding: theme.spacing.unit,
    paddingBottom: 0,
    whiteSpace: 'normal',
    '&:last-child > .wrapper': {
      borderBottom: 0
    },
    cursor: 'default'
  },
  menuItemCursorPointer: {
    cursor: 'pointer'
  },
  wrapper: {
    borderBottom: '1px solid rgba(12,31,44,0.12)',
    paddingBottom: theme.spacing.unit * 2
  },
  subject: {
    color: theme.typography.color.main,
    margin: 0
  },
  footer: {
    color: 'rgba(12,31,44,0.38)',
    display: 'flex',
    margin: 0,
    marginTop: theme.spacing.unit * 0.5
  },
  notificationContainer: {
    marginRight: theme.spacing.unit * 2.5
  },
  notificationsCircle: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing.unit * 0.25,
    position: 'initial'
  },
  content: {
    display: 'flex',
    padding: theme.spacing.unit,
    paddingBottom: 0
  },
  createdAt: {
    display: 'inline-block',
    flex: 1
  },
  readMore: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    border: 0
  },
  noEvents: {
    pointerEvents: 'none'
  },
  //Status
  //Read
  notificationsCircleRead: {
    backgroundColor: 'rgba(12,31,44,0.24)'
  },
  //Done
  menuItemDone: {
    pointerEvents: 'none'
  },
  notificationsCircleDone: {
    backgroundColor: 'rgba(12,31,44,0.12)'
  },
  subjectDone: {
    color: 'rgba(12,31,44,0.38)'
  }
});

export default styles;
