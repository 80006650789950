import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import QueryScoringQuota from './QueryScoringQuota';
import { newAlert } from '../../../actions/alert_actions';
import {
  queryScoringQuotas,
  closeQueryScoringQuotaModal,
  resetQueryScoringData
} from '../../../actions/query_scoring_quota_action';
import { sendOtp, resetOtpInfoResponse, validateOtp } from '../../../actions/otp_validation_actions';

function mapStateToProps(state) {
  return {
    loading: state.requestState.requestQueryScoringQuotasSend,
    serverErrors: state.requestState.requestErrorsOnQueryScoringQuotas,
    quotas: state.queryScoringQuotas.scoringQuotas,
    isOpen: state.queryScoringQuotas.isQueryScoringQuotaModalOpen,
    hasSearched: state.queryScoringQuotas.hasSearched,
    categoriesUsedToScoringMaximumValue: state.globalSetting.orgData.categories_to_use_scoring_extra_quota_maximun_value,
    requestOtpSend: state.requestState.requestOtpSend,
    requestValidateOtpSend: state.requestState.requestValidateOtpSend,
    otpSent: state.otpValidation.validationSendResponse,
    mobileValidated: state.otpValidation.validationConfirmationResponse,
    otpMobileConfirmedAt: state.otpValidation.mobileConfirmedAt,
    canVerifyPhoneCode: state.authentication.currentUser.canVerifyPhoneCode,
    termsAndConditionsUrl: state.globalSetting.orgData.terms_and_conditions_url,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    queryScoringQuotas: bindActionCreators(queryScoringQuotas, dispatch),
    closeQueryScoringQuotaModal: bindActionCreators(closeQueryScoringQuotaModal, dispatch),
    resetQueryScoringData: bindActionCreators(resetQueryScoringData, dispatch),
    sendOtp: bindActionCreators(sendOtp, dispatch),
    validateOtp: bindActionCreators(validateOtp, dispatch),
    resetOtpInfoResponse: bindActionCreators(resetOtpInfoResponse, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QueryScoringQuota);
