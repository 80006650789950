import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProgressStyles from '../__styles__/ProgressButtonStyle';

class ProgressButton extends PureComponent {
  _handleClick = () => {
    const { disabled, isSubmitting } = this.props;
    if (!disabled && !isSubmitting) {
      this.props.onClick && this.props.onClick();
    }
  };
  _isDisabled = () => {
    const { disabled, isSubmitting } = this.props;
    if (disabled || isSubmitting) return true;
    return false;
  };
  render() {
    const { classes, children, isSubmitting, className, ...props } = this.props;
    const buttonType = props.variant;

    return (
      <div className={classes.root}>
        <Button
          {...props}
          onClick={this._handleClick}
          disabled={this._isDisabled()}
          classes={{
            disabled: classes.disabled,
            label: classes.label
          }}
          className={classnames(className, {
            [classes.noBorder]: isSubmitting
          })}
        >
          <span className={classnames({ [classes.hideText]: isSubmitting })}>
            {children}
          </span>
          {isSubmitting && (
            <div
              className={classnames(classes.progressContainer, {
                [classes.progressContainerContained]:
                  buttonType === 'contained' && props.color === 'primary',
                [classes.progressContainerContainedSecondary]:
                  buttonType === 'contained' && props.color === 'secondary',
                [classes.progressContainerOutlined]:
                  buttonType === 'outlined' && props.color === 'primary',
                [classes.progressContainerOutlinedSecondary]:
                  buttonType === 'outlined' && props.color === 'secondary'
              })}
            >
              <CircularProgress
                className={classnames(classes.progressIcon, {
                  [classes.progressIconContained]: buttonType === 'contained'
                })}
              />
            </div>
          )}
        </Button>
      </div>
    );
  }
}

export default withStyles(ProgressStyles)(ProgressButton);
