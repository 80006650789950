const styles = theme => ({
  root: {
    display: 'flex',
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  container: {
    marginBottom: 0
  },
  details: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  contactData: {
    wordBreak: 'break-all'
  },
  contactTitle: {
    marginBottom: '1.5rem'
  },
  contactSubtitle: {
    color: 'rgba(12,31,44,0.87) !important'
  },
  detailsTitle: {
    marginBottom: '25px !important'
  },
  divider: {
    width: '100%',
    margin: '20px 0'
  }
});

export default styles;