import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import parseQuerystring from '../../../helpers/parseQuerystring';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import TextFieldInput from '../../shared/Inputs/TextField';
import PickCustomDateModal from '../../shared/Inputs/PickCustomDateModal';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import CollapseWrapper from '../../shared/CollapseWrapper.js';
import mapListToOptions from '../../../helpers/mapListToOptions';

import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import _find from 'lodash/find';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';

import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import {
  statusOptions,
  originalDateOptions,
  dateOptions
} from './dropDownOptions';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';

const CUSTOM_DATE = 'pickCustomDate';

class HomeVisitFilters extends PureComponent {
  state = {
    order: '',
    name: '',
    contract: '',
    status: 'todas',
    registered: 'todas',
    openPickCustomDate: null,
    date_range: 'todas',
    customDate: CUSTOM_DATE,
    pointOfSale: ''
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    const custom = {};
    if (query && query.date_range) {
      const split = query.date_range.split(' - ');
      if (split[1]) custom.customDate = query.date_range;
    }
    this.setState({
      ...this.state,
      ...query,
      ...custom
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _handleClosePickCustomDate = () =>
    this.setState({ openPickCustomDate: null });

  _handleDateFilter = ({ target: { name, value } }) => {
    if (value === this.state.customDate) {
      this.setState({ openPickCustomDate: name });
    } else {
      this.setState(
        { [name]: value, customDate: CUSTOM_DATE },
        this._handleChangeFilter
      );
    }
  };
  _handleDateRangeFilter = ({ target: { name, value } }) => {
    const customDate = !_find(
      originalDateOptions,
      availableOption => availableOption.value === value
    )
      ? value
      : CUSTOM_DATE;
    this.setState(
      {
        [name]: value,
        openPickCustomDate: null,
        customDate
      },
      () => this._handleChangeFilter()
    );
  };
  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter && filter != 'todas'),
      'order',
      'name',
      'contract',
      'status',
      'registered',
      'date_range',
      'pointOfSale'
    );
    return filterParams;
  };

  _getSuggestions = async value => {
    const pointsOfSale = this.props.pointsOfSale.map(pointOfSale => ({
      label: pointOfSale.attributes.name,
      value: pointOfSale.id
    }));

    const suggestions = filterListFuzzyly(value, pointsOfSale, 'label');

    return Promise.resolve(suggestions);
  };

  render() {
    const { classes, pointsOfSale, saleChannels } = this.props;

    const saleChannelOptions = !!saleChannels
      ? [ { value: 'todas', title: 'Todas'}, ...saleChannels.map(saleChannel => ({
          value: saleChannel.id,
          title: saleChannel.name
        }))]
      : [];
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={this._handleChange}
                  label="Registrado por"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldInput
                  id="pointOfSale"
                  name="pointOfSale"
                  value={this.state.contract}
                  onChange={this._handleChange}
                  label="Punto de Venta"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="contract"
                  name="contract"
                  value={this.state.contract}
                  onChange={this._handleChange}
                  label="# Contrato"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <OutlinedSelectChippedInput
                  label={'Registrado en'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'registered'}
                  options={saleChannelOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'registered'}
                  value={this.state.registered}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Fecha'}
                  name={'date_range'}
                  options={dateOptions(
                    this.state.customDate,
                    this.state.date_range
                  )}
                  value={this.state.date_range}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  inputLabel={'Delivery Date'}
                  onChange={this._handleDateFilter}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Estado'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'status'}
                  options={statusOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'Status'}
                  value={this.state.status}
                  withChip={false}
                />
              </Grid>
            </Grid>
            {!!this.state.openPickCustomDate && (
              <PickCustomDateModal
                name={this.state.openPickCustomDate}
                onClose={this._handleClosePickCustomDate}
                onSave={this._handleDateRangeFilter}
                value={this.state.date_range}
              />
            )}
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

HomeVisitFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(HomeVisitFilters);
