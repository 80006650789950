import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import ActivityIndicator from '../../shared/ActivityIndicator';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import StepperButtons from '../../shared/StepperButtons';
import RefuseStepModal from '../../shared/Modals/RefuseStepModal';
import classnames from 'classnames';
import moment from 'moment-timezone';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import PromissoryPreview from '../PromissoryDetail/PromissoryPreview';
class DigitalSaleDocRevisionVoucher extends PureComponent {
  state = {
    isOpenedRefuseModal: false,
    causals: {}
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
    if (!this.props.pdfPromissoryUrl) {
      this.props.getDocumentRevisionImageBase64(
        this.props.docRevisionDetails.id,
        'pdf_voucher_url'
      );
    }
  }

  renderDocumentData = (data, classes) => {
    return (
      <Grid item sm={12} xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.wrapperData}>
            <Grid container spacing={16}>
              <Grid item sm={12} xs={12}>
                <p className={classnames(classes.subtitle3)}>Datos cliente</p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>No. pagaré:</p>
                <p className={classnames(classes.body2, classes.nameValue)}>
                  {data.promissoryCode}
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>Nombre:</p>
                <p className={classnames(classes.body2, classes.nameValue)}>
                  {data.debtorDetails.name + ' ' + data.debtorDetails.lastName}
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>
                  Tipo de identificación:
                </p>
                <p className={classnames(classes.body2)}>
                  {data.debtorDetails.typeOfIdentification}
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>identificación:</p>
                <p className={classnames(classes.body2)}>
                  {data.debtorDetails.identification}
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>
                  Ciudad de residencia:
                </p>
                <p className={classnames(classes.body2, classes.itemValue)}>
                  {data.debtorDetails.address.department +
                    ' - ' +
                    data.debtorDetails.address.location}
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>
                  Teléfono celular:
                </p>
                <p className={classnames(classes.body2, classes.itemValue)}>
                  {data.debtorDetails.mobile}
                </p>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    );
  };

  renderSaleData = (data, classes) => {
    return (
      <Grid item sm={12} xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.wrapperData}>
            <Grid container spacing={16}>
              <Grid item sm={12} xs={12}>
                <p className={classnames(classes.subtitle3)}>
                  Artículos y valores
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>Valor total:</p>
                <p className={classnames(classes.body2, classes.nameValue)}>
                  {data.saleTotalValue}
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>
                  Valor a pagar con otro medio de pago:
                </p>
                <p className={classnames(classes.body2, classes.nameValue)}>
                  {data.saleDownPayment}
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>
                  Valor a financiar:
                </p>
                <p className={classnames(classes.body2)}>
                  {data.saleValueToFinance}
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>No. de cuotas:</p>
                <p className={classnames(classes.body2)}>
                  {data.saleNumberOfQuotas}
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>
                  Valor mensual cuota:
                </p>
                <p className={classnames(classes.body2)}>
                  {data.saleQuotaAproxValue}
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <p className={classnames(classes.subtitle3)}>
                  Valor mensual seguro de vida:
                </p>
                <p className={classnames(classes.body2, classes.itemValue)}>
                  {data.saleMonthlyValueOfLifeInsurance}
                </p>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    );
  };

  onSubmit = () => {
    const { docRevisionDetails, process, routes } = this.props;
    const params = {
      step: 'voucher_data',
      type: 'approve'
    };
    this.props.digitalSalesDocumentRevisionCheckClient(
      docRevisionDetails.id,
      params,
      routes[this.nextStep],
      process
    );
  };

  _handleClose = () => {
    this.setState({ isOpenedRefuseModal: false });
  };

  refuseValidation = () => {
    this.setState({
      isOpenedRefuseModal: true,
      causals: this.props.docRevisionDetails.causals.filter(
        causal => causal.taskType === 5
      )
    });
  };

  _handleRefuse = causals => {
    const { docRevisionDetails, process, routes } = this.props;
    const params = {
      step: 'voucher_data',
      type: 'refuse',
      causals: causals.causals
    };
    this.props.digitalSalesDocumentRevisionCheckClient(
      docRevisionDetails.id,
      params,
      routes[this.nextStep],
      process
    );
  };
  render() {
    const { classes, docRevisionDetails, pdfVoucherUrl } = this.props;
    return (
      <div className={classes.container}>
        {docRevisionDetails ? (
          <Fragment>
            <div className={classes.headerDataClientValidation}>
              <span>{'Revisión de voucher de compra'}</span>
              <span>{`Contrato ${docRevisionDetails.contractId}`}</span>
            </div>
            <div className={classes.parentColumnGrid}>
              <div className={classes.childColumnGrid}>
                <Paper className={classes.leftColumnGrid}>
                  <div className={classes.wrapperData}>
                    {this.renderDocumentData(docRevisionDetails, classes)}
                  </div>
                </Paper>
                <Paper className={classes.leftColumnGrid}>
                  <div className={classes.wrapperData}>
                    {this.renderSaleData(docRevisionDetails, classes)}
                  </div>
                </Paper>
              </div>
              <div className={classes.childColumnGrid}>
                <Paper className={classes.paper}>
                  {pdfVoucherUrl ? (
                    <PromissoryPreview
                      url={'data:application/pdf;base64,' + pdfVoucherUrl}
                    />
                  ) : (
                    <ActivityIndicator />
                  )}
                </Paper>
              </div>
            </div>
            <MuiThemeProvider theme={materialTheme}>
              <Grid
                container
                spacing={24}
                alignItems="flex-start"
                className={classes.containerGrid}
              >
                {this.renderDocumentData(docRevisionDetails, classes)}
                {this.renderSaleData(docRevisionDetails, classes)}
                <Grid item sm={12} xs={12}>
                  <Paper className={classes.paper}>
                    <PromissoryPreview
                      url={
                        'data:application/pdf;base64,' +
                        docRevisionDetails.pdfVoucherUrl
                      }
                    />
                  </Paper>
                </Grid>
              </Grid>
            </MuiThemeProvider>
            <Paper className={classes.paper}>
              <StepperButtons
                onSubmit={this.onSubmit}
                goBack={this.props.goBack}
                alternativeButtonText={'Rechazar'}
                alternativeButtonCallback={this.refuseValidation}
                nextText={'Aceptar'}
                nextIsPrimary={true}
              />
            </Paper>
            {this.state.isOpenedRefuseModal &&
              this.state.causals.length > 0 && (
                <RefuseStepModal
                  causals={this.state.causals}
                  onClose={this._handleClose}
                  onSave={this._handleRefuse}
                />
              )}
          </Fragment>
        ) : (
          <ActivityIndicator />
        )}
      </div>
    );
  }
}

const materialTheme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 479,
      md: 680,
      lg: 1281,
      xl: 1479
    }
  }
});

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  containerGrid: {
    [theme.breakpoints.up(680)]: {
      display: 'none'
    },
    display: 'flex',
    marginTop: 10,
    marginBottom: 10
  },
  headerDataClientValidation: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2em'
  },
  parentColumnGrid: {
    [theme.breakpoints.up(680)]: {
      display: 'flex'
    },
    display: 'none',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10
  },
  childColumnGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexBasis: '50%',
    marginRight: 10
  },
  childColumnGridBig: {
    flexBasis: '50%',
    marginRight: 10,
    alignSelf: 'center',
    height: '100%'
  },
  paper: {
    padding: 5,
    height: '100%'
  },
  leftColumnGrid: {
    padding: 5,
    height: '100%',
    marginBottom: 10
  },
  wrapperData: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '10px'
  },
  validationImg: {
    width: '100%'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  liDivider: {
    listStyleType: 'none'
  },
  item: {
    margin: 20,
    textAlign: 'center'
  },
  headline: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  }
});

DigitalSaleDocRevisionVoucher.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(styles, LayoutStyles, TypographyStyles, SpacingStyles)
)(DigitalSaleDocRevisionVoucher);
