import moment from 'moment-timezone';
import _compact from 'lodash/compact';
import { statusOptions, typeOptions } from '../Insurance/options';

export const columns = [
  {
    title: '# Servicio',
    name: 'insurance'
  },
  {
    title: '# Contrato',
    name: 'contract'
  },
  {
    title: 'Fecha',
    name: 'registeredDate'
  },
  {
    title: 'Deudor',
    name: 'debtor'
  },
  {
    title: 'Identificación',
    name: 'identification'
  },
  {
    title: 'Tipo',
    name: 'type'
  },
  {
    title: 'Estado',
    name: 'status'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      insurance: datum.id,
      registeredDate:
        moment(datum.registeredAt)
          .locale('es')
          .format('DD MMM YYYY') || '--',
      debtor: _compact([datum.firstName, datum.lastName]).join(' ') || '--',
      contract: datum.contractId || '--',
      type: typeOptions[datum.type] || '--',
      identification: datum.identification || '--',
      status: statusOptions[datum.status] || '--'
    };
  });
