import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import { ImagePicker } from 'react-file-picker';
import Webcam from 'react-webcam';

//Shared
import Modal from '../../../shared/Modals/Modal';
import ProgressButton from '../../../shared/Buttons/ProgressButton';

//helpers
import combineStyles from '../../../../helpers/combineStyles';
import { limiteSize } from '../../../../helpers/limitImageSize';

//styles
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
  return {
    img: {
      maxWidth: '80%',
      maxHeight: '60vh'
    },
    firstH1: {
      fontSize: 'calc(1.3rem + 1vw) !important',
      lineHeight: 'initial',
      padding: '10vh 0 15vh 0 !important'
    },
    secondH1: {
      fontSize: 'calc(0.8rem + 0.3vw) !important',
      paddingBottom: '5vh !important'
    },
    infoH1: {
      paddingBottom: '0px !important',
      fontSize: '13px !important',
      color: '#EF1D1D !important',
      width: '480px !important',
      marginLeft: 'auto !important',
      marginRight: 'auto !important'
    },
    div: {
      textAlign: 'center',
      minHeight: '64%'
    },
    titleWrapper: {
      display: 'flex',
      padding: '33px 44px 30px 33px',
      position: 'relative'
    },
    title: {
      fontSize: '20px',
      letterSpacing: '1px',
      color: '#2c3c47',
      fontFamily: 'Roboto',
      fontWeight: '700',
      margin: 0
    },
    centerWebCam: {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'table'
    },
    paddingLeft: {
      marginLeft: '5%'
    },
    modalRoot: {
      maxWidth: '100%',
      width: '959px',
      boxSizing: 'border-box',
      minHeight: '34%'
    },
    modalBody: {
      display: 'contents'
    },
    modalFooter: {
      '@media (min-width: 600px)': {
        padding: '33px 44px 0px 33px'
      },
      margin: '0 0 2vh'
    }
  };
};

export class EditProfileModal extends PureComponent {
  state = {
    title: 'Añadir Fotografía',
    screenshot: null,
    visibilityMiddleButton: true,
    back: [],
    leftButton: {
      properties: {
        onClick: () => {
          this._handleOnClose();
        }
      },
      text: 'CANCELAR'
    },
    middleButton: {
      properties: {
        onClick: () => {
          this._handleOnClose();
        },
        variant: 'outlined'
      },
      text: 'CARGAR FOTO'
    },
    rightButton: {
      properties: {
        onClick: () => {
          this._handleCameraSession();
        },
        variant: 'contained'
      },
      text: 'USAR LA CÁMARA'
    }
  };

  buttons = {
    back: {
      properties: {
        onClick: () => {
          this._handleBackbutton();
        }
      },
      text: 'REGRESAR'
    },
    cancel: {
      properties: {
        onClick: () => {
          this._handleOnClose();
        }
      },
      text: 'CANCELAR'
    },
    camera: {
      properties: {
        onClick: () => {
          this._handleCameraSession();
        },
        variant: 'contained'
      },
      text: 'USAR LA CÁMARA'
    },
    cameraSession: {
      properties: {
        onClick: () => {
          this._handleTakePhoto();
        },
        variant: 'contained'
      },
      text: 'HACER FOTO'
    },
    chargePicture: {
      properties: {
        onClick: () => {
          this._handleOnClose();
        },
        variant: 'outlined'
      },
      text: 'CARGAR FOTO'
    },
    savePicture: {
      properties: {
        onClick: () => {
          this._handleOnSave();
        },
        variant: 'contained'
      },
      text: 'GUARDAR'
    }
  };

  render() {
    const { classes, open = true, canUpdatePictureProfile } = this.props;

    return canUpdatePictureProfile ? (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this._handleOnSave(values);
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={() => (
          <Modal
            className={classnames(classes.display)}
            open={open}
            classes={{
              root: classes.modalRoot,
              body: classes.modalBody,
              footer: classnames(classes.padding, classes.modalFooter)
            }}
            body={
              <Fragment>
                <div
                  className={classnames(classes.padding, classes.titleWrapper)}
                >
                  <p className={classnames(classes.title)}>
                    {this.state.title}
                  </p>
                </div>
                <div className={classnames(classes.div)}>
                  {this.state.cameraSession ? (
                    <div className={classes.centerWebCam}>
                      {this.state.screenshot ? (
                        <img
                          className={classes.img}
                          src={this.state.screenshot}
                        />
                      ) : (
                        <Webcam
                          height={326}
                          screenshotFormat="image/jpeg"
                          audio={false}
                          ref={node => (this.webcam = node)}
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      <h1
                        className={classnames(
                          classes.firstH1,
                          classes.headline6
                        )}
                      >
                        ¡Regálanos tu mejor sonrisa!
                      </h1>
                      <h1
                        className={classnames(
                          classes.body2Light,
                          classes.secondH1
                        )}
                      >
                        Haz o carga una foto.
                      </h1>
                      <h1
                        className={classnames(
                          classes.body2Light,
                          classes.infoH1
                        )}
                      >
                        Recuerda que el único formato valido es JPG, además el
                        tamaño máximo de ancho y largo es de 1000px y mínimo de
                        100px.
                      </h1>
                    </>
                  )}
                </div>
              </Fragment>
            }
            //botones
            footer={
              <>
                <Button
                  className={classes.button}
                  classes={{ label: classes.buttonLabel }}
                  color={'secondary'}
                  {...this.state.leftButton.properties}
                >
                  {this.state.leftButton.text}
                </Button>
                <div className={classes.spacer} />
                {this.state.visibilityMiddleButton ? (
                  <ImagePicker
                    extensions={['jpg']}
                    dims={{
                      minWidth: 100,
                      maxWidth: 1000,
                      minHeight: 100,
                      maxHeight: 1000
                    }}
                    onChange={base64 => this._handleTakePhoto(base64)}
                    onError={errMsg => this._errorImagePicker(errMsg)}
                  >
                    <ProgressButton
                      className={classnames(classes.button, classes.action)}
                      color="secondary"
                      {...this.state.middleButton.properties}
                    >
                      {this.state.middleButton.text}
                    </ProgressButton>
                  </ImagePicker>
                ) : null}
                <ProgressButton
                  className={classnames(classes.button)}
                  classes={{
                    root: classes.paddingLeft,
                    disabled: classes.buttonDisabled
                  }}
                  color="secondary"
                  {...this.state.rightButton.properties}
                >
                  {this.state.rightButton.text}
                </ProgressButton>
              </>
            }
          />
        )}
      />
    ) : null;
  }

  _errorImagePicker = errMsg => {
    if (errMsg.includes('jpg')) {
      errMsg = 'Debe seleccionar un archivo de tipo: jpg';
    } else {
      if (errMsg.includes('The uploaded image is too large.')) {
        errMsg =
          'La imagen seleccionada supera las dimensiones máximas permitidas.';
      } else if (errMsg.includes('The uploaded image is too small.')) {
        errMsg =
          'La imagen seleccionada no supera las dimensiones Mínimas permitidas.';
      } else {
        errMsg = 'Ha ocurrido un error.';
      }
    }
    this.props.newAlert('error', 'ERROR:', errMsg);
  };
  _handleTakePhoto = selectedPhoto => {
    let screenshot;
    this._saveLastStates();

    selectedPhoto
      ? (screenshot = selectedPhoto)
      : (screenshot = this.webcam.getScreenshot());
    this.setState({
      title: 'Cargar Fotografía',
      leftButton: this.buttons.back,
      rightButton: this.buttons.savePicture,
      screenshot: screenshot,
      cameraSession: true,
      visibilityMiddleButton: false
    });
  };

  _handleBackbutton = () => {
    if (this.state.screenshot != null) {
      this.setState({ screenshot: null });
      this.setState({ ...this.state.back.pop() });
    } else {
      this.setState({ ...this.state.back.pop() });
    }
  };
  _handleCameraSession = () => {
    this._saveLastStates();
    this.setState({
      title: 'Hacer Fotografía',
      visibilityMiddleButton: false,
      cameraSession: true,
      leftButton: this.buttons.back,
      rightButton: this.buttons.cameraSession
    });
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.erros !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnSave = async user => {
    const { onSave } = this.props;
    const picture = this.state.screenshot;
    const base64 = limiteSize(this.state.screenshot);
    if (base64 != null || base64 != undefined) {
      await onSave(base64);
      this._handleOnClose();
    } else {
      this.props.newAlert(
        'error',
        'ERROR:',
        'El archivo supera el tamaño máximo permitido.'
      );
    }
  };

  _saveLastStates = () => {
    const lastChanges = {
      title: this.state.title,
      leftButton: this.state.leftButton,
      rightButton: this.state.rightButton,
      middleButton: this.state.middleButton,
      visibilityMiddleButton: this.state.visibilityMiddleButton,
      cameraSession: this.state.cameraSession
    };
    let lastsChanges = [...this.state.back];
    lastsChanges.push(lastChanges);
    this.setState({
      back: lastsChanges
    });
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(EditProfileModal);
