import _pickBy from 'lodash/pickBy';

export const financialDetailsFilterSanitizer = filters => {
  const { plan, category, page, perPage } = filters;
  const sanitizedFilters = {
    financingPlanDescription: plan,
    categoryName: category,
    page,
    perPage
  };
  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

export const listSanitizer = list => {
  return list.filter(item => item.id > 0);
};