import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinearLoading from '../Loaders/LinearLoading';
import ProgressButton from '../Buttons/ProgressButton';
import classnames from 'classnames';
import ConfirmDialog from './ConfirmDialog';

const DialogTitle = withStyles(theme => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2
  },
  secondaryContainer: {
    position: 'absolute',
    right: theme.spacing.unit * 3,
    top: theme.spacing.unit * 0.8
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const {
    children,
    classes,
    onClose,
    headerIcon,
    headerButton,
    headerSecondary
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {!headerSecondary && (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={headerButton || onClose}
        >
          {headerIcon || <CloseIcon />}
        </IconButton>
      )}
      {headerSecondary && (
        <div className={classes.secondaryContainer}>{headerSecondary}</div>
      )}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2
  }
}))(MuiDialogContent);

const DialogContentText = withStyles(theme => ({
  root: {
    color: theme.typography.color.light,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  }
}))(MuiDialogContentText);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

class CustomDialog extends React.Component {
  state = {
    confirmationModelOpen: false
  };

  _handleConfirmationModalOpen = () => {
    this.setState({ confirmationModelOpen: true });
  };

  _handleConfirmationModalClose = () => {
    this.setState({ confirmationModelOpen: false });
  };

  render() {
    const {
      classes,
      open,
      title,
      secondButtonText,
      buttonText,
      cancelButtonText,
      disabled,
      loading,
      loadingSecondary,
      onConfirmSecond,
      onConfirm,
      onClose,
      disableBackdropClick,
      disableLoadingButton,
      headerSecondary,
      headerButton,
      headerIcon,
      children,
      contentText,
      buttonSecondaryText,
      onConfirmSecondary,
      disabledSecondary,
      allowTitle = true,
      customWidth,
      maxWidth,
      confirmDialog = false,
      titleConfirm = '',
      textConfirm = ''
    } = this.props;
    return (
      <MuiDialog
        fullWidth
        onClose={onClose}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableBackdropClick}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={
          customWidth && {
            style: { maxWidth: maxWidth, transition: 'all 0.2s ease' }
          }
        }
        classes={{
          paper: customWidth
            ? classnames(
                classes.dialogRoot,
                classes.root,
                classes.dialogCustomWidth
              )
            : classnames(classes.dialogRoot, classes.root)
        }}
      >
        {allowTitle && !customWidth && (
          <DialogTitle
            id="customized-dialog-title"
            onClose={onClose}
            headerIcon={headerIcon}
            headerButton={headerButton}
            headerSecondary={headerSecondary}
          >
            {title || 'Comentario'}
          </DialogTitle>
        )}
        {disableLoadingButton && (
          <LinearLoading loading={loading} color="secondary" />
        )}
        <DialogContent
          className={
            customWidth
              ? classnames(
                  classes.dialogContentRoot,
                  classes.content,
                  classes.dialogContentInnerMargin
                )
              : classnames(classes.dialogContentRoot, classes.content)
          }
        >
          {contentText && (
            <DialogContentText className={classes.contentText}>
              {contentText}
            </DialogContentText>
          )}
          {children}
        </DialogContent>
        {!customWidth && (
          <DialogActions>
            {buttonSecondaryText && confirmDialog ? (
              <div>
                <ProgressButton
                  color="secondary"
                  isSubmitting={loadingSecondary}
                  onClick={this._handleConfirmationModalOpen}
                  disabled={disabledSecondary || loading}
                >
                  {buttonSecondaryText}
                </ProgressButton>
                <ConfirmDialog
                  open={this.state.confirmationModelOpen}
                  title={titleConfirm}
                  text={textConfirm}
                  buttonTextConfirm="ENTENDIDO"
                  onConfirm={() => {
                    onConfirmSecondary();
                    this._handleConfirmationModalClose();
                  }}
                  onClose={this._handleConfirmationModalClose}
                  buttonTextCancel="CANCELAR"
                />
              </div>
            ) : (
              buttonSecondaryText && (
                <ProgressButton
                  color="secondary"
                  isSubmitting={loadingSecondary}
                  onClick={onConfirmSecondary}
                  disabled={disabledSecondary || loading}
                >
                  {buttonSecondaryText}
                </ProgressButton>
              )
            )}
            <div className={classes.buttonSpace} />
            {(headerButton || headerSecondary) && (
              <Button disabled={loading} onClick={onClose} color="secondary">
                {cancelButtonText || 'Cerrar'}
              </Button>
            )}
            {secondButtonText && (
              <ProgressButton
                color="secondary"
                isSubmitting={loading}
                disabled={disabled}
                onClick={onConfirmSecond}
              >
                {secondButtonText}
              </ProgressButton>
            )}
            {buttonText && (
              <ProgressButton
                color="secondary"
                isSubmitting={loading}
                disabled={disabled}
                onClick={onConfirm}
              >
                {buttonText}
              </ProgressButton>
            )}
          </DialogActions>
        )}
      </MuiDialog>
    );
  }
}
const styles = theme => ({
  dialogContentRoot: {
    overflow: 'visible',
    overflowY: 'auto'
  },
  dialogContentInnerMargin: {
    padding: 40
  },
  dialogCustomWidth: {
    width: 'auto'
  },
  contentText: {
    fontSize: '14px',
    marginBottom: theme.spacing.unit * 2
  },
  buttonSecondary: {
    position: 'absolute',
    left: theme.spacing.unit
  },
  dialogRoot: {
    overflow: 'visible',
    borderRadius: 2
  },
  buttonSpace: {
    flex: 1
  },
  root: {},
  content: {}
});

CustomDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onConfirmSecond: PropTypes.func,
  children: PropTypes.object,
  title: PropTypes.string,
  secondButtonText: PropTypes.string,
  buttonText: PropTypes.string,
  textPlaceholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  headerButton: PropTypes.func,
  headerIcon: PropTypes.element,
  disableBackdropClick: PropTypes.bool
};
export default compose(withStyles(styles, { withTheme: true }))(CustomDialog);
