import {
  LIST_SECURE_CREDIT_INSTALMENTS_PERCENTAGES,
  SAVE_SECURE_CREDIT_INSTALMENTS_PERCENTAGES
} from '../constants/secure_credit_instalments_percentages_constants';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import RequestService from '../services/request_service';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { history } from '../helpers/history';
import { newAlert, clearAlerts, serverStatusError } from './alert_actions';
import {
  secureCreditInstalmentsPercentageSanitizer,
  secureCreditInstalmentsPercentageSaveSanitizer
} from '../helpers/sanitizers/secure_credit_instalments_percentage_sanitizer';

const requestService = new RequestService('node');

function setSecureCreditInstalmentsPercentages(data, meta) {
  return {
    type: LIST_SECURE_CREDIT_INSTALMENTS_PERCENTAGES,
    data,
    meta
  };
}

function saveSecureCreditInstalmentsPercentage() {
  return {
    type: SAVE_SECURE_CREDIT_INSTALMENTS_PERCENTAGES
  };
}

export const fetchSecureCreditInstalmentsPercentages = (params = {}, redirect = true) => async dispatch => {
  dispatch(sendRequest('SecureCreditInstalmentsPercentagesList'));
  if (redirect) history.push(`/secure-credit-instalments-percentages?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get(
      'secure-credit-instalments-percentages',
      secureCreditInstalmentsPercentageSanitizer(params),
      true
    );
    dispatch(setSecureCreditInstalmentsPercentages(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('SecureCreditInstalmentsPercentagesList'));
  }
};

export const createSecureCreditInstalmentsPercentage = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('SecureCreditInstalmentsPercentagesSave'));
  try {
    await requestService.post('secure-credit-instalments-percentages', { ...secureCreditInstalmentsPercentageSaveSanitizer(params) }, {}, true);
    dispatch(saveSecureCreditInstalmentsPercentage());
    dispatch(clearAlerts());
    dispatch(fetchSecureCreditInstalmentsPercentages(fetchParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('SecureCreditInstalmentsPercentagesSave', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('SecureCreditInstalmentsPercentagesSave'));
  }
};

export const updateSecureCreditInstalmentsPercentage = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('SecureCreditInstalmentsPercentagesSave'));
  try {
    await requestService.put(
      `secure-credit-instalments-percentages/${params.id}`,
      { ...secureCreditInstalmentsPercentageSaveSanitizer(params) },
      {},
      true
    );
    dispatch(saveSecureCreditInstalmentsPercentage());
    dispatch(clearAlerts());
    dispatch(fetchSecureCreditInstalmentsPercentages(fetchParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('SecureCreditInstalmentsPercentagesSave', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('SecureCreditInstalmentsPercentagesSave'));
  }
};

export const deleteSecureCreditInstalmentsPercentage = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('SecureCreditInstalmentsPercentagesSave'));
  try {
    await requestService.delete(`secure-credit-instalments-percentages/${params.id}`, {}, {}, true);
    dispatch(clearAlerts());
    dispatch(fetchSecureCreditInstalmentsPercentages(fetchParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('SecureCreditInstalmentsPercentagesSave', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('SecureCreditInstalmentsPercentagesSave'));
  }
};