import CommissionList from './CommissionList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchCommissions,
  updateCommission,
  fetchInfoToCreate,
  createCommission
} from '../../../actions/commission_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';

function mapStateToProps(state) {
  return {
    canUpdateCommissions: state.authentication.currentUser.canUpdateCommissions,
    canCreateCommissions: state.authentication.currentUser.canCreateCommissions,
    commissions: state.commissions.commissions,
    dataToCreateCommissions: { ...state.commissions.fetchData, saleChannels: state.globalSetting.orgData.sale_channels },
    commissionTypes: state.globalSetting.orgData.commission_types,
    commissionSaved: state.commissions.saved,
    meta: state.commissions.meta,
    suppliers: state.globalSetting.orgData.suppliers,
    categories: state.globalSetting.orgData.categories,
    requestFetchCommissionsSend: state.requestState.requestFetchCommissionsSend,
    requestErrorsOnSaveCommission:
      state.requestState.requestErrorsOnSaveCommission,
    requestSaveCommissionSend: state.requestState.requestSaveCommissionSend,
    companyName: state.globalSetting.orgData.company_name
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    fetchCommissions: bindActionCreators(fetchCommissions, dispatch),
    createCommission: bindActionCreators(createCommission, dispatch),
    fetchInfoToCreate: bindActionCreators(fetchInfoToCreate, dispatch),
    updateCommission: bindActionCreators(updateCommission, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CommissionList);
