import { SUPPLIER_PROPOSAL_DOCUMENT_TYPES } from './enums';

export const SET_PROPOSALS_LIST = 'SET_PROPOSALS_LIST';
export const SET_PROPOSAL_TO_SHOW = 'SET_PROPOSAL_TO_SHOW';
export const SET_CREATION_FORM_DATA = 'SET_CREATION_FORM_DATA';
export const SET_ADD_DOCUMENT_MODAL_OPEN = 'SET_ADD_DOCUMENT_MODAL_OPEN';
export const SET_PRODUCT_MODAL_OPEN = 'SET_PRODUCT_MODAL_OPEN';
export const SET_SIGNER_MODAL_OPEN = 'SET_SIGNER_MODAL_OPEN';
export const SET_LINE_EXECUTIVES = 'SET_LINE_EXECUTIVES';

export const GENERAL_DATA_DOCUMENTS = [
  SUPPLIER_PROPOSAL_DOCUMENT_TYPES.brandCertificate,
  SUPPLIER_PROPOSAL_DOCUMENT_TYPES.bankCertificate,
  SUPPLIER_PROPOSAL_DOCUMENT_TYPES.personalDataPolicy,
  SUPPLIER_PROPOSAL_DOCUMENT_TYPES.businessLogo,
  SUPPLIER_PROPOSAL_DOCUMENT_TYPES.supplierRegisterFile,
  SUPPLIER_PROPOSAL_DOCUMENT_TYPES.thirdLevelShareholderComposition
];

/* FILES */
export const BRAND_CERTIFICATE_NAME = 'Certificado de marca';
export const BANK_CERTIFICATE = 'Certificación bancaria';
export const PERSONAL_DATA_POLICY =
  'Política de tratamiento de datos personales';
export const BUSINESS_LOGO = 'Logo';
export const SUPPLIER_REGISTER_FILE = 'Formato de registro proveedor';
export const THIRD_LEVEL_SHAREHOLDER_COMPOSITION =
  'Composición accionaria de 3er nivel';
