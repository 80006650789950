import {
  SET_POLICY_PARAMETERS_LIST_DATA,
  OPEN_POLICY_PARAMETERS_FORM_MODAL,
  CLOSE_POLICY_PARAMETERS_FORM_MODAL
} from '../constants/action_constants';

const initialState = {
  configs: [],
  meta: {},
  formModalIsOpen: false
};

export function policyParameters(state = initialState, action) {
  switch (action.type) {
    case SET_POLICY_PARAMETERS_LIST_DATA:
      return {
        ...state,
        configs: action.data,
        meta: {
          totalCount: action.meta.totalCount,
          currentPage: action.meta.currentPage
        }
      };
    case OPEN_POLICY_PARAMETERS_FORM_MODAL:
      return {
        ...state,
        formModalIsOpen: true
      };
    case CLOSE_POLICY_PARAMETERS_FORM_MODAL:
      return {
        ...state,
        formModalIsOpen: false
      };
    default: {
      return state;
    }
  }
};