import * as Yup from 'yup';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import { Formik, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React, { PureComponent, Fragment } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';

import { errorOnRequest } from '../../../../actions/request_state';

//Shared
import Modal from '../../../shared/Modals/Modal';
import GridItem from '../../../shared/GridItem';
import Checkbox from '../../../shared/Inputs/Checkbox';
import TextFieldInput from '../../../shared/Inputs/TextField';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';
import OutlinedSelectChippedInput from '../../../shared/Inputs/OutlinedSelectChipped';

//helpers
import combineStyles from '../../../../helpers/combineStyles';
import SpacingStyles from '../../../../styles/helpers/spacing';
import { removeAccents } from '../../../../helpers/utilityFunctions';

//styles
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import ButtonStyles from '../../../../styles/button';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 3.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  attributes: {
    color: '#2C3C47 !important',
    fontWeight: '500 !important',
    //Porque la letra no es responsive
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  padding: {
    paddingTop: `${theme.spacing.unit * 11}px !important`
  },
  paddingConfirmation: {
    paddingTop: `${theme.spacing.unit * 3.25}px !important`
  },
  otpbody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: `${theme.spacing.unit * 7.375}px !important`,
    color: '#707070'
  },
  otpInputStyle: {
    width: '3rem !important',
    margin: '20px 1rem',
    borderBottom: '2px solid #707070',
    color: '#2C3C47',
    fontFamily: 'Roboto',
    fontSize: '50px'
  },
  mobileDot: {
    padding: '0px',
    top: '24px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '1000',
    position: 'absolute',
    boxSizing: 'border-box',
    background: 'transparent'
  },
  mobileDots: {
    width: '12px !important',
    height: '12px !important',
    margin: '0 4px !important',
    borderRadius: '3px !important'
  },
  mobileDotActive: {
    backgroundColor: '#707070'
  },
  modalRoot: {
    maxWidth: '100vw',
    width: '700px',
    height: '560.600px'
  },
  modalBody: {
    padding: `${theme.spacing.unit * 10}px ${theme.spacing.unit * 6}px 0 ${theme
      .spacing.unit * 6}px`,
    height: '80%',
    position: 'relative'
  },
  modalFooter: {
    margin: `${theme.spacing.unit * 7}px ${theme.spacing.unit * 5}px ${theme
      .spacing.unit * 1.75}px ${theme.spacing.unit * 5}px`
  },
  buttonChange: {
    padding: '0px'
  },
  pointer: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

const mapDispatchToProps = (dispatch, props) => ({
  UpdateErrorsOnUpdateMedia: value =>
    dispatch(errorOnRequest('UpdateMedia', value))
});

export class OtpModal extends PureComponent {
  state = {
    confirmationModal: false,
    activeStep: 0,
    input: ''
  };

  componentWillUnmount() {
    this.props.UpdateErrorsOnUpdateMedia({});
  }

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };
  _handleOnCloseAll = () => {
    const { onCloseAll } = this.props;
    onCloseAll();
  };
  _handleChangeOtp = (otp, handleChange) => {
    const evt = { target: { value: otp, name: 'otp' } };
    handleChange(evt);
  };
  _handleBackButton = () => {
    this.setState({ confirmationModal: false, activeStep: 0 });
  };
  _handleUpdateMedia = values => {
    const { updateMedia, user, otpType } = this.props;
    const newMedia = values[otpType];
    updateMedia(user, { otpType: otpType, newMedia: newMedia }, 'NewOtp').then(
      isCorrect => {
        if (isCorrect) {
          this.setState({
            confirmationModal: true,
            activeStep: 1,
            input: newMedia
          });
        }
      }
    );
  };
  _handleConfirmUpdateMedia = values => {
    const { confirmUpdateMedia, user, onClose, otpType } = this.props;
    const updateDataUser = { ...user };
    const newMedia = this.state.input;
    updateDataUser[otpType] = newMedia;
    if (otpType == 'mobile') updateDataUser.mobileConfirmedAt = true;
    confirmUpdateMedia(
      updateDataUser,
      {
        otpType: otpType,
        newMedia: newMedia,
        code: values.otp
      },
      'NewOtp'
    ).then(isCorrect => {
      if (isCorrect) onClose();
    });
  };
  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      otpType = null,
      requestErrorsOnUpdateMedia,
      hideBackdrop
    } = this.props;

    const currentCase = this.state.confirmationModal ? 'confirmation' : otpType;

    let disableInconfirmation = false;
    const cases = {
      email: {
        validationSchema: Yup.object().shape({
          email: Yup.string()
            .email('Correo electronico invalido')
            .required('El campo es requerido')
        }),
        initialValues: { email: this.state.input },
        title: 'Ingresa el Email a registrar',
        body: ''
      },
      mobile: {
        validationSchema: Yup.object().shape({
          mobile: Yup.number()
            .moreThan(3000000000, 'Debe ser un número válido')
            .lessThan(4000000000, 'Debe ser un número válido')
            .required('Celular es requerido')
        }),
        initialValues: { mobile: this.state.input },
        title: 'Ingrese el número de celular a registrar',
        body: ''
      },
      confirmation: {
        validationSchema: Yup.object().shape({
          otp: Yup.string().test(
            'len',
            'Debe tener exactamente 4 caracteres',
            val => val && val.toString().length === 4
          )
        }),
        initialValues: { otp: '' },
        title: 'Confirmación',
        body: ''
      }
    };

    return (
      <Formik
        initialValues={cases[currentCase].initialValues}
        onSubmit={async values => {
          if (this.state.confirmationModal) {
            this._handleConfirmUpdateMedia(values);
          } else {
            this._handleUpdateMedia(values);
          }
        }}
        onReset={(values, actions) => {
          this._handleOnClose();
          actions.resetForm();
        }}
        validationSchema={cases[currentCase].validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          handleBlur,
          setFieldValue,
          dirty,
          touched,
          errors
        }) => {
          if (this.state.confirmationModal && this.state.input != '') {
            if (values.otp != null) {
              if (values.otp.length < 4) {
                disableInconfirmation = true;
              } else {
                disableInconfirmation = false;
              }
            }
          }
          cases.email.body = (
            <div className={classes.padding}>
              <OutlinedTextField
                name="email"
                onChange={handleChange}
                value={values.email}
                label={'Email'}
                type={'email'}
                fullWidth
                error={
                  (touched.email && !!errors.email) ||
                  (requestErrorsOnUpdateMedia &&
                    !!requestErrorsOnUpdateMedia.email)
                }
                helperText={
                  (touched.email && errors.email) ||
                  (requestErrorsOnUpdateMedia &&
                    requestErrorsOnUpdateMedia.email &&
                    requestErrorsOnUpdateMedia.email.join(' '))
                }
              />
            </div>
          );
          cases.mobile.body = (
            <div className={classes.padding}>
              <OutlinedTextField
                name="mobile"
                onChange={handleChange}
                value={values.mobile}
                label={'Celular'}
                type={'number'}
                fullWidth
                min={0}
                error={
                  (touched.mobile && !!errors.mobile) ||
                  (requestErrorsOnUpdateMedia &&
                    !!requestErrorsOnUpdateMedia.mobile)
                }
                helperText={
                  (touched.mobile && errors.mobile) ||
                  (requestErrorsOnUpdateMedia &&
                    requestErrorsOnUpdateMedia.mobile &&
                    requestErrorsOnUpdateMedia.mobile.join(' '))
                }
              />
            </div>
          );
          cases.confirmation.body = (
            <div className={classes.paddingConfirmation}>
              <p className={classes.body1}>
                {otpType == 'email' ? (
                  <p>
                    Ingresa el código de 4 dígitos enviado al correo electrónico{' '}
                    <strong>{this.state.input}</strong>
                  </p>
                ) : (
                  <p>
                    Ingresa el código de 4 dígitos enviado por SMS al celular{' '}
                    <strong>{this.state.input}</strong>
                  </p>
                )}
              </p>
              <div className={classes.otpbody}>
                <Field
                  name="otp"
                  render={({ field, form }) => {
                    return (
                      <OtpInput
                        numInputs={4}
                        isInputNum={true}
                        onChange={otp =>
                          this._handleChangeOtp(otp, handleChange)
                        }
                        value={values.otp}
                        field={field}
                        form={form}
                        shouldAutoFocus={true}
                        inputStyle={classnames(classes.otpInputStyle)}
                      />
                    );
                  }}
                />
                <a
                  className={classnames(classes.subtitle2, classes.pointer)}
                  onClick={() => {
                    this._handleUpdateMedia(values);
                  }}
                >
                  ¿No te ha llegado el código? Presiona aquí para reenviarlo
                </a>
              </div>
            </div>
          );
          const cancelarButton = (
            <Button
              className={classes.button}
              onClick={handleReset}
              color={'secondary'}
              classes={{ label: classes.buttonLabel }}
            >
              {'Cancelar'}
            </Button>
          );
          return (
            <Modal
              open={open}
              hideBackdrop={hideBackdrop}
              onClose={this._handleOnCloseAll}
              classes={{
                root: classes.modalRoot,
                body: classes.modalBody,
                footer: classes.modalFooter
              }}
              body={
                <Fragment>
                  <MobileStepper
                    variant="dots"
                    steps={2}
                    position={'top'}
                    activeStep={this.state.activeStep}
                    classes={{
                      root: classes.mobileDot,
                      dot: classes.mobileDots,
                      dotActive: classes.mobileDotActive
                    }}
                  />
                  <div className={classes.titleWrapper}>
                    <p className={classnames(classes.headline6, classes.title)}>
                      {cases[currentCase].title}
                    </p>
                  </div>
                  {cases[currentCase].body}
                </Fragment>
              }
              footer={
                <Fragment>
                  {this.state.confirmationModal ? (
                    <>
                      <Button
                        className={classes.button}
                        onClick={this._handleBackButton}
                        color={'secondary'}
                        classes={{ label: classes.buttonLabel }}
                      >
                        {'Atrás'}
                      </Button>
                      {cancelarButton}
                      <div className={classes.spacer} />
                    </>
                  ) : (
                    <>
                      <div className={classes.spacer} />
                      {cancelarButton}
                    </>
                  )}

                  <ProgressButton
                    onClick={handleSubmit}
                    className={classes.button}
                    classes={{
                      disabled: classes.buttonDisabled,
                      label: classes.buttonLabel
                    }}
                    disabled={
                      isSubmitting ||
                      disableInconfirmation ||
                      (!this.state.confirmationModal &&
                        values[otpType] == this.state.input)
                    }
                    color={'secondary'}
                    isSubmitting={isSubmitting}
                  >
                    {'Siguiente'}
                  </ProgressButton>
                </Fragment>
              }
            />
          );
        }}
      />
    );
  }
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(
    combineStyles(
      styles,
      LayoutStyles,
      TypographyStyles,
      ButtonStyles,
      SpacingStyles
    )
  )
)(OtpModal);
