import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import combineStyles from './../../../helpers/combineStyles';
import { withStyles } from '@material-ui/core/styles';
import TypographyStyles from '../../../styles/typography';
import LayoutStyles from '../../../styles/layout';
import SpacingStyles from '../../../styles/helpers/spacing';
import classnames from 'classnames';
import FormItemContainerStyles from './__styles__/FormItemContainer.styles';

export class FormItemContainer extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    classes: PropTypes.object,
    secondaryAction: PropTypes.node,
    mediumSize: PropTypes.bool
  };

  render() {
    const { classes, children, title, customContents, generalCustomContents = false, secondaryAction = null, mediumSize = false } = this.props;

    return (
      <div className={!generalCustomContents ? classnames(classes.root, classes.container) : classnames(classes.customStyle)}>
        <div
          className={!generalCustomContents ? classnames(
              classes.titleContentDivider,
              classes.titleContainer
          ) : classnames(
            classes.titleContainer
          )
        }
        >
          <h2 className={mediumSize ? classnames(classes.headline7, classes.title) : classnames(classes.headline8, classes.title)}>
            {title}
          </h2>
          <div className={classes.secondaryAction}>{secondaryAction}</div>
        </div>
        <div className={classnames(classes.contents, customContents)}>{children}</div>
      </div>
    );
  }
}

export default withStyles(
  combineStyles(
    FormItemContainerStyles,
    TypographyStyles,
    LayoutStyles,
    SpacingStyles
  )
)(FormItemContainer);
