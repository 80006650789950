import RankingList from './RankingList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchPointsOfSaleNames,
  fetchPosDashboard
} from '../../../actions/points_of_sale_actions';

import {
  fetchSellersSummary,
  fetchSellersSales,
  fetchTopRanking,
  fetchDataSalesman,
  fetchSupplierFilter,
  fetchFilterSelection
} from '../../../actions/dashboard_sellers_actions';
import { fetchSuppliersDashboard } from '../../../actions/suppliers_actions';
import { newAlert } from '../../../actions/alert_actions';

function mapStateToProps(state) {
  return {
    dataSellersSummary: state.dashboardSellers.sellerSummary,
    dataSellersSales: state.dashboardSellers.sellersSales,
    dataTopRanking: state.dashboardSellers.topRanking,
    dataSalesman: state.dashboardSellers.dataSalesman,
    requestDashboardTopRankingSend:
      state.requestState.requestDashboardTopRankingSend,
    canViewSummary: state.authentication.currentUser.canViewSummary,
    canListTopSellers: state.authentication.currentUser.canListTopSellers,
    canListDashboardTopRanking:
      state.authentication.currentUser.canListDashboardTopRanking,
    sellers: state.dashboardSellers.topRanking,
    pointsOfSale: state.pointOfSale.pointsOfSaleNames,
    suppliers: state.globalSetting.orgData.suppliers,
    currentUserPos: state.authentication.currentUser.pointOfSales,
    currentUser: state.authentication.currentUser,
    supplierFilter: state.dashboardSellers.supplierFilter,
    filterSelection: state.dashboardSellers.filterSelection,
    hugeSurfaceCoordinatorRoles:
      state.globalSetting.orgData.huge_surface_coordinator_roles,
    operatingZones: state.globalSetting.orgData.osf_operating_zones,
    saleChannels: state.globalSetting.orgData.sale_channels,
    pointsOfSaleList: state.pointOfSale.pointsOfSale,
    zones: state.globalSetting.orgData.geographic_locations
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSellersSummary: bindActionCreators(fetchSellersSummary, dispatch),
    fetchSellersSales: bindActionCreators(fetchSellersSales, dispatch),
    fetchTopRanking: bindActionCreators(fetchTopRanking, dispatch),
    fetchDataSalesman: bindActionCreators(fetchDataSalesman, dispatch),
    fetchSupplierFilter: bindActionCreators(fetchSupplierFilter, dispatch),
    fetchFilterSelection: bindActionCreators(fetchFilterSelection, dispatch),
    fetchPointsOfSaleNames: bindActionCreators(
      fetchPointsOfSaleNames,
      dispatch
    ),
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchSuppliers: bindActionCreators(fetchSuppliersDashboard, dispatch),
    fetchPointsOfSaleList: bindActionCreators(fetchPosDashboard, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RankingList);
