import React, { Fragment } from 'react';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Fade, Grid, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import {
  mapSaleDetails,
  mapNumberOptions,
  mapReturnCancelCausals
} from '../../../../helpers/mappings/mapToOptions';
import mapListToOptions from '../../../../helpers/mapListToOptions';
class CancelPartially extends React.PureComponent {
  state = {
    items: [],
    nItems: 0
  };

  componentDidMount() {
    this._newArticle();
  }
  _newArticle = () => {
    const { items, nItems } = this.state;
    this.setState({
      items: [...items, { idx: nItems, id: -1, amount: -1, cause: -1 }],
      nItems: nItems + 1
    });
  };
  _removeItem = idx => {
    const { items } = this.state;
    const filtered = _filter(items, i => idx != i.idx);
    this.setState({
      items: [...filtered]
    });
    this.props.setArticles([...filtered]);
  };
  _handleSelector = e => {
    const { name, value } = e.target;
    const { items } = this.state;
    const attr = name.split('_')[0];
    const idx = name.split('_')[1];
    const prevItem = _find(items, i => idx == i.idx);
    const withoutPrev = _filter(items, i => idx != i.idx);
    if (attr == 'id') {
      prevItem.amount = -1;
      prevItem.cause = -1;
    }
    const newItems = _orderBy(
      [...withoutPrev, { ...prevItem, [attr]: value }],
      'idx',
      'asc'
    );
    this.props.setArticles(newItems);
    this.setState({ items: newItems });
  };

  _validSaleDetails = saleDetails => {
    const { isB2bSale } = this.props;
    let valid = [];
    if (isB2bSale) return valid;
    valid = _filter(saleDetails, e => {
      if (e.status == 'for_delivery') return true;
      return false;
    });
    return valid;
  };

  _getSaleArticles = sale => {
    const { items } = this.state;
    if (!sale.saleDetails[0]) return [''];
    return mapSaleDetails(this._validSaleDetails(sale.saleDetails), items);
  };

  _getAmount = (sale, sale_detail_id) => {
    if (!sale.saleDetails[0] || sale_detail_id < 0) return [];
    const selected = _find(sale.saleDetails, i => sale_detail_id == i.id);
    return mapNumberOptions(1, selected.amount);
  };
  _getCausal = (sale_detail_id, sale) => {
    const sale_detail = _find(sale.saleDetails, a => a.id == sale_detail_id);
    if (!sale_detail) return [];
    const filteredCausalList = _filter(
      mapReturnCancelCausals(this.props.revokeSaleCausals, this.props.cardif, this.props.cardifLayerTwoId),
      i => {
        if (i.allExcept.includes(sale_detail.article.id)) return false;
        if (i.only.includes(sale_detail.article.id)) return true;
        if (i.only.length == 0) return true;
        return false;
      }
    );
    const mappedList = mapListToOptions(filteredCausalList);
    return mappedList;
  };
  render() {
    const { classes, sale } = this.props;
    const { items } = this.state;

    return (
      <Fragment>
        {items.map(a => {
          return (
            <Fade key={a.idx} in={true}>
              <Grid container spacing={8}>
                <Grid item xs={5}>
                  <SimpleSelect
                    required
                    label="Artículo"
                    name={`id_${a.idx}`}
                    options={this._getSaleArticles(sale)}
                    value={a.id}
                    onChange={this._handleSelector}
                    disabled={false}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={2}>
                  <SimpleSelect
                    required
                    label="Cant."
                    name={`amount_${a.idx}`}
                    options={this._getAmount(sale, a.id)}
                    value={a.amount}
                    onChange={this._handleSelector}
                    disabled={a.id == -1}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleSelect
                    required
                    label="Causal de Anulación"
                    name={`cause_${a.idx}`}
                    options={this._getCausal(a.id, sale)}
                    value={a.cause}
                    onChange={this._handleSelector}
                    disabled={a.id == -1}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={1} className={classes.closeButton}>
                  <IconButton
                    disabled={items.length == 1}
                    onClick={() => this._removeItem(a.idx)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Fade>
          );
        })}
        {items.length < this._validSaleDetails(sale.saleDetails).length && (
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            onClick={this._newArticle}
          >
            {'Añadir artículo'}
          </Button>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing.unit
  }
});

CancelPartially.propTypes = {
  sales: PropTypes.object
};
export default withStyles(styles)(CancelPartially);
