import React from 'react';
import Grid from '@material-ui/core/Grid';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const Footer = props => {
  const {
    classes,
    onSave,
    goBack,
    onContinue,
    saveButtonDisabled,
    saveButtonSubmitting,
    continueButtonDisabled,
    continueButtonSubmitting,
    backButtonDisabled,
    continueButtonText,
    showChangeExecutiveField,
    lineExecutiveId,
    handleLineExecutiveChange,
    lineExecutives,
  } = props;
  return (
    <Grid container item alignItems='center' xs={12}>
      <Grid container item xs={6}>
        <Grid item xs={2}>
          <Button onClick={goBack} disabled={backButtonDisabled}>
            Atrás
          </Button>
        </Grid>
        {
          showChangeExecutiveField && (
            <Grid item xs={6}>
              <Select
                value={lineExecutiveId}
                onChange={handleLineExecutiveChange}
                className={classes.select}
              >
                {
                  lineExecutives.map(
                    lineExecutive => {
                      return (
                        <MenuItem key={lineExecutive.id} value={lineExecutive.id}>
                          {lineExecutive.name}
                        </MenuItem>
                      );
                    }
                  )
                }
              </Select>
            </Grid>
          )
        }
      </Grid>
      <Grid container item justify='flex-end' xs={6}>
        <Grid container justify='flex-end' item xs={3}>
          <ProgressButton
            disabled={saveButtonDisabled}
            variant="contained"
            color="secondary"
            isSubmitting={saveButtonSubmitting}
            onClick={onSave}
          >
            Guardar
          </ProgressButton>
        </Grid>
        <Grid container justify='flex-end' item xs={3}>
          <ProgressButton
            disabled={continueButtonDisabled}
            variant="contained"
            color="secondary"
            isSubmitting={continueButtonSubmitting}
            onClick={onContinue}
          >
            { continueButtonText }
          </ProgressButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;