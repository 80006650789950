import React, { Component, Fragment } from 'react';
import Stepper from '../../shared/Stepper';
import TeleshoppingErrors from './TeleshoppingErrors';
import { Route } from 'react-router-dom';
import ActivityIndicator from '../../shared/ActivityIndicator';
import {
  generateRoutes,
  getSteps,
  getRoutes
} from '../../../helpers/stepperFunctions';

class Teleshopping extends Component {
  state = {
    persisted: false,
    routeList: []
  };

  async componentDidMount() {
    const process = 'teleshopping';

    if (this.props.location.pathname.includes('contract-validation')) {
      await this.props.clearTeleshopping()
      await this.props.resetStepper();
      this.tryGetProcessAndSetRoutes(process);
    } else {
      this.props.persistTeleshopping();
      this.props.persistStepper();
    }
  }
  componentDidUpdate = () => {
    if (
      !this.state.persisted &&
      !this.props.location.pathname.includes('contract-validation')
    ) {
      this.tryGetProcessAndSetRoutes(this.props.process);
    }
  };

  tryGetProcessAndSetRoutes = process => {
    const globalSettings = {
      validateSaleInvoices: null,
      validatePromissoryInvoices: null
    };
    if (process) {
      const routeList = generateRoutes(process, globalSettings);
      this.props.setSteps(getSteps(routeList), getRoutes(routeList), process);
      this.setState({ routeList, persisted: true });
    }
  };
  _renderStep = () => {
    const { location, teleshoppingCompleted } = this.props;
    const currentStep = this.props.routes.indexOf(location.pathname);
    const maxStep = this.props[`${this.props.process}Next`];
    const route = this.state.routeList.find(
      route => route.path === location.pathname
    );
    if (currentStep >= 0 && maxStep >= 0 && route) {
      if (
        currentStep <= maxStep ||
        (teleshoppingCompleted &&
          location.pathname === '/teleshopping/register')
      ) {
        return (
          <Fragment>
            <Stepper />
            <Route exact path={route.path} component={route.component} />
          </Fragment>
        );
      } else {
        this.props.history.push(this.props.routes[maxStep]);
        this.props.newAlert(
          'error',
          'ERROR:',
          'No puedes ingresar a este paso sin haber completado los anteriores.'
        );
      }
    } else {
      //this.props.history.push(this.props.routes[maxStep]);
      this.props.newAlert(
        'error',
        'ERROR:',
        'La ruta ingresada no existe en el registro de venta.'
      );
    }
  };

  render() {
    const {
      location,
      server_errors,
      baseErrorOpen,
      baseErrorText,
      baseErrorTitle,
      baseErrorRoute,
      toggleBaseErrorModal,
      loadingParameters
    } = this.props;
    if (loadingParameters && location.pathname.includes('contract-validation'))
      return <ActivityIndicator />;
    if (!this.state.persisted) return null;
    return (
      <Fragment>
        <TeleshoppingErrors
          server_errors={server_errors}
          baseErrorOpen={baseErrorOpen}
          baseErrorText={baseErrorText}
          baseErrorTitle={baseErrorTitle}
          toggleModal={toggleBaseErrorModal}
          baseErrorRoute={baseErrorRoute}
        />
        {this._renderStep()}
      </Fragment>
    );
  }
}

export default Teleshopping;
