import _pickBy from 'lodash/pickBy';

const clientFilters = filters => {
  const { name, status, page, perPage } = filters;
  const sanitizedFilters = {
    name: name,
    page: page,
    perPage
  };

  if (status === 'activos') {
    sanitizedFilters.active = "true"
  }

  if (status === 'inactivos') {
    sanitizedFilters.active = "false"
  }

  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

export default clientFilters;
