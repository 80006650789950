import { withStyles } from '@material-ui/core/styles';
import combineStyles from './combineStyles';

// TODO: Compose the standard hocs so that we don't have to import them with every component
export function withComposedEnhancers(component, ...hocs) {
  let composed = component;
  for (const hoc of hocs) {
    composed = hoc(composed);
  }
  return composed;
}

// TODO: Combine all sitewide styles so that we don't have to import them with every component
export function withCombinedStyles(withTheme, ...styles) {
  return withStyles(combineStyles(...styles), {
    withTheme
  });
}
