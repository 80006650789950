import { Grid, Tooltip } from '@material-ui/core';
import { CloudDownloadRounded, InfoRounded } from '@material-ui/icons';
import React from 'react';
import { SUPPLIER_PROPOSAL_DOCUMENT_TYPES } from '../../../../constants/enums';
import FileUploader from '../FileUploader';

const GeneralDataDocuments = props => {
  const renderShareholderLabel = label => {
    const title =
      'Certificado donde indique el nombre de los accionistas/socios directos e indirectos, hasta llegar a su beneficiario final (persona natural), su tipo y número de identificación y el porcentaje de participación accionaria, suscrito por el representante legal, el contador o el revisor fiscal, con fecha de expedición no superior a 30 días.  Si dentro de dicha composición accionaria hay personas jurídicas accionistas, cuya participación accionaria es igual o superior al 5% debe remitir certificado de composición accionaria hasta llegar a su beneficiario final persona natural.';

    return (
      <p
        style={{
          margin: 0
        }}
      >
        {label}
        <Tooltip
          title={
            <p
              style={{
                fontSize: '14px'
              }}
            >
              {title}
            </p>
          }
          placement="top"
        >
          <InfoRounded color="secondary" fontSize="small" />
        </Tooltip>
      </p>
    );
  };

  const renderSupplierRegisterFile = label => {
    return (
      <p
        style={{
          margin: 0
        }}
      >
        {label}
        <a href={'/docs/supplier_register_form.pdf'} download>
          <Tooltip
            title={<p style={{ fontSize: '14px' }}>Descargar plantilla</p>}
            placement="right"
          >
            <CloudDownloadRounded
              color="secondary"
              style={{
                marginLeft: '8px'
              }}
            />
          </Tooltip>
        </a>
      </p>
    );
  };

  const renderLabel = (document, label) => {
    if (
      document.docType ===
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.thirdLevelShareholderComposition
    )
      return renderShareholderLabel(label);

    if (
      document.docType === SUPPLIER_PROPOSAL_DOCUMENT_TYPES.supplierRegisterFile
    )
      return renderSupplierRegisterFile(label);

    return document.name;
  };

  const allowFileReplace = document => {
    const {
      proposalWasSent,
      proposalWasRejected,
      userIsAspiringSupplier,
      values
    } = props;

    if (!values.companyName || !values.nit) return false;
    if (!proposalWasSent) return true;
    if (
      proposalWasRejected({
        values
      })
    )
      return false;
    if (!userIsAspiringSupplier) return false;
    return document.accepted !== true;
  };

  const {
    values,
    setFieldValue,
    onViewOtherComments,
    canAcceptOrRejectFile,
    showCommentSection,
    fileMaxSize,
    fileSizeUnit,
    extensions,
    allowAddComment,
    handleFileError,
    newAlert,
    handleFileChange,
    loadingDocumentIds,
    fetchFileBase64,
    onAddComment,
    handleFileAcceptanceChange
  } = props;

  return (
    <Grid container alignItems="baseline">
      {values.generalDataDocuments.map((document, index) => (
        <Grid
          item
          xs={4}
          key={document.docType}
          style={{
            paddingTop: '24px',
            paddingRight: `${index % 3 === 0 ? '24px' : '0'}`,
            paddingLeft: `${(index + 1) % 3 === 0 ? '24px' : '0'}`,
            paddingBottom: `${index > 2 ? '24px' : '0'}`
          }}
        >
          <FileUploader
            allowFileReplace={allowFileReplace(document)}
            fileName={document.originalFileName}
            fetchFileBase64={fetchFileBase64({ document })}
            label={renderLabel(document, document.name)}
            maxSize={10}
            sizeUnit={'MB'}
            extensions={
              document.docType === SUPPLIER_PROPOSAL_DOCUMENT_TYPES.businessLogo
                ? ['png']
                : extensions
            }
            onChange={handleFileChange({
              document,
              newAlert,
              setFieldValue,
              values
            })}
            onError={handleFileError({
              newAlert,
              fileMaxSize,
              fileSizeUnit
            })}
            comments={document.comments}
            onViewOtherComments={comments => onViewOtherComments(comments)}
            onAddComment={onAddComment({
              documentId: document.id,
              setFieldValue,
              values,
              isGeneralDataDocument: true
            })}
            canAcceptOrRejectFile={canAcceptOrRejectFile}
            showCommentSection={showCommentSection(document)}
            onFileAcceptanceChange={handleFileAcceptanceChange({
              documentId: document.id,
              setFieldValue,
              values,
              isGeneralDataDocument: true
            })}
            fileAccepted={document.accepted}
            allowAddComment={allowAddComment(document)}
            description={document.description}
            submitting={loadingDocumentIds.includes(document.id)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default GeneralDataDocuments;
