import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Comments from './Comments';
import { addSaleComment } from '../../../actions/sales_actions';
import { clearComments } from '../../../actions/comment_actions';
import { addPromissoryComment } from '../../../actions/promissory_actions';

function mapStateToProps(state) {
  return {
    currentUser: state.authentication.currentUser,
    newComments: state.comment.newComments,
    requestSend: state.requestState['requestAdd-CommentSend']
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addSaleComment: bindActionCreators(addSaleComment, dispatch),
    addPromissoryComment: bindActionCreators(addPromissoryComment, dispatch),
    clearComments: bindActionCreators(clearComments, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comments);
