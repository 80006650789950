import { sendRequest, completeRequest, } from './request_state';
import {
  SET_PROFILES_LIST,
  SET_SAVE_PROFILE_MODAL_OPEN,
} from '../constants/line_executive_profile_constants';
import { newAlert } from './alert_actions';

import RequestService from '../services/request_service';

const requestService = new RequestService('node');

function setProfilesList(data) {
  return {
    type: SET_PROFILES_LIST,
    data,
  };
}

function _setSaveModalOpen(data) {
  return {
    type: SET_SAVE_PROFILE_MODAL_OPEN,
    data,
  };
}

export const fetchProfiles = params => async dispatch => {
  dispatch(sendRequest('LineExecutiveProfileList'));
  try {
    const { page, rowsPerPage } = params;
    const payload = await requestService.get(
      'line_executive_profiles', { page, rowsPerPage }
    );
    const profiles = payload.data.profiles;
    const lineExecutives = payload.data.lineExecutives;
    const categories = payload.data.categories;
    const metaData = payload.data.metaData;
    dispatch(setProfilesList({ profiles, metaData, lineExecutives, categories }));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('LineExecutiveProfileList'));
  }
}

export const setSaveModalOpen = (open, profile={}) => async dispatch => {
  dispatch(_setSaveModalOpen({open, profile}));
}

export const createProfile = params => async dispatch => {
  dispatch(sendRequest('CreateLineExecutiveProfile'));
  try {
    const { profile } = params;
    await requestService.post(
      'line_executive_profiles', profile
    );
    await fetchProfiles(params)(dispatch);
    dispatch(_setSaveModalOpen({open: false, profile: {}}));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('CreateLineExecutiveProfile'));
  }
}

export const updateProfile = params => async dispatch => {
  dispatch(sendRequest('UpdateLineExecutiveProfile'));
  try {
    const { profile } = params;
    await requestService.put(
      'line_executive_profiles/'+profile.id,
      profile
    );
    await fetchProfiles(params)(dispatch);
    dispatch(_setSaveModalOpen({open: false, profile: {}}));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('UpdateLineExecutiveProfile'));
  }
}