import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames'
import { withStyles } from '@material-ui/core';

class CustomDatePicker extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    label: PropTypes.string,
    invalidLabel: PropTypes.string,
    maxDateMessage: PropTypes.string,
    minDateMessage: PropTypes.string,
    invalidDateMessage: PropTypes.string,
    disableFuture: PropTypes.bool
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <DatePicker
          clearLabel="Limpiar"
          cancelLabel="Cancelar"
          keyboard
          clearable={true}
          disableFuture={true}
          disableOpenOnEnter
          autoComplete="off"
          placeholder="aaaa/mm/dd"
          format="yyyy/MM/dd"
          mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
          InputLabelProps={{
            shrink: true
          }}
          {...this.props}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = () => ({});

export default withStyles(styles, { withTheme: true })(CustomDatePicker);
