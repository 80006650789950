import moment from 'moment-timezone';
import { CUSTOM_DATE } from './constants';
import {
  BulkProcessStatus,
  FriendlyBulkProcessStatus
} from '../../../../constants/enums';
import { FriendlyClassName } from '../../../../constants/enums';

export const processTypeOptions = [
  { value: 'todas', title: 'Todas' },
  ...Object.keys(FriendlyClassName).map(key => {
    return {
      value: key,
      title: FriendlyClassName[key]
    }
  })
];

export const processStatusOptions = [
  { value: 'todas', title: 'Todas' },
  {
    value: `${BulkProcessStatus.Registered}`,
    title: FriendlyBulkProcessStatus[BulkProcessStatus.Registered]
  },
  {
    value: `${BulkProcessStatus.Processing}`,
    title: FriendlyBulkProcessStatus[BulkProcessStatus.Processing]
  },
  {
    value: `${BulkProcessStatus.Done}`,
    title: FriendlyBulkProcessStatus[BulkProcessStatus.Done]
  },
  {
    value: `${BulkProcessStatus.DoneWithErrors}`,
    title: FriendlyBulkProcessStatus[BulkProcessStatus.DoneWithErrors].replace(
      /_/g,
      ' '
    )
  }
];

export const originalDateOptions = [
  { value: 'todas', title: 'Todas' },
  { value: moment().format('YYYY-MM-DD'), title: 'Hoy' },
  {
    value: `${moment()
      .startOf('week')
      .format('YYYY-MM-DD')} - ${moment()
        .endOf('week')
        .format('YYYY-MM-DD')}`,
    title: 'Esta semana'
  },
  {
    value: `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')} - ${moment()
        .endOf('month')
        .format('YYYY-MM-DD')}`,
    title: 'Este mes'
  }
];

export const dateOptions = (customDate, registered_at) => [
  ...originalDateOptions,
  {
    value: customDate !== CUSTOM_DATE ? registered_at : CUSTOM_DATE,
    title: customDate !== CUSTOM_DATE ? registered_at : 'Personalizada'
  }
];
