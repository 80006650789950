import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import FlashMessage from '../../shared/Alerts/Flash/FlashMessage';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import { BIOMETRIC_READER_STATUS, POINT_OF_SALES_VALIDATION_METHOD } from '../../../constants/enums';

const styles = theme => ({
  tag: {
    margin: 0,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: 'inline-block',
    padding: [[theme.spacing.unit * 0.5, theme.spacing.unit * 1]],
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#FEB00B',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:last-child': {
      marginRight: 0
    }
  },
  fragmentContainer: {
    display: 'flex'
  },
  version: {
    marginTop: '15px',
    fontSize: 'small',
    color: '#808080'
  },
  holder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

export class BiometricReader extends PureComponent {
  render() {
    const {
      classes,
      isLoading,
      biometricReader = {},
      pointsOfSales,
      onEditBiometricReader,
      canUpdateHasBiometricReader,
      biometricUpdateVersion
    } = this.props;
    const pos = pointsOfSales.find(
      pov => pov.id == biometricReader.pointOfSaleId
    )

    return (
      <FormItemContainer
        title={'Lector Biométrico'}
        secondaryAction={
          !isLoading && canUpdateHasBiometricReader ? (
            <div className={classes.fragmentContainer}>
              {BIOMETRIC_READER_STATUS[biometricReader.state] == "registered" && (
                <FlashMessage
                  variant="info"
                  message="Pendiente de activación"
                  actionIcon={false}
                  actionClick={false}
                />
              )}
              <Button
                size={'small'}
                color={'secondary'}
                className={classnames(classes.actionButtons)}
                classes={{
                  label: classes.buttonLabel
                }}
                onClick={onEditBiometricReader}
              >
                {'Editar'}
              </Button>
            </div>
          ) : null
        }
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div>
            {!biometricReader.length && !biometricReader.id ? (
              'No se ha configurado un lector para el usuario'
            ) : (
              <Grid container spacing={16} className={classes.gridContainer}>
                <Grid item md={3} xs={6}>
                  <p className={classnames(classes.subtitle3)}>
                    Punto de venta:
                  </p>
                  <p className={classnames(classes.body2)}>
                    {
                      pos ? pos.name : "" 
                    }
                  </p>
                </Grid>
                <Grid item md={3} xs={6}>
                  <p className={classnames(classes.subtitle3)}>Serial:</p>
                  <p className={classnames(classes.body2)}>
                    {biometricReader.serial}
                  </p>
                </Grid>
                <Grid item md={3} xs={6}>
                  <p className={classnames(classes.subtitle3)}>MAC:</p>
                  <p className={classnames(classes.body2)}>
                    {biometricReader.mac}
                  </p>
                </Grid>
                <Grid item md={3} xs={6}>
                  <p className={classnames(classes.subtitle3)}>
                    Método de validación:
                  </p>
                  <p className={classnames(classes.body2)}>
                    {
                      POINT_OF_SALES_VALIDATION_METHOD.find(
                        item => item.id == biometricReader.methodValidation
                      ).name
                    }
                  </p>
                </Grid>
              </Grid>
            )}
            {biometricUpdateVersion && <p className={classnames(classes.version)}>Versión de Software ({biometricUpdateVersion})</p>}
          </div>
        )}
      </FormItemContainer>
    );
  }
}

export default withStyles(
  combineStyles(ButtonStyles, TypographyStyles, styles)
)(BiometricReader);
