export const OTPCodes = Object.freeze({
  Approved: '1',
  Rejected: '2',
  NoAnswer: '4',
  Expired: '6',
  AdditionalIntentError: '7',
  AdditionalVerificationError: '8',
  GenerationError: '99',
  InvalidEmailFormat: '11'
});
