import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Icon from '@material-ui/core/Icon';
import { Grid } from '@material-ui/core';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import ActivityIndicator from '../../shared/ActivityIndicator';

const styles = theme => ({
  rootBase: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    minHeight: '120px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    paddingTop: theme.spacing.unit * 3,
    position: 'relative',
    marginLeft: '8px',
    marginRight: '8px',
    width: '100%',
  },

  iconRoot: {
    width: '70px',
    height: '70px',
    margin: '20px'
  },

  imageIcon: {
    height: '70%'
  },
});

export const QuickStatusCard = ({
  classes,
  message,
  isLoading,
  icon,
  number
}) => (
    <div className={classes.rootBase}>
      {isLoading && <ActivityIndicator className={classes.loadingIndicator} />}
      <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
        <Icon classes={{ root: classes.iconRoot }}>
          <img className={classes.imageIcon} src={icon} />
        </Icon>
        <div>
          <p className={classes.headline7}>{message}</p>
          <p className={classnames(classes.headline4)}>
            {number}
          </p>
        </div>
      </div>
    </div>
  );

QuickStatusCard.protoTypes = {}

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles)
)(QuickStatusCard);