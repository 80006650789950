export const columns = [
  {
    title: 'ID',
    name: 'id'
  },
  {
    title: 'Descripción',
    name: 'description'
  },
  {
    title: 'Interés',
    name: 'interest'
  },
  {
    title: 'Cuotas Mínimas',
    name: 'minInstallments'
  },
  {
    title: 'Cuotas Máximas',
    name: 'maxInstallments'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      id: datum.id,
      description: datum.description || '--',
      interest: datum.interest ? `${datum.interest}%` : '--',
      minInstallments: datum.minInstallments || '--',
      maxInstallments: datum.maxInstallments || '--'
    };
  });
