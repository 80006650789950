import _pickBy from 'lodash/pickBy';

export const campaignFilterSanitizer = values => {
  const {
    campaignName,
    supplier,
    geographicLocation,
    category,
    saleChannel,
    contractStrata,
    page,
    perPage
  } = values;
  const sanitizedFilters = {
    name: campaignName,
    supplier,
    geographicLocation,
    category,
    saleChannel,
    contractStrata,
    page,
    perPage
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};

export const campaignSaveSanitizer = values => {
  const {
    name,
    startAt,
    endAt,
    minimumValue,
    minimumQuota,
    maxQuota,
    suppliers,
    geographicLocations,
    categories,
    saleChannels,
    contractStratus
  } = values;
  const sanitizedFilters = {
    name,
    startAt: startAt,
    endAt: endAt,
    minimumValue: Number(minimumValue.replaceAll('.', '')),
    minimumQuota: Number(minimumQuota),
    maxQuota: Number(maxQuota),
    suppliers: suppliers.map(s => s.value),
    geographicLocations: geographicLocations.map(gl => gl.value),
    categories: categories.map(c => c.value),
    saleChannels: saleChannels.map(sc => sc.value),
    contractStratus: contractStratus.map(cs => cs.value)
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};
