import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import parseQuerystring from '../../../helpers/parseQuerystring';
import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';
import TextFieldInput from '../../shared/Inputs/TextField';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import { causeOptions, statusOptions } from './dropDownOptions';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

class SalesReturnFilters extends PureComponent {
  state = {
    saleId: '',
    cause: 'all',
    status: 'all',
    contractId: '',
    zone: '',
    zoneId: ''
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    this.setState({
      ...this.state,
      ...query
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter && filter != 'all'),
      ['saleId', 'cause', 'status', 'contractId', 'zoneId']
    );
    return filterParams;
  };

  _getSuggestions = async (type, value) => {
    const zones = this.props[type].filter(item => item.area_type === 2);

    const items = zones.map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  render() {
    const { classes, geographicLocations } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="saleId"
                  name="saleId"
                  value={this.state.saleId}
                  onChange={this._handleChange}
                  label="# Venta"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="contractId"
                  name="contractId"
                  value={this.state.contractId}
                  onChange={this._handleChange}
                  label="# Contrato"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <OutlinedSelectChippedInput
                  label={'Causal de devolución'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'cause'}
                  options={causeOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'Cause'}
                  value={this.state.cause}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <OutlinedSelectChippedInput
                  label={'Estado'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'status'}
                  options={statusOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'Status'}
                  value={this.state.status}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <AutocompleteInput
                  id="zone"
                  name="zone"
                  label="Departamento"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.zone || ''}
                  onChange={change => {
                    if (this.state.zone && !change) {
                      this._handleSelectChange({
                        target: { name: 'zone', value: '' }
                      });
                      this._handleSelectChange({
                        target: { name: 'zoneId', value: null }
                      });
                    }
                    this.setState({ zone: change });
                  }}
                  suggestions={geographicLocations}
                  getSuggestions={value =>
                    this._getSuggestions('geographicLocations', value)
                  }
                  onSuggestionSelected={geographicLocation => {
                    this.setState({ zoneId: geographicLocation.value });
                    this._handleSelectChange({
                      target: {
                        name: 'zone',
                        value: geographicLocation.label
                      }
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
SalesReturnFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(SalesReturnFilters);
