import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormikPropsValidations from './FormikPropsValidations';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';

const styles = theme => ({
  radioButton: {
    paddingBottom: theme.spacing.unit * 0.5,
    paddingTop: theme.spacing.unit * 0.5
  }
});
export class RadioField extends PureComponent {
  static propTypes = {
    name: props => FormikPropsValidations('name', props),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: props => FormikPropsValidations('onChange', props),
    disabled: PropTypes.bool,
    classes: PropTypes.object,
    form: PropTypes.object,
    validateField: PropTypes.func,
    options: PropTypes.array.isRequired // object should be a label, value object in the array
  };
  state = {
    fieldName: this.props.name || _get(this.props, 'field.name'),
    value: this.props.value
  };

  _handleChange = ({ target: { value } }) => {
    const { field = {}, form = null } = this.props;
    const { fieldName: name } = this.state;
    const onChange = this.props.onChange || field.onChange;
    if (form) {
      form.setFieldTouched(name);
    }
    this.setState({ value });
    onChange({ target: { name, value } });
  };
  _handleBlur = ({ target: { value, name } }) => {
    const { validateField } = this.props;
    if (_isFunction(validateField)) {
      validateField(name, value);
    }
  };
  _handleShowError = event => {
    const { fieldError } = this.state;
    if (fieldError) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };
  _handleHideError = () => {
    this.setState({ anchorEl: null });
  };
  componentDidMount() {
    this._setFieldError();
  }
  _setFieldError = () => {
    const { fieldName } = this.state;
    const { form = {} } = this.props;
    const { errors } = form;
    const error = _get(errors, fieldName, '');
    this.setState({ fieldError: error });
  };

  render() {
    const { options, name, classes, disabled } = this.props;
    const { value } = this.state;
    return (
      <RadioGroup
        name={name}
        value={value}
        onChange={this._handleChange}
        onBlur={this._handleBlur}
        onMouseEnter={this._handleShowError}
        onFocus={this._handleShowError}
        onMouseOut={this._handleHideError}
      >
        {options.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              value={option.value}
              disabled={disabled}
              control={<Radio classes={{ root: classes.radioButton }} />}
              label={option.label}
            />
          );
        })}
      </RadioGroup>
    );
  }
}

export default withStyles(styles)(RadioField);
