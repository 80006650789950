import _pickBy from 'lodash/pickBy';

const quotaTransferFilters = filters => {
  const {
    contractReceiving,
    contractGiving,
    supplier,
    type,
    status,
    saleNumber,
    page,
    per_page
  } = filters;
  const sanitizedFilters = {
    id_eq: saleNumber || '',
    contract_id_eq: contractReceiving,
    quota_transfers_contract_id_eq: contractGiving,
    point_of_sale_supplier_name_cont: supplier,
    page: page,
    per_page
  };

  if (type === 'transfer') {
    sanitizedFilters.quota_transfers_transfer_type_eq = 1;
  }

  if (type === 'union') {
    sanitizedFilters.quota_transfers_transfer_type_eq = 0;
  }

  if (status === 'registered') {
    sanitizedFilters.quota_transfers_status_eq = 0;
  }

  if (status === 'reviewed') {
    sanitizedFilters.quota_transfers_status_eq = 3;
  }

  if (status === 'approved') {
    sanitizedFilters.quota_transfers_status_eq = 1;
  }

  if (status === 'revoked') {
    sanitizedFilters.quota_transfers_status_eq = 2;
  }
  return _pickBy(sanitizedFilters, filter => !!filter || filter == 0);
};

export default quotaTransferFilters;
