import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classnames from 'classnames';
import {
    Typography,
    withStyles,
    Button,
    Dialog
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { orangeHighlight, white } from '../../../constants/style';
import { Fragment } from 'react';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500]
    }
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit
    }
}))(MuiDialogActions);

class DataAuthorizationModal extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        title: PropTypes.string,
        companyName: PropTypes.string,
        buttonTextCancel: PropTypes.string,
        isDataAuthorizationAccepted: PropTypes.bool
    };

    render() {
        const {
            open = true,
            title,
            onClose,
            disableBackdropClick = false,
            onConfirm,
            buttonTextCancel = null,
            classes,
            customBase,
            isDataAuthorizationAccepted,
            companyName,
            disableEscapeKeyDown = false
        } = this.props;
        return (
            <Dialog
                fullWidth
                open={open}
                onClose={onClose}
                disableBackdropClick={disableBackdropClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classnames(classes.dialogBase, customBase)
                }}
                disableEscapeKeyDown={disableEscapeKeyDown}
            >
                <DialogTitle id="alert-dialog-title" onClose={onClose}>
                    {title || 'Confirmación'}
                </DialogTitle>
                <DialogContent style={{ minHeight: 150 }}>
                    <Typography align='justify' variant="caption">
                        {
                            `${companyName == 'GDC'
                                ? `GASES DEL CARIBE S.A. E.S.P.`
                                : (companyName == 'EFG'
                                    ? `EFIGAS S.A. E.S.P.`
                                    : `GASES DE LA GUAJIRA S.A. E.S.P.`
                                  )
                            }, en calidad de responsable del tratamiento de datos personales,
                              informa que los datos suministrados por las personas que diligencian este formulario serán tratados de forma segura y confidencial, de acuerdo con el
                              cumplimiento del Régimen de Protección de Datos Personales vigente en Colombia.

                              Los datos recolectados serán tratados para fines de mercadeo, publicidad, contactabilidad, envío de SMS y/o correos electrónicos, inteligencia de negocios
                              y análisis para conocer las necesidades del mercado, experiencias, preferencias y gustos respecto de nuestros usuarios del servicio de gas natural, clientes
                              y aliados del Programa Brilla y/o potenciales consumidores de los bienes y/o servicios ofertados al mercado.

                              Los datos personales serán tratados de forma directa por esta organización y/o a través de terceros encargados del tratamiento de datos personales.
                              Tales datos podrán ser procesados en Colombia y/o en otros territorios como Estados Unidos, Canadá, Europa, Latinoamérica dando cumplimiento a los
                              dispuesto en materia de Transmisión Nacional e Internacional de Datos Personales. Estos datos no serán cedidos a terceros.

                              Para el ejercicio del Habeas Data, el titular del dato personal o quien demuestre un legítimo interés conforme lo señalado en la normatividad vigente,
                              podrá hacerlo a través del siguiente correo electrónico: ${companyName == 'GDC'
                                ? `habeasdata@gascaribe.com,
                              o dirigiendo una comunicación a la siguiente dirección física: Cra. 54 No. 59 - 144 en la ciudad de Barranquilla`
                              : (companyName == 'EFG'
                                  ? ` atencionalcliente@efigas.com.co,
                                  o dirigiendo una comunicación a la siguiente dirección física: Avenida Kevin Angel Nro. 70-70 Manizales, Caldas`
                                  : ` habeasdata@gasguajira.com,
                                  o dirigiendo una comunicación a la siguiente dirección física: Cra. 15 No.14C-33 Riohacha, La Guajira`
                                )
                            }.
                              La política de protección de datos personales está disponible mediante este enlace:
                              ${companyName == 'GDC'
                                ? `https://gascaribe.com/politica-de-tratamiento-de-la-informacion-y-datos-personales/`
                                : (companyName == 'EFG'
                                    ? `https://www.efigas.com.co/Portals/0/Pdf/Documentos/POLITICA-DE-TRATAMIENTO-DE-LA-INFORMACION-Y-DATOS-PERSONALES-EFIGAS.pdf?ver=2019-08-22-205331-270&timestamp=1583878815871`
                                    : `https://www.gasesdelaguajira.com/index.php/work/politica-de-tratamiento-de-la-informacion`
                                  )
                                }.
                              De acuerdo con lo anterior, manifiesto de forma previa, expresa e informada mi autorización para el tratamiento de mis datos personales.

                              Al dar clic en el botón "Aceptar” autorizo y acepto el tratamiento de mis datos personales.`
                        }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {isDataAuthorizationAccepted === null ? (
                        <Fragment>
                            <Button onClick={() => onConfirm(true)} color="secondary" autoFocus>
                                Aceptar
                            </Button>
                            <Button onClick={() => onConfirm(false)} color="secondary" autoFocus>
                                No Aceptar
                            </Button>
                        </Fragment>
                    ) : (
                        <Button onClick={() => onConfirm(!isDataAuthorizationAccepted)} color="secondary" autoFocus>
                            {isDataAuthorizationAccepted ? 'No aceptar' : 'Aceptar'}
                        </Button>
                    )}
                    {buttonTextCancel && (
                        <Button onClick={onClose} color="secondary" autoFocus>
                            {buttonTextCancel}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = () => ({
    button: {
        backgroundColor: orangeHighlight,
        color: white
    },
    dialogContainer: {},
    dialogBase: {
        borderRadius: '2px',
        width: 600
    }
});

export default compose(withStyles(styles, { withTheme: true }))(DataAuthorizationModal);
