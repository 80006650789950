import {
  SET_MANUAL_QUOTA_LIST,
  TOGGLE_MANUAL_QUOTA_MODAL,
  MANUAL_QUOTA_CREATE_MODAL
} from '../constants/action_constants';

const initialState = {
  manualQuotas: [],
  meta: {},
  manualQuota: null,
  modalOpen: false,
  manualQuotaDetail: null,
  editCreateOpen: false,
  isEditing: false
};

export function manualQuota(state = initialState, action) {
  switch (action.type) {
    case SET_MANUAL_QUOTA_LIST:
      return {
        ...state,
        manualQuotas: action.data,
        meta: action.meta
      };
    case TOGGLE_MANUAL_QUOTA_MODAL:
      return {
        ...state,
        manualQuota: action.data,
        modalOpen: action.open
      };
    case MANUAL_QUOTA_CREATE_MODAL:
      return {
        ...state,
        editCreateOpen: action.open,
        isEditing: action.isEditing,
        manualQuotaDetail: action.selected
      };
    default:
      return state;
  }
}
