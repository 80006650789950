import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import MUITextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Masks from './Masks';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import CloseIcon from '@material-ui/icons/Close';
import _isFunction from 'lodash/isFunction';
import _isEqual from 'lodash/isEqual';
import FormikPropsValidations from './FormikPropsValidations';
import { DATATYPES } from './../../../constants/input_types';
import TextFieldStyles from './TextField.styles';
import FieldValidationPopup from '../Poppers/FieldValidation';

export class TextField extends Component {
  static propTypes = {
    name: props => FormikPropsValidations('name', props),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    onChange: props => FormikPropsValidations('onChange', props),
    validateField: PropTypes.func,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    classes: PropTypes.object,
    mask: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    startAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    endAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    min: PropTypes.number,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    datatype: PropTypes.oneOf([DATATYPES.STRING, DATATYPES.NUMBER]),
    debounce: PropTypes.number,
    form: PropTypes.object,
    otpStyle: PropTypes.bool,
    _inputProps: PropTypes.object,
    autoFocus: PropTypes.bool,
    _ref: PropTypes.oneOfType([
      // Either a function
      PropTypes.func,
      // Or the instance of a DOM native element (see the note about SSR)
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  };
  componentDidMount() {
    this._setFieldError();
  }
  shouldComponentUpdate(nextProps, nextState) {
    const { form: currentForm = {} } = this.props;
    const { errors: currentErrors = {} } = currentForm;
    const { form = {} } = nextProps;
    const { errors = {} } = form;

    if (!_isEqual(currentErrors, errors)) {
      return true;
    }
    if (nextProps.startAdornment !== this.props.startAdornment) {
      return true;
    }
    if (
      nextProps.value !== nextState.value ||
      !_isEqual(this.state, nextState)
    ) {
      return true;
    } else {
      return false;
    }
  }

  _setFieldError = () => {
    const { fieldName } = this.state;
    const { form = {}, error } = this.props;
    const { errors } = form;
    const errorValue = _get(errors, fieldName, '') || error;
    this.setState({ fieldError: errorValue });
  };

  componentDidUpdate(prevProps) {
    const { validateField } = this.props;
    const { fieldName } = this.state;
    if (
      _get(prevProps, 'form.errors') !== _get(this.props, 'form.errors') ||
      prevProps.error !== this.props.error
    ) {
      this._setFieldError();
    }

    if (prevProps.value !== this.props.value) {
      if (_isFunction(validateField)) {
        validateField(fieldName, this.props.value);
      }
      this.setState({ value: this.props.value || '' });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      anchorEl: null,
      fieldError: '',
      fieldName: this.props.name || _get(this.props, 'field.name')
    };
  }
  syncChange = e => {
    this._handleChange(e);
  };
  _handleBlur = ({ target: { value, name } }) => {
    const { validateField } = this.props;
    if (_isFunction(validateField)) {
      validateField(name, value);
    }
  };

  _handleChange = ({ target: { value, name } }) => {
    const { field = {}, validateField, form = null } = this.props;
    const { fieldError } = this.state;

    const onChange = this.props.onChange || field.onChange;
    if (form) {
      form.setFieldTouched(name);
    }
    if (fieldError && _isFunction(validateField)) {
      validateField(name, value);
    }
    onChange({ target: { name, value } });
  };

  _handleFieldChange = e => {
    if (e.persist) {
      e.persist();
    }

    this.setState({ value: e.target.value });
    this.syncChange(e);
  };

  _handleResetField = () => {
    const { field = {} } = this.props;

    const onChange = this.props.onChange || field.onChange;

    this.setState({ value: '' });
    onChange({ target: { name: this.state.name, value: '' } });
  };
  _handleShowError = event => {
    const { fieldError } = this.state;
    if (fieldError) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };
  _handleHideError = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const {
      classes,
      label,
      multiline = false,
      startAdornment = null,
      endAdornment = null,
      fullWidth = false,
      disabled = false,
      mask = 'default',
      type = 'text',
      textFieldType,
      placeholder = null,
      min = null,
      max = null,
      _ref = null,
      maxLength = null,
      rows = 1,
      step = 1,
      otpStyle = false,
      _inputProps = null,
      autoFocus = false
    } = this.props;
    const { fieldName, fieldError, anchorEl } = this.state;
    const endAdornmentSearchContent = textFieldType === 'search' &&
      this.state.value && (
        <CloseIcon
          className={classes.closeIcon}
          onClick={this._handleResetField}
        />
      );
    const endAdornmentContent =
      endAdornment || endAdornmentSearchContent || null;
    const inputProps = {
      type,
      className: classnames({
        [classes.inputBaseRoot]: !multiline,
        [classes.inputBaseRootWidthEndAddorment]: endAdornment,
        [classes.inputBaseRootWidthStartAdornment]: startAdornment
      }),
      ..._inputProps
    };
    if (min) {
      inputProps.min = min;
    }
    if (max) {
      inputProps.max = max;
    }
    if (_ref) {
      inputProps.ref = _ref;
    }
    if (maxLength) {
      inputProps.maxLength = maxLength;
    }
    if (step) {
      inputProps.step = step;
    }

    return (
      <Fragment>
        <MUITextField
          id="outlined-name"
          className={
            otpStyle
              ? classnames(classes.textField, classes.root, classes.codeInput)
              : classnames(classes.textField, classes.root)
          }
          variant="outlined"
          classes={{
            root: classes.root
          }}
          name={fieldName}
          value={this.state.value || ''}
          label={label}
          disabled={disabled}
          onChange={this._handleFieldChange}
          onBlur={this._handleBlur}
          onMouseEnter={this._handleShowError}
          onFocus={this._handleShowError}
          onMouseOut={this._handleHideError}
          fullWidth={fullWidth}
          autoFocus={autoFocus}
          InputProps={{
            inputComponent: Masks[mask],
            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : null,
            endAdornment: endAdornmentContent ? (
              <InputAdornment position="end">
                {endAdornmentContent}
              </InputAdornment>
            ) : null,
            classes: {
              root: classes.outlinedInput,
              focused: classes.focused,
              notchedOutline: classes.notchedOutline,
              input: otpStyle && classes.codeLabel
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focused
            }
          }}
          // eslint-disable-next-line
          inputProps={inputProps}
          FormHelperTextProps={{
            classes: {
              root: classes.formHelperTextRoot
            }
          }}
          error={!!fieldError}
          multiline={multiline}
          placeholder={placeholder}
          rows={rows}
        />
        <FieldValidationPopup anchorEl={anchorEl} message={fieldError} />
      </Fragment>
    );
  }
}

export default withStyles(TextFieldStyles)(TextField);
