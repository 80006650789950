import { isNull } from 'lodash';
import {
  LIST_PREVIEW_ANNOUNCEMENTS,
  MODAL_CONTENT
} from '../constants/announcements_constants';

const initialState = {
  previewList: [],
  modalContent: []
};

export function announcement(state = initialState, action) {
  switch (action.type) {
    case LIST_PREVIEW_ANNOUNCEMENTS:
      return {
        ...state,
        previewList: action.data
      };
    case MODAL_CONTENT:
      return {
        ...state,
        modalContent: action.data
      };
    default:
      return state;
  }
}
