import ResetPasswordPage from './ResetPasswordPage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { newAlert } from '../../../actions/alert_actions';
import {
  fetchSessionData,
  sendResetPassword,
  confirmTokenRecaptchaV2
} from '../../../actions/session_actions';

function mapStateToProps(state) {
  return {
    loading: state.requestState.requestLoginSend,
    companyCode: state.sessionData.companyName,
    confirmCaptcha: state.sessionData.confirmCaptcha
  };
}
function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchSessionData: bindActionCreators(fetchSessionData, dispatch),
    sendResetPassword: bindActionCreators(sendResetPassword, dispatch),
    confirmTokenRecaptchaV2: bindActionCreators(
      confirmTokenRecaptchaV2,
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordPage);
