import { SET_POINT_USER_ASSOCIATION_LIST, UPDATED_POINT_USER_ASSOCIATION } from '../constants/action_constants';
import { newAlert, serverStatusError } from './alert_actions';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import pointUserAssociationFilters from '../helpers/sanitizers/pointUserAssociationFilter';
import AxiosRequest from '../services/axios_request';
import _get from 'lodash/get';


const axiosRequest = new AxiosRequest();

//* PURE ACTION FUNCTIONS
function setList(items, pagina) {
  return {
    type: SET_POINT_USER_ASSOCIATION_LIST,
    data: items,
    meta: pagina
  };
}
function updatePointUserAssociation() {
  return {
    type: UPDATED_POINT_USER_ASSOCIATION,
  };
}
// * LIST POINT OF SALES USER ASSOCIATIONS *
export const fetchPointUserAssociation = (params = {}) => async dispatch => {
  dispatch(sendRequest('pointUserAssociation'));
  history.replace(`/sellers_request?${stringifyQuery(params)}`);
  try {
    const payload = await axiosRequest.get(
      `users/fetch/point_of_sales`,
      pointUserAssociationFilters(params)
    );
    dispatch(setList(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('pointUserAssociation'));
  }
};
// * APPROVE ASSOCIATION *
export const pointUserAssociationRevision = (body = {}, queryParams) => async dispatch => {
  try {
    dispatch(sendRequest('PointUserAssociationRevision'));
    const payload = await axiosRequest.put(
      `users/${body.id}/point_of_sales_approve`
    );
    dispatch(newAlert('success', 'success', 'Asociación aprobada exitosamente.'));
    dispatch(updatePointUserAssociation());
    dispatch(fetchPointUserAssociation(queryParams));
  } catch (errors) {
    if (errors.errors.title){
      dispatch(newAlert('error', 'ERROR:', errors.errors.title));
    }else{
      dispatch(newAlert('error', 'ERROR:', errors.errors.base));
    }
    return errors;
  } finally {
    dispatch(completeRequest('PointUserAssociationRevision'));
  }
};
// * REVOKE ASSOCIATION *
export const pointUserAssociationRevoke = (body = {}, queryParams) => async dispatch => {
  try {
    dispatch(sendRequest('PointUserAssociationRevoke'));
    const payload = await axiosRequest.delete(
      `users/${body.id}/point_of_sales_revoke`
    );
    dispatch(newAlert('success', 'success', 'Asociación rechazada exitosamente.'));
    dispatch(updatePointUserAssociation());
    dispatch(fetchPointUserAssociation(queryParams));
  } catch (errors) {
    dispatch(newAlert('error', 'ERROR:', errors.errors.title));
    return errors;
  } finally {
    dispatch(completeRequest('PointUserAssociationRevoke'));
  }
};
