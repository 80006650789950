import {
  USERS_SET,
  USER_INFO_SET,
  USER_NAMES_SET,
  USER_CREATE_INFO_SET,
  USER_CREATE_INFO_CLEAR,
  USER_CREATE_ERROR,
  USER_CLEAR_ERROR
} from '../constants/action_constants';
import { newAlert } from './alert_actions';
import { setCurrentUser } from './authentication_actions';
import RequestService from '../services/request_service';
import AxiosRequest from '../services/axios_request';
import AxiosNodeRequest from '../services/axios_node_request';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { history } from '../helpers/history';
import userFiltersSanitizer from '../helpers/sanitizers/userFilters';
import { fetchPointsOfSaleNamesAssociation } from './points_of_sale_actions';
const requestService = new RequestService();
const requestServiceNode = new RequestService('node');
const axiosRequest = new AxiosRequest();
const axiosNodeRequest = new AxiosNodeRequest();

//* PURE ACTION FUNCTIONS

function setUsers(items, pagi) {
  return {
    type: USERS_SET,
    data: items,
    meta: pagi
  };
}

export function setUserNames(items) {
  return {
    type: USER_NAMES_SET,
    data: items
  };
}

function setUserInfo(info) {
  return {
    type: USER_INFO_SET,
    data: info
  };
}

function updateUserState(user) {
  return user.isCurrentUser ? setCurrentUser(user) : setUserInfo(user);
}

export function setCreationInfo(info) {
  return {
    type: USER_CREATE_INFO_SET,
    data: info
  };
}

export function clearCreationInfo() {
  return {
    type: USER_CREATE_INFO_CLEAR,
    data: null
  };
}

export function userCreateError(errors) {
  return {
    type: USER_CREATE_ERROR,
    errors
  };
}

export function userClearError() {
  return {
    type: USER_CLEAR_ERROR,
    data: null
  };
}

//* VIEW ACTION FUNCTIONS
export const fetchUsers = (params = {}, route) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`${route}?${stringifyQuery(params)}`);
  const blocked = route === '/black-list' ? true : undefined;
  try {
    const payload = await requestService.get(
      'users',
      userFiltersSanitizer({ ...params, blocked })
    );
    dispatch(setUsers(payload.data, payload.meta));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest());
  }
};

export const fetchFilterUserList = () => async dispatch => {
  dispatch(sendRequest());
  try {
    const payload = await requestService.get('list_users_names');
    dispatch(setUserNames(payload.data));
  } catch (errors) {
    dispatch(newAlert('error', 'ERROR:', e.errors.base));
  } finally {
    dispatch(completeRequest());
  }
};

export const fetchUserNames = (id, params = {}) => async dispatch => {
  dispatch(sendRequest('UserNames'));
  try {
    const payload = await requestService.get(
      `users/${id}/preview`,
      userFiltersSanitizer(params)
    );
    dispatch(setUserNames(payload.data));
  } catch (errors) {
    errors.json().then(e => {
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    });
  } finally {
    dispatch(completeRequest('UserNames'));
  }
};

export const fetchUserInfo = id => async dispatch => {
  dispatch(sendRequest('UserDetail'));
  try {
    const payload = await requestServiceNode.get(`users/${id}`);
    dispatch(setUserInfo(payload.data));
    dispatch(fetchPointsOfSaleNamesAssociation({ id }));
    return { success: true };
  } catch (errors) {
    // console.log(errors);
    const e = await errors.json();
    e && e.message && dispatch(newAlert('error', 'ERROR:', e.message));
    return { success: false };
  } finally {
    dispatch(completeRequest('UserDetail'));
  }
};

export const relatePointOfSalesToUser = (
  params = {},
  user
) => async dispatch => {
  dispatch(sendRequest('UpdatePointOfSalesOfUser'));
  try {
    const payload = await requestServiceNode.put('users/relatePointOfSalesToUser', params);
    user.pointOfSales = payload.data.pointOfSales;
    dispatch(updateUserState(user));

    dispatch(newAlert('success', 'sucess', payload.data.message));
    dispatch(completeRequest('UpdatePointOfSalesOfUser'));
  } catch (errors) {
    const e = await errors.json();
    e && e.errors && dispatch(newAlert('error', 'ERROR:', e.errors.base));
  } finally {
    dispatch(completeRequest('UpdatePointOfSalesOfUser'));
  }
};

export const fetchUserSessionData = user => async dispatch => {
  dispatch(sendRequest('FetchUserSessionData'));
  try {
    const payload = await requestServiceNode.get(`sessions`);
    const userInfo = { ...payload.data.attributes };
    delete userInfo.token;
    const newCurrentUserData = {
      data: Object.assign(user, userInfo)
    };
    dispatch(setCurrentUser(Object.assign(user, userInfo)));
  } catch (errors) {
    // console.log(errors);
    const e = await errors.json();
    e && e.message && dispatch(newAlert('error', 'ERROR:', e.message));
  } finally {
    dispatch(completeRequest('FetchUserSessionData'));
  }
};

export const validateUser = (params = {}) => async dispatch => {
  dispatch(sendRequest('NewUser'));
  try {
    dispatch(userClearError());
    const payload = await axiosNodeRequest.post('users/validate', params);
    dispatch(setCreationInfo(payload.data));
  } catch (error) {
    if (error.errors) {
      dispatch(userCreateError(error.errors));
      if (error.errors.base) {
        dispatch(newAlert('error', 'ERROR:', error.errors.base));
      }
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
        )
      );
    }
    dispatch(errorOnRequest('NewUser', error.message));
  } finally {
    dispatch(completeRequest('NewUser'));
  }
};

export const createUser = (params = {}) => async dispatch => {
  dispatch(sendRequest('NewUser'));
  try {
    const payload = await axiosNodeRequest.post('users', params);
    if (payload.status === 'success') {
      dispatch(newAlert('success', 'success', payload.data.message));
    }

    return true;
  } catch (errors) {
    const e = await errors;
    if (e.errors) {
      if (e.errors.base) {
        dispatch(newAlert('error', 'ERROR:', e.errors.base));
      }
    }
    dispatch(errorOnRequest('NewUser', e.message));
  } finally {
    dispatch(completeRequest('NewUser'));
  }
};

export const updateAdditionalInfo = (user, params = {}) => async dispatch => {
  dispatch(sendRequest('UpdateAdditionalInfo'));
  try {
    const payload = await requestService.patch(
      `users/${user.id}/update_additional_info`,
      params
    );
    user = Object.assign(user, payload.data);
    dispatch(updateUserState(user));
    payload.meta &&
      payload.meta.message &&
      dispatch(newAlert('success', 'sucess', payload.meta.message));
    payload.message && dispatch(newAlert('success', 'sucess', payload.message));
  } catch (errors) {
    const e = await errors.json();
    dispatch(errorOnRequest('UpdateAdditionalInfo', e.message));
  } finally {
    dispatch(completeRequest('UpdateAdditionalInfo'));
  }
};;

export const updateUserPictureProfile = (
  user,
  params = {}
) => async dispatch => {
  dispatch(sendRequest('UpdateUserPictureProfile'));
  try {
    const payload = await requestService.post(
      `users/${user.id}/update_picture_profile`,
      params,
      {},
      true
    );
    dispatch(updateUserState(user));
    payload.meta &&
      payload.meta.message &&
      dispatch(newAlert('success', 'sucess', payload.meta.message));
    payload.message && dispatch(newAlert('success', 'sucess', payload.message));
  } catch (errors) {
    const e = await errors.json();
    dispatch(errorOnRequest('UpdateUserPictureProfile', e.message));
  } finally {
    dispatch(completeRequest('UpdateUserPictureProfile'));
  }
};

export const deletePictureProfile = (user, params = {}) => async dispatch => {
  dispatch(sendRequest('DeletePictureProfile'));
  try {
    const payload = await requestService.post(
      `users/${user.id}/delete_picture_profile`,
      params,
      {},
      true
    );
    dispatch(updateUserState(user));
    payload.meta &&
      payload.meta.message &&
      dispatch(newAlert('success', 'sucess', payload.meta.message));
    payload.message && dispatch(newAlert('success', 'sucess', payload.message));
  } catch (errors) {
    const e = await errors.json();
    dispatch(errorOnRequest('DeletePictureProfile', e.message));
  } finally {
    dispatch(completeRequest('DeletePictureProfile'));
  }
};

export const updateRoles = (params = {}, user) => async dispatch => {
  dispatch(sendRequest('UpdateRoles'));
  try {
    const payload = await requestServiceNode.put(`users/updateRoles`, params);
    user = Object.assign(user, payload.data);

    dispatch(updateUserState(user));

    dispatch(newAlert('success', 'sucess', payload.message));
  } catch (errors) {
    const e = await errors.json();
    e.message && dispatch(newAlert('error', 'error', e.message));
    dispatch(errorOnRequest('UpdateRoles', e.message));
  } finally {
    dispatch(completeRequest('UpdateRoles'));
  }
};

export const updateUser = (user, params = {}, type = '') => async dispatch => {
  dispatch(sendRequest(type));
  try {
    const payload = await requestServiceNode.put(`users/${user.id}`, { ...params, type });
    user = Object.assign(user, payload.data);

    dispatch(updateUserState(user));
    dispatch(newAlert('success', 'sucess', payload.message));
    return { success: true };
  } catch (errors) {
    const e = await errors.json();

    if (e.errors && e.errors.base) {
      dispatch(newAlert('error', 'error', e.errors.base));
      dispatch(errorOnRequest('UpdateUser', e.errors.base));
    }

    return { success: false };
  } finally {
    dispatch(completeRequest(type));
  }
};

export const updatePassword = (user, params = {}) => async dispatch => {
  dispatch(errorOnRequest('UpdatePassword', {}));
  dispatch(sendRequest('UpdatePassword'));
  try {
    const payload = await requestServiceNode.put('users/updatePasswordWithToken', params);
    user = Object.assign(user, payload.data.user);
    dispatch(updateUserState(user));
    dispatch(newAlert('success', 'sucess', payload.data.message));
    return true;
  } catch (errors) {
    const e = await errors.json();

    e &&
      e.errors &&
      e.errors.base &&
      dispatch(newAlert('error', 'ERROR:', e.errors.base));

    e && e.errors && dispatch(errorOnRequest('UpdatePassword', e.errors));
    return false;
  } finally {
    dispatch(completeRequest('UpdatePassword'));
  }
};

export const getOtpUserInfo = (token, type = '') => async dispatch => {
  dispatch(sendRequest(type));
  try {
    const payload = await axiosRequest.get(
      `users/${token}/get_user_from_token`
    );
    dispatch(
      setCreationInfo({
        email: payload.email,
        username: payload.username,
        regex: payload.regex,
        description: payload.description
      })
    );
  } catch (errors) {
    const e = await errors;
    dispatch(setCreationInfo({ errors: e }));
  } finally {
    dispatch(completeRequest(type));
  }
};

export const confirmUserEmail = (params, type = '') => async dispatch => {
  dispatch(sendRequest(type));
  try {
    dispatch(userClearError());
    const payload = await requestService.post(
      'users/confirm_user_email',
      params
    );
    dispatch(setCurrentUser(payload.data));
    dispatch(newAlert('success', 's', 'Contraseña establecida exitosamente'));
    history.push('/');
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      dispatch(errorOnRequest('UpdatePassword', e.errors));
      if (e.errors.base) {
        dispatch(newAlert('error', 'ERROR:', e.errors.base));
      }
    }
  } finally {
    dispatch(completeRequest(type));
  }
};

export const updateMedia = (user, params = {}, type = '') => async dispatch => {
  dispatch(sendRequest(type));
  try {
    const payload = await requestService.post(
      `users/${user.id}/update_media`,
      params,
      {},
      true
    );
    return true;
  } catch (errors) {
    const e = await errors.json();
    e && e.errors && dispatch(errorOnRequest('UpdateMedia', e.errors));

    e &&
      e.errors &&
      e.errors.base &&
      dispatch(newAlert('error', 'ERROR:', e.errors.base.join(' ')));
    return false;
  } finally {
    dispatch(completeRequest(type));
  }
};

export const resendConfirmationEmail = (
  user,
  params = {}
) => async dispatch => {
  dispatch(sendRequest('ResendConfirmationEmail'));
  try {
    const payload = await requestService.post(
      `users/${user.id}/resend_confirmation_email`,
      params,
      {},
      true
    );
    payload.status && dispatch(newAlert('success', 'success', payload.status));
  } catch (errors) {
    const e = await errors.json();
    e &&
      e.errors &&
      dispatch(errorOnRequest('ResendConfirmationEmail', e.errors));
  } finally {
    dispatch(completeRequest('ResendConfirmationEmail'));
  }
};

export const confirmUpdateMedia = (
  user,
  params,
  type = ''
) => async dispatch => {
  dispatch(sendRequest(type));
  try {
    const payload = await requestService.patch(
      `users/${user.id}/confirm_media_updating`,
      params,
      {},
      true
    );
    dispatch(updateUserState(user));
    payload.status && dispatch(newAlert('success', 'success', payload.status));
    return true;
  } catch (errors) {
    const e = await errors.json();
    e &&
      e.errors &&
      e.errors.base &&
      dispatch(newAlert('error', 'ERROR:', e.errors.base.join(' ')));
    return false;
  } finally {
    dispatch(completeRequest(type));
  }
};
