const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    paddingTop: theme.spacing.unit * 7,
    paddingBottom: theme.spacing.unit * 7,
    '@media (max-width:1440px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  details: {
    marginLeft: theme.spacing.unit * 4,
    marginTop: 0,
    display: 'block',
    '@media (max-width:1440px)': {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 0,
      marginTop: theme.spacing.unit * 4
    }
  },
  action: {
    marginTop: theme.spacing.unit * 2,
    width: 'auto',
    '&:first-child': {
      marginTop: theme.spacing.unit * 3
    },
    '@media (max-width:1240px)': {
      width: '100%'
    }
  },
  userName: {
    marginTop: [theme.spacing.unit * 1.25, '!important'],
    marginBottom: [theme.spacing.unit * 0.5, '!important']
  }
});

export default styles;
