import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Card } from '@material-ui/core';
import classnames from 'classnames';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ActivityIndicator from '../../shared/ActivityIndicator';
import { toCapitalize } from '../../../helpers/utilityFunctions';

import InsuranceErrors from '../Insurance/InsuranceErrors';
import InsuranceInfo from './InsuranceInfo';
import ClientInfo from './ClientInfo';
import SecondInsuredInfo from './SecondInsuredInfo';
import Activity from './Activity/Activity';
import Movements from './Table/Movements';
import Beneficiaries from './Table/Beneficiaries';
import AffiliatesInfo from './AffiliatesInfo';

class InsuranceDetail extends React.Component {
  componentDidMount() {
    this.fetchInsuranceDetails();
  }

  fetchInsuranceDetails = () => {
    this.props.fetchInsuranceInfo(this.props.match.params.id);
    this.props.fetchCancellationCausals();
  };

  _handleEditInsurance = params => {
    this.props.editInsurance(params, this.props.match.params.id);
  };

  _handleCancelInsurance = params => {
    this.props.cancelInsurance(params, this.props.match.params.id);
  };

  _getActivities = () => {
    const { movementsDetails } = this.props;
    const activities = [];
    movementsDetails &&
      movementsDetails.forEach(move => {
        if (
          move.type != 'registered' &&
          move.type != 'cancelled' &&
          move.type != 'modified'
        )
          return;
        const causal = toCapitalize(move.comment);
        activities.push({
          title: move.type || '',
          comment: causal || '',
          date: move.createdAt,
          causal: move.causal,
          user: move.user
        });
      });
    return activities;
  };

  render() {
    const {
      classes,
      insuranceDetail,
      clientDetail,
      affiliatesDetail,
      beneficiariesDetail,
      secondInsuredDetail,
      movementsDetails,
      requestSend,
      server_errors,
      baseErrorOpen,
      baseErrorText,
      baseErrorTitle,
      baseErrorRoute,
      toggleBaseErrorModal
    } = this.props;

    return (
      <Fragment>
        <InsuranceErrors
          server_errors={server_errors}
          baseErrorOpen={baseErrorOpen}
          baseErrorText={baseErrorText}
          baseErrorTitle={baseErrorTitle}
          toggleModal={toggleBaseErrorModal}
          baseErrorRoute={baseErrorRoute}
        />
        {requestSend && <ActivityIndicator />}
        {!requestSend && insuranceDetail && clientDetail && movementsDetails && (
          <div>
            <div className={classes.titleRootWrapper}>
              <h1 className={classnames(classes.headline4, classes.title)}>
                {`Servicio #${insuranceDetail.id}`}
              </h1>
            </div>
            <Grid container className={classes.outContainer} spacing={16}>
              <Grid item sm={9}>
                <Grid item sm={12}>
                  <InsuranceInfo
                    insurance={insuranceDetail}
                    clientDetail={clientDetail}
                    onEdit={this._handleEditInsurance}
                    onCancel={this._handleCancelInsurance}
                    cancellationCausals={this.props.cancellationCausals}
                    currentUser={this.props.currentUser}
                  />
                </Grid>
                <Grid item sm={12}>
                  <ClientInfo serviceDetail={clientDetail} />
                </Grid>
                {secondInsuredDetail &&
                  Object.keys(secondInsuredDetail).length != 0 && (
                    <Grid item sm={12}>
                      <SecondInsuredInfo serviceDetail={secondInsuredDetail} />
                    </Grid>
                  )}
                {affiliatesDetail && Object.keys(affiliatesDetail).length != 0 && (
                  <Grid item sm={12}>
                    <AffiliatesInfo affiliates={affiliatesDetail} />
                  </Grid>
                )}
                {beneficiariesDetail && (
                  <Grid item sm={12}>
                    <Beneficiaries rows={beneficiariesDetail} />
                  </Grid>
                )}
                <Grid item sm={12}>
                  <Movements rows={movementsDetails} />
                </Grid>
              </Grid>
              <Grid item sm={3}>
                <Activity activities={this._getActivities()} />
              </Grid>
            </Grid>
          </div>
        )}
      </Fragment>
    );
  }
}

const styles = () => ({
  outContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  fullWidth: {
    width: '100%'
  }
});

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles
  )
)(InsuranceDetail);
