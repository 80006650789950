import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';
import { compose } from 'redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { PersonOutline } from '@material-ui/icons';
import TypographyStyles from '../../../styles/typography';
import classnames from 'classnames';

class ContactListItem extends PureComponent {
  render() {
    const { classes, photo, heading, children, button = true, onClick, selected = false } = this.props;

    return (
      <ListItem
        button={button}
        onClick={onClick}
        alignItems="flex-start"
        classes={{
          root: selected ? classes.listItemSelected : classes.listItem
        }}
      >
        <ListItemAvatar>
        <Avatar src={photo}><PersonOutline /></Avatar>
        </ListItemAvatar>
        <ListItemText
          classes={{
            primary: classnames(classes.subtitle2, classes.primary),
            secondary: classes.caption
          }}
          primary={heading}
          secondary={<React.Fragment>{children}</React.Fragment>}
          secondaryTypographyProps={{
            component: 'div'
          }}
        />
      </ListItem>
    );
  }
}

const styles = () => ({
  listItemSelected: {
    padding: '16px 24px !important',
    backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
  },
  listItem: {
    padding: '16px 24px !important'
  },
  primary: {
    margin: '0 !important'
  }
});

export default compose(
  withStyles(combineStyles(styles, TypographyStyles), { withTheme: true })
)(ContactListItem);
