import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PromissoryDetail from './PromissoryDetail';
import {
  fetchPromissory,
  approvePromissory,
  editPromissory,
  cancelPromissory,
  rejectPromissory
} from '../../../actions/promissory_actions';
import { fetchGeographicLocations } from '../../../actions/geographic_location_actions';
import { newAlert } from '../../../actions/alert_actions';

function mapStateToProps(state) {
  const { promissory } = state.promissory;
  return {
    promissory,
    currentUser: state.authentication.currentUser,
    canApprovePromissory: state.authentication.currentUser.canApprovePromissory,
    canUpdateUniquePromissory:
      state.authentication.currentUser.canUpdateUniquePromissory,
    canUpdateNoUniquePromissory:
      state.authentication.currentUser.canUpdateNoUniquePromissory,
    canCancelPromissory: state.authentication.currentUser.canCancelPromissory,
    canCancelUnmaterializedPromissory: state.authentication.currentUser.canCancelUnmaterializedPromissory,
    geographicLocations: state.geographicLocation.list,
    editPromissorySaved: state.promissory.editPromissorySaved,
    cancelPromissorySaved: state.promissory.cancelPromissorySaved,
    revokePromissorySaved: state.promissory.revokePromissorySaved,
    requestEditPromissorySend: state.requestState.requestEditPromissorySend,
    requestErrorsOnEditPromissory:
      state.requestState.requestErrorsOnEditPromissory,
    requestFetchPromissorySend: state.requestState.requestFetchPromissorySend,
    requestCancelPromissorySend: state.requestState.requestCancelPromissorySend,
    requestErrorsOnCancelPromissory:
      state.requestState.requestErrorsOnCancelPromissory,
    requestApprovePromissorySend:
      state.requestState.requestApprovePromissorySend,
    requestErrorsOnApprovePromissory:
      state.requestState.requestErrorsOnApprovePromissory,
    requestRejectPromissorySend: state.requestState.requestRejectPromissorySend,
    rejectPromissoryCausals: state.globalSetting.orgData.osf_reject_causes,
    cancelPromissoryCausals:
      state.globalSetting.orgData.promissory_revoke_causes,
    approvePromissorySaved: state.promissory.approvePromissorySaved,
    requestPromissoryUrlSend: state.requestState.requestPromissoryUrlSend,
    pdfUrl: state.file.promissoryUrl,
    canAddCommentsToPrommissory:
      state.authentication.currentUser.canAddCommentsToPrommissory,
    canViewSales: state.authentication.currentUser.canViewSales,
    canUpdateUnmaterializedPromissory: state.authentication.currentUser.canUpdateUnmaterializedPromissory
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPromissory: bindActionCreators(fetchPromissory, dispatch),
    approvePromissory: bindActionCreators(approvePromissory, dispatch),
    rejectPromissory: bindActionCreators(rejectPromissory, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    editPromissory: bindActionCreators(editPromissory, dispatch),
    fetchGeographicLocations: bindActionCreators(
      fetchGeographicLocations,
      dispatch
    ),
    cancelPromissory: bindActionCreators(cancelPromissory, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromissoryDetail);
