import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    marginRight: 8,
    padding: '6px 8px',
    width: 90
  },
  button: {
    color: theme.palette.secondary
  },
  loadingButton: {
    color: theme.palette.secondary
  }
});

const LoadingButton = props => {
  const { classes, children, loading, disabled, onClick, ...rest } = props;
  const button = loading ? classes.loadingButton : classes.button;

  return (
    <Button
      className={classnames(button, classes.root)}
      onClick={loading || disabled ? null : onClick}
      disabled={disabled}
      {...rest}
    >
      {loading ? <CircularProgress color={'secondary'} size={25} /> : children}
    </Button>
  );
};

export default withStyles(styles, { withTheme: true })(LoadingButton);
