import {
  LIST_PREVIEW_NOTIFICATIONS,
  LIST_NOTIFICATIONS,
} from '../constants/notifications_constants';

const initialState = {
  previewList: [],
};

export function notification(state = initialState, action) {
  switch (action.type) {
    case LIST_PREVIEW_NOTIFICATIONS:
      return {
        ...state,
        previewList: action.data,
      };
    default:
      return state;
  }
}
