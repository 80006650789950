import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TypographyStyles from '../../../styles/typography';
import combineStyles from '../../../helpers/combineStyles';
import classnames from 'classnames';

const DialogFormStyles = theme => ({
  dialogBase: {
    borderRadius: '2px'
  },
  titleContainer: { display: 'flex' },
  secondaryAction: {
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 3
  },
  titleRoot: {
    flex: 1,
    '& h6': {
      color: theme.typography.color.main,
      fontFamily: theme.typography.font.main,
      fontSize: '20px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      lineHeight: '24px',
      margin: 0
    }
  },
  titleCustom: {}
});

export class DialogForm extends PureComponent {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    footer: PropTypes.node,
    secondaryAction: PropTypes.node
  };

  render() {
    const {
      title,
      open,
      onClose,
      children,
      footer,
      maxWidth,
      fullWidth = false,
      classes,
      disableBackdropClick = false,
      secondaryAction
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        scroll={'paper'}
        classes={{
          paper: classes.dialogBase
        }}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        disableBackdropClick={disableBackdropClick}
        disableRestoreFocus={true}
      >
        <div
          className={classnames(classes.titleCustom, classes.titleContainer)}
        >
          <DialogTitle classes={{ root: classes.titleRoot }}>
            {title}
          </DialogTitle>
          <div className={classes.secondaryAction}>{secondaryAction}</div>
        </div>
        <DialogContent classes={{ root: classes.dialogBase }}>
          {children}
        </DialogContent>
        <DialogActions>{footer}</DialogActions>
      </Dialog>
    );
  }
}
export default withStyles(combineStyles(TypographyStyles, DialogFormStyles))(
  DialogForm
);
