import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, withStyles } from '@material-ui/core';
import CustomDialog from '../../shared/Modals/CustomDialog';

class ProductDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceSupplier: ''
    };
  }

  _handleSave = () => {
    const { sale, batch } = this.props;
    const { invoiceSupplier } = this.state;
    if (!batch) {
      this.props.productDelivery({ id: sale.id, deliveryComments: invoiceSupplier });
    } else {
      // TODO: CLIENT ITERATIVE REQUESTS TO API
    }
  };

  _handleClose = () => {
    if (!this.props.requestSend) {
      this.props.toggleSaleAction('Product-Delivery', false);
      this.setState({
        invoiceSupplier: ''
      });
    }
  };

  _handleChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  render() {
    const { open = false, requestSend, batch } = this.props;
    const { invoiceSupplier } = this.state;
    return (
      <div>
        <CustomDialog
          open={open}
          loading={requestSend}
          disabled={requestSend}
          disableBackdropClick={true}
          onClose={this._handleClose}
          onConfirm={this._handleSave}
          title={`Entrega total de ${batch ? 'ventas' : 'productos'}`}
          contentText={`Puede añadir el número de factura correspondiente a la venta en su sistema y esta será visible desde Sistema Brilla.`}
          buttonText={'ENTREGAR'}
        >
          <Grid container spacing={16}>
            {batch ? (
              batch.map(sale => {
                if (!sale) return null;
                return (
                  <Grid item xs={batch.length > 1 ? 6 : 12} key={sale.id}>
                    <TextField
                      label={`Factura de venta #${sale.id}`}
                      name={`invoiceSupplier_${sale.id}`}
                      margin="normal"
                      variant="outlined"
                      onChange={this._handleChange}
                      value={this.state[`invoiceSupplier_${sale.id}`]}
                      disabled={requestSend}
                      fullWidth
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <TextField
                  label="# Factura del aliado"
                  name="invoiceSupplier"
                  margin="normal"
                  variant="outlined"
                  onChange={this._handleChange}
                  value={invoiceSupplier}
                  disabled={requestSend}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = (/* theme */) => ({});

ProductDelivery.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(ProductDelivery);
