import {
  BRANDS_SET,
  BRAND_SHOW
} from '../constants/action_constants';
import { newAlert } from './alert_actions';
import RequestService from '../services/request_service';
const requestService = new RequestService('node');
import { sendRequest, completeRequest } from './request_state';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import brandFilterSanitizer from '../helpers/sanitizers/brandFilterSanitizer';

//* PURE ACTION FUNCTIONS
function success(items, pagi) {
  return {
    type: BRANDS_SET,
    data: items,
    meta: pagi
  };
}
function setModal(open, isEditing, selected) {
  return {
    type: BRAND_SHOW,
    open: open,
    data: selected,
    isEditing: isEditing
  };
}

//* VIEW ACTION FUNCTIONS

// * OPEN/CLOSE MODAL *
export function toggleModal(open, isEditing, selected) {
  return dispatch => {
    dispatch(setModal(open, isEditing, selected));
  };
}

// * INDEX *
export const fetchBrands = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/brands?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get(
      'brands',
      brandFilterSanitizer(params)
    );
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest());
  }
};

// * CREATE *
export function createBrand(params) {
  return dispatch => {
    return requestService.post('brands', params).then(
      payload => {
        if (!payload.data.success) {
          const _error = payload.data;
          dispatch(newAlert('error', 'error', _error.title));
          return _error
        }

        dispatch(
          newAlert('success', 'sucess', 'Marca creada satisfactoriamente')
        );
        return { success: true, payload };
      },
      error => {
        return error.json().then(e => {
          // console.log('error user create action:', e)
          e.errors &&
            e.errors.title &&
            dispatch(newAlert('error', 'error', e.errors.title));
          return e;
        });
      }
    );
  };
}

// * UPDATE *
export function updateBrand(params) {
  return dispatch => {
    return requestService.put(`brands/${params.id}`, params).then(
      (payload) => {
        if (!payload.data.success) {
          const _error = payload.data;
          dispatch(newAlert('error', 'error', _error.title));
          return _error
        }
        dispatch(
          newAlert('success', 'sucess', 'Marca modificada satisfactoriamente')
        );
        return { success: true };
      },
      error => {
        // console.log('ERROR IN GET ARTICLE INFO',error)
        return error.json().then(e => {
          e.data &&
            e.data.message &&
            dispatch(newAlert('error', 'ERROR:', e.data.message));
          e.error &&
            e.error.message &&
            dispatch(newAlert('error', 'ERROR:', e.error.message));
          e.errors &&
            e.errors.title &&
            dispatch(newAlert('error', 'ERROR:', e.errors.title));
          e.message && dispatch(newAlert('error', 'ERROR:', e.message));
          return e;
        });
      }
    );
  };
}
