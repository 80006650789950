import RangeEditCreate from './RangeEditCreate';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { updateRange, createRange } from '../../../actions/promissory_actions';
import { fetchCorporationNames } from '../../../actions/corporations_actions';

function mapStateToProps(state) {
  return {
    requestSend: state.requestState['requestRange-CreateSend'],
    corporationNames: state.corporations.corporationNames
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createRange: bindActionCreators(createRange, dispatch),
    updateRange: bindActionCreators(updateRange, dispatch),
    fetchCorporationNames: bindActionCreators(fetchCorporationNames, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RangeEditCreate);
