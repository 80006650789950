import {
  REQUEST_SEND,
  REQUEST_COMPLETE,
  REQUEST_ERRORS
} from '../constants/action_constants';

export const sendRequest = (resource = '') => ({
  type: REQUEST_SEND,
  data: resource
});

export const completeRequest = (resource = '') => ({
  type: REQUEST_COMPLETE,
  data: resource
});

export const errorOnRequest = (resource = '', errors={}) => ({
  type: REQUEST_ERRORS,
  data: resource,
  errors
});
