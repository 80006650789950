import _pickBy from 'lodash/pickBy';

export const teleshoppingSanitizer = values => {
  const {
    contract,
    verification_sale,
    observations,
    price,
    instalments,
    process,
    unmaterializedVoucherProcessId,
    salesmanId,
    voucherSerial,
    isPromissoriesV2,
    externalProcessId
  } = values;
  const sanitizedFilters = {
    contract_id: contract,
    verification_sale_id: verification_sale,
    process,
    salesman_id: salesmanId,
    is_promissories_v2: isPromissoriesV2
  };
  if (!!observations) {
    sanitizedFilters[observations] = observations;
  }
  if (!!price && !!instalments) {
    sanitizedFilters['price'] = price;
    sanitizedFilters['instalments'] = instalments;
  }

  if(!!externalProcessId && !!voucherSerial) {
    sanitizedFilters['unmaterialized_process_id'] = externalProcessId;
    sanitizedFilters['voucher_serial'] = voucherSerial;
  }

  if (!!unmaterializedVoucherProcessId && !!voucherSerial) {
    sanitizedFilters['unmaterialized_process_id'] = unmaterializedVoucherProcessId;
    sanitizedFilters['voucher_serial'] = voucherSerial;
  }
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};
