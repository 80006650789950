import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import ActivityIndicator from '../../shared/ActivityIndicator';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import StepperButtons from '../../shared/StepperButtons';
import RefuseStepModal from '../../shared/Modals/RefuseStepModal';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { theme } from '../../Theme';

class DigitalSaleDocRevisionClient extends PureComponent {
  state = {
    isOpenedRefuseModal: false,
    causals: {}
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
    if (!this.props.faceUrlGesture) {
      this.props.getDocumentRevisionImageBase64(
        this.props.docRevisionDetails.id,
        'face_url_gesture'
      );
    }
    if (!this.props.faceUrlValidation) {
      this.props.getDocumentRevisionImageBase64(
        this.props.docRevisionDetails.id,
        'face_url_validation'
      );
    }
    if (!this.props.frontalDocument) {
      this.props.getDocumentRevisionImageBase64(
        this.props.docRevisionDetails.id,
        'frontal_document'
      );
    }
  }

  onSubmit = () => {
    const { docRevisionDetails, process, routes } = this.props;
    const params = {
      step: 'client_data',
      type: 'approve'
    };
    this.props.digitalSalesDocumentRevisionCheckClient(
      docRevisionDetails.id,
      params,
      routes[this.nextStep],
      process
    );
  };

  _handleClose = () => {
    this.setState({ isOpenedRefuseModal: false });
  };

  refuseValidation = () => {
    this.setState({
      isOpenedRefuseModal: true,
      causals: this.props.docRevisionDetails.causals.filter(
        causal => causal.taskType === 1
      )
    });
  };

  _handleRefuse = causals => {
    const { docRevisionDetails, process, routes } = this.props;
    const params = {
      step: 'client_data',
      type: 'refuse',
      causals: causals.causals
    };
    this.props.digitalSalesDocumentRevisionCheckClient(
      docRevisionDetails.id,
      params,
      routes[this.nextStep],
      process
    );
  };

  render() {
    const {
      classes,
      docRevisionDetails,
      faceUrlGesture,
      faceUrlValidation,
      frontalDocument
    } = this.props;
    return (
      <div className={classes.container}>
        {docRevisionDetails ? (
          <Fragment>
            <div className={classes.headerDataClientValidation}>
              <span>{'Comparación de fotos con documento de identidad'}</span>
              <span>{`Contrato ${docRevisionDetails.contractId}`}</span>
            </div>
            <div className={classes.parentColumnGrid}>
              <div className={classes.childColumnGrid}>
                <Paper className={classes.paper}>
                  {faceUrlGesture ? (
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          className={classes.validationImg}
                          src={'data:image/jpg;base64,' + faceUrlGesture}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  ) : (
                    <ActivityIndicator />
                  )}
                </Paper>
                <Paper className={classes.paper}>
                  {faceUrlValidation ? (
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          className={classes.validationImg}
                          src={'data:image/jpg;base64,' + faceUrlValidation}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  ) : (
                    <ActivityIndicator />
                  )}
                </Paper>
              </div>
              <div className={classes.childColumnGridBig}>
                <Paper className={classes.paper}>
                  {frontalDocument ? (
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          src={'data:image/png;base64,' + frontalDocument}
                          className={classes.validationImg}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  ) : (
                    <ActivityIndicator />
                  )}
                </Paper>
              </div>
            </div>
            <MuiThemeProvider theme={materialTheme}>
              <Grid
                container
                spacing={24}
                alignItems="flex-start"
                className={classes.containerGrid}
              >
                <Grid item md={4} sm={6} xs={12}>
                  <Paper className={classes.paper}>
                    {faceUrlGesture ? (
                      <TransformWrapper>
                        <TransformComponent>
                          <img
                            className={classes.validationImg}
                            src={'data:image/jpg;base64,' + faceUrlGesture}
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    ) : (
                      <ActivityIndicator />
                    )}
                  </Paper>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Paper className={classes.paper}>
                    {faceUrlValidation ? (
                      <TransformWrapper>
                        <TransformComponent>
                          <img
                            className={classes.validationImg}
                            src={'data:image/jpg;base64,' + faceUrlValidation}
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    ) : (
                      <ActivityIndicator />
                    )}
                  </Paper>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Paper className={classes.paper}>
                    {frontalDocument ? (
                      <TransformWrapper>
                        <TransformComponent>
                          <img
                            src={'data:image/png;base64,' + frontalDocument}
                            className={classes.validationImg}
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    ) : (
                      <ActivityIndicator />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </MuiThemeProvider>
            <Paper className={classes.paper}>
              <StepperButtons
                onSubmit={this.onSubmit}
                goBack={this.props.goBack}
                alternativeButtonText={'Rechazar'}
                alternativeButtonCallback={this.refuseValidation}
                nextText={'Aceptar'}
                nextIsPrimary={true}
              />
            </Paper>
            {this.state.isOpenedRefuseModal &&
              this.state.causals.length > 0 && (
                <RefuseStepModal
                  causals={this.state.causals}
                  onClose={this._handleClose}
                  onSave={this._handleRefuse}
                />
              )}
          </Fragment>
        ) : (
          <ActivityIndicator />
        )}
      </div>
    );
  }
}

const materialTheme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 479,
      md: 1023,
      lg: 1281,
      xl: 1479
    }
  }
});

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  containerGrid: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    display: 'flex',
    marginTop: 10,
    marginBottom: 10
  },
  headerDataClientValidation: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2em'
  },
  parentColumnGrid: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    display: 'none',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10
  },
  childColumnGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexBasis: '33%'
  },
  childColumnGridBig: {
    flexBasis: '70%',
    marginLeft: 10,
    alignSelf: 'center'
  },
  paper: {
    padding: 5
  },
  validationImg: {
    width: '100%'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  liDivider: {
    listStyleType: 'none'
  },
  item: {
    margin: 20,
    textAlign: 'center'
  },
  headline: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  }
});

DigitalSaleDocRevisionClient.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(DigitalSaleDocRevisionClient);
