import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import classnames from 'classnames';
import parseQuerystring from '../../../helpers/parseQuerystring';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import { history } from '../../../helpers/history';
import RequestService from '../../../services/request_service';
import SecureProductMovementsFilters from './SecureProductMovementsFilters';
import SecureProductMovementsCreate from './modal/SecureProductMovementsCreate';

class SecureProductMovements extends React.Component {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    createModal: this.props.modalStatus,
    data: {}
  };

  componentDidMount() {
    this._fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this._handleCloseCreateModal();
    }
  }

  _fetchProducts = contractId => {
    this.props.getSecureProducts({ contractId });
  }

  _resetProductData = () => {
    this.props.resetProductData()
  }

  _onKeyPress = event => {
    event.key === 'Enter' && event.preventDefault();
  };

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.setState({ data: {
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    }});
    this.props.fetchSecureProductMovements({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleOpenUpdateModal = () => {
    this.setState({ createModal: true });
  };

  _handleCloseCreateModal = () => {
    this.setState({ createModal: false });
  };

  render() {
    const {
      classes,
      movements = [],
      meta = {},
      requestSend,
      canUpdateSecureProduct
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Movimientos Productos Afianzados'}
          </h1>
          {canUpdateSecureProduct && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenUpdateModal}
            >
              {'Actualizar Producto'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <SecureProductMovementsFilters
              onChangeFilter={this._fetchItems}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Productos'}
            columns={columns}
            data={mapData(movements)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            totalCount={(meta && meta.total_count) || 0}
            loading={requestSend}
          />
        </div>
        {this.state.createModal && (
          <SecureProductMovementsCreate
            onClose={this._handleCloseCreateModal}
            getSecureProducts={this._fetchProducts}
            resetProductData={this._resetProductData}
            product_data={this.props.product_data}
            isSubmitting={this.props.requestSend}
            onSave={this.props.updateSecureProduct}
            params={this.state.data}
          />
        )}
      </div>
    );
  }
}

const styles = () => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

SecureProductMovements.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
  movements: PropTypes.array.isRequired,
  fetchSecureProductMovements: PropTypes.func.isRequired,
  meta: PropTypes.object
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(SecureProductMovements);
