import Dashboard from './Dashboard';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchSummary,
  fetchSales,
  fetchTopRated
} from '../../../actions/dashboard_actions';

function mapStateToProps(state) {
  return {
    dataSummary: state.dashboard.summary,
    dataSales: state.dashboard.sales,
    dataTopRated: state.dashboard.topRated,
    requestDashboardSummarySend: state.requestState.requestDashboardSummarySend,
    requestDashboardSalesSend: state.requestState.requestDashboardSalesSend,
    requestDashboardTopRatedSend:
      state.requestState.requestDashboardTopRatedSend,
    canListDashboardSummaries:
      state.authentication.currentUser.canListDashboardSummaries,
    canListDashboardSales:
      state.authentication.currentUser.canListDashboardSales,
    canListDashboardTopRated:
      state.authentication.currentUser.canListDashboardTopRated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSummary: bindActionCreators(fetchSummary, dispatch),
    fetchSales: bindActionCreators(fetchSales, dispatch),
    fetchTopRated: bindActionCreators(fetchTopRated, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Dashboard);
