import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import LoginLayout from '../../shared/LoginLayout/LoginLayout';
import ConfirmDialog from '../../shared/Modals/ConfirmDialog';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordPageStyles from './ResetPasswordPage.styles';

class ResetPasswordPage extends React.Component {
  state = {
    dialogReset: false,
    sitekey: process.env.SITE_KEY_CAPTCHA_V2,
    captcha: false
  };

  _handleOnChangeCaptcha = async value => {
    if (value != null) {
      this.props
        .confirmTokenRecaptchaV2({ token: value })
        .then(response => this.setState({ captcha: response.success }));
    } else {
      this.setState({ captcha: false });
    }
  };

  _handleCloseDialog = () => {
    this.setState({ dialogReset: false });
    this.props.history.push('/login');
  };

  componentWillMount() {
    this.props.fetchSessionData();
  }

  _handleOnSave = value => () => {
    const { email } = value;
    const newState = { dialogReset: true, resultEmail: email };
    this.props.sendResetPassword({ email: email }, false).then(response => {
      if (!email.includes('@') || !response.email) {
        newState.resultEmail =
          response.email != undefined
            ? this._censorEmail(response.email)
            : 'su dirección de correo electrónico';
      }
      this.setState(newState);
    });
  };

  _censorWord = (str, baseCharacter) => {
    const i = str.indexOf(baseCharacter);
    const startIndex = (i * 0.2) | 0;
    const endIndex = (i * 0.9) | 0;
    return (
      str.slice(0, startIndex) +
      '*'.repeat(endIndex - startIndex) +
      str.slice(endIndex)
    );
  };

  _censorEmail = email => {
    const arr = email.split('@');
    return this._censorWord(arr[0] + '@', '@') + this._censorWord(arr[1], '.');
  };

  render() {
    const { classes, loading } = this.props;
    const { captcha } = this.state;

    return (
      <LoginLayout>
        <ResetPasswordForm
          classes={classes}
          handleOnChangeCaptcha={this._handleOnChangeCaptcha}
          loading={loading}
          captcha={captcha}
          handleSubmit={this._handleOnSave}
        />
        <ConfirmDialog
          open={this.state.dialogReset}
          title="Recuperación de contraseña"
          text={`Hemos enviado un email a
            ${
              this.state.resultEmail
            }. Haga click en el enlace del correo para cambiar su contraseña. Si no encuentra el email en su bandeja de entrada, revise otras carpetas de su correo electrónico, como spam, sociales, u otros directorios.`}
          buttonTextConfirm="ENTENDIDO"
          onConfirm={this._handleCloseDialog}
          onClose={this._handleCloseDialog}
        />
      </LoginLayout>
    );
  }
}

const styles = theme => ({
  check: {
    marginLeft: 0,
    marginTop: theme.spacing.unit * 2.5
  },
  alertContainer: {
    marginTop: theme.spacing.unit * 2.5,
    marginBottom: -theme.spacing.unit * 2
  }
});

export default withStyles(
  combineStyles(styles, ResetPasswordPageStyles, ButtonStyles, TypographyStyles)
)(ResetPasswordPage);
