import {
  OPEN_REGISTER_SCHEDULE_MODAL,
  CLOSE_REGISTER_SCHEDULE_MODAL,
  OPEN_UPDATE_SCHEDULE_MODAL
} from '../constants/register_schedule_constants';

const initialState = {
  isRegsiterScheduleModalOpen: false,
  isUpdateScheduleModalOpen: false,
};

export function registerSchedule(state = initialState, action) {
  switch (action.type) {
    case OPEN_REGISTER_SCHEDULE_MODAL:
      return {
        ...state,
        isRegsiterScheduleModalOpen: true
      }
    case CLOSE_REGISTER_SCHEDULE_MODAL:
      return {
        ...state,
        isRegsiterScheduleModalOpen: false,
        homevisit: null,
      }
    case OPEN_UPDATE_SCHEDULE_MODAL:
      return {
        ...state,
        homevisit: action.data,
        isRegsiterScheduleModalOpen: true
      }
    default:
      return state;
  }
}
