import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import moment from 'moment-timezone';
import FormikPropsValidations from './FormikPropsValidations';
import DatePickersTheme from './DatePickers.theme';
import FieldValidationPopup from '../Poppers/FieldValidation';

import 'moment/locale/es';

moment.locale('es');

const DEFAULT_SAVING_FORMAT = 'YYYY/MM/DD';
const DEFAULT_FORMAT = 'DD/MM/YYYY';

export class DateField extends PureComponent {
  static propTypes = {
    name: props => FormikPropsValidations('name', props),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    helperText: PropTypes.string,
    validateField: PropTypes.func,
    form: PropTypes.object,
    field: PropTypes.object,
    themeOverrides: PropTypes.object,
    savingFormat: PropTypes.string,
    format: PropTypes.string,
    disablePast: PropTypes.bool,
    keyboard: PropTypes.bool,
    onChange: props => FormikPropsValidations('onChange', props)
  };
  state = {
    anchorEl: null,
    fieldError: '',
    fieldName: this.props.name || _get(this.props, 'field.name'),
    value: this.props.value
  };
  componentDidMount() {
    this._setFieldError();
  }
  componentDidUpdate(prevProps) {
    const { validateField } = this.props;
    const { fieldName } = this.state;
    if (_get(prevProps, 'form.errors') !== _get(this.props, 'form.errors')) {
      this._setFieldError();
    }
    if (prevProps.value !== this.props.value) {
      if (_isFunction(validateField)) {
        validateField(fieldName, this.props.value);
      }
      this.setState({ value: this.props.value });
    }
  }
  _setFieldError = () => {
    const { fieldName } = this.state;
    const { form = {} } = this.props;
    const { errors } = form;
    const error = _get(errors, fieldName, '');
    this.setState({ fieldError: error });
  };

  _handleChange = date => {
    const { onChange, field = {}, validateField, form = null } = this.props;
    const { fieldError, fieldName } = this.state;
    const inputOnChange = onChange || field.onChange;
    const formattedDate = this._formatValue(moment(date));
    this.setState({ value: formattedDate });
    inputOnChange({
      target: { name: fieldName, value: formattedDate }
    });
    if (fieldError && _isFunction(validateField)) {
      validateField(fieldName, formattedDate);
    }
    if (form) {
      form.setFieldTouched(fieldName);
    }
  };
  _formatValue = value => {
    const { savingFormat = DEFAULT_SAVING_FORMAT } = this.props;
    const freetextDate = value.isValid()
      ? value
      : moment(value._i, [
          'MM/DD/YYYY',
          'YYYY/MM/DD',
          'MM-DD-YYYY',
          'MM/DD/YY',
          'MMM DD, YYYY'
        ]);
    return freetextDate.isValid() ? freetextDate.format(savingFormat) : null;
  };
  _handleShowError = event => {
    const { fieldError } = this.state;
    if (fieldError) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };
  _handleHideError = () => {
    this.setState({ anchorEl: null });
  };
  _handleBlur = ({ target: { name, value } }) => {
    const { savingFormat = DEFAULT_SAVING_FORMAT } = this.props;
    const fieldDate = moment(value, savingFormat);
    const formattedDate = this._formatValue(fieldDate);

    const { validateField } = this.props;
    if (_isFunction(validateField)) {
      this._handleHideError();
      validateField(name, formattedDate);
    }
  };

  render() {
    const {
      label = 'Date Field',
      themeOverrides = {},
      savingFormat = DEFAULT_SAVING_FORMAT,
      format = DEFAULT_FORMAT,
      disabled = false,
      disablePast = true,
      disableFuture = false,
      keyboard = true,
      mask = null,
      minDate,
      maxDate,
      error,
      helperText
    } = this.props;
    const { value } = this.state;
    const { anchorEl, fieldError, fieldName } = this.state;
    let inputValue = value || null;

    if (inputValue) {
      inputValue = moment(inputValue, savingFormat);
    }

    return (
      <Fragment>
        <MuiThemeProvider theme={DatePickersTheme(themeOverrides)}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
            <InlineDatePicker
              name={fieldName}
              keyboard={keyboard}
              variant="outlined"
              label={label}
              value={inputValue}
              onChange={this._handleChange}
              onBlur={this._handleBlur}
              onFocus={this._handleShowError}
              onMouseEnter={this._handleShowError}
              onMouseOut={this._handleHideError}
              format={format}
              mask={mask}
              disabled={disabled}
              disablePast={disablePast}
              disableFuture={disableFuture}
              keyboardIcon={<EventIcon />}
              leftArrowIcon={<KeyboardArrowLeftIcon />}
              rightArrowIcon={<KeyboardArrowRightIcon />}
              minDate={minDate ? moment(minDate, savingFormat) : undefined}
              maxDate={maxDate ? moment(maxDate, savingFormat) : undefined}
              maxDateMessage={null}
              minDateMessage={null}
              invalidDateMessage={null}
              clearable
              error={!!fieldError || error}
              helperText={helperText}
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
        <FieldValidationPopup anchorEl={anchorEl} message={fieldError} />
      </Fragment>
    );
  }
}

export default DateField;
