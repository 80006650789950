import RequestService from '../services/request_service';
const requestService = new RequestService();

import { newAlert, serverStatusError } from './alert_actions';
import { sendRequest, completeRequest } from './request_state';
import { objectToString } from '../helpers/mappings/map';
import { salesDetail } from './sales_actions';
import { stringifyQuery } from '../helpers/parseQuerystring';
import quotaTransferFilters from '../helpers/sanitizers/quotaTransferFilters';
import { history } from '../helpers/history';
import {
  TRANSFERS_SET,
  TRANSFER_TOGGLE_ACTION,
  TRANSFER_PROCESSED
} from '../constants/action_constants';
import { fetchTransfersSanitizer } from '../helpers/sanitizers/quotaTransferSanitizer';

// * QUOTA TRANSFER REVISION *
export const quotaTransferRevision = (body = {}, type) => async dispatch => {
  try {
    dispatch(sendRequest('Transfer-Revision'));
    const payload = await requestService[type == 'revoke' ? 'delete' : 'post'](
      `quota_transfers/${body.id}/${type}`,
      body,
      {},
      true
    );
    if (history.location.pathname === '/quota-transfer') {
      // dispatch(toggleTransferAction('QuotaModal', false));
    } else {
      dispatch(salesDetail(body.sale_id));
    }
    dispatch(newAlert('success', 'success', payload.status));
    return { success: true };
  } catch (errors) {
    dispatch(serverStatusError(errors));
    errors.json().then(e => {
      e.errors &&
        dispatch(newAlert('error', 'ERROR:', objectToString(e.errors)));
    });
    return { success: false };
  } finally {
    dispatch(completeRequest('Transfer-Revision'));
    dispatch(transferProcessed(false));
  }
};

//* FETCH TRANSFERS *
function fetchSuccess(items, pagi) {
  return {
    type: TRANSFERS_SET,
    data: items,
    meta: pagi
  };
}

function transferProcessed(status) {
  return {
    type: TRANSFER_PROCESSED,
    status
  };
}

export const fetchTransfers = (params = {}) => async dispatch => {
  dispatch(sendRequest('QuotaTransfer'));
  history.replace(`/quota-transfer?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get(
      'quota_transfers',
      quotaTransferFilters(params),
      true
    );
    const transfers = fetchTransfersSanitizer(payload.data);
    dispatch(fetchSuccess(transfers, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    errors.json().then(e => {
      e.errors &&
        dispatch(newAlert('error', 'ERROR:', objectToString(e.errors)));
    });
  } finally {
    dispatch(completeRequest('QuotaTransfer'));
  }
};

function setModal(action, open) {
  return {
    type: TRANSFER_TOGGLE_ACTION,
    action: action,
    open: open
  };
}

export function toggleTransferAction(action, open) {
  return dispatch => {
    dispatch(setModal(action, open));
  };
}
