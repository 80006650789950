import React, { Fragment } from 'react';
import CollapseWrapper from '../../../shared/CollapseWrapper.js';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import SpacingStyles from '../../../../styles/helpers/spacing';
import collapseWrapperStyles from '../../../../styles/components/collapseWrapper';
import StatusLabel from './StatusLabel';
import PromissoryApproveFormModal from '../../PromissoryDetail/modals/PromisoryApproveForm';
import PromissoryRejectFormModal from '../../PromissoryDetail/modals/PromisoryRejectForm';
import Button from '@material-ui/core/Button';

import moment from 'moment-timezone';
import { PROMISSORY_STATUSES } from '../../../../constants/enums';

export const Promissory = ({
  classes,
  promissory,
  canApprovePromissory,
  handleModal,
  approveFormOpen,
  rejectFormOpen,
  onApprove,
  onReject,
  requestApprovePromissorySend,
  requestRejectPromissorySend,
  canViewPromissories,
  rejectPromissoryCausals
}) => (
  <CollapseWrapper
    expanded={true}
    classes={{
      root: classes.root,
      header: classes.panelHeader,
      expandButton: classes.expandButton,
      bodyRoot: classes.collapsePanelBodyRoot
    }}
    header={() => (
      <div className={classes.titleWrapper}>
        <h2 className={classnames(classes.headline8, classes.title)}>
          {'Pagaré'}
        </h2>
        <StatusLabel
          label={PROMISSORY_STATUSES[promissory.status]}
          type={PROMISSORY_STATUSES[promissory.status]}
        />
      </div>
    )}
    body={
      <Fragment>
        <Divider />
        <div className={classes.body}>
          <Grid container spacing={16}>
            <Grid item lg={2}>
              <p className={classnames(classes.subtitle3)}>Consecutivo:</p>
              {canViewPromissories ? (
                <Link
                  to={`/promissories/${promissory.id}`}
                  className={classes.body2}
                >
                  {promissory.code}
                </Link>
              ) : (
                <p className={classes.body2}>{promissory.code}</p>
              )}
            </Grid>
            <Grid item lg={2}>
              <p className={classnames(classes.subtitle3)}>Único:</p>
              <p className={classes.body2}>
                {promissory.isUnique ? 'Si' : 'No'}
              </p>
            </Grid>
            <Grid item lg={2}>
              <p className={classnames(classes.subtitle3)}>Tipo:</p>
              <p className={classes.body2}>
                {promissory.typeOfPromissory === 'M' ? 'Manual' : 'Digital'}
              </p>
            </Grid>
            <Grid item lg={3}>
              <p className={classnames(classes.subtitle3)}>Inmaterializado:</p>
              <p className={classes.body2}>
                {promissory.isUnmaterialized ? 'Si' : 'No'}
              </p>
            </Grid>
            <Grid item lg={3}>
              <p className={classnames(classes.subtitle3)}>
                Fecha de registro:
              </p>
              <p className={classes.body2}>
                {moment(promissory.registeredAt)
                  .locale('es')
                  .format('DD MMM YYYY')}
              </p>
            </Grid>
            <Grid item lg={3}>
              <div className={classes.marginTop} />
            </Grid>
            {promissory.isUnique &&
              promissory.status != 'approved' &&
              promissory.status != 'canceled' &&
              !promissory.isUnmaterialized &&
              canApprovePromissory && (
                <Fragment>
                  <Grid item lg={12}>
                    <Button
                      className={classes.button}
                      classes={{
                        label: classes.buttonLabel
                      }}
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleModal('rejectFormOpen')}
                      fullWidth={true}
                    >
                      {'Rechazar'}
                    </Button>
                  </Grid>
                  <Grid item lg={12}>
                    <Button
                      className={classes.button}
                      classes={{
                        label: classes.buttonLabel
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => handleModal('approveFormOpen')}
                      fullWidth={true}
                    >
                      {'Aprobar'}
                    </Button>
                  </Grid>
                  {approveFormOpen && (
                    <PromissoryApproveFormModal
                      onSave={onApprove}
                      onClose={() => handleModal('approveFormOpen')}
                      isSubmitting={requestApprovePromissorySend}
                    />
                  )}
                  {rejectFormOpen && (
                    <PromissoryRejectFormModal
                      onSave={onReject}
                      onClose={() => handleModal('rejectFormOpen')}
                      isSubmitting={requestRejectPromissorySend}
                      rejectPromissoryCausals={rejectPromissoryCausals}
                    />
                  )}
                </Fragment>
              )}
          </Grid>
        </div>
      </Fragment>
    }
  />
);
export default withStyles(
  combineStyles(
    collapseWrapperStyles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles
  )
)(Promissory);
