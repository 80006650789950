export function contractValidation(api_error, returnBool) {
  if (api_error && api_error.contract)
    return returnBool ? true : api_error.contract[0];
  return returnBool ? false : '';
}

export function valueValidation(api_error, returnBool) {
  if (api_error && api_error.value)
    return returnBool ? true : api_error.value[0];
  return returnBool ? false : '';
}

export function commentValidation(api_error, returnBool) {
  if (api_error && api_error.comment)
    return returnBool ? true : api_error.comment[0];
  return returnBool ? false : '';
}
