import { stringify } from 'query-string';
import changeCaseKeys from 'change-case-keys';
import axios from 'axios';
import { logout } from '../actions/authentication_actions';
import Store from '../store/Store';
import { validatePassword } from '../helpers/fetchFilters';
export default class RequestServiceNew {
  buildIndexUrl(base, params = null) {
    const rootURL = `${process.env.REACT_APP_API_DOMAIN}/${
      process.env.REACT_APP_API_VERSION
    }/`;
    let paramsString = '';
    if (params) {
      const { ...filters } = params;
      const queryParams = this._buildQueryParams(filters);
      // console.log('QUERY PARAMS', queryParams)
      paramsString = `?${stringify(
        { ...params, ...queryParams },
        { encode: false, arrayFormat: 'bracket' }
      )}`;
      // console.log('PARAMS String: ',paramsString)
    }
    return `${rootURL}${base}${paramsString}`;
  }
  get(base, params, requestOptions = {}) {
    // console.log('GET IN REQUEST_SERVICE',base,params)
    return this.fetch(
      base,
      params,
      {
        method: 'GET',
        headers: this.authHeader()
      },
      requestOptions
    );
  }

  post(base, body, params) {
    // console.log('POST IN REQUEST_SERVICE',base,body)
    return this.fetch(base, params, {
      method: 'POST',
      body: body,
      headers: this.authHeader()
    });
  }

  put(base, body, params) {
    // console.log('PUT IN REQUEST_SERVICE',base,body,params)
    // console.log('BODY STRINGIFYED',body)
    return this.fetch(base, params, {
      method: 'PUT',
      body: body,
      headers: this.authHeader()
    });
  }
  delete(base, body, params) {
    // console.log('DELETE IN REQUEST_SERVICE',base,body)
    return this.fetch(base, params, {
      method: 'DELETE',
      body: body,
      headers: this.authHeader()
    });
  }

  patch(base, body, params) {
    // console.log('PATCH IN REQUEST_SERVICE',base,body)
    return this.fetch(base, params, {
      method: 'PATCH',
      body: body,
      headers: this.authHeader()
    });
  }

  fetch(base, params, options, requestOptions = {}) {
    // console.log('FETCH IN REQUEST_SERVICE',base,params)
    if (options && options.body) options.body = this._formatBody(options);
    // console.log('BODY _formatted: ',options.body)
    const headers = this.authHeader();
    return axios({
      url: this.buildIndexUrl(base, params),
      method: options.method,
      data: options.body,
      headers: headers,
      ...requestOptions
    })
      .then(this._checkStatus)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response) {
          // console.log('Rejecting promise based on response', error.response);
          // console.log(error.response.data);
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          return Promise.reject(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          const timeout_error = {
            data: {},
            meta: {},
            warnings: {},
            errors: {
              base: ['No se pudo realizar la petición, intente más tarde.']
            }
          };
          return Promise.reject(timeout_error);
        } else {
          // Something happened in setting up the request that triggered an Error
          const generic_error = {
            data: {},
            meta: {},
            warnings: {},
            errors: {
              base: ['Ocurrió un error, por favor contacte al Administrador.']
            }
          };
          return Promise.reject(generic_error);
        }
      });
  }

  _buildQueryParams(filters) {
    return Object.keys(filters).reduce((result, key) => {
      const value = filters[key];
      result[`q[${key}]`] = value;
      return result;
    }, {});
  }

  _formatBody(options) {
    if (options.body instanceof FormData) return options.body;
    return JSON.stringify(changeCaseKeys(options.body, 'underscored'));
  }

  _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      if (response.status === 401) {
        Store.dispatch(logout());
      }
      if (response.status === 403) {
        response
          .clone()
          .json()
          .then(payload => validatePassword(payload));
      }

      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }

  authHeader() {
    // return authorization header with jwt token
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user) {
      return {
        Authorization: user.token,
        'Content-Type': 'application/json'
      };
    } else {
      return { 'Content-Type': 'application/json' };
    }
  }
}
