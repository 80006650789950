import * as Yup from 'yup';
import moment from 'moment-timezone';

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required('Nombre es requerido'),
  minimumValue: Yup.string().required('Valor mínimo es requerido'),
  minimumQuota: Yup.number().integer().required('Cuota mínima es requerida'),
  maxQuota: Yup.number().integer().when('minimumQuota', () => {
    return Yup.number()
      .integer()
      .min(Yup.ref('minimumQuota'), 'La cuota máxima debe ser mayor o igual a la cuota mínima');
  }).required('Cuota máxima es requerida'),
  startAt: Yup.date().nullable().required('Fecha de Inicio es requerida'),
  endAt: Yup.date().when('startAt', (startAt, schema) => {
      return schema.min(moment(startAt).add(1, 'days'), 'Fecha de Finalización tiene que ser mayor a la Fecha de inicio');
  }).required('Fecha de Finalización es requerida'),
});

export default validationSchema;
