import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  withStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ExpandMore } from '@material-ui/icons';
import classnames from 'classnames';
import _get from 'lodash/get';
import { default as React } from 'react';
import {
  BulkProcessStatus,
  FriendlyBulkProcessStatus,
  FriendlyClassName
} from '../../../constants/enums';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import SpacingStyles from '../../../styles/helpers/spacing';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import ListsStyle from '../__styles__/Lists.style';
import BulkProgressBar from './CustomProgressBar';
import ProcessErrors from './ProcessErrors';

class BulkProcess extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { classes, process, progress } = this.props;
    let processStatus = FriendlyBulkProcessStatus[0];

    if (
      process.status === BulkProcessStatus.Done &&
      process.errors.length !== 0
    ) {
      // Bulk process has errors
      processStatus =
        FriendlyBulkProcessStatus[BulkProcessStatus.DoneWithErrors];
    } else if (progress && process.numRecords === progress.processed) {
      // Bulk process finished but status has not been updated
      if (progress.processed === progress.numSuccessful) {
        processStatus = FriendlyBulkProcessStatus[BulkProcessStatus.Done];
      } else {
        processStatus =
          FriendlyBulkProcessStatus[BulkProcessStatus.DoneWithErrors];
      }
    } else if (progress || process.status === BulkProcessStatus.Processing) {
      // Bulk process is being processed
      processStatus = FriendlyBulkProcessStatus[BulkProcessStatus.Processing];
    } else {
      processStatus = FriendlyBulkProcessStatus[process.status];
    }

    const textColor = _get(classes, processStatus, {});

    return (
      <div style={{ marginBottom: '24px' }}>
        <Card>
          <CardHeader
            title={`Proceso: ${process.id}\t•\t${
              FriendlyClassName[process.processType]
            }`}
            subheader={`Fecha de Creación: ${new Date(
              process.createdAt
            ).toLocaleString('es-CO', {
              dateStyle: 'long',
              timeStyle: 'short'
            })}`}
            action={
              <span className={classnames(classes.statusLabel, textColor)}>
                {processStatus.replace(/_/g, ' ')}
              </span>
            }
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <p>
                  <strong>Datos totales: </strong>
                  {process.numRecords}
                </p>
                <p>
                  <strong>Archivo: </strong>
                  {process.fileName}
                </p>
              </Grid>
              <Grid item xs>
                <div>
                  <strong>Registros Exitosos</strong>
                  <BulkProgressBar
                    value={progress ? progress.successful : 0}
                    valueBuffer={progress ? progress.processed : 0}
                    total={process.numRecords}
                    finalValue={process.numSuccessful}
                    finalValueBuffer={process.numProcessed}
                    finished={process.status === BulkProcessStatus.Done}
                  />
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions disableActionSpacing>
            {/* <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="text"
              color="secondary"
            >
              Reintentar
            </Button> */}
            {process.errors.length !== 0 && (
              <IconButton
                aria-label="show more"
                style={{
                  marginLeft: 'auto',
                  transform: this.state.expanded
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                  transition: 'transform .5s',
                  transitionTimingFunction: 'ease-in-out'
                }}
                onClick={this.handleExpandClick}
              >
                <ExpandMore />
              </IconButton>
            )}
          </CardActions>
          <Collapse in={this.state.expanded} timeout="auto" mountOnEnter>
            <CardContent>
              <ProcessErrors errors={process.errors} />
            </CardContent>
          </Collapse>
        </Card>
      </div>
    );
  }
}

const styles = theme => ({
  statusLabel: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    marginRight: '16px',
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#FEB00B',
    fontFamily: theme.typography.font.main,
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    position: 'relative',
    top: '24px'
  },
  [FriendlyBulkProcessStatus[2]]: {
    backgroundColor: 'rgba(0,195,150,0.08)',
    color: '#00C396'
  },
  [FriendlyBulkProcessStatus[3]]: {
    backgroundColor: 'rgba(219,73,69,0.08)',
    color: theme.palette.primary.main
  },
  [FriendlyBulkProcessStatus[4]]: {
    backgroundColor: 'rgba(245, 106, 7,0.08)',
    color: '#f56a07'
  },
  tooltip: {
    backgroundColor: '#2A3C47',
    color: '#FFFFFF !important',
    padding: '4px 12px'
  },
  tooltipPopper: {
    top: `-${theme.spacing.unit}px !important`
  }
});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(BulkProcess);
