import {
  OPEN_CALCULATE_INSTALMENTS_MODAL,
  CLOSE_CALCULATE_INSTALMENTS_MODAL,
  SET_INSTALMENTS_VALUE,
  SET_MAX_INSTALMENTS_NUMBER,
  SET_OBLIGATORY_INSURANCE_VALUE,
  SET_VALUE_TO_FINANCE,
  SET_SECURE_VALUE,
  SET_PRODUCT_TYPES
} from '../constants/calculate_instalments_constants';

const initialState = {
  isCalculateInstalmentsModalOpen: false,
  instalmentsValue: null,
  maxInstalmentsNumber: 1000,
  obligatoryInsurance: null
};

export function calculateInstalments(state = initialState, action) {
  switch (action.type) {
    case OPEN_CALCULATE_INSTALMENTS_MODAL:
      return {
        ...initialState,
        isCalculateInstalmentsModalOpen: true
      };
    case CLOSE_CALCULATE_INSTALMENTS_MODAL:
      return initialState;
    case SET_INSTALMENTS_VALUE:
      return {
        ...state,
        instalmentsValue: action.data
      };
    case SET_MAX_INSTALMENTS_NUMBER:
      return {
        ...state,
        maxInstalmentsNumber: action.data
      };
    case SET_OBLIGATORY_INSURANCE_VALUE:
      return {
        ...state,
        obligatoryInsurance: action.data
      };
    case SET_VALUE_TO_FINANCE:
      return {
        ...state,
        valueToFinance: action.data
      };
    case SET_SECURE_VALUE:
      return {
        ...state,
        secureValue: action.data
      };
    default:
      return state;
  }
}
