import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import Grid from '@material-ui/core/Grid';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import SpacingStyles from '../../../styles/helpers/spacing';
import TypographyStyles from '../../../styles/typography';
import AffiliatesInfoStyles from './__styles__/AffiliatesInfo.styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import CollapseWrapper from '../../shared/CollapseWrapper';
import Divider from '@material-ui/core/Divider';
import collapseWrapperStyles from '../../../styles/components/collapseWrapper';

export class AffiliatesInfo extends React.Component {
  render() {
    const { classes, affiliates, expanded = false } = this.props;
    return (
      <section className={classes.headerRoot}>
        <CollapseWrapper
          expanded={expanded}
          classes={{
            root: classes.root,
            header: classes.panelHeader,
            expandButton: classes.expandButton,
            bodyRoot: classes.collapsePanelBodyRoot
          }}
          header={() => (
            <div className={classes.titleWrapper}>
              <h2 className={classnames(classes.headline8, classes.title)}>
                {'Información de afiliados'}
              </h2>
            </div>
          )}
          body={
            <Fragment>
              <Divider />
              <div className={classes.details}>
                <Grid container spacing={16} className={classes.contactData}>
                  {Object.keys(affiliates).map(key => (
                    <>
                      <Grid item lg={4}>
                        <p className={classes.subtitle3}>{'Nombre:'}</p>
                        <p className={classes.body2}>
                          {`${affiliates[key].name}` || '--'}
                        </p>
                      </Grid>
                      <Grid item lg={4}>
                        <p className={classes.subtitle3}>{'Parentesco:'}</p>
                        <p className={classes.body2}>
                          {`${affiliates[key].relationship}` || '--'}
                        </p>
                      </Grid>
                      <Grid item lg={4}>
                        <p className={classes.subtitle3}>{'Edad:'}</p>
                        <p className={classes.body2}>
                          {`${affiliates[key].age}` || '--'}
                        </p>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </div>
            </Fragment>
          }
        />
      </section>
    );
  }
}

export default withStyles(
  combineStyles(
    AffiliatesInfoStyles,
    ButtonStyles,
    TypographyStyles,
    collapseWrapperStyles
  )
)(AffiliatesInfo);
