/* eslint-disable complexity */
import moment from 'moment-timezone';
import _find from 'lodash/find';
import _get from 'lodash/get';
import camelcaseKeys from 'camelcase-keys';

const _name = user => {
  if (!user) return '';
  const fullname = `${_get(user, 'firstName', '--')} ${_get(
    user,
    'lastName',
    '--'
  )}`;
  return 'Por: ' + fullname.toLowerCase();
};

export const MapSteps = (movements, disables = []) => {
  const steps = [];
  const registered = _find(movements, { status: 'registered' });
  const reviewed = _find(movements, { status: 'reviewed' });
  const rejected = _find(movements, { status: 'revoked' });
  const approved = _find(movements, { status: 'approved' });
  const deactivated = _find(movements, { status: 'deactivated' });
  // Solicitud Registrada
  const registeredAt = _get(camelcaseKeys(registered), 'createdAt', '');
  if (!disables.includes('registered')) {
    steps.push({
      isCompleted: true,
      status: 'Registrada',
      createdBy: registered ? _name(camelcaseKeys(registered.user)) : '--',
      createdAt: registeredAt
        ? moment(registeredAt).format('YYYY/MM/DD - h:mm A')
        : '--'
    });
  }
  // Solicitud Revisada
  const reviewedAt = _get(camelcaseKeys(reviewed), 'createdAt', '');
  if ((reviewed || !rejected) && !disables.includes('reviewed')) {
    steps.push({
      isCompleted: reviewed ? true : false,
      status: 'Revisada',
      createdBy: reviewed ? _name(camelcaseKeys(reviewed.user)) : '--',
      createdAt: reviewedAt
        ? moment(reviewedAt).format('YYYY/MM/DD - h:mm A')
        : '--'
    });
  }
  // Solicitud Rechazada
  const rejectedAt = _get(camelcaseKeys(rejected), 'createdAt', '');
  if (rejected && !disables.includes('revoked')) {
    steps.push({
      isCompleted: rejected ? true : false,
      status: 'Rechazada',
      createdBy: rejected ? _name(camelcaseKeys(rejected.user)) : '--',
      createdAt: rejectedAt
        ? moment(rejectedAt).format('YYYY/MM/DD - h:mm A')
        : '--'
    });
  }
  // Solicitud Aprobada
  const approvedAt = _get(camelcaseKeys(approved), 'createdAt', '');
  if ((approved || !rejected) && !disables.includes('approved')) {
    steps.push({
      isCompleted: approved ? true : false,
      status: 'Aprobada',
      createdBy: approved ? _name(camelcaseKeys(approved.user)) : '--',
      createdAt: approvedAt
        ? moment(approvedAt).format('YYYY/MM/DD - h:mm A')
        : '--'
    });
  }
  // Solicitud Desactivada
  const deactivatedAt = _get(deactivated, 'createdAt', '');
  if (deactivated && !disables.includes('deactivated')) {
    steps.push({
      isCompleted: deactivated ? true : false,
      status: 'Desactivada',
      createdBy: deactivated ? _name(camelcaseKeys(deactivated.user)) : '--',
      createdAt: deactivatedAt
        ? moment(deactivatedAt).format('YYYY/MM/DD - h:mm A')
        : '--'
    });
  }
  return steps;
};
