import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import CustomDialog from '../../shared/Modals/CustomDialog';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../../pages/__styles__/Lists.style';

class QueryCampaign extends PureComponent {
  render() {
    const {
      classes,
      loading,
      serverErrors = {},
      onSubmit,
      onApplyCampaign,
      onClose,
      validationResponse,
      campaignIds,
      campaignTitle = ''
    } = this.props;
    const successfulRequest = loading && _isEmpty(serverErrors);
    return (
      <CustomDialog
        onClose={onClose}
        onConfirm={_isEmpty(validationResponse)? onSubmit : onApplyCampaign}
        loading={loading}
        open={true}
        title={`Consulta de campaña - ${campaignTitle}`}
        buttonText={_isEmpty(validationResponse)? 'Consultar': validationResponse.data.isValid ? 'Continuar' : ''}
        disabled={successfulRequest}
        buttonSecondaryText={'Cerrar'}
        onConfirmSecondary={onClose}
        disableLoadingButton={true}
        classes={{
          root: classes.queryQuotaDialogRoot,
          content: classes.queryQuotaDialogContent
        }}
      >
        { !successfulRequest && _isEmpty(validationResponse) &&
          <p>Si desea aplicar a la campaña de {campaignTitle}, haga click en consultar para validar si cumple con las condiciones.</p>
        }
        { !_isEmpty(validationResponse) && (
            validationResponse.data.isValid || !validationResponse.data.isValid && validationResponse.data.selectedCampaignId === 0 && campaignIds.length > 1 ? (
              <p> { validationResponse.data.messages[0] } </p>
            ) : (
              <>
                <p><strong>Se incumplen las siguientes condiciones para {campaignTitle}:</strong></p>
                <ul>
                  { validationResponse.data.messages.map((message, index) => (
                    <li key={index}> { message } </li>
                  ))}
                </ul>
              </>
            )

        )}
      </CustomDialog>
    );
  }
}

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 0
  },
  notFound: {
    marginTop: 10,
    color: 'red'
  },
  dataContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '5px 0px'
  },
  dataColumn: {
    width: '50%',
    ['@media (max-width:600px)']: {
      width: '100% !important'
    }
  },
  dataRegister: {
    margin: '2.5px 0px'
  },
  observationsList: {
    paddingLeft: 30
  },
  extraQuotaItem: {
    borderTop: 'solid 1px #BCBCBC',
    padding: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'solid 1px #BCBCBC'
    }
  },
  queryResults: {
    marginTop: 10,
    display: 'block',
    width: '100%'
  },
  insurance: {
    margin: '2% 0 2% 0'
  },
  resultContainer: {
    minHeight: 50,
    width: '100%',
    display: 'block',
    backgroundColor: '#FFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    marginBottom: theme.spacing.unit * 2,
    padding: '10px 16px'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    marginRight: 0,
    padding: '6px 8px',
    width: 100
  },
  queryQuotaDialogRoot: {
    ['@media (max-width:600px)']: {
      width: '95%',
      margin: '0 24px'
    }
  },
  queryQuotaDialogContent: {
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  inputGrid: {
    padding: '6px 12px 6px 12px !important',
    ['@media (max-width:600px)']: {
      padding: '0 12px !important'
    }
  },
  grayContainer: {
    backgroundColor: '#EFF1F4',
    marginBottom: '5%',
    padding: 20
  },
  title: {
    color: '#111'
  }
});

QueryCampaign.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(QueryCampaign);
