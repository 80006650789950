import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

class ResourceManagementTable extends PureComponent {
  render() {
    const {
      classes,
      resourceName,
      columns,
      rows,
      showCreateButton,
      onCreateButtonClick=(()=>{}),
      onRowClick,
      loading,
      canClickRows=true,
    } = this.props;
    return (
      <Grid container item>
        {
          loading ? (
            <Grid container item justify='center'>
              <CircularProgress color="secondary"/>
            </Grid>
          ) : (
            <TableContent
              classes={classes}
              resourceName={resourceName}
              columns={columns}
              rows={rows}
              showCreateButton={showCreateButton}
              onCreateButtonClick={onCreateButtonClick}
              canClickRows={canClickRows}
              onRowClick={onRowClick}
            />
          )
        }
      </Grid>
    );
  }
}

const TableContent = props => {
  const {
    classes,
    resourceName,
    columns,
    rows,
    showCreateButton,
    onCreateButtonClick,
    onRowClick,
    canClickRows,
  } = props;
  return (
    <Grid container item>
      {
        showCreateButton ? (
          <Grid container item xs={12}>
            <Grid item xs={10}>
              <Typography variant='h5' align='justify'>{resourceName}</Typography>
            </Grid>
            <Grid container item justify='flex-end' xs={2}>
              <Button
                onClick={() => onCreateButtonClick({})}
                variant='contained'
                color='secondary'
                className={classes.createButton}
              >
                Crear
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant='h5' align='justify'>{resourceName}</Typography>
          </Grid>
        )
      }
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              {
                columns.map((item, idx) => (
                  <TableCell
                    key={idx}
                  >
                    {item.title}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.map(
                (row, idx) => (
                  <TableRow
                    hover={canClickRows}
                    key={idx}
                    onClick={
                      () => {
                        if (canClickRows) onRowClick(row);
                      }
                    }
                  >
                    {
                      columns.map((column, indx) => (
                        <TableCell
                          key={indx}
                        >
                          {row[column.name]}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                )
              )
            }
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}

const styles = theme => ({
  createButton: {
    maxHeight: 36
  }
});

export default withStyles(styles, { withTheme: true })(ResourceManagementTable);
