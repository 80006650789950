import { OTP_SEND_RESPONSE, OTP_VALIDATION_RESPONSE, RESET_OTP_INFO_RESPONSE, VALIDATE_UNIQUE_MOBILE } from '../constants/otp_validation_constants';

const initialState = {
  mobile: '',
  mobileConfirmedAt: '',
  validationSendResponse: false,
  validationConfirmationResponse: false,
  validationUniqueMobileResponse: true,
};

export function otpValidation(state = initialState, action) {
  switch (action.type) {
    case OTP_SEND_RESPONSE:
      return {
        ...state,
        mobile: action.data.mobile,
        validationSendResponse: action.data.validationSendResponse
      };
    case OTP_VALIDATION_RESPONSE:
      return {
        ...state,
        mobileConfirmedAt: action.data.mobileConfirmedAt,
        validationConfirmationResponse: action.data.validationConfirmationResponse
      };
    case RESET_OTP_INFO_RESPONSE:
      return {
        ...state,
        mobile: '',
        mobileConfirmedAt: '',
        validationSendResponse: false,
        validationConfirmationResponse: false
      };
    case VALIDATE_UNIQUE_MOBILE:
      return {
        ...state,
        validationUniqueMobileResponse: action.data
      };
    default:
      return state;
  }
}
