import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, withStyles } from '@material-ui/core';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

import CustomDialog from '../../shared/Modals/CustomDialog';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { mapReturnCancelCausals } from '../../../helpers/mappings/mapToOptions';

class RegisterSinister extends Component {
  state = {
    observation: '',
    cause: -1,
  };

  handleSave = () => {
    this.props.registerSiniter({
      saleId: this.props.sale.id,
      observation: this.state.observation,
      causal: this.state.cause,
    });
  };

  handleClose = () => {
    if (!this.props.requestSend) {
      this.props.toggleSaleAction('Register-Sinister', false);
      this.setState({
        observation: '',
        cause: -1,
      });
    };
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };


  getCausal = () => {
    const { sinisterRegisterCauses = [] } = this.props;
    return mapListToOptions(sinisterRegisterCauses);
  };

  render() {
    const { open = false, requestSend, sale } = this.props;
    const { observation, cause } = this.state;

    return (
      <div>
        <CustomDialog
          open={open}
          loading={requestSend}
          disabled={requestSend}
          disableBackdropClick={true}
          onClose={this.handleClose}
          onConfirm={this.handleSave}
          title={'Registro de siniestro'}
          contentText={`Registra el siniestro de la venta.`}
          buttonText={'REGISTRAR'}
        >
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <SimpleSelect
                required
                label="Causal de siniestro"
                name="cause"
                options={this.getCausal(sale)}
                value={cause}
                onChange={this.handleChange}
                disabled={false}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observación"
                name="observation"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={observation}
                disabled={requestSend}
                fullWidth
              />
            </Grid>
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = (/* theme */) => ({});

RegisterSinister.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(RegisterSinister);
