import _pickBy from 'lodash/pickBy';

const clientFilters = filters => {
  const {
    name,
    identification,
    identificationType,
    contract,
    clientExternalCode,
    page,
    per_page
  } = filters;

  const sanitizedFilters = {
    fullNameCont: name,
    identificationCont: identification,
    contractsIdEq: contract,
    externalCodeEq: clientExternalCode,
    page: page,
    perPage: per_page
  };

  if (identificationType != 'todos') {
    sanitizedFilters.typeOfIdentificationCont = identificationType;
  }
  return _pickBy(sanitizedFilters, filter => !!filter);
};

export default clientFilters;
