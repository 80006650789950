import React from "react";
import FormItemContainer from "../../shared/Containers/FormItemContainer";
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { history } from '../../../helpers/history';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';

const styles = theme => ({
    tag: {
        margin: 0,
        display: 'inline-block',
        padding: [[theme.spacing.unit * 1, theme.spacing.unit * 1.5 ]],
        borderRadius: '18px',
        color: '#FFFFFF',
        textAlign: 'left',
        fontSize: '16px',
        fontFamily: 'Roboto',
        letterSpacing: '0.25px',
        opacity: 1
    },

    blueColor: {
        backgroundColor: '#0277BD',
        marginLeft: '0.25rem'
    },

    blueLagoonColor: {
        backgroundColor: '#00838F',
        marginRight: '0.25rem'
    },

    container: {
        display: 'flex',
        alignItems: 'center,'
    },

    contentDisposition: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid rgba(12,31,44,0.1)',
        padding: '25px'
    },

    progressInfoDisposition: {
        display: 'flex',
        flexDirection: 'column'
    },

    porcentageStyle: {
        alignSelf: 'end',
        fontSize: '16px',
        fontFamily: 'Roboto',
        color: '#000000DE',
        opacity: 1
    },

    progressBar: {
        height: '0.25rem',
        appearance: 'none',
        '&::-webkit-progress-value': {
            backgroundColor: '#00838F',
            borderRadius: '20px'
        },
        '&::-webkit-progress-bar': {
            borderRadius: '20px',
            backgroundColor: '#00838F3D'
        },
        marginBottom: '5px' 
    },

    coursesNameStyle: {
        fontSize: '16px',
        fontFamily: 'Roboto',
        color: '#00000099',
        letterSpacing: '0.12px',
        textAlign: 'left',
        opacity: 1,
        alignSelf: 'center',
        textTransform: 'capitalize'
    },

    contents: {
        padding: '0 !important'
    },

    handlerCoursesSection: {
        padding: '8px 16px 8px 16px',
        border: '2px solid #F2A138',
        borderRadius: '5px'
    },

    coursesContentClose: {
        maxHeight: '182px',
        overflow: 'hidden'
    },

    coursesContentOpen: {
        maxHeight: '364px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        } 
    },

    withoutCourses: {
      minHeight: "180px",
      backgroundColor: "#FFFFFF99",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold",
      fontSize: "x-large",
      borderRadius: "5px",
      borderBottom: '1px solid rgba(12,31,44,0.1)'
    },
});

class CoursesPandoty extends React.Component {
    state = {isOpen: false}

    _coursesCompleteCount = (courses) => {
        var completeCourse = courses.filter(course => course.progress_user == "100%");
        return completeCourse.length
    };

    _coursesIncompleteCount = (courses) => {
      var incompleteCourse = courses.filter(course => course.progress_user != "100%");
      return incompleteCourse.length
    };

    _toggleCoursesBox = () => {
        this.setState({isOpen: !this.state.isOpen})
    }

    _goToBrillaELearning  = () => {
        window.open('https://brilla.pandoty.com/', '_blank');
    };

    _goToCourseDetail = (id) => {
        history.push('/my-profile/course/'+ id);
    }

    render() {
        const {
            classes,
            courses,
        } = this.props;
        return (
            <FormItemContainer
                title={'Cursos'}
                customContents={classes.contents}
                secondaryAction={
                    <div>
                        <span className={classnames(classes.tag, classes.blueLagoonColor)}>{`Cursos completados (${courses ? this._coursesCompleteCount(courses) : "0"})`}</span>
                        <span className={classnames(classes.tag, classes.blueColor)}>{`En progreso (${courses ? this._coursesIncompleteCount(courses) : "0"})`}</span>
                    </div>
                }
            >
                <div className={this.state.isOpen ? classes.coursesContentOpen : classes.coursesContentClose}>
                    {!courses ? (
                      <div className={classes.withoutCourses}>
                          <span>No hay cursos disponibles para este usuario</span>
                      </div>
                    ) : (
                      courses.map(course => 
                        <div className={classes.contentDisposition}>
                            <Button className={classes.coursesNameStyle} onClick={() => this._goToCourseDetail(course.id)}>{course.attributes.name}</Button>
                            <div className={classes.progressInfoDisposition}>
                                <progress className={classes.progressBar} value={course.progress_user.slice(0, -1)} max="100"></progress>
                                <span className={classes.porcentageStyle}>{course.progress_user}</span>
                            </div>
                        </div>  
                      )
                    )}
                </div>
                <div className={classes.contentDisposition}>
                        <Button
                            size={'small'}
                            color={'secondary'}
                            className={classnames(classes.smallButton, classes.actionButtons, classes.handlerCoursesSection)}
                            classes={{
                                label: classes.buttonLabel
                            }}
                            onClick={() => this._goToBrillaELearning()}
                            >
                            {'IR AL PORTAL DE CURSOS'}
                        </Button>
                        {courses && courses.length > 2 && (
                            !this.state.isOpen ? (
                                <Button
                                    size={'small'}
                                    color={'secondary'}
                                    className={classnames(classes.smallButton, classes.actionButtons)}
                                    classes={{
                                        label: classes.buttonLabel
                                    }}
                                    onClick={() => this._toggleCoursesBox()}
                                    >
                                    {'+ VER MAS'}
                                </Button> 
                            ) : (
                                <Button
                                    size={'small'}
                                    color={'secondary'}
                                    className={classnames(classes.smallButton, classes.actionButtons)}
                                    classes={{
                                        label: classes.buttonLabel
                                    }}
                                    onClick={() => this._toggleCoursesBox()}
                                    >
                                    {'- VER MENOS'}
                                </Button> 
                            )
                        )}
                    </div>
            </FormItemContainer>
        );
    };
}

export default withStyles(combineStyles(ButtonStyles, TypographyStyles, styles))(CoursesPandoty);