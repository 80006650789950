import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  queryType: Yup.string().required('El campo es requerido'),
  contract: Yup.number().when('queryType', {
    is: val => val === 'contract',
    then: Yup.number()
      .required('El campo es requerido')
      .min(1, 'Ingresa un contrato válido.')
  }),
  identificationType: Yup.string().when('queryType', {
    is: val => val === 'id',
    then: Yup.string()
      .required('El campo es requerido')
  }),
  identification: Yup.number().when('queryType', {
    is: val => val === 'id',
    then: Yup.number()
      .required('El campo es requerido')
      .min(1, 'Ingresa una identificación válida.')
  })
});
