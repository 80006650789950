import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

class InfoField extends PureComponent {
  render() {
    const {
      children,
      classes,
      className,
      label,
      customLabel,
      customValue,
      ...props
    } = this.props;
    return (
      <div {...props} className={classnames(classes.container, className)}>
        <span className={classnames(classes.label, customLabel)}>{label}</span>
        <span className={classnames(classes.value, customValue)}>
          {children}
        </span>
      </div>
    );
  }
}

InfoField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  className: PropTypes.string
};

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: 2 * theme.spacing.unit,
    paddingBottom: 2 * theme.spacing.unit,
    width: '90%',
    margin: '0 auto'
  },
  label: {
    display: 'inline-block',
    flex: '50%',
    color: theme.palette.grey['600'],
    ['@media (max-width:600px)']: {
      fontSize: '0.8rem'
    }
  },
  value: {
    flex: '50%',
    ['@media (max-width:600px)']: {
      display: 'inline-block',
      textTransform: 'capitalize',
      fontSize: '0.8rem'
    }
  }
});

export default withStyles(styles)(InfoField);
