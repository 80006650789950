import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import DigitalSaleDocRevisionClient from './DigitalSaleDocRevisionClient';
import { digitalSalesDocumentRevisionCheckClient, getDocumentRevisionImageBase64 } from '../../../actions/digital_sale_document_revisions_actions';
import { newAlert } from '../../../actions/alert_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import { setNextStep } from '../../../actions/portal_financing_actions';

import _get from 'lodash/get';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  const docRev = state.digitalSalesDocumentRevisions;
  return {
    docRevisionDetails: docRev.digitalSalesDocumentRevisionDetails,
    faceUrlGesture: docRev.face_url_gesture,
    faceUrlValidation: docRev.face_url_validation,
    frontalDocument: docRev.frontal_document,
    process: state.stepper.process,
    digitalSalesDocumentRevisionNext:
      state.stepper.digitalSalesDocumentRevisionNext,
    serverErrors: pf.errors,
    baseErrorOpen: docRev.baseErrorOpen,
    routes: state.stepper.routes,
    stepIsLoading: docRev.stepIsLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    digitalSalesDocumentRevisionCheckClient: bindActionCreators(
      digitalSalesDocumentRevisionCheckClient,
      dispatch
    ),
    getDocumentRevisionImageBase64: bindActionCreators(
      getDocumentRevisionImageBase64,
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitalSaleDocRevisionClient);
