import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import SegmentHeader from '../../shared/SegmentHeader';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PromissoryPreview from '../PromissoryDetail/PromissoryPreview';
import OTPV2Modal from '../DigitalSaleOTPSale/OTPModal';
import Icon from '@material-ui/core/Icon';

import StepperButtons from '../../shared/StepperButtons';
import MIMETYPES from '../../../constants/mimetypes';
import { PdfTypeV2 } from '../../../constants/enums';
import { submitOtpSanitizerPromissory } from '../../../helpers/sanitizers/portalPromissorySanitizer';
import ActivityIndicator from '../../shared/ActivityIndicator';

const signed_document = require('../../../assets/icons/contract.png');

class DigitalSaleOTPPromissory extends PureComponent {
  constructor(props) {
    super(props);

    this.setCode = this.setCode.bind(this);
  }

  state = {
    debtorExternalTabOpened: false,
    cosignerExternalTabOpened: false,
    submit: false,
    showOtpValidationModal: false,
  };

  setCode(code) {
    this.setState({
      code: code
    });
  }

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
  }

  componentDidUpdate() {
    if (
      this.props.isMyPromissoriesV2Active
      && !this.props.promissoryUrl
      && !this.props.previewPromissoryLoading
    ) {
      this.props.previewDocumentV2(this.props, 'Promissory');
    }
  }

  handleCloseOtpModal = () => {
    this.setState({ showOtpValidationModal: false });
  };

  handleOpenOtpModal = () => {
    this.setState({ showOtpValidationModal: true });
  };

  handleSend = () => {
    const { otpV2TransaccionId } = this.props;
    if (otpV2TransaccionId) {
      this.setState({ showOtpValidationModal: true });
      return;
    }
    const parsedValues = submitOtpSanitizerPromissory(PdfTypeV2.promissory, this.props);
    this.props.sendOtpV2(
      parsedValues, 'Promissory'
    ).then((valid) => {
      if (valid) {
        this.setState({ showOtpValidationModal: true });
      }
    })
  };

  onSubmit = () => {
    const {
      tempPromissory = {},
      isMyPromissoriesV2Active,
      otpValidatorDebtor,
      otpValidatorCosigner,
    } = this.props;

    const otpV2Validator =  tempPromissory.cosigner ? otpValidatorDebtor && otpValidatorCosigner : otpValidatorDebtor;

    if (isMyPromissoriesV2Active && !otpV2Validator) {
      this.props.newAlert(
        'error',
        'ERROR:',
        'El pagaré no ha sido firmado'
      );
      return;
    }

    this.promissoryLastStep()
  };

  promissoryLastStep = () => {
    const {
      unmaterializedPromissoryProcessId,
      process,
      contract,
      routes,
      documentSerial,
      otpV2TransaccionId,
      tempPromissory
    } = this.props;
    this.props.checkDigitalSaleSignedUnmaterializedPromissory(
      {
        unmaterializedProcessId: unmaterializedPromissoryProcessId,
        documentSerial,
        transactionId: otpV2TransaccionId,
        debtor: tempPromissory.debtor
      },
      contract,
      routes[this.nextStep],
      process
    );
  };

  handleResend = () => {
    const { contract, promissory } = this.props;
    this.props.resendDigitalSaleOTP(
      { mobile: promissory.debtor.mobile, otp_type: 1 },
      contract
    );
  };

  render() {
    const {
      loading,
      classes,
      contract,
      tempPromissory,
      debtorUnmaterializedPromissoryUrl,
      cosignerUnmaterializedPromissoryUrl,
      unmaterializedPromissoryProcessId,
      isMyPromissoriesV2Active,
      otpV2TransaccionId,
      otpValidatorDebtor,
      otpValidatorCosigner,
      orgDataLoading,
      promissoryUrl,
      previewPromissoryLoading,
      requestSendOtpV2
    } = this.props;
    if (orgDataLoading || previewPromissoryLoading) {
      return <ActivityIndicator />
    }

    window.onbeforeunload = () => {
      if (isMyPromissoriesV2Active) {
        // If page is reloaded, set url to null and fetch new pdf data
        const pf = JSON.parse(localStorage.getItem("portalFinancing"));
        pf["promissory_url"] = null;

        localStorage.setItem("portalFinancing", JSON.stringify(pf));
      }
    }

    if (debtorUnmaterializedPromissoryUrl && !this.state.debtorExternalTabOpened && !isMyPromissoriesV2Active) {
      window.open(debtorUnmaterializedPromissoryUrl, '_blank');
      this.setState({ debtorExternalTabOpened: true });
    }
    if (cosignerUnmaterializedPromissoryUrl && !this.state.cosignerExternalTabOpened && !isMyPromissoriesV2Active) {
      window.open(cosignerUnmaterializedPromissoryUrl, '_blank');
      this.setState({ cosignerExternalTabOpened: true });
    }
    const currentUser = { mobile: tempPromissory.debtor.mobile };
    const cosignerUser = tempPromissory.cosigner ? { mobile: tempPromissory.cosigner.mobile } : null;

    const validCosigner = tempPromissory.cosigner && !otpValidatorCosigner;
    const buttonSigner = otpValidatorDebtor && validCosigner ? "codeudor" : "deudor";
    const isOtpValid = tempPromissory.cosigner ? otpValidatorDebtor && otpValidatorCosigner : otpValidatorDebtor;
    return (
      <div className={classes.container}>
        {isMyPromissoriesV2Active && promissoryUrl != '' ? (
          <Paper className={classes.paper}>
            <Grid container spacing={40} className={classes.pdf}>
              <Grid item xs={12} md={8}>
                <SegmentHeader className={classes.segmentHeaderOtpV2}>
                    Para proceder con la firma del documento, por favor desplácese hacia abajo y siga las instrucciones.
                </SegmentHeader>
                <PromissoryPreview
                  url={promissoryUrl}
                />
                <Grid item xs={12} md={12} className={classes.bottomOptions}>
                  {!isOtpValid ? (
                    <PrimaryButton
                      loading={requestSendOtpV2}
                      onClick={() => {
                        this.handleSend();
                      }}
                      className={classes.signButton}
                    >
                      {`Firma ${buttonSigner}`}
                    </PrimaryButton>
                  ) : (
                    <Icon classes={{ root: classes.iconRoot }}>
                      <img className={classes.imageIcon} src={signed_document} />
                    </Icon>
                  )}
                  {!isOtpValid ? (
                  <p className={classes.advisoryText} >{`Al momento de iniciar la firma del documento se le enviará un mensaje de texto ${tempPromissory.cosigner ? 'a los números': 'al número'} ${
                    tempPromissory.cosigner ? currentUser.mobile + ' y ' + cosignerUser.mobile : currentUser.mobile + '.'
                  } `}</p>
                  ) : (
                    <p className={classes.advisoryText} >{`El documento ha sido firmado exitosamente. Puede finalizar la venta.`}</p>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <StepperButtons onSubmit={this.onSubmit} goBack={this.props.goBack} />
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            <div className={classes.segmentHeaderOptions}>
              <SegmentHeader className={classes.segmentHeader}>
                {`Se le enviará un mensaje de texto ${cosignerUnmaterializedPromissoryUrl ? 'a los números': 'al número'} ${
                  cosignerUnmaterializedPromissoryUrl ? currentUser.mobile + ' y ' + cosignerUser.mobile : currentUser.mobile
                } `}
              </SegmentHeader>
              <PrimaryButton
                onClick={() => {
                  this.props.resendOlimpiaOTP(contract, {
                    process_id: unmaterializedPromissoryProcessId
                  });
                }}
                className={classes.button}
              >
                Reenviar OTP
              </PrimaryButton>
            </div>
            <div className={classes.item} variant="caption">
              {cosignerUnmaterializedPromissoryUrl ? `El pagaré está siendo firmado por los usuarios en este momento. Al
                  completar el proceso para ambos usuarios (deudor y codeudor), favor continuar presionando ` :`El pagaré está siendo firmado por el usuario en este momento. Al
                  completar el proceso, favor continuar presionando `}
              <span className={classes.headline}>Siguiente</span>
              {` en esta pantalla`}
            </div>
            <StepperButtons onSubmit={this.onSubmit} goBack={this.props.goBack} />
          </Paper>
        )}
        <OTPV2Modal
          open={this.state.showOtpValidationModal}
          transaccionId={otpV2TransaccionId}
          debtor={currentUser}
          handleCloseOtpModal={this.handleCloseOtpModal}
          handleOpenOtpModal={this.handleOpenOtpModal}
          validateOtpV2={this.props.validateOtpV2}
          reSendOtpV2={this.props.reSendOtpV2}
          otpValidatorDebtor={otpValidatorDebtor}
          otpValidatorCosigner={otpValidatorCosigner}
          cosigner={cosignerUser}
          loading={requestSendOtpV2 || loading}
        />
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  item: {
    margin: 20,
    textAlign: 'center'
  },
  headline: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    marginTop: 3 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 2
    }
  },
  liDivider: {
    listStyleType: 'none'
  },
  segmentHeaderOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5'
  },
  button: {
    margin: 2,
    padding: '8px'
  },
  pdf: {
    display: 'flex',
    justifyContent: 'center',
  },
  segmentHeaderOtpV2: {
    marginTop: 3 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 5
    },
    width: '100%',
  },
  signButton: {
    margin: 2,
    padding: '8px',
    marginRight: '20px'
  },
  iconRoot: {
    width: '70px',
    height: '70px',
  },
  imageIcon: {
    height: '70%'
  },
  advisoryText: {
    textAlign: 'center'
  },
  bottomOptions: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    marginTop: '7px'
  },
});

DigitalSaleOTPPromissory.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(DigitalSaleOTPPromissory);
