import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { orangeHighlight } from '../../constants/style';

class Stepper extends React.Component {
  isStepOptional = step => this.props.optionalSteps.includes(step);

  isStepFailed = step => this.props.failedSteps.includes(step);

  isStepSkipped = step => this.props.skippedSteps.includes(step);

  render() {
    const { classes, steps, activeStep, stepsRenderArray = {} } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.responsiveStep}>
          <div className={classes.stepNumberContainer}>{activeStep + 1}</div>
          <p className={classes.stateName}>{steps[activeStep]}</p>
        </div>
        <MuiStepper
          activeStep={activeStep}
          alternativeLabel
          classes={{ root: classes.MuiStepperRoot }}
        >
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};
            const iconProps = {
              classes: {
                active: classes.iconActive,
                completed:
                  stepsRenderArray.length > 0
                    ? stepsRenderArray[index]
                      ? classes.iconCompletedWithRenderTrue
                      : classes.iconCompletedWithRenderFalse
                    : classes.iconCompleted
              }
            };
            if (this.isStepFailed(index)) {
              labelProps.error = true;
            }
            if (this.isStepSkipped(index)) {
              props.completed = false;
            }
            return (
              <Step key={label} {...props}>
                <StepLabel StepIconProps={iconProps} {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </MuiStepper>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing.unit * 0.5
  },
  MuiStepperRoot: {
    marginBottom: theme.spacing.unit * 2.5,
    ['@media (max-width: 680px)']: {
      display: 'none !important'
    },
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
  iconActive: {
    color: `${orangeHighlight} !important`
  },
  iconCompleted: {
    color: `${orangeHighlight} !important`
  },
  iconCompletedWithRenderTrue: {
    color: `${theme.palette.success.main} !important`
  },
  iconCompletedWithRenderFalse: {
    color: `${theme.palette.error.main} !important`
  },
  responsiveStep: {
    height: 70,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: theme.spacing.unit * 2.5,
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    backgroundColor: 'white',
    color: '#000',
    ['@media (min-width: 680px)']: {
      display: 'none !important'
    }
  },
  stepNumberContainer: {
    height: 40,
    width: 40,
    borderRadius: 40,
    backgroundColor: '#FEB00B',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginRight: 10
  },
  stateName: {
    fontSize: '1.5rem',
    color: '#000'
  }
});

Stepper.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  steps: state.stepper.steps,
  optionalSteps: state.stepper.optionalSteps,
  skippedSteps: state.stepper.skippedSteps,
  failedSteps: state.stepper.failedSteps,
  activeStep: state.stepper.activeStep
});

const mapDispatchToProps = () => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Stepper);
