const styles = () => ({
  brillaLogo: {
    position: 'absolute',
    bottom: '24.58px',
    left: '24.58px',
    width: '121px',
    ['@media (max-width:600px)']: {
      width: '72px',
      left: '10.58px',
      bottom: '10.58px'
    }
  },
  whiteCorner: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '267px',
    ['@media (max-width:600px)']: {
      width: '143px'
    }
  },
  imageContainer: {
    background: 'url(/images/login-client.webp)',
    position: 'relative',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'top'
  }
});

export default styles;
