const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'relative',
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  menuItemRoot: {
    color: 'rgba(12,31,44,0.87)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    backgroundColor: '#FFF !important',
    height: '40px',
    padding: '0 9px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'whitesmoke !important'
    }
  },
  suggestion: {
    display: 'block'
  },
  suggestionRow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  suggestionPopper: {
    zIndex: 1500
  },
  suggestionIcon: {
    paddingRight: '10px'
  },
  suggestionMore: {
    float: 'right'
  },
  outlinedInput: {
    '&$focused $notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
      borderWidth: 2
    }
  },
  label: {
    color: 'rgba(12,31,44,0.6)',
    '&$focused': {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  focused: {},
  notchedOutline: {}
});

export default styles;
