export const columns = [
  {
    title: '# Artículo',
    name: 'id'
  },
  {
    title: 'Nombre',
    name: 'name'
  },
  {
    title: 'Categoria',
    name: 'categoryName'
  },
  {
    title: 'Marca',
    name: 'brandName'
  },
  {
    title: 'I.V.A.',
    name: 'tax'
  },
  {
    title: 'Periodo de Gracia',
    name: 'gracePeriodText'
  },
  {
    title: 'Estado',
    name: 'status'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      tax: datum.iva ? `${datum.iva}%` : '--',
      status: datum.active ? 'Activo' : 'Inactivo',
      gracePeriodText: datum.gracePeriod ? 'Si' : 'No',
      suppliers: datum.suppliers.length
        ? datum.suppliers.map(s => ({
            id: s.supplier.id,
            name: s.supplier.name
          }))
        : []
    };
  });
