import qs from 'qs';
import _omit from 'lodash/omit';

export const stringifyQuery = (props, encode=true) =>
  qs.stringify(_omit({ ...props }, 'per_page'), { encode: encode });

export default function parseQuerystring(props) {
  const searchTerm = props && props.location && props.location.search.slice(1);
  const params = (searchTerm && qs.parse(searchTerm)) || null;

  if (!params) {
    return params;
  }

  if (params.page) {
    params.page = Number.parseInt(params.page, 10);
  }

  return params;
}
