const maxKbyteSize = 600;

const calculateImageSize = base64String => {
  let padding, inBytes, base64StringLength, kbytes;
  if (base64String.endsWith('==')) padding = 2;
  else if (base64String.endsWith('=')) padding = 1;
  else padding = 0;
  base64StringLength = base64String.length;
  inBytes = (base64StringLength / 4) * 3 - padding;
  kbytes = inBytes / 1000;
  return kbytes;
};

export const limiteSize = base64String => {
  let base64Str, imagesize;
  base64Str = base64String.split(',');
  imagesize = calculateImageSize(base64Str[1]);
  if (imagesize > maxKbyteSize) {
    return null;
  } else {
    return base64String;
  }
};
