/* eslint-disable*/
import { Button, Grid, withStyles } from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import combineStyles from '../../../helpers/combineStyles';

import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';

import InputFile from '../../shared/Inputs/InputFile';

class BulkLoad extends Component {
  render() {
    const { classes, handleChangeFile, templatePath } = this.props;

    return (
      <Grid container spacing={16} justify="space-between" alignItems="center">
        <Grid item sm={8} xs={12}>
          <InputFile
            onChange={handleChangeFile}
            accept=".csv"
            //error={!!this.state.fileError}
            //helperText={this.state.fileError}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <a
            href={templatePath}
            className={classes.noDecorationAnchor}
            download
          >
            <Button color="secondary">
              {'Plantilla Base'} <CloudDownload style={{ marginLeft: '8px' }} />
            </Button>
          </a>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  noDecorationAnchor: {
    textDecoration: 'none'
  }
});

BulkLoad.propTypes = {
  handleChangeFile: PropTypes.func,
  templatePath: PropTypes.string
};

export default withStyles(
  combineStyles(styles, TypographyStyles, ButtonStyles),
  {
    withTheme: true
  }
)(BulkLoad);
