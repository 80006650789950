import _get from 'lodash/get';

export const columns = [
  {
    title: 'Nombre Proveedor',
    name: 'name'
  },
  {
    title: 'Nombre Contacto',
    name: 'contact'
  },
  {
    title: 'Correo',
    name: 'email'
  },
  {
    title: 'Teléfono',
    name: 'phone'
  },
  {
    title: 'Estado',
    name: 'status'
  },
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      name: datum.name || '--',
      contact: datum.contactRepresentative || '--',
      email: datum.email || '--',
      phone: datum.phone || '--',
      status: datum.active ? 'Activo' : 'Inactivo'
    };
  });
