import {
  LOAD_SESSION_DATA,
  OPEN_DEPLOY_MODAL,
  CLOSE_DEPLOY_MODAL,
  SET_RECAPTCHA_VALIDATION
} from '../constants/session_constants';

const initialState = {
  sessionData: null,
  confirmCaptcha: null,
  deployModalIsOpen: false,
  deployModalData: {},
  isRecaptchaValidationActive: false
};

export function sessionData(state = initialState, action) {
  switch (action.type) {
    case LOAD_SESSION_DATA:
      return {
        ...state,
        companyName: action.data,
      };
    case OPEN_DEPLOY_MODAL:
      return {
        ...state,
        deployModalIsOpen: true,
        deployModalData: action.data,
      };
    case CLOSE_DEPLOY_MODAL:
      return {
        ...state,
        deployModalIsOpen: false,
        deployModalData: {},
      };
    case SET_RECAPTCHA_VALIDATION:
      return {
        ...state,
        isRecaptchaValidationActive: action.data
      }
    default:
      return state;
  }
}
