import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import {
  generateRoutes,
  getOptionals,
  getRoutes,
  getSteps,
  goBack
} from '../../../helpers/stepperFunctions';
import LayoutStyles from '../../../styles/layout';
import ActivityIndicator from '../../shared/ActivityIndicator';
import PortalContractDetails from '../../shared/PortalContractDetails';
import Stepper from '../../shared/Stepper';
import PortalErrors from './PortalErrors';

class Portal extends Component {
  state = {
    persisted: false,
    routeList: []
  };

  async componentDidMount() {
    const process = 'portal';

    if (this.props.location.pathname.includes('validate-debtor')) {
      await this.props.clearFinancing();
      await this.props.resetStepper();
    } else {
      this.props.persistFinancing();
      this.props.persistStepper();
    }

    this.props.globalParameters.validateSaleInvoices === null &&
      (await this.props.fetchGlobalParameters());
    const routeList = generateRoutes(process, this.props.globalParameters);

    this.props.setSteps(getSteps(routeList), getRoutes(routeList), process);
    this.props.setOptionalSteps(getOptionals(routeList));

    this.setState({ routeList, persisted: true });
  }

  _renderStep = () => {
    const {
      location,
      saleCompleted,
      routes,
      skips,
      history,
      promissory,
      classes
    } = this.props;
    const currentStep = routes.indexOf(location.pathname);
    const maxStep = this.props[`${this.props.process}Next`];
    const route = this.state.routeList.find(
      route => route.path === location.pathname
    );
    if (currentStep >= 0 && maxStep >= 0 && route) {
      if (
        currentStep <= maxStep ||
        (saleCompleted && location.pathname === '/portal/end-sale')
      ) {
        return (
          <Fragment>
            {location.pathname !== '/portal/validate-debtor' && (
              <PortalContractDetails />
            )}
            <Stepper />
            {location.pathname === '/portal/contract-information' &&
              (promissory &&
                (promissory.status === 'rejected' ||
                  promissory.status === 'registered')) && (
                <Card
                  className={classes.alertMessage}
                  style={{ minHeight: 100, marginBottom: 20 }}
                >
                  <p>
                    El pagaré único no se encuentra aprobado, valida en la
                    opción <strong>ver pagarés</strong> que todos los datos de
                    éste se encuentren correctos, para que al finalizar el
                    registro de la venta reimprimas los documentos
                  </p>
                </Card>
              )}
            <Route
              exact
              path={route.path}
              render={props => (
                <route.component
                  {...props}
                  goBack={() => goBack(routes, skips, location.pathname)}
                />
              )}
            />
          </Fragment>
        );
      } else {
        history.push(routes[maxStep]);
        this.props.newAlert(
          'error',
          'ERROR:',
          'No puedes ingresar a este paso sin haber completado los anteriores.'
        );
      }
    } else {
      history.push(routes[maxStep]);
      this.props.newAlert(
        'error',
        'ERROR:',
        'La ruta ingresada no existe en el registro de venta.'
      );
    }
  };

  render() {
    const {
      location,
      server_errors,
      baseErrorOpen,
      baseErrorText,
      baseErrorTitle,
      baseErrorRoute,
      toggleBaseErrorModal,
      loadingParameters
    } = this.props;
    if (loadingParameters && location.pathname.includes('validate-debtor'))
      return <ActivityIndicator />;
    if (!this.state.persisted) return null;
    return (
      <Fragment>
        <PortalErrors
          server_errors={server_errors}
          baseErrorOpen={baseErrorOpen}
          baseErrorText={baseErrorText}
          baseErrorTitle={baseErrorTitle}
          toggleModal={toggleBaseErrorModal}
          baseErrorRoute={baseErrorRoute}
        />
        {this._renderStep()}
      </Fragment>
    );
  }
}

export default withStyles(LayoutStyles)(Portal);
