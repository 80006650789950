export function corporationValidation(api_error, returnBool) {
  if (api_error && api_error.corporation)
    return returnBool ? true : api_error.corporation;
  if (api_error && api_error.corporation_id)
    return returnBool ? true : api_error.corporation_id;
  return returnBool ? false : '';
}

export function minValidation(value, api_error, returnBool) {
  if (api_error && api_error.min_value)
    return returnBool ? true : api_error.min_value;
  return returnBool ? false : '';
}

export function maxValidation(value, api_error, returnBool) {
  if (api_error && api_error.max_value)
    return returnBool ? true : api_error.max_value;
  return returnBool ? false : '';
}
