import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';

import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import SpacingStyles from '../../../../styles/helpers/spacing';

import DialogForm from '../../../shared/Modals/DialogForm';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';

import * as Yup from 'yup';

export class EditInsuranceModal extends Component {
  _handleOnClose = () => {
    this.props.onClose();
  };

  _handleOnSave = async values => {
    const { onSave } = this.props;
    try {
      await onSave(values);
      return true;
    } catch {
      return false;
    }
  };

  render() {
    const { classes, isSubmitting } = this.props;

    const validationSchema = Yup.object().shape({
      totalValue: Yup.number().required('Valor es requerido')
    });

    return (
      <Formik
        ref={ref => (this.formik = ref)}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          this._handleOnSave(values);
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty
        }) => (
          <DialogForm
            open={true}
            onClose={handleReset}
            title={
              <div className={classes.titleContainer}>
                <p className={classnames(classes.headline6, classes.title)}>
                  Editar valor
                </p>
              </div>
            }
            footer={
              <Fragment>
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={!dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Aprobar'}
                </ProgressButton>
              </Fragment>
            }
          >
            <Grid container spacing={16}>
              <GridItem xs={12}>
                <OutlinedTextField
                  label="Valor"
                  name="totalValue"
                  type={'number'}
                  value={values.totalValue}
                  onChange={handleChange}
                />
              </GridItem>
            </Grid>
          </DialogForm>
        )}
      />
    );
  }
}

export default withStyles(
  combineStyles(LayoutStyles, TypographyStyles, ButtonStyles, SpacingStyles)
)(EditInsuranceModal);
