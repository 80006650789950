import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import QuickStatusCard from './QuickStatusCard';
import { Grid } from '@material-ui/core';

const icon_room_service = require('../../../assets/icons/room-service.svg');
const icon_cloud = require('../../../assets/icons/cloud-done-black.svg');
const not_disturb = require('../../../assets/icons/do-not-disturb.svg');

const styles = theme => ({
  rowCardWrapper: {
    width: 'calc(50% - 12px)'
  },
  quickStatusContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginTop: '-20px'
  }
});

export const Header = ({
  classes,
  theme,
  isLoading,
  data,
}) => (
  <Grid item xs={12} md={12} className={classes.quickStatusContainer}>
    <QuickStatusCard
      message={'Cantidad de servicios'}
      isLoading={isLoading}
      icon={icon_room_service}
      number={Object.keys(data).length}
    />
    <QuickStatusCard
      message={'Estables'}
      isLoading={isLoading}
      icon={icon_cloud}
      number={Object.values(data).filter((d) => (d === 'Ok')).length}
    />
    <QuickStatusCard
      message={'No disponibles en el momento'}
      isLoading={isLoading}
      icon={not_disturb}
      number={Object.values(data).filter((d) => (d != 'Ok')).length}
    />
  </Grid>
);

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles)
)(Header);