import { connect } from 'react-redux';
import PandotyCourseDetail from './PandotyCourseDetail';
import { bindActionCreators, compose } from 'redux';
import { fetchCoursesDetails } from '../../../actions/authentication_actions';

function mapStateToProps(state) {
    return {
      currentUser: state.authentication.currentUser,
      courseDetail: state.authentication.courseDetail
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        fetchCoursesDetails: bindActionCreators(fetchCoursesDetails, dispatch)
    };
}

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps
))(PandotyCourseDetail);