import {
  PROMISSORIES_SET,
  SET_PROMISSORY,
  PROMISSORY_RANGES,
  PROMISSORY_RANGES_MODAL,
  SAVED_PROMISSORY,
  CANCELLED_PROMISSORY,
  REVOKED_PROMISSORY,
  APPROVED_PROMISSORY
} from '../constants/action_constants';

const initialState = {
  promissories: [],
  meta: {},
  promissory: null,
  ranges: [],
  rangesMeta: {},
  rangeDetail: null,
  modalOpen: false,
  isEditing: false,
  editPromissorySaved: false,
  cancelPromissorySaved: false,
  revokePromissorySaved: false,
  approvePromissorySaved: false
};

export function promissory(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case PROMISSORIES_SET:
      return {
        ...state,
        promissories: action.data,
        meta: action.meta
      };
    case SET_PROMISSORY:
      return {
        ...state,
        promissory: action.data,
        editPromissorySaved: false,
        cancelPromissorySaved: false,
        approvePromissorySaved: false,
        revokePromissorySaved: false
      };
    case SAVED_PROMISSORY:
      return {
        ...state,
        editPromissorySaved: true
      };
    case CANCELLED_PROMISSORY:
      return {
        ...state,
        cancelPromissorySaved: true
      };
    case REVOKED_PROMISSORY:
      return {
        ...state,
        revokePromissorySaved: true
      };
    case APPROVED_PROMISSORY:
      return {
        ...state,
        approvePromissorySaved: true
      };
    case PROMISSORY_RANGES:
      return {
        ...state,
        ranges: action.data,
        rangesMeta: action.meta
      };
    case PROMISSORY_RANGES_MODAL:
      return {
        ...state,
        rangeDetail: action.data,
        modalOpen: action.open,
        isEditing: action.isEditing
      };
    default:
      return state;
  }
}
