import _get from 'lodash/get';

export const columns = [
  {
    title: '# Empresa',
    name: 'id'
  },
  {
    title: 'Nombre Empresa',
    name: 'name'
  },
  {
    title: 'NIT',
    name: 'identification'
  },
  {
    title: 'Estado',
    name: 'status'
  },
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      id: datum.id,
      name: datum.name || '--',
      identification: datum.identification || '--',
      status: datum.active ? 'Activo' : 'Inactivo'
    };
  });
