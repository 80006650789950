import {
  LIST_SECURE_CREDIT_INSTALMENTS_PERCENTAGES,
  SAVE_SECURE_CREDIT_INSTALMENTS_PERCENTAGES
} from '../constants/secure_credit_instalments_percentages_constants';

const initialState = {
  list: [],
  meta: {},
  saved: false
};

export function secureCreditInstalmentsPercentage(state = initialState, action) {
  switch (action.type) {
    case LIST_SECURE_CREDIT_INSTALMENTS_PERCENTAGES:
      return {
        ...state,
        list: action.data,
        meta: action.meta
      };
    case SAVE_SECURE_CREDIT_INSTALMENTS_PERCENTAGES:
      return {
        ...state,
        saved: true
      };
    default:
      return state;
  }
}