export const styles = theme => ({
    titleWrapper: {
      display: 'flex',
      marginBottom: `${theme.spacing.unit * 2.125}px !important`,
      position: 'relative'
    },
    title: {
      flex: 1,
      fontWeight: 'bold !important'
    },
    resetLinkButton: {
      position: 'absolute',
      right: 0,
      top: -6
    },
    resetLink: {
      textTransform: 'capitalize',
      color: `${theme.palette.primary.main} !important`
    },
    autocomplete: {
      marginTop: 16
    },
    modalFooter: {
      marginTop: `${theme.spacing.unit * 5}px !important`
    },
    dialogRoot: {
      width: 650
    },
  });