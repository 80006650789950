/* eslint-disable complexity */
import React, { PureComponent } from 'react';
import _remove from 'lodash/remove';
import _sortedUniq from 'lodash/sortedUniq';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircleRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleRounded';

import { MapSteps } from './MovementSteps';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

class RequestMovements extends PureComponent {
  state = {
    openStatus: []
  };

  _handleStatusClick = idx => {
    const { openStatus } = this.state;
    const newOpenStatus = openStatus;
    if (!openStatus.includes(idx)) {
      newOpenStatus.push(idx);
    } else {
      _remove(newOpenStatus, i => i == idx);
    }
    const withoutDupli = _sortedUniq(newOpenStatus);
    this.setState({ openStatus: withoutDupli });
  };

  _handleIcon = move => {
    const { classes } = this.props;
    if (move.status == 'Registrada') {
      return (
        <AddCircleIcon
          color={move.isCompleted ? 'primary' : 'disabled'}
          className={classes.icon}
        />
      );
    }
    if (move.status == 'Revisada' || move.status == 'Aprobada') {
      return (
        <CheckCircleIcon
          color={move.isCompleted ? 'primary' : 'disabled'}
          className={classes.icon}
        />
      );
    }
    if (move.status == 'Rechazada' || move.status == 'Desactivada') {
      return (
        <RemoveCircleIcon
          color={move.isCompleted ? 'primary' : 'disabled'}
          className={classes.icon}
        />
      );
    }
    return null;
  };
  render() {
    const { classes, movements, disables } = this.props;
    return (
      <div className={classes.root}>
        <Stepper alternativeLabel nonLinear activeStep={0}>
          {MapSteps(movements, disables).map((move, index) => {
            return (
              <Step key={`step_${index}`} completed={move.isCompleted}>
                <StepButton
                  onClick={() => this._handleStatusClick(index)}
                  icon={this._handleIcon(move)}
                  optional={
                    this.state.openStatus.includes(index) ? (
                      <Typography variant="caption">
                        {move.createdAt}
                      </Typography>
                    ) : (
                      <Typography variant="caption" className={classes.name}>
                        {move.createdBy}
                      </Typography>
                    )
                  }
                >
                  {move.status}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  }
}
const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
    width: '100%',
    flexDirection: 'column',
    marginBottom: theme.spacing.unit
  },
  name: {
    textTransform: 'capitalize'
  },
  icon: {
    fontSize: 25
  }
});
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles
  )
)(RequestMovements);
