import _get from 'lodash/get';

export const columns = [
  {
    title: 'Nombre',
    name: 'name',
  },
  {
    title: 'Ejecutivo',
    name:  'executiveName',
  },
];

export const mapData = data =>
  data.map(datum => {
    return {
      id: datum.id,
      name: datum.name,
      executiveName: datum.lineExecutive.name,
      lineExecutiveId: datum.lineExecutive.id,
      categoryIds: datum.categoryIds,
    };
});