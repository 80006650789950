/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import {
  nextStep,
  previousStep,
  skipStep
} from '../../actions/stepper_actions';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PrimaryButton from '../shared/Buttons/PrimaryButton';
import LoadingButton from '../shared/Buttons/LoadingButton';

class StepperButtons extends React.PureComponent {
  state = {
    nextButtonDisabled: false
  };

  next = () => {
    // Disable next button to prevent multiple clicks
    this.setState({ nextButtonDisabled: true });
    setTimeout(() => {
      this.setState({ nextButtonDisabled: false });
    }, 5000);

    if (this.props.onSubmit) {
      this.props.onSubmit();
    } else {
      this.props.handleNext();
    }
  };

  back = () => {
    if (this.props.goBack) {
      this.props.goBack();
    } else {
      this.props.handleBack();
    }
  };

  alternativeButtonCallback = () => {
    if (this.props.alternativeButtonCallback) {
      this.props.alternativeButtonCallback();
    }
  };

  skip = () => {
    if (this.props.skip) {
      this.props.skip();
    }
    this.props.handleSkip();
  };

  render() {
    const {
      stepIsLoading,
      customLoading,
      steps,
      optionalSteps,
      allowOptional,
      allowNext = true,
      activeStep,
      classes,
      process = '',
      disabledNext = false,
      nextText = '',
      nextIsPrimary = false,
      alternativeButtonText = '',
      alternativeButtonLoading = false
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.buttonsContainer}>
          {(((process === 'teleshopping' || process === 'insurance') &&
            activeStep !== 0 &&
            activeStep <= steps.length - 1) ||
            ((process !== 'teleshopping' || process !== 'insurance') &&
              activeStep !== 0 &&
              activeStep < steps.length - 1)) && (
            <Button
              onClick={() => this.back()}
              className={classes.button}
              disabled={customLoading || stepIsLoading}
            >
              Atrás
            </Button>
          )}
          {optionalSteps.includes(activeStep) && allowOptional && (
            <PrimaryButton
              onClick={() => this.skip()}
              className={classes.button}
              disabled={disabledNext || this.state.nextButtonDisabled}
            >
              Omitir
            </PrimaryButton>
          )}
          {alternativeButtonText && (
            <LoadingButton
              loading={alternativeButtonLoading || stepIsLoading}
              onClick={() => this.alternativeButtonCallback()}
              className={classes.button}
              disabled={alternativeButtonLoading || stepIsLoading}
            >
              {alternativeButtonText}
            </LoadingButton>
          )}
          {allowNext &&
            (nextIsPrimary ? (
              <PrimaryButton
                loading={customLoading || stepIsLoading}
                onClick={() => this.next()}
                className={classes.button}
                disabled={disabledNext || this.state.nextButtonDisabled}
              >
                {activeStep >= steps.length - 1
                  ? 'Finalizar'
                  : nextText != ''
                  ? nextText
                  : 'Siguiente'}
              </PrimaryButton>
            ) : (
              <LoadingButton
                loading={customLoading || stepIsLoading}
                onClick={() => this.next()}
                disabled={disabledNext || this.state.nextButtonDisabled}
              >
                {activeStep >= steps.length - 1
                  ? 'Finalizar'
                  : nextText != ''
                  ? nextText
                  : 'Siguiente'}
              </LoadingButton>
            ))}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 3
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  button: {
    marginRight: theme.spacing.unit
  }
});

const mapStateToProps = state => ({
  steps: state.stepper.steps,
  optionalSteps: state.stepper.optionalSteps,
  activeStep: state.stepper.activeStep,
  skippedSteps: state.stepper.skippedSteps,
  stepIsLoading:
    state.portalFinancing.stepIsLoading ||
    state.digitalSalesDocumentRevisions.stepIsLoading
});

const mapDispatchToProps = dispatch => ({
  handleBack: bindActionCreators(previousStep, dispatch),
  handleSkip: bindActionCreators(skipStep, dispatch),
  handleNext: bindActionCreators(nextStep, dispatch)
});

StepperButtons.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(StepperButtons);
