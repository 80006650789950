import React, { Component, Fragment } from 'react';
import Modal from '../../shared/Modals/Modal';

import _compact from 'lodash/compact';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import DateFieldInput from '../../shared/Inputs/DateField';

import {
  withCombinedStyles,
  withComposedEnhancers
} from '../../../helpers/hocUtils';
import TypographyStyles from '../../../styles/typography';
import LayoutStyles from '../../../styles/layout';
import SpacingStyles from '../../../styles/helpers/spacing';
import ButtonStyles from '../../../styles/button';

const DATE_FORMAT = 'YYYY-MM-DD';

const styles = theme => ({
  title: {
    fontWeight: 'bold !important'
  },
  actionButtons: {
    padding: 0,
    width: 96
  },
  titleWrapper: {
    marginBottom: theme.spacing.unit * 4.125
  },
  pickersContainer: {
    alignItems: 'center',
    display: 'flex'
  },
  pickersSeparator: {
    margin: [[0, theme.spacing.unit * 2], '!important']
  }
});

export class PickCustomDateModal extends Component {
  state = {
    startDate: null,
    endDate: null
  };

  componentDidMount() {
    let startDate = null;
    let endDate = null;
    if (this.props.value && this.props.value !== 'todas') {
      [startDate = null, endDate = null] = this.props.value.split(' - ');
    }
    this.setState({ startDate, endDate });
  }

  _handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  _handleFilter = () => {
    const { onSave, name } = this.props;
    const { startDate, endDate } = this.state;

    onSave({
      target: { name, value: _compact([startDate, endDate]).join(' - ') }
    });
  };

  render() {
    const { classes, onClose } = this.props;

    return (
      <Modal
        open={true}
        onClose={onClose}
        body={
          <Fragment>
            <div
              className={classnames(
                classes.titleRootWrapper,
                classes.titleWrapper
              )}
            >
              <h1 className={classnames(classes.headline6, classes.title)}>
                {'Personalizar fecha'}
              </h1>
            </div>
            <div className={classes.pickersContainer}>
              <DateFieldInput
                value={this.state.startDate}
                name={'startDate'}
                label={'Fecha inicial'}
                onChange={this._handleChange}
                format={DATE_FORMAT}
                savingFormat={DATE_FORMAT}
                disablePast={false}
              />
              <p
                className={classnames(
                  classes.body2Light,
                  classes.pickersSeparator
                )}
              >
                to
              </p>
              <DateFieldInput
                value={this.state.endDate}
                name={'endDate'}
                label={'Fecha final'}
                onChange={this._handleChange}
                format={DATE_FORMAT}
                savingFormat={DATE_FORMAT}
                disablePast={false}
              />
            </div>
          </Fragment>
        }
        footer={
          <Fragment>
            <Button
              color={'secondary'}
              className={classnames(classes.button, classes.actionButtons)}
              classes={{
                label: classes.buttonLabel
              }}
              onClick={onClose}
            >
              {'Cancelar'}
            </Button>
            <Button
              color={'secondary'}
              className={classnames(classes.button, classes.actionButtons)}
              classes={{
                label: classes.buttonLabel,
                disabled: classes.buttonDisabled
              }}
              onClick={this._handleFilter}
            >
              {'Filtrar'}
            </Button>
          </Fragment>
        }
      />
    );
  }
}

export default withComposedEnhancers(
  PickCustomDateModal,
  withCombinedStyles(
    true,
    TypographyStyles,
    LayoutStyles,
    SpacingStyles,
    ButtonStyles,
    styles
  )
);
