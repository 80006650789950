import * as Yup from 'yup';

export const validationSchema = (osfMaxIdLength) => Yup.object().shape({
  typeOfIdentification: Yup.string().required('Es requerido'),
  identification: Yup.string()
    .when('typeOfIdentification', {
      is: 'Cédula',
      then: Yup.string()
        .matches(
          /^[0-9]+$/,
          'Tipo de documento no admite caracteres especiales o letras.'
        )
        .required('Es requerido')
    })
    .when('typeOfIdentification', {
      is: 'Pasaporte',
      then: Yup.string()
        .matches(
          /^[A-Z-a-z-0-9-]+$/,
          'Tipo de documento no admite caracteres especiales.'
        )
        .required('Es requerido')
    })
    .when('typeOfIdentification', {
      is: 'Cédula de Extranjería',
      then: Yup.string()
        .matches(
          /^[A-Z-a-z-0-9-]+$/,
          'Tipo de documento no admite caracteres especiales.'
        )
        .required('Es requerido')
    })
    .when('typeOfIdentification', {
      is: 'Nit / Rut',
      then: Yup.string()
        .matches(
          /^[0-9-]+$/,
          'Tipo de documento solo admite números y el caracter - '
        )
        .required('Es requerido')
    })
    .max(
      osfMaxIdLength,
      `La identificación debe tener un máximo de hasta ${osfMaxIdLength} caracteres`
    ),
  firstName: Yup.string().required('Es requerido'),
  lastName: Yup.string().required('Es requerido'),
  email: Yup.string().email('El email debe ser válido').required('El correo no puede estar vacío'),
  roleId: Yup.string().required('Es requerido'),
});
