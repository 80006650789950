import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import parseQuerystring from '../../../helpers/parseQuerystring';

import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import _debounce from 'lodash/debounce';
import _find from 'lodash/find';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import _replace from 'lodash/replace';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';

import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';

import { filterListFuzzyly } from '../../../helpers/utilityFunctions';

class LocationExceptionFilter extends PureComponent {
  state = {
    geographicLocation: '',
    geographicLocationId: 0,
    supplier: '',
    supplierId: 0,
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    const custom = {};
    this.setState({
      ...this.state,
      ...query,
      ...custom
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleAutoSuggest = name => (event, { newValue }) => {
    this.setState({ [name]: newValue }, (name, newValue) =>
      this._searchCallback(name, newValue)
    );
  };

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter && filter != 0),
      [
        'supplierId',
        'geographicLocationId',
      ]
    );
    return filterParams;
  };

  _getSupplierSuggestions = async value => {
    const suppliers = this.props.suppliers.map(supplier => ({
      label: supplier.name,
      value: supplier.id
    }));

    const suggestions = filterListFuzzyly(value, suppliers, 'label');

    return Promise.resolve(suggestions);
  };

  _getGeographicLocationsSuggestions = async value => {
    const geographicLocations = this.props.geographicLocations.map(geographicLocation => ({
      label: `${geographicLocation.id} - ${geographicLocation.name || geographicLocation.description}`,
      value: geographicLocation.id
    }));
    const suggestions = filterListFuzzyly(value, geographicLocations, 'label');

    return Promise.resolve(suggestions);
  };

  render() {
    const { classes } = this.props;
    const filterBy = this.props.filterSalesBy;

    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
                <Grid item md={6}>
                  <AutocompleteInput
                    name="geographicLocation"
                    label="Zona geográfica"
                    margin="normal"
                    variant="outlined"
                    error={''}
                    helperText={''}
                    value={this.state.geographicLocation}
                    onChange={change => {
                      if (this.state.geographicLocation && !change) {
                        this._handleSelectChange({
                          target: { name: 'geographicLocation', value: ''}
                        });
                        this._handleSelectChange({
                          target: { name: 'geographicLocationId', value: 0}
                        });
                      }
                      this.setState({ geographicLocation: change });
                    }}
                    suggestions={this.props.geographicLocations}
                    getSuggestions={this._getGeographicLocationsSuggestions}
                    onSuggestionSelected={geographicLocation =>
                      {this._handleSelectChange({
                        target: {
                          name: 'geographicLocation',
                          value: geographicLocation.label
                        }
                      });
                      this._handleSelectChange({
                        target: {
                          name: 'geographicLocationId',
                          value: geographicLocation.value
                        }
                      })}
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <AutocompleteInput
                    id="name"
                    name="supplier"
                    label="Aliado"
                    margin="normal"
                    variant="outlined"
                    error={''}
                    helperText={''}
                    value={this.state.supplier}
                    onChange={change => {
                      if (this.state.supplier && !change) {
                        this._handleSelectChange({
                          target: { name: 'supplier', value: '' }
                        });
                        this._handleSelectChange({
                          target: { name: 'supplierId', value: 0 }
                        });
                      }
                      this.setState({ supplier: change });
                    }}
                    suggestions={this.props.suppliers}
                    getSuggestions={this._getSupplierSuggestions}
                    onSuggestionSelected={supplier =>
                      {this._handleSelectChange({
                        target: { name: 'supplier', value: supplier.label }
                      })
                      this._handleSelectChange({
                        target: { name: 'supplierId', value: supplier.value }
                      })}
                    }
                  />
                </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    // marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
LocationExceptionFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(LocationExceptionFilter);
