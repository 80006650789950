import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { newAlert } from '../../../actions/alert_actions';
import CorporationDetail from './CorporationDetail';
import { fetchCorporationInfo } from '../../../actions/corporations_actions';

function mapStateToProps(state) {
  return {
    permissions: state.authentication.permissions,
    currentUser: state.authentication.currentUser,
    corporation: state.corporations.corporationInfo,
    corporationLoading: state.requestState.requestCorporationInfoSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchCorporationInfo: bindActionCreators(fetchCorporationInfo, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorporationDetail);
