import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FilePicker } from 'react-file-picker';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import TextField from '@material-ui/core/TextField';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';

const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

class FileUploader extends Component {
  state = {
    fileName: null,
    newCommentMessage: '',
    fileAccepted: null
  };

  constructor(props) {
    super(props);
    this.state = {
      fileName: props.fileName,
      newCommentMessage: '',
      fileAccepted: [true, false].includes(props.fileAccepted)
        ? props.fileAccepted
        : null
    };
  }

  render() {
    const {
      label,
      onChange,
      classes,
      onError,
      maxSize = 5,
      sizeUnit = 'MB',
      extensions = ['pdf'],
      allowFileReplace,
      description,
      submitting,
      comments,
      showCommentSection = true,
      onViewOtherComments,
      onAddComment,
      canAcceptOrRejectFile,
      allowAddComment,
      fetchFileBase64
    } = this.props;
    const { fileName, newCommentMessage, fileAccepted } = this.state;
    if (fileName !== this.props.fileName) {
      this.setState({ fileName: this.props.fileName });
    }
    const acceptFileButtonStyles = canAcceptOrRejectFile
      ? fileAccepted === true
        ? classes.acceptFileButtonSelected
        : classes.acceptFileButtonNotSelected
      : classes.acceptFileButtonStatic;
    const rejectFileButtonStyles = canAcceptOrRejectFile
      ? fileAccepted === false
        ? classes.rejectFileButtonSelected
        : classes.rejectFileButtonNotSelected
      : classes.rejectFileButtonStatic;
    return (
      <Grid container item>
        <Grid container item alignItems="flex-end" xs={12}>
          <Grid item xs={11}>
            <Typography variant="body1" className={classes.fileName}>
              {typeof label === 'string' ? capitalize(label) : label}
            </Typography>
          </Grid>
          {!!fileName &&
            !description &&
            (canAcceptOrRejectFile ? (
              <Grid container item xs={1}>
                <Grid container item justify="flex-end" xs={6}>
                  <CheckCircleIcon
                    className={acceptFileButtonStyles}
                    style={{ marginRight: '8px' }}
                    onClick={this._onAcceptFileButtonClicked}
                  />
                </Grid>
                <Grid container item justify="flex-end" xs={6}>
                  <BlockIcon
                    className={rejectFileButtonStyles}
                    onClick={this._onRejectFileButtonClicked}
                  />
                </Grid>
              </Grid>
            ) : (
              [true, false].includes(fileAccepted) &&
              (fileAccepted ? (
                <Grid container item justify="flex-end" xs={1}>
                  <CheckCircleIcon className={acceptFileButtonStyles} />
                </Grid>
              ) : (
                <Grid container item justify="flex-end" xs={1}>
                  <BlockIcon className={rejectFileButtonStyles} />
                </Grid>
              ))
            ))}
        </Grid>
        {!!description && (
          <Grid container item alignItems="flex-end" xs={12}>
            <Grid item xs={11}>
              <Typography variant="body1">{capitalize(description)}</Typography>
            </Grid>
            {!!fileName &&
              (canAcceptOrRejectFile ? (
                <Grid container item xs={1}>
                  <Grid container item justify="flex-end" xs={6}>
                    <CheckCircleIcon
                      className={acceptFileButtonStyles}
                      onClick={this._onAcceptFileButtonClicked}
                    />
                  </Grid>
                  <Grid container item justify="flex-end" xs={6}>
                    <BlockIcon
                      className={rejectFileButtonStyles}
                      onClick={this._onRejectFileButtonClicked}
                    />
                  </Grid>
                </Grid>
              ) : (
                [true, false].includes(fileAccepted) &&
                (fileAccepted ? (
                  <Grid container item justify="flex-end" xs={1}>
                    <CheckCircleIcon className={acceptFileButtonStyles} />
                  </Grid>
                ) : (
                  <Grid container item justify="flex-end" xs={1}>
                    <BlockIcon className={rejectFileButtonStyles} />
                  </Grid>
                ))
              ))}
          </Grid>
        )}
        {submitting ? (
          <LoadingState classes={classes} />
        ) : fileName ? (
          <FullState
            classes={classes}
            fileName={fileName}
            allowFileReplace={allowFileReplace}
            maxSize={maxSize}
            sizeUnit={sizeUnit}
            extensions={extensions}
            setFile={this._setFile}
            onError={onError}
            nullifyFile={() => {
              this.setState({ fileName: null });
              onChange({
                base64String: null,
                fileName: null
              });
            }}
            fetchFileBase64={fetchFileBase64}
          />
        ) : (
          <EmptyState
            classes={classes}
            allowFileReplace={allowFileReplace}
            maxSize={maxSize}
            sizeUnit={sizeUnit}
            extensions={extensions}
            setFile={this._setFile}
            onError={onError}
          />
        )}
        {showCommentSection && (
          <CommentSection
            classes={classes}
            comments={comments}
            onViewOtherComments={onViewOtherComments}
            onAddComment={onAddComment}
            newCommentMessage={newCommentMessage}
            setNewCommentMessage={message =>
              this.setState({ newCommentMessage: message })
            }
            allowAddComment={allowAddComment}
          />
        )}
      </Grid>
    );
  }

  _setFile = file => {
    const { onChange } = this.props;
    this.setState({ fileName: file.name });
    fileToBase64(file).then(base64Str => {
      onChange({
        base64String: base64Str,
        fileName: file.name
      });
    });
  };

  _onAcceptFileButtonClicked = () => {
    const { fileAccepted } = this.state;
    const { onFileAcceptanceChange } = this.props;
    let newValue = null;
    if (fileAccepted !== true) {
      newValue = true;
    }
    this.setState({ fileAccepted: newValue });
    onFileAcceptanceChange(newValue);
  };

  _onRejectFileButtonClicked = () => {
    const { fileAccepted } = this.state;
    const { onFileAcceptanceChange } = this.props;
    let newValue = null;
    if (fileAccepted !== false) {
      newValue = false;
    }
    this.setState({ fileAccepted: newValue });
    onFileAcceptanceChange(newValue);
  };
}

const LoadingState = props => {
  const { classes } = props;
  return (
    <Grid
      container
      item
      justify="center"
      xs={12}
      className={classes.fileZoneContainerWithFile}
    >
      <CircularProgress color="secondary" />
    </Grid>
  );
};

const FullState = props => {
  const {
    classes,
    fileName,
    allowFileReplace,
    maxSize,
    sizeUnit,
    extensions,
    setFile,
    onError,
    nullifyFile,
    fetchFileBase64
  } = props;
  return (
    <Grid container item xs={12} className={classes.fileZoneContainerWithFile}>
      <Grid container item xs={12}>
        <Grid container item justify="center" xs={1}>
          <AttachFileIcon />
        </Grid>
        <Grid container item xs={9}>
          <TextField disabled={true} fullWidth={true} value={fileName} />
        </Grid>
        <Grid
          container
          item
          justify="center"
          xs={1}
          className={classes.clickableIcon}
        >
          <VisibilityIcon onClick={fetchFileBase64} />
        </Grid>
        {allowFileReplace && (
          <Grid
            container
            item
            justify="center"
            xs={1}
            className={classes.clickableIcon}
          >
            <DeleteIcon onClick={nullifyFile} />
          </Grid>
        )}
      </Grid>
      {allowFileReplace && (
        <Grid container item xs={12} justify="center" alignItems="center">
          <FilePicker
            maxSize={maxSize}
            sizeUnit={sizeUnit}
            extensions={extensions}
            dims={{
              minWidth: 100,
              maxWidth: 1000,
              minHeight: 100,
              maxHeight: 1000
            }}
            onChange={setFile}
            onError={onError}
          >
            <ProgressButton color="secondary">
              {extensions.length > 0
                ? `Reemplace el archivo (${extensions.join(', ')})`
                : 'Reemplace el archivo'}
            </ProgressButton>
          </FilePicker>
        </Grid>
      )}
    </Grid>
  );
};

const EmptyState = props => {
  const {
    classes,
    maxSize,
    sizeUnit,
    extensions,
    setFile,
    onError,
    allowFileReplace
  } = props;
  return (
    <Grid
      container
      item
      xs={12}
      justify="center"
      alignItems="center"
      className={classes.fileZoneContainerWithoutFile}
    >
      <FilePicker
        maxSize={maxSize}
        sizeUnit={sizeUnit}
        extensions={extensions}
        dims={{
          minWidth: 100,
          maxWidth: 1000,
          minHeight: 100,
          maxHeight: 1000
        }}
        onChange={setFile}
        onError={onError}
      >
        <ProgressButton color="secondary" disabled={!allowFileReplace}>
          {extensions.length > 0
            ? `Cargue el archivo (${extensions.join(', ')})`
            : 'Cargue el archivo'}
        </ProgressButton>
      </FilePicker>
    </Grid>
  );
};

const CommentSection = props => {
  const {
    comments,
    classes,
    onViewOtherComments,
    onAddComment,
    newCommentMessage,
    setNewCommentMessage,
    allowAddComment
  } = props;
  const comment = comments.length > 0 && comments[0];
  const viewOtherCommentsMessage =
    comments.length === 2
      ? 'Ver el otro comentario'
      : `Ver los otros ${comments.length - 1} comentarios`;
  return (
    <Grid container item>
      <Grid
        container
        item
        xs={12}
        className={classes.commentarySectionTitleContainer}
      >
        <Grid container item xs={6}>
          <Typography variant="h5">Comentarios</Typography>
        </Grid>
        {comments.length > 1 && (
          <Grid container item justify="flex-end" xs={6}>
            <Button
              color="secondary"
              onClick={() => onViewOtherComments(comments.slice(1))}
            >
              {viewOtherCommentsMessage}
            </Button>
          </Grid>
        )}
      </Grid>
      {!!comment && (
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <Grid container item xs={9}>
              <Typography variant="body1" align="justify">
                {comment.message}
              </Typography>
            </Grid>
            <Grid
              container
              item
              justify="flex-end"
              alignItems="flex-end"
              xs={3}
            >
              <Typography variant="body1" className={classes.createdAtMessage}>
                {comment.displayCreatedAt}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {allowAddComment && (
        <Grid container item xs={12}>
          <Grid item xs={12} className={classes.dividerContainer}>
            <Divider />
          </Grid>
          <Grid container item xs={12}>
            <TextField
              fullWidth
              multiline
              value={newCommentMessage}
              onChange={e => {
                const message = e.target.value;
                setNewCommentMessage(message);
              }}
              variant="outlined"
            />
          </Grid>
          <Grid container item justify="flex-end" xs={12}>
            <Button
              color="secondary"
              disabled={!newCommentMessage}
              onClick={() => {
                onAddComment(newCommentMessage);
                setNewCommentMessage('');
              }}
            >
              Agregar comentario
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const capitalize = str => {
  if (!str) str = '';
  return str.trim().replace(/^\w/, char => char.toUpperCase());
};

const styles = theme => ({
  fileZoneContainerWithoutFile: {
    borderStyle: 'dashed',
    borderRadius: 10,
    minHeight: 100,
    maxHeight: 100,
    padding: 20
  },
  fileZoneContainerWithFile: {
    borderStyle: 'dashed',
    borderRadius: 10,
    minHeight: 100,
    maxHeight: 100,
    padding: 20,
    backgroundColor: '#f7f7f7'
  },
  clickableIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: 'gray'
    }
  },
  dividerContainer: {
    marginTop: 10,
    marginBottom: 10
  },
  commentarySectionTitleContainer: {
    marginTop: 10,
    marginBottom: 10
  },
  createdAtMessage: {
    color: 'gray'
  },
  acceptFileButtonSelected: {
    color: '#25b84c',
    '&:hover': {
      color: 'green'
    }
  },
  acceptFileButtonNotSelected: {
    color: '#CCCCCC',
    '&:hover': {
      color: 'gray'
    }
  },
  acceptFileButtonStatic: {
    color: '#25b84c'
  },
  rejectFileButtonSelected: {
    color: 'red',
    '&:hover': {
      color: '#cf383f'
    }
  },
  rejectFileButtonNotSelected: {
    color: '#CCCCCC',
    '&:hover': {
      color: 'gray'
    }
  },
  rejectFileButtonStatic: {
    color: 'red'
  },
  fileName: {
    textAlign: 'justify'
  }
});

export default withStyles(styles, { withTheme: true })(FileUploader);
