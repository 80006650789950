import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SupplierProposalShow from './SupplierProposalShow';
import {
  fetchProposal
} from '../../../actions/supplier_proposal_actions';

function mapStateToProps(state) {
  return {
    proposal: state.supplierProposal.proposalToShow,
    fetchingProposal: state.requestState.requestSupplierProposalShowSend,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchProposal: bindActionCreators(fetchProposal, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierProposalShow);