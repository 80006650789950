/* DASHBOARD SELLER */
import _pickBy from 'lodash/pickBy';

export const salesmanDataFilters = filters => {
  const {
    pointOfSale,
    supplier,
    year,
    month,
    saleType,
    channelId,
    selectedZones
  } = filters;

  const zoneIds = selectedZones.map(z => z.value);
  const sanitizedFilters = {
    pointOfSaleId: pointOfSale,
    supplierSellerId: supplier,
    year: year,
    month: month,
    saleType: saleType,
    saleChannelId: channelId,
    zoneIds
  };

  return _pickBy(sanitizedFilters, filter => {
    if (typeof filter === 'boolean') {
      return true;
    }
    return !!filter;
  });
};
