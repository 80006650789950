import _map from 'lodash/map';
import _mapKeys from 'lodash/mapKeys';
import _mapValues from 'lodash/mapValues';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';

import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';

export const toCamelCase = (_, key) => {
  return _camelCase(key);
};

export const toSnakeCase = (_, key) => {
  return _snakeCase(key);
};

export function objectToString(object) {
  let str = '';
  str = _map(object, (value, key) => {
    return key + ': ' + (typeof value != 'string' ? value.join('') : value);
  });
  return str;
}

export const mapKeysDeep = (obj, iteratee) => {
  if (_isArray(obj)) {
    return obj.map(innerObj => mapKeysDeep(innerObj, iteratee));
  } else if (_isObject(obj)) {
    return _mapValues(_mapKeys(obj, iteratee), val =>
      mapKeysDeep(val, iteratee)
    );
  } else {
    return obj;
  }
};

export function mapKeysDeepTo(object, convert_type) {
  switch (convert_type) {
    case 'camel':
      return mapKeysDeep(object, toCamelCase);
    case 'snake':
      return mapKeysDeep(object, toSnakeCase);
    default:
      return object;
  }
}
