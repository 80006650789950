export const listSanitizer = params => {
  const sanitizedParams = {
    perPage: params.perPage,
    page: params.page
  };

  return sanitizedParams;
};

export const updateSanitizer = params => {
  const sanitizedParams = {
    name: params.name,
    lowerLimit: params.lowerLimit,
    upperLimit: params.upperLimit,
    quotaAssignmentTimeLimit: params.quotaAssignmentTimeLimit,
    salesToBeRollover: params.salesToBeRollover,
    segment: params.segment
  };
  return sanitizedParams;
};