import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import classnames from 'classnames';

const styles = theme => ({
  selectable: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  gridContainer: {
    '& b': {
      color: 'rgba(12,31,44,0.87)'
    }
  },
  itemValue: {
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase',
    }
  },
});

export const Supplier = ({
  classes,
  supplier,
  isLoading,
  canViewSuppliers,
  toSupplier
}) => {
  const selectableSupplier = canViewSuppliers ? classes.selectable : {};
  return (
    <FormItemContainer title={'Información del Proveedor'}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
          <Grid container spacing={16} className={classes.gridContainer}>
            <Grid item md={6} xs={12}>
              <p className={classnames(classes.subtitle3)}>Nombre:</p>
              <p className={classnames(classes.body2, selectableSupplier)}>
                <a onClick={() => toSupplier(supplier.id, canViewSuppliers)}>
                  {supplier.name.toUpperCase()}
                </a>
              </p>
            </Grid>
            <Grid item md={6} xs={12}>
              <p className={classnames(classes.subtitle3)}>Estado:</p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {supplier.active ? 'ACTIVO' : 'INACTIVO'}
              </p>
            </Grid>
          </Grid>
        )}
    </FormItemContainer>
  );
};

export default withStyles(
  combineStyles(ButtonStyles, TypographyStyles, styles)
)(Supplier);
