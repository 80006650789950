import { LIST_PREVIEW_NOTIFICATIONS } from '../constants/notifications_constants';
import { sendRequest, completeRequest } from './request_state';
import RequestService from '../services/request_service';
import { serverStatusError } from './alert_actions';

const requestServiceNode = new RequestService('node');

function setPreviewNotifications(data) {
  return {
    type: LIST_PREVIEW_NOTIFICATIONS,
    data
  };
}

export const fetchNotifications = () => async dispatch => {
  dispatch(sendRequest('Notifications'));
  try {
    const payload = await requestServiceNode.get('notifications', { page: 1, perPage: 15 });
    dispatch(setPreviewNotifications(payload.data));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('Notifications'));
  }
};

export const notificationMarkAsSeen = (id) => async dispatch => {
  dispatch(sendRequest('Notifications'));
  try {
    await requestServiceNode.put(`notifications/${id}/`, { seen: true });
    dispatch(fetchNotifications());
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('Notifications'));
  }
};
