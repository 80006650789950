import * as Yup from 'yup';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Formik, Field } from 'formik';
import React, { Component, Fragment } from 'react';
import MultipleSelectBox from '../../../shared/Selects/MultiSelect/MultiSelectBox';
import Button from '@material-ui/core/Button';
import { Grid, TextField, withStyles } from '@material-ui/core';
import _isEqual from 'lodash/isEqual';

// shared
import DialogForm from '../../../shared/Modals/DialogForm';
import GridItem from '../../../shared/GridItem';
import ProgressButton from '../../../shared/Buttons/ProgressButton';

// styles
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import SpacingStyles from '../../../../styles/helpers/spacing';

import combineStyles from '../../../../helpers/combineStyles';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required('Nombre es requerido')
});

class SupplierEdit extends Component {
  state = {
    selected: this.props.currentGeographicLocations.map(ps => {
      return ({
        id: ps.id,
        label: ps.display_description
      })
    }),
    secure_sale_selected: this.props.currentSecureSaleGeographicLocations.map(ps => {
      return ({
        id: ps.id,
        label: ps.display_description
      })
    })
  }
  _handleOnSave = async supplier => {
    const { onSave, onClose } = this.props;
    onSave(supplier);
    onClose();
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  _getFormatLocations = locations => {
    const formatLocations = locations.map(lo => {
      return ({
        id: lo.id,
        label: lo.display_description
      })
    })
    return formatLocations
  }

  _isDirty = () => {
    const selected = this._getFormatLocations(this.props.currentGeographicLocations);
    const secure_sale_selected = this._getFormatLocations(this.props.currentSecureSaleGeographicLocations);
    return !_isEqual(selected, this.state.selected) || !_isEqual(secure_sale_selected, this.state.secure_sale_selected)
  }

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      supplier,
      locationNames,
      currentGeographicLocations,
      supplierGeographicLocationsIds,
      canUpdateSupplierGeographicLocations,
      currentSecureSaleGeographicLocations
    } = this.props;
    const locations = this._getFormatLocations(locationNames.filter(location => supplierGeographicLocationsIds.includes(location.id)))
    return (
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={{
          name: supplier ? supplier.name : '',
          description: supplier ? supplier.description : '',
          locations: this._getFormatLocations(currentGeographicLocations),
          secure_sale_locations: this._getFormatLocations(currentSecureSaleGeographicLocations)
        }}
        onSubmit={(values, actions) => {
          this._handleOnSave(values);
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          handleReset,
          dirty,
          setFieldValue
        }) => (
          <DialogForm
            title={
              <p className={classnames(classes.headline6, classes.title)}>
                {'Editar proveedor'}
              </p>
            }
            maxWidth={'sm'}
            fullWidth={true}
            open={open}
            onClose={this._handleOnClose}
            disableBackdropClick={true}
            footer={
              <>
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={(!dirty && !this._isDirty()) || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Guardar'}
                </ProgressButton>
              </>
            }
          >
            <Grid container spacing={16}>
              <GridItem xs={12}>
                <TextField
                  required
                  name="name"
                  label="Nombre"
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.name}
                  fullWidth
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </GridItem>
              <GridItem xs={12}>
                <TextField
                  required
                  name="description"
                  label="Nombre comercial"
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.description}
                  fullWidth
                  error={touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
              </GridItem>
              {canUpdateSupplierGeographicLocations && (
                <Fragment>
                  <p className={classnames(classes.headline6, classes.title, classes.subtitle)}>
                    {'Zona(s) de disponibilidad'}
                  </p>
                  <GridItem xs={12}>
                    <MultipleSelectBox
                      loadingOptions={false}
                      nameSingular="zona"
                      namePlural="zonas"
                      items={locations}
                      selectedItems={this.state.selected}
                      changeItems={(e) => {
                        this.setState({ selected: e });
                        setFieldValue("locations", e)
                      }}
                      selectAllOption
                      height={280}
                    />
                  </GridItem>
                </Fragment>
              )}
              {canUpdateSupplierGeographicLocations && (
                <Fragment>
                  <p className={classnames(classes.headline6, classes.title, classes.subtitle)}>
                    {'Zona(s) de disponibilidad venta afianzada'}
                  </p>
                  <GridItem xs={12}>
                    <MultipleSelectBox
                      loadingOptions={false}
                      nameSingular="zona"
                      namePlural="zonas"
                      items={locations}
                      selectedItems={this.state.secure_sale_selected}
                      changeItems={(e) => {
                        this.setState({ secure_sale_selected: e });
                        setFieldValue("secure_sale_locations", e)
                      }}
                      selectAllOption
                      height={280}
                    />
                  </GridItem>
                </Fragment>
              )}
            </Grid>
          </DialogForm>
        )}
      />
    );
  }
}

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  dialogBase: {
    width: 'calc(100% - 96px)'
  },
  paperRoot: {
    '& .MuiPaper-root': {
      width: '540px'
    }
  },
  subtitle: {
    marginLeft: '15px !important',
    fontSize: '16px !important'
  }
});

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(SupplierEdit);
