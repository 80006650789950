import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StepTitle from './StepTitle';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import Divider from '@material-ui/core/Divider';
import PercentageList from '../../shared/Lists/PercentageList';

const SaleChannelsStep = props => {
  const {
    classes,
    values,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    proposalWasSent,
    handleChangeOnlyDigits,
  } = props;
  return (
    <Grid container item className={classes.saleChannelsStepContainer}>
      <StepTitle />
      <Grid item xs={12} className={classes.saleChannelsStepSubtitle}>
        <Typography variant='body1'>
          Completa los siguientes datos de la empresa para continuar con el proceso
        </Typography>
      </Grid>
      <Grid container item alignItems='center' xs={12}>
        <Grid item xs={8}>
          <Typography variant='body1'>
            Ingresa la cantidad de puntos de venta y/o sedes de la empresa en la zona de cobertura de Gases del Caribe (Atlántico, Magdalena y Cesar)
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <OutlinedTextField
            name='officeQuantity'
            label='Cantidad'
            disabled={proposalWasSent}
            value={values.officeQuantity}
            onBlur={handleBlur}
            onChange={
              event => handleChangeOnlyDigits(event, 'officeQuantity', setFieldValue)
            }
            helperText={touched.officeQuantity && errors.officeQuantity}
            error={touched.officeQuantity && !!errors.officeQuantity}
          />
        </Grid>
        <Grid item xs={12} className={classes.dividerContainer}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.saleChannelsListTitle}>
        <Typography variant='body1'>
          Selecciona los canales de venta y distribución a personas naturales
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PercentageList
          disabled={proposalWasSent}
          list={
            [
              {
                id: 1, label: 'Presencial', initialValues: {
                  percentage: values.faceToFaceSalePercentage
                }
              },
              {
                id: 2, label: 'Televentas', initialValues: {
                  percentage: values.teleshoppingSalePercentage
                }
              },
              { id: 3, label: 'Página web', initialValues: {
                  percentage: values.webPageSalePercentage
                }
              },
              { id: 4, label: 'Redes sociales (Whatsapp, Facebook, Instagram, etc)', initialValues: {
                  percentage: values.socialMediaSalePercentage
                }
              },
              { id: 5, label: 'Puerta a puerta', initialValues: {
                  percentage: values.doorToDoorSalePercentage
                }
              },
              { id: 6, label: 'Catálogo', initialValues: {
                  percentage: values.catalogueSalePercentage
                }
              },
              {
                id: 7, label: 'Otros', initialValues: {
                  percentage: values.otherChannelSalePercentage,
                  text: values.otherChannelName
                },
                writable: true
              }
            ]
          }
          onChange={
            data => {
              setFieldValue('saleChannelPercentageTotal', data.total);
              Object.keys(data.values).forEach(
                key => {
                  const value = data.values[key];
                  const label = value.item.label;
                  const writable = value.item.writable;
                  const fieldName = ({
                    'Presencial': 'faceToFaceSalePercentage',
                    'Televentas': 'teleshoppingSalePercentage',
                    'Página web': 'webPageSalePercentage',
                    'Redes sociales (Whatsapp, Facebook, Instagram, etc)': 'socialMediaSalePercentage',
                    'Puerta a puerta': 'doorToDoorSalePercentage',
                    'Catálogo': 'catalogueSalePercentage',
                    'Otros': 'otherChannelSalePercentage',
                  })[label];
                  const percentage = value.percentage === ''
                    ? null
                    : value.percentage;
                  setFieldValue(fieldName, percentage);
                  if (writable) setFieldValue('otherChannelName', value.text);
                }
              );
            }
          }
        />
      </Grid>
    </Grid>
  );
}
export default SaleChannelsStep;