import { values } from 'lodash';
import _pickBy from 'lodash/pickBy';

const registerSchedule = values => {
  const {
    contractId,
    clientIdentificationTypeId,
    clientIdentification,
    clientName,
    clientLastName,
    categoryId,
    requestObservations,
    receptionTypeId,
    pointOfSaleId,
    additionalComments,
    creatorEmail,
    creatorMobile
  } = values;
  const sanitizedFilters = {
    contractId,
    clientIdentificationTypeId,
    clientIdentification,
    clientName,
    clientLastName,
    categoryId,
    requestObservations,
    receptionTypeId,
    pointOfSaleId,
    additionalComments,
    creatorEmail,
    creatorMobile
  }
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};

export default registerSchedule;
