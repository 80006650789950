import _pickBy from 'lodash/pickBy';
import _get from 'lodash/get';

export const articleFilterSanitizer = filters => {
  const { name, category, brand, status, page, per_page } = filters;
  const sanitizedFilters = {
    name_cont: name,
    category_name_cont: category,
    brand_name_cont: brand,
    page: page,
    per_page
  };
  if (status === 'activos') {
    sanitizedFilters.active_eq = true;
  }

  if (status === 'inactivos') {
    sanitizedFilters.active_eq = false;
  }
  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

export const articleSaveSanitizer = values => {
  const {
    name,
    category_id,
    iva,
    description,
    concept_id,
    active,
    brand_id,
    suppliers,
    sellers,
    grace_period
  } = values;
  const sanitizedFilters = {
    name,
    category_id,
    iva: iva || 0,
    description,
    concept_id,
    active,
    brand_id,
    grace_period,
    suppliers_ids: suppliers.map(s => s.value),
    sellers_ids: sellers.map(s => s.value)
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params,
    iva: params.iva || 0
  };
};

export const fetchTransfersSanitizer = transfers => {
  transfers.length > 0 &&
    transfers[0].quota_transfers.map(o => {
      if (o.contract.id) {
        Object.assign(o.contract, {
          ['externalCode']: o.contract['external_code']
        })['external_code'];
        if (o.contract.client) {
          Object.assign(o.contract.client, {
            ['lastName']: o.contract.client['last_name']
          })['last_name'];
          Object.assign(o.contract.client, {
            ['secondLastName']: o.contract.client['second_last_name']
          })['second_last_name'];
        }
      }
    });
  return transfers;
};

export const addQuotasSanitizer = (values, debtor_cache, type) => {
  const { secondContract, thirdContract } = values;
  const contract_ids = [Number.parseInt(secondContract)];
  thirdContract && contract_ids.push(Number.parseInt(thirdContract));
  const sanitizedFilters = {
    transfer_type: type,
    contract_ids,
    cache_data: debtor_cache,
    transfer_client_data: {
      identification_type: null,
      identification: null,
      birthdate: null
    }
  };
  return sanitizedFilters;
};

export const addQuotasSetSanitizer = (payload, values) => {
  const sanitizedFilters = {
    addQuotasData: {
      requestMade: true,
      secondContractQuota: values.secondContract
        ? payload.find(contract => contract.id == values.secondContract)
            .available_quota
        : '--',
      thirdContractQuota: values.thirdContract
        ? payload.find(contract => contract.id == values.thirdContract)
            .available_quota
        : '--'
    }
  };
  return sanitizedFilters;
};

export const moveQuotaSanitizer = (values, debtor_cache, type) => {
  const { contract, identificationType, identification, birthdate } = values;
  const contract_ids = [contract];
  const sanitizedFilters = {
    transfer_type: type,
    contract_ids,
    cache_data: debtor_cache,
    transfer_client_data: {
      identification_type: identificationType,
      identification,
      birthdate
    }
  };
  return sanitizedFilters;
};

export const moveQuotaSetSanitizer = (payload, values) => {
  const sanitizedFilters = {
    moveQuotaData: {
      requestMade: true,
      clientName: payload.client_name,
      availableQuota: payload.available_quota,
      contract: values.contract,
      identificationType: values.identificationType,
      identification: values.identification,
      birthdate: values.birthdate
    }
  };
  return sanitizedFilters;
};

export const addAvailableQuotas = contracts => {
  const sanitizedFilters = contracts
    .map(contract => Number.parseFloat(contract.available_quota))
    .reduce((prev, next) => prev + next);
  return sanitizedFilters;
};

export const transferErrorsSanitizer = errors => {
  const sanitizedFilters = {
    transferContract: _get(errors, 'contract_1', null),
    secondContract: _get(errors, 'contract_1', null),
    thirdContract: _get(errors, 'contract_2', null),
    identification: _get(errors, 'transfer_client_identification', null),
    birthdate: _get(errors, 'transfer_client_birthdate', null)
  };
  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
}