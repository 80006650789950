import React, { Component, Fragment } from 'react';
import MUIToolbar from '@material-ui/core/Toolbar';

import pluralize from '../../../helpers/pluralize-es/pluralize';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ActivityIndicator from '../ActivityIndicator';

import ColumnChooser from './ColumnChooser';
import { NavLink } from 'react-router-dom';

export class TableToolbar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    totalCount: PropTypes.number.isRequired,
    rowsSelected: PropTypes.array,
    filterToolbar: PropTypes.object,
    withDownload: PropTypes.bool,
    withSelection: PropTypes.bool,
    withColumnChooser: PropTypes.bool,
    noCount: PropTypes.bool,
    batchItemsOptions: PropTypes.array,
    columns: PropTypes.array,
    toggleColumnVisibility: PropTypes.func,
    hiddenColumnNames: PropTypes.array
  };

  state = {
    optionsMenu: null
  };

  _handleOpenOptionsMenu = event => {
    this.setState({ optionsMenu: event.currentTarget });
  };

  _handleCloseOptionsMenu = () => {
    this.setState({ optionsMenu: null });
  };

  render() {
    const {
      classes,
      loading,
      name,
      rowsSelected = [],
      totalCount = 0,
      filterToolbar,
      batchItemsOptions = [],
      withDownload = false,
      downloadUrl = null,
      onDownloadIconClick,
      loadingDownload = false,
      withSelection = true,
      withColumnChooser = true,
      columns = [],
      toggleColumnVisibility,
      noCount,
      hiddenColumnNames = []
    } = this.props;
    return (
      <MUIToolbar
        classes={{
          root: classes.toolbarRoot
        }}
      >
        <h1
          className={classnames(classes.tableName, {
            [classes.rowsSelectedTextColor]: rowsSelected.length > 0
          })}
        >
          {!loading &&
            (rowsSelected.length > 0 ? (
              `${rowsSelected.length} ${pluralize(
                'item',
                rowsSelected.length
              )} ${pluralize('seleccionado', rowsSelected.length)}`
            ) : (
              <div>
                <p className={classes.toolbarTitle}>{noCount? `${pluralize(name, 2)}` :`${totalCount} ${pluralize(
                  name,
                  totalCount
                )}`}</p>
                {filterToolbar}
              </div>
            ))}
        </h1>
        <div className={classes.actions}>
          {withDownload ? (
            loadingDownload ? (
              <IconButton>
                <ActivityIndicator size={24} />
              </IconButton>
            ) : (
              !!onDownloadIconClick ? (
                <IconButton
                  aria-label="Download"
                  component="a"
                  onClick={onDownloadIconClick}
                >
                  <SaveAltIcon />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="Download"
                  component="a"
                  href={downloadUrl}
                  download={true}
                >
                  <SaveAltIcon />
                </IconButton>
              )
            )
          ) : null}
          {batchItemsOptions.length > 0 && (
            <Fragment>
              <IconButton
                aria-label="More"
                onClick={this._handleOpenOptionsMenu}
                title={'Acciones'}
                ref={ref => (this.anchorEl = ref)}
              >
                <MoreVertIcon className={classes.rowsSelectedTextColor} />
              </IconButton>
              <Menu
                anchorEl={this.state.optionsMenu}
                open={Boolean(this.state.optionsMenu)}
                onClose={this._handleCloseOptionsMenu}
              >
                {batchItemsOptions.map(batchItemsOption => (
                  <MenuItem
                    key={batchItemsOption.label}
                    onClick={() => {
                      this._handleCloseOptionsMenu();
                      batchItemsOption.action();
                    }}
                    classes={{
                      root: classes.menuItemRoot,
                      selected: classes.menuItemSelected
                    }}
                  >
                    {batchItemsOption.label}
                  </MenuItem>
                ))}
              </Menu>
            </Fragment>
          )}
          {withColumnChooser && (
            <ColumnChooser
              columns={columns}
              toggleColumnVisibility={toggleColumnVisibility}
              hiddenColumnNames={hiddenColumnNames}
            />
          )}
        </div>
      </MUIToolbar>
    );
  }
}

export default TableToolbar;
