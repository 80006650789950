import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { MONTH, YEAR, WEEK } from '../../../constants/timespan';
import IconButton from '@material-ui/core/IconButton';
import ArrowRight from '@material-ui/icons/ArrowForwardIos';
import ArrowLeft from '@material-ui/icons/ArrowBackIos';
import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';
import LayoutStyles from '../../../styles/layout';
import moment from 'moment';

const styles = theme => ({
  timespanSelected: {
    borderRadius: theme.spacing.unit * 1.75,
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: theme.palette.secondary.main,
  },
  incrementButton: {
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
    paddingLeft: theme.spacing.unit * 1.25,
    paddingRight: theme.spacing.unit * 1.25,
    cursor: 'pointer'
  },
  timespanContainer: {
    height: theme.spacing.unit * 3,
    display: 'flex',
    alignContent: 'center',
    marginBottom: theme.spacing.unit * 3
  },
  arrowButtonContainer: {
    padding: 0
  },
  arrows: {
    width: theme.spacing.unit * 1.5,
    height: theme.spacing.unit * 1.5
  },
  timespanText: {
    padding: `0 ${theme.spacing.unit * 1}px`
  },
  arrowDisabled: {
    color: 'rgba(12,31,44,0.38)'
  },
  labelText: {
    fontWeight: 'bold'
  }
});

export class TimespanSelector extends Component {
  state = { futureButtonEnabled: true };
  static propTypes = {
    increment: PropTypes.oneOf([MONTH, YEAR, WEEK]),
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    onDateChange: PropTypes.func.isRequired,
    onIncrementChange: PropTypes.func.isRequired,
    graphName: PropTypes.string.isRequired
  };

  _setPastDate = () => {
    const { increment, date=moment(), onDateChange, graphName } = this.props;
    const updatedDate = moment(date).subtract(1, `${increment}s`);
    onDateChange(graphName, updatedDate);
  };

  _setFutureDate = futureButtonEnabled => {
    const { increment, date, onDateChange, graphName } = this.props;
    if (!futureButtonEnabled) {
      return;
    }
    const updatedDate = moment(date).add(1, `${increment}s`);
    onDateChange(graphName, updatedDate);
  };

  _futureButtonEnabled = () => {
    const { increment, endDate } = this.props;
    const futureEndDate = moment(endDate)
      .add(1, increment)
      .startOf(increment);

    return futureEndDate.isSameOrBefore(moment());
  };

  _formatTimespanText = () => {
    const { increment, startDate=moment(), endDate } = this.props;
    let timespanText = '';

    switch (increment) {
      case YEAR:
      default:
        timespanText = moment(startDate).format('YYYY');
        break;
      case WEEK:
        timespanText = `${moment(startDate).format('MMM D')} - ${moment(endDate).format(
          'MMM D'
        )}`;
        break;
      case MONTH:
        timespanText = moment(startDate).format('MMMM YYYY');
        break;
    }
    return timespanText;
  };

  render() {
    const { increment, onIncrementChange, classes, graphName } = this.props;
    const timeSpanText = this._formatTimespanText();
    const futureButtonEnabled = this._futureButtonEnabled();

    return (
      <div
        className={classnames(
          classes.row,
          classes.labelText,
          classes.caption,
          classes.timespanContainer
        )}
      >
        <div>
          <span
            className={classnames(classes.incrementButton, {
              [classes.timespanSelected]: increment === WEEK
            })}
            onClick={() => {
              onIncrementChange(graphName, WEEK);
            }}
          >
            {'Semana'}
          </span>
          <span
            className={classnames(classes.incrementButton, {
              [classes.timespanSelected]: increment === MONTH
            })}
            onClick={() => {
              onIncrementChange(graphName, MONTH);
            }}
          >
            {'Mes'}
          </span>
          <span
            className={classnames(classes.incrementButton, {
              [classes.timespanSelected]: increment === YEAR
            })}
            onClick={() => {
              onIncrementChange(graphName, YEAR);
            }}
          >
            {'Año'}
          </span>
        </div>
        <div>
          <IconButton
            onClick={this._setPastDate}
            classes={{ root: classes.arrowButtonContainer }}
          >
            <ArrowLeft classes={{ root: classes.arrows }} />
          </IconButton>
          <span className={classes.timespanText}>{timeSpanText}</span>
          <IconButton
            classes={{ root: classes.arrowButtonContainer }}
            onClick={() => {
              this._setFutureDate(futureButtonEnabled);
            }}
          >
            <ArrowRight
              classes={{
                root: classnames(classes.arrows, {
                  [classes.arrowDisabled]: !futureButtonEnabled
                })
              }}
            />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default withStyles(
  combineStyles(LayoutStyles, TypographyStyles, styles)
)(TimespanSelector);
