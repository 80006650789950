import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, SnackbarContent } from '@material-ui/core';
import classnames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import FlashMessageStyle from './__style__/FlashMessageStyle';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};
const FlashMessage = ({
  classes,
  className,
  message,
  variant,
  actionIcon,
  actionClick,
  withIcon,
  ...other
}) => {
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classnames(classes[variant], classes.base, className)}
      aria-describedby="client-flash-message"
      message={
        <span id="client-flash-message" className={classes.message}>
          {withIcon && (
            <Icon className={classnames(classes.icon, classes.iconVariant)} />
          )}
          {message}
        </span>
      }
      action={
        actionClick && [
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={actionClick}
          >
            {actionIcon ? actionIcon : <CloseIcon className={classes.icon} />}
          </IconButton>
        ]
      }
      {...other}
    />
  );
};

export default withStyles(FlashMessageStyle, { withTheme: true })(FlashMessage);
