import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Paper, Divider } from '@material-ui/core';
import ListItem from '../../shared/ListItem/AnnouncementListItem';

import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';
import ButtonStyles from '../../../styles/button';
import CustomDialog from '../../shared/Modals/CustomDialog';
import formatDate from '../../../helpers/formatDate';

const styles = () => ({
  paperRoot: {
    top: 64,
    height: 'calc(100% - 64px)'
  },
  paper: {
    width: 420,
    boxShadow: 'none'
  },
  title: {
    padding: 16,
    paddingLeft: 24,
    flex: '0 !important'
  },
  subtitle: {
    color: 'rgba(12,31,44,0.6)',
    justifyContent: 'left',
    alignItems: 'left',
    fontSize: 12,
    margin: 0,
    padding: 24,
    paddingTop: 20,
    paddingBottom: 0
  },
  announcementListItem: {
    margin: 0
  },
  announcementListItemDate: {
    fontSize: 10,
    marginTop: 8
  }
});

export class NewsDrawer extends Component {
  state = {
    announcementShouldOpen: true,
    announcementId: null
  };

  _handleClose = () => {
    if (this.state.announcementShouldOpen) {
      this.setState({ announcementShouldOpen: false });
      setTimeout(() => {
        this.setState({ announcementId: null });
      }, 200);
    }
  };

  _handleOpenAnnouncement = id => {
    this.setState({ announcementShouldOpen: true, announcementId: id });
    if (!this.props.list[id].seen) {
      this.props.markAsSeen(this.props.list[id].announcement.id, true);
    }
  };

  _handleMarkAsSeen = (id, seen) => e => {
    e.stopPropagation();
    this.props.markAsSeen(id, seen);
  };

  render() {
    const { classes, open = false, onClose, list } = this.props;
    return (
      <Fragment>
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={open}
          onClose={onClose}
          classes={{
            paper: classes.paperRoot
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <h1 className={classnames(classes.headline7, classes.title)}>
            {'Anuncios'}
          </h1>
          <Divider />
          <h2 className={classnames(classes.subtitle)}>{'MÁS RECIENTES'}</h2>
          <Paper
            classes={{
              root: classes.paper
            }}
          >
            {list.map((item, index) => {
              return (
                <Fragment key={item.announcement.id}>
                  <ListItem
                    heading={item.announcement.title}
                    seen={item.seen}
                    onClick={() => this._handleOpenAnnouncement(index)}
                    onMarkAsSeenHandler={this._handleMarkAsSeen(
                      item.announcement.id,
                      !item.seen
                    )}
                  >
                    <div>
                      <p className={classes.announcementListItem}>
                        {item.announcement.description}
                      </p>
                      <p
                        className={classnames(
                          classes.announcementListItem,
                          classes.announcementListItemDate
                        )}
                      >
                        {formatDate(new Date(item.announcement.initialDate)) +
                          ' - ' +
                          formatDate(new Date(item.announcement.finalDate))}
                      </p>
                    </div>
                  </ListItem>
                  <Divider />
                </Fragment>
              );
            })}
          </Paper>
        </Drawer>
        <CustomDialog
          open={
            this.state.announcementShouldOpen &&
            this.state.announcementId !== null
          }
          className={this.props.classes.sliderPopUp}
          loading={false}
          disabled={false}
          onClose={this._handleClose}
          onConfirm={this._handleClose}
          customWidth={true}
          title={`Anuncios`}
        >
          {this.state.announcementId != null ? (
            <div
              dangerouslySetInnerHTML={{
                __html: list[this.state.announcementId].announcement.content
              }}
            />
          ) : null}
        </CustomDialog>
      </Fragment>
    );
  }
}

export default withStyles(
  combineStyles(styles, ButtonStyles, TypographyStyles)
)(NewsDrawer);
