const styles = theme => ({
  root: {
    // backgroundColor: 'rgb(242,161,56)',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '2px',
    padding: theme.spacing.unit * 3,
    position: 'relative',
    marginBottom: theme.spacing.unit * 3,
    width: '100%'
  },
  titleContainer: {
    marginRight: `-${theme.spacing.unit * 1.5}px`,
    marginTop: [0, '!important'],
    marginBottom: [theme.spacing.unit * 3, '!important']
  },
  mainTitle: {
    // color: '#FFFFFF !important'
    color: 'red',
    width: '100%'
  },
  selectedRate: {
    // backgroundColor: 'rgba(235, 238, 241, 0.8)',
    borderRadius: '2px',
    display: 'flex',
    padding: `${theme.spacing.unit}px 0px`,
    paddingTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  grayContainer: {
    backgroundColor: '#EFF1F4',
    padding: 20,
    marginBottom: theme.spacing.unit * 2
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(224, 224, 224)'
  },
  date_item: {
    paddingLeft: 10
  },
  lowestCostMessage: {
    // color: '#FFFFFF !important',
    color: 'red',
    fontWeight: '500 !important',
    textTransform: 'uppercase'
  },
  carrierName: {
    // color: '#FFFFFF !important',
    color: 'red',
    fontSize: 18,
    marginBottom: theme.spacing.unit * 3
  }, 
  deliveryInfo: {
    // color: '#FFFFFF !important'
    color: 'red'
  },
  detailText: {
    color: 'rgba(12,31,44,0.87)',
    margin: 0,
    marginBottom: 5,
    fontSize: 17,
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0.15
  },
  estimatedDeliveryInfo: {
    marginTop: `${theme.spacing.unit * 0.5}px !important`
  },
  lowestCostMessageContainer: {
    paddingTop: theme.spacing.unit * 0.25,
    marginBottom: 0,
    '@media (max-width:1440px)': {
      width: '50%',
      textAlign: 'center',
      marginBottom: theme.spacing.unit * 4
    },
    '@media (max-width:1278px)': {
      width: 'auto',
      textAlign: 'left',
      marginBottom: 0
    },
    '@media (max-width:1180px)': {
      width: '50%',
      textAlign: 'center',
      marginBottom: theme.spacing.unit * 4
    },
    '@media (max-width:940px)': {
      width: '100%',
      marginBottom: theme.spacing.unit * 5
    }
  },
  carrierInfoContainer: {
    marginBottom: 0,
    width: '50%',

    '@media (max-width:940px)': {
      width: '100%'
    }
  },
  priceDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    width: '50%',

    '@media (max-width:940px)': {
      width: '100%',
    }
  },
  selectRateContainer: {
    '@media (min-width:1441px)': {
      minWidth: 162
    },
    '@media (max-width:1440px)': {
      width: '50%',
      textAlign: 'center'
    },
    '@media (max-width:1278px)': {
      width: 'auto',
      textAlign: 'left'
    },
    '@media (max-width:1180px)': {
      width: '50%',
      textAlign: 'center'
    },
    '@media (max-width:940px)': {
      width: '100%'
    }
  },
  priceDetails: {
    // color: '#FFFFFF !important',
    color: 'red',
    textAlign: 'center'
  },
  price: {
    // color: '#FFFFFF !important',
    color: 'red',
    marginBottom: `${theme.spacing.unit * 2}px !important`,
    textAlign: 'center'
  },
  rateActionLink: {
    // color: '#FFFFFF !important',
    color: 'red',
    paddingLeft: `${theme.spacing.unit * 1.5}px !important`,
    paddingRight: `${theme.spacing.unit * 1.5}px !important`,
    textTransform: 'capitalize',
    position: 'absolute',
    right: 0
  },
  customRateLinkAction: {
    right: '112px'
  },
  rateActionLinkLabel: {
    // color: '#FFFFFF !important'
    color: 'red'
  },
  selectRateButton: {
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2) !important',
    padding: '0 !important',
    width: 162
  },
  getRatesButton: {
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2) !important',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    width: '100%'
  },
  getRatesButtonLabel: {
    fontWeight: 700
  },
  refreshRatesLoading: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.primary.darker,
    top: 0,
    left: 0,
    zIndex: 2,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  refreshRatesLoadingCircularProgress: {
    height: '80px !important',
    width: '80px !important',
    marginBottom: theme.spacing.unit * 6
  },
  refreshRatesLoadingMessage: {
    // color: '#FFFFFF !important'
    color: 'red'
  },
  refreshRatesLoadingMessageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default styles;
