const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    width: '100%',
    marginBottom: theme.spacing.unit * 3
  },
  container: {},
  title: {
    justifyContent: 'flex-start',
    width: '100%'
  },
  titleContainer: {
    display: 'flex',
    padding: theme.spacing.unit * 3,
    alignItems: 'center',
    position: 'relative'
  },
  secondaryAction: {
    justifyContent: 'flex-end',
    alignContent: 'center',
    position: 'absolute',
    right: theme.spacing.unit * 2
  },
  contents: {
    padding: theme.spacing.unit * 3,
    position: 'relative',
    minHeight: 68
  },
  customStyle: {
    backgroundColor: '#FFFFFF99',
    borderRadius: '2px',
    position: 'absolute',
    right: 0,
    width: '100%'
  }
});

export default styles;
