import { SET_HOME_VISIT_LIST, UPDATED_HOME_VISIT, SET_HOME_VISIT_ARTICLES_LIST  } from '../constants/action_constants';
import { newAlert, serverStatusError } from './alert_actions';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import MIMETYPES from '../constants/mimetypes';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import homeVisitSanitizer from '../helpers/sanitizers/homeVisitFilters';
import AxiosRequest from '../services/axios_request';
import RequestService from '../services/request_service';
import _get from 'lodash/get';


const requestService = new RequestService('node');
const axiosRequest = new AxiosRequest();

//* PURE ACTION FUNCTIONS
function setList(items, pagina) {
  return {
    type: SET_HOME_VISIT_LIST,
    data: items,
    meta: pagina
  };
}

function setArticlesList(data) {
  return {
    type: SET_HOME_VISIT_ARTICLES_LIST,
    data
  }
}

function updatedHomeVisit() {
  return {
    type: UPDATED_HOME_VISIT,
  };
}

// * LIST HOME VISITS *
export const fetchHomeVisits = (params = {}) => async dispatch => {
  dispatch(sendRequest('HomeVisits'));
  history.replace(`/home-visits?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get(
      'schedules',
      homeVisitSanitizer(params)
    );
    dispatch(setList(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('HomeVisits'));
  }
};

// * LIST HOME VISIT WITH ARTICLES *
export const getHomeVisitArticles = id => async dispatch => {
  dispatch(sendRequest('HomeVisitArticles'));
  try {
    const payload = await requestService.get(
      'schedules/articles/'+id
    );
    dispatch(setArticlesList(payload.data.schedule.pointOfSale.supplier));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('HomeVisitArticles'));
  }
};

// * HOME VISIT REVISION *
export const homeVisitRevision = (body = {}, queryParams) => async dispatch => {
  try {
    dispatch(sendRequest('HomeVisitRevision'));
    await requestService.put(
      `schedules/legalize/${body.id}`,
      body
    );
    dispatch(newAlert('success', 'success', 'Legalización realizada exitosamente.'));
    dispatch(updatedHomeVisit());
    dispatch(fetchHomeVisits(queryParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('HomeVisitRevision', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('HomeVisitRevision'));
  }
};

// * FETCH INDEX EXCEL REPORT FILE *
export const fetchIndexExcelReportFile = (params = {}) => async dispatch => {
  dispatch(sendRequest('HomeVisitsIndexExcelReportFile'));
  try {
    const payload = await requestService.get(`schedules/exportSchedules`, homeVisitSanitizer(params));
    const file = new Blob([new Uint8Array(payload.data).buffer], { type: MIMETYPES['xlsx'] });
    const fileUrl = URL.createObjectURL(file);
    window.location.href = fileUrl;
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('HomeVisitsIndexExcelReportFile'));
  }
};