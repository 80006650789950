import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Description, MonetizationOn, Money, Shop } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import IconItem from './IconItem';
import formatMoney from '../../helpers/formatMoney';

class PortalContractDetails extends PureComponent {
  render() {
    const {
      classes,
      contract,
      assignedCredit,
      usedCredit,
      availableCredit,
      requestSend,
      useMegaQuota,
      ...props
    } = this.props;
    return (
      <Paper {...props} className={classes.container} square>
        <Grid container spacing={24}>
          <Grid item className={classes.item} md={3} xs={6}>
            <IconItem
              title="Numero de contrato:"
              icon={<Description />}
              label={String(contract)}
              style={{ flex: '25%' }}
            />
          </Grid>
          <Grid item className={classes.item} md={3} xs={6}>
            <IconItem
              title="Cupo asignado:"
              icon={<Money />}
              label={assignedCredit >= 0 ? formatMoney(assignedCredit) : '--'}
              style={{ flex: '25%' }}
            />
          </Grid>
          <Grid item className={classes.item} md={3} xs={6}>
            <IconItem
              title="Cupo utilizado:"
              icon={<Shop />}
              label={usedCredit >= 0 ? formatMoney(usedCredit) : '--'}
              style={{ flex: '25%' }}
            />
          </Grid>
          <Grid item className={classes.item} md={3} xs={6}>
            <IconItem
              title="Cupo disponible:"
              icon={<MonetizationOn />}
              loading={requestSend}
              label={availableCredit >= 0 ? formatMoney(availableCredit) : '--'}
              style={{ flex: '25%' }}
              isMegaQuota={useMegaQuota}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

PortalContractDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

/* FIXME: replace with actual values */
function mapStateToProps(state) {
  return {
    requestSend: state.requestState.requestChangeAvailableQuotaSend,
    contract: state.portalFinancing.debtor_contract_number,
    assignedCredit: state.portalFinancing.assigned_quota,
    usedCredit: state.portalFinancing.used_quota,
    availableCredit: state.portalFinancing.available_quota,
    useMegaQuota: state.portalFinancing.useMegaQuota,
  };
}

function mapDisptchToProps(/* dispatch */) {
  return {};
}

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 3
  },
  item: {
    padding: '6px !important'
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDisptchToProps
  ),
  withStyles(styles)
)(PortalContractDetails);
