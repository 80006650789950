import {
    LIST_CAMPAIGN,
    SAVED_CAMPAIGN,
    CAMPAIGN_VALIDATION_RESPONSE,
    RESET_QUERY_CAMPAIGN
  } from '../constants/campaign_constants';
  import { sendRequest, completeRequest, errorOnRequest } from './request_state';
  import RequestService from '../services/request_service';
  import { stringifyQuery } from '../helpers/parseQuerystring';
  import { history } from '../helpers/history';
  import { newAlert, serverStatusError } from './alert_actions';
  import { campaignFilterSanitizer, campaignSaveSanitizer } from '../helpers/sanitizers/campaignSanitizer';
  
  const requestService = new RequestService('node');
  
  function setCampaign(data, meta) {
    return {
      type: LIST_CAMPAIGN,
      data,
      meta
    };
  }

  function setCampaignValidationResponse(data) {
    return {
      type: CAMPAIGN_VALIDATION_RESPONSE,
      data
    };
  }
  
  function savedCampaign() {
    return {
      type: SAVED_CAMPAIGN
    };
  }

  export const resetQueryCampaign = () => {
    return {
      type: RESET_QUERY_CAMPAIGN
    };
  }
  
  export const fetchCampaign = (params = {}) => async dispatch => {
    dispatch(sendRequest('CampaignList'));
    history.push(`/campaigns?${stringifyQuery(params)}`);
    try {
      const payload = await requestService.get(
        'campaigns',
        campaignFilterSanitizer(params),
        true
      );
      dispatch(setCampaign(payload.data, payload.meta));
    } catch (errors) {
      dispatch(serverStatusError(errors));
    } finally {
      dispatch(completeRequest('CampaignList'));
    }
  };
  
  export const createCampaign = (
    params = {},
    fetchParams = {}
  ) => async dispatch => {
    dispatch(sendRequest('SaveCampaign'));
    try {
      const payload = await requestService.post('campaigns', campaignSaveSanitizer(params.campaign), {}, true);
      dispatch(savedCampaign());
      dispatch(fetchCampaign(fetchParams));
      dispatch(newAlert('success', 's', payload.data.message));
    } catch (errors) {
      const e = await errors.json();
      if (e.errors) {
        if ('base' in e.errors) {
          dispatch(newAlert('error', 'ERROR:', e.errors['base']));
        } else {
          dispatch(errorOnRequest('SaveCampaign', e.errors));
        }
      } else {
        dispatch(serverStatusError(errors));
      }
    } finally {
      dispatch(completeRequest('SaveCampaign'));
    }
  };
  
  export const updateCampaign = (
    params = {},
    fetchParams = {}
  ) => async dispatch => {
    dispatch(sendRequest('SaveCampaign'));
    try {
      const payload = await requestService.put(
        `campaigns/${params.campaign.id}`,
        campaignSaveSanitizer(params.campaign),
        true
      );
      dispatch(savedCampaign());
      dispatch(fetchCampaign(fetchParams));
      dispatch(newAlert('success', 's', payload.data.message));
    } catch (errors) {
      const e = await errors.json();
      if (e.errors) {
        if ('base' in e.errors) {
          dispatch(newAlert('error', 'ERROR:', e.errors['base']));
        } else {
          dispatch(errorOnRequest('SaveCampaign', e.errors));
        }
      } else {
        dispatch(serverStatusError(errors));
      }
    } finally {
      dispatch(completeRequest('SaveCampaign'));
    }
  };

  export const deleteCampaign = (
    params = {},
    fetchParams = {}
  ) => (
    async dispatch => {
      dispatch(sendRequest('DeleteCampaign'));
      try {
        const payload = await requestService.delete(
          `campaigns/${params.id}`
        );
        dispatch(savedCampaign());
        dispatch(fetchCampaign(fetchParams));
        dispatch(newAlert('success', 's', payload.data.message));
      } catch (errors) {
        const e = await errors.json();
        if (e.errors) {
          if ('base' in e.errors) {
            dispatch(newAlert('error', 'ERROR:', e.errors['base']));
          } else {
            dispatch(errorOnRequest('DeleteCampaign', e.errors));
          }
        } else {
          dispatch(serverStatusError(errors));
        }
      } finally {
        dispatch(completeRequest('DeleteCampaign'));
      }
    }
  );

  export const validateCampaign = (params = {}) => async dispatch => {
    dispatch(sendRequest('CampaignValidation'));
    try {
      const payload = await requestService.post(
        'campaigns/validateCampaign',
        params,
        {},
        true
      );
      dispatch(setCampaignValidationResponse(payload.data));
    } catch (errors) {
      dispatch(serverStatusError(errors));
    } finally {
      dispatch(completeRequest('CampaignValidation'));
    }
  };
  