import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  text: {
    subtitle1: {
      color: 'rgba(12,31,44,0.87)',
      fontFamily: 'Roboto',
      fontSize: '16px'
    }
  },
  buttons: {
    textButton: {
      height: 36,
      border: 0,
      padding: '0 20px',
      backgroundColor: 'transparent',
      fontSize: '14px',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      lineHeight: '16px',
      fontFamily: 'Roboto',
      outline: 'none',
      cursor: 'pointer',
      textTransform: 'uppercase'
    },
    deleteTextButton: {
      color: '#F22D12',
      '&:hover': {
        backgroundColor: 'rgba(233, 46, 21, 0.06)'
      }
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    font: {
      main: 'Roboto',
      second: 'Rubik'
    },
    color: {
      main: 'rgba(12,31,44,0.87)',
      second: 'rgba(12,31,44,0.6)',
      light: 'rgba(12,31,44,0.38)'
    }
  },
  palette: {
    primary: {
      lightest: '#F6D1D0',
      light: '#E57F7C',
      main: '#DB4945',
      dark: '#AF3A37',
      darker: '#993330',
      darkest: '#571D1B',
      contrastText: '#fff'
    },
    secondary: {
      lightest: '#fdf5eb',
      light: '#F7C380',
      main: '#F2A138',
      dark: '#F2A138',
      mainDarker: '#F2A138',
      mainLighter: '#F2A138',
      contrastText: '#fff'
    },
    error: {
      light: 'rgba(233, 46, 21, 0.06)',
      main: '#F22D12'
    },
    warning: {
      light: '#fff8e1',
      main: '#ffa000'
    },
    success: {
      light: 'rgba(0,195,150,0.1)',
      main: '#00C396'
    },
    background: {
      paper: '#fff',
      default: '#eceef1'
    },
    disabled: {
      main: 'rgba(12,31,44,0.24)'
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'rgba(0, 0, 0, 0.87)'
        }
      },
      focused: {}
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.87)',
          borderWidth: 1
        }
      },
      focused: {},
      notchedOutline: {}
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#DB4945'
      }
    },
    MuiPickersDay: {
      day: {
        '&$selected': {
          backgroundColor: '#F2A138',
          '&$hover': {
            backgroundColor: '#F2A138'
          }
        },
        current: {
          color: '#F2A138'
        }
      }
    },
    MuiPickersYear: {
      root: {
        '&$selected': {
          color: '#F2A138',
          '&:hover': {
            color: '#F2A138'
          }
        },
        '&:focus': {
          color: '#F2A138'
        }
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#DB4945'
      }
    }
  },
  breakpoints: {
    keys: ['xs', 'm', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      m: 479,
      sm: 899,
      md: 1023,
      lg: 1281,
      xl: 1479
    }
  }
});
