import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  names: Yup.string().required('El campo es requerido'),
  lastNames: Yup.string().required('El campo es requerido'),
  identificationType: Yup.string().required('El campo es requerido'),
  identification: Yup.number()
    .required('El campo es requerido')
    .min(1, 'Ingresa una identificación válida.'),
  email: Yup.string()
    .required('El campo es requerido')
    .email('Debe ingresar un email válido.'),
  mobile: Yup.string().required('El campo es requerido'),
  termsAndConditionsAccepted: Yup.boolean().oneOf(
    [true],
    'El campo es requerido'
  ),
  isHuman: Yup.boolean().oneOf([true], 'El campo es requerido')
});
