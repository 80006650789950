import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MultipleSelectBox from '../../../shared/Selects/MultiSelect/MultiSelectBox';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { _parsePOSname } from '../../../../helpers/utilityFunctions';
import Modal from '../../../shared/Modals/Modal';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../../shared/Buttons/ProgressButton';

import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';

import _isEqual from 'lodash/isEqual';

class PointOfSaleEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedPOS: [],
      selectedPOS: [],
      selectedFreelance: [],
      previousSelectedPOS: [],
      loadingNames: true,
      isAdmin: false
    };
  }

  componentDidMount() {
    const role = this.props.canShowSelectUsers
    this.setState({ isAdmin: this.props.canShowSelectUsers });
    this._mapActualPOS(this.props.selectedPointsOfSales, role);
    this._mapPOS(this.props.pointsOfSales, this.props.selectedPointsOfSales, role);
  }

  _savePOS = () => {
    const newPOS = [];
    this.state.selectedPOS.concat(this.state.selectedFreelance).map(r => newPOS.push(r.id));
    this.props.onSave(newPOS);
    this.props.onClose();
  };

  _mapActualPOS = (actualPOS, role) => {
    const initialSelected = [];
    const freelance = [];
    const isCoordinator = this.props.currentUser.roles.some(role => this.props.coordinatorRoles.includes(role.name))
    if (actualPOS) {
      actualPOS.map(r => {
        if (isCoordinator) {
          if (this.props.currentUser.pointOfSales.some(pt => pt.id == r.id)) {
            initialSelected.push({
              id: Number.parseInt(r.id),
              label: _parsePOSname(r.name).concat(r.active ? `` : ` - INACTIVO`),
              group: r.supplier.name
            });
          } else {
            freelance.push({
              id: Number.parseInt(r.id),
              label: _parsePOSname(r.name).concat(r.active ? `` : ` - INACTIVO`),
              group: r.supplier.name
            });
          }
        } else {
          initialSelected.push({
            id: Number.parseInt(r.id),
            label: _parsePOSname(r.name).concat(r.active ? `` : ` - INACTIVO`),
            group: r.supplier.name
          });
        }
      });
    }
    if (role) {
      this.setState({
        selectedPOS: initialSelected, previousSelectedPOS: [...initialSelected],
        selectedFreelance: freelance
      });
    } else {
      this.setState({ selectedPOS: [], previousSelectedPOS: [...initialSelected] });
    }
  };

  _verification = (selected, id) => {
    let is_selected = true
    selected.map(r => {
      if (r.id == id) {
        is_selected = false
      }
    });
    return is_selected
  };

  _mapPOS = (pointsOfSaleNames, selected, role) => {
    const mapped = [];
    const { userSupplier } = this.props;
    pointsOfSaleNames.map(r => {
      const id_number = Number.parseInt(r.id)
      if (this._verification(selected, id_number) || role) {
        mapped.push({
          id: id_number,
          label: _parsePOSname(r.attributes.name).concat(r.attributes.active ? `` : ` - INACTIVO`),
          group: r.attributes.supplier.name
        });

      }
    });

    this.setState({
      mappedPOS: mapped,
      loadingNames: false
    });
  };

  changeItems = (selectedItems) => {
    this.setState({ selectedPOS: selectedItems });
  }

  _isDirty = () => {
    return !_isEqual(this.state.previousSelectedPOS, this.state.selectedPOS)
  }

  render() {
    const { mappedPOS, selectedPOS, loadingNames } = this.state;
    const { classes, loading, isSubmitting, onClose, onSave } = this.props;
    return (
      <Modal
        open={true}
        onClose={onClose}
        classes={{
          footer: classes.modalFooter,
          root: classes.modalRoot
        }}
        body={
          <Fragment>
            <div className={classes.titleWrapper}>
              <p className={classnames(classes.headline6, classes.title)}>
                {
                  this.state.isAdmin ?
                    'Editar puntos de ventas'
                    : 'Solicitar acceso a puntos de venta'}
              </p>
            </div>
            <MultipleSelectBox
              loadingOptions={loadingNames}
              group
              nameSingular="punto de venta"
              namePlural="puntos de venta"
              items={mappedPOS}
              selectedItems={selectedPOS}
              changeItems={this.changeItems}
              selectAllOption
              selectAllOptionByGroup
            />
          </Fragment>
        }
        footer={
          <Fragment>
            <div className={classes.spacer} />
            <Button
              className={classes.button}
              onClick={onClose}
              color={'secondary'}
              classes={{ label: classes.buttonLabel }}
            >
              {'Cancelar'}
            </Button>
            <ProgressButton
              onClick={this._savePOS}
              className={classes.button}
              classes={{
                disabled: classes.buttonDisabled,
                label: classes.buttonLabel
              }}
              color={'secondary'}
              disabled={!this._isDirty()}
              isSubmitting={isSubmitting}
            >
              {'Guardar'}
            </ProgressButton>
          </Fragment>
        }
      />
    );
  }
}
const styles = theme => ({
  container: {},
  cardContainer: {
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  modalRoot: {
    width: 900
  }
});

PointOfSaleEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  actualPOS: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool
};

export default withStyles(combineStyles(
  styles,
  LayoutStyles,
  TypographyStyles,
  ButtonStyles,
  SpacingStyles,
  styles
), { withTheme: true })(PointOfSaleEditor);
