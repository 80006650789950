import QuotaConfigurationList from './QuotaConfigurationList';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  fetchAssignedQuotasList,
  openAssignedQuotasFormModal,
  closeAssignedQuotasFormModal,
  updateAssignedQuotas,
  createAssignedQuota,
  deleteAssignedQuota
} from '../../../actions/assigned_quotas_actions';

function mapStateToProps(state) {
  return {
    configs: state.assignedQuotas.configs,
    meta: state.assignedQuotas.meta,
    loading: state.requestState.requestAssignedQuotaListSend,
    loadingData: state.requestState.requestOrgDataSend,
    canUpdateAssignedQuotas: state.authentication.currentUser.canUpdateAssignedQuotas,
    canCreateAssignedQuotas: state.authentication.currentUser.canCreateAssignedQuotas,
    canDeleteAssignedQuotas: state.authentication.currentUser.canDeleteAssignedQuotas,
    contractStrata: state.contractStratum.list,
    geographicLocations: state.globalSetting.orgData.geographic_locations,
    modalSubmitLoading: (
      state.requestState.requestAssignedQuotaUpdateSend
    ),
    modalSecondSubmitLoading: state.requestState.requestDeleteAssignedQuotaSend,
    formModalIsOpen: state.assignedQuotas.formModalIsOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAssignedQuotasList: bindActionCreators(fetchAssignedQuotasList, dispatch),
    openFormModal: bindActionCreators(openAssignedQuotasFormModal, dispatch),
    closeFormModal: bindActionCreators(closeAssignedQuotasFormModal, dispatch),
    updateConfig: bindActionCreators(updateAssignedQuotas, dispatch),
    createAssignedQuota: bindActionCreators(createAssignedQuota, dispatch),
    deleteAssignedQuota: bindActionCreators(deleteAssignedQuota, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QuotaConfigurationList);