import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import StepperButtons from '../../shared/StepperButtons';
import SegmentHeader from '../../shared/SegmentHeader';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import InfoField from '../../shared/InfoField';
import formatMoney from '../../../helpers/formatMoney';
import TypographyStyles from '../../../styles/typography';
import combineStyles from '../../../helpers/combineStyles';
import classnames from 'classnames';
import { Formik } from 'formik';
import { history } from '../../../helpers/history';
import { typeOptions } from '../Insurance/options';

class InsuranceSummary extends Component {
  state = {
    contract: this.props.contract,
    invoice: this.props.invoice,
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    typeOfIdentification: this.props.typeOfIdentification,
    identification: this.props.identification,
    email: this.props.email,
    mobile: this.props.mobile,
    type: this.props.type,
    value: this.props.value,
    birthDate: this.props.birthDate,
    expeditionDate: this.props.expeditionDate,
    userId: this.props.userId,
    affiliates: this.props.affiliates,
    user: `${this.props.currentUser.firstName} ${
      this.props.currentUser.lastName
    }`
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  previousStep = this.currentStep - 1;
  initialStep = 0;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
  }

  onSubmit = values => {
    const { process, routes } = this.props;
    this.props.createInsurance(values, process);
  };

  render() {
    const { classes, routes, affiliates } = this.props;
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            ref={ref => (this.formik = ref)}
            enableReinitialize={true}
            initialValues={this.state}
            onSubmit={this.onSubmit}
            // eslint-disable-next-line complexity
            render={({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange
            }) => (
              <form autoComplete="off" className={classes.form}>
                <SegmentHeader>DATOS BASICOS</SegmentHeader>
                <Grid container className={classes.infoFieldGrid}>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Número de contrato:">
                      {this.state.contract}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Nombre:">
                      {`${this.state.firstName} ${this.state.lastName}`}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Tipo de identificación:">
                      {this.state.typeOfIdentification}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Documento de identificación:">
                      {this.state.identification}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Correo electrónico:">
                      {this.state.email}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Número celular:">
                      {this.state.mobile}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Tipo de servicio:">
                      {typeOptions[this.state.type]}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Valor de servicio:">
                      {formatMoney(this.state.value)}
                    </InfoField>
                  </Grid>
                </Grid>
                {affiliates.length > 0 && (
                  <>
                    <SegmentHeader>DATOS AFILIADOS</SegmentHeader>
                    {affiliates.map(item => (
                      <Grid
                        key={item.key}
                        container
                        className={classes.infoFieldGrid}
                      >
                        <Grid item md={4} xs={12}>
                          <InfoField label="Nombre">{item.name}</InfoField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <InfoField label="Parentesco">
                            {item.relationship}
                          </InfoField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <InfoField label="Edad">{item.age}</InfoField>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
                <div className={classes.actionBar}>
                  <StepperButtons
                    onSubmit={handleSubmit}
                    process={'insurance'}
                    goBack={() => history.go(-1)}
                  />
                </div>
              </form>
            )}
          />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {},
  infoFieldGrid: {
    alignItems: 'flex-end',
    marginBottom: 5 * theme.spacing.unit
  },
  form: {
    paddingTop: 50
  }
});

export default withStyles(combineStyles(TypographyStyles, styles))(
  InsuranceSummary
);
