import * as Yup from 'yup';

export const validationSchema = companyName => Yup.object().shape({
  initialDate: Yup.string()
    .when('commission_type_code', {
      is: 'sale',
      then: Yup.string()
        .nullable()
        .required('Fecha inicial es requerida')
    })
    .when('commission_type_code', {
      is: 'advertisement',
      then: Yup.string().nullable()
    }),
  recoveryPercentage: Yup.number()
    .min(0.0, 'El valor mínimo de la comisión de cobro es 0.0')
    .max(100.0, 'El valor máximo de la comisión de cobro es 100.0')
    .nullable()
    .required('Comisión de cobro es requerida'),
  commission_type_name: Yup.string(),
  supplier_name: Yup.string().when(
    ['commission_type_code','article_name', 'category_name'],
    (commission_type_code, article_name, category_name, schema) => {
      if (commission_type_code == 'sale') {
        if ((category_name != '' && article_name) || companyName === 'GDG') {
          return schema.required('Proveedor es requerido');
        }
      } else {
        return schema.required('Proveedor es requerido');
      }
    }
  ),
  article_name: companyName === 'GDG' && Yup.string().required('Artículo es requerido'),
  sale_channel_name: Yup.string().nullable(),
  category_name: Yup.string()
  .when('commission_type_code', {
    is: 'sale',
    then: Yup.string()
      .nullable()
      .required('Categoría es requerida')
  })
  .when('commission_type_code', {
    is: 'advertisement',
    then: Yup.string().nullable()
  })
});

export default validationSchema;
