import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Primer nombre es requerido'),
  lastName: Yup.string().required('Primer apellido es requerido'),
  identification: Yup.number()
    .required('Número de identificación es requerido')
    .test(
      'len',
      'Debe tener menos de 16 dígitos',
      val => !!val && val.toString().length <= 15
    ),
  typeOfIdentification: Yup.string().required(
    'Tipo de identificación es requerido'
  ),
  birthDate: Yup.string()
    .required('Fecha de nacimiento es requerido')
    .typeError('Digite una fecha válida'),
  expeditionDate: Yup.string()
    .required('Fecha de expedición es requerido')
    .typeError('Digite una fecha válida'),
  email: Yup.string()
    .required('Correo electrónico es requerido')
    .email('Debe ser un correo electrónico válido'),
  mobile: Yup.number()
    .nullable()
    .required('Número de celular es requerido')
    .test(
      'len',
      'Debe tener 10 dígitos',
      val => !!val && val.toString().length === 10
    ),
  affiliates: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .nullable()
          .required('Requerido'),
        relationship: Yup.string()
          .nullable()
          .required('Requerido'),
        age: Yup.number()
          .nullable()
          .typeError('Requerido')
          .required('Requerido')
          .test(
            'len',
            'Debe tener 3 dígitos o menos',
            val => !!val && val.toString().length <= 3
          )
      })
    )
});
