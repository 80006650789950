import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  Grid,
  withStyles,
  withTheme
} from '@material-ui/core';
import ActivityIndicator from '../../shared/ActivityIndicator';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import combineStyles from '../../../helpers/combineStyles';

class InputFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileData: '',
      loading: false
    };
  }

  handleUploadFile = event => {
    this.setState({ loading: true });
    const data = event.target.files[0];

    this.setState({ fileData: data, loading: false });
    this.props.onChange(data);
  };

  render() {
    const { error, helperText, accept, classes } = this.props;

    return (
      <Grid container spacing={16} alignItems="center">
        <Grid item sm={8} xs={12}>
          <TextField
            name="fileName"
            label="Archivo"
            margin="normal"
            fullWidth
            variant="outlined"
            inputProps={{ readOnly: true }}
            value={
              this.state.fileData
                ? this.state.fileData.name
                  ? this.state.fileData.name
                  : ''
                : ''
            }
            error={error}
            helperText={
              helperText || 'Máximo número de registros por archivo: 200.000'
            }
          />
        </Grid>
        <Grid item sm xs={12}>
          <input
            accept={accept}
            id="input-file"
            type="file"
            className={classes.inputStyle}
            onChange={this.handleUploadFile}
          />
          <label htmlFor="input-file">
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              component="span"
              color="secondary"
              disabled={this.state.loading}
            >
              {this.state.loading ? (
                <ActivityIndicator
                  className={classes.loadingIndicator}
                  size={24}
                />
              ) : (
                'Subir Archivo'
              )}
            </Button>
          </label>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  inputStyle: {
    display: 'none'
  },
  loadingIndicator: {
    position: 'absolute',
    left: '0',
    width: '100%',
    top: '0',
    height: '100%',
    zIndex: 1
  }
});

InputFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default withStyles(
  combineStyles(ButtonStyles, TypographyStyles, styles),
  {
    withTheme: true
  }
)(InputFile);
