import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

class SimpleTableRow extends PureComponent {
  render() {
    const { classes, className, ...props } = this.props;
    return (
      <TableRow className={classnames(classes.row, className)} {...props} />
    );
  }
}

SimpleTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

const styles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
});

export default withStyles(styles)(SimpleTableRow);
