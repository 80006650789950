import moment from 'moment-timezone';

export const columns = [
  {
    title: 'Nombre',
    name: 'name'
  },
  {
    title: 'Descripción',
    name: 'description'
  },
  {
    title: 'Valor',
    name: 'value'
  },
  {
    title: 'Etiquetas',
    name: 'tags'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum.attributes,
      id: datum.id,
      name: datum.attributes.name || '--',
      description: datum.attributes.description || '--',
      value: datum.attributes.value 
                ? datum.attributes.value.includes("[{")
                  ? "-- Ver en Consola Administrativa"
                  : datum.attributes.value 
                : '--',
      tags: datum.attributes.tags
    };
  });
