import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';

const styles = theme => ({
  toggleSwitch: {
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    top: 3
  },
  root: {},
  toggleSwitchLeftLabel: {
    marginRight: `${theme.spacing.unit * 1.5}px !important`
  },
  toggleSwitchLabel: {
    color: `${theme.typography.color.second} !important`
  },
  inactiveLable: {
    cursor: 'pointer'
  },
  noMargin: {
    margin: [0, '!important']
  }
});

export class Toggle extends Component {
  state = {
    active: false
  };
  static propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.setState({ active: this.props.value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ active: this.props.value });
    }
  }

  _handleChange = () =>
    this.setState({ active: !this.state.active }, () =>
      this.props.onChange(this.state.active)
    );

  render() {
    const {
      classes,
      name = 'active',
      inactiveLabel = 'Inactivo',
      activeLabel = 'Activo',
      disabled = false
    } = this.props;
    const { active } = this.state;

    return (
      <div className={classnames(classes.toggleSwitch, classes.root)}>
        <p
          className={classnames(
            disabled
              ? classes.body2Light
              : [classes.body2, classes.inactiveLable],
            {
              [classes.toggleSwitchLabel]: active
            }
          )}
          onClick={disabled ? null : this._handleChange}
        >
          {inactiveLabel}
        </p>
        <FormControlLabel
          control={
            <Switch
              disabled={disabled}
              checked={active}
              onChange={this._handleChange}
              value={name}
              color="secondary"
            />
          }
          label={activeLabel}
          classes={{
            root: classes.noMargin,
            label: classnames(classes.body2, {
              [classes.toggleSwitchLabel]: !active
            })
          }}
        />
      </div>
    );
  }
}

export default withStyles(combineStyles(styles, TypographyStyles))(Toggle);
