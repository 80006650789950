import {
  DASHBOARD_SELLERS_SUMMARY,
  DASHBOARD_SELLERS_SALES,
  DASHBOARD_TOP_RANKING,
  DASHBOARD_DATA_SALESMAN,
  DASHBOARD_DATA_SUPPLIER,
  SUPPLIER_FILTER,
  DASHBOARD_COUNTER_SUMMARY,
  FILTER_SELECTION
} from '../constants/dashboard_sellers_constants';

const actualMonth = () => {
  const meses = new Array(
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  );
  const f = new Date();
  return meses[f.getMonth()];
};

const initialState = {
  sellersSummary: {
    month: actualMonth(),
    totalSupplier: '0',
    totalSalesman: '0'
  },
  sellersSales: {
    month: '',
    year: '',
    partialActualCount: 0,
    totalActualCount: 0,
    lastMonth: '',
    lastYear: '',
    partialLastCount: 0,
    totalLastCount: 0
  },
  topRanking: [{}],
  dataSalesman: [{}],
  dataSupplier: [{}],
  supplierFilter: '',
  filterSelection: {
    supplier: '',
    pointOfSale: '',
    year: 0,
    month: 0,
    saleType: ''
  }
};

export function dashboardSellers(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_SELLERS_SUMMARY:
      return {
        ...state,
        sellersSummary: action.data
      };
    case DASHBOARD_SELLERS_SALES:
      return {
        ...state,
        sellersSales: action.data
      };
    case DASHBOARD_TOP_RANKING:
      return {
        ...state,
        topRanking: action.data
      };
    case DASHBOARD_COUNTER_SUMMARY:
      return {
        ...state,
        counterSummary: action.data
      };
    case DASHBOARD_DATA_SALESMAN:
      return {
        ...state,
        dataSalesman: action.data
      };
    case DASHBOARD_DATA_SUPPLIER:
      return {
        ...state,
        dataSupplier: action.data
      };
    case SUPPLIER_FILTER:
      return {
        ...state,
        supplierFilter: action.data
      };
    case FILTER_SELECTION:
      return {
        ...state,
        filterSelection: action.data
      };
    default:
      return state;
  }
}
