const styles = theme => ({
  hidden: {
    display: 'none'
  },
  visible: {
    visibility: 'visible'
  },
  invisible: {
    visibility: 'hidden'
  },
  pageRoot: {
    position: 'relative'
  },
  emptyWrapper: {
    display: 'flex'
  },
  flexWrap: {
    flexWrap: 'wrap'
  },
  imageIcon: {
    width: '100%',
    marginTop: '20px'
  },
  loadingIndicator: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 1
  },
  titleRootWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.unit * 3,
    // marginTop: theme.spacing.unit * 3,
    position: 'relative'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  rowHalf: {
    width: 'calc(50% - 8px)'
  },
  rowHalf24: {
    marginRight: theme.spacing.unit * 3,
    width: 'calc(50%)',
    '&:last-child': {
      marginRight: 0
    }
  },
  rowQuarter: {
    width: 'calc(25% - 13px)'
  },
  wrapper: {
    backgroundColor: '#FFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    padding: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    width: '100%',
    position: 'relative'
  },
  simpleWrapper: {
    width: '100%',
    position: 'relative'
  },
  wrapperNoPadding: {
    backgroundColor: '#FFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    width: '100%',
    marginBottom: theme.spacing.unit * 3
  },
  wrapperHalf: {
    width: 'calc(50% - 12px)'
  },
  wrapperNoBox: {
    backgroundColor: '#FFF',
    borderRadius: '2px',
    marginBottom: theme.spacing.unit * 3,
    width: '100%',
    position: 'relative'
  },
  dashedContentDivider: {
    borderBottom: '1px dashed rgba(0,0,0,0.12)'
  },
  titleContentDivider: {
    borderBottom: '1px solid rgba(12,31,44,0.1)'
  },
  spacer: {
    display: 'flex',
    flex: 1
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    borderBottom: '1px solid rgba(12,31,44,0.1)'
  },
  alertMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1em',
    color: '#DB4945',
    fontSize: 'large',
    fontWeight: 'bold'
  }
});

export default styles;
