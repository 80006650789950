import AxiosRequest from '../services/axios_request';
import {
  formSanitizer,
  nodeFormSanitizer
} from '../helpers/sanitizers/calculateInstalmentsSanitizer';
import { newAlert } from './alert_actions';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import {
  OPEN_CALCULATE_INSTALMENTS_MODAL,
  CLOSE_CALCULATE_INSTALMENTS_MODAL,
  SET_INSTALMENTS_VALUE,
  SET_MAX_INSTALMENTS_NUMBER,
  SET_OBLIGATORY_INSURANCE_VALUE,
  SET_VALUE_TO_FINANCE,
  SET_SECURE_VALUE
} from '../constants/calculate_instalments_constants';
import RequestService from '../services/request_service';

const axiosRequest = new AxiosRequest();
const requestNodeService = new RequestService('node');

const requestMaxInstalmentsNumber = async () => {
  return await requestNodeService.get(
    'global-settings/org-data?keys[]=maxInstalmentsNumber'
  );
};

const setMaxInstalmentsNumber = (maxInstalmentsNumber) => {
  return {
    type: SET_MAX_INSTALMENTS_NUMBER,
    data: maxInstalmentsNumber
  };
};

export const openCalculateInstalmentsModal = () => async dispatch => {
  dispatch(errorOnRequest('InstalmentsCalculation', {}));
  dispatch({
    type: OPEN_CALCULATE_INSTALMENTS_MODAL
  });
  try {
    const max_instalments_payload = await requestMaxInstalmentsNumber();
    dispatch(setMaxInstalmentsNumber(max_instalments_payload.data.maxInstalmentsNumber));
  } catch (errors) {
    dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  }
};

export const closeCalculateInstalmentsModal = () => {
  return {
    type: CLOSE_CALCULATE_INSTALMENTS_MODAL
  };
};

const requestInstalmentsCalculation = async (params = {}) => {
  return requestNodeService.post(
    'instalments-value-calculation',
    nodeFormSanitizer(params)
  );
};

const setInstalmentsValue = (instalments_value) => {
  return {
    type: SET_INSTALMENTS_VALUE,
    data: instalments_value
  };
};

const setObligatoryInsuranceValue = (obligatory_insurance_value) => {
  return {
    type: SET_OBLIGATORY_INSURANCE_VALUE,
    data: obligatory_insurance_value
  };
};

const setValueToFinance = (valueToFinance) => {
  return {
    type: SET_VALUE_TO_FINANCE,
    data: valueToFinance
  };
};

const setSecureValue = (secureValue) => {
  return {
    type: SET_SECURE_VALUE,
    data: secureValue
  };
};

export const calculateInstalments = (params = {}) => async dispatch => {
  dispatch(setInstalmentsValue(null));
  dispatch(setObligatoryInsuranceValue(null));
  dispatch(setValueToFinance(null));
  dispatch(setSecureValue(null));
  dispatch(sendRequest('InstalmentsCalculation'));
  dispatch(errorOnRequest('InstalmentsCalculation', {}));
  try {
    const payload = await requestInstalmentsCalculation(params);

    dispatch(setInstalmentsValue(payload.instalmentsValue));
    dispatch(setObligatoryInsuranceValue(payload.obligatoryInsuranceValue));
    dispatch(setValueToFinance(payload.valueToFinance));
    dispatch(setSecureValue(payload.secureValue));
    if (params.isSecureSale && !payload.secureValue && payload.instalmentsValue) {
      dispatch(newAlert('error', 'ERROR:', 'No existe una configuración de fianza para el N° de cuotas seleccionado'));
    }
  } catch (errors) {
    const formErrors = await errors.json();
    dispatch(errorOnRequest('InstalmentsCalculation', formErrors.errors));
    dispatch(newAlert('error', 'ERROR:', formErrors.errors.base));
  } finally {
    dispatch(completeRequest('InstalmentsCalculation'));
  }
};
