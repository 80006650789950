import {
  QUERY_QUOTAS,
  OPEN_QUERY_QUOTA_MODAL,
  CLOSE_QUERY_QUOTA_MODAL,
  RESET_QUERY_DATA,
  CLIENT_IS_ROLLOVER
} from '../constants/query_quota_constants';
import _get from 'lodash/get';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { newAlert } from './alert_actions';
import AxiosNodeRequest from '../services/axios_node_request';
import queryQuotaFiltersSanitizer from '../helpers/sanitizers/queryQuotaSanitizer';

const axiosNodeRequest = new AxiosNodeRequest();

export const openQueryQuotaModal = () => {
  return dispatch => {
    dispatch({
      type: OPEN_QUERY_QUOTA_MODAL
    });
  };
};

export const closeQueryQuotaModal = () => {
  return dispatch => {
    dispatch(errorOnRequest('QueryQuotas', null));
    dispatch({
      type: CLOSE_QUERY_QUOTA_MODAL
    });
  };
};

export const resetQueryData = () => {
  return dispatch => {
    dispatch({
      type: RESET_QUERY_DATA
    });
  };
};

function setQuotas(data) {
  return {
    type: QUERY_QUOTAS,
    data
  };
}

function setClientIsRollover(data) {
  return {
    type: CLIENT_IS_ROLLOVER,
    data
  };
}

export const queryQuotas = (params = {}) => async dispatch => {
  dispatch(sendRequest('QueryQuotas'));
  try {
    const payload = await axiosNodeRequest.post(
      'contracts/quotaCheck',
      queryQuotaFiltersSanitizer(params),
      true
    );
    dispatch(setQuotas(payload.data.contracts));
    dispatch(setClientIsRollover(payload.data.clientIsRollover));
    dispatch(errorOnRequest('QueryQuotas', null));
  } catch (errors) {
    dispatch(errorOnRequest('QueryQuotas', errors));
    dispatch(setQuotas([]));
    dispatch(setClientIsRollover(null));
    _get(errors, 'errors.base') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base));
  } finally {
    dispatch(completeRequest('QueryQuotas'));
  }
};
