import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ActivityIndicator from '../../shared/ActivityIndicator';
import _get from 'lodash/get';
import { toCapitalize } from '../../../helpers/utilityFunctions';
import { history } from '../../../helpers/history';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';

import PromissoryHeader from './PromissoryHeader';
import PromissoryPreview from './PromissoryPreview';
import PromissoryCustomer from '../SaleDetailPage/Customer';
import PromissorySales from './Sales/Sales';
import Comments from '../Comments';
import Activity from './Activity/Activity';

class PromissoryDetail extends Component {
  state = {
    promissory_approving: false,
    promissory_causal_id: '',
    observation_dialog: false,
    observation_text: ''
  };

  componentDidMount() {
    this.props.fetchPromissory(this.props.match.params.id);
    (this.props.canUpdateUniquePromissory ||
      this.props.canUpdateNoUniquePromissory) &&
      this.props.fetchGeographicLocations();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.editPromissorySaved !== this.props.editPromissorySaved &&
      this.props.editPromissorySaved
    ) {
      this.setState({ isEditPromissoryOpen: false });
    }
  }

  _handleApprovePromissory = params => {
    this.props.approvePromissory(params, this.props.match.params.id);
  };

  _handleEditPromissory = params => {
    this.props.editPromissory(params, this.props.match.params.id);
  };

  _handleCancel = params => {
    this.props.cancelPromissory(params, this.props.match.params.id);
  };

  _handleReject = params => {
    this.props.rejectPromissory(params, this.props.match.params.id);
  };

  _getActivities = () => {
    const { promissory } = this.props;
    const activities = [];
    promissory.movements &&
      promissory.movements.forEach(move => {
        const causal = toCapitalize(move.comment);
        activities.push({
          title: move.status || '',
          user: move.user || { firstName: 'Sin', lastName: 'Usuario' },
          comment: causal || '',
          date: move.createdAt
        });
      });
    return activities;
  };

  _getComments = () => {
    const { promissory } = this.props;
    const comments = [];
    promissory &&
      promissory.comments &&
      promissory.comments.length > 0 &&
      promissory.comments.forEach(c => {
        comments.push(c);
      });
    return comments;
  };
  _showCosigner = promissory => {
    return promissory.cosigner || promissory.cosignerDetails;
  };

  toSaleDetail = sale => {
    sale.canViewSale &&
      this.props.canViewSales &&
      history.push(`/sales/${sale.id}`);
  };

  render() {
    const {
      promissory,
      classes,
      requestFetchPromissorySend = true,
      canAddCommentsToPrommissory,
      currentUser
    } = this.props;

    if (requestFetchPromissorySend) {
      return <ActivityIndicator />;
    }
    if (!promissory) return null;

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <PromissoryHeader
            promissory={promissory}
            canApprovePromissory={this.props.canApprovePromissory}
            canCancelPromissory={this.props.canCancelPromissory}
            canCancelUnmaterializedPromissory={this.props.canCancelUnmaterializedPromissory}
            canUpdateUniquePromissory={this.props.canUpdateUniquePromissory}
            canUpdateNoUniquePromissory={this.props.canUpdateNoUniquePromissory}
            canUpdateUnmaterializedPromissory={this.props.canUpdateUnmaterializedPromissory}
            revokePromissoryCausals={this.props.revokePromissoryCausals}
            rejectPromissoryCausals={this.props.rejectPromissoryCausals}
            cancelPromissoryCausals={this.props.cancelPromissoryCausals}
            onEdit={this._handleEditPromissory}
            onApprove={this._handleApprovePromissory}
            onCancel={this._handleCancel}
            onReject={this._handleReject}
            {...this.props}
          />
        </div>
        <div className={classes.bodySection}>
          <Grid container spacing={40}>
            <Grid item xs={12} md={4}>
              <Activity activities={this._getActivities()} />
              <PromissoryCustomer
                contract={_get(promissory, 'debtorContract') || {}}
                customer={_get(promissory, 'debtor') || {}}
                customerDetails={_get(promissory, 'debtorDetails') || {}}
                expanded={!_get(promissory, 'cosigner')}
              />
              {this._showCosigner(promissory) && (
                <PromissoryCustomer
                  type="Codeudor"
                  contract={_get(promissory, 'cosignerContract') || {}}
                  customer={_get(promissory, 'cosigner') || {}}
                  customerDetails={_get(promissory, 'cosignerDetails') || {}}
                  status={
                    _get(promissory, 'solidaryDebtor')
                      ? 'Solidario'
                      : 'Obligatorio'
                  }
                />
              )}
              {promissory.sales.length > 0 && (
                <PromissorySales
                  sales={promissory.sales}
                  toSaleDetail={this.toSaleDetail}
                  currentUser={currentUser}
                />
              )}
              {canAddCommentsToPrommissory && (
                <Comments
                  entityName="Promissory"
                  object={promissory}
                  previousComments={this._getComments()}
                />
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <PromissoryPreview
                url={
                  !this.props.requestPromissoryUrlSend && this.props.pdfUrl
                    ? this.props.pdfUrl
                    : ''
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
const styles = theme => ({});

PromissoryDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  promissory: PropTypes.object,
  fetchPromissory: PropTypes.func,
  approvePromissory: PropTypes.func
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(PromissoryDetail);
