import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-step-progress-bar/styles.css';
import classnames from 'classnames';
import { ProgressBar as ReactProgressBar, Step } from 'react-step-progress-bar';
import ProgressBarStyles from './__styles__/ProgressBar.styles';
import Tooltip from '@material-ui/core/Tooltip';

import { STATUSES as SALE_STATUSES } from './constanst';

export class ProgressBar extends PureComponent {
  static propTypes = {
    status: PropTypes.string.isRequired,
    saleDetail: PropTypes.object.isRequired
  };
  determineStyle = () => {
    const { status, classes, theme, saleDetail } = this.props;
    const pageStyles = ProgressBarStyles(theme);

    const validSteps = [
      SALE_STATUSES.REGISTERED,
      SALE_STATUSES.DELIVERED,
      SALE_STATUSES.EXCEPTION,
      SALE_STATUSES.REVOKED,
      SALE_STATUSES.RETURNED,
      SALE_STATUSES.PENDING_DELIVERY,
      SALE_STATUSES.PENDING_REVISION
    ];

    const barStyle = {
      step1: '',
      step2: '',
      step3: '',
      step4: '',
      barColor:
        validSteps.indexOf(status) > -1
          ? pageStyles[status].backgroundColor
          : pageStyles.notDoneColor.backgroundColor,
      percentComplete: 25
    };

    const doneColorClass = classes[status];

    switch (status) {
      case SALE_STATUSES.REGISTERED:
        barStyle.step1 = classnames(classes.endCircles, doneColorClass);
        barStyle.step2 = classnames(
          classes.stepCircle,
          classes[SALE_STATUSES.REGISTERED]
        );
        barStyle.step3 = classnames(classes.stepCircle, classes.notDoneStep);
        barStyle.step4 = classnames(
          classes.endCircles,
          classes.notDoneStep,
          classes.lastCircle
        );
        barStyle.percentComplete = 25;
        break;
      case SALE_STATUSES.PENDING_DELIVERY:
        barStyle.step1 = classnames(classes.endCircles, doneColorClass);
        barStyle.step2 = classnames(classes.endCircles, doneColorClass);
        barStyle.step3 = classnames(
          classes.stepCircle,
          classes[SALE_STATUSES.PENDING_DELIVERY]
        );
        barStyle.step4 = classnames(
          classes.endCircles,
          classes.notDoneStep,
          classes.lastCircle
        );
        barStyle.percentComplete = 75;
        break;
      case SALE_STATUSES.PENDING_REVISION:
        barStyle.step1 = classnames(classes.endCircles, doneColorClass);
        barStyle.step2 = classnames(classes.endCircles, doneColorClass);
        barStyle.step3 = classnames(
          classes.stepCircle,
          classes.pendingRevisionStatus
        );
        barStyle.step4 = classnames(classes.endCircles, doneColorClass);
        barStyle.percentComplete = 100;
        break;
      case SALE_STATUSES.DELIVERED:
        barStyle.step1 = classnames(classes.endCircles, doneColorClass);
        barStyle.step2 = classnames(classes.stepCircle, doneColorClass);
        barStyle.step3 = classnames(classes.stepCircle, doneColorClass);
        barStyle.step4 = classnames(classes.endCircles, doneColorClass);
        barStyle.percentComplete = 100;
        break;
      case SALE_STATUSES.RETURNED:
        barStyle.step1 = classnames(classes.endCircles, doneColorClass);
        barStyle.step2 = '';
        barStyle.step3 = '';
        barStyle.step4 = classnames(classes.endCircles, doneColorClass);
        barStyle.percentComplete = 100;
        break;
      case SALE_STATUSES.EXCEPTION:
      case SALE_STATUSES.REVOKED:
      default:
        barStyle.step1 = classnames(classes.endCircles, classes.revokedColor);
        barStyle.step2 = '';
        barStyle.step3 = '';
        barStyle.step4 = classnames(
          classes.endCircles,
          classes.revokedColor,
          classes.lastCircle
        );
        barStyle.percentComplete = 100;
        break;
    }

    return barStyle;
  };

  _createPendingStatusText = () => {
    if (this.props.saleDetail.approvedAt) {
      return 'Aprobada';
    } else {
      return 'Sin aprobar';
    }
  };

  render() {
    const barStyle = this.determineStyle();

    return (
      <div>
        <ReactProgressBar
          percent={barStyle.percentComplete}
          stepPositions={[0, 25, 75, 100]}
          unfilledBackground={'rgba(235,238,241, 0.8)'}
          filledBackground={barStyle.barColor}
          height={4}
        >
          <Step>{() => <div className={barStyle.step1} />}</Step>
          <Step>
            {() => (
              <Tooltip title={SALE_STATUSES.REGISTERED}>
                <div className={barStyle.step2} />
              </Tooltip>
            )}
          </Step>
          <Step>
            {() => (
              <Tooltip title={this._createPendingStatusText()}>
                <div className={barStyle.step3} />
              </Tooltip>
            )}
          </Step>
          <Step>
            {() => {
              if (this.props.saleDetail.canceledAt) {
                return (
                  <Tooltip title={SALE_STATUSES.REVOKED}>
                    <div className={barStyle.step4} />
                  </Tooltip>
                );
              }
              return (
                <Tooltip
                  title={
                    barStyle.percentComplete === 100
                      ? SALE_STATUSES.DELIVERED
                      : 'Sin entregar'
                  }
                >
                  <div className={barStyle.step4} />
                </Tooltip>
              );
            }}
          </Step>
        </ReactProgressBar>
      </div>
    );
  }
}
export default withStyles(ProgressBarStyles)(ProgressBar);
