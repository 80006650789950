export const listSanitizer = params => {
    const sanitizedParams = {
      page: params.page,
      per_page: params.perPage
    };
  
    return sanitizedParams;
  };
  
  export const createSanitizer = params => {
    const sanitizedParams = {
      scoring_exclusion: {
        supplier_id: params.supplierId,
        category_id: params.categoryId,
        geographic_location_id: params.geographicLocationId
      }
    };
  
    return sanitizedParams;
  };
  
  export const updateSanitizer = params => {
    const sanitizedParams = {
        scoring_exclusion: {
        supplier_id: params.supplierId,
        category_id: params.categoryId,
        geographic_location_id: params.geographicLocationId
      }
    };
  
    return sanitizedParams;
  };