import React, { PureComponent, Fragment } from 'react';
import ActivityIndicator from './ActivityIndicator';

class Timer extends PureComponent {
  resendText = counter => {
    if (counter > 0) {
      const minutes = Math.floor(counter / 60);
      let seconds = counter - minutes * 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    }
    return this.props.message || 'Reenviar código';
  };

  render() {
    const { counter, loading = false } = this.props;

    if (loading) return <ActivityIndicator />;

    return <Fragment>{this.resendText(counter)}</Fragment>;
  }
}

export default Timer;
