import moment from 'moment-timezone';
import _compact from 'lodash/compact';
import { saleTypeOptions } from './dropDownOptions';
import { formatSaleStatusLabel } from '../../../helpers/utilityFunctions';

export const columns = [
  {
    title: '# Venta',
    name: 'id'
  },
  {
    title: 'Fecha',
    name: 'registeredDate'
  },
  {
    title: 'Aliado',
    name: 'supplierName'
  },
  {
    title: 'Deudor',
    name: 'customer'
  },
  {
    title: 'Tipo de Identificación',
    name: 'identificationType'
  },
  {
    title: 'Identificación',
    name: 'identification'
  },
  {
    title: '# Contrato',
    name: 'contractId'
  },
  {
    title: 'Estado',
    name: 'saleStatus'
  },
  {
    title: 'Tipo',
    name: 'saleType'
  }
];

export const columnsPOS = [
  {
    title: '# Venta',
    name: 'id'
  },
  {
    title: 'Fecha',
    name: 'registeredDate'
  },
  {
    title: 'Punto de venta',
    name: 'pointOfSaleName'
  },
  {
    title: 'Deudor',
    name: 'customer'
  },
  {
    title: 'Tipo de Identificación',
    name: 'identificationType'
  },
  {
    title: 'Identificación',
    name: 'identification'
  },
  {
    title: '# Contrato',
    name: 'contractId'
  },
  {
    title: 'Estado',
    name: 'saleStatus'
  },
  {
    title: 'Tipo',
    name: 'saleType'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      registeredDate:
        moment(datum.registeredAt)
          .locale('es')
          .format('DD MMM YYYY') || '--',
      identificationType: datum.clientTypeOfIdentification,
      identification: datum.clientIdentification,
      customer:
        _compact([datum.clientName, datum.clientLastName]).join(' ') || '--',
      saleType: saleTypeOptions.find(item => item.value == datum.saleType)
        .title,
      saleStatus: formatSaleStatusLabel(datum.saleStatus)
    };
  });
