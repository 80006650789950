import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import TextFieldInput from '../../../shared/Inputs/TextField';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import DateFieldInput from '../../../shared/Inputs/DateField';
import InfoField from '../../../shared/InfoField';
import formatMoney from '../../../../helpers/formatMoney';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';

import MoveQuotaValidation from './MoveQuota.validation';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  infoField: {
    width: '95%'
  },
  gridItem: {
    padding: 0
  },
  outlined: {
    padding: '2px 8px 0 8px !important'
  }
});

export class MoveQuotaModal extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnSave = async params => {
    const { moveQuota } = this.props;
    moveQuota(params, this.props.debtor_cache, this.props.routes[2]);
  };

  _handleOnClose = () => {
    const { onClose, resetMoveQuotaData } = this.props;
    onClose('MoveQuota');
    resetMoveQuotaData();
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  _handleNewQuery = () => {
    const { resetMoveQuotaData } = this.props;
    this.formik.resetForm();
    resetMoveQuotaData();
  };

  _queryContracts = values => {
    this.props.fetchTransferQuotas(values, this.props.debtor_cache, 'transfer');
  };

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      doctypes,
      moveQuotaData,
      isFetching,
      serverErrors
    } = this.props;

    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        validationSchema={MoveQuotaValidation}
        initialValues={{
          contract: '',
          identificationType: 'Cédula',
          identification: '',
          birthdate: null
        }}
        onSubmit={values => {
          this._handleOnSave(values);
        }}
        onReset={actions => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty,
          setFieldValue
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              footer: classes.modalFooter
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {'Traslado de Cupo'}
                  </p>
                </div>
                <Grid container spacing={16}>
                  <GridItem sm={6} xs={12} className={classes.outlined}>
                    <OutlinedTextField
                      label={'Contrato'}
                      name={'contract'}
                      value={values.contract}
                      onChange={handleChange}
                      type={'number'}
                      fullWidth
                      error={serverErrors && !!serverErrors.transferContract}
                      helperText={serverErrors && serverErrors.transferContract}
                    />
                  </GridItem>
                  <GridItem sm={6} xs={12}>
                    <Field
                      render={({ field, form }) => {
                        return (
                          <SimpleSelect
                            name="identificationType"
                            onChange={handleChange}
                            value={values.identificationType}
                            label="Tipo de identificación"
                            fullWidth
                            field={field}
                            form={form}
                            type={'number'}
                            options={doctypes}
                            allowMargin={false}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem sm={6} xs={12} className={classes.outlined}>
                    <OutlinedTextField
                      label={'Identificación'}
                      name={'identification'}
                      value={values.identification}
                      onChange={handleChange}
                      fullWidth
                      error={serverErrors && !!serverErrors.identification}
                      helperText={serverErrors && serverErrors.identification}
                    />
                  </GridItem>
                  <GridItem sm={6} xs={12}>
                    <Field
                      name="birthdate"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <DateFieldInput
                            value={values.birthdate || null}
                            label={'Fecha de nacimiento'}
                            fullWidth
                            field={field}
                            form={form}
                            disablePast={false}
                            disableFuture={true}
                            format={'YYYY/MM/DD'}
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              '/',
                              /\d/,
                              /\d/,
                              '/',
                              /\d/,
                              /\d/
                            ]}
                            error={!!_get(serverErrors, 'birthdate', null)}
                            helperText={_get(serverErrors, 'birthdate', '')}
                          />
                        );
                      }}
                    />
                  </GridItem>
                </Grid>
                {moveQuotaData.requestMade && (
                  <Grid container spacing={16}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      classes={{ item: classes.gridItem }}
                    >
                      <InfoField label="Nombre:" className={classes.infoField}>
                        {moveQuotaData.clientName}
                      </InfoField>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      classes={{ item: classes.gridItem }}
                    >
                      <InfoField
                        label="Cupo Trasladable:"
                        className={classes.infoField}
                      >
                        {formatMoney(moveQuotaData.availableQuota)}
                      </InfoField>
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            }
            footer={
              <Fragment>
                {moveQuotaData.requestMade && (
                  <Button
                    className={classes.button}
                    onClick={this._handleNewQuery}
                    color={'secondary'}
                    classes={{ label: classes.buttonLabel }}
                  >
                    {'Nueva Consulta'}
                  </Button>
                )}
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={this._handleOnClose}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                {moveQuotaData.requestMade ? (
                  <ProgressButton
                    onClick={handleSubmit}
                    className={classes.button}
                    classes={{
                      disabled: classes.buttonDisabled,
                      label: classes.buttonLabel
                    }}
                    color={'secondary'}
                    disabled={!dirty || isSubmitting}
                    isSubmitting={isSubmitting}
                  >
                    {'Trasladar Cupo'}
                  </ProgressButton>
                ) : (
                  <ProgressButton
                    onClick={() => this._queryContracts(values)}
                    className={classes.button}
                    classes={{
                      disabled: classes.buttonDisabled,
                      label: classes.buttonLabel
                    }}
                    color={'secondary'}
                    disabled={!dirty || isFetching}
                    isSubmitting={isFetching}
                  >
                    {'Consultar'}
                  </ProgressButton>
                )}
              </Fragment>
            }
          />
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(MoveQuotaModal);
