import _pickBy from 'lodash/pickBy';

const saleRevokeFilters = filters => {
  const { saleId, contractId, cause, status, zoneId, page, per_page } = filters;
  const sanitizedFilters = {
    sale_id_eq: saleId,
    sale_contract_id_eq: contractId,
    sale_return_details_cause_cont: cause,
    sale_return_details_status_eq: status,
    sale_return_details_return_type_cont: 'returned',
    sale_contract_location_parent_id_eq: zoneId,
    page,
    per_page
  };

  const STATUSES = {
    registered: 0,
    approved: 1,
    revoked: 2,
    reviewed: 3
  };

  const params = _pickBy(sanitizedFilters, filter => !!filter);
  return {
    ...params,
    sale_return_details_status_eq: STATUSES[status]
  };
};

export default saleRevokeFilters;
