export const SpacingStyles = theme => ({
  noMargin: {
    margin: '0 !important'
  },
  noMarginBottom: {
    marginBottom: '0 !important'
  },
  marginBottom1: {
    marginBottom: theme.spacing.unit
  },
  marginBottom2: {
    marginBottom: theme.spacing.unit * 2
  },
  marginBottom3: {
    marginBottom: theme.spacing.unit * 3
  },
  noMarginTop: {
    marginTop: '0 !important'
  },
  marginTop2: {
    marginTop: `${theme.spacing.unit * 2}px !important`
  },
  noPadding: {
    padding: '0 !important'
  },
  noPaddingBottom: {
    paddingBottom: '0 !important'
  },
  noPaddingRight: {
    paddingRight: '0 !important'
  },
  gridItemPadding: {
    padding: [[theme.spacing.unit * 2.25, theme.spacing.unit], '!important']
  },
  verticalGridItem: {
    padding: [[theme.spacing.unit * 1.875, 0], '!important']
  },
  containerPadding: {
    padding: theme.spacing.unit * 3
  },
  containerPadding2: {
    padding: theme.spacing.unit * 6
  },
  spacer: {
    display: 'flex',
    flex: 1,
    margin: 0
  }
});

export default SpacingStyles;
