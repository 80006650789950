import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import QueryPromissory from './QueryPromissory';
import { newAlert } from '../../../actions/alert_actions';
import {
  queryPromissories,
  closeQueryPromissoryModal,
  resetQueryData,
  fetchAvailableQuota,
  setAvailableQuota
} from '../../../actions/query_promissory_actions';

function mapStateToProps(state) {
  return {
    loading: state.requestState.requestQueryPromissoriesSend,
    serverErrors: state.requestState.requestErrorsOnQueryPromissories,
    promissories: state.queryPromissories.promissories,
    isOpen: state.queryPromissories.isQueryPromissoryModalOpen,
    avQuota: state.queryPromissories.availableQuota,
    avQuotaLoading: state.requestState.requestQueryPromissoryAvailableQuotaSend,
    hasSearched: state.queryPromissories.hasSearched
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    queryPromissories: bindActionCreators(queryPromissories, dispatch),
    closeModal: bindActionCreators(closeQueryPromissoryModal, dispatch),
    resetQueryData: bindActionCreators(resetQueryData, dispatch),
    fetchAvailableQuota: bindActionCreators(fetchAvailableQuota, dispatch),
    setAvailableQuota: bindActionCreators(setAvailableQuota, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QueryPromissory);
