import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import TextFieldInput from '../../../shared/Inputs/TextField';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import GridItem from '../../../shared/GridItem';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import _forEach from 'lodash/forEach';
import CategoryFormValidation from './CategoryForm.validation';
import Toggle from '../../../shared/Buttons/Toggle';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  }
});

export class CategoryFormModal extends Component {
  state = {
    name: '',
    parent_name: '',
    active: false,
    nameInSupplierProposalModule: '',
    parent_id: null,
    contract_type_id: ''
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnSave = async category => {
    const { onSave } = this.props;
    onSave(category);
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v));
    return errors;
  };

  _getSuggestions = async (type, value) => {
    let itemsByType = this.props[type];
    if (this.state.contract_type_id) {
      itemsByType = this.props[type].filter(item => Number(item.contract_type_id) == this.state.contract_type_id);
    }
    const items = itemsByType.map(item => ({
      label: item.name || item.description,
      value: item.id
    }));
    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      category = {},
      canUpdateCategoriesNameInSupplierProposalModuleField,
      canSetMaxItemsPerCategory
    } = this.props;
    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        validationSchema={CategoryFormValidation}
        initialValues={{
          name: '',
          parent_name: '',
          depth: 0,
          active: true,
          nameInSupplierProposalModule: '',
          ...category
        }}
        onSubmit={(values, actions) => {
          this._handleOnSave(values);
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty,
          setFieldValue,
          errors,
          touched
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              footer: classes.modalFooter
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {values.isNew ? 'Crear Categoría' : 'Editar Categoría'}
                  </p>
                  <Toggle
                    disabled={false}
                    name={'active'}
                    value={values.active}
                    onChange={change => {
                      setFieldValue('active', change);
                      this.setState({ active: change });
                    }}
                  />
                </div>
                <Grid container spacing={16}>
                  <GridItem item xs={12}>
                    <SimpleSelect
                      id="contract_type_id"
                      name="contract_type_id"
                      label="Tipo"
                      margin="normal"
                      variant="outlined"
                      disabled={!values.isNew}
                      error={
                        !!errors.contract_type_id
                      }
                      helperText={
                        errors.contract_type_id
                      }
                      options={[
                        {key: 1, value: 1, label: "Residencial"},
                        {key: 2, value: 2, label: "Comercial"}
                      ]}
                      value={values.contract_type_id || ''}
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('parent_name', '');
                        setFieldValue('parent_id', 0);
                        setFieldValue('parentId', 0);
                        this.setState({
                          contract_type_id: e.target.value,
                          parent_name: ''
                        });
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      label="Nombre"
                      name="name"
                      value={values.name}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      error={
                        touched.name && !!errors.name
                      }
                      helperText={
                        errors.name
                      }
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      multiline
                      render={({ field, form }) => {
                        return (
                          <TextFieldInput
                            value={values.name}
                            label={'Nombre'}
                            fullWidth
                            onChange={change => {
                              setFieldValue('name', change.target.value);
                            }}
                            field={field}
                            form={form}
                            type="text"
                          />
                        );
                      }}
                    />
                  </GridItem>
                  {(values.depth === 1 && !values.isNew) || values.isNew ? (
                    <GridItem xs={12}>
                      <Field
                        name="parent_name"
                        onChange={handleChange}
                        render={() => {
                          return (
                            <AutocompleteInput
                              id="parent_name"
                              name="parent_name"
                              label="Padre"
                              margin="normal"
                              variant="outlined"
                              disabled={!values.isNew}
                              error={
                                touched.parent_name &&
                                (!!errors.parent_name || !!errors.parent_id)
                              }
                              helperText={
                                touched.parent_name &&
                                (errors.parent_name || errors.parent_id)
                              }
                              value={
                                this.state.parent_name ||
                                values.parent_name ||
                                ''
                              }
                              onChange={change => {
                                setFieldValue('parent_name', '');
                                setFieldValue('parent_id', '');
                                this.setState({
                                  parent_name: change
                                });
                              }}
                              suggestions={this.props.allCategories}
                              getSuggestions={value =>
                                this._getSuggestions('allCategories', value)
                              }
                              onSuggestionSelected={parent => {
                                setFieldValue('parent_name', parent.label);
                                setFieldValue('parent_id', parent.value);
                                setFieldValue('parentId', parent.value);
                                this.setState({
                                  parent_id: parent.value
                                });
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                  ) : null}
                  {canUpdateCategoriesNameInSupplierProposalModuleField && (
                    <GridItem xs={12}>
                      <Field
                        name="nameInSupplierProposalModule"
                        onChange={handleChange}
                        render={() => {
                          return (
                            <OutlinedTextField
                              id="nameInSupplierProposalModule"
                              name="nameInSupplierProposalModule"
                              label="Nombre en modulo de ingreso de aliados"
                              margin="normal"
                              value={values.nameInSupplierProposalModule}
                              onChange={event => {
                                setFieldValue(
                                  'nameInSupplierProposalModule',
                                  event.target.value.toUpperCase()
                                );
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                  )}
                  {((values.depth === 1 && !values.isNew) ||
                    this.state.parent_id !== null) &&
                  canSetMaxItemsPerCategory ? (
                    <GridItem xs={12}>
                      <Field
                        name={'maxItems'}
                        render={({ field, form }) => (
                          <TextFieldInput
                            value={values.maxItems}
                            label={
                              'Máximo No. de artículos de esta categoría por cliente'
                            }
                            fullWidth
                            field={field}
                            form={form}
                            type="number"
                          />
                        )}
                      />
                    </GridItem>
                  ) : null}
                </Grid>
              </Fragment>
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={!dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Guardar'}
                </ProgressButton>
              </Fragment>
            }
          />
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(CategoryFormModal);
