/* eslint-disable complexity */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import LoadingButton from '../../shared/Buttons/LoadingButton';
import { withStyles } from '@material-ui/core/styles';
import ActivityIndicator from '../../shared/ActivityIndicator';
import CustomDialog from '../../shared/Dialogs/CustomDialog';
import InfoField from '../../shared/InfoField';
import SegmentHeader from '../../shared/SegmentHeader';
import formatMoney from '../../../helpers/formatMoney';
import StepperButtons from '../../shared/StepperButtons';
import { Typography } from '@material-ui/core';
import _get from 'lodash/get';
import { doctypes, PROMISSORY_STATUSES } from '../../../constants/enums';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { removeFirstZeros } from '../../../helpers/utilityFunctions';

import AddQuotasModal from './modals/AddQuotasModal';
import MoveQuotaModal from './modals/MoveQuotaModal';
import GnpGasApplianceInfoModal from './modals/GnpGasApplianceInfoModal';

class PortalContractInformationPage extends Component {
  state = {
    prompt: false,
    isAddQuotasModalOpen: false,
    isMoveQuotaModalOpen: false,
    isGnpGasApplianceInfoModalOpen: false,
    GnpGasApplianceInfoModalCategories: [],
    isGnpSale: false,
    isGasApplianceSale: false
  };
  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    const {
      gnpGasApplianceSaleEvaluation,
      gnpCategories,
      gasApplianceCategories
    } = this.props;
    this.props.setCurrentStep(this.currentStep);
    if (!this.props.ongoingTransfer && !this.props.useScoring) {
      this._fetchContractData();
    }
    if (gnpGasApplianceSaleEvaluation) {
      if (
        gnpGasApplianceSaleEvaluation['gnp_sale']['make_gnp_sale'] &&
        gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_is_gnp'] &&
        gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_has_gnp_articles']
      ) {
        this.setState({
          isGnpSale: true,
          GnpGasApplianceInfoModalCategories: gnpCategories,
          isGnpGasApplianceInfoModalOpen: true
        });
      } else {
        if (
          gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'supplier_has_gas_appliance_articles'
          ] &&
          gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'any_breached_policy'
          ] &&
          !gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'any_no_gas_appliance_policy'
          ] &&
          gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'any_gas_appliance_policy'
          ]
        ) {
          this.setState({
            isGasApplianceSale: true,
            GnpGasApplianceInfoModalCategories: gasApplianceCategories,
            isGnpGasApplianceInfoModalOpen: true
          });
        }
      }
    }
  }
  _fetchContractData = () => {
    this.props.checkSecondStep(
      {
        debtorContractNumber: this.props.debtor_cache.debtor_contract_number,
        debtorIdentification: this.props.debtor_cache.debtor_identification,
        debtorIdentificationType: this.props.debtor_cache
          .debtor_identification_type,
        debtorBirthdate: this.props.debtor_cache.debtor_birthdate,
        process: this.props.process
      },
    );
    this.props.resetAddQuotasData();
    this.props.resetMoveQuotaData();
  };

  _cancelTransfer = () => {
    this.props.cancelTransfer(
      this.props.debtor_cache,
      this.props.cosigner_cache
    );
  };

  _resetTransaction = () => {
    this._toggleDialog(false);
    this.props.goBack();
  };

  _toggleDialog = action => {
    this.setState({ prompt: action });
  };

  onSubmit = () => {
    this.props.history.push(this.props.routes[this.nextStep]);
  };

  mapPolicies = policies => {
    return policies.length > 0
      ? policies.map((policy, key) => {
        return (
          <Typography key={key}>
            {key > 0 ? ', ' + policy.name : policy.name}
          </Typography>
        );
      })
      : 'CUMPLE TODAS LAS POLITICAS';
  };

  _handleOpenModal = type => this.setState({ [`is${type}ModalOpen`]: true });
  _handleCloseModal = type => this.setState({ [`is${type}ModalOpen`]: false });

  _onCloseGasApplianceInfoModal = () => {
    this.setState({ isGnpGasApplianceInfoModalOpen: false });
  };

  render() {
    const {
      prompt,
      isAddQuotasModalOpen,
      isMoveQuotaModalOpen,
      isGnpGasApplianceInfoModalOpen,
      GnpGasApplianceInfoModalCategories,
      isGnpSale,
      isGasApplianceSale
    } = this.state;
    const {
      classes,
      details,
      promissory,
      loading,
      debtorIsHolder,
      location,
      debtor_cache,
      requestCancelTransferSend,
      ongoingTransfer,
      canValidateNewQuotaTransferOnSale,
      canRevokeQuotaTransfer,
      useScoring
    } = this.props;
    if (!details.docid || loading) {
      return <ActivityIndicator />;
    }
    return (
      <div>
        <Paper className={classes.container}>
          <form
            noValidate
            onSubmit={this.onSubmit}
            autoComplete="off"
            className={classes.form}
          >
            {debtorIsHolder &&
              !useScoring &&
              !(isGasApplianceSale || isGnpSale) &&
              location.pathname.includes('/portal/') && (
                <Grid container className={classes.buttonsGrid}>
                  {canValidateNewQuotaTransferOnSale && !ongoingTransfer && (
                    <React.Fragment>
                      <Grid item md={6} xs={12}>
                        <Button
                          onClick={() => this._handleOpenModal('AddQuotas')}
                          classes={{ root: classes.bigButton }}
                        >
                          Realizar unión de cupos
                        </Button>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Button
                          onClick={() => this._handleOpenModal('MoveQuota')}
                          classes={{ root: classes.bigButton }}
                        >
                          Realizar traslado de cupo
                        </Button>
                      </Grid>
                    </React.Fragment>
                  )}
                  {canRevokeQuotaTransfer && ongoingTransfer && (
                    <Grid item md={12} xs={12}>
                      <LoadingButton
                        onClick={() => this._cancelTransfer()}
                        loading={requestCancelTransferSend}
                        classes={{ root: classes.bigButton }}
                      >
                        Rechazar unión/traslado de cupo
                      </LoadingButton>
                    </Grid>
                  )}
                </Grid>
              )}
            <SegmentHeader>DATOS BASICOS</SegmentHeader>
            <Grid container className={classes.infoFieldGrid}>
              <Grid item md={6} xs={12}>
                <InfoField label="Identificación:">
                  {details && details.doctype}{' '}
                  {details && removeFirstZeros(details.docid)}
                </InfoField>
              </Grid>
              <Grid item md={6} xs={12}>
                <InfoField label="Nombre:">{details && details.name}</InfoField>
              </Grid>
              <Grid item md={6} xs={12}>
                <InfoField label="Localidad:">
                  {details && details.locality}
                </InfoField>
              </Grid>
              <Grid item md={6} xs={12}>
                <InfoField label="Departamento:">
                  {details && details.department}
                </InfoField>
              </Grid>
              <Grid item md={6} xs={12}>
                <InfoField label="Teléfono:">
                  {details && details.phone}
                </InfoField>
              </Grid>
              <Grid item md={6} xs={12}>
                <InfoField label="Dirección:">
                  {details && details.address}
                </InfoField>
              </Grid>
              <Grid item md={6} xs={12}>
                <InfoField label="Categoria:">
                  {details && details.category}
                </InfoField>
              </Grid>
              <Grid item md={6} xs={12}>
                <InfoField label="Estrato:">
                  {details && details.subcategory}
                </InfoField>
              </Grid>
            </Grid>
            <SegmentHeader>INFORMACIÓN DE CUPO</SegmentHeader>
            <Grid container className={classes.infoFieldGrid}>
              <Grid item md={6} xs={12}>
                <InfoField label="Cupo bloqueado:">
                  {details && details.creditBlocked}
                </InfoField>
              </Grid>
              <Grid item md={6} xs={12}>
                <InfoField label="Saldo de red:">
                  {details && formatMoney(details.consumptionBalance)}
                </InfoField>
              </Grid>
              <Grid item md={6} xs={12}>
                <InfoField label="Políticas incumplidas:">
                  {details && this.mapPolicies(details.violatedPolicies)}
                </InfoField>
              </Grid>
              <Grid item md={6} xs={12}>
                <InfoField label="Segmentación:">
                  {details && details.segmentation}
                </InfoField>
              </Grid>
            </Grid>
            {this.props.process === 'portal' && (
              <React.Fragment>
                <SegmentHeader>INFORMACIÓN DE PAGARÉ ÚNICO</SegmentHeader>
                <Grid container className={classes.infoFieldGrid}>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Consecutivo:">
                      {promissory && promissory.code ? promissory.code : 'N/A'}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Estado:">
                      {promissory
                        ? PROMISSORY_STATUSES[promissory.status].toUpperCase()
                        : 'N/A'}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Identificación Codeudor:">
                      {_get(promissory, 'cosigner', false) &&
                        promissory.cosigner.identification
                        ? `${promissory.cosigner.type_of_identification.toUpperCase()} ${promissory.cosigner.identification
                        }`
                        : 'N/A'}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Nombre Codeudor:">
                      {_get(promissory, 'cosigner', false)
                        ? promissory.cosigner.name +
                        ' ' +
                        promissory.cosigner.last_name
                        : 'N/A'}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Inmaterializado:">
                      {promissory && promissory.unmaterialized ? 'Sí' : 'No'}
                    </InfoField>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            <div className={classes.actionBar}>
              <StepperButtons
                onSubmit={this.onSubmit}
                customLoading={requestCancelTransferSend}
                goBack={() => this._toggleDialog(true)}
              />
            </div>
          </form>
        </Paper>
        <CustomDialog
          onClose={() => this._toggleDialog(false)}
          onConfirm={() => this._resetTransaction()}
          open={prompt}
          title={'Cancelar Registro'}
          buttonText={'Continuar'}
        >
          <Typography variant="subheading">
            {`Al continuar se cancelará el registro de ${this.props.process === 'portal' ? 'venta' : 'pagaré'
              } y se perderán los datos actuales.`}
          </Typography>
        </CustomDialog>
        {isAddQuotasModalOpen && (
          <AddQuotasModal
            addQuotas={this.props.addQuotas}
            addQuotasData={this.props.addQuotasData}
            serverErrors={this.props.transferErrors}
            resetAddQuotasData={this.props.resetAddQuotasData}
            firstContract={debtor_cache.debtor_contract_number}
            onClose={this._handleCloseModal}
            errors={this.props.requestErrorsOnAddQuotas}
            isSubmitting={this.props.requestAddQuotasSend}
            isFetching={this.props.requestFetchTransferQuotasSend}
            debtor_cache={this.props.debtor_cache}
            fetchTransferQuotas={this.props.fetchTransferQuotas}
            routes={this.props.routes}
          />
        )}
        {isMoveQuotaModalOpen && (
          <MoveQuotaModal
            moveQuota={this.props.moveQuota}
            moveQuotaData={this.props.moveQuotaData}
            serverErrors={this.props.transferErrors}
            resetMoveQuotaData={this.props.resetMoveQuotaData}
            doctypes={mapListToOptions(doctypes, {}, true)}
            onClose={this._handleCloseModal}
            errors={this.props.requestErrorsOnMoveQuota}
            isSubmitting={this.props.requestMoveQuotaSend}
            isFetching={this.props.requestFetchTransferQuotasSend}
            debtor_cache={this.props.debtor_cache}
            fetchTransferQuotas={this.props.fetchTransferQuotas}
            routes={this.props.routes}
          />
        )}
        {(isGasApplianceSale || isGnpSale) &&
          isGnpGasApplianceInfoModalOpen && (
            <GnpGasApplianceInfoModal
              closeModal={this._onCloseGasApplianceInfoModal}
              categories={GnpGasApplianceInfoModalCategories}
            />
          )}
      </div>
    );
  }
}

const styles = theme => ({
  container: {},
  infoFieldGrid: {
    alignItems: 'flex-end',
    marginBottom: 5 * theme.spacing.unit
  },
  buttonsGrid: {
    marginBottom: 2 * theme.spacing.unit
  },
  form: {
    paddingTop: 30
  },
  actionBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  button: {
    alignSelf: 'flex-end',
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  bigButton: {
    width: '100%',
    fontSize: '1rem',
    color: '#db4945'
  }
});

PortalContractInformationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  clearFinancing: PropTypes.func.isRequired,
  details: PropTypes.object,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(PortalContractInformationPage);
