import moment from 'moment-timezone';
import _compact from 'lodash/compact';

export const columns = [
  {
    title: '# Proceso',
    name: 'modelId'
  },
  {
    title: 'Tipo',
    name: 'typeableModel'
  },
  {
    title: 'Fecha',
    name: 'registeredDate'
  },
  {
    title: 'Deudor',
    name: 'customer'
  },
  {
    title: 'Cédula',
    name: 'identification'
  },
  {
    title: '# Contrato',
    name: 'contractId'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      registeredDate:
        moment(datum.registeredAt)
          .locale('es')
          .format('DD MMM YYYY') || '--',
      identification:
        _compact(
          datum.clientTypeOfIdentification == 'Cédula'
            ? [datum.clientIdentification]
            : [datum.clientTypeOfIdentification, datum.clientIdentification]
        ).join(' ') || '--',
      customer:
        _compact([datum.clientName, datum.clientLastName]).join(' ') || '--'
    };
  });
