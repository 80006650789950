/* eslint-disable complexity */
import React, { PureComponent, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { loadModels, getFullFaceDescription } from '../../helpers/face';
import SegmentHeader from './SegmentHeader';
import Webcam from 'react-webcam';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ActivityIndicator from './ActivityIndicator';

const cameraDocFrameFront = require('../../assets/images/frame_front.png');
const cameraDocFrameBack = require('../../assets/images/frame_back.png');
const inputSize = 160;

class Camera extends PureComponent {
  state = {
    videoConstraints: null,
    isFullScreen: null
  };

  interval = null;
  fullScreenRef = React.createRef();

  componentDidMount = async () => {
    await loadModels();
    this.startCapture();
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
    if (this.props.webcamRef.current) {
      const stream = this.props.webcamRef.current.stream;
      if (stream) {
        const tracks = stream.getTracks();

        tracks.forEach(track => track.stop());
        this.props.webcamRef.current.stream = null;
      }
    }
    if (document.fullscreenElement) {
      this.toggleFullScreen();
    }
  };

  startCapture = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const idealDevice = devices
      .filter(device => device.kind === 'videoinput')
      .slice(-1)
      .pop();
    let videoConstraints = {
      width: { min: 1280, ideal: 1920, max: 1920 },
      height: { min: 720, ideal: 1080, max: 1080 }
    };
    if (idealDevice && idealDevice.length > 0) {
      videoConstraints = {
        ...videoConstraints,
        video: { deviceId: { exact: idealDevice[0].deviceId } }
      };
    } else {
      videoConstraints = {
        ...videoConstraints,
        facingMode: { ideal: 'environment' }
      };
    }
    this.setState({
      videoConstraints: videoConstraints
    });
    if (!this.props.capturingVoucher) {
      this.interval = setInterval(() => {
        if (
          this.props.documentShouldBeHorizontal === null &&
          this.props.capturingDocument
        ) {
          this.setDocumentShouldBeHorizontal();
        }
        this.captureForFace();
      }, 1500);
    }
  };

  setDocumentShouldBeHorizontal = () => {
    if (this.props.webcamRef.current) {
      const width = this.props.webcamRef.current.video.offsetParent.offsetWidth;
      const height = this.props.webcamRef.current.video.offsetParent
        .offsetHeight;
      if (height > width) {
        this.props.setDocumentShouldBeHorizontal(true);
      } else {
        this.props.setDocumentShouldBeHorizontal(false);
      }
    }
  };

  toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      this.fullScreenRef.current.requestFullscreen();
      this.setState({ isFullScreen: true });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        this.setState({ isFullScreen: false });
      }
    }
  };

  captureForFace = async () => {
    if (
      (this.props.webcamRef.current &&
        this.props.capturingDocument &&
        this.props.isBackCapturedDebtorDocument) ||
      (this.props.webcamRef.current && !this.props.capturingDocument)
    ) {
      const imageSrc = this.props.webcamRef.current.getScreenshot();
      await getFullFaceDescription(imageSrc, inputSize).then(fullDesc => {
        if (fullDesc) {
          if (this.props.captureValid) {
            this.props.setCameraDetections(fullDesc.map(fd => fd.detection));
          } else {
            const landmarks = fullDesc.map(fd => fd.landmarks)[0];
            if (landmarks) {
              const happyScore = fullDesc.map(fd => fd.expressions)[0].happy;
              if (happyScore > 0.9) {
                this.props.silentCapture(imageSrc);
              }
              this.props.setCameraDetections(
                fullDesc.map(fd => fd.detection),
                happyScore > 0.9
              );
            }
          }
        }
      });
    }
  };

  getValidStatusWhenBackCaptured = () => {
    return (
      this.props.isBackCapturedDebtorDocument &&
      this.getDisabledAndCaptureValidStatus()
    );
  };
  getDisabledAndCaptureValidStatus = () => {
    return this.props.disabledNext || !this.props.captureValid;
  };

  render() {
    const {
      classes,
      isFrontCapturedDebtorDocument = false,
      isBackCapturedDebtorDocument = false,
      captureValid,
      detections,
      disabledNext,
      webcamRef,
      documentShouldBeHorizontal = null,
      capture,
      capturingDocument,
      capturingVoucher = false
    } = this.props;

    const minScreenshotWidth = capturingDocument
      ? isBackCapturedDebtorDocument
        ? 600
        : 960
      : 600;

    const extension = capturingDocument
      ? isBackCapturedDebtorDocument
        ? 'jpeg'
        : 'png'
      : 'jpeg';
    return (
      <Fragment>
        {capturingDocument &&
          (isFrontCapturedDebtorDocument ? (
            isBackCapturedDebtorDocument ? (
              <SegmentHeader className={classes.segmentHeader}>
                {'Favor tomar captura del rostro del cliente'}
              </SegmentHeader>
            ) : (
              <SegmentHeader className={classes.segmentHeader}>
                {
                  'Favor tomar captura de la parte trasera del documento de identidad'
                }
              </SegmentHeader>
            )
          ) : (
            <SegmentHeader className={classes.segmentHeader}>
              {
                'Favor tomar captura de la parte frontal del documento de identidad'
              }
            </SegmentHeader>
          ))}
        {this.state.videoConstraints ? (
          <Fragment>
            <div
              ref={this.fullScreenRef}
              className={
                capturingDocument
                  ? this.getValidStatusWhenBackCaptured()
                    ? classes.cameraWrapperDisabled
                    : classes.cameraWrapper
                  : this.getDisabledAndCaptureValidStatus()
                    ? classes.cameraWrapperDisabled
                    : classes.cameraWrapper
              }
            >
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat={'image/' + extension}
                screenshotQuality={1}
                minScreenshotWidth={minScreenshotWidth}
                width="500"
                height="500"
                style={{
                  minHeight: '100%',
                  width: '100%',
                  objectFit: 'cover'
                }}
                videoConstraints={this.state.videoConstraints}
              />
              {this.state.isFullScreen ? (
                <Fragment>
                  <FullscreenExitIcon
                    className={classes.fullScreenIcon}
                    fontSize="large"
                    onClick={() => {
                      this.toggleFullScreen();
                    }}
                  />
                  <FiberManualRecordIcon
                    className={classes.captureIcon}
                    color={
                      capturingDocument
                        ? this.getValidStatusWhenBackCaptured()
                          ? 'disabled'
                          : 'primary'
                        : this.getDisabledAndCaptureValidStatus()
                          ? 'disabled'
                          : 'primary'
                    }
                    onClick={() => {
                      this.toggleFullScreen();
                      capture();
                    }}
                  />
                </Fragment>
              ) : (
                <FullscreenIcon
                  className={classes.fullScreenIcon}
                  fontSize="large"
                  onClick={() => {
                    this.toggleFullScreen();
                  }}
                />
              )}
              {!isBackCapturedDebtorDocument &&
                documentShouldBeHorizontal !== null && (
                  <img
                    className={
                      documentShouldBeHorizontal
                        ? classes.frameHorizontal
                        : classes.frame
                    }
                    src={
                      isFrontCapturedDebtorDocument
                        ? cameraDocFrameBack
                        : cameraDocFrameFront
                    }
                  />
                )}
              <span className={classes.overlayText}>
                {capturingDocument
                  ? isBackCapturedDebtorDocument &&
                  (!captureValid
                    ? 'Favor sonreir a la cámara'
                    : disabledNext &&
                    (detections
                      ? detections.length > 1
                        ? 'Solo debe haber una persona'
                        : 'No hay personas'
                      : 'No hay personas'))
                  : !captureValid
                    ? 'Favor sonreir a la cámara'
                    : disabledNext &&
                    (detections
                      ? detections.length > 1
                        ? 'Solo debe haber una persona'
                        : 'No hay personas'
                      : 'No hay personas')}
              </span>
            </div>
            <canvas id="c" style={{ display: 'none' }} />
          </Fragment>
        ) : (
          <ActivityIndicator className={classes.loadingIndicator} />
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  cameraWrapper: {
    position: 'relative',
    border: '5px solid #97D077',
    lineHeight: 0
  },
  cameraWrapperDisabled: {
    position: 'relative',
    border: '5px solid #CCCCCC',
    lineHeight: 0
  },
  overlayText: {
    position: 'absolute',
    fontSize: 20,
    textAlign: 'center',
    width: '100%',
    bottom: theme.spacing.unit,
    left: 0,
    lineHeight: '16px',
    color: '#FFF'
  },
  frame: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    maxWidth: '90%',
    maxHeight: '90%',
    transform: 'translate(-50%,-50%)'
  },
  frameHorizontal: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    maxWidth: '68vh',
    maxHeight: '68vw',
    transform: 'translate(-50%,-50%) rotate(90deg)'
  },
  fullScreenIcon: {
    color: 'white',
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 20
  },
  captureIcon: {
    fontSize: 60,
    cursor: 'pointer',
    position: 'absolute',
    left: '50%',
    bottom: 20,
    transform: 'translate(-50%,0)'
  }
});

export default withStyles(styles)(Camera);
