import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import combineStyles from '../../../../helpers/combineStyles';
import TypographyStyles from '../../../../styles/typography';
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import DetailsStyles from './Details.styles';
import { SALE_TYPES } from '../../../../constants/enums';
import _isEmpty from 'lodash/isEmpty';

import {
  _moneyFormat,
  toCapitalize
} from '../../../../helpers/utilityFunctions';
import { formatData } from './ArticlesTableMetadata';
import moment from 'moment-timezone';

import ArticlesTable from './ArticlesTable';

export class Details extends Component {
  static propTypes = {
    sale: PropTypes.object.isRequired
  };

  _invoiceList = classes => {
    const { sale } = this.props;
    return (
      <div>
        {this._invoiceItem(sale.firstInvoiceId, sale.firstInvoiceDate, classes)}
        {sale.secondInvoiceId != null &&
          sale.secondInvoiceId != 0 &&
          sale.secondInvoiceId != -1 &&
          this._invoiceItem(
            sale.secondInvoiceId,
            sale.secondInvoiceDate,
            classes
          )}
      </div>
    );
  };

  _invoiceItem = (code, date, classes) => {
    return (
      <div className={classnames(classes.detailText)}>
        <strong>Número: </strong> {code}
        {date && (
          <div>
            <strong>Fecha: </strong>
            {moment(date)
              .locale('es')
              .format('DD MMM YYYY')}
          </div>
        )}
      </div>
    );
  };

  _getSaleType = type => {
    return SALE_TYPES.find(item => item.id == type).name;
  };

  render() {
    const { classes, sale } = this.props;
    const extraQuotaName = !_isEmpty(sale.extraQuotum) ? sale.extraQuotum.id !== sale.scoringExtraQuotaId && sale.scoringResponseId !== null ?
      `${sale.scoringExtraQuotaName} + ${sale.extraQuotum.name}` :
      sale.extraQuotum.name : '';

    return (
      <div className={classes.root}>
        <Fragment>
          <div
            className={classnames(
              classes.titleRootWrapper,
              classes.titleContainer
            )}
          >
            <h2 className={classnames(classes.headline7, classes.mainTitle)}>
              {'Detalle de la Venta'}
            </h2>
          </div>

          <div className={classes.grayContainer}>
            <p className={classnames(classes.headline6, classes.carrierName)}>
              <strong>{'Contrato: '}</strong>
              {sale.contractId}
            </p>
            <div className={classes.selectedRate}>
              <div className={classes.carrierInfoContainer}>
                {sale.firstInvoiceId != null &&
                  sale.firstInvoiceId != 0 &&
                  sale.firstInvoiceId != -1 && (
                    <div>
                      <h2 className={classnames(classes.detailText)}>
                        <strong>{'Facturas:'}</strong>
                      </h2>
                      <div className={classnames(classes.date_item)}>
                        {this._invoiceList(classes)}
                      </div>
                    </div>
                  )}
                {sale.invoiceValidationSkipped && (
                  <div>
                    <h2 className={classnames(classes.detailText)}>
                      <strong>{'Facturas:'}</strong> Validación Omitida
                    </h2>
                  </div>
                )}
                {sale.voucher && (
                  <p className={classnames(classes.detailText)}>
                    <strong>{'Voucher: '}</strong>
                    {sale.voucher || '--'}
                  </p>
                )}
                {sale.saleChannel && (
                  <p className={classnames(classes.detailText)}>
                    <strong>{'Canal de venta: '}</strong>
                    {sale.saleChannel || '--'}
                  </p>
                )}
              </div>
              <div className={classes.priceDetailsContainer}>
                <div className={classnames(classes.detailText)}>
                  <strong>{'Cupo disponible en la venta: '}</strong>
                  {_moneyFormat(sale.availableQuotaInSale) || '--'}
                </div>
                <div className={classnames(classes.detailText)}>
                  <strong>{'Cuota Inicial: '}</strong>
                  {_moneyFormat(sale.downPayment)}
                </div>
                <div className={classnames(classes.detailText)}>
                  <strong>{'Valor Financiado: '}</strong>
                  {_moneyFormat(sale.totalValue - sale.downPayment)}
                </div>
                {sale.invoiceValue && sale.invoiceValue !== (sale.totalValue - sale.downPayment) && (
                  <div className={classnames(classes.detailText)}>
                    <strong>{'Valor Facturado: '}</strong>
                    {_moneyFormat(sale.invoiceValue)}
                  </div>
                )}
                {sale.megacupoResponseId && sale.registeredFromSb && (
                  <div className={classnames(classes.detailText)}>
                    <strong>Consulta Megacupo: </strong>
                    {'Sí'}
                  </div>
                )}
                {sale.extraQuotum && sale.registeredFromSb && (
                  <div className={classnames(classes.detailText)}>
                    <strong>Extra cupo usado: </strong>
                    {toCapitalize(extraQuotaName)}
                  </div>
                )}
                {sale.extraUsedQuota > 0 && sale.registeredFromSb && (
                  <div className={classnames(classes.detailText)}>
                    <strong>{`Valor usado de ${sale.megacupoResponseId && !sale.extraQuotum ? 'Mega' : 'extra '}cupo: `}</strong>
                    {_moneyFormat(sale.extraUsedQuota)}
                  </div>
                )}
                {sale.transferredQuota > 0 && (
                  <div className={classnames(classes.detailText)}>
                    <strong>{'Valor Trasladado: '}</strong>
                    {_moneyFormat(sale.transferredQuota)}
                  </div>
                )}
                {sale.identityValidationMethod &&
                  sale.identityValidationMethod != 'no_validation' && (
                    <div className={classnames(classes.detailText)}>
                      <strong>{'Validación de identidad: '}</strong>
                      {sale.identityValidationMethod}
                    </div>
                  )}
                <div className={classnames(classes.detailText)}>
                  <strong>{'Tipo de venta: '}</strong>
                  {this._getSaleType(sale.saleType)}
                </div>
                {sale.campaignName && <div className={classnames(classes.detailText)}>
                  <strong>{'Campaña: '}</strong>
                  {sale.campaignName}
                </div>}
              </div>
            </div>
          </div>
          <ArticlesTable
            rows={formatData(sale.saleDetails)}
            title="Resumen de venta"
            total={sale.totalValue}
          />
        </Fragment>
      </div>
    );
  }
}

export default withStyles(
  combineStyles(DetailsStyles, TypographyStyles, LayoutStyles, ButtonStyles)
)(Details);
