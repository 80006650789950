import {
  SET_INVENTORY,
  QUERY_INVENTORY_FILTERED,
  QUERY_INVENTORY_CATEGORIES,
  SET_SELECTED_PRODUCT,
  SET_STORE,
  OPEN_QUERY_INVENTORY_MODAL,
  CLOSE_QUERY_INVENTORY_MODAL,
  RESET_QUERY_DATA
} from '../constants/query_inventory_constants';

const initialState = {
  inventory: {},
  filteredInventory: [],
  selectedProduct: {},
  store: 'electroao',
  isQueryInventoryModalOpen: false,
  hasSearched: false,
  categories: []
};

export function queryInventory(state = initialState, action) {
  switch (action.type) {
    case SET_INVENTORY:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [action.data.store]: action.data.inventory
        }
      };
    case QUERY_INVENTORY_FILTERED:
      return {
        ...state,
        filteredInventory: action.data,
        hasSearched: true
      };
    case QUERY_INVENTORY_CATEGORIES:
      return {
        ...state,
        categories: action.data
      };
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.data
      };
    case SET_STORE:
      return {
        ...state,
        store: action.data
      };
    case OPEN_QUERY_INVENTORY_MODAL:
      return {
        ...state,
        isQueryInventoryModalOpen: true
      }
    case CLOSE_QUERY_INVENTORY_MODAL:
      return {
        ...state,
        isQueryInventoryModalOpen: false
      }
    case RESET_QUERY_DATA:
      return {
        ...state,
        hasSearched: initialState.hasSearched,
        filteredInventory: initialState.filteredInventory,
        selectedProduct: initialState.selectedProduct
      }
    default:
      return state;
  }
}
