import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import SegmentHeader from '../../shared/SegmentHeader';
import { Formik } from 'formik';
import { validationSchema } from './TeleshoppingRegistrationValidation';
import StepperButtons from '../../shared/StepperButtons';

class TeleshoppingRegistration extends Component {
  state = {
    contract: '',
    verification_sale: ''
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.baseErrorOpen !== this.props.baseErrorOpen &&
      !this.props.baseErrorOpen
    ) {
      this.formik.resetForm(this.state);
    }
  }

  onSubmit = values => {
    const { contract, verification_sale } = values;
    const { process, routes } = this.props;
    const params = {
      contract,
      verification_sale,
      observations: null,
      process
    };

    this.props.validateContractToTeleshopping(
      params,
      routes[this.nextStep],
      process
    );
  };

  render() {
    const { classes, serverErrors = {}, isBlocked = false, isBlackListActive } = this.props;
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            ref={ref => (this.formik = ref)}
            enableReinitialize={true}
            initialValues={this.state}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            render={({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange
            }) => (
              <form autoComplete="off" className={classes.form}>
                <SegmentHeader className={classes.segmentHeader}>
                  DATOS DEL CONTRATO
                </SegmentHeader>
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item sm={6} xs={12}>
                    <OutlinedTextField
                      label="Número de contrato:"
                      name="contract"
                      type={'number'}
                      error={
                        (touched.contract && !!errors.contract) ||
                        (serverErrors && !!serverErrors.contract_id)
                      }
                      helperText={
                        (touched.contract && errors.contract) ||
                        (serverErrors && serverErrors.contract_id)
                      }
                      value={values.contract}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OutlinedTextField
                      label="Número de venta:"
                      name="verification_sale"
                      type={'number'}
                      error={
                        (touched.verification_sale &&
                          !!errors.verification_sale) ||
                        (serverErrors && !!serverErrors.verification_sale_id)
                      }
                      helperText={
                        (touched.verification_sale &&
                          errors.verification_sale) ||
                        (serverErrors && serverErrors.verification_sale_id)
                      }
                      value={values.verification_sale}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <div className={classes.spacer} />
                <StepperButtons
                  onSubmit={handleSubmit}
                  process={'teleshopping'}
                  disabledNext={ isBlocked && isBlackListActive }
                />
              </form>
            )}
          />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  }
});

export default withStyles(styles)(TeleshoppingRegistration);
