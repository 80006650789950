const styles = theme => ({
  toolbar: {
    backgroundColor: process.env.REACT_APP_SENTRY_DSN
      ? theme.palette.primary.main : '#0071d9',
    height: 64
  },
  header: {
    marginTop: 10,
    marginLeft: 20,
    height: 40,
    width: 90
  }
});
  
export default styles;