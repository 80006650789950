import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import LoginLayout from '../../shared/LoginLayout/LoginLayout';
import LoginForm from './LoginForm';
import LoginPageStyles from './LoginPage.styles';

const recaptchaRef = React.createRef();

class LoginPage extends React.Component {
  state = {
    email: '',
    password: '',
    warningCaps: false,
    sitekey: process.env.SITE_KEY_CAPTCHA_INVISIBLE_V2
  };

  componentDidMount() {
    this.props.fetchSessionData();
    this.props.recaptchaValidationActivation();
  }

  _resetRecaptcha = () => recaptchaRef.current.reset();

  _handleSubmit = (email, password) => async e => {
    e.preventDefault();
    const { login, loading, isRecaptchaValidationActive } = this.props;

    if (!loading && email && password) {
      if (!isRecaptchaValidationActive) {
        login(email, password);
        return;
      }
      const token = await recaptchaRef.current.executeAsync();

      this.props.confirmTokenRecaptchaInvisible({ token }).then(response => {
        if (response.success) {
          login(email, password).then(
            response => !response && this._resetRecaptcha()
          );

          return;
        }

        this._resetRecaptcha();
      });
    }
  };

  render() {
    const {
      loading,
      loadingRecaptchaInvisible,
      isRecaptchaValidationActive
    } = this.props;

    return (
      <LoginLayout>
        <LoginForm
          loading={loading}
          loadingRecaptchaInvisible={loadingRecaptchaInvisible}
          isRecaptchaValidationActive={isRecaptchaValidationActive}
          recaptchaRef={recaptchaRef}
          handleSubmit={this._handleSubmit}
        />
      </LoginLayout>
    );
  }
}

const styles = theme => ({
  check: {
    marginLeft: 0,
    marginTop: theme.spacing.unit * 2.5
  },
  alertContainer: {
    marginTop: theme.spacing.unit * 2.5,
    marginBottom: -theme.spacing.unit * 2
  }
});

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  newAlert: PropTypes.func,
  classes: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(styles, LoginPageStyles, ButtonStyles, TypographyStyles)
)(LoginPage);
