import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import { Formik } from 'formik';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import * as Yup from 'yup';
import _isEmpty from 'lodash/isEmpty';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { SUPPLIER_PROPOSAL_SIGNER_TYPES, doctypes } from '../../../constants/enums';

const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required('El campo es requerido'),
  identificationType: Yup.string().nullable().required('El campo es requerido'),
  identification: Yup.number().nullable().required('El campo es requerido').min(
    1, 'Ingresa una identificación válida'
  ),
  email: Yup.string()
    .nullable()
    .required('El campo es requerido')
    .email('Debe ingresar un email válido.'),
  phone: Yup.number().nullable().required('El campo es requerido').min(
    1, 'Ingresa un teléfono válido'
  ).test(
    'phoneHasCorrectLength',
    'El teléfono debe tener 7 o 10 dígitos',
    value => {
      return !!value && [7,10].includes(value.toString().length);
    }
  ),
  signerType: Yup.number().nullable().required('El campo es requerido'),
});

const ProductModal = props => {
  const {
    open,
    classes,
    onClose,
    onSubmit,
    isSubmitting,
    signer,
  } = props;
  const action = !!signer.id ? 'update' : 'create';
  const dialogTitle = action === 'create' ? 'Crear firmante y/o revisor' : 'Editar firmante y/o revisor';
  return (
    <Formik
      initialValues={{
        id: signer.id,
        name: signer.name || '',
        identificationType: signer.identificationType || '',
        identification: signer.identification || '',
        email: signer.email || '',
        phone: signer.phone || '',
        signerType: signer.signerType || '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={
        ({
          values,
          handleSubmit,
          handleChange,
          touched,
          handleBlur,
          errors,
          handleReset,
        }) => {
          const _onClose = () => {
            handleReset();
            onClose();
          };
          return (
            <Dialog open={open} onClose={_onClose}>
              <DialogTitle>{dialogTitle}</DialogTitle>
              <DialogContent className={classes.productModalDialogContent}>
                <OutlinedTextField
                  name='name'
                  label='Nombre completo'
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.name && errors.name}
                  error={touched.name && !!errors.name}
                />
                <SimpleSelect
                  name='identificationType'
                  label='Tipo de identificación'
                  options={mapListToOptions(doctypes, {}, true)}
                  value={values.identificationType}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.identificationType && errors.identificationType}
                  error={touched.identificationType && !!errors.identificationType}
                />
                <OutlinedTextField
                  name='identification'
                  label='Identificación'
                  type='number'
                  value={values.identification}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.identification && errors.identification}
                  error={touched.identification && !!errors.identification}
                />
                <OutlinedTextField
                  name='email'
                  label='Correo electrónico'
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.email && errors.email}
                  error={touched.email && !!errors.email}
                />
                <OutlinedTextField
                  name='phone'
                  label='Teléfono'
                  type='number'
                  value={values.phone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.phone && errors.phone}
                  error={touched.phone && !!errors.phone}
                />
                <SimpleSelect
                  name='signerType'
                  label='Tipo'
                  options={mapListToOptions(SUPPLIER_PROPOSAL_SIGNER_TYPES, {}, false)}
                  value={values.signerType}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.signerType && errors.signerType}
                  error={touched.signerType && !!errors.signerType}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={_onClose} color="secondary">
                  Cancelar
                </Button>
                <ProgressButton
                  color="secondary"
                  onClick={ () => {
                    handleSubmit({
                      ...values,
                      identification: values.identification === ''
                        ? null : values.identification,
                      phone: values.phone === ''
                        ? null : values.phone,
                      signerType: values.signerType === ''
                        ? null : values.signerType,
                    });
                    if (_isEmpty(errors) && !_isEmpty(touched)) {
                      setTimeout(handleReset, 1000);
                    }
                  }}
                  isSubmitting={isSubmitting}
                >
                  Guardar
                </ProgressButton>
              </DialogActions>
            </Dialog>
          );
        }
      }
    />
  );
};

export default ProductModal;