import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AspiringSupplierSignUpPage from './AspiringSupplierSignUpPage';
import { submit } from '../../../actions/aspiring_supplier_sign_up_page_actions';
import { confirmTokenRecaptchaInvisible } from '../../../actions/session_actions';
import { newAlert } from '../../../actions/alert_actions';

function mapStateToProps(state) {
  return {
    loading: state.requestState.requestAspiringSupplierSignUpSend,
    loadingRecaptcha: state.requestState.requestConfirmTokenRecaptchaInvisibleSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submit: bindActionCreators(submit, dispatch),
    confirmToken: bindActionCreators(confirmTokenRecaptchaInvisible, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AspiringSupplierSignUpPage);