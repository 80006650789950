import moment from 'moment-timezone';

export const CUSTOM_DATE = 'pickCustomDate';

export const typeableModelOptions = [
  { value: 'todas', title: 'Todas' },
  { value: 'Promissory', title: 'Pagarés' },
  { value: 'Sale', title: 'Ventas' }
];

export const originalDateOptions = [
  { value: 'todas', title: 'Todas' },
  { value: moment().format('YYYY-MM-DD'), title: 'Hoy' },
  {
    value: `${moment()
      .startOf('week')
      .format('YYYY-MM-DD')} - ${moment()
      .endOf('week')
      .format('YYYY-MM-DD')}`,
    title: 'Esta semana'
  },
  {
    value: `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')} - ${moment()
      .endOf('month')
      .format('YYYY-MM-DD')}`,
    title: 'Este mes'
  }
];

export const dateOptions = (customDate, registered_at) => [
  ...originalDateOptions,
  {
    value: customDate !== CUSTOM_DATE ? registered_at : CUSTOM_DATE,
    title: customDate !== CUSTOM_DATE ? registered_at : 'Personalizada'
  }
];
