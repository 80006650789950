import _pickBy from 'lodash/pickBy';
import { ANI_STATUS, DC_GENDERS } from '../../constants/enums';
import moment from 'moment';
import _get from 'lodash/get';

export const identificationSanitizer = (values, debtor, cosigner) => {
  const sanitizedFilters = {
    debtor: {
      identification: values.debtorID.toString(),
      typeOfIdentification: values.debtorDocType,
      lastname: values.debtorLastName,
      contractId: debtor.contract,
      dateOfBirth: debtor.birthdate
    },
    cosigner: values.cosignerID ? {
      identification: values.cosignerID.toString(),
      typeOfIdentification: values.cosignerDocType,
      lastname: values.cosignerLastName,
      contractId: cosigner.contract,
      dateOfBirth: cosigner.birthdate
    } : null
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};

export const debtorIdentificationSanitizer = debtorResult => {
  const expirationDate = debtorResult.expiration_date || _get(debtorResult, 'documento.fecha_vencimiento');

  const sanitizedFilters = {
    id_number: debtorResult.identification || '--',
    id_department: debtorResult.expedition_department || '--',
    id_locality: debtorResult.expedition_city || '--',
    id_name: debtorResult.name || '--',
    id_first_last_name: debtorResult.last_name || '--',
    id_second_last_name: debtorResult.second_last_name || '--',
    id_expiration_date: expirationDate ? moment(expirationDate).format('YYYY-MM-DD') : '--',
    id_country: debtorResult.country || '--',
    id_gender: debtorResult.gender || '--',
    id_expedition_date: debtorResult.expedition_date
      ? moment(debtorResult.expedition_date).format('YYYY-MM-DD')
      : '--',
    id_status: debtorResult.status
      ? ANI_STATUS[debtorResult.status]
      : 'NO ENCONTRADO'
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};

export const cosignerIdentificationSanitizer = cosignerResult => {
  const expirationDate = cosignerResult.expiration_date || _get(cosignerResult, 'documento.fecha_vencimiento');

  const sanitizedFilters = {
    dc_cosigner_number: cosignerResult.identification || '--',
    dc_cosigner_department: cosignerResult.expedition_department || '--',
    dc_cosigner_locality: cosignerResult.expedition_city || '--',
    dc_cosigner_name: cosignerResult.name || '--',
    dc_cosigner_first_last_name: cosignerResult.last_name || '--',
    dc_cosigner_second_last_name: cosignerResult.second_last_name || '--',
    dc_country: cosignerResult.country || '--',
    dc_gender: cosignerResult.gender || '--',
    dc_cosigner_gender: cosignerResult.gender
      ? DC_GENDERS[cosignerResult.gender]
      : '--',
    dc_expiration_date: expirationDate ? moment(expirationDate).format('YYYY-MM-DD') : '--',
    dc_cosigner_expedition_date: cosignerResult.expedition_date
      ? moment(cosignerResult.expedition_date).format('YYYY-MM-DD')
      : '--',
    dc_cosigner_status: cosignerResult.status
      ? ANI_STATUS[cosignerResult.status]
      : 'NO ENCONTRADO'
  };
  return sanitizedFilters;
};
