import { Divider, Grid, Paper, withStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { default as React } from 'react';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import SpacingStyles from '../../../styles/helpers/spacing';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import ListsStyle from '../__styles__/Lists.style';

class ProcessErrors extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, errors } = this.props;
    const numErrors = errors ? errors.length : 0;

    return (
      <div style={{ overflowY: 'scroll', maxHeight: '400px' }}>
        {errors.map(error => (
          <Paper key={error.id} elevation={0} className={classes.errorSection}>
            <Grid
              container
              alignItems="flex-start"
              style={{ paddingTop: '8px' }}
            >
              <Grid
                item
                xs="auto"
                style={{ paddingLeft: '16px', paddingRight: '16px' }}
              >
                <ErrorOutline />
              </Grid>
              <Grid item xs>
                <strong>{error.observation}</strong>
                <p>
                  <strong>Datos:</strong> {error.data}
                </p>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </div>
    );
  }
}

const styles = theme => ({
  errorSection: {
    background: 'rgba(219,73,69,0.08)',
    padding: '8px',
    marginBottom: '16px',
    color: 'rgb(163, 54, 51)'
  }
});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(ProcessErrors);
