import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { history } from '../../../helpers/history';
import UserFilters from './UserFilters';
import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';

import UserCreateEdit from './modals/UserCreateEdit';

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class UsersList extends React.Component {
  state = {
    isOpenedCreateUserModal: false,
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE
  };

  componentDidMount() {
    this._fetchItems();
    if (!this.props.suppliers) {
      this.props.fetchOrgData();
    }

    if (this.props.canUpdateRoles) {
      this.props.fetchAllRoles();
    }
    this.props.fetchRoles();
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};

    this.props.fetchUsers({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    }, this.props.location.pathname);
  };

  _handleRowClick = id => {
    history.push(`/users/${id}`);
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleOpenCreateUserModal = () =>
    this.setState({ isOpenedCreateUserModal: true });
  _handleCloseCreateUserModal = () => {
    this.setState({ isOpenedCreateUserModal: false });
    this.props.completeRequest('NewUser');
  };
  _handleValidateUser = userDetails => this.props.validateUser(userDetails);

  _handleSaveUser = async userDetails => {
    await this.props.createUser(userDetails).then(isSuccess => {
      if (isSuccess) {
        this._fetchItems();
        this._handleCloseCreateUserModal();
        this.props.clearCreationInfo();
      }
    });
  };

  render() {
    const {
      classes,
      currentUser,
      users = [],
      meta = {},
      requestSend,
      roles
    } = this.props;
    const isBlackList = this.props.location.pathname === '/black-list';
    const { page } = parseQuerystring(this.props) || {};

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {!isBlackList ? 'Usuarios' : 'Lista negra de Asesores'}
          </h1>
          {(currentUser.canCreateUsers ||
            currentUser.canCreateSellers ||
            currentUser.canCreateNonAdminUsers) &&
            currentUser.canValidateUsers && !isBlackList &&
            roles.length !== 0 && (
              <Button
                className={classes.button}
                classes={{
                  label: classes.buttonLabel
                }}
                variant="contained"
                color="secondary"
                onClick={this._handleOpenCreateUserModal}
              >
                {'Crear Usuario'}
              </Button>
            )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <UserFilters
              onChangeFilter={this._fetchItems}
              suppliers={this.props.suppliers}
              isBlackList={isBlackList}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Usuario'}
            columns={columns}
            data={mapData(users)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props => this._handleRowClick(props.id)}
            totalCount={(meta && meta.total_count) || 0}
            loading={requestSend}
          />
        </div>
        {this.state.isOpenedCreateUserModal && (
          <UserCreateEdit
            osfMaxIdLength={this.props.osfMaxIdLength}
            currentUser={this.props.currentUser}
            serverErrors={this.props.serverErrors}
            freelanceRoles={this.props.freelanceRoles}
            hugeSurfaceCoordinatorRoles={this.props.hugeSurfaceCoordinatorRoles}
            newAlert={this.props.newAlert}
            userRoles={currentUser.roles}
            suppliers={this.props.suppliers}
            onClose={this._handleCloseCreateUserModal}
            onValidate={this._handleValidateUser}
            onSave={this._handleSaveUser}
            errors={this.props.requestErrorsOnNewUser}
            isSubmitting={this.props.requestNewUserSend}
            creationInfo={this.props.creationInfo}
            setCreationInfo={this.props.setCreationInfo}
            clearCreationInfo={this.props.clearCreationInfo}
            userCreateError={this.props.userCreateError}
            userClearError={this.props.userClearError}
            roles={this.props.roles}
            salesmanRolesIds={this.props.salesmanRolesIds}
          />
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

UsersList.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(UsersList);
