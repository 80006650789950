import * as React from 'react';
import * as PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

export const ColumnChooserItem = ({
  item: { column, hidden },
  disabled, onToggle,
  classes,
  ...restProps
}) => (
  <ListItem
    key={column.name}
    button={!disabled}
    disabled={disabled}
    onClick={!disabled ? onToggle : null}
    classes={{
      root: classes.listItemRoot
    }}
    {...restProps}
  >
    <Checkbox
      checked={!hidden}
      tabIndex={-1}
      disableRipple
      disabled={disabled}
      style={{ width: 'auto', height: 'auto', padding: 0}}
    />
    <ListItemText primary={column.title || column.name} classes={{primary: classes.listItemTextRoot}}/>
  </ListItem>
);

ColumnChooserItem.propTypes = {
  item: PropTypes.shape({
    column: PropTypes.shape({
      name: PropTypes.string,
    }),
    hidden: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  classes: PropTypes.object,
};

ColumnChooserItem.defaultProps = {
  onToggle: () => { },
  disabled: false,
};

export default ColumnChooserItem;
