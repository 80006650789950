import {
  QUERY_SCORING_QUOTAS,
  OPEN_QUERY_SCORING_QUOTA_MODAL,
  CLOSE_QUERY_SCORING_QUOTA_MODAL,
  RESET_QUERY_SCORING_DATA
} from '../constants/query_scoring_quota_constants';

const initialState = {
  scoringQuotas: [],
  isQueryScoringQuotaModalOpen: false,
  hasSearched: false
};

export function queryScoringQuotas(state = initialState, action) {
  switch (action.type) {
    case QUERY_SCORING_QUOTAS:
      return {
        ...state,
        scoringQuotas: action.data,
        hasSearched: true
      };
    case OPEN_QUERY_SCORING_QUOTA_MODAL:
      return {
        ...state,
        isQueryScoringQuotaModalOpen: true
      }
    case CLOSE_QUERY_SCORING_QUOTA_MODAL:
      return {
        ...state,
        isQueryScoringQuotaModalOpen: false
      }
    case RESET_QUERY_SCORING_DATA:
      return {
        ...state,
        scoringQuotas: initialState.promissories,
        hasSearched: initialState.hasSearched
      }
    default:
      return state;
  }
}
