import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import InfoField from '../../../shared/InfoField';
import formatMoney from '../../../../helpers/formatMoney';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';
import AddQuotasValidation from './AddQuotas.validation';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  infoFieldContainer: {
    marginTop: '1rem'
  },
  infoField: {
    width: '95%'
  },
  gridItem: {
    padding: '0 !important'
  },
});

export class AddQuotasModal extends Component {
  state = {
    supplier: '',
    selectedSupplier: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnSave = async contracts => {
    const { addQuotas } = this.props;
    addQuotas(contracts, this.props.debtor_cache, this.props.routes[2]);
  };

  _handleOnClose = () => {
    const { onClose, resetAddQuotasData } = this.props;
    onClose('AddQuotas');
    resetAddQuotasData();
  };

  _handleNewQuery = () => {
    const { resetAddQuotasData } = this.props;
    this.formik.resetForm();
    resetAddQuotasData();
  }

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  _queryContracts = contracts => {
    this.props.fetchTransferQuotas(contracts, this.props.debtor_cache, 'union');
  };

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      isFetching,
      firstContract,
      addQuotasData,
      serverErrors
    } = this.props;
    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        validationSchema={AddQuotasValidation}
        initialValues={{
          firstContract,
          secondContract: '',
          thirdContract: ''
        }}
        onSubmit={values => {
          this._queryContracts(values);
        }}
        onReset={actions => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          errors,
          touched,
          dirty
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              footer: classes.modalFooter
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {'Unión de cupos'}
                  </p>
                </div>
                <Grid container spacing={16}>
                  <GridItem xs={12} className={classes.gridItem}>
                    <OutlinedTextField
                      disabled
                      label={'Primer Contrato'}
                      name={'firstContract'}
                      type={'number'}
                      value={values.firstContract}
                      onChange={handleChange}
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} className={classes.gridItem}>
                    <OutlinedTextField
                      label={'Segundo Contrato'}
                      name={'secondContract'}
                      type={'number'}
                      value={values.secondContract}
                      onChange={handleChange}
                      fullWidth
                      error={
                        (touched.secondContract && !!errors.secondContract) ||
                        (serverErrors && !!serverErrors.secondContract)
                      }
                      helperText={
                        (touched.secondContract && errors.secondContract) ||
                        (serverErrors && serverErrors.secondContract)
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} className={classes.gridItem}>
                    <OutlinedTextField
                      label={'Tercer Contrato'}
                      name={'thirdContract'}
                      type={'number'}
                      value={values.thirdContract}
                      onChange={handleChange}
                      fullWidth
                      error={
                        (touched.thirdContract && !!errors.thirdContract) ||
                        (serverErrors && !!serverErrors.thirdContract)
                      }
                      helperText={
                        (touched.thirdContract && errors.thirdContract) ||
                        (serverErrors && serverErrors.thirdContract)
                      }
                    />
                  </GridItem>
                </Grid>
                {addQuotasData.requestMade && (
                  <Grid container spacing={16} className={classes.infoFieldContainer}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      classes={{ item: classes.gridItem }}
                    >
                      <InfoField
                        label="Cupo disponible contrato 2:"
                        className={classes.infoField}
                      >
                        {formatMoney(addQuotasData.secondContractQuota)}
                      </InfoField>
                    </Grid>
                    {addQuotasData.thirdContractQuota >= 0 && (
                      <Grid
                        item
                        md={12}
                        xs={12}
                        classes={{ item: classes.gridItem }}
                      >
                        <InfoField
                          label="Cupo disponible contrato 3:"
                          className={classes.infoField}
                        >
                          {formatMoney(addQuotasData.thirdContractQuota)}
                        </InfoField>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Fragment>
            }
            footer={
              <Fragment>
                {addQuotasData.requestMade && (
                  <Button
                    className={classes.button}
                    onClick={this._handleNewQuery}
                    color={'secondary'}
                    classes={{ label: classes.buttonLabel }}
                  >
                    {'Nueva Consulta'}
                  </Button>
                )}
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={this._handleOnClose}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                {addQuotasData.requestMade ? (
                  <ProgressButton
                    onClick={() => this._handleOnSave(values)}
                    className={classes.button}
                    classes={{
                      disabled: classes.buttonDisabled,
                      label: classes.buttonLabel
                    }}
                    color={'secondary'}
                    disabled={!dirty || isSubmitting}
                    isSubmitting={isSubmitting}
                  >
                    {'Unir Cupos'}
                  </ProgressButton>
                ) : (
                  <ProgressButton
                    onClick={handleSubmit}
                    className={classes.button}
                    classes={{
                      disabled: classes.buttonDisabled,
                      label: classes.buttonLabel
                    }}
                    color={'secondary'}
                    disabled={!dirty || isFetching}
                    isSubmitting={isFetching}
                  >
                    {'Consultar'}
                  </ProgressButton>
                )}
              </Fragment>
            }
          />
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(AddQuotasModal);
