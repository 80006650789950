import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import SinisterList from './SinisterList';

import { fetchSinisters, approveSinister, revokeSinister } from '../../../actions/sinister_actions';

function mapStateToProps(state) {
  const { sinisters, meta } = state.sinister;
  return {
    sinisters,
    meta,
    requestSend: state.requestState.requestSend,
    requestRevokeSinisterSend: state.requestState.requestRevokeSinisterSend,
    requestApproveSinisterSend: state.requestState.requestApproveSinisterSend,
    canApproveSinister: state.authentication.currentUser.canApproveSinister,
    canRevokeSinister: state.authentication.currentUser.canRevokeSinister,
    sinisterRegisterCauses: state.globalSetting.orgData.sinister_register_causes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSinisters: bindActionCreators(fetchSinisters, dispatch),
    approveSinister: bindActionCreators(approveSinister, dispatch),
    revokeSinister: bindActionCreators(revokeSinister, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SinisterList);
