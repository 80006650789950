import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import { Formik } from 'formik';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('El campo es requerido')
});

const AddDocumentModal = props => {
  const { open, onClose, onSubmit, isSubmitting } = props;
  return (
    <Formik
      initialValues={{name: '', description: ''}}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={
        ({
          values,
          handleSubmit,
          handleChange,
          touched,
          handleBlur,
          errors,
          handleReset
        }) => {
          const _onClose = () => {
            handleReset();
            onClose();
          };
          return (
            <Dialog open={open} onClose={_onClose}>
              <DialogTitle>Agregar documento</DialogTitle>
              <DialogContent>
                <OutlinedTextField
                  name='name'
                  label='Nombre del documento'
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.name && errors.name}
                  error={touched.name && !!errors.name}
                />
                <OutlinedTextField
                  name='description'
                  label='Descripción'
                  value={values.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.description && errors.description}
                  error={touched.description && !!errors.description}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={_onClose} color="secondary">
                  Cancelar
                </Button>
                <ProgressButton
                  color="secondary"
                  onClick={ e => {
                    handleSubmit(e);
                    setTimeout(handleReset, 1000);
                  }}
                  isSubmitting={isSubmitting}
                >
                  Guardar
                </ProgressButton>
              </DialogActions>
            </Dialog>
          );
        }
      }
    />
  );
};

export default AddDocumentModal;