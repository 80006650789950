import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const RejectMinuteModal = props => {
  const { open, onClose, onSubmit } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Minuta rechazada</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Estas apunto de rechazar la propuesta por la causal "No acuerdo comercial"
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={onSubmit} color="secondary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectMinuteModal;