import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';

class ActivityIndicator extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  render() {
    const { classes, className, size = 60, ...props } = this.props;
    return (
      <div className={classnames(classes.spinnerContainer, className)}>
        <CircularProgress color="secondary" size={size} {...props} />
      </div>
    );
  }
}

const styles = () => ({
  spinnerContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default compose(withStyles(styles, { withTheme: true }))(
  ActivityIndicator
);
