import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { orangeHighlight, white } from '../../../constants/style';

class PrimaryButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
  };

  render() {
    const {
      className,
      children,
      classes,
      loading = false,
      ...props
    } = this.props;
    return (
      <Button
        className={classnames(
          this.props.disabled ? classes.buttonDisabled : classes.button,
          className
        )}
        {...props}
      >
        {loading ? (
          <CircularProgress className={classes.circularProgress} size={25} />
        ) : (
          children
        )}
      </Button>
    );
  }
}

const styles = () => ({
  circularProgress: {
    color: white
  },
  button: {
    backgroundColor: orangeHighlight,
    color: white
  },
  buttonDisabled: {
    backgroundColor: white
  }
});

export default compose(withStyles(styles, { withTheme: true }))(PrimaryButton);
