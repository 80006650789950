import _pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

const digitalSaleDocumentRevisionFilters = filters => {
  const {
    modelId,
    typeableModel,
    contract,
    registered_at,
    page,
    per_page
  } = filters;
  const registered_at_range = registered_at ? registered_at.split(' - ') : [];

  const sanitizedFilters = {
    model_id_eq: modelId,
    contract_id_eq: contract,
    typeable_model_eq: typeableModel,
    page: page,
    per_page
  };
  if (registered_at_range.length > 1) {
    sanitizedFilters.created_at_gteq = moment(registered_at_range[0]).format();
    sanitizedFilters.created_at_lteq = moment(registered_at_range[1])
      .endOf('day')
      .format();
  } else if (registered_at_range.length > 0) {
    sanitizedFilters.created_at_gteq = moment(registered_at_range[0]).format();
    sanitizedFilters.created_at_lteq = moment(registered_at_range[0])
      .endOf('day')
      .format();
  }

  if (typeableModel === 'Sale') {
    sanitizedFilters.typeableModel = 'Venta';
  }
  return _pickBy(sanitizedFilters, filter => !!filter);
};

export default digitalSaleDocumentRevisionFilters;
