import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import parseQuerystring from '../../../helpers/parseQuerystring';
import Filters from './Filters';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import classnames from 'classnames';
import { columns, mapData } from './TableMetadata';
import Table from '../../shared/Table/Table';
import CommissionFormModal from './Modal/CommissionFormModal';

class CommissionList extends Component {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    isCreate: true,
    isOpenedCommissionFormModal: false
  };

  componentDidMount() {
    if (!this.props.commissionTypes) {
      this.props.fetchOrgData();
    }
    this._fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.commissionSaved !== this.props.commissionSaved &&
      this.props.commissionSaved
    ) {
      this.setState({ isOpenedCommissionFormModal: false });
    }
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchCommissions({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _fetchInfoToCreate = params => {
    this.props.fetchInfoToCreate(params);
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleOpenCommissionFormModal = (isCreate, commission = null) => {
    const { canUpdateCommissions } = this.props;
    const params = {
      isOpenedCommissionFormModal: false,
      commission: {
        ...commission
      },
      isCreate: isCreate
    };
    params.isOpenedCommissionFormModal = isCreate || canUpdateCommissions;

    this.setState(params);
  };

  _handleCloseCommissionFormModal = () => {
    this.setState({
      isOpenedCommissionFormModal: false
    });
  };

  _handleSave = (isCreate, commission) => {
    const query = parseQuerystring(this.props) || {};
    let params = [
      {
        commission: {
          ...commission
        }
      },
      {
        ...query,
        page: query.page || 1,
        per_page: this.state.rowsPerPage
      }
    ];

    if (isCreate) {
      params = [
        {
          categoryId: commission.categoryId,
          supplierId: commission.supplierId,
          articleId: commission.articleId,
          saleChannelId: commission.saleChannelId,
          recoveryPercentage: Number(commission.recoveryPercentage),
          initialDate: commission.initialDate,
          commissionTypeId: commission.commission_type_id,
          active: commission.active
        },
        {
          ...query,
          page: query.page || 1,
          per_page: this.state.rowsPerPage
        }
      ];
    }

    isCreate
      ? this.props.createCommission(params[0], params[1])
      : this.props.updateCommission(params[0], params[1]);
  };

  render() {
    const {
      classes,
      commissions = [],
      meta = {},
      commissionTypes,
      requestFetchCommissionsSend = true,
      dataToCreateCommissions,
      canCreateCommissions
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Comisiones'}
          </h1>
          {canCreateCommissions && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenCommissionFormModal.bind(this, true)}
            >
              {'Crear Comisión'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <Filters
              onChangeFilter={this._fetchItems}
              suppliers={this.props.suppliers}
              categories={this.props.categories}
              fetchInfoToCreate={this._fetchInfoToCreate}
              dataToCreateCommissions={dataToCreateCommissions}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Comisión'}
            columns={columns}
            data={mapData(commissions)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props =>
              this._handleOpenCommissionFormModal(false, props)
            }
            totalCount={(meta && meta.total_count) || 0}
            loading={requestFetchCommissionsSend}
          />
        </div>
        {this.state.isOpenedCommissionFormModal && (
          <CommissionFormModal
            onClose={this._handleCloseCommissionFormModal}
            onSave={this._handleSave}
            errors={this.props.requestErrorsOnSaveCommission}
            isSubmitting={this.props.requestSaveCommissionSend}
            commission={this.state.commission}
            commissionTypes={commissionTypes}
            fetchInfoToCreate={this._fetchInfoToCreate}
            dataToCreateCommissions={dataToCreateCommissions}
            isCreate={this.state.isCreate}
            companyName={this.props.companyName}
          />
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

CommissionList.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
  canUpdateCommissions: PropTypes.bool,
  canCreateCommissions: PropTypes.bool
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(CommissionList);
