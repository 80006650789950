import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import SpacingStyles from './../../styles/helpers/spacing';

export class GridItem extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object
  };

  render() {
    const { children, classes, ...rest } = this.props;
    return (
      <Grid item {...rest} classes={{ item: classes.gridItemPadding }}>
        {children}
      </Grid>
    );
  }
}

export default withStyles(SpacingStyles)(GridItem);
