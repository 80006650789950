import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DialogContentText from '@material-ui/core/DialogContentText';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import {
  SUPPLIER_PROPOSAL_REJECTION_MOTIVE_DROPDOWN_OPTIONS,
  SUPPLIER_PROPOSAL_REJECTION_MOTIVES
} from '../../../constants/enums';
import mapListToOptions from '../../../helpers/mapListToOptions';
import TextField from '@material-ui/core/TextField';
import _isEmpty from 'lodash/isEmpty';
import OurDatePicker from '../../shared/CustomDatePicker';
import moment from 'moment';

const validationSchema = Yup.object().shape({
  committeeDate: Yup.string()
    .nullable()
    .required('El campo es requerido'),
  rejectionMotive: Yup.number()
    .nullable()
    .required('El campo es requerido'),
  rejectionExplanation: Yup.string()
    .nullable()
    .when('rejectionMotive', {
      is: SUPPLIER_PROPOSAL_REJECTION_MOTIVES.Other,
      then: Yup.string()
        .nullable()
        .required('El campo es requerido')
    })
});

const RejectProposalModal = props => {
  const { open, onClose, onSubmit, committeeDate } = props;
  return (
    <Formik
      initialValues={{
        rejectionMotive: null,
        rejectionExplanation: null,
        committeeDate
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({
        values,
        handleSubmit,
        handleChange,
        touched,
        handleBlur,
        errors,
        handleReset,
        setFieldValue,
        setFieldTouched
      }) => {
        const _onClose = () => {
          handleReset();
          onClose();
        };
        return (
          <Dialog open={open} onClose={_onClose}>
            <DialogTitle>Rechazar propuesta</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Indique el motivo de rechazo de la propuesta
              </DialogContentText>
              {!committeeDate && (
                <OurDatePicker
                  name="committeeDate"
                  invalidLabel="Fecha inválida"
                  maxDateMessage="Fecha inválida"
                  minDateMessage="Fecha inválida"
                  invalidDateMessage="Fecha inválida"
                  label="Fecha del comité"
                  value={values.committeeDate || ''}
                  onChange={date => {
                    setFieldTouched('committeeDate = ', true);
                    const momentDate = moment(date);
                    if (momentDate.isValid()) {
                      const parsedDate = momentDate.format('YYYY-MM-DD 05:00');
                      setFieldValue('committeeDate', parsedDate);
                    } else {
                      setFieldValue('committeeDate', '');
                    }
                  }}
                  onBlur={handleBlur}
                  onError={() => setFieldValue('committeeDate', '')}
                  error={touched.committeeDate && !!errors.committeeDate}
                  helperText={touched.committeeDate && errors.committeeDate}
                  style={{ width: '100%' }}
                  margin="normal"
                  variant="outlined"
                />
              )}
              <SimpleSelect
                name="rejectionMotive"
                label="Motivo de rechazo"
                options={mapListToOptions(
                  SUPPLIER_PROPOSAL_REJECTION_MOTIVE_DROPDOWN_OPTIONS,
                  { withId: false },
                  false
                )}
                value={values.rejectionMotive || ''}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.rejectionMotive && errors.rejectionMotive}
                error={touched.rejectionMotive && !!errors.rejectionMotive}
                style={{ width: '100%' }}
              />
              {values.rejectionMotive ===
                SUPPLIER_PROPOSAL_REJECTION_MOTIVES.Other && (
                <TextField
                  name="rejectionExplanation"
                  label="Explicación de rechazo"
                  value={values.rejectionExplanation || ''}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    touched.rejectionExplanation && errors.rejectionExplanation
                  }
                  error={
                    touched.rejectionExplanation &&
                    !!errors.rejectionExplanation
                  }
                  style={{ width: '100%' }}
                  multiline
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={_onClose} color="secondary">
                Cancelar
              </Button>
              <ProgressButton
                color="secondary"
                onClick={() => {
                  handleSubmit(values);
                  if (_isEmpty(errors) && !_isEmpty(touched)) {
                    setTimeout(_onClose, 1000);
                  }
                }}
              >
                Aceptar
              </ProgressButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};

export default RejectProposalModal;
