import {
  TRANSFERS_SET,
  TRANSFER_PROCESSED,
  TRANSFER_TOGGLE_ACTION
} from '../constants/action_constants';
const initialState = {
  transfers: [],
  meta: {},
  transferProcessed: false
};

export function quotaTransfer(state = initialState, action) {
  switch (action.type) {
    case TRANSFERS_SET:
      return {
        ...state,
        transfers: action.data,
        meta: action.meta,
        transferProcessed: false
      };
    case TRANSFER_TOGGLE_ACTION:
        const actionModal = `action${action.action}Open`;
        return {
          ...state,
          [actionModal]: action.open
        };
    case TRANSFER_PROCESSED:
      return {
        ...state,
        transferProcessed: action.status
      };
    default: {
      return state;
    }
  }
}
