import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { history } from '../../../helpers/history';
import CorporationFilters from './CorporationFilters';
import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';

import _isEmpty from 'lodash/isEmpty';

import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class CorporationList extends React.Component {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    isOpenedCreateUserModal: false,
  };

  componentDidMount() {
    this._fetchItems();
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchCorporations({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _handleRowClick = id => {
    history.push(`/corporations/${id}`);
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  render() {
    const {
      classes,
      corporations = [],
      meta = {},
      requestSend = true
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Empresas'}
          </h1>
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <CorporationFilters
              onChangeFilter={this._fetchItems}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Empresa'}
            columns={columns}
            data={mapData(corporations)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props => this._handleRowClick(props.id)}
            totalCount={meta && meta.totalCount || 0}
            loading={requestSend}
          />
        </div>
      </div>
    );
  }

}

const styles = (/* theme */) => ({
});

CorporationList.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object
};

export default withStyles(combineStyles(
  LayoutStyles,
  ButtonStyles,
  TypographyStyles,
  SpacingStyles,
  ListsStyle,
  styles
),)(CorporationList);
