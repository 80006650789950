import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';

class OutlinedTextField extends PureComponent {
  render() {
    return (
      <TextField margin="normal" variant="outlined" fullWidth {...this.props} />
    );
  }
}

export default OutlinedTextField;
