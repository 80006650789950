import _pickBy from 'lodash/pickBy';

const userFilters = filters => {
  const {
    name,
    username,
    email,
    supplier_id,
    identification,
    page,
    per_page,
    hasBiometricReader = 'todos',
    serial,
    blocked
  } = filters;

  const sanitizedFilters = {
    full_name_cont: name,
    username_cont: username,
    email_cont: email,
    identification_eq: identification,
    supplier_id_eq: supplier_id,
    blocked_eq: blocked,
    has_biometric_reader_eq: hasBiometricReader !== 'todos' ? hasBiometricReader === 'SI' : undefined,
    serial_eq: serial,
    page,
    per_page
  };

  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

export default userFilters;
