import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import InsuranceSummary from './InsuranceSummary';
import { newAlert } from '../../../actions/alert_actions';
import { createInsurance } from '../../../actions/insurance_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';

function mapStateToProps(state) {
  return {
    affiliates: state.insurances.affiliates,
    contract: state.insurances.contract,
    invoice: state.insurances.invoice,
    firstName: state.insurances.firstName,
    lastName: state.insurances.lastName,
    typeOfIdentification: state.insurances.typeOfIdentification,
    identification: state.insurances.identification,
    email: state.insurances.email,
    mobile: state.insurances.mobile,
    type: state.insurances.type,
    value: state.insurances.value,
    birthDate: state.insurances.birthDate,
    expeditionDate: state.insurances.expeditionDate,
    authentication: state.authentication,
    currentUser: state.authentication.currentUser,
    supplierId: state.authentication.currentUser.supplier.id,
    userId: state.authentication.currentUser.id,
    serverErrors: state.insurances.errors,
    baseErrorOpen: state.insurances.baseErrorOpen,
    clientValid: state.insurances.clientValid,
    contractValid: state.insurances.contractValid,
    routes: state.stepper.routes,
    process: state.stepper.process
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    createInsurance: bindActionCreators(createInsurance, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InsuranceSummary);
