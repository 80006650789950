import React, { Component } from 'react';

import CustomDialog from '../../shared/Modals/CustomDialog';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import Checkbox from '../../shared/Inputs/Checkbox';
import Masks from '../../shared/Inputs/Masks';
import _ from 'lodash';
import { Formik } from 'formik';
import { validationSchema } from './CalculateInstalmentsValidations';
import formatMoney from '../../../helpers/formatMoney';
import { formatBack } from '../../shared/Inputs/Masks';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';

class CalculateInstalments extends Component {
  responseFetched() {
    return (
      this.props.instalmentsValue != null || !_.isEmpty(this.props.serverErrors)
    );
  }

  showResponse() {
    return !this.props.calculatingInstalments && this.responseFetched();
  }

  renderResponse() {
    const { instalmentsValue, classes } = this.props;

    if (instalmentsValue === null) {
      return <p>Error calculando el valor de las cuotas</p>;
    } else {
      return (
        <div>
          <br />
          <div className={classes.extraQuotaItem}>
            <p className={classes.dataRegister}>El valor a financiar es:</p>
            <p className={classes.dataRegister}>
              <b>{formatMoney(this.props.valueToFinance)}</b>
            </p>
          </div>
          <div className={classes.extraQuotaItem}>
            <p className={classes.dataRegister}>
              El valor del seguro obligatorio es:
            </p>
            <p className={classes.dataRegister}>
              <b>{formatMoney(this.props.obligatoryInsurance)}</b>
            </p>
          </div>
          {this.props.secureValue !== 0 && <div className={classes.extraQuotaItem}>
            <p className={classes.dataRegister}>
              El valor de la fianza es:
            </p>
            <p className={classes.dataRegister}>
              <b>{formatMoney(this.props.secureValue)}</b>
            </p>
          </div>}
          <div className={classes.extraQuotaItem}>
            <p className={classes.dataRegister}>
              El valor aproximado de las cuotas es:
            </p>
            <p className={classes.dataRegister}>
              <b>{formatMoney(this.props.instalmentsValue)}</b>
            </p>
          </div>
        </div>
      );
    }
  }

  getVoluntaryInsuranceValue(values) {
    if (values.totalValue === '' || !values.withVoluntaryInsurance) return 0;
    return this.props.voluntaryInsurances[values.articleType] * values.instalments;
  }

  getVoluntaryInsuranceValueLayerTwo(values) {
    if (values.totalValue === '' || !values.withVoluntaryInsuranceLayerTwo) return 0;
    return this.props.layerTwoValue * values.instalments;
  }

  disableWithVoluntaryInsuranceCheckbox(values) {
    return values.instalments === '' || values.totalValue === '';
  }

  handleSubmit = values => {
    const voluntaryInsuranceValue = this.getVoluntaryInsuranceValue(values);
    const voluntaryInsuranceLayerTwoValue = this.getVoluntaryInsuranceValueLayerTwo(values);

    this.props.calculateInstalments({
      ...values,
      totalValue: Number(formatBack(values.totalValue, 'currency')) + voluntaryInsuranceValue + voluntaryInsuranceLayerTwoValue,
      voluntaryInsuranceValue: voluntaryInsuranceValue + voluntaryInsuranceLayerTwoValue,
      withVoluntaryInsurance: values.withVoluntaryInsurance || values.withVoluntaryInsuranceLayerTwo
    });
  };

  renderModal() {
    const { classes, isSecureSaleCheckboxActive, supplier, layerTwoName } = this.props;

    return (
      <Formik
        initialValues={{
          totalValue: '',
          downPayment: '0',
          instalments: '',
          withVoluntaryInsurance: false,
          withVoluntaryInsuranceLayerTwo: false,
          articleType: 'generic'
        }}
        validationSchema={validationSchema({
          maxInstalmentsNumber: this.props.maxInstalmentsNumber
        })}
        onSubmit={this.handleSubmit}
        onReset={(values, actions) => {
          this.props.closeModal();
          actions.resetForm();
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue
        }) => (
          <CustomDialog
            open={this.props.isOpen}
            title={'Cálculo del Valor de las Cuotas'}
            onClose={handleReset}
            buttonText="Calcular"
            onConfirm={handleSubmit}
            loading={this.props.calculatingInstalments}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ flex: '50%', margin: '5px' }}>
                <SimpleSelect
                  name="articleType"
                  label="Tipo de artículo"
                  value={values.articleType}
                  onChange={handleChange}
                  options={mapListToOptions(this.props.articleTypes, {
                    withId: false
                  })}
                  inputProps={{
                    MenuProps: {
                      disableAutoFocusItem: true
                    }
                  }}
                />
                <OutlinedTextField
                  name="instalments"
                  label="Número de cuotas"
                  type="number"
                  value={values.instalments}
                  onChange={handleChange}
                  error={
                    touched.instalments &&
                    (!!errors.instalments ||
                      !!this.props.serverErrors.number_of_instalments)
                  }
                  helperText={
                    touched.instalments &&
                    (errors.instalments ||
                      this.props.serverErrors.number_of_instalments)
                  }
                />
              </div>
              <div style={{ flex: '50%', margin: '5px' }}>
                <OutlinedTextField
                  name="totalValue"
                  label="Valor de la Venta"
                  value={values.totalValue}
                  onChange={handleChange}
                  error={
                    touched.totalValue &&
                    (!!errors.totalValue ||
                      !!this.props.serverErrors.totalValue)
                  }
                  helperText={
                    touched.totalValue &&
                    (errors.totalValue || this.props.serverErrors.totalValue)
                  }
                  InputProps={{
                    inputComponent: Masks.currency
                  }}
                />
                <OutlinedTextField
                  name="downPayment"
                  label="Cuota inicial"
                  value={values.downPayment}
                  onChange={handleChange}
                  error={
                    touched.downPayment &&
                    (!!errors.downPayment ||
                      !!this.props.serverErrors.downPayment)
                  }
                  helperText={
                    touched.downPayment &&
                    (errors.downPayment || this.props.serverErrors.downPayment)
                  }
                  InputProps={{
                    inputComponent: Masks.currency
                  }}
                />
              </div>
            </div>
            <Checkbox
              disabled={this.disableWithVoluntaryInsuranceCheckbox(values)}
              name="withVoluntaryInsurance"
              label="Con Factura Protegida"
              value={values.withVoluntaryInsurance}
              onChange={handleChange}
            />
            {supplier && supplier.canSellVoluntaryInsuranceLayerTwo && (
              <div className={classes.checkboxMargin}>
                <Checkbox
                  disabled={this.disableWithVoluntaryInsuranceCheckbox(values)}
                  name="withVoluntaryInsuranceLayerTwo"
                  label={`Con ${_.startCase(layerTwoName)}`}
                  value={values.withVoluntaryInsuranceLayerTwo}
                  onChange={handleChange}
                />
              </div>
            )}
            {isSecureSaleCheckboxActive && supplier && supplier.canSellWithSecureSale && (
              <div className={classes.checkboxMargin}>
                <Checkbox
                  disabled={this.disableWithVoluntaryInsuranceCheckbox(values)}
                  name="isSecureSale"
                  label="Con fianza"
                  value={values.isSecureSale}
                  onChange={handleChange}
                />
              </div>
            )}
            {this.showResponse() && this.renderResponse()}
          </CustomDialog>
        )}
      />
    );
  }

  render() {
    return this.props.isOpen && this.renderModal();
  }
}

const styles = theme => ({
  dataRegister: {
    margin: '2.5px 0px'
  },
  checkboxMargin: {
    marginLeft: '10px',
    display: 'initial'
  },
  extraQuotaItem: {
    borderTop: 'solid 1px #BCBCBC',
    padding: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'solid 1px #BCBCBC'
    }
  }
});

export default withStyles(combineStyles(styles))(CalculateInstalments);
