import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SupplierProposalCreator from './SupplierProposalCreator';
import {
  createProposal,
  updateProposal,
  goToIndex,
  setCreationFormActiveStep,
  createDocument,
  updateDocument,
  setAddDocumentModalOpen,
  setProductModalOpen,
  updateProduct,
  createProduct,
  setSignerModalOpen,
  createSigner,
  updateSigner,
  fetchDocument,
  updateLineExecutive,
  fetchLineExecutivesAndCoordinators,
  deleteProposal,
  previewMinute,
} from '../../../actions/supplier_proposal_actions';
import { newAlert } from '../../../actions/alert_actions';
import { logout } from '../../../actions/authentication_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';

function mapStateToProps(state) {
  return {
    proposal: state.supplierProposal.proposalToShow,
    activeStep: state.supplierProposal.creationForm.activeStep,
    action: state.supplierProposal.creationForm.action,
    addDocumentModalOpen: state.supplierProposal.addDocumentModalOpen,
    productModal: state.supplierProposal.productModal,
    creatingProposal: state.requestState.requestSupplierProposalCreateSend,
    updatingProposal: state.requestState.requestSupplierProposalUpdateSend,
    fetchingProposal: state.requestState.requestSupplierProposalShowSend,
    deletingProposal: state.requestState.requestDeleteSupplierProposalSend,
    documentCreating: state.requestState.requestCreateSupplierProposalDocumentSend,
    creatingProduct: state.requestState.requestCreateSupplierProposalProductSend,
    updatingProduct: state.requestState.requestUpdateSupplierProposalProductSend,
    creatingSigner: state.requestState.requestCreateSupplierProposalSignerSend,
    updatingSigner: state.requestState.requestUpdateSupplierProposalSignerSend,
    fetchingOrgData: state.requestState.requestOrgDataSend,
    currentUser: state.authentication.currentUser,
    categories: state.globalSetting.orgData.categories,
    signerModal: state.supplierProposal.signerModal,
    lineExecutives: state.supplierProposal.lineExecutives,
    fetchingLineExecutives: state.requestState.requestLineExecutivesAndCoordinatorsSend,
    updatingLineExecutive: state.requestState.requestUpdateSupplierProposalLineExecutiveSend,
    generatingMinute: state.requestState.requestPreviewSupplierProposalMinuteSend,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createProposal: bindActionCreators(createProposal, dispatch),
    updateProposal: bindActionCreators(updateProposal, dispatch),
    goToIndex: bindActionCreators(goToIndex, dispatch),
    logout: bindActionCreators(logout, dispatch),
    setCreationFormActiveStep: bindActionCreators(setCreationFormActiveStep, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    createDocument: bindActionCreators(createDocument, dispatch),
    updateDocument: bindActionCreators(updateDocument, dispatch),
    setAddDocumentModalOpen: bindActionCreators(setAddDocumentModalOpen, dispatch),
    setProductModalOpen: bindActionCreators(setProductModalOpen, dispatch),
    updateProduct: bindActionCreators(updateProduct, dispatch),
    createProduct: bindActionCreators(createProduct, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    setSignerModalOpen: bindActionCreators(setSignerModalOpen, dispatch),
    createSigner: bindActionCreators(createSigner, dispatch),
    updateSigner: bindActionCreators(updateSigner, dispatch),
    fetchDocument: bindActionCreators(fetchDocument, dispatch),
    fetchLineExecutives: bindActionCreators(fetchLineExecutivesAndCoordinators, dispatch),
    updateLineExecutive: bindActionCreators(updateLineExecutive, dispatch),
    deleteProposal: bindActionCreators(deleteProposal, dispatch),
    previewMinute: bindActionCreators(previewMinute, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierProposalCreator);