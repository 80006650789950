import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _find from 'lodash/find';
import _compact from 'lodash/compact';
import Button from '@material-ui/core/Button';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import ProfileStyles from './__styles__/Profile.styles';

export const Profile = ({
  classes,
  supplier,
  isLoading,
  onEditSupplierAttribute,
  canUpdateSupplier,
  currentGeographicLocations,
  currentSecureSaleGeographicLocations
}) => {
  return (
    <FormItemContainer
      title={'Información del Proveedor'}
      secondaryAction={
        !isLoading &&
        canUpdateSupplier && (
          <Button
            size={'small'}
            color={'secondary'}
            className={classnames(classes.smallButton, classes.actionButtons)}
            classes={{
              label: classes.buttonLabel
            }}
            onClick={onEditSupplierAttribute}
          >
            {'Editar'}
          </Button>
        )
      }
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
          <div className={classes.root}>
            <div className={classes.details}>
              <Grid container spacing={16} className={classes.contactData}>
                <Grid item lg={6}>
                  <p className={classes.subtitle3}>{'Nombre del proveedor:'}</p>
                  <p className={classes.body2}>{supplier.name || '--'}</p>
                </Grid>
                <Grid item lg={6}>
                  <p className={classes.subtitle3}>{'Nombre comercial:'}</p>
                  <p className={classes.body2}>{supplier.description || '--'}</p>
                </Grid>
                <Grid item lg={6}>
                  <p className={classes.subtitle3}>{'Estado:'}</p>
                  <p className={classes.body2}>
                    {supplier.active ? 'ACTIVO' : 'INACTIVO'}
                  </p>
                </Grid>
              </Grid>
            </div>
            <Divider
              variant={'middle'}
              light
              classes={{ root: classes.divider }}
            />
            <div className={classes.details}>
              <p className={classnames(classes.headline7, classes.detailsTitle)}>
                Contacto
              </p>
              <Grid container spacing={16} className={classes.contactData}>
                <Grid item lg={6}>
                  <p className={classes.subtitle3}>{'Nombre del contacto:'}</p>
                  <p className={classes.body2}>
                    {supplier.contactRepresentative || '--'}
                  </p>
                </Grid>
                <Grid item lg={6}>
                  <p className={classes.subtitle3}>{'Teléfono:'}</p>
                  <p className={classes.body2}>{supplier.phone || '--'}</p>
                </Grid>
                <Grid item lg={6}>
                  <p className={classes.subtitle3}>{'Correo:'}</p>
                  <p className={classes.body2}>{supplier.email || '--'}</p>
                </Grid>
              </Grid>
              <Divider
                variant={'middle'}
                light
                classes={{ root: classes.divider }}
              />
              <p className={classnames(classes.headline7, classes.detailsTitle)}>
                Zona(s) de disponibilidad:
              </p>
              <Grid container spacing={16} className={classes.contactData}>
                {currentGeographicLocations.map(geo => {
                  return (<span className={classes.tag} key={geo.id}>
                    {geo.display_description}
                  </span>)
                })}
              </Grid>
              <Divider
                variant={'middle'}
                light
                classes={{ root: classes.divider }}
              />
              <p className={classnames(classes.headline7, classes.detailsTitle)}>
                Zona(s) de disponibilidad venta afianzada:
              </p>
              <Grid container spacing={16} className={classes.contactData}>
                {currentSecureSaleGeographicLocations.map(geo => {
                  return (<span className={classes.tag} key={geo.id}>
                    {geo.display_description}
                  </span>)
                })}
              </Grid>
              { !supplier.canSellWithSecureSale &&
                <p className={classnames(classes.annotations)}>
                  *La configuración de fianza para el proveedor se encuentra desactivada.
                </p>
              }
            </div>
          </div>
        )}
    </FormItemContainer>
  );
};

const styles = theme => ({
  tag: {
    margin: 0,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    fontSize: '14px',
    display: 'inline-block',
    padding: [[theme.spacing.unit * 0.5, theme.spacing.unit * 1]],
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#FEB00B',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:last-child': {
      marginRight: 0
    }
  },
  annotations: {
    fontSize: '14px',
    color: '#808080',
    fontStyle: 'italic'
  }
});

export default withStyles(
  combineStyles(ProfileStyles, ButtonStyles, TypographyStyles, styles)
)(Profile);