import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import Tooltip from '@material-ui/core/Tooltip';

const CommentInput = ({ classes, onSend, loading, value, ...props }) => {
  return (
    <Paper className={classes.root}>
      <InputBase className={classes.input} value={value} {...props} />
      <Divider className={classes.divider} />
      <div>
        {loading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <IconButton
            className={value ? classes.iconButton : classes.iconButtonDisabled}
            onClick={onSend}
            aria-label="Directions"
          >
            <SendIcon />
          </IconButton>
        )}
      </div>
    </Paper>
  );
};
const styles = theme => ({
  root: {
    border: `1px solid ${theme.typography.color.light}`,
    boxShadow: 'none',
    padding: '2px 4px',
    margin: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit,
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  progress: {
    padding: '10px',
    margin: '2px',
    color: theme.palette.success.main
  },
  iconButton: {
    padding: 10,
    transition: 'color 0.8s ease',
    color: theme.palette.success.main
  },
  iconButtonDisabled: {
    padding: 10,
    transition: 'color 0.8s ease',
    color: theme.palette.disabled.main
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
});

export default withStyles(styles, { withTheme: true })(CommentInput);
