import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import DigitalSaleListContracts from './DigitalSaleListContracts';
import { newAlert } from '../../../actions/alert_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import {
  setNextStep,
  checkDigitalSaleSelectContract
} from '../../../actions/portal_financing_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  return {
    process: state.stepper.process,
    digitalSaleWithUUPNext: state.stepper.digitalSaleWithUUPNext,
    digitalSaleWithouFaceIdNext: state.stepper.digitalSaleWithouFaceIdNext,
    digitalSaleWithoutUUPonContractNext:
      state.stepper.digitalSaleWithoutUUPonContractNext,
    serverErrors: pf.errors,
    contractList: pf.contract_list,
    routes: state.stepper.routes,
    debtorIdentification: pf.debtor_identification,
    debtorIdentificationType: pf.debtor_identification_type
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    checkDigitalSaleSelectContract: bindActionCreators(
      checkDigitalSaleSelectContract,
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitalSaleListContracts);
