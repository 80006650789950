import {
  SET_PROPOSALS_LIST,
  SET_PROPOSAL_TO_SHOW,
  SET_CREATION_FORM_DATA,
  SET_ADD_DOCUMENT_MODAL_OPEN,
  SET_PRODUCT_MODAL_OPEN,
  SET_SIGNER_MODAL_OPEN,
  SET_LINE_EXECUTIVES,
} from '../constants/supplier_proposal_constants';

const initialState = {
  proposalsList: [],
  proposalToShow: {},
  creationForm: { activeStep: 0 },
  addDocumentModalOpen: false,
  productModal: {
    open: false,
    product: {}
  },
  signerModal: {
    open: false,
    signer: {}
  }
};

export function supplierProposal(state = initialState, action) {
  switch (action.type) {
    case SET_PROPOSALS_LIST:
      return {
        ...state,
        proposalsList: action.data.proposals,
        metaData: action.data.metaData,
      };
    case SET_PROPOSAL_TO_SHOW:
      return {
        ...state,
        proposalToShow: action.data.proposal
      };
    case SET_CREATION_FORM_DATA:
      return {
        ...state,
        creationForm: {
          action: action.data.action || state.creationForm.action,
          activeStep: action.data.activeStep === 0 ? 0 : (
            action.data.activeStep || state.creationForm.activeStep
          )
        }
      };
    case SET_ADD_DOCUMENT_MODAL_OPEN:
      return {
        ...state,
        addDocumentModalOpen: action.data
      };
    case SET_PRODUCT_MODAL_OPEN:
      return {
        ...state,
        productModal: action.data
      };
    case SET_SIGNER_MODAL_OPEN:
      return {
        ...state,
        signerModal: action.data
      };
    case SET_LINE_EXECUTIVES:
      return {
        ...state,
        lineExecutives: action.data.lineExecutives
      };
    default: {
      return state;
    }
  }
}
