import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import ManualQuotaFilters from './ManualQuotaFilters';
import ManualQuotaRevision from '../ManualQuotaRevision';
import ManualQuotaCreateEdit from '../ManualQuotaCreateEdit';
import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import { history } from '../../../helpers/history';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import RequestService from '../../../services/request_service';

const requestService = new RequestService();

import ListsStyle from '../__styles__/Lists.style';

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class ManualQuotaList extends React.PureComponent {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    createModal: false,
    revisionModal: false,
    manualQuotaInModal: {}
  };

  async componentDidMount() {
    this.fetchItems();
  }

  fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchManualQuotas({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this.fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleOpenCreateModal = () => {
    this.setState({ manualQuotaInModal: {}, createModal: true });
  };

  _handleCloseCreateModal = () => {
    this.setState({ manualQuotaInModal: {}, createModal: false });
  };

  _handleOpenRevisionModal = manualQuota => {
    this.setState({ manualQuotaInModal: manualQuota, revisionModal: true });
  };

  _handleCloseRevisionModal = () => {
    this.setState({ manualQuotaInModal: {}, revisionModal: false });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this.fetchItems);
  };
  render() {
    const {
      manualQuotas = [],
      meta,
      classes,
      requestSend,
      canCreateManualQuota
    } = this.props;

    const { page } = parseQuerystring(this.props) || {};

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Asignación de Cupo Manual'}
          </h1>
          {canCreateManualQuota && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenCreateModal}
            >
              {'Nueva solicitud'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <ManualQuotaFilters onChangeFilter={this.fetchItems} />
          </div>
        </div>
        <div>
          <Table
            name={'Solicitud'}
            columns={columns}
            data={mapData(manualQuotas)}
            onDownloadClick={this._handleDownloadCSV}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props => this._handleOpenRevisionModal(props)}
            totalCount={0}
            loading={requestSend}
            withNoCountPagination={true}
            nextPage={meta.nextPage}
          />
        </div>
        {canCreateManualQuota && this.state.createModal && (
          <ManualQuotaCreateEdit
            reFetch={this.fetchItems}
            onClose={this._handleCloseCreateModal}
          />
        )}
        {this.state.revisionModal && (
          <ManualQuotaRevision
            reFetch={this.fetchItems}
            manualQuota={this.state.manualQuotaInModal}
            onClose={this._handleCloseRevisionModal}
          />
        )}
      </div>
    );
  }
}

ManualQuotaList.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchManualQuotas: PropTypes.func.isRequired,
  toggleModal: PropTypes.func,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(ManualQuotaList);
