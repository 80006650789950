import { orangeHighlight } from '../../../../constants/style';

const styles = theme => ({
  mainContainer: {
    height: '100%'
  },
  navBarContainer: {
    height: '7%',
    marginBottom: 5
  },
  contentContainer: {
    height: '92%'
  },
  stepperContainer: {
    backgroundColor: 'white'
  },
  headerContainer: {
    height: '10%',
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: '#f7f7f7',
    marginLeft: 3
  },
  stepLabel: {
    cursor: 'pointer',
  },
  iconActive: {
    color: '#25B74B !important'
  },
  iconCompleted: {
    color: `${orangeHighlight} !important`
  },
  activeStepViewContainer: {
    height: '80%',
    padding: 40,
    backgroundColor: 'white',
    marginLeft: 3,
    overflowY: 'auto',
    maxHeight: 600
  },
  footerContainer: {
    height: '10%',
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: '#f7f7f7',
    marginLeft: 3
  },
  generalDataStepContainer: {
    height: '50%'
  },
  generalDataStepSubtitle: {
    marginTop: 100,
    marginBottom: 20
  },
  generalDataStepTextField: {
    width: '90%'
  },
  categoriesAndLocationsStepContainer: {
    height: '20%'
  },
  categoriesAndLocationsStepSubtitle: {
    marginTop: 20,
    marginBottom: 20
  },
  categoriesListTitle: {
    marginTop: 40
  },
  saleChannelsStepContainer: {
    height: '20%'
  },
  saleChannelsStepSubtitle: {
    marginTop: 20,
    marginBottom: 20
  },
  saleChannelsListTitle: {
    marginTop: 20
  },
  companySalesStepContainer: {
    height: '20%'
  },
  companySalesStepSubtitle: {
    marginTop: 20,
    marginBottom: 20
  },
  companySalesStepFileUploaderContainer: {
    marginTop: 30,
    marginBottom: 30
  },
  dividerContainer: {
    marginTop: 20,
    marginBottom: 20
  },
  filesTitle: {
    marginBottom: 20
  },
  addDocumentButtonContainer: {
    marginTop: 20, marginBottom: 20
  },
  listCommentsModalTextContainer: {
    borderBottomStyle: 'solid',
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    marginBottom: 40,
    minWidth: 500
  },
  listCommentsModalText: {
    textAlign: 'justify'
  },
  acceptProposalButton: {
    backgroundColor: '#43A047',
    color: 'white',
    minWidth: 169,
    '&:hover': {
      backgroundColor: '#43A047',
      color: 'white',
    }
  },
  rejectProposalButton: {
    backgroundColor: '#cf383f',
    color: 'white',
    minWidth: 169,
    '&:hover': {
      backgroundColor: '#cf383f',
      color: 'white',
    }
  },
  giveUpMinuteButton: {
    minWidth: 169,
  },
  sendMinuteButton: {
    minWidth: 169,
  },
  supplierDataStepContainer: {
    height: '20%'
  },
  supplierDataStepFileUploaderContainer: {
    marginTop: 30,
    marginBottom: 30
  },
  minuteStepContainer: {
    height: '20%'
  },
  minuteStepProductsTableContainer: {
    marginBottom: 40
  },
  minuteFormContainer: {
    marginTop: 40
  },
  minuteFileUploaderContainer: {
    marginTop: 30,
    marginBottom: 30
  },
  productModalDialogContent: {
    minWidth: 500
  },
  minuteFieldsStepContainer: {
    height: '50%'
  },
  minuteFieldsStepSubtitle: {
    marginTop: 40,
    marginBottom: 20
  },
  minuteFieldsStepTextField: {
    width: '90%'
  },
  minuteFieldsStepProductsTableContainer: {
    marginBottom: 40
  },
  signersStepContainer: {
    height: '50%'
  },
  signersStepSubtitle: {
    marginTop: 40,
    marginBottom: 20,
  },
  signersStepSignersTableContainer: {
    marginBottom: 40
  },
  select: {
    minWidth: 400,
    '&:before': {
      borderColor: theme.palette.secondary.main,
    },
    '&:after': {
      borderColor: theme.palette.secondary.main,
    },
  }
});
export default styles;