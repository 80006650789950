import _get from 'lodash/get';

export const columns = [
  {
    title: 'Punto de Venta',
    name: 'name'
  },
  {
    title: 'Proveedor',
    name: 'supplier'
  },
  {
    title: 'Dirección',
    name: 'address'
  },
  {
    title: 'Correo',
    name: 'email'
  },
  {
    title: 'Teléfono',
    name: 'phone'
  },
  {
    title: 'Estado',
    name: 'status'
  },
  {
    title: 'Preguntas reto',
    name: 'securityQuestions'
  },
  {
    title: 'Biometría dactilar',
    name: 'fingerprintsBiometric'
  },
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      name: datum.name || '--',
      supplier: _get(datum, 'supplier.name', '--'),
      address: datum.address || '--',
      email: datum.email || '--',
      phone: datum.phone || '--',
      status: datum.active ? 'Activo' : 'Inactivo',
      securityQuestions: datum.hasSecurityQuestions ? 'SI' : 'NO',
      fingerprintsBiometric: datum.hasFingerprintBiometric ? 'SI' : 'NO',
    };
  });
