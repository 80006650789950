import React, { PureComponent, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';
import { compose } from 'redux';
import List from '@material-ui/core/List';
import ContactListItem from '../../shared/ListItem/ContactListItem';
import Divider from '@material-ui/core/Divider';
import TypographyStyles from '../../../styles/typography';
import { history } from '../../../helpers/history';

class SuppliersList extends PureComponent {
  _handleClick = id => {
    if (this.props.canViewSuppliers) {
      history.push(`/suppliers/${id}`);
    }
  }

  render() {
    const { classes, suppliers } = this.props;

    return (
      <List classes={{ root: classes.list }}>
        {suppliers.map((supplier, i) => (
          <Fragment key={supplier.id}>
            <ContactListItem
              heading={`${supplier.name}`.replace(
                /_/,
                ' '
              )}
              onClick={() => this._handleClick(supplier.id)}
            >
              <Fragment>
                {supplier.email}
                <br />
                {supplier.phone}
              </Fragment>
            </ContactListItem>
            {i < suppliers.length - 1 && (
              <Divider component="li" />
            )}
          </Fragment>
        ))}
      </List>
    );
  }
}

const styles = theme => ({
  list: {
    width: '100%',
    padding: '0 !important',
    backgroundColor: theme.palette.background.paper
  },
});

export default compose(
  withStyles(combineStyles(styles, TypographyStyles), { withTheme: true })
)(SuppliersList);
