import {
  SUPPLIER_INFO_SET,
  SUPPLIERS_SET,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_COMPLETE
} from '../constants/action_constants';
const initialState = {
  suppliers: [],
  meta: null,
  loadingUpdate: false
};

export function suppliers(state = initialState, action) {
  switch (action.type) {
    case SUPPLIERS_SET:
      return {
        ...state,
        suppliers: action.data,
        meta: action.meta
      };
    case SUPPLIER_INFO_SET:
      return {
        ...state,
        supplierInfo: action.data
      };
    case SUPPLIER_UPDATE_REQUEST:
      return {
        ...state,
        loadingUpdate: true
      };
    case SUPPLIER_UPDATE_COMPLETE:
      return {
        ...state,
        loadingUpdate: false
      };
    default: {
      return state;
    }
  }
}
