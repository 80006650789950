import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import classnames from 'classnames';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import Button from '@material-ui/core/Button';
import FormModal from './FormModal';

const _getNewConfig = () => {
  return {
    categoryName: '',
    geographicLocationName: '',
    pointOfSaleName: '',
    pointOfSales: [],
    categoryId: undefined,
    geographicLocationId: undefined,
    pointOfSaleId: undefined
  };
};

class SchedulePointOfSaleConfigurationList extends React.PureComponent {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    currentConfig: _getNewConfig()
  };

  componentDidMount() {
    this._fetchData();
  }

  _fetchData(params = {}) {
    this._fetchConfigs(params);
    this.props.fetchPointsOfSales();
    this.props.fetchCategories();
  }

  _fetchConfigs = (params = {}) => {
    const { meta } = this.props;
    const page = params.page || (meta && meta.currentPage) || 1;
    this.props.fetchSchedulePointOfSaleConfigurationList({
      page: page,
      perPage: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    this._fetchConfigs({
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchConfigs);
  };

  _formButtonIsVisible = () => {
    return this.props.canCreateSchedulePointOfSaleConfigurations;
  };

  _getTableName = () => {
    const { configs = [] } = this.props;
    return configs.length > 1
      ? 'Configuraciónes de puntos de venta de agendamientos'
      : 'Configuración de punto de venta de agendamiento';
  };

  _openModal = (params = {}) => {
    this.props.openFormModal();
    this.setState({
      currentConfig: this._getCurrentConfig(params.config)
    });
  };

  _closeModal = () => {
    this.props.closeFormModal();
  };

  _isLoadingData = () => {
    const {
      loading = false,
      loadingPointOfSales = false,
      loadingCategories = false
    } = this.props;
    return loading || loadingPointOfSales || loadingCategories;
  };

  _getNewConfig = () => {
    return {
      categoryName: '',
      geographicLocationName: '',
      pointOfSaleName: '',
      categoryId: undefined,
      geographicLocationId: undefined,
      pointOfSaleId: undefined
    };
  };

  _getCurrentConfig = config => {
    return config || _getNewConfig();
  };

  _handleRowClick = config => {
    this._openModal({ config });
  };

  render() {
    const {
      configs = [],
      meta = {},
      classes,
      categories,
      geographicLocations,
      pointOfSales,
      modalSubmitLoading = false,
      createConfig,
      updateConfig,
      formModalIsOpen
    } = this.props;

    const { currentPage } = meta;

    const { rowsPerPage } = this.state;

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Configuración de puntos de venta de agendamientos'}
          </h1>
          {this._formButtonIsVisible() && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={() => this._openModal()}
            >
              {'Crear Configuración'}
            </Button>
          )}
        </div>
        <div>
          <Table
            name={this._getTableName()}
            columns={columns}
            data={mapData(configs)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={currentPage - 1}
            rowsPerPage={this.state.rowsPerPage}
            totalCount={(meta && meta.totalCount) || 0}
            loading={this._isLoadingData()}
            onClickOnRow={this._handleRowClick}
          />
        </div>
        <div>
          {
            formModalIsOpen && (
              <FormModal
                onClose={this._closeModal}
                config={this.state.currentConfig}
                categories={categories}
                geographicLocations={geographicLocations}
                pointOfSales={pointOfSales}
                createConfig={createConfig}
                updateConfig={updateConfig}
                submitLoading={modalSubmitLoading}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
              />
            )
          }
        </div>
      </div>
    );
  }
}

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(SchedulePointOfSaleConfigurationList);