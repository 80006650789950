export const ACTION_TYPES = {
  OPEN_MODAL: 'OPEN_MODAL',
  VIEW_ENTITY: 'VIEW_ENTITY'
};

export const STATUS = {
  NEW: 'new',
  DONE: 'done'
};

export const ENTITIES_ROUTES_MAP = {
  ManualQuotum: '/manual-quota',
  Schedule: '/home-visits',
  SaleReturnDetail: '/sales/revoke',
  PointOfSalesUser: '/sellers_request',
  RemoteSale: '/sales'
};
