import {
  PHONE_INFO_SET,
  PHONE_INFO_CLEAR
} from '../constants/action_constants';

const initialState = {
  phoneInfo: null
};

export function phone(state = initialState, action = {}) {
  switch (action.type) {
    case PHONE_INFO_SET:
      return {
        ...state,
        phoneInfo: action.data
      };
    case PHONE_INFO_CLEAR:
      return {
        ...state,
        phoneInfo: null
      };
    default:
      return state;
  }
}
