import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Fade, withStyles } from '@material-ui/core';
import { redBrilla, redBrillaSoft } from '../../../constants/style';
import { compose } from 'redux';

class LinearLoading extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };
  state = {
    timer: null,
    realLoading: false
  };
  componentDidUpdate(prevProps) {
    const { loading, timeout = 800 } = this.props;
    if (prevProps.loading !== loading) {
      if (!loading) this._clearTimer();
      if (loading) this._setTimer(timeout);
    }
  }
  _setTimer = timeout => {
    const t = setTimeout(this._handleTimer, timeout);
    this.setState({ timer: t });
  };

  _handleTimer = () => {
    this.setState({ realLoading: true });
  };

  _clearTimer = () => {
    clearTimeout(this.state.timer);
    this.setState({ realLoading: false });
  };

  render() {
    const { classes, customStyle, loading, ...props } = this.props;
    const { realLoading } = this.state;
    let style = {};
    if (customStyle)
      style = {
        colorPrimary: classes.linearColorPrimary,
        barColorPrimary: classes.linearBarColorPrimary
      };
    return (
      <div style={{ height: 10 }}>
        {realLoading && (
          <Fade in={realLoading}>
            <LinearProgress classes={style} {...props} />
          </Fade>
        )}
      </div>
    );
  }
}

const styles = () => ({
  linearBarColorPrimary: {
    backgroundColor: redBrilla
  },
  linearColorPrimary: {
    backgroundColor: redBrillaSoft
  }
});

export default compose(withStyles(styles, { withTheme: true }))(LinearLoading);
