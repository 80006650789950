import React, { PureComponent } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ActivityIndicator from './ActivityIndicator';

const badgeGreen = require('../../assets/icons/badge-green.svg');

class IconItem extends PureComponent {
  render() {
    const { classes, icon, label, labelStyle, style, title, loading, isMegaQuota } = this.props;

    return (
      <div style={style} className={classes.container}>
        {icon}
        <div className={classes.content}>
          <span className={classes.title}>{title}</span>
          {loading ? (
            <ActivityIndicator
              size={24}
              className={classes.activityIndicator}
            />
          ) : (
            isMegaQuota ? (
              <Tooltip title="Megacupo activado">
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.label}
                  style={labelStyle}
                >
                  {label}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                variant="h5"
                gutterBottom
                className={classes.label}
                style={labelStyle}
              >
                {label}
              </Typography>
            )
          )}
        </div>
        {isMegaQuota && <Tooltip title="Megacupo activado"><img className={classes.imageIcon} src={badgeGreen} /></Tooltip>}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    flex: 1
  },
  label: {
    flex: 1
  },
  activityIndicator: {
    margin: '0.35rem'
  },
  imageIcon: {
    color: 'white',
    marginLeft: '5px',
    height: '28px',
    width: '28px'
  }
});

IconItem.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.any,
  style: PropTypes.any,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(IconItem);
