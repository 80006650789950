import {
    QUERY_SCORING_QUOTAS,
    OPEN_QUERY_SCORING_QUOTA_MODAL,
    CLOSE_QUERY_SCORING_QUOTA_MODAL,
    RESET_QUERY_SCORING_DATA,
} from '../constants/query_scoring_quota_constants';
import _get from 'lodash/get';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import AxiosRequest from '../services/axios_request';
import RequestService from '../services/request_service';
import queryScoringQuotaFiltersSanitizer from '../helpers/sanitizers/queryScoringQuotaSanitizer';
import snakecaseKeys from 'snakecase-keys';

const requestServiceNode = new RequestService('node');
const axiosRequest = new AxiosRequest();

export const openQueryScoringQuotaModal = () => {
    return dispatch => {
        dispatch({
            type: OPEN_QUERY_SCORING_QUOTA_MODAL
        });
    };
};

export const closeQueryScoringQuotaModal = () => {
    return dispatch => {
        dispatch(errorOnRequest('QueryQuotas', null));
        dispatch({
            type: CLOSE_QUERY_SCORING_QUOTA_MODAL
        });
    };
};

export const resetQueryScoringData = () => {
    return dispatch => {
        dispatch({
            type: RESET_QUERY_SCORING_DATA
        });
    };
};

function setScoringQuotas(data) {
    return {
        type: QUERY_SCORING_QUOTAS,
        data
    };
}

export const queryScoringQuotas = (params = {}) => async dispatch => {
    dispatch(sendRequest('QueryScoringQuotas'));
    try {
        const payload = await requestServiceNode.post(
            'scoring/scoringConsultsSideBar',
            queryScoringQuotaFiltersSanitizer(params)
        );
        const data = snakecaseKeys(payload.data);
        dispatch(setScoringQuotas(data));
        dispatch(errorOnRequest('QueryScoringQuotas', []));
    } catch (errors) {
        const e = await errors.json();
        dispatch(errorOnRequest('QueryScoringQuotas', e.errors.base));
        dispatch(setScoringQuotas({}));
    } finally {
        dispatch(completeRequest('QueryScoringQuotas'));
    }
};
