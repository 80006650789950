import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { newAlert } from '../../../actions/alert_actions';
import ClientDetail from './ClientDetail';
import { fetchClientInfo, unlockContract } from '../../../actions/client_actions';

function mapStateToProps(state) {
  return {
    canViewSales: state.authentication.currentUser.canViewSales,
    canViewPromissories: state.authentication.currentUser.canViewPromissories,
    clientInfo: state.client.clientInfo,
    clientLoading: state.requestState.requestClientInfoSend,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchClientInfo: bindActionCreators(fetchClientInfo, dispatch),
    unlockContract: bindActionCreators(unlockContract, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDetail);
