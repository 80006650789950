import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import PortalIDCheck from './PortalIDCheck';
import {
  validateInformation,
  toggleIDModal,
  setNextStep,
  toggleBaseErrorModal,
  checkFourthStep,
  checkBiometricValidation,
  getSecurityQuestions,
  checkSecurityAnswers,
  fetchSecurityQuestionsCausals,
  chooseCausal,
  resetBiometricProps,
  resetScrollProp,
  resetFailedProp,
  resetRetryProp,
  setSecurityQuestionAdditionalData,
  startIdentityOtpTransaction,
  resetOTPCodeProp,
  verifyIdentityOtp,
  resetProp,
  sendFacialBiometricMessage,
  checkFacialBiometricStatus
} from '../../../actions/portal_financing_actions';

import { setCurrentStep } from '../../../actions/stepper_actions';
import { newAlert } from '../../../actions/alert_actions';
import { doctypes } from '../../../constants/enums';

function findDocTypeID(docTypeText) {
  const result = docTypeText
    ? doctypes.find(o => o.name === docTypeText).id
    : '';
  return result;
}

// eslint-disable-next-line complexity
function mapStateToProps(state) {
  const pf = state.portalFinancing;
  const debtor = pf.promissory ? pf.promissory.debtor : null;
  const cosigner =
    pf.promissory && pf.promissory.cosigner ? pf.promissory.cosigner : null;
  return {
    stepIsLoading: pf.stepIsLoading,
    debtor: {
      securityQuestionsLastName: pf.client_debtor_last_name
        ? pf.client_debtor_last_name
        : pf.experian_debtor_response
          ? pf.experian_debtor_response.persona.primer_apellido
          : '',
      securityQuestionsName: pf.client_debtor_name
        ? pf.client_debtor_name
        : pf.experian_debtor_response
          ? pf.experian_debtor_response.persona.nombres
          : '',
      dcDebtorLastName: debtor
        ? debtor.last_name
          ? debtor.last_name.split(' ')[0]
          : ''
        : pf.dcDebtorLastName,
      buyerID: (debtor && debtor.identification) || pf.debtor_identification,
      buyerIDType:
        (debtor && debtor.type_of_identification) ||
        pf.debtor_identification_type,
      buyerDocTypeID:
        debtor && debtor.type_of_identification
          ? findDocTypeID(debtor.type_of_identification)
          : findDocTypeID(pf.debtor_identification_type),
      docID: pf.id_number,
      expeditionDate: pf.id_debtor_expedition_date || pf.id_expedition_date || '',
      name: pf.client_debtor_name || pf.id_name,
      lastName: pf.client_debtor_last_name || pf.id_first_last_name,
      secondLastName: pf.id_second_last_name,
      locality: pf.id_locality,
      department: pf.id_department,
      gender: debtor ? debtor.gender : pf.id_gender || '',
      status: pf.id_status,
      birthdate: pf.debtor_birthdate,
      contract: pf.debtor_contract_number,
      biometricValidated: pf.debtor_validated,
      country: debtor ? debtor.id_expedition_place || '' : pf.id_country,
    },
    cosigner: {
      securityQuestionsLastName: pf.client_cosigner_last_name
        ? pf.client_cosigner_last_name
        : pf.experian_cosigner_response
          ? pf.experian_cosigner_response.persona.primer_apellido
          : '',
      securityQuestionsName: pf.client_cosigner_name
        ? pf.client_cosigner_name
        : pf.experian_cosigner_response
          ? pf.experian_cosigner_response.persona.nombres
          : '',
      dcCosignerLastName: cosigner
        ? cosigner.last_name
          ? cosigner.last_name.split(' ')[0]
          : ''
        : pf.dcCosignerLastName,
      cosignerID: cosigner
        ? cosigner.identification
        : pf.cosigner_identification,
      cosignerIDType: cosigner
        ? cosigner.type_of_identification
        : pf.cosigner_identification_type,
      cosignerDocTypeID:
        cosigner && cosigner.type_of_identification
          ? findDocTypeID(cosigner.type_of_identification)
          : findDocTypeID(pf.cosigner_identification_type),
      dcID: pf.dc_cosigner_number,
      dcName: pf.dc_cosigner_name,
      dcLastName: pf.dc_cosigner_first_last_name || pf.id_first_last_name_cosigner,
      dcSecondLastName: pf.dc_cosigner_second_last_name,
      expeditionDate: pf.id_cosigner_expedition_date || pf.id_expedition_date_cosigner || '',
      dcLocality: pf.dc_cosigner_locality,
      dcDepartment: pf.dc_cosigner_department,
      dcGender: pf.dc_cosigner_gender || pf.dc_gender || '',
      dcExpeditionDate: pf.dc_cosigner_expedition_date,
      dcStatus: pf.dc_cosigner_status,
      birthdate: pf.cosigner_birthdate,
      contract: pf.cosigner_contract_number,
      biometricValidated: pf.cosigner_validated,
      dcCountry: cosigner ? cosigner.country || '' : pf.dc_country,
    },
    channelIgnoreBiometricsValidation: pf.channel_ignore_biometrics_validation,
    cosignerServerErrors: pf.dcCosignerErrors,
    debtorServerErrors: pf.dcDebtorErrors,
    pointOfSale: pf.point_of_sale,
    process: state.stepper.process,
    promissory: pf.promissory,
    routes: state.stepper.routes,
    saleChannel: pf.sale_channel,
    transfer: {
      contract: pf.moveQuotaData.contract,
      identificationType: pf.moveQuotaData.identificationType,
      identification: pf.moveQuotaData.identification,
      birthdate: pf.moveQuotaData.birthdate
    },

    nextStep: state.stepper.nextStep,
    portalNext: state.stepper.portalNext,
    promissoryNext: state.stepper.promissoryNext,
    remoteDigitalSaleWithUUPNext: state.stepper.remoteDigitalSaleWithUUPNext,
    remoteDigitalSaleWithoutUUPNext:
      state.stepper.remoteDigitalSaleWithoutUUPNext,
    serverErrors: pf.errors,
    loading: state.requestState.requestIdentificationCheckSend,
    debtorSuccess: pf.dcDebtorSuccess,
    cosignerSuccess: pf.dcCosignerSuccess,
    pointOfSales: pf.portalPOS,
    idModal: pf.idModal,
    cosignerIdentification: pf.cosigner_identification,
    currentClient: pf.currentClient,
    debtorQuestions: {
      idQuestionnaire:
        typeof pf.debtor !== 'undefined'
          ? pf.debtor.security_questions.id_questionnaire
          : null,
      regQuestionnaire:
        typeof pf.debtor !== 'undefined'
          ? pf.debtor.security_questions.reg_questionnaire
          : null,
      securityQuestions:
        typeof pf.debtor !== 'undefined'
          ? pf.debtor.security_questions.security_questions
          : null
    },
    cosignerQuestions: {
      idQuestionnaire:
        typeof pf.cosigner !== 'undefined'
          ? pf.cosigner.security_questions.id_questionnaire
          : null,
      regQuestionnaire:
        typeof pf.cosigner !== 'undefined'
          ? pf.cosigner.security_questions.reg_questionnaire
          : null,
      securityQuestions:
        typeof pf.cosigner !== 'undefined'
          ? pf.cosigner.security_questions.security_questions
          : null
    },
    biometricValidationFailedTimes:
      typeof pf.biometric_validation_failed_times !== 'undefined'
        ? pf.biometric_validation_failed_times
        : 0,
    securityQuestionsCausals: pf.securityQuestionsCausals,
    causal: pf.causal,
    securityQuestionsPreload: pf.security_questions_preload,
    scrollUp: pf.scrollUp || false,
    failed: pf.failed || false,
    questionsFailed: pf.questionsFailed || false,
    retry: pf.retry || false,
    biometricError: pf.biometricError || '',
    isSecureSale: pf.isSecureSale,
    isBiometricValidationInSecureSaleActive: state.globalSetting.orgData.is_biometric_validation_in_secure_sale_active,
    identityOTP: {
      requiresQuestions: pf.identityOTPRequiresQuestions,
      timestampOTP: pf.identityOTPtimestamp,
      OTPCode: pf.identityOTPCode,
      emailSent: pf.identityOTPEmailSent,
      mobileSent: pf.identityOTPMobileSent,
      transactionId: pf.identityOTPTransactionId,
      regValidation: pf.identityOTPRegValidation,
      name: pf.identityOTPName,
      lastName: pf.identityOTPLastName,
      usingCrosscoreParams: pf.usingCrosscoreParams && state.globalSetting.orgData.validate_identity_with_crosscore && pf.posCanUseCrosscore,
      email: pf.identityOTPEmail,
      mobile: pf.identityOTPMobile
    },
    validateIdentityWithCrosscore: state.globalSetting.orgData.validate_identity_with_crosscore && pf.posCanUseCrosscore,
    validateAniInSales: state.globalSetting.orgData.validate_ani_in_sales,
    validationMethod: pf.validationMethod,
    userId: pf.userId,
    canSendFacialBiometricMessage: state.authentication.currentUser.canSendFacialBiometricMessage,
    canCheckFacialBiometricStatus: state.authentication.currentUser.canCheckFacialBiometricStatus,
    biometricLogId: pf.biometricLogId,
    cosignerBiometricLogid: pf.cosignerBiometricLogid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleBaseErrorModal: bindActionCreators(toggleBaseErrorModal, dispatch),

    validateInformation: bindActionCreators(validateInformation, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    toggleIDModal: bindActionCreators(toggleIDModal, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    checkFourthStep: bindActionCreators(checkFourthStep, dispatch),
    checkBiometricValidation: bindActionCreators(
      checkBiometricValidation,
      dispatch
    ),
    getSecurityQuestions: bindActionCreators(getSecurityQuestions, dispatch),
    checkSecurityAnswers: bindActionCreators(checkSecurityAnswers, dispatch),
    chooseCausal: bindActionCreators(chooseCausal, dispatch),
    resetBiometricProps: bindActionCreators(resetBiometricProps, dispatch),
    resetScrollProp: bindActionCreators(resetScrollProp, dispatch),
    resetFailedProp: bindActionCreators(resetFailedProp, dispatch),
    resetRetryProp: bindActionCreators(resetRetryProp, dispatch),
    setSecurityQuestionAdditionalData: bindActionCreators(
      setSecurityQuestionAdditionalData,
      dispatch
    ),
    startIdentityOtpTransaction: bindActionCreators(
      startIdentityOtpTransaction,
      dispatch
    ),
    verifyIdentityOtp: bindActionCreators(verifyIdentityOtp, dispatch),
    resetOTPCodeProp: bindActionCreators(resetOTPCodeProp, dispatch),
    resetProp: bindActionCreators(resetProp, dispatch),
    sendFacialBiometricMessage: bindActionCreators(sendFacialBiometricMessage, dispatch),
    checkFacialBiometricStatus: bindActionCreators(checkFacialBiometricStatus, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PortalIDCheck);
