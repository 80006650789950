import { BULK_LOAD_SET } from '../constants/action_constants';

const initialState = {
  bulkProcesses: []
};

export function bulkProcesses(state = initialState, action) {
  switch (action.type) {
    case BULK_LOAD_SET:
      return {
        ...state,
        bulkProcesses: action.data
      };
    default: {
      return state;
    }
  }
}
