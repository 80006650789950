import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

class SegmentHeader extends PureComponent {
  render() {
    const { children, classes, className, ...props } = this.props;
    return (
      <div {...props} className={classnames(classes.container, className)}>
        <Typography>{children}</Typography>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing.unit
  }
});

SegmentHeader.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default withStyles(styles)(SegmentHeader);
