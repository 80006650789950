import Store from '../store/Store';
import { setCurrentUser } from '../actions/authentication_actions';
import camelcaseKeys from 'camelcase-keys';
import { newAlert } from '../actions/alert_actions';

export const validatePassword = (payload) => {
  const camelcaseRes = camelcaseKeys(payload, { deep: true });
  if (camelcaseRes && camelcaseRes.interrupt) {
    if (
      camelcaseRes.interrupt.passwordUpdateInfo &&
      camelcaseRes.interrupt.passwordUpdateInfo.passwordUpdateIsRequired
    ) {
      const currentState = Store.getState();
      const currentUser = { ...currentState.authentication.currentUser };
      if (
        camelcaseRes.interrupt.passwordUpdateInfo.passwordUpdateIsRequired !=
        currentUser.passwordUpdateInfo.passwordUpdateIsRequired
      ) {
        const user = Object.assign(currentUser, camelcaseRes.interrupt);
        Store.dispatch(setCurrentUser(user));
      }
      camelcaseRes.message &&
        Store.dispatch(newAlert('error', 'ERROR:', camelcaseRes.message));
    }
  }
};
