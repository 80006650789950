import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { newAlert } from '../../../actions/alert_actions';
import PointOfSaleDetail from './PointOfSaleDetail';
import { fetchUserNames } from '../../../actions/user_actions';
import { fetchLocationMappedNames } from '../../../actions/financial_plan_actions';
import {
  fetchPointOfSaleInfo,
  setPointOfSaleSellers,
  updatePointOfSale,
  clearUpdatePOSErrors
} from '../../../actions/points_of_sale_actions';

function mapStateToProps(state) {
  return {
    permissions: state.authentication.permissions,
    currentUser: state.authentication.currentUser,
    pointOfSaleInfo: state.pointOfSale.pointOfSaleInfo,
    canAddCoordinator: state.authentication.currentUser.canAddCoordinator,
    userNames: state.user.userNames,
    freelanceRoles: state.globalSetting.orgData.asesor_freelance_roles,
    asesorRoles: state.globalSetting.orgData.asesor_fnb_roles,
    userNamesLoading: state.requestState.requestUserNamesSend,
    selectedSellers: state.pointOfSale.selectedSellers,
    coordinatorRoles: state.globalSetting.orgData.coordinador_aliado_roles,
    hugeSurfaceCoordinatorRoles:
      state.globalSetting.orgData.huge_surface_coordinator_roles,
    locationMappedNames: state.financialPlans.locationMappedNames,
    updatePointOfSaleLoading: state.requestState.requestUpdatePointOfSaleSend,
    updatePointOfSaleErrors:
      state.requestState.requestErrorsOnUpdatePointOfSale,
    pointOfSaleInfoLoading: state.requestState.requestPointOfSaleInfoSend,
    pointOfSaleSellersUpdating:
      state.requestState.requestUpdatePointOfSaleSellersSend,
    saleChannels: state.globalSetting.orgData.sale_channels
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchPointOfSaleInfo: bindActionCreators(fetchPointOfSaleInfo, dispatch),
    setPointOfSaleSellers: bindActionCreators(setPointOfSaleSellers, dispatch),
    fetchUserNames: bindActionCreators(fetchUserNames, dispatch),
    fetchLocationMappedNames: bindActionCreators(
      fetchLocationMappedNames,
      dispatch
    ),
    updatePointOfSale: bindActionCreators(updatePointOfSale, dispatch),
    clearUpdatePOSErrors: bindActionCreators(clearUpdatePOSErrors, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PointOfSaleDetail);
