import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _truncate from 'lodash/truncate';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { history } from '../../../helpers/history';
import { withRouter } from 'react-router';

import MenuItem from '@material-ui/core/MenuItem';

import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';

import NotificationsCircle from './NotificationsCircle';
import NotificationItemStyles from './__styles__/NotificationItem.styles';
import { STATUS, ENTITIES_ROUTES_MAP } from './NotificationItem.constant';

const MAX_CHARACTERS_DISPLAYED = 123;

export class NotificationItem extends PureComponent {
  state = {
    seen: false
  };

  _handleClick = () => {
    const {
      entityId,
      entityType,
      location: { pathname },
      markAsSeen,
      notification,
      freelanceRoles,
      asesorRoles,
      currentUser,
      action
    } = this.props;
    const { location } = window;
    this.setState({ seen: true });

    markAsSeen(notification.id);
    if (
      !currentUser.roles.some(role =>
        asesorRoles.concat(freelanceRoles).includes(role.name)
      )
    ) {
      history.push({
        pathname:
          action === 'view_entity'
            ? ENTITIES_ROUTES_MAP[entityType] + '/' + entityId
            : ENTITIES_ROUTES_MAP[entityType],
        search:
          ENTITIES_ROUTES_MAP[entityType] === location.pathname
            ? location.search
            : '',
        state: {
          entityId: entityId,
          entityType: entityType
        }
      });
    }
  };

  render() {
    const {
      classes,
      action,
      entityId,
      entityType,
      notification,
      bodyMessage,
      seen
    } = this.props;

    const status = seen || this.state.seen ? STATUS.DONE : STATUS.NEW;

    return (
      <MenuItem
        className={classnames(classes.menuItem, {
          [classes.menuItemDone]: status === 'done'
        })}
        onClick={this._handleClick}
        classes={{
          root: classnames(classes.menuItemCursorPointer)
        }}
        data-action-type={action}
      >
        <div className={classnames(classes.wrapper, 'wrapper')}>
          <div className={classes.content}>
            <div className={classes.notificationContainer}>
              <NotificationsCircle
                classes={{
                  root: classes.notificationsCircle,
                  icon: classnames({
                    [classes.notificationsCircleDone]: status === 'done'
                  })
                }}
              />
            </div>
            <div>
              <p
                className={classnames(classes.subject, classes.caption, {
                  [classes.subjectDone]: seen
                })}
              >
                <strong>
                  {_get(notification, 'generator.fullname') || ''}
                </strong>{' '}
                {
                  <span
                    dangerouslySetInnerHTML={{
                      __html: notification.text
                    }}
                  />
                }
              </p>
              {bodyMessage && (
                <p
                  className={classnames(classes.subject, classes.caption, {
                    [classes.subjectDone]: status
                  })}
                >
                  {`"${_truncate(bodyMessage, {
                    length: MAX_CHARACTERS_DISPLAYED,
                    separator: ' '
                  })}"`}
                </p>
              )}
              <p className={classnames(classes.footer, classes.caption)}>
                <span className={classes.createdAt}>
                  {moment(notification.date)
                    .add(5, 'hours')
                    .fromNow(true)}
                </span>
                {bodyMessage && bodyMessage.length > MAX_CHARACTERS_DISPLAYED && (
                  <button
                    type="button"
                    className={classnames(classes.readMore, {
                      [classes.subjectDone]: seen === 'done'
                    })}
                    onClick={this._handleClick}
                    data-action-type={action}
                    data-entity-id={entityId}
                    data-entity-type={entityType}
                  >
                    Read More
                  </button>
                )}
              </p>
            </div>
          </div>
        </div>
      </MenuItem>
    );
  }
}

export default withRouter(
  withStyles(combineStyles(TypographyStyles, NotificationItemStyles))(
    NotificationItem
  )
);
