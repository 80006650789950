import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
import { validationSchema } from './PortalValidateInvoiceValidation';

import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment-timezone';
import ActivityIndicator from '../../shared/ActivityIndicator';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';
import OurDatePicker from '../../shared/CustomDatePicker';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import CustomDialog from '../../shared/Modals/CustomDialog';
import SegmentHeader from '../../shared/SegmentHeader';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import StepperButtons from '../../shared/StepperButtons';
import SkipValidateInvoiceModal from './SkipValidateInvoiceModal';

class PortalValidateInvoicePage extends PureComponent {
  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;
  state = {
    modalOpen: false,
    isValid: false,
    isComplete: false,
    haveQuestions: false,
    showingSkipStepModal: false
  };

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
    this.props.fetchGlobalParameters();
    this.props.fetchModalsImage();
    this.props.resetInvoiceValidationSkipped();
    if (this.props.invoice1.answer || this.props.invoice2.answer) {
      this.setState({ isComplete: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.invoice1.id ||
      this.props.invoice1.id ||
      this.props.invoice2.id
    ) {
      this.setState({ isValid: true });
    }
  }

  onSubmit = async values => {
    try {
      const params = {
        contract: this.props.contract,
        debtorIdentification: this.props.debtorIdentification,
        validateWithOneInvoice: this.validateWithOneInvoice(),
        invoice1: {
          id: values.invoice1,
          date: moment(values.invoice1Date).format('DD-MM-YYYY'),
          answer: values.answer1
        },
        isGnp: this.props.gnpGasApplianceSaleEvaluation.gnp_sale.make_gnp_sale,
        isSkipInvoiceQuestionActive: this.props.isSkipInvoiceQuestionActive,
      };
      if (!this.validateWithOneInvoice()) {
        params['invoice2'] = {
          id: values.invoice2,
          date: moment(values.invoice2Date).format('DD-MM-YYYY'),
          answer: values.answer2
        };
      }

      if (
        this.props.canCheckInvoiceAnswers &&
        this.props.canViewInvoiceQuestions
      ) {
        const payload = {
          contract: params.contract,
          validateWithOneInvoice: params.validateWithOneInvoice,
          isGnp: params.isGnp,
          isSkipInvoiceQuestionActive: this.props.isSkipInvoiceQuestionActive,
        };
        this.setState({ haveQuestions: true });

        if (this.props.invoice1.id !== values.invoice1) {
          Object.assign(payload, { invoice1: params.invoice1 });
        }

        if (!this.validateWithOneInvoice()) {
          if (this.props.invoice2.id !== values.invoice2) {
            Object.assign(payload, { invoice2: params.invoice2 });
          }

          if (
            Object.keys(payload).includes('invoice1') ||
            Object.keys(payload).includes('invoice2')
          ) {
            await this.props.checkFifthStep(payload, this.props.routes[this.nextStep], this.props.process);
            if (this.props.invoice1.id !== null) {
              this.props.fetchQuestionsInvoice(payload);
            }
          }

          if (
            this.props.questionsInvoice[0].question !== null &&
            (values.answer1 || values.answer2)
          ) {
            await this.props.checkInvoiceResponse(
              params,
              this.props.routes[this.nextStep],
              this.props.process
            );
            if (
              this.props.serverErrors.answer1 ||
              this.props.serverErrors.answer2
            ) {
              Object.assign(payload, {
                invoice1: params.invoice1,
                invoice2: params.invoice2
              });
              document.getElementById('invoice-form').reset();
              this.props.fetchQuestionsInvoice(payload);
            }
          }
        } else {
          if (Object.keys(payload).includes('invoice1')) {
            await this.props.checkFifthStep(payload, this.props.routes[this.nextStep], this.props.process);
            if (this.props.invoice1.id !== null) {
              this.props.fetchQuestionsInvoice(payload);
            }
          }

          if (
            this.props.questionsInvoice[0].question !== null &&
            values.answer1
          ) {
            await this.props.checkInvoiceResponse(
              params,
              this.props.routes[this.nextStep],
              this.props.process
            );
            if (this.props.serverErrors.answer1) {
              Object.assign(payload, { invoice1: params.invoice1 });
              document.getElementById('invoice-form').reset();
              this.props.fetchQuestionsInvoice(payload);
            }
          }
        }
      } else {
        await this.props.checkFifthStep(
          params,
          this.props.routes[this.nextStep],
          this.props.process
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  validateWithOneInvoice = () => {
    const { validateWithOneInvoice } = this.props;
    return validateWithOneInvoice || this.props.gnpGasApplianceSaleEvaluation.gnp_sale.make_gnp_sale;
  };

  getOptionsFormat = (stockOptions, options) => {
    if (options) {
      stockOptions = [
        {
          key: 'option1',
          value: '1',
          label:
            _moneyFormat(options[0][0]) + ' - ' + _moneyFormat(options[0][1])
        },
        {
          key: 'option2',
          value: '2',
          label:
            _moneyFormat(options[1][0]) + ' - ' + _moneyFormat(options[1][1])
        },
        {
          key: 'option3',
          value: '3',
          label:
            _moneyFormat(options[2][0]) + ' - ' + _moneyFormat(options[2][1])
        },
        {
          key: 'option4',
          value: '4',
          label:
            _moneyFormat(options[3][0]) + ' - ' + _moneyFormat(options[3][1])
        }
      ];
      return stockOptions;
    }
  };

  _handleModalOpen = () => this.setState({ modalOpen: true });
  _handleModalClose = () => this.setState({ modalOpen: false });

  _handleSkipStepModalClose = () => {
    this.setState({ showingSkipStepModal: false });
  };

  _handleSkipStepModalConfirm = () => {
    this.props.skipStep(
      {
        contractId: this.props.contract
      },
      this.props.routes[this.nextStep],
      this.props.process
    );
  };

  render() {
    const {
      classes,
      serverErrors,
      portalIsLoading,
      invoice1,
      invoice2,
      portalFinancing,
      canViewInvoiceQuestions,
      canCheckInvoiceAnswers,
      announcements,
      invoiceValidationData,
      supplierCanSkipStep,
      isSecureSale,
      gnpGasApplianceSaleEvaluation,
      isSkipInvoiceQuestionActive
    } = this.props;

    const initialValues = {
        invoice1: invoice1.id || '',
        invoice1Date:
          (invoice1.date &&
            moment(invoice1.date, 'DD-MM-YYYY').format('YYYY/MM/DD')) ||
          null,
        invoice2: invoice2.id || '',
        invoice2Date:
          (invoice2.date &&
            moment(invoice2.date, 'DD-MM-YYYY').format('YYYY/MM/DD')) ||
          null,
        answer1: invoice1.answer || '',
        answer2: invoice2.answer || ''
      },
      validateWithOneInvoice = this.validateWithOneInvoice(),
      question1 = portalFinancing[0],
      question2 = portalFinancing[1] || '',
      innerWidthViewPort = window.innerWidth;
    const { isComplete } = this.state;

    let options1,
      options2,
      stockOptions1 = [],
      stockOptions2 = [];

    if (question1 || question2) {
      options1 = question1.options;
      options2 = question2.options;
    }

    if (portalIsLoading) {
      return <ActivityIndicator />;
    }

    const canSkipStep =
      !isSecureSale &&
      invoiceValidationData &&
      invoiceValidationData.enable_skip_invoice_validation &&
      invoiceValidationData.enable_skip_invoice_validation_by_sale[
        this.props.location.pathname.split('/')[1].replace('-', '_')
      ] === 1 &&
      supplierCanSkipStep;

    return (
      <div>
        <Paper className={classes.container}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema({ validateWithOneInvoice, isGnp: gnpGasApplianceSaleEvaluation.gnp_sale.make_gnp_sale })}
            onSubmit={this.onSubmit}
            render={({
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              handleSubmit,
              handleReset,
              handleBlur
            }) => (
              <Form className={classes.form} id={'invoice-form'}>
                <div className={classes.segmentHeaderOptions}>
                  <SegmentHeader className={classes.segmentHeader}>
                    CONTROL DE FACTURA
                    {canViewInvoiceQuestions && canCheckInvoiceAnswers && (
                      <IconButton
                        buttonRef={node => {
                          this.anchorEl = node;
                        }}
                        aria-label="Visibility"
                        classes={{ root: classes.iconButtonRoot }}
                        onClick={this._handleModalOpen}
                        title={'Ayuda'}
                      >
                        <HelpOutlineIcon
                          style={{ color: '#000000', opacity: '0.7' }}
                        />
                      </IconButton>
                    )}
                  </SegmentHeader>
                  {canSkipStep && (
                    <PrimaryButton
                      onClick={() => {
                        this.setState({ showingSkipStepModal: true });
                      }}
                      className={classes.button}
                    >
                      Omitir
                    </PrimaryButton>
                  )}
                </div>

                {announcements.length > 0 && (
                  <CustomDialog
                    open={this.state.modalOpen}
                    className={classes.sliderPopUp}
                    onClose={this._handleModalClose}
                    onConfirm={this._handleModalClose}
                    disabled={isComplete}
                    customWidth={true}
                    maxWidth={innerWidthViewPort < 1000 ? '100%' : 1000}
                    title={''}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: announcements[0].content
                      }}
                    />
                  </CustomDialog>
                )}

                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
                    <OutlinedTextField
                      name="invoice1"
                      type={'number'}
                      value={values.invoice1}
                      onChange={handleChange}
                      disabled={this.state.isValid}
                      onBlur={handleBlur}
                      error={
                        !!serverErrors.invoice1 ||
                        (touched.invoice1 && !!errors.invoice1)
                      }
                      helperText={
                        serverErrors.invoice1 ||
                        (touched.invoice1 && errors.invoice1)
                      }
                      label="Número de primera factura:"
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
                    <OurDatePicker
                      name="invoice1Date"
                      invalidLabel={'Fecha inválida'}
                      maxDateMessage={'Fecha inválida'}
                      minDateMessage={'Fecha inválida'}
                      invalidDateMessage={'Fecha inválida'}
                      disabled={this.state.isValid}
                      label={'Fecha emisión:'}
                      value={values.invoice1Date}
                      onChange={evt => {
                        setFieldValue('invoice1Date', evt.toString());
                      }}
                      onError={() => setFieldValue('invoice1Date', null)}
                      error={touched.invoice1Date && !!errors.invoice1Date}
                      helperText={touched.invoice1Date && errors.invoice1Date}
                      onBlur={handleBlur}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  {invoice1.id &&
                    question1 &&
                    !isSkipInvoiceQuestionActive &&
                    canCheckInvoiceAnswers &&
                    canViewInvoiceQuestions && (
                      <Grid item md={12} sm={12} xs={12}>
                        <h5 className={classes.question}>
                          {question1.question}
                        </h5>
                        {(question1.type === 'text' ||
                          question1.type === 'number') && (
                          <OutlinedTextField
                            name="answer1"
                            label="Respuesta"
                            value={values.answer1}
                            onChange={handleChange}
                            disabled={isComplete}
                            error={
                              !!serverErrors.answer1 ||
                              (touched.answer1 && !!errors.answer1)
                            }
                            helperText={
                              serverErrors.answer1 ||
                              (touched.answer1 && errors.answer1)
                            }
                          />
                        )}
                        {question1.type === 'date' && (
                          <OurDatePicker
                            name="answer1"
                            invalidLabel={'Fecha inválida'}
                            maxDateMessage={'Fecha inválida'}
                            minDateMessage={'Fecha inválida'}
                            disableFuture={false}
                            disabled={isComplete}
                            invalidDateMessage={'Fecha inválida'}
                            label={'Fecha:'}
                            value={values.answer1}
                            onChange={evt => {
                              setFieldValue('answer1', evt.toString());
                            }}
                            onError={() => setFieldValue('answer1', null)}
                            error={
                              !!serverErrors.answer1 ||
                              (touched.answer1 && !!errors.answer1)
                            }
                            helperText={
                              serverErrors.answer1 ||
                              (touched.answer1 && errors.answer1)
                            }
                            onBlur={handleBlur}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                        {question1.type === 'select' && (
                          <SimpleSelect
                            disabled={isComplete}
                            name={'answer1'}
                            value={values.answer1}
                            label={'Valor'}
                            options={this.getOptionsFormat(
                              stockOptions1,
                              options1
                            )}
                            onChange={handleChange}
                            error={
                              !!serverErrors.answer1 ||
                              (touched.answer1 && !!errors.answer1)
                            }
                            helperText={
                              serverErrors.answer1 ||
                              (touched.answer1 && errors.answer1)
                            }
                          />
                        )}
                      </Grid>
                    )}
                </Grid>
                {invoice2.id &&
                  !isSkipInvoiceQuestionActive &&
                  canCheckInvoiceAnswers &&
                  canViewInvoiceQuestions && (
                    <SegmentHeader className={classes.segmentHeader2} />
                  )}
                {!validateWithOneInvoice && !gnpGasApplianceSaleEvaluation.gnp_sale.make_gnp_sale && (
                  <Grid container spacing={24} alignItems="flex-start">
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      className={classes.gridItem}
                    >
                      <OutlinedTextField
                        name="invoice2"
                        type={'number'}
                        value={values.invoice2}
                        onChange={handleChange}
                        disabled={this.state.isValid}
                        onBlur={handleBlur}
                        error={
                          !!serverErrors.invoice2 ||
                          (touched.invoice2 && !!errors.invoice2)
                        }
                        helperText={
                          serverErrors.invoice2 ||
                          (touched.invoice2 && errors.invoice2)
                        }
                        label="Número de segunda factura:"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      className={classes.gridItem}
                    >
                      <OurDatePicker
                        name="invoice2Date"
                        invalidLabel={'Fecha inválida'}
                        maxDateMessage={'Fecha inválida'}
                        minDateMessage={'Fecha inválida'}
                        invalidDateMessage={'Fecha inválida'}
                        label={'Fecha emisión:'}
                        value={values.invoice2Date}
                        onChange={evt => {
                          setFieldValue('invoice2Date', evt.toString());
                        }}
                        disabled={this.state.isValid}
                        onError={() => setFieldValue('invoice2Date', null)}
                        error={touched.invoice2Date && !!errors.invoice2Date}
                        helperText={touched.invoice2Date && errors.invoice2Date}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    {invoice2.id &&
                      question2 &&
                      canCheckInvoiceAnswers &&
                      canViewInvoiceQuestions && (
                        <Grid item md={12} sm={12} xs={12}>
                          <h5 className={classes.question}>
                            {question2.question}
                          </h5>
                          {(question2.type === 'text' ||
                            question2.type === 'number') && (
                            <OutlinedTextField
                              name="answer2"
                              label="Respuesta"
                              value={values.answer2}
                              onChange={handleChange}
                              disabled={isComplete}
                              error={
                                !!serverErrors.answer2 ||
                                (touched.answer2 && !!errors.answer2)
                              }
                              helperText={
                                serverErrors.answer2 ||
                                (touched.answer2 && errors.answer2)
                              }
                            />
                          )}
                          {question2.type === 'date' && (
                            <OurDatePicker
                              name="answer2"
                              invalidLabel={'Fecha inválida'}
                              maxDateMessage={'Fecha inválida'}
                              minDateMessage={'Fecha inválida'}
                              disableFuture={false}
                              invalidDateMessage={'Fecha inválida'}
                              label={'Fecha:'}
                              value={values.answer2}
                              onChange={evt => {
                                setFieldValue('answer2', evt.toString());
                              }}
                              disabled={isComplete}
                              onError={() => setFieldValue('answer2', null)}
                              error={
                                !!serverErrors.answer2 ||
                                (touched.answer2 && !!errors.answer2)
                              }
                              helperText={
                                serverErrors.answer2 ||
                                (touched.answer2 && errors.answer2)
                              }
                              onBlur={handleBlur}
                              fullWidth
                              margin="normal"
                              variant="outlined"
                            />
                          )}
                          {question2.type === 'select' && (
                            <SimpleSelect
                              name={'answer2'}
                              value={values.answer2}
                              label={'Valor'}
                              withChip={false}
                              options={this.getOptionsFormat(
                                stockOptions2,
                                options2
                              )}
                              onChange={handleChange}
                              disabled={isComplete}
                              error={
                                !!serverErrors.answer2 ||
                                (touched.answer2 && !!errors.answer2)
                              }
                              helperText={
                                serverErrors.answer2 ||
                                (touched.answer2 && errors.answer2)
                              }
                            />
                          )}
                        </Grid>
                      )}
                  </Grid>
                )}
                <StepperButtons
                  onSubmit={handleSubmit}
                  goBack={this.props.goBack}
                />
              </Form>
            )}
          />
        </Paper>

        {canSkipStep && (
          <SkipValidateInvoiceModal
            isPresent={this.state.showingSkipStepModal}
            saleCap={invoiceValidationData.sale_cap_for_invoice_validation}
            handleClose={this._handleSkipStepModalClose}
            handleConfirm={this._handleSkipStepModalConfirm}
          />
        )}
      </div>
    );
  }
}
const styles = theme => ({
  container: {},
  segmentHeader: {
    marginTop: 3 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  segmentHeaderOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f5f5f5'
  },
  iconButtonRoot: {
    padding: 0,
    marginLeft: '2px'
  },
  segmentHeader2: {
    marginTop: '1em',
    marginBottom: '1.5em',
    padding: 2
  },
  gridItem: {
    paddingTop: '0 !important',
    paddingBottom: '12 !important'
  },
  table: {
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 3 * theme.spacing.unit
  },
  actionBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  question: {
    marginTop: 0,
    marginBottom: '15px',
    color: '#000000',
    opacity: 0.8,
    textTransform: 'uppercase'
  },
  mainButton: {
    alignSelf: 'flex-end',
    marginTop: 4 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  registerDateContainer: {
    marginTop: theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit
  },
  subtitle: {
    color: 'rgba(12,31,44,0.6)',
    fontSize: 15,
    textAlign: 'center',
    margin: 0,
    padding: 24,
    paddingTop: 20,
    paddingBottom: 0
  },
  dialog: {
    width: '80%'
  },
  dialogButton: {
    margin: 1 * theme.spacing.unit,
    marginTop: 0,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  button: {
    margin: 8,
    padding: '8px 16px'
  }
});

PortalValidateInvoicePage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(PortalValidateInvoicePage);
