import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import parseQuerystring from '../../../helpers/parseQuerystring';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import TextFieldInput from '../../shared/Inputs/TextField';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';
import { doctypes } from '../../../constants/enums';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';

class ClientFilters extends PureComponent {
  state = {
    name: '',
    identification: '',
    identificationType: 'todos',
    contract: '',
    clientExternalCode: ''
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    this.setState({
      ...this.state,
      ...query
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter && filter != 'todos'),
      'name',
      'identification',
      'identificationType',
      'contract',
      'clientExternalCode'
    );
    return filterParams;
  };

  render() {
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="clientExternalCode"
                  name="clientExternalCode"
                  value={this.state.clientExternalCode}
                  onChange={this._handleChange}
                  label="# Cliente"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="contract"
                  name="contract"
                  value={this.state.contract}
                  onChange={this._handleChange}
                  label="# Contrato"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldInput
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={this._handleChange}
                  label="Nombre Cliente"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <OutlinedSelectChippedInput
                  label={'Tipo de identificación'}
                  name={'identificationType'}
                  options={[...doctypes, { title: 'Todos', value: 'todos' }]}
                  value={this.state.identificationType}
                  onChange={this._handleSelectChange}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextFieldInput
                  id="identification"
                  name="identification"
                  value={this.state.identification}
                  onChange={this._handleChange}
                  label="Identificación"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = () => ({});
ClientFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(ClientFilters);
