import * as Yup from 'yup';

const names = /^[a-zA-Z\u00f1\u00d1]+(\s*[a-zA-Z\u00f1\u00d1\-]*)*[a-zA-Z\u00f1\u00d1\s]+$/;
const lastNames = /^[a-z áéíóúüñ']+(\s*[a-z áéíóúüñ'\-]*)*[a-z áéíóúüñ'\s]+$/i;
export const validationSchema = needsCosigner => {
  return Yup.object().shape({
    debtorLastName: Yup.string()
      .required('El campo es requerido')
      .matches(lastNames, 'No se permiten números.'),
    cosignerLastName: needsCosigner
      ? Yup.string()
          .required('El campo es requerido')
          .matches(lastNames, 'No se permiten números.')
      : Yup.string().default('')
  });
};

export const validationSchemaCause = () => {
  return Yup.object().shape({
    cause: Yup.string().required('El campo es requerido')
  });
};

export const validationSchemaAdditionalData = () => {
  return Yup.object().shape({
    lastName: Yup.string()
      .required('El campo es requerido')
      .matches(lastNames, 'No se permiten números.'),
    name: Yup.string()
      .required('El campo es requerido')
      .matches(names, 'No se permiten caracteres especiales o números.')
  });
};

export const validationSchemaAdditionalDataOTP = () => {
  return Yup.object().shape({
    lastName: Yup.string()
      .required('El campo es requerido')
      .matches(names, 'No se permiten caracteres especiales o números.'),
    name: Yup.string()
      .required('El campo es requerido')
      .matches(names, 'No se permiten caracteres especiales o números.'),
    email: Yup.string()
      .required('El campo es requerido')
      .email('El formato del correo no es correcto.'),
    mobile: Yup.string()
      .required('El campo es requerido')
      .matches(/^\d{10}$/, 'El formato del número celular no es correcto')
      .length(10, 'El número celular debe tener 10 dígitos.')
  });
};
