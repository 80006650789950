import moment from 'moment-timezone';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
export const columns = [
  {
    title: 'Nombre',
    name: 'pretty_name'
  },
  {
    title: 'Rango fechas',
    name: 'pretty_date'
  },
  {
    title: 'Valor mínimo',
    name: 'pretty_minimum_value'
  },
  {
    title: 'Cuotas mínimas',
    name: 'pretty_minimum_quota'
  },
  {
    title: 'Cuotas máximas',
    name: 'pretty_max_quota'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      minimumValue: datum.minimumValue.toString(),
      pretty_name: datum.name || '--',
      pretty_date: `${moment(datum.startAt)
        .locale('es')
        .format('DD MMM YYYY') || '--'} - ${moment(datum.endAt)
        .locale('es')
        .format('DD MMM YYYY') || '--'}`,
      pretty_minimum_value: `${_moneyFormat(datum.minimumValue)}`,
      pretty_minimum_quota: datum.minimumQuota || '--',
      pretty_max_quota: datum.maxQuota || '--'
    };
  });
