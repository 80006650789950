import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RemoteDigitalSale from './RemoteDigitalSale';
import { newAlert } from '../../../actions/alert_actions';
import {
  persistFinancing,
  toggleBaseErrorModal,
  clearFinancing,
  setNextStep,
  fetchGlobalParameters
} from '../../../actions/portal_financing_actions';
import {
  resetStepper,
  setSteps,
  setOptionalSteps,
  persistStepper,
  setCurrentStep
} from '../../../actions/stepper_actions';
import _get from 'lodash/get';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  return {
    routes: state.stepper.routes,
    skips: state.stepper.skippedSteps,
    process: state.stepper.process,
    remoteDigitalSaleWithUUPNext: state.stepper.remoteDigitalSaleWithUUPNext,
    remoteDigitalSaleWithoutUUPNext:
      state.stepper.remoteDigitalSaleWithoutUUPNext,
    saleCompleted: pf.saleCompleted,
    currentUser: state.authentication.currentUser,
    loadingParameters: state.requestState.requestFetchGlobalParametersSend,
    globalParameters: {
      validateRemoteSaleInvoices: pf.validate_remote_sale_invoices,
      validateSaleInvoices: pf.validate_sale_invoices,
      validatePromissoryInvoices: pf.validate_promissory_invoices,
      showScoringStep:
        _get(
          state,
          'authentication.currentUser.supplier.hasScoringExtraQuotum'
        ) && state.authentication.currentUser.canConsultScoringOnSale,
      identityValidationWithEnrollmentClients: pf.identity_validation_with_enrollment_clients
    },
    details: {
      debtor_contract_number: pf.debtor_contract_number,
      assigned_quota: pf.assigned_quota,
      used_quota: pf.used_quota,
      available_quota: pf.available_quota,
      identification: pf.identification
    },
    server_errors: pf.errors,
    baseErrorOpen: pf.baseErrorOpen,
    baseErrorText: pf.baseErrorText,
    baseErrorTitle: pf.baseErrorTitle,
    baseErrorRoute: pf.baseErrorRoute
  };
}

function mapDispatchToProps(dispatch) {
  return {
    persistFinancing: bindActionCreators(persistFinancing, dispatch),
    resetStepper: bindActionCreators(resetStepper, dispatch),
    setSteps: bindActionCreators(setSteps, dispatch),
    setOptionalSteps: bindActionCreators(setOptionalSteps, dispatch),
    persistStepper: bindActionCreators(persistStepper, dispatch),
    toggleBaseErrorModal: bindActionCreators(toggleBaseErrorModal, dispatch),
    clearFinancing: bindActionCreators(clearFinancing, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    fetchGlobalParameters: bindActionCreators(fetchGlobalParameters, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoteDigitalSale);
