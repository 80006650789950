import ClientList from './ClientList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchClients } from '../../../actions/client_actions';

function mapStateToProps(state) {
  const { clients, meta } = state.client;
  return {
    clients,
    meta,
    requestSend: state.requestState.requestSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchClients: bindActionCreators(fetchClients, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ClientList);
