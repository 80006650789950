export const options = [
  {
    value: 'true',
    label: 'Si'
  },
  {
    value: 'false',
    label: 'No'
  }
];
