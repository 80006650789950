import ArticleList from './ArticleList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchArticles,
  updateArticle,
  createArticle,
  fetchArticleListExcelReportFile
} from '../../../actions/article_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';
import { errorOnRequest } from '../../../actions/request_state';
function mapStateToProps(state) {
  const currentUser = state.authentication.currentUser;
  const canUpdateArticles = currentUser ? currentUser.canUpdateArticles : false;
  const canCreateArticles = currentUser ? currentUser.canCreateArticles : false;
  const canExportArticlesToExcel = currentUser
    ? currentUser.canExportArticlesToExcel
    : false;
  const currentUserRoles = currentUser
    ? currentUser.roles.map(role => role.name)
    : [];
  return {
    canUpdateArticles: canUpdateArticles,
    canCreateArticles: canCreateArticles,
    canExportArticlesToExcel: canExportArticlesToExcel,
    canSetMaxItemsPerArticle:
      state.authentication.currentUser.canSetMaxItemsPerArticle,
    articles: state.articles.articles || [],
    articleSaved: state.articles.saved,
    meta: state.articles.meta,
    articleTypes: state.globalSetting.orgData.article_types,
    categories: state.globalSetting.orgData.categories,
    concepts: state.globalSetting.orgData.concepts,
    brands: state.globalSetting.orgData.brands,
    suppliers: state.globalSetting.orgData.suppliers,
    requestArticleListSend: state.requestState.requestArticleListSend,
    requestSaveArticleSend: state.requestState.requestSaveArticleSend,
    requestErrorsOnSaveArticle: state.requestState.requestErrorsOnSaveArticle,
    currentUserRoles: currentUserRoles,
    fetchingExcelReportFile:
      state.requestState.requestArticlesListExcelReportFileSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchArticles: bindActionCreators(fetchArticles, dispatch),
    createArticle: bindActionCreators(createArticle, dispatch),
    updateArticle: bindActionCreators(updateArticle, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    fetchArticleListExcelReportFile: bindActionCreators(
      fetchArticleListExcelReportFile,
      dispatch
    ),
    errorOnRequest: bindActionCreators(errorOnRequest, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ArticleList);
