import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

class SimpleTable extends PureComponent {
  render() {
    const { classes, headers, rows, subText, ...props } = this.props;
    return (
      <div className={classes.root}>
        <Table {...props} className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHead}>
              {headers.map((item, idx) => (
                <TableCell
                  key={item.name || idx}
                  {...item.headerProps}
                  className={classes.tableHeadRow}
                >
                  {item.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item, idx) => (
              <TableRow key={item.key || idx} className={classes.tableBody}>
                {headers.map(column => (
                  <TableCell
                    key={column.name}
                    {...column.cellProps}
                    className={classes.tableBodyRow}
                  >
                    {item[column.name]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {subText && <p className={classes.tableSubText}>{subText}</p>}
      </div>
    );
  }
}
const styles = theme => ({
  root: {
    overflow: 'hidden'
  },
  table: {
    // minWidth: '100% !important'
  },
  tableHead: {
    height: theme.spacing.unit * 5
  },
  tableHeadRow: {
    backgroundColor: theme.palette.secondary.lightest
  },
  tableBody: {
    height: theme.spacing.unit * 6,
    border: '0'
  },
  tableBodyRow: {
    backgroundColor: theme.palette.background.paper
  },
  tableSubText: {
    color: theme.typography.color.light,
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    paddingTop: '6px',
    margin: `0px ${theme.spacing.unit * 3}px `
  }
});

SimpleTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
};

export default withStyles(styles, { withTheme: true })(SimpleTable);
