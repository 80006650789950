import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DialogContentText from '@material-ui/core/DialogContentText';
import OurDatePicker from '../../shared/CustomDatePicker';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';

const validationSchema = Yup.object().shape({
  committeeDate: Yup.string().required('El campo es requerido')
});

const AcceptProposalModal = props => {
  const { open, onClose, onSubmit } = props;
  return (
    <Formik
      initialValues={{committeeDate: ''}}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={
        ({
          values,
          handleSubmit,
          handleChange,
          touched,
          handleBlur,
          errors,
          handleReset,
          setFieldValue,
          setFieldTouched,
        }) => {
          const _onClose = () => {
            handleReset();
            onClose();
          };
          return (
            <Dialog open={open} onClose={_onClose}>
              <DialogTitle>Aceptar propuesta</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Para aceptar la propuesta indica la fecha del comité y presiona en aceptar. Si deseas descartar la aceptación presiona en cancelar.
                </DialogContentText>
                <OurDatePicker
                  name='committeeDate'
                  invalidLabel="Fecha inválida"
                  maxDateMessage="Fecha inválida"
                  minDateMessage="Fecha inválida"
                  invalidDateMessage='Fecha inválida'
                  label="Fecha del comité"
                  value={values.committeeDate}
                  onChange={
                    date => {
                      setFieldTouched('committeeDate = ', true);
                      const momentDate = moment(date);
                      if (momentDate.isValid()) {
                        const parsedDate = momentDate.format('YYYY-MM-DD 05:00');
                        setFieldValue('committeeDate', parsedDate);
                      } else {
                        setFieldValue('committeeDate', '');
                      }
                    }
                  }
                  onBlur={handleBlur}
                  onError={
                    () => setFieldValue('committeeDate', '')
                  }
                  error={
                    touched.committeeDate &&
                    !!errors.committeeDate
                  }
                  helperText={
                    touched.committeeDate &&
                    errors.committeeDate
                  }
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={_onClose} color="secondary">
                  Cancelar
                </Button>
                <ProgressButton
                  color="secondary"
                  onClick={ () => {
                    handleSubmit(values);
                    if (_isEmpty(errors) && !_isEmpty(touched)) {
                      setTimeout(_onClose, 1000);
                    }
                  }}
                >
                  Aceptar
                </ProgressButton>
              </DialogActions>
            </Dialog>
          );
        }
      }
    />
  );
};

export default AcceptProposalModal;