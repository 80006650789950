import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import parseQuerystring from '../../../helpers/parseQuerystring';

import TextFieldInput from '../../shared/Inputs/TextField';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import PickCustomDateModal from '../../shared/Inputs/PickCustomDateModal';

import _debounce from 'lodash/debounce';
import _find from 'lodash/find';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import {
  typeableModelOptions,
  originalDateOptions,
  dateOptions
} from './dropDownOptions';

import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';

import { CUSTOM_DATE } from '../SalesList/constants';

class SaleFilter extends PureComponent {
  state = {
    modelId: '',
    typeableModel: 'todas',
    openPickCustomDate: null,
    contract: '',
    registered_at: 'todas',
    customDate: CUSTOM_DATE
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    const custom = {};
    if (query && query.registered_at) {
      const split = query.registered_at.split(' - ');
      if (split[1]) custom.customDate = query.registered_at;
    }
    this.setState({
      ...this.state,
      ...query,
      ...custom
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, this._handleChangeFilter);
  };

  _handleAutoSuggest = name => (event, { newValue }) => {
    this.setState({ [name]: newValue }, (name, newValue) =>
      this._searchCallback(name, newValue)
    );
  };

  _handleDateFilter = ({ target: { name, value } }) => {
    if (value === this.state.customDate) {
      this.setState({ openPickCustomDate: name });
    } else {
      this.setState(
        { [name]: value, customDate: CUSTOM_DATE },
        this._handleChangeFilter
      );
    }
  };

  _handleDateRangeFilter = ({ target: { name, value } }) => {
    const customDate = !_find(
      originalDateOptions,
      availableOption => availableOption.value === value
    )
      ? value
      : CUSTOM_DATE;
    this.setState(
      { [name]: value, openPickCustomDate: null, customDate },
      this._handleChangeFilter
    );
  };

  _handleClosePickCustomDate = () =>
    this.setState({ openPickCustomDate: null });

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter && filter != 'todas'),
      ['modelId', 'typeableModel', 'contract', 'registered_at']
    );
    return filterParams;
  };

  render() {
    const { classes } = this.props;

    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={3}>
                <TextFieldInput
                  id="modelId"
                  name="modelId"
                  value={this.state.modelId}
                  onChange={this._handleChange}
                  label="# Proceso"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <OutlinedSelectChippedInput
                  label={'Tipo'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'typeableModel'}
                  options={typeableModelOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'Tipo'}
                  value={this.state.typeableModel}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <OutlinedSelectChippedInput
                  label={'Fecha'}
                  name={'registered_at'}
                  options={dateOptions(
                    this.state.customDate,
                    this.state.registered_at
                  )}
                  value={this.state.registered_at}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  inputLabel={'Delivery Date'}
                  onChange={this._handleDateFilter}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextFieldInput
                  id="contract"
                  name="contract"
                  value={this.state.contract}
                  onChange={this._handleChange}
                  label="# Contrato"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {!!this.state.openPickCustomDate && (
              <PickCustomDateModal
                name={this.state.openPickCustomDate}
                onClose={this._handleClosePickCustomDate}
                onSave={this._handleDateRangeFilter}
                value={this.state.registered_at}
              />
            )}
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    // marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
SaleFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(SaleFilter);
