import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MySnackbarContentWrapper from './CustomSnack';

class CustomizedSnackbars extends React.Component {
  handleClick = () => {
    this.setState({ open: true });
  };

  handleClickOpen = () => {
    // this.setState({open:true})
    const { clear, newAlert } = this.props;
    clear();
    newAlert('success', 'TEST TITLE', 'TEST MESSAGE');
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const { closeAlert } = this.props;
    closeAlert();
    // this.setState({ open: false });
  };

  render() {
    const {
      children,
      AlertOpen = false,
      AlertType = 'success',
      AlertText = ''
    } = this.props;
    if (!children) {
      return null;
    }
    return (
      <Fragment>
        {children}

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={AlertOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={AlertType}
            message={AlertText}
          />
        </Snackbar>
      </Fragment>
    );
  }
}

CustomizedSnackbars.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
  AlertOpen: PropTypes.bool,
  AlertType: PropTypes.string,
  AlertText: PropTypes.string,
  newAlert: PropTypes.func,
  closeAlert: PropTypes.func,
  clear: PropTypes.func
};

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit
  }
});

export default withStyles(styles2)(CustomizedSnackbars);
