import { LIST_CONTRACT_STRATUM } from '../constants/contract_stratum_constants';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import RequestService from '../services/request_service';

const nodeRequestService = new RequestService('node');

function setContractStrata(data, meta) {
  return {
    type: LIST_CONTRACT_STRATUM,
    data,
  };
}

export const fetchContractStrata = () => async dispatch => {
  dispatch(sendRequest());
  try {
    const payload = await nodeRequestService.get('contracts/listContractStrata', {}, true);
    dispatch(setContractStrata(payload.data));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest());
  }
};
