const styles = theme => ({
  textField: {
    width: '100%'
  },
  root: {
    margin: 0
  },
  outlinedInput: {
    '&$focused $notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
      borderWidth: 2
    }
  },
  label: {
    color: 'rgba(12,31,44,0.6)',
    '&$focused': {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  focused: {},
  notchedOutline: {},
  formHelperTextRoot: {
    marginTop: theme.spacing.unit * 0.375,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: theme.spacing.unit * 2,
    fontFamily: theme.typography.font.main,
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '16px',
    minHeight: '16px'
  },
  inputBaseRoot: {
    paddingTop: theme.spacing.unit * 2.25,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2.25,
    paddingLeft: theme.spacing.unit * 2
  },
  inputBaseRootWidthEndAddorment: {
    paddingTop: theme.spacing.unit * 2.25,
    paddingRight: 0,
    paddingBottom: theme.spacing.unit * 2.25,
    paddingLeft: theme.spacing.unit * 2
  },
  inputBaseRootWidthStartAdornment: {
    paddingTop: theme.spacing.unit * 2.25,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2.25,
    paddingLeft: theme.spacing.unit * 0.5
  },
  closeIcon: {
    color: 'rgba(12,31,44,0.54)',
    cursor: 'pointer',
    marginRight: theme.spacing.unit * 0.25
  },
  codeInput: {
    maxWidth: 70,
    margin: [[0, 2, 0, 2]],
    [theme.breakpoints.up('sm')]: {
      margin: [[0, 14, 0, 14]]
    }
  },
  codeLabel: {
    fontSize: 40,
    fontWeight: 500,
    textAlign: 'center',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    },
    '-moz-appearance': 'textfield'
  }
});

export default styles;
