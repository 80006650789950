import DashboardSellers from './DashboardSellers';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchPointsOfSaleNames,
  fetchPosDashboard
} from '../../../actions/points_of_sale_actions';
import {
  fetchSellersSummary,
  fetchSellersSales,
  fetchTopRanking,
  fetchDataSalesman,
  fetchDataSupplier,
  fetchSupplierFilter,
  fetchFilterSelection,
  fetchCounterSummary
} from '../../../actions/dashboard_sellers_actions';
import { fetchSuppliersDashboard } from '../../../actions/suppliers_actions';
import { newAlert } from '../../../actions/alert_actions';

function mapStateToProps(state) {
  return {
    requestDashboardSellersSummarySend:
      state.requestState.requestDashboardSellersSummarySend,
    requestDashboardSellersSalesSend:
      state.requestState.requestDashboardSellersSalesSend,
    requestDashboardDataSalesmanSend:
      state.requestState.requestDashboardDataSalesmanSend,
    requestDashboardDataSupplierSend:
      state.requestState.requestDashboardDataSupplierSend,
    requestTopRankingSend: state.requestState.requestTopRankingSend,
    requestDashboardCounterSummarySend:
      state.requestState.requestDashboardCounterSummarySend,
    currentUser: state.authentication.currentUser,
    canListDashboardSellersSummaries:
      state.authentication.currentUser.canListDashboardSellersSummaries,
    canListDashboardSellersSales:
      state.authentication.currentUser.canListDashboardSellersSales,
    canListDashboardTopRanking:
      state.authentication.currentUser.canListDashboardTopRanking,
    canListDataSalesman: state.authentication.currentUser.canListDataSalesman,
    canListDataSupplier: state.authentication.currentUser.canListDataSupplier,
    canViewSummary: state.authentication.currentUser.canViewSummary,
    dataSellersSummary: state.dashboardSellers.sellersSummary,
    dataSellersSales: state.dashboardSellers.sellersSales,
    dataTopRanking: state.dashboardSellers.topRanking,
    dataSalesman: state.dashboardSellers.dataSalesman,
    dataSupplier: state.dashboardSellers.dataSupplier,
    counterSummary: state.dashboardSellers.counterSummary,
    pointsOfSales: state.pointOfSale.pointsOfSaleNames,
    pointsOfSaleList: state.pointOfSale.pointsOfSale,
    suppliers: state.suppliers.suppliers,
    currentUserPos: state.authentication.currentUser.pointOfSales,
    canViewDashboardCounters:
      state.authentication.currentUser.canViewDashboardCounters,
    supplierFilter: state.dashboardSellers.supplierFilter,
    filterSelection: state.dashboardSellers.filterSelection,
    hugeSurfaceCoordinatorRoles:
      state.globalSetting.orgData.huge_surface_coordinator_roles,
    counterStates:
      state.globalSetting.orgData.counter_states_at_salesman_history,
    zones: state.globalSetting.orgData.geographic_locations,
    saleChannels: state.globalSetting.orgData.sale_channels
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSellersSummary: bindActionCreators(fetchSellersSummary, dispatch),
    fetchSellersSales: bindActionCreators(fetchSellersSales, dispatch),
    fetchTopRanking: bindActionCreators(fetchTopRanking, dispatch),
    fetchDataSalesman: bindActionCreators(fetchDataSalesman, dispatch),
    fetchDataSupplier: bindActionCreators(fetchDataSupplier, dispatch),
    fetchSupplierFilter: bindActionCreators(fetchSupplierFilter, dispatch),
    fetchFilterSelection: bindActionCreators(fetchFilterSelection, dispatch),
    fetchPointsOfSaleNames: bindActionCreators(
      fetchPointsOfSaleNames,
      dispatch
    ),
    fetchPointsOfSaleList: bindActionCreators(fetchPosDashboard, dispatch),
    fetchCounterSummary: bindActionCreators(fetchCounterSummary, dispatch),
    fetchSuppliers: bindActionCreators(fetchSuppliersDashboard, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DashboardSellers);
