import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Stepper from '../../shared/Stepper';
import RegisterPromissoryErrors from './RegisterPromissoryErrors';
import { Route } from 'react-router-dom';
import ActivityIndicator from '../../shared/ActivityIndicator';
import {
  generateRoutes,
  getSteps,
  getOptionals,
  getRoutes,
  goBack
} from '../../../helpers/stepperFunctions';

class RegisterPromissory extends Component {
  state = {
    persisted: false,
    routeList: []
  };

  async componentDidMount() {
    const process = 'promissory';
    if (this.props.location.pathname.includes('validate-debtor')) {
      await this.props.clearFinancing();
      await this.props.resetStepper();
    } else {
      this.props.persistFinancing();
      this.props.persistStepper();
    }

    this.props.globalParameters.validatePromissoryInvoices === null &&
      (await this.props.fetchGlobalParameters());
    const routeList = generateRoutes(process, this.props.globalParameters);

    this.props.setSteps(getSteps(routeList), getRoutes(routeList), process);
    this.props.setOptionalSteps(getOptionals(routeList));

    this.setState({ routeList, persisted: true });
  }

  _renderStep = () => {
    const { location, routes, skips, history } = this.props;
    const currentStep = routes.indexOf(location.pathname);
    const maxStep = this.props[`${this.props.process}Next`];
    const route = this.state.routeList.find(
      route => route.path === location.pathname
    );
    if (currentStep >= 0 && maxStep >= 0 && route) {
      if (currentStep <= maxStep) {
        return (
          <Fragment>
            <Stepper />
            <Route
              exact
              path={route.path}
              render={props => (
                <route.component
                  {...props}
                  goBack={() => goBack(routes, skips, location.pathname)}
                />
              )}
            />
          </Fragment>
        );
      } else {
        history.push(routes[maxStep]);
        this.props.newAlert(
          'error',
          'ERROR:',
          'No puedes ingresar a este paso sin haber completado los anteriores.'
        );
      }
    } else {
      history.push(routes[maxStep]);
      this.props.newAlert(
        'error',
        'ERROR:',
        'La ruta ingresada no existe en el registro de pagaré.'
      );
    }
  };

  render() {
    const {
      server_errors,
      baseErrorOpen,
      baseErrorText,
      baseErrorTitle,
      baseErrorRoute,
      toggleBaseErrorModal,
      loadingParameters
    } = this.props;
    if (loadingParameters && location.pathname.includes('validate-debtor'))
      return <ActivityIndicator />;
    if (!this.state.persisted) return null;
    return (
      <Fragment>
        <RegisterPromissoryErrors
          server_errors={server_errors}
          baseErrorOpen={baseErrorOpen}
          baseErrorText={baseErrorText}
          baseErrorTitle={baseErrorTitle}
          toggleModal={toggleBaseErrorModal}
          baseErrorRoute={baseErrorRoute}
        />
        {this._renderStep()}
      </Fragment>
    );
  }
}

RegisterPromissory.propTypes = {
  resetStepper: PropTypes.func.isRequired,
  setSteps: PropTypes.func.isRequired,
  setOptionalSteps: PropTypes.func.isRequired,
  persistFinancing: PropTypes.func.isRequired,
  persistStepper: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default RegisterPromissory;
