import {
  SET_SCHEDULE_POINT_OF_SALE_CONFIGURATIONS_LIST_DATA,
  OPEN_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL,
  CLOSE_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL,
  SET_CATEGORIES_IN_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL
} from '../constants/schedule_point_of_sale_configuration_constants';

const initialState = {
  configs: [],
  categories: [],
  meta: {},
  formModalIsOpen: false
};

export function schedulePointOfSaleConfigurations(state = initialState, action) {
  switch (action.type) {
    case SET_SCHEDULE_POINT_OF_SALE_CONFIGURATIONS_LIST_DATA:
      return {
        ...state,
        configs: action.data,
        meta: {
          totalCount: action.meta.total_count,
          currentPage: action.meta.current_page
        }
      };
    case OPEN_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL:
      return {
        ...state,
        formModalIsOpen: true
      };
    case CLOSE_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL:
      return {
        ...state,
        formModalIsOpen: false
      };
    case SET_CATEGORIES_IN_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL:
      return {
        ...state,
        categories: action.data
      };
    default: {
      return state;
    }
  }
};