import LocationExceptionList from './LocationExceptionList';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  fetchExceptionConfigurationList,
  createExceptionConfiguration,
  openExceptionConfigurationFormModal,
  closeExceptionConfigurationFormModal,
  updateExceptionConfiguration,
  deleteExceptionConfiguration
} from '../../../actions/location_exception_configuration_actions';

function mapStateToProps(state) {
  return {
    configs: state.exceptionConfigurations.configs,
    meta: state.exceptionConfigurations.meta,
    loading: state.requestState.requestExceptionConfigurationListSend,
    canCreateLocationExceptions: state.authentication.currentUser.canCreateLocationExceptions,
    canUpdateLocationExceptions: state.authentication.currentUser.canUpdateLocationExceptions,
    canDestroyLocationExceptions: state.authentication.currentUser.canDestroyLocationExceptions,
    geographicLocations: state.globalSetting.orgData.geographic_locations,
    suppliers: state.globalSetting.orgData.suppliers,
    modalSubmitLoading: (
      state.requestState.requestExceptionConfigurationCreateSend ||
      state.requestState.requestExceptionConfigurationUpdateSend
    ),
    modalDestroyLoading: state.requestState.requestExceptionConfigurationDeleteSend,
    formModalIsOpen: state.exceptionConfigurations.formModalIsOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchExceptionConfigurationList: bindActionCreators(fetchExceptionConfigurationList, dispatch),
    createConfig: bindActionCreators(createExceptionConfiguration, dispatch),
    openFormModal: bindActionCreators(openExceptionConfigurationFormModal, dispatch),
    closeFormModal: bindActionCreators(closeExceptionConfigurationFormModal, dispatch),
    updateConfig: bindActionCreators(updateExceptionConfiguration, dispatch),
    deleteConfig: bindActionCreators(deleteExceptionConfiguration, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LocationExceptionList);