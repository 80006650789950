import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  ComercialContractStrata
} from '../../../constants/enums';

import PortalContractInformationPage from './PortalContractInformationPage';
import {
  clearFinancing,
  checkSecondStep,
  setNextStep,
  toggleBaseErrorModal,
  addQuotas,
  moveQuota,
  fetchTransferQuotas,
  resetAddQuotasData,
  resetMoveQuotaData,
  cancelTransfer
} from '../../../actions/portal_financing_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import { newAlert } from '../../../actions/alert_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  return {
    useScoring: pf.useScoring,
    requestFetchTransferQuotasSend:
      state.requestState.requestFetchTransferQuotasSend,
    canValidateNewQuotaTransferOnSale:
      state.authentication.currentUser.canValidateNewQuotaTransferOnSale,
    canRevokeQuotaTransfer:
      state.authentication.currentUser.canRevokeQuotaTransfer,
    requestAddQuotasSend: state.requestState.requestAddQuotasSend,
    requestMoveQuotaSend: state.requestState.requestMoveQuotaSend,
    requestCancelTransferSend: state.requestState.requestCancelTransferSend,
    transferErrors: state.requestState.requestErrorsOnFetchTransferQuotas,
    debtorIsHolder: pf.holder,
    process: state.stepper.process,
    nextStep: state.stepper.nextStep,
    portalNext: state.stepper.portalNext,
    promissoryNext: state.stepper.promissoryNext,
    remoteDigitalSaleWithUUPNext: state.stepper.remoteDigitalSaleWithUUPNext,
    remoteDigitalSaleWithoutUUPNext: state.stepper.remoteDigitalSaleWithoutUUPNext,
    promissory: pf.promissory,
    routes: state.stepper.routes,
    loading: pf.stepIsLoading,
    addQuotasData: pf.addQuotasData,
    moveQuotaData: pf.moveQuotaData,
    ongoingTransfer: pf.ongoingTransfer,
    details: {
      doctype: pf.type_of_identification.toUpperCase(),
      docid: pf.identification,
      name: `${pf.name} ${pf.last_name ? pf.last_name : ''}`,
      locality: pf.locality,
      department: pf.department,
      phone: pf.phone || 'SIN TELÉFONO',
      address: pf.address,
      category: pf.category,
      subcategory: pf.is_commercial_sale ? ComercialContractStrata[pf.stratum] : `Estrato ${pf.stratum}`, 
      creditBlocked: 'NO',
      consumptionBalance: pf.network_balance,
      violatedPolicies: pf.breached_policies,
      segmentation: pf.segment
    },
    debtor_cache: {
      debtor_identification_type: pf.debtor_identification_type,
      debtor_identification: pf.debtor_identification,
      debtor_contract_number: pf.debtor_contract_number,
      debtor_birthdate: pf.debtor_birthdate
    },
    cosigner_cache: {
      cosigner_identification_type: pf.cosigner_identification_type || null,
      cosigner_identification: pf.cosigner_identification || null,
      cosigner_contract_number: pf.cosigner_contract_number || null,
      cosigner_birthdate: pf.cosigner_birthdate || null
    },
    gnpGasApplianceSaleEvaluation: pf.gnp_gas_appliance_evaluation,
    gasApplianceCategories:
      state.globalSetting.orgData.gas_appliance_categories,
    gnpCategories: state.globalSetting.orgData.gnp_categories
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearFinancing: bindActionCreators(clearFinancing, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    checkSecondStep: bindActionCreators(checkSecondStep, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    toggleBaseErrorModal: bindActionCreators(toggleBaseErrorModal, dispatch),
    addQuotas: bindActionCreators(addQuotas, dispatch),
    moveQuota: bindActionCreators(moveQuota, dispatch),
    fetchTransferQuotas: bindActionCreators(fetchTransferQuotas, dispatch),
    resetAddQuotasData: bindActionCreators(resetAddQuotasData, dispatch),
    resetMoveQuotaData: bindActionCreators(resetMoveQuotaData, dispatch),
    cancelTransfer: bindActionCreators(cancelTransfer, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PortalContractInformationPage);
