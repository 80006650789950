import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import { Grid } from '@material-ui/core';
import GridItem from '../../shared/GridItem';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
import SellerCard from './SellerCard';

const icon_money = require('../../../assets/icons/money.svg');
const icon_coins = require('../../../assets/icons/dolar.svg');

const styles = theme => ({
  header: {
    textAlign: 'center'
  },
  item: {
    padding: '0px !important'
  }
});

const actualMonth = () => {
  const meses = new Array(
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  );
  const f = new Date();
  return meses[f.getMonth()];
};

export const Header = ({
  classes,
  isLoading,
  role,
  data,
  supplierSelected
}) => (
  <div className={classnames(classes.row, classes.header)}>
    {!role && (
      <Grid container spacing={4} justify="center">
        <GridItem item sm={6} xs={12}>
          <SellerCard
            icon={icon_money}
            month={actualMonth()}
            message={supplierSelected ? 'Ventas Aliado: ' : 'Ventas Totales: '}
            totalCount={{
              color: '#FEB00B',
              value: _moneyFormat(data.totalSupplier)
            }}
            isLoading={isLoading}
          />
        </GridItem>
        <GridItem item sm={6} xs={12}>
          <SellerCard
            icon={icon_coins}
            message={'Mis Ventas: '}
            totalCount={{
              color: '#DB4844',
              value: _moneyFormat(data.totalSalesman)
            }}
            isLoading={isLoading}
          />
        </GridItem>
      </Grid>
    )}

    {role && (
      <Grid container className={classes.root}>
        <GridItem item xs>
          <SellerCard
            icon={icon_coins}
            month={actualMonth()}
            message={'Mis Ventas: '}
            totalCount={{
              color: '#DB4844',
              value: _moneyFormat(data.totalSalesman)
            }}
            isLoading={isLoading}
          />
        </GridItem>
      </Grid>
    )}
  </div>
);

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles),
  {
    withTheme: true
  }
)(Header);
