import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required('Nombre es requerido'),
  description: Yup.string().nullable(),
  brand_id: Yup.number().nullable().required('Marca es requerida'),
  category_id: Yup.number().nullable().required('Categoria es requerida'),
  concept_id: Yup.number().nullable().required('Concepto es requerido'),
  maxItems: Yup.number().positive('El número máximo de unidades debe ser un número positivo').nullable(),
  iva: Yup.number().nullable().min(0, 'El valor minimo a ingresar es 0').max(100, 'El valor maximo a ingresar es 100').default(0),
  suppliers: Yup.array().min(1, 'Debe elegir al menos un Proveedor'),
  article_types: Yup.array().min(1, 'Debe elegir al menos un Tipo de Artículo')
});

export default validationSchema;
