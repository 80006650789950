import moment from 'moment-timezone';
import _compact from 'lodash/compact';
// import {STATUSES} from './constants';
import { PROMISSORY_STATUSES } from '../../../constants/enums';

export const columns = [
  {
    title: 'Código',
    name: 'code'
  },
  {
    title: 'Fecha',
    name: 'registeredDate'
  },
  {
    title: 'Nombre Deudor',
    name: 'debtor'
  },
  {
    title: '# Contrato',
    name: 'contractId'
  },
  {
    title: 'Unico',
    name: 'isUnique'
  },
  {
    title: 'Inmaterializado',
    name: 'isUnmaterialized'
  },
  {
    title: 'Estado',
    name: 'status'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      registeredDate:
        moment(datum.registeredAt)
          .locale('es')
          .format('DD MMM YYYY') || '--',
      debtor:
        _compact([datum.debtorName, datum.debtorLastName]).join(' ') || '--',
      status: PROMISSORY_STATUSES[datum.status],
      isUnique: datum.isUnique ? 'Si' : 'No',
      isUnmaterialized: datum.isUnmaterialized ? 'Si' : 'No'
    };
  });
