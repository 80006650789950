import {
  SET_PROFILES_LIST,
  SET_SAVE_PROFILE_MODAL_OPEN,
} from '../constants/line_executive_profile_constants';

const initialState = {
  profilesList: [],
  metaData: { totalCount: 0 },
  saveProfileModal: {
    open: false,
    profile: {},
  }
};

export function lineExecutiveProfile(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILES_LIST:
      return {
        ...state,
        profilesList: action.data.profiles,
        metaData: action.data.metaData,
        lineExecutives: action.data.lineExecutives,
        categories: action.data.categories,
      };
    case SET_SAVE_PROFILE_MODAL_OPEN:
      return {
        ...state,
        saveProfileModal: action.data
      };
    default: {
      return state;
    }
  }
}