import { sinisterConstants } from '../constants/sinister_constants';

const initialState = {
  sinisters: [],
  meta: { nextPage: false }
};

export function sinister(state = initialState, action) {
  switch (action.type) {
    case sinisterConstants.LIST_SINISTERS_REQUEST:
      return {
        ...state,
        sinisters: action.data,
        meta: action.meta
      };
    default:
      return state;
  }
}
