import { Grid, withStyles } from '@material-ui/core';
import _find from 'lodash/find';
import _pick from 'lodash/pick';
import _pickBy from 'lodash/pickBy';
import { default as React, Fragment } from 'react';
import combineStyles from '../../../../helpers/combineStyles';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import CollapseWrapper from '../../../shared/CollapseWrapper';
import OutlinedSelectChipped from '../../../shared/Inputs/OutlinedSelectChipped';
import PickCustomDateModal from '../../../shared/Inputs/PickCustomDateModal';
import ListsStyle from '../../__styles__/Lists.style';
import { CUSTOM_DATE } from './constants';
import {
  dateOptions,
  originalDateOptions,
  processStatusOptions,
  processTypeOptions
} from './dropDownOptions';

class BulkProcess extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      processType: 'todas',
      status: 'todas',
      createdAt: 'todas',
      customDate: CUSTOM_DATE,
      openPickCustomDate: null
    };
  }

  _handleDateFilter = ({ target: { name, value } }) => {
    if (value === this.state.customDate) {
      this.setState({ openPickCustomDate: name });
    } else {
      this.setState(
        { [name]: value, customDate: CUSTOM_DATE },
        this._handleChangeFilter
      );
    }
  };

  _handleDateRangeFilter = ({ target: { name, value } }) => {
    const customDate = !_find(
      originalDateOptions,
      availableOption => availableOption.value === value
    )
      ? value
      : CUSTOM_DATE;
    this.setState(
      { [name]: value, openPickCustomDate: null, customDate },
      this._handleChangeFilter
    );
  };

  _handleClosePickCustomDate = () =>
    this.setState({ openPickCustomDate: null });

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    return _pick(_pickBy(this.state, filter => !!filter && filter != 'todas'), [
      'processType',
      'status',
      'createdAt'
    ]);
  };

  render() {
    const { classes } = this.props;

    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item sm={4} xs={12}>
                <OutlinedSelectChipped
                  label="Tipo Proceso"
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name="processType"
                  withChip={false}
                  options={processTypeOptions}
                  value={this.state.processType}
                  onChange={this._handleSelectChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <OutlinedSelectChipped
                  label="Estado"
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name="status"
                  withChip={false}
                  options={processStatusOptions}
                  value={this.state.status}
                  onChange={this._handleSelectChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <OutlinedSelectChipped
                  label={'Fecha'}
                  name={'createdAt'}
                  options={dateOptions(
                    this.state.customDate,
                    this.state.createdAt
                  )}
                  value={this.state.createdAt}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  inputLabel={'Creation Date'}
                  onChange={this._handleDateFilter}
                  withChip={false}
                />
              </Grid>
            </Grid>
            {!!this.state.openPickCustomDate && (
              <PickCustomDateModal
                name={this.state.openPickCustomDate}
                onClose={this._handleClosePickCustomDate}
                onSave={this._handleDateRangeFilter}
                value={this.state.createdAt}
              />
            )}
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(BulkProcess);
