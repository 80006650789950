import _compact from 'lodash/compact';

export const columns = [
  {
    title: '# Cliente',
    name: 'externalCode'
  },
  {
    title: '# Contrato',
    name: 'contracts'
  },
  {
    title: 'Nombre',
    name: 'name'
  },
  {
    title: 'Tipo de Identificación',
    name: 'identificationType'
  },
  {
    title: 'Identificación',
    name: 'identification'
  },
  {
    title: 'Dirección',
    name: 'address'
  },
  {
    title: 'Celular',
    name: 'mobile'
  },
  {
    title: 'Email',
    name: 'email'
  }
];

export const mapData = data =>
  data.map(datum => {
    let contracts = '';
    datum.contracts.some((c, idx) => {
      if (idx > 2) {
        contracts = contracts + '...';
        return true;
      }
      contracts = contracts + `${contracts && ', '}${c.id}`;
      return false;
    });
    return {
      ...datum,
      contracts: `${contracts || '--'}`,
      name: `${datum.name || '--'} ${datum.lastName ||
        ''} ${datum.secondLastName || ''}`,
      identificationType: datum.typeOfIdentification,
      identification: datum.identification,
      mobile: datum.mobile || '--',
      address: datum.address || '--',
      email: datum.email || '--'
    };
  });
