import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, withStyles } from '@material-ui/core';
import CustomDialog from '../../shared/Modals/CustomDialog';
import Toggle from '../../shared/Buttons/Toggle';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _get from 'lodash/get';
class BrandEditCreate extends Component {

  state = { serverErrors: null }

  _handleSave = async (params) => {
    const { isEditing } = this.props;
    let response = '';

    if (isEditing) {
      // * UPDATE
      response = await this.props.updateBrand(params);
    } else {
      // * CREATE
      response = await this.props.createBrand(params);
    }

    if (response.success) {
      this.props.reFetch();
      this._handleClose();
      return true;
    } else {
      this.setState({ serverErrors: response.errors });
      return false;
    }
  };

  _handleClose = () => {
    this.props.toggleModal(false, null, null);
    this.setState({ serverErrors: null });
  };

  componentWillUnmount() {
    this.setState({ serverErrors: null })
  }

  validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required('Nombre es requerido'),
    description: Yup.string().nullable().required('Descripción es requerida'),
    active: Yup.bool().required('Estado es requerido')
  });

  render() {
    const { isEditing } = this.props;
    const { name, description, active = true, id } = this.props.brand || {};    

    return (

      <Formik
        initialValues={{
          name: name || '',
          description: description || '',
          active: active,
          id: id
        }}
        validationSchema={this.validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, actions) => {
          const reset = await this._handleSave(values);

          if (reset) actions.resetForm();
        }}
        onReset={(values, actions) => {
          this._handleClose();
          actions.resetForm();
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue
        }) => (
          <CustomDialog
            open={this.props.open}
            // loading={loading}
            onClose={handleReset}
            onConfirm={handleSubmit}
            title={isEditing ? `Editar Marca` : 'Nueva Marca'}
            buttonText={isEditing ? `GUARDAR` : 'CREAR'}
            disableBackdropClick={true}
            headerSecondary={
              <Toggle
                name={'active'}
                value={values.active}
                onChange={(e) => {
                  setFieldValue('active', e);
                }}
              />
            }
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label="Nombre Marca"
                  required
                  name="name"
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.name}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  multiline
                  error={
                    touched.name &&
                    (!!errors.name || _get(this.state.serverErrors, 'name[0]') &&
                      !!this.state.serverErrors['name'][0])
                  }
                  helperText={
                    touched.name &&
                    (errors.name || _get(this.state.serverErrors, 'name[0]') &&
                      this.state.serverErrors['name'][0])
                  }
                />
                <p style={{ color: 'grey', fontSize: '.7em', margin: 0, padding: 0 }}>
                  {`${values.name.length} / 100`}
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descripción"
                  name="description"
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.description}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  multiline
                  error={
                    touched.description &&
                    (!!errors.description || _get(this.state.serverErrors, 'description[0]') &&
                      !!this.state.serverErrors['description'][0])
                  }
                  helperText={
                    touched.description &&
                    (errors.description || _get(this.state.serverErrors, 'description[0]') &&
                      this.state.serverErrors['description'][0])
                  }
                />
                <p style={{ color: 'grey', fontSize: '.7em', margin: 0, padding: 0 }}>
                  {`${values.description.length} / 100`}
                </p>
              </Grid>
            </Grid>
          </CustomDialog>
        )}
      />
    );
  }
}

const styles = (/* theme */) => ({});

BrandEditCreate.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(BrandEditCreate);
