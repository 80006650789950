const styles = theme => ({
  smallButton: {
    borderRadius: '2px',
    height: 40
  },
  button: {
    borderRadius: '2px',
    height: 40,
    marginRight: theme.spacing.unit * 2,
    '&:last-child': {
      marginRight: 0
    },
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  },
  buttonLabel: {
    fontFamily: theme.typography.font.main,
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    lineHeight: '16px'
  },
  buttonLabelSecondaryAction: {
    fontFamily: theme.typography.font.main,
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    lineHeight: '20px'
  },
  outlinedButton: {
    borderRadius: '2px',
    marginBottom: theme.spacing.unit * 4,
    width: '100%',
    height: 40
  },
  linkButton: {
    border: 0,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    textDecoration: 'underline',
    padding: 0,
    outline: 'none'
  },
  textButton: {
    height: 36,
    border: 0,
    padding: '0 20px',
    backgroundColor: 'transparent',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    lineHeight: '16px',
    fontFamily: 'Roboto',
    outline: 'none',
    cursor: 'pointer',
    textTransform: 'uppercase'
  },
  buttonDisabled: {
    color: 'rgba(12,31,44,0.38) !important'
  },
  deleteTextButton: {
    color: '#F22D12',
    '&:hover': {
      backgroundColor: 'rgba(233, 46, 21, 0.06)'
    }
  }
});

export default styles;
