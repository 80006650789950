import { salesConstants } from '../constants/sales_constants';
import { SALE_TOGGLE_ACTION } from '../constants/action_constants';
import { newAlert, serverStatusError } from './alert_actions';
import { addComment } from './comment_actions';
import { handleRequestError } from './session_actions';
import { sendRequest, completeRequest } from './request_state';
import RequestService from '../services/request_service';
const requestService = new RequestService();
const requestServiceNode = new RequestService('node');
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import saleFiltersSanitizer from '../helpers/sanitizers/saleFilters';
import { objectToString } from '../helpers/mappings/map';
import MIMETYPES from '../constants/mimetypes';
import AxiosRequest from '../services/axios_request';
const axiosRequest = new AxiosRequest();

//* PURE ACTION FUNCTIONS (This ones call the reducer)
function success(items, pagina) {
  return {
    type: salesConstants.LIST_SALES_SUCCESS,
    data: items,
    meta: pagina
  };
}

function success_sales_detail(data) {
  return { type: salesConstants.DETAIL_SALES_SUCCESS, data };
}

function setModal(action, open) {
  return {
    type: SALE_TOGGLE_ACTION,
    action: action,
    open: open
  };
}

//* VIEW ACTION FUNCTIONS

// * INDEX *
export const fetchSales = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/sales?${stringifyQuery(params)}`);
  try {
    const payload = await requestServiceNode.get(
      'sales',
      saleFiltersSanitizer(params)
    );
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest());
  }
};

// * SHOW *
export const salesDetail = id => async dispatch => {
  dispatch(sendRequest());
  try {
    const payload = await requestServiceNode.get(`sales/${id}`);
    dispatch(success_sales_detail(payload.data.attributes));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest());
  }
};

// * TOGGLE ACTIONS *
export function toggleSaleAction(action, open) {
  return dispatch => {
    dispatch(setModal(action, open));
  };
}
// * PRODUCT DELIVERY *
export const productDelivery = (params = {}) => async dispatch => {
  dispatch(sendRequest('Product-Delivery'));
  try {
    const payload = await requestServiceNode.post(
      `sales/delivery`,
      params,
      {},
      true
    );
    dispatch(newAlert('success', 'success', payload.data.message));
    dispatch(toggleSaleAction('Product-Delivery', false));
    dispatch(salesDetail(params.id));
  } catch (errors) {
    let errorMessage = '';
    errors && errors.json().then(e => {
      errorMessage = e.errors.b2b || objectToString(e.errors);
      handleRequestError(
        {
          beforeHandle: () => dispatch(serverStatusError(errors)),
          handleRequest: () => {
            e.errors &&
              dispatch(newAlert('error', 'ERROR:', errorMessage));
          },
          response: e
        }
      )(dispatch);
    });
  } finally {
    dispatch(completeRequest('Product-Delivery'));
  }
};

export const registerSiniter = (params = {}) => async dispatch => {
  dispatch(sendRequest('Register-Sinister'));
  try {
    const payload = await requestServiceNode.post(
      `sinisters/register`,
      params,
      {},
      true
    );

    dispatch(newAlert('success', 'success', payload.data.message));
    dispatch(toggleSaleAction('Register-Sinister', false));
    dispatch(salesDetail(params.saleId));
  } catch (errors) {
    errors && errors.json().then(e => {
      handleRequestError(
        {
          beforeHandle: () => dispatch(serverStatusError(errors)),
          handleRequest: () => {
            e.errors &&
              dispatch(newAlert('error', 'ERROR:', objectToString(e.errors)));
          },
          response: e
        }
      )(dispatch);
    });
  } finally {
    dispatch(completeRequest('Register-Sinister'));
  }
};

// * RETURN SALE *
export const returnSale = (body = {}) => async dispatch => {
  let errorMessage = '';
  try {
    dispatch(sendRequest('Return-Sale'));
    const payload = await requestServiceNode.post(
      `sales/${body.sale.id}/return-articles`,
      body.sale
    );
    dispatch(newAlert('success', 'success', payload.data.message));
    dispatch(toggleSaleAction('Return-Sale', false));
    dispatch(salesDetail(body.sale.id));
  } catch (errors) {
    const error = await errors.json();
    errorMessage = Object.values(error.errors)[0];
    handleRequestError({
      beforeHandle: () => dispatch(serverStatusError(errors)),
      handleRequest: () => {
        error.errors &&
          dispatch(newAlert('error', 'ERROR:', errorMessage.split(':')[0]));
      },
      response: error
    })(dispatch);
  } finally {
    dispatch(completeRequest('Return-Sale'));
  }
  return errorMessage;
};

// * CANCEL SALE *
export const cancelSale = (body = {}, partially) => async dispatch => {
  try {
    dispatch(sendRequest('Cancel-Sale'));
    const payload = await requestService.delete(
      `sales/${body.sale.id}/${partially ? 'revoke_articles' : 'revoke'}`,
      body,
      {},
      true
    );
    dispatch(newAlert('success', 'success', payload.status));
    dispatch(toggleSaleAction('Cancel-Sale', false));
    dispatch(salesDetail(body.sale.id));
  } catch (errors) {
    errors && errors.json().then(e => {
      handleRequestError(
        {
          beforeHandle: () => dispatch(serverStatusError(errors)),
          handleRequest: () => {
            e.errors &&
              dispatch(newAlert('error', 'ERROR:', objectToString(e.errors)));
          },
          response: e
        }
      )(dispatch);
    });
  } finally {
    dispatch(completeRequest('Cancel-Sale'));
  }
};

export const approveSale = (id, comment) => async dispatch => {
  dispatch(sendRequest('Approve-Sale'));
  try {
    await requestServiceNode.put(
      `approve-sale/${id}/approve`,
      {
        comment
      },
      {},
      true
    );
    dispatch(toggleSaleAction('Approve-Sale', false));
    dispatch(newAlert('success', 's', 'Venta está en proceso de aprobación'));
    dispatch(salesDetail(id));
  } catch (errors) {
    if (errors) {
      const e = await errors.json();
      handleRequestError(
        {
          beforeHandle: () => dispatch(serverStatusError(errors)),
          handleRequest: () => {
            if (e.errors && e.errors.base) {
              dispatch(newAlert('error', 'ERROR:', e.errors.base));
            } else {
              dispatch(
                newAlert(
                  'error',
                  'ERROR:',
                  'Error procesando su solicitud, por favor comuníquese con su administrador.'
                )
              );
            }
          },
          response: e
        }
      )(dispatch);
    }
  } finally {
    dispatch(completeRequest('Approve-Sale'));
  }
};

// * ADD COMMENT TO SALE *
export const addSaleComment = (body = {}) => async dispatch => {
  try {
    dispatch(sendRequest('Add-Comment'));
    const payload = await requestService.post(
      `sales/${body.sale_id}/comments`,
      body,
      {},
      true
    );
    dispatch(addComment(body.comment));
    dispatch(newAlert('success', 'success', payload.status));
    dispatch(toggleSaleAction('Add-Comment', false));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    errors &&
      errors.json().then(e => {
        e.errors &&
          dispatch(newAlert('error', 'ERROR:', objectToString(e.errors)));
      });
  } finally {
    dispatch(completeRequest('Add-Comment'));
  }
};

// * EXPORT SALE LIST TO XLSX*
export const exportToXlsx = (params = {}) => async dispatch => {
  dispatch(sendRequest('exportToXlsx'));
  try {
    const payload = await requestServiceNode.get(`sales/exportSales`, saleFiltersSanitizer(params));
    const file = new Blob([new Uint8Array(payload.data).buffer], { type: MIMETYPES['xlsx'] });
    const fileUrl = URL.createObjectURL(file);
    window.location.href = fileUrl;
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('exportToXlsx'));
  }
};
