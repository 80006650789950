import React from 'react';
import CustomDialog from '../../shared/Modals/CustomDialog';
import WarningIcon from '@material-ui/icons/WarningTwoTone';

const getText = (data, currentUser) => {
  if (data.action === 'logout') {
    return 'Se ha actualizado el sistema. Esta sesión caducará inmediatamente.';
  } else if (data.action === 'http_logout') {
    return data.body.message;
  } else if (data.action === 'site_on_maintenance') {
    return 'El sitio entrará en mantenimiento. Por favor, presione el botón de continuar e ingrese más tarde.';
  } else if (data.action === 'site_out_maintenance') {
    return 'El sitio ya fue restablecido. Por favor, presione el botón de continuar.';
  } else if (validateRoleUpdate(data, currentUser)) {
    return 'Se han actualizado sus permisos. Por favor, presione el botón de continuar para aplicar los cambios.';
  } else {
    return 'Se ha actualizado el sistema. Por favor, presionar el botón de continuar para aplicar los cambios.';
  }
};

const validateRoleUpdate = (data, currentUser) => {
  const currentUserRoles = !!currentUser
    ? currentUser.roles.map(role => role.id)
    : [];
  const role = parseInt(data.role);
  if (data.action === 'permission_update' && currentUserRoles.includes(role))
    return true;
  else return false;
};

const getLogoutActions = () => {
  return ['http_logout', 'logout', 'site_out_maintenance'];
};

const getMaintenanceActions = () => {
  return ['site_on_maintenance'];
};

const getUpdateActions = () => {
  return ['update'];
};

const isUpdateAction = action => {
  return getUpdateActions().includes(action);
};

const isLogoutAction = action => {
  return getLogoutActions().includes(action);
};

const isMaintenanceAction = action => {
  return getMaintenanceActions().includes(action);
};

export default function deployModal(isOpen, data, currentUser, logout, close, siteState) {
  if (isLogoutAction(data.action)) {
    sessionStorage.clear();
  }

  if (
    validateRoleUpdate(data, currentUser) ||
    isLogoutAction(data.action) ||
    isMaintenanceAction(data.action) ||
    isUpdateAction(data.action)
  ) {
    return (
      <CustomDialog
        open={isOpen}
        disableBackdropClick={true}
        onConfirm={() => {
          close();
          if (isLogoutAction(data.action)) {
            logout();
          } else if (isMaintenanceAction(data.action)) {
            siteState(false);
          } else {
            window.location.reload();
          }
        }}
        onClose={() => {
          close();
          if (isLogoutAction(data.action)) {
            logout();
          } else if (isMaintenanceAction(data.action)) {
            siteState(false);
          } else {
            window.location.reload();
          }
        }}
        title={`Mensaje del Sistema`}
        buttonText={`CONTINUAR`}
        allowTitle={false}
      >
        <React.Fragment>
          <WarningIcon
            color={'secondary'}
            style={{
              fontSize: 100,
              align: 'center',
              width: '100%'
            }}
          />
          <p>{getText(data, currentUser)}</p>
        </React.Fragment>
      </CustomDialog>
    );
  }
}
