import _pickBy from 'lodash/pickBy';

const pointUserAssociationFilters = filters => {
  const { Identificación, pointOfSale, asesor, status, date_range, page, per_page } = filters;
  const range = date_range ? date_range.split(' - ') : [];

  const sanitizedFilters = {
    created_at_gteq: range[0] || date_range,
    created_at_lteq: range[1] || date_range,
    user_full_name_cont: asesor,
    point_of_sale_name_eq: pointOfSale,
    user_identification_cont: Identificación,
    page: page,
    per_page
  };

  if (status === 'registered') {
    sanitizedFilters.status_eq = 0;
  }
  if (status === 'approved') {
    sanitizedFilters.status_eq = 1;
  }
  if (status === 'revoked') {
    sanitizedFilters.status_eq = 2;
  }

  return _pickBy(sanitizedFilters, filter => !!filter || filter == 0);
};

export default pointUserAssociationFilters;
