import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import { Formik, Field } from 'formik';

import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import SpacingStyles from '../../../../styles/helpers/spacing';

import TextFieldInput from '../../../shared/Inputs/TextField';
import CustomDialog from '../../../shared/Modals/CustomDialog';
import GridItem from '../../../shared/GridItem';

import validationSchema from './FormValidations';

const styles = theme => ({
  title: {
    fontWeight: 'bold !important'
  },
  fieldsMarginBottom: {
    marginBottom: `${theme.spacing.unit * 2.5}px !important`
  },
  toggleSwitchLeftLabel: {
    marginRight: `${theme.spacing.unit * 1.5}px !important`
  },
  toggleSwitchLabel: {
    color: `${theme.typography.color.second} !important`
  },
  titleContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  }
});

export class FormModal extends Component {
  static propTypes = {
    secureCreditInstalmentsPercentage: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
  };

  componentDidMount() {
    if (this.props.item) {
      this.setState({
        categoryMaxItems: this.props.item.categoryMaxItems,
        maxItems: this.props.item.maxItems
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.formik.setErrors({});

    if (this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  handleDelete = id => {
    this.props.onDelete({ id }, { page: 1, perPage: 10 }).then(() => this.props.onClose());
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  modalProps = (handleSubmit, values) => {
    if (!values.isNew && this.props.canDeleteSecureCreditInstalmentsPercentages) {
      return {
        onConfirm: () => handleSubmit(),
        title: 'Editar Porcentaje de Fianza',
        buttonText: 'Guardar',
        buttonSecondaryText: true
          ? 'Eliminar'
          : null,
        onConfirmSecondary: () => {
          this.handleDelete(values.id);
        }
      };
    }
    return {
      title: 'Crear Porcentaje de Fianza',
      buttonText: 'Guardar',
      onConfirm: () => handleSubmit()
    };
  };

  render() {
    const {
      classes,
      isSubmitting,
      secureCreditInstalmentsPercentage = {}
    } = this.props;

    return (
      <Formik
        ref={ref => (this.formik = ref)}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}
        initialValues={{
          ...secureCreditInstalmentsPercentage
        }}
        onSubmit={this.props.onSave}
        onReset={(_, actions) => {
          actions.resetForm();
          this.props.onClose();
        }}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleReset,
          dirty
        }) => (
          <Fragment>
            <CustomDialog
              open={true}
              loading={isSubmitting}
              disabled={!dirty || isSubmitting || Object.keys(errors).length > 0}
              disableBackdropClick={false}
              headerSecondary
              onClose={handleReset}
              {...this.modalProps(handleSubmit, values)}
              confirmDialog={true}
              titleConfirm="Mensaje Importante"
              textConfirm="Está a punto de eliminar este porcentaje para fianza"
            >
              <Grid container spacing={16}>
                <GridItem xs={12} sm={12}>
                  <Field
                    name="minNumOfInstalments"
                    onChange={handleChange}
                    render={({ field, form }) => {
                      return (
                        <TextFieldInput
                          value={values.minNumOfInstalments}
                          label={'Cuotas mínimas'}
                          fullWidth
                          field={field}
                          form={form}
                          classes={{
                            root: classes.noMarginBottom
                          }}
                        />
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <Field
                    name="maxNumOfInstalments"
                    onChange={handleChange}
                    render={({ field, form }) => {
                      return (
                        <TextFieldInput
                          value={values.maxNumOfInstalments}
                          label={'Cuotas máximas'}
                          fullWidth
                          field={field}
                          form={form}
                          classes={{
                            root: classes.noMarginBottom
                          }}
                        />
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <Field
                    name="percentage"
                    onChange={handleChange}
                    render={({ field, form }) => {
                      return (
                        <TextFieldInput
                          value={values.percentage}
                          label={'Porcentaje'}
                          fullWidth
                          field={field}
                          form={form}
                          type="number"
                        />
                      );
                    }}
                  />
                </GridItem>
              </Grid>
            </CustomDialog>
          </Fragment>
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(FormModal);
