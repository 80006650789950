import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StepTitle from './StepTitle';
import PercentageList from '../../shared/Lists/PercentageList';

const CategoriesAndLocationsStep = props => {
  const { classes, values, setFieldValue, proposalWasSent } = props;
  const sortedCategories = [...(values.categories || [])].sort(
    (a, b) => {
      // Ordena las categorias alfabeticamente en base al campo nameInSupplierProposalModule
      // Es necesario ya que si no al momento de reconstruir el objeto categories el orden de la lista va a cambiar, porque el orden en el que se itera en el metodo oChange no es el mismo orden en el que llegan las categorias del back, entonces mejor se ordenan y que siempre se muestren en el mismo orden
      return a.nameInSupplierProposalModule.localeCompare(
        b.nameInSupplierProposalModule
      );
    }
  );
  return (
    <Grid container item className={classes.categoriesAndLocationsStepContainer}>
      <StepTitle />
      <Grid item xs={12} className={classes.categoriesAndLocationsStepSubtitle}>
        <Typography variant='body1'>
          Completa los siguientes datos de la empresa para continuar con el proceso
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1'>
          Selecciona las zonas geográficas de cobertura y sus porcentajes de ventas a personas naturales
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <b>Te recordamos que Gases del Caribe solo tiene cobertura en estos 3 Departamentos</b>
      </Grid>
      <Grid item xs={12}>
        <PercentageList
          disabled={proposalWasSent}
          list={
            [
              {
                id: 1, label: 'ATLÁNTICO', initialValues: {
                  percentage: values.atlanticoSalePercentage
                }
              },
              {
                id: 2, label: 'MAGDALENA', initialValues: {
                  percentage: values.magdalenaSalePercentage
                }
              },
              {
                id: 3, label: 'CESAR', initialValues: {
                  percentage: values.cesarSalePercentage
                }
              }
            ]
          }
          onChange={
            data => {
              setFieldValue('coveragePercentageTotal', data.total);
              Object.keys(data.values).forEach(
                key => {
                  const value = data.values[key];
                  const label = value.item.label;
                  const fieldName = ({
                    'ATLÁNTICO': 'atlanticoSalePercentage',
                    'MAGDALENA': 'magdalenaSalePercentage',
                    'CESAR': 'cesarSalePercentage',
                  })[label];
                  const percentage = value.percentage === ''
                    ? null
                    : value.percentage;
                  setFieldValue(fieldName, percentage);
                }
              );
            }
          }
        />
      </Grid>
      <Grid item xs={12} className={classes.categoriesListTitle}>
        <Typography variant='body1'>
          Selecciona las categorías de productos y/o servicios que ofrece la empresa y sus porcentajes de venta a personas naturales
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PercentageList
          disabled={proposalWasSent}
          list={
            [
              ...sortedCategories.map(
                category => {
                  return {
                    id: category.categoryId,
                    label: category.nameInSupplierProposalModule,
                    initialValues: {
                      percentage: category.percentage
                    }
                  };
                }
              ),
            ]
          }
          onChange={
            data => {
              setFieldValue('categoriesPercentageTotal', data.total);
              const categories = [];
              Object.keys(data.values).forEach(
                key => {
                  const value = data.values[key];
                  const percentage = value.percentage === ''
                    ? null
                    : value.percentage;
                  const proposalCategory = {
                    categoryId: Number(key),
                    percentage,
                    nameInSupplierProposalModule: value.item.label
                  };
                  categories.push(proposalCategory);
                }
              );
              setFieldValue('categories', categories);
            }
          }
        />
      </Grid>
    </Grid>
  );
}
export default CategoriesAndLocationsStep;