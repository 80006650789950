import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../shared/Modals/Modal';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';
import ButtonStyles from '../../../styles/button';

const styles = theme => ({
  formTitle: {
    fontWeight: 'bold !important',
    marginBottom: `${theme.spacing.unit * 2}px !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 4}px !important`
  }
});

export class NotificationModal extends Component {
  render() {
    const { classes, open, onClose, notification} = this.props;
    return (
      <Modal
        open={open}
        onClose={onClose}
        classes={{
          footer: classes.modalFooter
        }}
        body={
          <Fragment>
            <p className={classnames(classes.formTitle, classes.headline6)}>{`${
              notification.title
            } ${notification.headerMessage}`}</p>
            <p className={classes.body1Light}>{`"${
              notification.bodyMessage
            }"`}</p>
          </Fragment>
        }
        footer={
          <Fragment>
            <Button
              color={'primary'}
              className={classes.textButton}
              onClick={onClose}
            >
              {'OK'}
            </Button>
          </Fragment>
        }
      />
    );
  }
}

export default withStyles(
  combineStyles(styles, ButtonStyles, TypographyStyles)
)(NotificationModal);
