export const columns = [
    {
      title: 'Zona geográfica',
      name: 'geographicLocationName'
    },
    {
      title: 'Aliados',
      name: 'supplierName'
    }
  ];

  export const mapData = data => (
    data.map(datum => {  
      return {
        id: datum.id,
        supplierName: datum.suppliers && datum.suppliers.length > 0 ? datum.suppliers.map( s=> s.name ).join(', ') : '--',
        geographicLocationName: datum.geographicLocation ? `${datum.geographicLocation.id} - ${datum.geographicLocation.description}` : '--',
        suppliers: datum.suppliers ? datum.suppliers : [],
        geographicLocationId: datum.geographicLocation ? datum.geographicLocation.id : ''
      };
    })
  );