import { salesRevokeConstants } from '../constants/sales_revoke_constants';

const initialState = {
  salesRevoke: [],
  meta: null
};

export function salesRevoke(state = initialState, action) {
  switch (action.type) {
    case salesRevokeConstants.LIST_SALES_REVOKE_REQUEST:
      return {
        ...state,
        salesRevoke: action.data,
        meta: action.meta
      };
    default:
      return state;
  }
}
