import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';

import SummaryCard from '../../shared/Cards/SummaryCard';

const styles = theme => ({
  rowCardWrapper: {
    width: 'calc(50% - 12px)'
  }
});

export const Header = ({ classes, theme, isLoading, data }) => (
  <div className={classnames(classes.row)}>
    <div className={classnames(classes.rowCardWrapper, classes.row)}>
      <div className={classes.rowCardWrapper}>
        <SummaryCard
          totalCount={data.registered}
          message={'Por Revisar'}
          ProgressBarProps={{
            color: '#FEB00B',
            totalValue: data.all,
            value: data.registered,
            resourceType: 'Ventas'
          }}
          isLoading={isLoading}
        />
      </div>
      <div className={classes.rowCardWrapper}>
        <SummaryCard
          totalCount={data.revoked}
          message={'Rechazadas'}
          ProgressBarProps={{
            color: theme.palette.primary.main,
            totalValue: data.all,
            value: data.revoked,
            resourceType: 'Ventas'
          }}
          isLoading={isLoading}
        />
      </div>
    </div>
    <div className={classnames(classes.rowCardWrapper, classes.row)}>
      <div className={classes.rowCardWrapper}>
        <SummaryCard
          totalCount={data.approved}
          message={'Aprobadas'}
          ProgressBarProps={{
            color: '#0071D9',
            totalValue: data.all,
            value: data.approved,
            resourceType: 'Ventas'
          }}
          isLoading={isLoading}
        />
      </div>
      <div className={classes.rowCardWrapper}>
        <SummaryCard
          totalCount={data.delivered}
          message={'Entregadas'}
          ProgressBarProps={{
            color: '#00C396',
            totalValue: data.all,
            value: data.delivered,
            resourceType: 'Ventas'
          }}
          isLoading={isLoading}
        />
      </div>
    </div>
  </div>
);

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles),
  {
    withTheme: true
  }
)(Header);
