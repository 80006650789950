import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import { Formik } from 'formik';
import CustomDialog from '../../shared/Modals/CustomDialog';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';

import { Stores, samirWarehouses } from './QueryInventoryStores';

class QueryInventory extends PureComponent {
  componentDidMount() {
    //this.props.queryInventory({ store: 'electroao' });
  }

  _handleOnClose = () => {
    this.props.closeModal();
    this.props.resetQueryData();
  };

  filterByName = (item, name) => {
    if (item[Stores[this.props.store].name]) {
      return item[Stores[this.props.store].name]
        .toUpperCase()
        .includes(name.toUpperCase());
    } else {
      return item[Stores[this.props.store].nameAlt]
        .toUpperCase()
        .includes(name.toUpperCase());
    }
  };

  filterByCategory = (item, category) => {
    return item[Stores[this.props.store].category]
      .toUpperCase()
      .includes(category.toUpperCase());
  };

  filterInventory = (productName, category) => {
    this.props.setSelectedProduct({});

    if (productName && category !== 'todas') {
      this.props.setFilteredInventory(
        this.props.inventory[this.props.store]
          .filter(
            item =>
              this.filterByName(item, productName) &&
              this.filterByCategory(item, category)
          )
          .slice(0, 5)
      );
    } else if (!productName && category !== 'todas') {
      this.props.setFilteredInventory(
        this.props.inventory[this.props.store].filter(item =>
          this.filterByCategory(item, category)
        )
      );
    } else if (productName.length > 2 && category === 'todas') {
      this.props.setFilteredInventory(
        this.props.inventory[this.props.store]
          .filter(item => this.filterByName(item, productName))
          .slice(0, 5)
      );
    } else {
      this.props.setFilteredInventory([]);
    }
  };

  selectedProduct = product => {
    const total = product[Stores[this.props.store].totalValue];
    const base = product[Stores[this.props.store].baseValue];
    const ivaValue =
      this.props.store === 'samir'
        ? total - base
        : product[Stores[this.props.store].ivaValue];

    return [
      { label: 'Precio base', price: base },
      { label: 'Valor de IVA', price: ivaValue },
      { label: 'Descuento', price: product[Stores[this.props.store].discount] },
      { label: 'Total', price: total }
    ];
  };

  render() {
    const { classes, loading, isOpen, selectedProduct } = this.props;

    return (
      isOpen && (
        <Formik
          initialValues={{
            queryType: 'electroao',
            productName: '',
            category: 'todas'
          }}
          onReset={(values, actions) => {
            actions.resetForm();
            this._handleOnClose();
          }}
          render={({ values, handleChange, handleReset, setFieldValue }) => (
            <CustomDialog
              onClose={handleReset}
              onConfirm={() => {
                this.props.resetQueryData();
                setFieldValue('category', 'todas');
                setFieldValue('productName', '');
              }}
              onConfirmSecond={() => this.props.setSelectedProduct({})}
              loading={loading}
              open={isOpen}
              title={'Consulta de Inventario'}
              buttonText={'Limpiar'}
              secondButtonText={
                Object.keys(selectedProduct).length ? 'Regresar' : ''
              }
              classes={{
                root: classes.queryQuotaDialogRoot,
                content: classes.queryQuotaDialogContent
              }}
            >
              <form
                autoComplete="off"
                className={classes.form}
                onSubmit={e => {
                  e.preventDefault();
                }}
              >
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item sm={7} xs={12} className={classes.inputGrid}>
                    <OutlinedTextField
                      label="Nombre del producto:"
                      name="productName"
                      type="text"
                      value={values.productName}
                      onChange={e => {
                        handleChange(e);
                        this.filterInventory(e.target.value, values.category);
                      }}
                    />
                  </Grid>
                  <Grid item sm={3} xs={6} className={classes.inputGrid}>
                    <SimpleSelect
                      value={values.category}
                      onChange={e => {
                        handleChange(e);
                        this.filterInventory(
                          values.productName,
                          e.target.value
                        );
                      }}
                      name="category"
                      label="Categorías:"
                      options={this.props.categories.map(category => {
                        return {
                          key: category.toLowerCase(),
                          value: category.toLowerCase(),
                          label: category
                        };
                      })}
                    />
                  </Grid>
                  <Grid item sm={2} xs={6} className={classes.inputGrid}>
                    <SimpleSelect
                      value={values.queryType}
                      onChange={e => {
                        handleChange(e);

                        const store = e.target.value;

                        this.props.setStore(store);
                        this.props.resetQueryData();

                        if (
                          !this.props.inventory[store] ||
                          (!this.props.inventory[store] &&
                            !this.props.inventory[store].length)
                        ) {
                          this.props.setCategoriesD([]);
                          this.props.queryInventory({ store });
                        } else {
                          const categories = ['Todas'];

                          this.props.inventory[store].map(item => {
                            if (
                              !categories.includes(item[Stores[store].category])
                            ) {
                              categories.push(item[Stores[store].category]);
                            }
                          });

                          this.props.setCategoriesD(categories);
                        }

                        setFieldValue('category', 'todas');
                        setFieldValue('productName', '');
                      }}
                      name="queryType"
                      label="Consulta en:"
                      options={[
                        {
                          key: 'electroao',
                          value: 'electroao',
                          label: 'Electro AO'
                        },
                        {
                          key: 'samir',
                          value: 'samir',
                          label: 'SAMIR'
                        }
                      ]}
                    />
                  </Grid>
                </Grid>

                {Object.keys(selectedProduct).length ? (
                  <div className={classes.filteredContainer}>
                    <div>
                      <img
                        src={selectedProduct[Stores[this.props.store].image]}
                        alt=""
                        className={classes.filteredSelectedImage}
                      />
                    </div>
                    <div className={classes.filteredContent}>
                      <div className={classes.prices}>
                        <p className={classes.textBold}>
                          {selectedProduct[Stores[this.props.store].name]
                            ? selectedProduct[Stores[this.props.store].name]
                            : selectedProduct[Stores[this.props.store].nameAlt]}
                          <span className={classes.filteredCode}>
                            {selectedProduct[Stores[this.props.store].code]}
                          </span>
                        </p>
                        {typeof selectedProduct[
                          Stores[this.props.store].stock
                        ] !== 'undefined' && (
                          <p className={classes.textBold}>
                            Disponibles:{' '}
                            <span
                              style={{
                                color: Number(
                                  selectedProduct[
                                    Stores[this.props.store].stock
                                  ]
                                )
                                  ? 'black'
                                  : 'red'
                              }}
                            >
                              {selectedProduct[Stores[this.props.store].stock]}
                            </span>
                          </p>
                        )}
                      </div>
                      <p>
                        {selectedProduct[Stores[this.props.store].description]}
                      </p>
                      <p>
                        {
                          selectedProduct[
                            Stores[this.props.store].shortDescription
                          ]
                        }
                      </p>
                      {Boolean(
                        Number(selectedProduct[Stores[this.props.store].weight])
                      ) && (
                        <p>
                          <span className={classes.textBold}>Peso: </span>
                          {`${Number(
                            selectedProduct[Stores[this.props.store].weight]
                          ).toFixed(3)}
                          ${
                            selectedProduct[Stores[this.props.store].weightUnit]
                          }`}
                        </p>
                      )}
                      {!selectedProduct[Stores[this.props.store].stock] && (
                        <>
                          <p className={classes.textBold}>Disponibilidad: </p>
                          <div className={classes.stockContainer}>
                            {Object.entries(samirWarehouses).map(entry => (
                              <p className={classes.stockText}>
                                {entry[1]}:&nbsp;
                                <span
                                  style={{
                                    color: Number(selectedProduct[entry[0]])
                                      ? 'black'
                                      : 'red'
                                  }}
                                >
                                  {selectedProduct[entry[0]]}
                                </span>
                              </p>
                            ))}
                          </div>
                        </>
                      )}
                      <div className={classes.prices}>
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            {this.selectedProduct(selectedProduct).map(
                              item =>
                                Boolean(item.price) && (
                                  <TableRow key={item.label}>
                                    <TableCell component="th" scope="row">
                                      <p
                                        className={
                                          item.label === 'Total'
                                            ? classes.textBold
                                            : undefined
                                        }
                                      >
                                        {item.label}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p
                                        className={
                                          item.label === 'Total'
                                            ? classes.textBold
                                            : undefined
                                        }
                                      >
                                        {item.price}
                                      </p>
                                    </TableCell>
                                  </TableRow>
                                )
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                ) : this.props.filteredInventory.length &&
                  !Object.keys(selectedProduct).length ? (
                  this.props.filteredInventory.map(item => (
                    <div
                      key={item[Stores[this.props.store].code]}
                      className={`${classes.filteredContainer} ${
                        classes.pointer
                      }`}
                      onClick={() => {
                        this.props.setSelectedProduct(item);
                      }}
                    >
                      <div>
                        <img
                          src={item[Stores[this.props.store].image]}
                          alt=""
                          className={classes.filteredImage}
                        />
                      </div>
                      <div className={classes.filteredContent}>
                        <p className={classes.textBold}>
                          {item[Stores[this.props.store].name]
                            ? item[Stores[this.props.store].name]
                            : item[Stores[this.props.store].nameAlt]}
                        </p>
                        {typeof item[Stores[this.props.store].stock] !==
                          'undefined' && (
                          <p className={classes.textBold}>
                            Disponibles:{' '}
                            <span
                              style={{
                                color: item[Stores[this.props.store].stock]
                                  ? 'black'
                                  : 'red'
                              }}
                            >
                              {item[Stores[this.props.store].stock]}
                            </span>
                          </p>
                        )}
                        <p>{item[Stores[this.props.store].description]}</p>
                        <p>{item[Stores[this.props.store].shortDescription]}</p>
                        {Boolean(
                          Number(item[Stores[this.props.store].weight])
                        ) && (
                          <p>
                            <span className={classes.textBold}>Peso: </span>
                            {`${Number(
                              item[Stores[this.props.store].weight]
                            ).toFixed(3)}
                            ${item[Stores[this.props.store].weightUnit]}`}
                          </p>
                        )}
                        {!item[Stores[this.props.store].stock] && (
                          <>
                            <p className={classes.textBold}>Disponibilidad: </p>
                            <div className={classes.stockContainer}>
                              {Object.entries(samirWarehouses).map(entry => (
                                <p className={classes.stockText}>
                                  {entry[1]}:&nbsp;
                                  <span
                                    style={{
                                      color: Number(item[entry[0]])
                                        ? 'black'
                                        : 'red'
                                    }}
                                  >
                                    {item[entry[0]]}
                                  </span>
                                </p>
                              ))}
                            </div>
                          </>
                        )}
                        <p className={classes.textBold}>
                          Valor total: $
                          {item[Stores[this.props.store].totalValue]}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  this.props.hasSearched && <p>No hay resultados.</p>
                )}
              </form>
            </CustomDialog>
          )}
        />
      )
    );
  }
}

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 0
  },
  inputGrid: {
    padding: '6px 12px 6px 12px !important',
    ['@media (max-width:600px)']: {
      padding: '0 12px !important'
    }
  },
  pointer: {
    cursor: 'pointer'
  },
  filteredContainer: {
    padding: '10px',
    backgroundColor: 'whitesmoke',
    width: '100%',
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center'
  },
  filteredContent: {
    flex: 1
  },
  filteredImage: {
    height: '150px',
    width: '150px',
    marginRight: '15px'
  },
  filteredSelectedImage: {
    height: '205px',
    width: '190px',
    marginRight: '15px'
  },
  textBold: {
    fontWeight: 'bold'
  },
  filteredCode: {
    fontWeight: 'regular',
    fontSize: '10px',
    marginLeft: '10px'
  },
  prices: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  stockText: {
    fontSize: '10px',
    margin: '5px 0'
  },
  stockContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    justifyContent: 'space-between'
  }
});

QueryInventory.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(QueryInventory);
