import _pickBy from 'lodash/pickBy';

export const bulkLoadSanitizer = filters => {
  const { processType, status, createdAt } = filters;
  const createdAtParts = createdAt ? createdAt.split(' - ') : null;
  let createdAtGteq = null;
  let createdAtLteq = null;

  if (createdAtParts) {
    createdAtGteq = new Date(createdAtParts[0]).toISOString();

    if (createdAtParts.length === 2) {
      createdAtLteq = new Date(
        new Date(createdAtParts[1]).setHours(23, 59, 59)
      ).toISOString();
    } else {
      createdAtLteq = new Date(new Date().setHours(23, 59, 59)).toISOString();
    }
  }

  return _pickBy(
    { processType, status, createdAtLteq, createdAtGteq },
    filter => !!filter || filter === false
  );
};
