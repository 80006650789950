import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _capitalize from 'lodash/capitalize';
import ResourceManagementTable from './ResourceManagementTable';
import { SUPPLIER_PROPOSAL_SIGNER_TYPES } from '../../../constants/enums';
import _isEmpty from 'lodash/isEmpty';

const SignersStep = props => {
  const {
    classes,
    values,
    openCreateSignerModal,
    userIsAspiringSupplier,
    proposalRejected,
  } = props;
  return (
    <Grid container item className={classes.signersStepContainer}>
      <Grid item xs={12}>
        <Typography variant='h3'>Firmantes y revisores</Typography>
      </Grid>
      <Grid item className={classes.signersStepSubtitle}>
        <Typography variant='body1'>
          Para poder firmar electrónicamente la minuta por favor ingresa la siguiente información de el o los firmantes (representante legal) relacionados en el convenio. Opcionalmente, también puede agregar revisores a los cuales les llegará la minuta para aprobación antes de la firma.
        </Typography>
      </Grid>
      <Grid container item>
        <Grid container item xs={12} className={classes.signersStepSignersTableContainer}>
          <SignersTable
            values={values}
            openCreateSignerModal={openCreateSignerModal}
            userIsAspiringSupplier={userIsAspiringSupplier}
            proposalRejected={proposalRejected}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
const SignersTable = props => {
  const {
    values,
    openCreateSignerModal,
    userIsAspiringSupplier,
    proposalRejected,
  } = props;
  return (
    <ResourceManagementTable
      resourceName='Tabla de firmantes y revisores'
      columns={
        [
          {
            title: 'Nombre completo',
            name: 'name'
          },
          {
            title: 'Tipo de identificación',
            name: 'identificationType'
          },
          {
            title: 'Identificación',
            name: 'identification'
          },
          {
            title: 'Correo electrónico',
            name: 'email'
          },
          {
            title: 'Teléfono',
            name: 'phone'
          },
          {
            title: 'Tipo',
            name: 'signerType'
          },
        ]
      }
      rows={
        values.signers.map(
          signer => {
            const signerType = SUPPLIER_PROPOSAL_SIGNER_TYPES.find(
              signerType => signerType.id.toString() === signer.signerType.toString()
            ) || {name: 'Tipo desconocido'};
            return {
              ...signer,
              signerType: signerType.name
            };
          }
        )
      }
      showCreateButton={userIsAspiringSupplier && !proposalRejected}
      onCreateButtonClick={
        signer => {
          openCreateSignerModal(signer);
        }
      }
      canClickRows={userIsAspiringSupplier && !proposalRejected}
      onRowClick={
        signer => {
          const signerType = SUPPLIER_PROPOSAL_SIGNER_TYPES.find(
            signerType => signerType.name === signer.signerType
          );
          openCreateSignerModal({
            ...signer,
            signerType: signerType.id
          });
        }
      }
    />
  );
}

export default SignersStep;