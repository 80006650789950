import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ActivityIndicator from '../../shared/ActivityIndicator';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import LineChart from '../../shared/Graphs/LineChart';
import TimespanSelector from '../../shared/Graphs/TimespanSelector';
import DashboardStyles from './Sellers.styles';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ListsStyle from '../__styles__/Lists.style';
import _replace from 'lodash/replace';

import { YEAR } from '../../../constants/timespan';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
import parseQuerystring from '../../../helpers/parseQuerystring';

import Header from './Header';
import SalesCard from './SalesCard';
import RankingCard from './RankingCard';
import SalesmanDataFilters from '../RankingList/SalesmanDataFilters';
import SaleCountSummary from './SaleCountSummary';

const icon_pig = require('../../../assets/icons/pig.svg');
const icon_trans = require('../../../assets/icons/transaction.svg');
const icon_ranking = require('../../../assets/icons/trofeo.svg');

class DashboardSellers extends Component {
  state = {
    isFreelance: this.props.currentUser.roles.some(
      role => role.name === 'asesor_freelance_fnb'
    ),
    isAdmin: this.props.currentUser.roles.some(role =>
      role.name.includes('admin')
    ),
    isSurfaceCoordinator: this.props.currentUser
      .canCreateSellerAsHugeSurfaceCoordinator,
    salesman: {
      date: moment().format('YYYY-MM-DD'),
      startDate: moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .endOf('year')
        .format('YYYY-MM-DD'),
      increment: YEAR
    },
    supplier: {
      date: moment().format('YYYY-MM-DD'),
      startDate: moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .endOf('year')
        .format('YYYY-MM-DD'),
      increment: YEAR
    },
    selectedSupplier: '',
    selectedPos: ''
  };

  componentDidMount() {
    const {
      canListDashboardSellersSummaries,
      canListDashboardSellersSales,
      canListDashboardTopRanking,
      canListDataSalesman,
      canListDataSupplier
    } = this.props;
    canListDashboardSellersSummaries;
    canListDataSalesman;
    canListDataSupplier;
    canListDashboardSellersSales;
    canListDashboardTopRanking;
  }

  _handleFilterChange = filters => {
    this.setState({
      selectedSupplier: filters.supplierName,
      selectedPos: filters.pointOfSaleName
    });

    this._fetchSellersSummary(filters);
    this._fetchTopRanking(filters);
    this._fetchDataSupplier(filters);
    this._fetchCounterSummary(filters);
    this.props.fetchFilterSelection(filters);
    this._fetchPointsOfSale(filters);
    this._fetchSuppliers(filters);
    this._fetchSellersSales(filters);
    this._fetchDataSalesman(filters);
  };

  _handleDateChange = (graphName, date) => {
    const field = { ...this.state[graphName] };
    field.date = date.format('YYYY-MM-DD');
    field.startDate = moment(field.date)
      .startOf(field.increment)
      .format('YYYY-MM-DD');
    field.endDate = moment(field.startDate)
      .endOf(field.increment)
      .format('YYYY-MM-DD');
    this.setState({ [graphName]: field }, () => {
      if (graphName === 'salesman') {
        this._fetchDataSalesman();
      } else {
        this._fetchDataSupplier(this.props.supplierFilter);
      }
    });
  };

  _handleIncrementChange = (graphName, increment) => {
    const field = { ...this.state[graphName] };
    field.increment = increment;
    field.startDate = moment(field.date)
      .startOf(increment)
      .format('YYYY-MM-DD');
    field.endDate = moment(field.startDate)
      .endOf(increment)
      .format('YYYY-MM-DD');
    this.setState({ [graphName]: field }, () => {
      if (graphName === 'salesman') {
        this._fetchDataSalesman();
      } else {
        this._fetchDataSupplier(this.props.supplierFilter);
      }
    });
  };

  _fetchSellersSales = params => {
    const query = params || parseQuerystring(this.props) || {};
    new Promise((resolve, reject) => {
      (async () => {
        try {
          await this.props.fetchSellersSales({
            ...query,
            page: query.page || 1,
            per_page: this.state.rowsPerPage
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      })();
      setTimeout(() => reject('Servicio no disponible.'), 120000);
    });
  };

  _fetchSuppliers = params => {
    this.props.fetchSuppliers({ selectedZones: params.selectedZones });
  };

  _fetchSellersSummary = params => {
    const query = params || parseQuerystring(this.props) || {};
    if (this.state.isSurfaceCoordinator || this.state.isFreelance) {
      new Promise((resolve, reject) => {
        (async () => {
          try {
            await this.props.fetchSellersSummary({
              pointOfSale: params.pointOfSale,
              supplier: params.supplier,
              channelId: params.channelId,
              selectedZones: params.selectedZones
            });
            resolve();
          } catch (error) {
            reject(error);
          }
        })();
        setTimeout(() => reject('Servicio no disponible.'), 120000);
      });
    } else {
      new Promise((resolve, reject) => {
        (async () => {
          try {
            await this.props.fetchSellersSummary({
              pointOfSale: params.pointOfSale
            });
            resolve();
          } catch (error) {
            reject(error);
          }
        })();
        setTimeout(() => reject('Servicio no disponible.'), 120000);
      });
    }
  };

  _fetchCounterSummary = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchCounterSummary({
      ...query
    });
  };

  _fetchTopRanking = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchTopRanking({
      ...query
    });
  };

  _fetchDataSalesman = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchDataSalesman({
      supplier: query.supplier,
      start_at: this.state.salesman.startDate,
      end_at: this.state.salesman.endDate,
      interval: this.state.salesman.increment
    });
  };

  _fetchDataSupplier = params => {
    if (this.props.canListDataSupplier) {
      const query = params || parseQuerystring(this.props) || {};
      this.props.fetchDataSupplier({
        supplier: this.props.supplierFilter,
        start_at: this.state.supplier.startDate,
        end_at: this.state.supplier.endDate,
        interval: this.state.supplier.increment,
        ...query
      });
    }
  };

  _fetchPointsOfSale = params => {
    const supplier = params.supplier || '';
    const selectedZones = params.selectedZones || [];
    if (
      this.state.isAdmin &&
      (supplier.length > 0 || selectedZones.length > 0)
    ) {
      this.props.fetchPointsOfSaleList({
        supplier,
        selectedZones
      });
    }
  };

  viewPos = () => {
    if (!this.state.isSurfaceCoordinator) {
      return true;
    } else {
      return false;
    }
  };

  _isCoordinator = userRoles => {
    return userRoles &&
      this.props.hugeSurfaceCoordinatorRoles &&
      userRoles.some(role =>
        this.props.hugeSurfaceCoordinatorRoles.includes(role.name)
      )
      ? true
      : false;
  };

  render() {
    const {
      theme,
      classes,
      requestDashboardSellersSummarySend,
      requestDashboardSellersSalesSend,
      requestDashboardDataSalesmanSend,
      requestDashboardDataSupplierSend,
      requestDashboardCounterSummarySend,
      requestTopRankingSend,
      dataSellersSummary,
      dataTopRanking,
      dataSellersSales,
      dataSalesman,
      dataSupplier,
      counterSummary,
      currentUserPos,
      currentUser,
      zones,
      canListDataSupplier,
      canListDataSalesman,
      canListDashboardSellersSummaries,
      canListDashboardSellersSales,
      canListDashboardTopRanking,
      canViewDashboardCounters,
      suppliers,
      saleChannels,
      fetchSupplierFilter,
      pointsOfSaleList,
      newAlert
    } = this.props;

    const { salesman = {}, supplier = {} } = this.state;
    const saleDetails = dataSellersSales;

    return (
      <Grid container spacing={24}>
        {canListDashboardSellersSummaries && (
          <Grid item xs={12}>
            <Header
              role={this.state.isFreelance}
              data={dataSellersSummary}
              isLoading={requestDashboardSellersSummarySend}
              supplierSelected={!!this.state.selectedSupplier}
            />
          </Grid>
        )}
        {canListDashboardSellersSales && canListDashboardTopRanking && zones && (
          <Grid item xs={12}>
            <div className={classnames(classes.wrapperFilter)}>
              <div className={classes.filter}>
                <SalesmanDataFilters
                  suppliers={suppliers}
                  saleChannels={saleChannels}
                  pointsOfSale={currentUser.pointOfSales}
                  pointsOfSaleList={pointsOfSaleList}
                  isFreelance={this.state.isFreelance}
                  ranking={false}
                  currentUserPos={currentUserPos}
                  currentUser={currentUser}
                  onChangeFilter={this._handleFilterChange}
                  supplierFilter={fetchSupplierFilter}
                  filterSelection={this.props.filterSelection}
                  isSurfaceCoordinator={this.state.isSurfaceCoordinator}
                  hasCoordinatorPermissions={this._isCoordinator(
                    currentUser.roles
                  )}
                  isAdmin={this.state.isAdmin}
                  newAlert={newAlert}
                  zones={zones.filter(
                    zone => parseInt(zone.area_type) === 2 && zone.id <= 4
                  )}
                />
              </div>
            </div>

            <div
              className={classnames(classes.wrapper, classes.rowCardWrapper)}
            >
              <Grid container className={classes.root} spacing={32}>
                <Grid item sm={4} xs={12}>
                  <SalesCard
                    icon={icon_trans}
                    freelance={this.state.isFreelance}
                    isSurfaceCoordinator={this.state.isSurfaceCoordinator}
                    admin={this.state.isAdmin}
                    canSeePosText={
                      currentUser.canFilterDashboardByPos || !!currentUserPos
                    }
                    message={
                      'Mes anterior: ' +
                      saleDetails.lastMonth +
                      ' ' +
                      saleDetails.lastYear
                    }
                    partialCount={_moneyFormat(saleDetails.partialLastCount)}
                    totalCount={_moneyFormat(saleDetails.totalLastCount)}
                    isLoading={requestDashboardSellersSalesSend}
                    posSelected={!!this.state.selectedPos}
                    supplierSelected={!!this.state.selectedSupplier}
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <SalesCard
                    icon={icon_pig}
                    freelance={this.state.isFreelance}
                    isSurfaceCoordinator={this.state.isSurfaceCoordinator}
                    admin={this.state.isAdmin}
                    message={
                      'Mes actual: ' +
                      saleDetails.month +
                      ' ' +
                      saleDetails.year
                    }
                    partialCount={_moneyFormat(saleDetails.partialActualCount)}
                    totalCount={_moneyFormat(saleDetails.totalActualCount)}
                    isLoading={requestDashboardSellersSalesSend}
                    canSeePosText={
                      currentUser.canFilterDashboardByPos || !!currentUserPos
                    }
                    posSelected={!!this.state.selectedPos}
                    supplierSelected={!!this.state.selectedSupplier}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  {requestTopRankingSend && (
                    <ActivityIndicator className={classes.loadingIndicator} />
                  )}
                  <RankingCard
                    icon={icon_ranking}
                    title={'Top Vendedores'}
                    subtitle={'Ver Ranking'}
                    person={dataTopRanking}
                    isLoading={requestTopRankingSend}
                    selectedSupplier={this.state.selectedSupplier}
                    selectedPos={this.state.selectedPos}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}

        {(canListDataSupplier || canListDataSalesman) && (
          <Grid item xs={12}>
            <div className={classnames(classes.wrapperFilter)}>
              <h2 className={classnames(classes.headline7, classes.graphTitle)}>
                {'Historial de Ventas'}
              </h2>
              {canViewDashboardCounters && (
                <SaleCountSummary
                  counterSummary={counterSummary}
                  counterStates={this.props.counterStates}
                  isLoading={requestDashboardCounterSummarySend}
                />
              )}
              <Grid container spacing={24} justify="center">
                {(!this.state.isFreelance || this.state.isAdmin) &&
                  canListDataSupplier && (
                    <Grid item xs={12} md={6}>
                      <div
                        className={classnames(
                          classes.wrapperNoBox,
                          classes.rowCardWrapper
                        )}
                      >
                        {requestDashboardDataSupplierSend && (
                          <ActivityIndicator
                            className={classes.loadingIndicator}
                          />
                        )}
                        <p>{'Ventas Aliado'}</p>
                        <TimespanSelector
                          onIncrementChange={this._handleIncrementChange}
                          increment={supplier.increment}
                          startDate={supplier.startDate}
                          date={supplier.date}
                          endDate={supplier.endDate}
                          graphName={'supplier'}
                          onDateChange={this._handleDateChange}
                        />
                        <div>
                          <LineChart
                            data={dataSupplier.map(sale => sale.count)}
                            labels={dataSupplier.map(sale => {
                              return supplier.increment === YEAR
                                ? moment(sale.date).format('YYYY-MM')
                                : sale.date;
                            })}
                            backgroundColor={theme.palette.primary.main}
                            borderColor={theme.palette.primary.main}
                            pointBackgroundColor={theme.palette.primary.main}
                            pointHoverBackgroundColor={
                              theme.palette.primary.main
                            }
                            pointHoverBorderColor={
                              theme.palette.secondary.light
                            }
                          />
                        </div>
                      </div>
                    </Grid>
                  )}
                {canListDataSalesman && (
                  <Grid
                    item
                    xs={12}
                    md={!this.state.isFreelance || this.state.isAdmin ? 6 : 12}
                  >
                    <div
                      className={classnames(
                        classes.wrapperNoBox,
                        classes.rowCardWrapper
                      )}
                    >
                      {requestDashboardDataSalesmanSend && (
                        <ActivityIndicator
                          className={classes.loadingIndicator}
                        />
                      )}
                      <p>{'Mis ventas'}</p>
                      <TimespanSelector
                        onIncrementChange={this._handleIncrementChange}
                        increment={salesman.increment}
                        startDate={salesman.startDate}
                        date={salesman.date}
                        endDate={salesman.endDate}
                        graphName={'salesman'}
                        onDateChange={this._handleDateChange}
                      />
                      <div>
                        <LineChart
                          data={dataSalesman.map(mysale => mysale.count)}
                          labels={dataSalesman.map(mysale => {
                            return salesman.increment === YEAR
                              ? moment(mysale.date).format('YYYY-MM')
                              : mysale.date;
                          })}
                          backgroundColor={theme.palette.primary.main}
                          borderColor={theme.palette.primary.main}
                          pointBackgroundColor={theme.palette.primary.main}
                          pointHoverBackgroundColor={theme.palette.primary.main}
                          pointHoverBorderColor={theme.palette.secondary.light}
                        />
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>

              <div className={classnames(classes.headline7, classes.section)}>
                <h3
                  className={classnames(classes.headline7, classes.textSection)}
                >
                  {'Para conocer el detalle de tus ventas: '}
                </h3>
                <br />
                <Link to="/sales">
                  <button type="button" className={classes.button}>
                    {'VER DETALLES'}
                  </button>
                </Link>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(
  combineStyles(DashboardStyles, LayoutStyles, ListsStyle),
  {
    withTheme: true
  }
)(DashboardSellers);
