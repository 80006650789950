import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StepTitle from './StepTitle';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import Divider from '@material-ui/core/Divider';
import FileUploader from './FileUploader';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import { SUPPLIER_PROPOSAL_DOCUMENT_TYPES } from '../../../constants/enums';
import { formatMoney } from 'accounting';

const CompanySalesStep = props => {
  const {
    classes,
    values,
    handleBlur,
    touched,
    errors,
    newAlert,
    setFieldValue,
    proposalWasSent,
    onAddDocument,
    canCreateDocuments,
    updateDocument,
    loadingDocumentIds,
    onViewOtherComments,
    onAddComment,
    canAcceptOrRejectFile,
    showCommentSection,
    allowFileReplace,
    fileMaxSize,
    fileSizeUnit,
    extensions,
    allowAddComment,
    handleFileChange,
    handleFileError,
    handleFileAcceptanceChange,
    getDocByDocType,
    proposalAccepted,
    proposalRejected,
    handleChangeOnlyDigits,
    fetchFileBase64
  } = props;
  const lastYearFinancialStatementDoc = getDocByDocType(
    SUPPLIER_PROPOSAL_DOCUMENT_TYPES.LastYearFinancialStatement,
    values.documents
  );
  const penultimateYearFinancialStatementDoc = getDocByDocType(
    SUPPLIER_PROPOSAL_DOCUMENT_TYPES.PenultimateYearFinancialStatement,
    values.documents
  );
  const commercialBrochureDoc = getDocByDocType(
    SUPPLIER_PROPOSAL_DOCUMENT_TYPES.CommercialBrochure,
    values.documents
  );
  const otherDocuments = _getOtherDocuments(values.documents);
  const showCommercialBrochure =
    !proposalWasSent ||
    (proposalWasSent && !!commercialBrochureDoc.originalFileName);
  return (
    <Grid container item className={classes.companySalesStepContainer}>
      <StepTitle />
      <Grid item xs={12} className={classes.companySalesStepSubtitle}>
        <Typography variant="body1">
          Completa los siguientes datos de la empresa para continuar con el
          proceso
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Ingresa el total de ventas anuales a personas naturales en los
            últimos dos años
          </Typography>
        </Grid>
        <Grid container item justify="space-between" xs={12}>
          <Grid item xs={5}>
            <OutlinedTextField
              name="lastYearSalesAverage"
              label="Último año"
              disabled={proposalWasSent}
              value={formatMoneyField(values.lastYearSalesAverage)}
              onBlur={handleBlur}
              onChange={event => {
                handleChangeOnlyDigits(
                  event,
                  'lastYearSalesAverage',
                  setFieldValue
                );
              }}
              helperText={
                touched.lastYearSalesAverage && errors.lastYearSalesAverage
              }
              error={
                touched.lastYearSalesAverage && !!errors.lastYearSalesAverage
              }
            />
          </Grid>
          <Grid item xs={5}>
            <OutlinedTextField
              name="penultimateYearSalesAverage"
              label="Penúltimo año"
              disabled={proposalWasSent}
              value={formatMoneyField(values.penultimateYearSalesAverage)}
              onBlur={handleBlur}
              onChange={event => {
                handleChangeOnlyDigits(
                  event,
                  'penultimateYearSalesAverage',
                  setFieldValue
                );
              }}
              helperText={
                touched.penultimateYearSalesAverage &&
                errors.penultimateYearSalesAverage
              }
              error={
                touched.penultimateYearSalesAverage &&
                !!errors.penultimateYearSalesAverage
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.dividerContainer}>
        <Divider />
      </Grid>
      <Grid container item justify="center" xs={12}>
        {canCreateDocuments && !proposalAccepted && !proposalRejected && (
          <Grid container item justify="center">
            <Grid
              container
              item
              xs={8}
              className={classes.addDocumentButtonContainer}
            >
              <Grid item xs={6}>
                <Typography variant="body1">
                  ¿Consideras que hace falta algún documento por adjuntar?
                </Typography>
              </Grid>
              <Grid container item justify="flex-end" xs={6}>
                <ProgressButton
                  variant="contained"
                  color="secondary"
                  onClick={onAddDocument(
                    SUPPLIER_PROPOSAL_DOCUMENT_TYPES.AdditionalInCompanySalesStep
                  )}
                >
                  Agregar documento
                </ProgressButton>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.dividerContainer}>
              <Divider />
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12} className={classes.filesTitle}>
          <Typography variant="body1">
            Adjunta los estados financieros de los últimos dos años en formato
            PDF,{' '}
            <b>
              firmados por el revisor fiscal y/o representante legal (de lo
              contrario no serán tenidos en cuenta).
            </b>
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={8}
          className={classes.companySalesStepFileUploaderContainer}
        >
          <FileUploader
            allowFileReplace={allowFileReplace(lastYearFinancialStatementDoc)}
            fileName={lastYearFinancialStatementDoc.originalFileName}
            fetchFileBase64={fetchFileBase64({
              document: lastYearFinancialStatementDoc
            })}
            label={lastYearFinancialStatementDoc.name}
            description={lastYearFinancialStatementDoc.description}
            maxSize={fileMaxSize}
            sizeUnit={fileSizeUnit}
            extensions={extensions}
            onChange={handleFileChange({
              values,
              document: lastYearFinancialStatementDoc,
              newAlert,
              setFieldValue,
              updateDocument
            })}
            onError={handleFileError({ newAlert, fileMaxSize, fileSizeUnit })}
            submitting={loadingDocumentIds.includes(
              lastYearFinancialStatementDoc.id
            )}
            comments={lastYearFinancialStatementDoc.comments}
            onViewOtherComments={comments => onViewOtherComments(comments)}
            onAddComment={onAddComment({
              documentId: lastYearFinancialStatementDoc.id,
              setFieldValue,
              values
            })}
            canAcceptOrRejectFile={canAcceptOrRejectFile}
            showCommentSection={showCommentSection(
              lastYearFinancialStatementDoc
            )}
            onFileAcceptanceChange={handleFileAcceptanceChange({
              documentId: lastYearFinancialStatementDoc.id,
              setFieldValue,
              values
            })}
            fileAccepted={lastYearFinancialStatementDoc.accepted}
            allowAddComment={allowAddComment(lastYearFinancialStatementDoc)}
          />
        </Grid>
        <Grid
          container
          item
          xs={8}
          className={classes.companySalesStepFileUploaderContainer}
        >
          <FileUploader
            allowFileReplace={allowFileReplace(
              penultimateYearFinancialStatementDoc
            )}
            fileName={penultimateYearFinancialStatementDoc.originalFileName}
            fetchFileBase64={fetchFileBase64({
              document: penultimateYearFinancialStatementDoc
            })}
            label={penultimateYearFinancialStatementDoc.name}
            description={penultimateYearFinancialStatementDoc.description}
            maxSize={fileMaxSize}
            sizeUnit={fileSizeUnit}
            extensions={extensions}
            onChange={handleFileChange({
              values,
              document: penultimateYearFinancialStatementDoc,
              newAlert,
              setFieldValue,
              updateDocument
            })}
            onError={handleFileError({ newAlert, fileMaxSize, fileSizeUnit })}
            submitting={loadingDocumentIds.includes(
              penultimateYearFinancialStatementDoc.id
            )}
            comments={penultimateYearFinancialStatementDoc.comments}
            onViewOtherComments={comments => onViewOtherComments(comments)}
            onAddComment={onAddComment({
              documentId: penultimateYearFinancialStatementDoc.id,
              setFieldValue,
              values
            })}
            canAcceptOrRejectFile={canAcceptOrRejectFile}
            showCommentSection={showCommentSection(
              penultimateYearFinancialStatementDoc
            )}
            onFileAcceptanceChange={handleFileAcceptanceChange({
              documentId: penultimateYearFinancialStatementDoc.id,
              setFieldValue,
              values
            })}
            fileAccepted={penultimateYearFinancialStatementDoc.accepted}
            allowAddComment={allowAddComment(
              penultimateYearFinancialStatementDoc
            )}
          />
        </Grid>
        {showCommercialBrochure && (
          <Grid container item justify="center">
            <Grid item xs={12} className={classes.dividerContainer}>
              <Divider />
            </Grid>
            <Grid container item xs={12} className={classes.filesTitle}>
              <Typography variant="body1">
                En caso de que desee agregar un brochure comercial en formato
                PDF, puede hacerlo aquí
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={8}
              className={classes.companySalesStepFileUploaderContainer}
            >
              <FileUploader
                allowFileReplace={allowFileReplace(commercialBrochureDoc)}
                fileName={commercialBrochureDoc.originalFileName}
                fetchFileBase64={fetchFileBase64({
                  document: commercialBrochureDoc
                })}
                label={commercialBrochureDoc.name}
                description={commercialBrochureDoc.description}
                maxSize={fileMaxSize}
                sizeUnit={fileSizeUnit}
                extensions={extensions}
                onChange={handleFileChange({
                  values,
                  document: commercialBrochureDoc,
                  newAlert,
                  setFieldValue,
                  updateDocument
                })}
                onError={handleFileError({
                  newAlert,
                  fileMaxSize,
                  fileSizeUnit
                })}
                submitting={loadingDocumentIds.includes(
                  commercialBrochureDoc.id
                )}
                comments={commercialBrochureDoc.comments}
                onViewOtherComments={comments => onViewOtherComments(comments)}
                onAddComment={onAddComment({
                  documentId: commercialBrochureDoc.id,
                  setFieldValue,
                  values
                })}
                canAcceptOrRejectFile={canAcceptOrRejectFile}
                showCommentSection={showCommentSection(commercialBrochureDoc)}
                onFileAcceptanceChange={handleFileAcceptanceChange({
                  documentId: commercialBrochureDoc.id,
                  setFieldValue,
                  values
                })}
                fileAccepted={commercialBrochureDoc.accepted}
                allowAddComment={allowAddComment(commercialBrochureDoc)}
              />
            </Grid>
          </Grid>
        )}
        {otherDocuments.length > 0 && (
          <Grid container item justify="center">
            <Grid item xs={12} className={classes.dividerContainer}>
              <Divider />
            </Grid>
            <Grid container item xs={12}>
              <Typography variant="h5">Documentos adicionales</Typography>
            </Grid>
            {otherDocuments.map(document => {
              return (
                <Grid
                  key={document.id}
                  container
                  item
                  xs={8}
                  className={classes.companySalesStepFileUploaderContainer}
                >
                  <FileUploader
                    allowFileReplace={allowFileReplace(document)}
                    fileName={document.originalFileName}
                    fetchFileBase64={fetchFileBase64({ document: document })}
                    label={document.name}
                    description={document.description}
                    maxSize={fileMaxSize}
                    sizeUnit={fileSizeUnit}
                    extensions={extensions}
                    onChange={handleFileChange({
                      values,
                      document,
                      newAlert,
                      setFieldValue,
                      updateDocument
                    })}
                    onError={handleFileError({
                      newAlert,
                      fileMaxSize,
                      fileSizeUnit
                    })}
                    submitting={loadingDocumentIds.includes(document.id)}
                    comments={document.comments}
                    onViewOtherComments={comments =>
                      onViewOtherComments(comments)
                    }
                    onAddComment={onAddComment({
                      documentId: document.id,
                      setFieldValue,
                      values
                    })}
                    canAcceptOrRejectFile={canAcceptOrRejectFile}
                    showCommentSection={showCommentSection(document)}
                    onFileAcceptanceChange={handleFileAcceptanceChange({
                      documentId: document.id,
                      setFieldValue,
                      values
                    })}
                    fileAccepted={document.accepted}
                    allowAddComment={allowAddComment(document)}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const _getOtherDocuments = documents => {
  return documents.filter(
    doc =>
      doc.docType ===
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.AdditionalInCompanySalesStep
  );
};

const formatMoneyField = value => {
  const str = formatMoney(value, '', 0, ',', '.');
  if (Number(str) === 0) return '';
  return str;
};

export default CompanySalesStep;
