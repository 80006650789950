import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog from '../../shared/Dialogs/CustomDialog';
import { history } from '../../../helpers/history';

class PortalErrors extends React.PureComponent {
  _closeBaseError = () => {
    const errorText = this.props.baseErrorText;
    this.props.toggleModal(false, errorText);
    if (this.props.baseErrorRoute) {
      history.push(this.props.baseErrorRoute);
    }
  };

  render() {
    const {
      server_errors = {},
      baseErrorOpen,
      baseErrorText,
      baseErrorTitle = 'Registro de venta'
    } = this.props;

    const errorMessage = baseErrorText || server_errors.base;
    let messageParts = null;
    let errors = null;

    if (server_errors.type && server_errors.type[0] === 'max_items') {
      messageParts = server_errors.base[0].split(':');
      errors = messageParts[1].split('.');
    }
    return (
      <CustomDialog
        open={baseErrorOpen}
        disableBackdropClick={true}
        onConfirm={this._closeBaseError}
        onClose={this._closeBaseError}
        title={`Mensaje importante: ${baseErrorTitle}`}
        buttonText={`CONTINUAR`}
      >
        {messageParts ? (
          <p>
            {messageParts[0]}:
            <ul>
              {errors.map(error => error && <li key={error}>{error}</li>)}
            </ul>
          </p>
        ) : (
          <p>{errorMessage}</p>
        )}
      </CustomDialog>
    );
  }
}

PortalErrors.propTypes = {
  server_errors: PropTypes.object,
  baseErrorOpen: PropTypes.bool,
  baseErrorText: PropTypes.string,
  toggleModal: PropTypes.func
};
export default PortalErrors;
