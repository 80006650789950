export const columns = [
  {
    title: '# Marca',
    name: 'id'
  },
  {
    title: 'Nombre',
    name: 'name'
  },
  {
    title: 'Descripción',
    name: 'description'
  },
  {
    title: 'Estado',
    name: 'status'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      id: datum.id,
      name: `${datum.name || '--'}`,
      description: `${datum.description || '--'}`,
      status: datum.active ? 'Activo' : 'Inactivo'
    };
  });
