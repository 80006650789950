import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';

const styles = theme => ({
  listContainer: {
    listStyle: 'none',
    '& li': {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
    '& :first-child': {
      paddingTop: 0
    },
    '& :last-child': {
      borderBottom: 'none'
    },
    margin: 0,
    position: 'relative',
    top: 24,
    paddingLeft: theme.spacing.unit * 1.5,
  },
  colorBox: {
    width: theme.spacing.unit * 1.5,
    height: theme.spacing.unit * 1.5,
    display: 'inline-block',
    borderRadius: theme.spacing.unit * 0.25,
    marginRight: theme.spacing.unit * 0.5,
    position: 'relative',
    top: 1,
  },
  label: {
    height: theme.spacing.unit * 2
  }
});
export class DonutLegend extends Component {
  static propTypes = {
    labels: PropTypes.array.isRequired,
    colors: PropTypes.array.isRequired
  };

  render() {
    const { labels, colors, classes } = this.props;
    return (
      <ul className={classes.listContainer}>
        {labels.map((label, index) => (
          <li key={index}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: colors[index] }}
            />
            <span className={classnames(classes.label, classes.caption)}>
              {label}
            </span>
          </li>
        ))}
      </ul>
    );
  }
}

export default withStyles(combineStyles(TypographyStyles, styles))(DonutLegend);
