import moment from 'moment-timezone';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
import { POINT_USER_ASSOCIATION_STATUS } from '../../../constants/enums';
import _get from 'lodash/get';

export const columns = [
  {
    title: 'Tipo',
    name: 'type'
  },
  {
    title: 'Identificación',
    name: 'Identificación'
  },
  {
    title: 'Nombre Asesor',
    name: 'asesor'
  },
  {
    title: 'Punto de Venta',
    name: 'pointOfSale'
  },
  {
    title: 'Fecha Solicitud',
    name: 'registrationDate'
  },
  {
    title: 'Estado',
    name: 'status'
  }
];

const getName = (name, lastName) => {
  return name == "" && lastName == ""
    ? null
    : name.toUpperCase() + " " + lastName.toUpperCase();
}

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      type: _get(datum, 'user_role[0].display_name') || '--',
      Identificación: _get(datum, 'user.identification') || '--',
      asesor: getName(datum.user.first_name, datum.user.last_name) || '--',
      registrationDate: datum.created_at
        ? moment(datum.created_at)
          .locale('es')
          .format('DD MMM YYYY')
        : '--',
      status: POINT_USER_ASSOCIATION_STATUS[datum.status] || '--',
      pointOfSale: _get(datum, 'point_of_sale.name') || '--'
    };
  });
