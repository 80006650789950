import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import QueryInventory from './QueryInventory';
import { newAlert } from '../../../actions/alert_actions';
import {
  queryInventory,
  setFilteredInventory,
  setSelectedProduct,
  setStore,
  getCategories,
  setCategoriesD,
  closeQueryInventoryModal,
  resetQueryData
} from '../../../actions/query_inventory_actions';

function mapStateToProps(state) {
  return {
    loading: state.requestState.requestQueryInventorysSend,
    serverErrors: state.requestState.requestErrorsOnQueryInventorys,
    inventory: state.queryInventory.inventory,
    filteredInventory: state.queryInventory.filteredInventory,
    selectedProduct: state.queryInventory.selectedProduct,
    store: state.queryInventory.store,
    categories: state.queryInventory.categories,
    isOpen: state.queryInventory.isQueryInventoryModalOpen,
    hasSearched: state.queryInventory.hasSearched
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    queryInventory: bindActionCreators(queryInventory, dispatch),
    setFilteredInventory: bindActionCreators(setFilteredInventory, dispatch),
    setSelectedProduct: bindActionCreators(setSelectedProduct, dispatch),
    setStore: bindActionCreators(setStore, dispatch),
    getCategories: bindActionCreators(getCategories, dispatch),
    setCategoriesD: bindActionCreators(setCategoriesD, dispatch),
    closeModal: bindActionCreators(closeQueryInventoryModal, dispatch),
    resetQueryData: bindActionCreators(resetQueryData, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QueryInventory);
