import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _every from 'lodash/every';
import _find from 'lodash/find';
import {
  Grid,
  TextField,
  withStyles,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { mapCausals } from '../../../../helpers/mappings/mapToOptions';
import SimpleTable from '../../../shared/Tables/SimpleTable';
import CustomDialog from '../../../shared/Modals/CustomDialog';
import OutlinedSelectChippedInput from '../../../shared/Inputs/OutlinedSelectChipped';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import Movements from '../../../shared/Movements/Movements';
import combineStyles from '../../../../helpers/combineStyles';
import TypographyStyles from '../../../../styles/typography';
import Button from '@material-ui/core/Button';

class HomeVisitRevision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      cause: '',
      effectiveContact: false,
      contactName: '',
      articleName: '',
      saleNumber: '',
      api_errors: null,
      errors: null,
      updateErrorMessage: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.serverErrors !== this.props.serverErrors &&
      !_isEmpty(this.props.serverErrors)
    ) {
      this.setState({ api_errors: this.props.serverErrors });
    }
  }

  _filterCausals = causals => {
    let filteredCausals = causals.filter(
      causal => causal.task_id === this.props.homeVisit.taskId
    );
    return mapCausals(filteredCausals);
  };

  /**
   * Indicates whether the article exists.
   * @param {String} articleName Article name.
   * @returns True if the article exists, false otherwise
   */
  _articleExists = articleName => {
    const articles = _get(this.props.homeVisitArticles, 'supplies');

    return !!articles.find(
      article => article.name === articleName.toUpperCase()
    );
  };

  _handleSave = () => {
    let saleNumber = null;
    let articleName = null;

    if (this.state.effectiveContact && this.state.saleNumber.length > 0) {
      saleNumber = parseInt(this.state.saleNumber);
    }

    if (this.state.effectiveContact) {
      articleName = this._articleExists(this.state.articleName)
        ? this.state.articleName.toUpperCase()
        : null;

      if (articleName === null) {
        this.setState({
          errors: { articleName: 'Seleccióne un artículo válido' }
        });

        return;
      }
    }

    const body = {
      id: Number(this.props.homeVisit.id),
      causalId: this.state.cause,
      observations: this.state.comment,
      effectiveContact: this.state.effectiveContact,
      contactName: this.state.effectiveContact ? this.state.contactName : null,
      articleName,
      saleNumber
    };
    this.props.onSave(body);
  };

  _handleClose = () => {
    if (!this.props.requestSend) {
      this.setState({
        comment: '',
        cause: '',
        effectiveContact: false,
        contactName: '',
        articleName: '',
        saleNumber: ''
      });
      this.props.onClose();
    }
  };

  _handleChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value, api_errors: null });

  _handleCheckboxChecked = event => {
    this.setState({ effectiveContact: event.target.checked });
  };

  _disableSubmit = () => {
    const cantBeEmpty = {
      ...this.state,
      contactName: this.state.effectiveContact
        ? this.state.contactName
        : 'no-check',
      articleName: this.state.effectiveContact
        ? this.state.articleName
        : 'no-check',
      saleNumber: 'no-check',
      api_errors: 'no-check',
      effectiveContact: 'no-check',
      errors: 'no-check',
      updateErrorMessage: 'no-check'
    };

    if (!_every(cantBeEmpty, e => !!e)) return true;
    return false;
  };

  _modalProps = homeVisit => {
    if (
      homeVisit.orderStatus === 2 ||
      homeVisit.orderStatus === 3 ||
      !this.props.canLegalizeHomeVisits
    ) {
      return {
        title: 'Legalización de Agendamiento',
        onConfirm: () => null
      };
    }

    return {
      onConfirm: () => this._handleSave(),
      title: 'Legalización de Agendamiento',
      buttonText: 'Legalizar'
    };
  };

  _formatHomeVisit = homeVisit => {
    const visit = [];
    visit.push({
      ...homeVisit,
      causal: _get(homeVisit, 'causal.description') || '--',
      pointOfSale: _get(homeVisit, 'pointOfSale') || '--'
    });
    return visit;
  };

  /**
   * Formats the effective contact field as a string.
   * @param {boolean} effectiveContact Indicates whether there was an effective contact or not.
   * @returns "SÍ" if effectiveContact = true,
   *          "NO" if effectiveContact = false,
   *          "--" if effectiveContact = null
   */
  _formatEffectiveContact = effectiveContact => {
    if (effectiveContact !== null) {
      return effectiveContact ? 'SÍ' : 'NO';
    }

    return '--';
  };

  _getArticleSuggestions = async value => {
    const articles = _get(this.props.homeVisitArticles, 'supplies').map(article => ({
      label: article.name,
      value: article.id
    }));

    const suggestions = articles.filter(article => {
      return article.label.includes(value.trim().toUpperCase());
    });

    return Promise.resolve(suggestions);

    //const suggestions = filterListFuzzyly(value, articles, 'label');
    //return Promise.resolve(suggestions);
  };

  renderUpdateErrorMessage(scheduleStatus, isScheduleFinished) {
    if (isScheduleFinished) {
      return <p>
        No es posible realizar la edición del agendamiento, debido a que este ya ha sido {scheduleStatus == 2 ? 'aprobado' : 'rechazado'}.
      </p>;
    } else {
      return <p>
        No es posible realizar la edición del agendamiento, debido a que este se encuentra activo en Open Smartflex.
      </p>;
    }
  }

  render() {
    const {
      classes,
      homeVisit,
      homeVisitArticles,
      openUpdateScheduleModal,
      requestSend,
      canUpdateHomeVisits,
      canLegalizeHomeVisits,
      canShowHomeVisit,
      causals,
    } = this.props;
    const {
      comment,
      cause,
      effectiveContact,
      contactName,
      articleName,
      saleNumber,
      api_errors,
      errors
    } = this.state;
    if (!homeVisit) return null;
    if (!homeVisit.status) return null;
    if (!canShowHomeVisit) return null;

    const isScheduleFinished = homeVisit.orderStatus === 2 || homeVisit.orderStatus === 3;
    return (
      <div>
        <CustomDialog
          open={true}
          loading={requestSend}
          loadingSecondary={requestSend}
          disabled={requestSend || this._disableSubmit()}
          disableBackdropClick={false}
          headerSecondary={
          canUpdateHomeVisits &&
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={() => {
                if (isScheduleFinished || homeVisit.processInOsf) {
                  this.setState({ updateErrorMessage: true });
                } else {
                  openUpdateScheduleModal(homeVisit);
                }
              }}
            >
              {'EDITAR'}
            </Button>
          }
          onClose={this._handleClose}
          {...this._modalProps(homeVisit)}
          classes={{
            root: classes.dialogRoot
          }}
        >
          <Grid container spacing={16}>
            {homeVisit.movements && (
              <Movements
                movements={homeVisit.movements}
                disables={['reviewed']}
              />
            )}
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  {
                    name: 'status',
                    title: 'Estado'
                  },
                  {
                    name: 'causal',
                    title: 'Causal'
                  },
                  {
                    name: 'pointOfSale',
                    title: 'Punto de Venta'
                  }
                ]}
                rows={this._formatHomeVisit(homeVisit)}
              />
              <p
                className={classnames(
                  classes.headline7,
                  classes.titleContainer
                )}
              >
                {`Contrato: ${_get(homeVisit, 'contract.id') || '--'}`}
              </p>
              <p
                className={classnames(
                  classes.subtitle3,
                  classes.titleContainer
                )}
              >
                {'Ubicación: '}
                <a className={classes.body2Light}>
                {_get(homeVisit, 'contract.location.displayDescription') ||
                    '--'}
                </a>
              </p>
              <p
                className={classnames(
                  classes.subtitle3,
                  classes.titleContainer
                )}
              >
                {'Dirección: '}
                <a className={classes.body2Light}>
                  {_get(homeVisit, 'contract.address') || '--'}
                </a>
              </p>
              <p
                className={classnames(
                  classes.headline7,
                  classes.titleContainer
                )}
              >
                {'Solicitado por:'}
              </p>
              <p
                className={classnames(
                  classes.subtitle3,
                  classes.titleContainer
                )}
              >
                {'Nombre: '}
                <a className={classes.body2Light}>
                  {`${_get(homeVisit, 'creator.firstName') ||
                    '--'} ${_get(homeVisit, 'creator.lastName', '')}`}
                </a>
              </p>
              <p
                className={classnames(
                  classes.subtitle3,
                  classes.titleContainer
                )}
              >
                {'Medio de recepción: '}
                <a className={classes.body2Light}>
                  {`${_get(homeVisit, 'saleChannel.name') || '--'}`}
                </a>
              </p>
              <p
                className={classnames(
                  classes.subtitle3,
                  classes.titleContainer
                )}
              >
                {'Comentarios: '}
                <a className={classes.body2Light}>
                  {homeVisit.requestObservations}
                </a>
              </p>
              <p
                className={classnames(
                  classes.subtitle3,
                  classes.titleContainer
                )}
              >
                {'Comentarios adicionales: '}
                <a className={classes.body2Light}>
                  {homeVisit.additionalComments}
                </a>
              </p>
              {(homeVisit.orderStatus === 2 ||
                homeVisit.orderStatus === 3) && (
                <Fragment>
                  <p
                    className={classnames(
                      classes.headline7,
                      classes.titleContainer
                    )}
                  >
                    {'Legalizado por:'}
                  </p>
                  <p
                    className={classnames(
                      classes.subtitle3,
                      classes.titleContainer
                    )}
                  >
                    {'Nombre: '}
                    <a className={classes.body2Light}>
                      {`${_get(homeVisit, 'user.firstName') || '--'} ${_get(
                        homeVisit,
                        'user.lastName',
                        ''
                      )}`}
                    </a>
                  </p>
                  <p
                    className={classnames(
                      classes.subtitle3,
                      classes.titleContainer
                    )}
                  >
                    {'Comentarios: '}
                    <a className={classes.body2Light}>
                      {homeVisit.observations}
                    </a>
                  </p>
                  <p
                    className={classnames(
                      classes.headline7,
                      classes.titleContainer
                    )}
                  >
                    {'Detalles:'}
                  </p>
                  <p
                    className={classnames(
                      classes.subtitle3,
                      classes.titleContainer
                    )}
                  >
                    {'Se hizo contacto con el cliente:'}{' '}
                    <a className={classes.body2Light}>
                      {this._formatEffectiveContact(
                        homeVisit.effectiveContact
                      )}
                    </a>
                  </p>
                  <p
                    className={classnames(
                      classes.subtitle3,
                      classes.titleContainer
                    )}
                  >
                    {'Nombre del contacto:'}{' '}
                    <a className={classes.body2Light}>
                      {homeVisit.contactName || '--'}
                    </a>
                  </p>
                  <p
                    className={classnames(
                      classes.subtitle3,
                      classes.titleContainer
                    )}
                  >
                    {'Nombre del artículo:'}{' '}
                    <a className={classes.body2Light}>
                      {homeVisit.articleName || '--'}
                    </a>
                  </p>
                  {
                    <p
                      className={classnames(
                        classes.subtitle3,
                        classes.titleContainer
                      )}
                    >
                      {'Número de venta asociado:'}{' '}
                      <a className={classes.body2Light}>
                        {homeVisit.saleNumber || '--'}
                      </a>
                    </p>
                  }
                </Fragment>
              )}
            </Grid>
            {homeVisit.orderStatus !== 2 &&
              homeVisit.orderStatus !== 3 &&
              canLegalizeHomeVisits && (
                <Grid item xs={12}>
                  <OutlinedSelectChippedInput
                    disabled={requestSend}
                    name={'cause'}
                    value={cause}
                    label={'Causa*'}
                    withChip={false}
                    options={this._filterCausals(causals)}
                    onChange={this._handleChange}
                    error={!!_get(api_errors, 'causal[0]')}
                    helperText={_get(api_errors, 'causal[0]')}
                    classes={{ root: classes.causalInput }}
                  />
                  <TextField
                    label={'Agregar Comentario*'}
                    name="comment"
                    margin="normal"
                    variant="outlined"
                    onChange={this._handleChange}
                    value={comment}
                    disabled={requestSend}
                    fullWidth
                  />
                  <FormControlLabel
                    label={'¿Se hizo contacto con el cliente?'}
                    control={
                      <Checkbox
                        checked={effectiveContact}
                        name={'effectiveContact'}
                        disabled={requestSend}
                        onChange={this._handleCheckboxChecked}
                      />
                    }
                  />
                  {effectiveContact && (
                    <div>
                      <TextField
                        label={'Nombre del usuario*'}
                        name={'contactName'}
                        margin={'normal'}
                        variant={'outlined'}
                        disabled={requestSend}
                        value={contactName}
                        onChange={this._handleChange}
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        error={!!_get(api_errors, 'contact_name[0]')}
                        helperText={_get(api_errors, 'contact_name[0]')}
                      />
                      <AutocompleteInput
                        id="articleName"
                        name="articleName"
                        label="Nombre del artículo*"
                        variant="outlined"
                        classes={{
                          root: `marginTop: ${classes.topInput}`
                        }}
                        disabled={requestSend}
                        error={
                          !!_get(api_errors, 'article_name[0]') ||
                          !!_get(errors, 'articleName')
                        }
                        helperText={
                          _get(api_errors, 'article_name[0]') ||
                          _get(errors, 'articleName')
                        }
                        value={articleName}
                        onChange={change => {
                          if (articleName && !change) {
                            this._handleChange({
                              target: { name: 'articleName', value: '' }
                            });
                          }
                          this.setState({ articleName: change });
                        }}
                        suggestions={_get(homeVisitArticles, 'supplies')}
                        getSuggestions={this._getArticleSuggestions}
                        onSuggestionSelected={article => {
                          this._handleChange({
                            target: {
                              name: 'articleName',
                              value: article.label
                            }
                          });
                          this.setState({ errors: null });
                        }}
                      />
                      <OutlinedTextField
                        label="Número de venta asociado"
                        name="saleNumber"
                        disabled={requestSend}
                        type={'number'}
                        error={!!_get(api_errors, 'sale_number[0]')}
                        helperText={_get(api_errors, 'sale_number[0]')}
                        value={saleNumber}
                        onChange={this._handleChange}
                        onKeyDown={evt =>
                          ['e', 'E', '+', '-'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />
                    </div>
                  )}
                </Grid>
              )}
          </Grid>
          <CustomDialog
              open={this.state.updateErrorMessage}
              onClose={() => this.setState({ updateErrorMessage: false })}
              onConfirm={() => this.setState({ updateErrorMessage: false })}
              title={`Mensaje importante`}
              buttonText={'CONTINUAR'}
            >
              {this.renderUpdateErrorMessage(homeVisit.orderStatus, isScheduleFinished)}
            </CustomDialog>
        </CustomDialog>
      </div>
    );
  }
}

const styles = theme => ({
  activeContainer: {
    padding: theme.spacing.unit * 2
  },
  dialogRoot: {
    width: 800
  },
  topInput: {
    marginTop: theme.spacing.unit
  },
  titleContainer: {
    padding: '6px 0 0 0 !important',
    margin: `0px ${theme.spacing.unit * 3}px !important`
  },
  nameLabel: {
    textTransform: 'capitalize'
  },
  causalInput: {
    marginTop: theme.spacing.unit * 3
  },
  effectivSaleInput: {
    marginTop: theme.spacing.unit
  }
});

HomeVisitRevision.propTypes = {
  open: PropTypes.bool
};

export default withStyles(combineStyles(styles, TypographyStyles), {
  withTheme: true
})(HomeVisitRevision);
