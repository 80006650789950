import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstContract: Yup.number()
    .required('El campo es requerido.')
    .min(1, 'Ingresa un contrato válido.'),
  secondContract: Yup.number()
    .required('El campo es requerido.')
    .min(1, 'Ingresa un contrato válido.')
    .notOneOf([Yup.ref('firstContract'), Yup.ref('thirdContract')], 'Ingresa contratos diferentes.'),
  thirdContract: Yup.number()
    .notOneOf([Yup.ref('firstContract'), Yup.ref('secondContract')], 'Ingresa contratos diferentes.')
});

export default validationSchema;
