import { stringify } from 'query-string';
import { history } from '../helpers/history';
import changeCaseKeys from 'change-case-keys';
import { Deserializer as JSONAPIDeserializer } from 'jsonapi-serializer';
import { camel } from 'case';
import { logout } from '../actions/authentication_actions';
import Store from '../store/Store';
import { validatePassword } from '../helpers/fetchFilters';
const deserializer = new JSONAPIDeserializer({ keyForAttribute: 'camelCase' });
const railsApiDomain = process.env.REACT_APP_API_DOMAIN;
const railsApiVersion = process.env.REACT_APP_API_VERSION;
const nodeApiDomain = process.env.REACT_APP_NODE_API;
export default class RequestService {
  constructor(server = 'rails') {
    this.server = server;
  }
  buildIndexUrl(base, params = null) {
    const baseURL =
      this.server === 'rails'
        ? `${railsApiDomain}/${railsApiVersion}/`
        : `${nodeApiDomain}/`;
    let paramsString = '';
    if (params) {
      const { ...filters } = params;
      const queryParams = this._buildQueryParams(filters);
      paramsString = `?${stringify(
        { ...params, ...queryParams },
        { encode: false }
      )}`;
    }
    return `${baseURL}${base}${paramsString}`;
  }
  get(base, params, adapter) {
    return this.fetch(
      base,
      params,
      {
        method: 'GET',
        headers: this.authHeader()
      },
      adapter
    );
  }
  post(
    base,
    body,
    params,
    adapter,
    options = {},
    includeContentTypeHeader = true,
    responseIsFile = false
  ) {
    return this.fetch(
      base,
      params,
      {
        method: 'POST',
        body: body,
        headers: this.authHeader(includeContentTypeHeader),
        ...options
      },
      adapter,
      responseIsFile
    );
  }
  delete(base, body, params, adapter) {
    return this.fetch(
      base,
      params,
      {
        method: 'DELETE',
        body: body,
        headers: this.authHeader()
      },
      adapter
    );
  }
  put(base, body, params, adapter) {
    return this.fetch(
      base,
      params,
      {
        method: 'PUT',
        body: body,
        headers: this.authHeader()
      },
      adapter
    );
  }
  patch(base, body, params, adapter) {
    return this.fetch(
      base,
      params,
      {
        method: 'PATCH',
        body: body,
        headers: this.authHeader()
      },
      adapter
    );
  }
  // FIXME JSONAPI Deserializer replaces values in the array with nulls
  decorateWithMissingRelationships = (deserialized, payload) => {
    if (payload.relationships) {
      for (const relation in payload.relationships) {
        const camelRelation = camel(relation);
        if (deserialized[relation] || !payload.relationships[relation].data) {
          continue;
        }
        if (Array.isArray(payload.relationships[relation].data)) {
          if (
            deserialized[camelRelation] &&
            deserialized[camelRelation].length > 0 &&
            deserialized[camelRelation][0] === null
          ) {
            deserialized[camelRelation] = payload.relationships[relation].data;
          }
          continue;
        }
        deserialized[relation] = payload.relationships[relation].data;
      }
    }
  };
  fetch(base, params, options, adapter, responseIsFile = false) {
    if (options && options.body) options.body = this._formatBody(options);
    const headers = this.authHeader();
    return fetch(this.buildIndexUrl(base, params), { headers, ...options })
      .then(this._checkStatus)
      .then(response => {
        if (response.status === 204) return response;
        if (responseIsFile) return response;
        return response.json().then(payload => {
          if (adapter || this.server == 'node') return payload;
          return deserializer.deserialize(payload).then(deserialized => {
            if (Array.isArray(deserialized)) {
              for (let idx = 0; idx < deserialized.length; idx += 1) {
                this.decorateWithMissingRelationships(
                  deserialized[idx],
                  payload.data[idx]
                );
              }
            } else {
              this.decorateWithMissingRelationships(deserialized, payload.data);
            }
            const isEmpty = JSON.stringify(deserialized).trim() == '{}';
            return {
              data: !isEmpty ? deserialized : payload.data,
              meta: payload.meta
            };
          });
        });
      })
      .catch(resp => {
        return Promise.reject(resp.response);
      });
  }
  _buildQueryParams(filters) {
    return Object.keys(filters).reduce((result, key) => {
      const value = filters[key];
      result[`q[${key}]`] = value;
      return result;
    }, {});
  }
  _formatBody(options) {
    if (options.body instanceof FormData) return options.body;
    return this.server == 'rails'
      ? JSON.stringify(changeCaseKeys(options.body, 'underscored'))
      : JSON.stringify(options.body);
  }
  _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      if (response.status === 401) {
        Store.dispatch(logout());
      }
      if (response.status === 403) {
        response
          .clone()
          .json()
          .then(payload => validatePassword(payload));
      }
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
  authHeader(addContentTypeHeader = true) {
    // return authorization header with jwt token
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user) {
      return addContentTypeHeader
        ? {
            Authorization: user.token,
            'Content-Type': 'application/json'
          }
        : { Authorization: user.token };
    } else {
      return {
        'Content-Type': 'application/json'
      };
    }
  }
}
