import { SET_SECURE_PRODUCT_MOVEMENTS, SET_PRODUCT_IDS } from '../constants/action_constants';

const initialState = {
  movements: [],
  meta: {}
};

export function secureProductMovements(state = initialState, action) {
  switch (action.type) {
    case SET_SECURE_PRODUCT_MOVEMENTS:
      return {
        ...state,
        movements: action.data,
        meta: action.meta
      };
    case SET_PRODUCT_IDS:
      return {
        ...state,
        product_data: action.data,
      };
    default:
      return state;
  }
}
