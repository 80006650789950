import React from 'react';
import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import LabeledCheckbox from './LabeledCheckbox';
import PropTypes from 'prop-types';
import { AutoSizer, List } from 'react-virtualized';

class MultiSelectOptions extends React.Component {
  render() {
    const { classes, handleChange, items, selectedIDS, selectAllOptionByGroup,allGroupsSelected, groupSelectedIDS} = this.props;
    const list =
      items &&
      items.map(item => {
        return item.isGroup ? (
            <div className={(selectAllOptionByGroup ? classes.groupContainerByGroup : classes.groupContainer)} key={item.id}>
              {selectAllOptionByGroup ? (
                <LabeledCheckbox
                  label={item.label}
                  handleChange={() => handleChange(item)}
                  key={item.id}
                  checked={true}
                  allSelector={allGroupsSelected}
                  selectedItem={item}
                  selectedIDS={groupSelectedIDS}
                  isHeader
                  isGrouped
                />
                //<Checkbox checked={(allGroupsSelected ? true : groupSelectedIDS.includes(item.id))} onChange={() => handleChange(item)} color={'primary'} />
              ):(
                item.label
              )}
            </div>
        ) : (
          <LabeledCheckbox
            label={item.label}
            handleChange={() => handleChange(item)}
            key={item.id}
            selectedItem={item}
            selectedIDS={selectedIDS}
            isGrouped
          />
        );
      });
    if (!list || list.length === 0) {
      return (
        <div className={classes.noItemsSelected}>
          No existen resultados con su criterio de búsqueda.
        </div>
      );
    }
    return (
      <AutoSizer>
        {({ width }) => (
          <List
            className={classes.ReactVirtualized_List}
            width={width}
            height={318}
            rowCount={items.length}
            rowHeight={40}
            rowRenderer={({
              key, // Unique key within array of rows
              index, // Index of row within collection
              style // Style object to be applied to row (to position it)
            }) => {
              return (
                <div key={key} style={style}>
                  {list[index]}
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
    );
  }
}

const styles = theme => ({
 subSection_selectableItems: {
    boxSizing: 'border-box',
    direction: 'ltr',
    height: '313px',
    position: 'relative',
    willChange: 'transform',
    overflow: 'auto',
    width: '100%',
    padding: 0
  },
  groupContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    margin: 0,
    color: '#000000',
    paddingLeft: '10px',
    fontFamily: theme.typography.fontFamily,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#f3f3f3'
    }
  },
  groupContainerByGroup: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    margin: 0,
    color: '#000000',
    fontFamily: theme.typography.fontFamily,
    transition: 'all 0.2s ease-in-out',
    backgroundColor: '#fcfcfc',
    '&:hover': {
      backgroundColor: '#f3f3f3'
    }
  },
  ReactVirtualized_List: {
    '&:focus': {
      outline: 'none'
    }
  },
  noItemsSelected: {
    fontSize: '14px',
    color: '#999999',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontFamily: theme.typography.fontFamily
  }
});

MultiSelectOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array,
  groupItems: PropTypes.array,
  handleChange: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(MultiSelectOptions);
