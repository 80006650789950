export const statusOptions = [
  { value: 'todos', title: 'Todos' },
  { value: 'registered', title: 'Registrado' },
  { value: 'reviewed', title: 'Revisado' },
  { value: 'approved', title: 'Aprobado' },
  { value: 'revoked', title: 'Rechazado' }
];

export const typeOptions = [
  { value: 'todos', title: 'Todos' },
  { value: 'union', title: 'Unión' },
  { value: 'transfer', title: 'Traslado' }
];
