export const columns = [
  {
    title: 'Id',
    name: 'id'
  },
  {
    title: 'NIT',
    name: 'nit'
  },
  {
    title: 'Nombre',
    name: 'companyName'
  },
  {
    title: 'Estado',
    name: 'state'
  },
  {
    title: 'Fecha de creación',
    name: 'createdAt'
  }
];

const capitalize = str => {
  if (!str) str = '';
  return str.trim().replace(/^\w/, char => char.toUpperCase());
}

export const mapData = data => (
  data.map(datum => {
    return {
      id: datum.id,
      nit: datum.nit,
      companyName: capitalize(datum.companyName),
      state: capitalize(datum.state),
      createdAt: datum.createdAt
    };
  })
);