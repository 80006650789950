import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, withStyles } from '@material-ui/core';
import CustomDialog from '../../shared/Modals/CustomDialog';
import Toggle from '../../shared/Buttons/Toggle';
import {
  corporationValidation,
  minValidation,
  maxValidation
} from './RangeValidations';
import AutoSuggestInput from '../../shared/AutoSuggestInput';

class RangeEditCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      corporation: '',
      corporation_id: null,
      min_value: '',
      max_value: '',
      enable: true
    };
  }

  componentDidMount() {
    if (this.props.corporationNames.length == 0) {
      this.props.fetchCorporationNames();
    }
    const {
      corporation,
      corporation_id,
      min_value,
      max_value,
      enable
    } = this.props.rangeDetail;
    this.setState({
      corporation: corporation || '',
      corporation_id: corporation_id,
      min_value: min_value || '',
      max_value: max_value || '',
      enable: enable == 'Inactivo' ? false : true,
      api_errors: null,
      success: false
    });
  }

  _handleSave = () => {
    const { isEditing, rangeDetail } = this.props;
    const { corporation_id, min_value, max_value, enable } = this.state;
    const params = {};
    params.promissory_range = { corporation_id, min_value, max_value, enable };
    if (isEditing) {
      // * UPDATE *
      rangeDetail &&
        this.props.updateRange(rangeDetail.id, params).then(r => {
          if (r.success) {
            if (this.props.reFetch) {
              this.props.reFetch();
            }
            this.setState({ success: true });
            this._handleClose();
          } else {
            this.setState({ api_errors: r.errors });
          }
        });
    } else {
      // * CREATE *
      this.props.createRange(params).then(r => {
        if (r.success) {
          if (this.props.reFetch) {
            this.props.reFetch();
          }
          this.setState({ success: true });
          this._handleClose();
        } else {
          this.setState({ api_errors: r.errors });
        }
      });
    }
  };

  _handleClose = () => {
    const { requestSend } = this.props;
    if (!requestSend) {
      this.setState({
        corporation: '',
        corporation_id: null,
        min_value: '',
        max_value: ''
      });
      this.props.onClose();
    }
  };

  _handleAutoSuggest = name => (event, { newValue }) => {
    let id = null;
    name === 'corporation' &&
      this.props.corporationNames.map(c => {
        if (c.name === newValue) {
          id = c.id;
        }
      });
    this.setState({
      [name]: newValue,
      [`${name}_id`]: id,
      api_errors: null
    });
  };

  _handleActive = a => {
    !this.props.requestSend && this.setState({ enable: a });
  };

  _handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value, api_errors: null });
  };

  _handleSwitchChange = e => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked, api_errors: null });
  };

  _validations = () => {
    const {
      corporation,
      corporation_id,
      min_value,
      max_value,
      enable,
      api_errors
    } = this.state;
    const { rangeDetail } = this.props;
    if (
      rangeDetail &&
      corporation == rangeDetail.corporation &&
      min_value == rangeDetail.min_value &&
      max_value == rangeDetail.max_value &&
      enable == (rangeDetail.enable == 'Inactivo' ? false : true)
    )
      return true;
    if (corporationValidation(api_errors, true)) {
      return true;
    }
    if (minValidation(min_value, api_errors, true)) return true;
    if (maxValidation(max_value, api_errors, true)) return true;
    if (!corporation || !min_value) return true;
    return false;
  };

  render() {
    const { requestSend, isEditing, corporationNames } = this.props;
    const { api_errors } = this.state;
    return (
      <div>
        <CustomDialog
          open={true}
          loading={requestSend}
          disabled={this._validations() || requestSend}
          onClose={this._handleClose}
          onConfirm={this._handleSave}
          title={
            isEditing ? 'Editar Rango de Pagarés' : 'Nuevo Rango de Pagarés'
          }
          buttonText={isEditing ? 'GUARDAR' : 'CREAR'}
          disableBackdropClick={true}
          headerSecondary={
            <Toggle
              name={'enable'}
              value={this.state.enable}
              onChange={this._handleActive}
            />
          }
        >
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <AutoSuggestInput
                name="corporation"
                label="Empresa*"
                margin="normal"
                variant="outlined"
                disabled={requestSend}
                value={this.state.corporation}
                onChange={this._handleAutoSuggest('corporation')}
                attr={'name'}
                suggestions={corporationNames}
                error={corporationValidation(api_errors, true)}
                helperText={corporationValidation(api_errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                name="min_value"
                label="# Mínimo"
                type="number"
                margin="normal"
                variant="outlined"
                onChange={this._handleChange}
                value={this.state.min_value}
                disabled={requestSend}
                fullWidth
                error={minValidation(this.state.min_value, api_errors, true)}
                helperText={minValidation(
                  this.state.min_value,
                  api_errors,
                  false
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                name="max_value"
                label="# Máximo"
                type="number"
                margin="normal"
                variant="outlined"
                onChange={this._handleChange}
                value={this.state.max_value}
                disabled={requestSend}
                fullWidth
                error={maxValidation(this.state.max_value, api_errors, true)}
                helperText={maxValidation(
                  this.state.max_value,
                  api_errors,
                  false
                )}
              />
            </Grid>
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = (/* theme */) => ({});

RangeEditCreate.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(RangeEditCreate);
