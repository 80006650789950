// import { green, amber } from '@material-ui/core/colors';

const styles = theme => ({
  base: {
    borderWidth: 1,
    minWidth: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: 0,
    paddingBottom: 0
  },
  info: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    border: `1px solid ${theme.palette.warning.main}`
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`
  },
  label: {
    border: `1px solid ${theme.palette.success.main}`,
    borderWidth: 1,
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: 0,
    paddingBottom: 0
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
});

export default styles;
