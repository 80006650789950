import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import _find from 'lodash/find';
import _get from 'lodash/get';
import {
  filterListFuzzyly,
  removeAccents
} from '../../helpers/utilityFunctions';

function renderInputComponent(inputProps) {
  const { classes = {}, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
          root: classes.outlinedInput,
          focused: classes.focused,
          notchedOutline: classes.notchedOutline
        }
      }}
      {...other}
      classes={{
        root: classes.root
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focused
        }
      }}
    />
  );
}

class AutoSuggestInput extends React.Component {
  state = {
    suggestions: [],
    value: ''
  };

  componentDidMount() {
    if (this.props.initial_value) {
      this.setState({ value: this.props.initial_value });
    }
    if (this.props.initial_value_article) {
      const { suggestions_alt } = this.props;
      const element = suggestions_alt.find(e => {
        return `${e.id}` === `${this.props.initial_value_article}`;
      });
      this.setState({ value: `${element.id} - ${_get(element, 'name', '')}` });
    }
  }

  listContains = (list, attr, value) => {
    return list
      .filter(item =>
        removeAccents(item[attr])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
      .slice(0, 5);
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const { suggestions, attr, search } = this.props;
    let newSuggestions = [];
    if (search === 'contains') {
      newSuggestions = this.listContains(suggestions, attr, value);
    } else {
      newSuggestions = filterListFuzzyly(value, suggestions, attr);
    }
    this.setState({
      suggestions: newSuggestions
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const { attr } = this.props;
    const matches = match(suggestion[attr], query);
    const parts = parse(suggestion[attr], matches);
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            );
          })}
        </div>
      </MenuItem>
    );
  };

  _handleChange = (evt, { newValue }) => {
    const { suggestions, attr } = this.props;
    if (newValue === '') {
      this.props.onChange({ target: { name: this.props.id, value: '' } });
    } else {
      let suggestionID = 0;
      suggestions.map(item => {
        if (
          removeAccents(item[attr]).toLowerCase() ===
          removeAccents(newValue).toLowerCase()
        ) {
          suggestionID = item.id;
        }
      });
      this.props.onChange({
        target: { name: this.props.id, value: Number.parseInt(suggestionID) },
        newValue
      });
    }
    this.setState({ value: newValue });
  };

  getSuggestionValue = suggestion => {
    return suggestion[this.props.attr];
  };

  _handleSuggestionSelected = (evt, { suggestion }) => {
    if (!!this.props.handleSuggestionSelected) this.props.handleSuggestionSelected(evt, { suggestion });
    this.props.onChange({
      target: { name: this.props.id, value: Number.parseInt(suggestion.id) }
    });
  };

  render() {
    const { classes, field } = this.props;
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
      onSuggestionHighlighted: this.suggestionHighlighted
    };
    let formikProps = {};
    if (field) {
      formikProps = {
        value: this.state.value,
        onChange: this._handleChange
      };
      autosuggestProps['onSuggestionSelected'] = this._handleSuggestionSelected;
    }
    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            ...this.props,
            ...formikProps
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}
const styles = theme => ({
  root: {},
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 3,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  outlinedInput: {
    // '&$focused $notchedOutline': {
    //   borderColor: 'rgba(0, 0, 0, 0.87)',
    //   borderWidth: 1
    // },
    height: 55
  },
  label: {
    color: 'rgba(12,31,44,0.6)'
    // '&$focused': {
    //   color: 'rgba(0, 0, 0, 0.87)'
    // }
  },
  focused: {},
  notchedOutline: {}
});

AutoSuggestInput.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AutoSuggestInput);
