import React, { Component } from 'react';
import MUITableCell from '@material-ui/core/TableCell';
import MUITableRow from '@material-ui/core/TableRow';
import MUICheckbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import _remove from 'lodash/remove';
import { columnChooserItems } from './helpers';
import { history } from '../../../helpers/history';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

const StyledChip = withStyles({
  label: {
    width: '100% !important'
  }
})(Chip);

export class TableRow extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    withSelection: PropTypes.bool,
    onClickOnRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    cellComponent: PropTypes.func,
    rowsSelected: PropTypes.array,
    hiddenColumnNames: PropTypes.array
  };

  _handleRowClick = () => {
    const { onClickOnRow = null } = this.props;

    if (onClickOnRow) {
      onClickOnRow(this.props.row);
    }
  };

  _handleLinkClick = linkTo => {
    history.push(linkTo);
  };

  _handleSelectRow = () => {
    const { onSelectRow = null, rowsSelected, row } = this.props;
    const currentRowsSelected = [...rowsSelected];
    if (!currentRowsSelected.includes(row.id)) {
      currentRowsSelected.push(row.id);
    } else {
      _remove(currentRowsSelected, item => String(item) === String(row.id));
    }

    if (onSelectRow) {
      onSelectRow(currentRowsSelected);
    }
  };

  _validateNotEmpty = (column, row, cellComponent) => {
    return cellComponent ? !column.hidden && cellComponent({column: column.column, row}) != null : !column.hidden
  }

  _contentRender = (data) => {
    if( Array.isArray(data) ){
      if(this.props.showArrayValuesAsChips){
        return (
          <table>
            <tbody>
              {data.map( (value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <StyledChip
                        label={value}
                        color='secondary'
                        variant='outlined'
                        size='small'
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      } else {
        return (
          <ul>
            {data.map( (value, index) => {
              return <li key={index}>{value}</li>
            })}
          </ul>
        );
      }
    }else{
      return data;
    }
  }

  render() {
    const {
      classes,
      columns,
      row,
      withSelection = false,
      cellComponent = null,
      rowsSelected = [],
      hiddenColumnNames = []
    } = this.props;
    return (
      <MUITableRow
        key={`${row.id}`}
        classes={{
          hover: row.disableRowHover
            ? classes.disableRowHover
            : classes.tableRowHover,
          root: classes.tableRowRoot
        }}
        hover={true}
      >
        {withSelection && (
          <MUITableCell
            padding="checkbox"
            style={{ width: 8 }}
            classes={{ body: classes.tableCellCheckbox }}
          >
            <MUICheckbox
              checked={rowsSelected.includes(row.id)}
              onChange={this._handleSelectRow}
            />
          </MUITableCell>
        )}
        {columnChooserItems(columns, hiddenColumnNames)
          .filter(column => this._validateNotEmpty(column, row, cellComponent) )
          .map(({ column }, columnIndex) => {
            if (cellComponent) {
              return cellComponent({ column, row });
            }
            return (
              <MUITableCell
                key={`${row.id}.${columnIndex}`}
                classes={{
                  root: classes.tableCellRoot,
                  body: classnames(classes.tableCellBody, {
                    [classes.tableCellLink]: column.componentType === 'Link'
                  })
                }}
                onClick={
                  column.componentType === 'Link'
                    ? () => this._handleLinkClick(row[`${column.name}LinkTo`])
                    : this._handleRowClick
                }
                data-label={column.title}
              >
                {this._contentRender(row[column.name])}
              </MUITableCell>
            );
          })}
      </MUITableRow>
    );
  }
}

export default TableRow;
