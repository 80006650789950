import moment from 'moment-timezone';
import _compact from 'lodash/compact';
import { PROMISSORY_STATUSES } from '../../../../constants/enums';

export const promissoriesColumns = [
  {
    title: '# Pagaré',
    name: 'id'
  },
  {
    title: 'Contrato',
    name: 'contractId'
  },
  {
    title: 'Fecha',
    name: 'registeredDate'
  },
  {
    title: 'Deudor',
    name: 'debtor'
  },
  {
    title: 'Codeudor',
    name: 'cosigner'
  },
  {
    title: 'Estado',
    name: 'state'
  }
];

export const promissoriesMapData = data =>
  data.map(datum => {
    return {
      ...datum,
      registeredDate:
        moment(datum.registeredAt)
          .locale('es')
          .format('DD MMM YYYY') || '--',
      debtor: datum.debtorName + ' ' + datum.debtorLastName,
      cosigner: datum.cosignerName + ' ' + datum.cosignerLastName,
      state: PROMISSORY_STATUSES[datum.status]
    };
  });
