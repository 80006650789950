import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';

import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import Filters from './Filters';
import FormModal from './modals/FormModal';

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class GlobalSettingList extends Component {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    isOpenedFormModal: false
  };

  componentDidMount() {
    this._fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.globalSettingSaved !== this.props.globalSettingSaved &&
      this.props.globalSettingSaved
    ) {
      this.setState({ isOpenedFormModal: false });
    }
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchGlobalSettings({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _handleRowClick = (globalSetting, canUpdateGlobalSettings) => {
    if (canUpdateGlobalSettings) {
      this.setState({
        globalSetting: {
          ...globalSetting,
          isNew: false
        },
        isOpenedFormModal: true
      });
    }
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleCloseFormModal = () => this.setState({ isOpenedFormModal: false });

  _handleSave = globalSetting => {
    const query = parseQuerystring(this.props) || {};
    this.props.updateGlobalSetting(
      { global_setting: { ...globalSetting } },
      {
        ...query,
        page: query.page || 1,
        per_page: this.state.rowsPerPage
      }
    );
  };

  render() {
    const {
      classes,
      canUpdateGlobalSettings,
      globalSettings = [],
      metadata = {},
      requestGlobalSettingsSend = true
    } = this.props;

    const { page } = parseQuerystring(this.props) || {};

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Parámetros Globales'}
          </h1>
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <Filters
              onChangeFilter={this._fetchItems}
              settingNames={this.props.settingNames}
              descriptions={this.props.descriptions}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Parámetros Globales'}
            columns={columns}
            data={mapData(globalSettings)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props =>
              this._handleRowClick(props, canUpdateGlobalSettings)
            }
            totalCount={(metadata && metadata.total_count) || 0}
            loading={requestGlobalSettingsSend}
            showArrayValuesAsChips
          />
        </div>
        {this.state.isOpenedFormModal && (
          <FormModal
            onClose={this._handleCloseFormModal}
            onSave={this._handleSave}
            errors={this.props.requestErrorsOnSaveGlobalSetting}
            isSubmitting={this.props.requestSaveGlobalSettingSend}
            item={this.state.globalSetting}
            itemList={this.props.companyList}
          />
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

GlobalSettingList.propTypes = {
  metadata: PropTypes.object,
  canUpdateGlobalSettings: PropTypes.bool,
  fetchGlobalSettings: PropTypes.func.isRequired,
  globalSettings: PropTypes.array.isRequired
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(GlobalSettingList);
