import React from 'react';
import { withStyles } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AutoSizer, List } from 'react-virtualized';

class MultiSelectItems extends React.Component {
  _handleChangeItem = item => {
    const { handleChange, loadingOptions } = this.props;
    !loadingOptions && handleChange(item);
  };

  render() {
    const { classes, selectedItems, namePlural } = this.props;
    const list =
      selectedItems &&
      selectedItems.length > 0 &&
      selectedItems.map(item => {
        return item.isGroup ? (
          <div
            className={classNames(
              classes.selectedAndClean,
              classes.groupHeader
            )}
            key={item.id}
          >
            {item.label}
          </div>
        ) : (
          <div
            className={classNames(
              classes.selectedAndClean,
              classes.selectedList
            )}
            key={item.id}
            onClick={() => this._handleChangeItem(item)}
          >
            <div className={classes.selectedCounter}>{item.label}</div>
            <div
              className={classes.deleteSelected}
              onClick={() => this._handleChangeItem(item)}
            >
              <CloseRounded />
            </div>
          </div>
        );
      });
    return (
      <AutoSizer>
        {({ width }) => (
          <List
            className={classes.ReactVirtualized_List}
            width={width}
            height={357}
            rowCount={selectedItems.length}
            rowHeight={40}
            rowRenderer={({
              key, // Unique key within array of rows
              index, // Index of row within collection
              style // Style object to be applied to row (to position it)
            }) => {
              return (
                <div key={key} style={style}>
                  {list[index]}
                </div>
              );
            }}
            noRowsRenderer={() => {
              return (
                <div className={classes.noItemsSelected}>
                  {`Sin ${namePlural}...`}
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
    );
  }
}

const styles = theme => ({
  subSection_selectedItems: {
    boxSizing: 'border-box',
    direction: 'ltr',
    height: '357px',
    position: 'relative',
    willChange: 'transform',
    overflow: 'auto',
    width: '100%',
    padding: 0
  },
  selectedAndClean: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  selectedList: {
    height: '40px',
    padding: '0 12px',
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f3f3f3'
    }
  },
  groupHeader: {
    height: '40px',
    padding: '0 12px',
    color: '#000000',
    fontFamily: theme.typography.fontFamily
  },
  selectedCounter: {
    display: 'inline-block',
    fontFamily: theme.typography.fontFamily
  },
  deleteSelected: {
    display: 'inline-block',
    color: '#3f51b5',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily
  },
  noItemsSelected: {
    fontSize: '14px',
    color: '#999999',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontFamily: theme.typography.fontFamily
  },
  ReactVirtualized_List: {
    '&:focus': {
      outline: 'none'
    }
  }
});

MultiSelectItems.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedItems: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  loadingOptions: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(MultiSelectItems);
