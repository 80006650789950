import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';

import LoadingButton from '../../../shared/Buttons/LoadingButton';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import AutoSuggestInput from '../../../shared/AutoSuggestInput';
import LoadingIndicator from '../../../shared/LoadingIndicator';
import Modal from '../../../shared/Modals/Modal';
import ChippedAutocompleteInput from '../../../shared/Inputs/ChippedAutocomplete';
import GridItem from '../../../shared/GridItem';

import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';

import { validationSchema } from './EditPOSInfoModal.validation';

class EditPOSInfoModal extends React.Component {
  state = {
    sale_channel: ''
  };

  onSubmit = values => {
    this.props.update(values);
  };

  booleanOptions = () => {
    const types = [
      {
        key: true,
        value: true,
        label: 'Sí'
      },
      {
        key: false,
        value: false,
        label: 'No'
      }
    ];
    return types;
  };

  _getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  render() {
    const {
      loading,
      classes,
      locationMappedNames,
      cityName,
      onClose,
      posInfo,
      canUpdatePOS,
      canUpdatePOSLocationInfo
    } = this.props;
    const initialValues = {
      city: posInfo.attributes.city || null,
      address: posInfo.attributes.address || '',
      hasSecurityQuestions: posInfo.attributes.hasSecurityQuestions,
      schedulable: posInfo.attributes.schedulable,
      canUseCrosscore: posInfo.attributes.canUseCrosscore,
      saleChannels: posInfo.attributes.saleChannels.map(sc => ({ label: sc.name, value: sc.id })),
      hasFingerprintBiometric: posInfo.attributes.hasFingerprintBiometric,
      hasFacialBiometric: posInfo.attributes.hasFacialBiometric,
      canUpdatePOSLocationInfo
    };
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.onSubmit}
        render={({ values, handleChange, errors, touched, handleSubmit, dirty }) => (
          <Form>
            <Modal
              open={true}
              onClose={onClose}
              classes={{
                footer: classes.modalFooter,
                root: classes.modalRoot
              }}
              body={
                <Fragment>
                  <Typography className={classes.cardTitle}>
                    INFORMACIÓN DE PUNTO DE VENTA
                  </Typography>
                  {!locationMappedNames ? (
                    <LoadingIndicator />
                  ) : (
                    <Grid container spacing={24}>
                      <Grid item xs={12} md={4}>
                        <Field
                          id="city"
                          name="city"
                          label="Ciudad"
                          margin="normal"
                          variant="outlined"
                          attr="description"
                          value={values.city}
                          initial_value={cityName}
                          error={touched.city && !!errors.city}
                          helperText={touched.city && errors.city}
                          onChange={handleChange}
                          suggestions={locationMappedNames}
                          component={AutoSuggestInput}
                          disabled={loading || !canUpdatePOSLocationInfo}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <OutlinedTextField
                          name="address"
                          label="Dirección"
                          value={values.address}
                          onChange={handleChange}
                          error={touched.address && !!errors.address}
                          helperText={touched.address && errors.address}
                          disabled={loading || !canUpdatePOSLocationInfo}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <SimpleSelect
                          name='hasSecurityQuestions'
                          label="Preguntas reto"
                          value={values.hasSecurityQuestions}
                          onChange={handleChange}
                          options={this.booleanOptions()}
                          disabled={loading || !canUpdatePOS}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <SimpleSelect
                          name='schedulable'
                          label="Agendamiento"
                          value={values.schedulable}
                          onChange={handleChange}
                          options={this.booleanOptions()}
                          disabled={loading || !canUpdatePOS}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <SimpleSelect
                          name='canUseCrosscore'
                          label="Crosscore"
                          value={values.canUseCrosscore}
                          onChange={handleChange}
                          options={this.booleanOptions()}
                          disabled={loading || !canUpdatePOS}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <SimpleSelect
                          name='hasFingerprintBiometric'
                          label="Biometría dactilar"
                          value={values.hasFingerprintBiometric}
                          onChange={handleChange}
                          options={this.booleanOptions()}
                          disabled={loading || !canUpdatePOS}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <SimpleSelect
                          name='hasFacialBiometric'
                          label="Biometría Facial"
                          value={values.hasFacialBiometric}
                          onChange={handleChange}
                          options={this.booleanOptions()}
                          disabled={loading || !canUpdatePOS}
                        />
                      </Grid>
                      <GridItem xs={12} sm={12}>
                        <Field
                          name="saleChannels"
                          render={({ field, form }) => (
                            <ChippedAutocompleteInput
                              getSuggestions={value =>
                                this._getSuggestions('saleChannels', value)
                              }
                              value={this.state.sale_channel}
                              values={values.saleChannels}
                              onChange={evt => {
                                const {
                                  target: { value }
                                } = evt;
                                this.setState({ sale_channel: value });
                                handleChange(evt);
                              }}
                              field={field}
                              form={form}
                              readOnly={!canUpdatePOS}
                              label={'Canales de venta'}
                            />
                          )}
                        />
                      </GridItem>
                    </Grid>
                  )}
                </Fragment>

              }
              footer={
                <Fragment>
                  <div className={classes.spacer} />
                  <LoadingButton
                    size="large"
                    color="secondary"
                    loading={loading}
                    onClick={handleSubmit}
                    disabled={loading || !locationMappedNames || !dirty}
                  >
                    GUARDAR
                  </LoadingButton>
                </Fragment>
              }
            />
          </Form>
        )}
      />
    );
  }
}
const styles = theme => ({
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA',
    paddingBottom: theme.spacing.unit * 3
  },
  card: {
    marginBottom: theme.spacing.unit * 3
  }
});

export default withStyles(styles)(EditPOSInfoModal);
