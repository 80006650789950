import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import GridItem from '../../shared/GridItem';
import DialogForm from '../../shared/Modals/DialogForm';
import Button from '@material-ui/core/Button';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import ChippedAutocompleteInput from '../../shared/Inputs/ChippedAutocomplete';
import { Formik, Field } from 'formik';
import classnames from 'classnames';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import ButtonStyles from '../../../styles/button';
import SpacingStyles from '../../../styles/helpers/spacing';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ModalStyles';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import validationSchema from './FormValidationSchema';

class FormModal extends Component {
  getSuggestions = (array, value) => {
    const items = array.map(item => ({
      label: item.name || item.description || (item.attributes && item.attributes.name),
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return suggestions;
  };

  handleSave = params => {
    if (this.configIsNew()) {
      this.props.createConfig({
        fields: params.values,
        rowsPerPage: params.rowsPerPage
      });
    } else {
      this.props.updateConfig({
        fields: params.values,
        rowsPerPage: params.rowsPerPage,
        page: params.currentPage
      });
    }
  };

  configIsNew = () => {
    const { config } = this.props;
    return config.pointOfSaleId === undefined;
  };

  handlePointOfSales = async (event, handleChange, roles) => {
    const { target: { value, type } } = event;
    const roleSelected = value[value.length - 1];

    if (type == 'delete') {
      roles = value ? value : null;
    } else {
      if (!roles.some(role => role.value === roleSelected.value)) {
        roles.push(roleSelected);
      }
    }

    event.target = { value: roles, name: 'pointOfSales' };
    handleChange(event);
  };

  render() {
    const {
      onClose,
      classes,
      submitLoading,
      categories,
      geographicLocations,
      pointOfSales,
      rowsPerPage,
      currentPage,
      config
    } = this.props;
    return (
      <div>
        <Formik
          initialValues={{ ...config }}
          validationSchema={validationSchema}
          onSubmit={values => this.handleSave({ values, rowsPerPage, currentPage })}
          onReset={(values, actions) => {
            actions.resetForm();
          }}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
            handleReset
          }) => {
            return (
              <DialogForm
                open={true}
                onClose={handleReset}
                title={
                  <div className={classes.titleWrapper}>
                    <p className={classnames(classes.headline6, classes.title)}>
                      {this.configIsNew()
                        ? 'Nueva Configuración'
                        : 'Modificar Configuración'}
                    </p>
                  </div>
                }
                footer={
                  <Fragment>
                    <div className={classes.spacer} />
                    <Button
                      onClick={() => {
                        handleReset();
                        onClose();
                      }}
                      className={classes.button}
                      color={'secondary'}
                      classes={{ label: classes.buttonLabel }}
                    >
                      {'Cancelar'}
                    </Button>
                    <ProgressButton
                      onClick={handleSubmit}
                      className={classes.button}
                      classes={{
                        disabled: classes.buttonDisabled,
                        label: classes.buttonLabel
                      }}
                      color={'secondary'}
                      isSubmitting={submitLoading}
                    >
                      {'Guardar'}
                    </ProgressButton>
                  </Fragment>
                }
              >
                <Fragment>

                  <Grid container spacing={16}>
                    {this.configIsNew()
                      ? (
                        <GridItem xs={12}>
                          <Field
                            name="pointOfSales"
                            onChange={handleChange}
                            render={({ field, form }) => {
                              return (
                                <ChippedAutocompleteInput
                                  label="Puntos de venta"
                                  name="pointOfSales"
                                  getSuggestions={value =>
                                    this.getSuggestions(pointOfSales, value)
                                  }
                                  values={values.pointOfSales || []}
                                  onChange={event => {
                                    this.handlePointOfSales(
                                      event,
                                      handleChange,
                                      values.pointOfSales
                                    );
                                  }}
                                  field={field}
                                  form={form}
                                  margin="normal"
                                />
                              );
                            }}
                          />
                        </GridItem>
                      ) : (
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="pointOfSale"
                            onChange={handleChange}
                            render={({ field, form }) => {
                              return (
                                <AutocompleteInput
                                  id="pointOfSale"
                                  name="pointOfSale"
                                  label="Punto de venta"
                                  margin="normal"
                                  variant="outlined"
                                  error={touched.pointOfSaleId && !!errors.pointOfSaleId}
                                  helperText={touched.pointOfSaleId && errors.pointOfSaleId}
                                  value={values.pointOfSaleName}
                                  onChange={change => {
                                    setFieldValue('pointOfSaleName', change);
                                  }}
                                  suggestions={pointOfSales}
                                  getSuggestions={value =>
                                    this.getSuggestions(pointOfSales, value)
                                  }
                                  onSuggestionSelected={pointOfSale => {
                                    setFieldValue('pointOfSaleName', pointOfSale.label);
                                    setFieldValue('pointOfSaleId', pointOfSale.value);
                                  }}
                                  field={field}
                                  form={form}
                                  classes={{
                                    root: classes.autocomplete
                                  }}
                                />
                              );
                            }}
                          />
                        </GridItem>
                      )
                    }
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="category"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <AutocompleteInput
                              id="category"
                              name="category"
                              label="Categoría"
                              margin="normal"
                              variant="outlined"
                              error={touched.categoryId && !!errors.categoryId}
                              helperText={touched.categoryId && errors.categoryId}
                              value={values.categoryName}
                              onChange={change => {
                                setFieldValue('categoryName', change);
                              }}
                              suggestions={categories}
                              getSuggestions={value =>
                                this.getSuggestions(categories, value)
                              }
                              onSuggestionSelected={category => {
                                setFieldValue('categoryName', category.label);
                                setFieldValue('categoryId', category.value);
                              }}
                              field={field}
                              form={form}
                              classes={{
                                root: classes.autocomplete
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="geographic_location"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <AutocompleteInput
                              id="geographic_location"
                              name="geographic_location"
                              label="Zona Geográfica"
                              margin="normal"
                              variant="outlined"
                              error={touched.geographicLocationId && !!errors.geographicLocationId}
                              helperText={touched.geographicLocationId && errors.geographicLocationId}
                              value={values.geographicLocationName}
                              onChange={change => {
                                setFieldValue('geographicLocationName', change);
                              }}
                              suggestions={geographicLocations}
                              getSuggestions={value =>
                                this.getSuggestions(geographicLocations, value)
                              }
                              onSuggestionSelected={geographicLocation => {
                                setFieldValue('geographicLocationName', geographicLocation.label);
                                setFieldValue('geographicLocationId', geographicLocation.value);
                              }}
                              field={field}
                              form={form}
                              classes={{
                                root: classes.autocomplete
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                  </Grid>
                </Fragment>
              </DialogForm>
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(FormModal);