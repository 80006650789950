const styles = theme => ({
  modalRootBase: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: 0
  },
  modalRoot: {},
  baseRoot: {
    borderRadius: '2px',
    boxShadow:
      '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)',
    backgroundColor: '#FFF',
    outline: 'none'
  },
  defaultWidth: {
    width: 600,
    maxHeight: '90%',
    overflowY: 'auto',
  },
  root: {},
  baseBody: {
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px 0 ${theme
      .spacing.unit * 3}px`
  },
  body: {},
  baseFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `${theme.spacing.unit}px`,
    paddingTop: 0,
    marginTop: theme.spacing.unit * 6.25
  },
  footer: {}
});

export default styles;
