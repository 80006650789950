import {
  SET_CURRENT_STEP,
  SET_STEPS,
  SET_OPTIONAL_STEPS,
  SET_FAILED_STEPS,
  STEPPER_NEXT,
  STEPPER_PREVIOUS,
  STEPPER_SKIP,
  RESET_STEPPER,
  SET_NEXT_STEP,
  STEPPER_PERSIST,
  RESET_NEXT_STEPS,
  SET_AUTO_SKIP_STEP,
  SET_PROCESS,
  UPDATE_STEPPER_ROUTES,
  SET_STEP_TO_DESTINATION,
  SET_ORIGINAL_PROCESS
} from '../constants/action_constants';

const initialState = {
  steps: [],
  routes: [],
  process: '',
  originalProcess: '',
  optionalSteps: [],
  skippedSteps: [],
  failedSteps: [],
  activeStep: 0,
  nextStep: 0,
  portalNext: 0,
  promissoryNext: 0,
  teleshoppingNext: 0,
  remoteTeleshoppingNext: 0,
  insuranceNext: 0
};

export function stepper(state = initialState, action) {
  let skippedSteps, failedSteps;
  const nextStepTitle = action.nextStepTitle
    ? `${action.nextStepTitle}Next`
    : 'nextStep';
  const nextStep =
    action.nextStep >= 0 ? action.nextStep : state.activeStep + 1;
  const process = action.process || process;
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        activeStep: action.step,
        skippedSteps: state.skippedSteps.filter(
          step => step !== state.routes[action.step]
        )
      };
    case SET_NEXT_STEP:
      return {
        ...state,
        process: process,
        [nextStepTitle]: nextStep
      };
    case SET_AUTO_SKIP_STEP:
      return {
        ...state,
        skippedSteps: [...state.skippedSteps, action.route]
      };
    case SET_STEPS:
      return {
        ...state,
        steps: action.steps,
        routes: action.routes,
        process: process,
        [nextStepTitle]: nextStep
      };
    case SET_OPTIONAL_STEPS:
      return {
        ...state,
        optionalSteps: action.data
      };
    case SET_FAILED_STEPS:
      failedSteps = [...state.failedSteps, state.activeStep];
      return {
        ...state,
        failedSteps
      };
    case STEPPER_NEXT:
      skippedSteps = state.skippedSteps.filter(
        step => step !== state.activeStep
      );
      failedSteps = state.failedSteps.filter(step => step !== state.activeStep);
      return {
        ...state,
        activeStep: state.activeStep + 1,
        skippedSteps,
        failedSteps
      };
    case STEPPER_PREVIOUS:
      return {
        ...state,
        activeStep: state.activeStep - 1
      };
    case STEPPER_SKIP:
      skippedSteps = [...state.skippedSteps, state.activeStep];
      return {
        ...state,
        skippedSteps,
        activeStep: state.activeStep + 1
      };
    case STEPPER_PERSIST:
      return action.data;
    case RESET_STEPPER:
      return {
        ...initialState
      };
    case RESET_NEXT_STEPS:
      return {
        ...state,
        process: process,
        nextStep: 0,
        portalNext: 0,
        promissoryNext: 0,
        teleshoppingNext: 0,
        remoteTeleshoppingNext: 0
      };
    case UPDATE_STEPPER_ROUTES:
      return {
        ...state,
        process: process,
        steps: action.steps,
        routes: action.routes,
        optionalSteps: action.optionalSteps
      };
    case SET_PROCESS:
      return {
        ...state,
        process: process
      };
    case SET_ORIGINAL_PROCESS:
      return {
        ...state,
        originalProcess: process
      };
    default:
      return state;
  }
}
