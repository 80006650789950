import HomeVisitList from './HomeVisitList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchHomeVisits,
  homeVisitRevision,
  fetchIndexExcelReportFile,
  getHomeVisitArticles
} from '../../../actions/home_visit_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';
import { fetchPointsOfSaleHomeVisits } from '../../../actions/points_of_sale_actions';
import { openRegisterScheduleModal, openUpdateScheduleModal } from '../../../actions/register_schedule_actions';
import _get from 'lodash/get';

function mapStateToProps(state) {
  const orgData = _get(state, 'globalSetting.orgData');
  return {
    currentUser: state.authentication.currentUser,
    canUpdateHomeVisits: state.authentication.currentUser.canUpdateHomeVisits,
    canShowHomeVisit: state.authentication.currentUser.canShowHomeVisit,
    causals: state.globalSetting.orgData.causals,
    homeVisits: state.homeVisits.homeVisits,
    meta: state.homeVisits.meta,
    requestSend: state.requestState.requestHomeVisitsSend,
    causalsLoading: state.requestState.requestOrgDataSend,
    homeVisitUpdated: state.homeVisits.saved,
    updateHomeVisitSend: state.requestState.requestHomeVisitRevisionSend,
    homeVisitRevisionErrors:
      state.requestState.requestErrorsOnHomeVisitRevision,
    pointsOfSale: state.pointOfSale.pointsOfSaleNames,
    canExportSchedulesToExcel:
      state.authentication.currentUser.canExportSchedulesToExcel,
    fetchingExcelReportFile:
      state.requestState.requestHomeVisitsIndexExcelReportFileSend,
    saleChannels: state.globalSetting.orgData.sale_channels,
    homeVisitArticles: state.homeVisits.homeVisitArticles,
    isProcessScheduleInOsfActive: _get(orgData, 'is_process_schedule_in_osf_active'),
    canLegalizeHomeVisits: state.authentication.currentUser.canLegalizeHomeVisits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchHomeVisits: bindActionCreators(fetchHomeVisits, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    homeVisitRevision: bindActionCreators(homeVisitRevision, dispatch),
    fetchPointsOfSaleHomeVisits: bindActionCreators(
      fetchPointsOfSaleHomeVisits,
      dispatch
    ),
    fetchIndexExcelReportFile: bindActionCreators(
      fetchIndexExcelReportFile,
      dispatch
    ),
    getHomeVisitArticles: bindActionCreators(getHomeVisitArticles, dispatch),
    openUpdateScheduleModal: bindActionCreators(
      openUpdateScheduleModal,
      dispatch
    ),
    openRegisterScheduleModal: bindActionCreators(
      openRegisterScheduleModal,
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HomeVisitList);
