import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { SUPPLIER_PROPOSAL_DOCUMENT_TYPES } from '../../../constants/enums'
import FileUploader from './FileUploader';

const ConfigurationTemplatesStep = props => {
  const {
    classes,
    values,
    setFieldValue,
    getDocByDocType,
    allowFileReplace,
    handleFileChange,
    handleFileError,
    onViewOtherComments,
    onAddComment,
    showCommentSection,
    handleFileAcceptanceChange,
    allowAddComment,
    fileMaxSize,
    fileSizeUnit,
    extensions,
    newAlert,
    updateDocument,
    loadingDocumentIds,
    fetchFileBase64,
    canAcceptOrRejectFile,
  } = props;
  const configurationTemplatesDoc = getDocByDocType(
    SUPPLIER_PROPOSAL_DOCUMENT_TYPES.ConfigurationTemplates,
    values.documents
  );
  return (
    <Grid container item justify='center'>
      <Grid item xs={12}>
        <Typography variant='h3'>Plantilla de creación de aliados, puntos de venta y cuentas de acceso</Typography>
      </Grid>
      <Grid item xs={8}>
        <FileUploader
          allowFileReplace={allowFileReplace(configurationTemplatesDoc)}
          fileName={configurationTemplatesDoc.originalFileName}
          fetchFileBase64={fetchFileBase64({document: configurationTemplatesDoc})}
          label={configurationTemplatesDoc.name}
          description={configurationTemplatesDoc.description}
          maxSize={fileMaxSize}
          sizeUnit={fileSizeUnit}
          extensions={extensions}
          onChange={
            handleFileChange({
              values,
              document: configurationTemplatesDoc,
              newAlert,
              setFieldValue,
              updateDocument,
            })
          }
          onError={handleFileError({ newAlert, fileMaxSize, fileSizeUnit })}
          submitting={loadingDocumentIds.includes(configurationTemplatesDoc.id)}
          comments={configurationTemplatesDoc.comments}
          onViewOtherComments={
            comments => onViewOtherComments(comments)
          }
          onAddComment={
            onAddComment({
              documentId: configurationTemplatesDoc.id,
              setFieldValue,
              values
            })
          }
          canAcceptOrRejectFile={canAcceptOrRejectFile}
          showCommentSection={showCommentSection(configurationTemplatesDoc)}
          onFileAcceptanceChange={
            handleFileAcceptanceChange({
              documentId: configurationTemplatesDoc.id,
              setFieldValue,
              values,
            })
          }
          fileAccepted={configurationTemplatesDoc.accepted}
          allowAddComment={allowAddComment(configurationTemplatesDoc)}
        />
      </Grid>
    </Grid>
  );
};

export default ConfigurationTemplatesStep;