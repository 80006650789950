import { CATEGORIES_SET, CATEGORY_SAVED } from '../constants/action_constants';
import { newAlert, clearAlerts, serverStatusError } from './alert_actions';
import RequestService from '../services/request_service';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { stringifyQuery } from '../helpers/parseQuerystring';
import categoriesFiltersSanitizer from '../helpers/sanitizers/categoriesFilters';
import { history } from '../helpers/history';
import { setUrl } from './file_actions';
import AxiosRequest from '../services/axios_request';
import { fetchOrgData } from './global_setting_actions';
import MIMETYPES from '../constants/mimetypes';
import camelcaseKeys from 'camelcase-keys';

const axiosRequest = new AxiosRequest();

const requestService = new RequestService('node');

function setCategories(data, meta) {
  return {
    type: CATEGORIES_SET,
    data,
    meta
  };
}

function saveCategory() {
  return {
    type: CATEGORY_SAVED
  };
}

export const fetchCategories = (params = {}) => async dispatch => {
  dispatch(sendRequest('FetchCategories'));
  history.replace(`/categories?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get(
      'categories',
      categoriesFiltersSanitizer(params)
    );
    dispatch(setCategories(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('FetchCategories'));
  }
};

export const createCategory = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('SaveCategory'));
  try {
    await requestService.post('categories', { ...camelcaseKeys(params) }, {}, true);
    dispatch(saveCategory());
    dispatch(clearAlerts());
    dispatch(fetchCategories(fetchParams));
    dispatch(fetchOrgData());
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors.base));
      } else {
        dispatch(errorOnRequest('SaveCategory', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('SaveCategory'));
  }
};

export const updateCategory = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('SaveCategory'));
  try {
    await requestService.put(
      `categories/${params.id}`,
      { ...params },
      {},
      true
    );
    dispatch(saveCategory());
    dispatch(clearAlerts());
    dispatch(fetchCategories(fetchParams));
    dispatch(fetchOrgData());
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors.base));
      } else {
        dispatch(errorOnRequest('SaveCategory', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('SaveCategory'));
  }
};

export const exportToXlsx = (params = {}) => async dispatch => {
  dispatch(sendRequest('ExportToXlsx'));
  try {
    const payload = await requestService.get(
      'categories/export',
      categoriesFiltersSanitizer(params)
    );
    const file = new Blob([new Uint8Array(payload.data).buffer], {
      type: MIMETYPES['xlsx']
    });
    const fileUrl = URL.createObjectURL(file);
    window.location.href = fileUrl;
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('ExportToXlsx'));
  }
};
