import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {columnChooserItems} from './helpers';
import ColumnChooserItem from './ColumnChooserItem';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = theme => ({
  listItemRoot: {
    padding: [[theme.spacing.unit, theme.spacing.unit * 2]],
  },
  listItemTextRoot: {
    fontSize: 13
  }
})

export class ColumnChooser extends PureComponent {
  state = {
    optionsMenu: null
  }

  _handleOpenOptionsMenu = event => {
    this.setState({ optionsMenu: event.currentTarget });
  };

  _handleCloseOptionsMenu = () => {
    this.setState({ optionsMenu: null });
  };

  render(){
    return (
      <Fragment>
        <IconButton
          aria-label="More"
          onClick={this._handleOpenOptionsMenu}
          title={'Acciones'}
          ref={ref => (this.anchorEl = ref)}
        >
          <VisibilityOffIcon />
        </IconButton>
        <Menu
          anchorEl={this.state.optionsMenu}
          open={Boolean(this.state.optionsMenu)}
          onClose={this._handleCloseOptionsMenu}
        >
          {
            columnChooserItems(this.props.columns, this.props.hiddenColumnNames)
              .map((item) => {
                const { name: columnName } = item.column;
                return (
                  <ColumnChooserItem
                    key={columnName}
                    item={item}
                    onToggle={() => this.props.toggleColumnVisibility(columnName)}
                    classes={this.props.classes}
                  />
                );
              })
          }
        </Menu>
      </Fragment>
    )
  }
}

ColumnChooser.propTypes = {
  columns: PropTypes.array.isRequired,
  hiddenColumnNames: PropTypes.array.isRequired,
  toggleColumnVisibility: PropTypes.func,
};

ColumnChooser.defaultProps = {
  toggleColumnVisibility: () => { },
};

export default withStyles(styles)(ColumnChooser);
