import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChangePasswordPageStyles from './ChangePasswordPage.styles';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import ChangePasswordModal from '../UserDetail/modals/ChangePasswordModal';
import ConfirmDialog from '../../shared/Modals/ConfirmDialog';
import classnames from 'classnames';

class ChangePasswordPage extends React.Component {
  state = {
    isOpenedChangePasswordModal: false,
    dialogReset: false,
    dialogReqPassword: false
  };

  componentDidMount = () => {
    this._validateUserToken();
  };

  _handleCloseDialogReqPass = () => {
    this.setState({ dialogReqPassword: false });
  };

  _handleCloseDialog = () => {
    this.setState({ dialogReset: false });
    this.props.history.push('/login');
  };

  _handleCloseChangePasswordModal = () => {
    this.setState({ isOpenedChangePasswordModal: false });
    this.props.history.push('/login');
  };

  _handleChangePassword = passwordInfo => {
    return this.props
      .updatePasswordWithToken({ token: this.state.token, ...passwordInfo })
      .then(isCorrect => {
        if (isCorrect) {
          this.props.history.push('/login');
        } else {
          return false;
        }
      });
  };

  _validateUserToken = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tokenUser = params.get('reset_password_token');

    if (tokenUser != null && tokenUser != '') {
      this.props.validateUserToken(tokenUser).then(isCorrect => {
        if (isCorrect.errors) {
          this.setState({
            dialogReset: true
          });
        } else {
          this.setState({
            token: tokenUser,
            isOpenedChangePasswordModal: true
          });
        }
      });
    } else {
      this.props.history.push('/login');
    }
  };

  render() {
    const {
      classes,
      requestChangePasswordSend,
      requestErrorsOnChangePassword
    } = this.props;
    console.log(requestErrorsOnChangePassword);
    return (
      <div className={classes.resetContainer}>
        <div className={classes.backgroundTop} />
        <div className={classes.backgroundBottom} />

        {this.state.isOpenedChangePasswordModal && (
          <ChangePasswordModal
            isCurrentUser={false}
            isSubmitting={requestChangePasswordSend}
            errors={requestErrorsOnChangePassword}
            onClose={this._handleCloseChangePasswordModal}
            onSave={this._handleChangePassword}
          />
        )}
        <ConfirmDialog
          open={this.state.dialogReset}
          title="Mensaje Importante"
          text="Este enlace se encuentra deshabilitado debido a que el token no es válido."
          buttonTextConfirm="ENTENDIDO"
          onConfirm={this._handleCloseDialog}
          onClose={this._handleCloseDialog}
        />
      </div>
    );
  }
}

const styles = theme => ({
  check: {
    marginLeft: 0,
    marginTop: theme.spacing.unit * 2.5
  },
  alertContainer: {
    marginTop: theme.spacing.unit * 2.5,
    marginBottom: -theme.spacing.unit * 2
  }
});

export default withStyles(
  combineStyles(
    styles,
    ChangePasswordPageStyles,
    ButtonStyles,
    TypographyStyles
  )
)(ChangePasswordPage);
