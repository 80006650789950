/* eslint-disable complexity */
import { SET_DATA_TELESHOPPING_REGISTER } from '../constants/teleshopping_cosntants';

import {
  TELESHOPPING_TOGGLE_BASE_MODAL,
  TELESHOPPING_STEP_ERROR,
  TELESHOPPING_STEP_SUCCESS,
  TELESHOPPING_PERSIST,
  TELESHOPPING_CLEAR
} from '../constants/action_constants';

const initialState = {
  errors: {},
  baseErrorOpen: false,
  baseErrorText: '',
  baseErrorTitle: '',
  baseErrorRoute: '',

  product_name: '',
  contract: null,
  client_name: '',
  client_last_name: '',
  client_email: '',
  client_phone: '',
  client_mobile: '',
  document: '',
  verification_sale: null,
  price: null,
  iva: null,
  instalments: null,
  sold_at: null,
  reference_sale: null,
  reference_sale_supplier: '',
  reference_sale_pointOfSale: '',
  reference_sale_salesman: '',
  reference_sale_salesman_id: '',
  reference_sale_registered_at: null,
  sellers: [],
  salesmanId: '',
  salesman: '',
  is_promissories_v2: false,

  validContract: false,
  stepValidateContract: true,
  stepCreateTeleshopping: false,
  base64: null,
  voucherProcessId: null,
  voucher_process_id: null,
  mobile: null,
  otpValidatorDebtor: null,
  otpValidatorCosigner: null,
  otpV2TransaccionId: null,
  voucherSerialV2: null,


  stepIsLoading: false,
  teleshoppingCompleted: false
};

export function teleshopping(state = initialState, action) {
  switch (action.type) {
    case TELESHOPPING_TOGGLE_BASE_MODAL:
      return {
        ...state,
        baseErrorOpen: action.open,
        baseErrorText: action.text,
        baseErrorTitle: action.title,
        baseErrorRoute: action.route
      };
    case SET_DATA_TELESHOPPING_REGISTER:
      return {
        ...state,
        product_name: action.data.sale_summary.article_name,
        price: action.data.sale_summary.price,
        iva: action.data.sale_summary.iva,
        contract: action.data.sale_summary.contract,
        instalments: action.data.sale_summary.number_of_instalments,
        document: action.data.sale_summary.client_identification,
        client_name: action.data.sale_summary.client_name,
        client_last_name: action.data.sale_summary.client_last_name,
        client_email: action.data.sale_summary.client_email,
        client_phone: action.data.sale_summary.client_phone,
        client_mobile: action.data.sale_summary.client_mobile,
        verification_sale: action.data.sale_summary.verification_sale,
        sold_at: action.data.sale_summary.sold_at,
        reference_sale: action.data.sale_summary.reference_sale,
        reference_sale_supplier:
          action.data.sale_summary.reference_sale_supplier,
        reference_sale_pointOfSale:
          action.data.sale_summary.reference_sale_pointOfSale,
        reference_sale_salesman:
          action.data.sale_summary.reference_sale_salesman,
        reference_sale_salesman_id:
          action.data.sale_summary.reference_sale_salesman_id,
        reference_sale_registered_at:
          action.data.sale_summary.reference_sale_registered_at,
        is_promissories_v2:
          action.data.sale_summary.is_promissories_v2
      };
    case TELESHOPPING_STEP_ERROR:
      return {
        ...state,
        stepIsLoading: false,
        errors: action.errors
      };
    case TELESHOPPING_STEP_SUCCESS:
      return {
        ...state,
        ...action.data,
        stepIsLoading: false,
        errors: {}
      };
    case TELESHOPPING_CLEAR:
      return initialState;
    case TELESHOPPING_PERSIST:
      return action.data;
    default:
      return state;
  }
}
