import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/styles';
import _isEmpty from 'lodash/isEmpty';
import LoadingIndicator from '../../shared/LoadingIndicator';
import SupplierProposalCreator from '../SupplierProposalCreator';

class SupplierProposalShow extends Component {
  state = {
    activeStep: 0
  };

  componentDidMount() {
    const { fetchProposal, match } = this.props;
    const urlPropsalId = match.params.id;
    fetchProposal({ id: urlPropsalId });
  }

  render() {
    const { fetchingProposal, proposal } = this.props;
    if (fetchingProposal || !proposal || !proposal.id)
      return <LoadingIndicator />;
    return (
      <SupplierProposalCreator proposal={proposal} creator={proposal.creator} />
    );
  }
}

export default withStyles(styles, { withTheme: true })(SupplierProposalShow);
