import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required('El nombre es requerido'),
  parent_name: Yup.string(),
  contract_type_id: Yup.string().nullable().required('El tipo es requerido'),
});

export default validationSchema;
