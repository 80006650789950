import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';

const styles = () => ({
  icon: {},
  progress: {
    fontSize: '20px'
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  endInputLoading: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  }
});

function LoadingIndicator(props) {
  const { classes, endInputLoading = false } = props;
  return (
    <div className={endInputLoading ? classes.endInputLoading : classes.loadingContainer}>
      <CircularProgress
        color="secondary"
        {...(endInputLoading ? { size: 30 } : {})}
        className={classnames(classes.progress, classes.icon)}
      />
    </div>
  );
}

LoadingIndicator.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingIndicator);
