import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  contract: Yup.number()
    .required('El campo es requerido')
    .min(1, 'Ingresa un contrato válido.'),
  verification_sale: Yup.number()
    .required('El campo es requerido')
    .min(1, 'Ingresa una venta válida.')
});
