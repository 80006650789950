import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const capitalize = str => {
  if (!str) str = '';
  return str.trim().replace(/^\w/, char => char.toUpperCase());
}

class PercentageList extends Component {
  state = {
    values: {}
  }

  constructor(props) {
    super(props);
    const values = {};
    props.list.forEach(
      item => {
        values[item.id] = {};
        values[item.id].item = item;
        if (item.initialValues === undefined) item.initialValues = {};
        const checked = item.writable
          ? !!item.initialValues.percentage || !!item.initialValues.text
          : !!item.initialValues.percentage;
        values[item.id].checked = checked;
        values[item.id].percentage = checked
          ? item.initialValues.percentage
          : '';
        if (item.writable) {
          values[item.id].text = checked
            ? item.initialValues.text
            : '';
        }
      }
    );
    this.state = { values };
  }

  render() {
    const { list, classes, onChange, disabled } = this.props;
    const { values } = this.state;
    const totalPercentage = this._calculateTotalPercentage(values);
    const someChecked = Object.values(values).some(
      value => value.checked
    );
    const helperTextStyles = someChecked && totalPercentage !== 100
      ? classes.helperTextWithError
      : classes.helperText;
    return (
      <List>
        {
          list.map(
            item => {
              return (
                <div key={item.id}>
                  <ListItem className={classes.listItem}>
                    <Checkbox
                      className={classes.checkbox}
                      checked={values[item.id].checked}
                      disabled={disabled}
                      onChange={
                        event => {
                          const newValue = event.target.checked;
                          values[item.id].checked = newValue;
                          if (newValue === false) {
                            values[item.id].percentage = '';
                            if (item.writable) values[item.id].text = '';
                            onChange({
                              values,
                              total: this._calculateTotalPercentage(values)
                            });
                          }
                          this.setState({ values });
                        }
                      }
                    />
                    <ListItemText>
                      { capitalize(item.label) }
                    </ListItemText>
                    {
                      item.writable && values[item.id].checked && (
                        <TextField
                          label='Otros *'
                          variant='outlined'
                          value={values[item.id].text}
                          className={classes.textField}
                          disabled={disabled}
                          onChange={
                            event => {
                              const newValue = event.target.value;
                              values[item.id].text = newValue;
                              onChange({
                                values,
                                total: this._calculateTotalPercentage(values)
                              });
                              this.setState({ values });
                            }
                          }
                        />
                      )
                    }
                    <TextField
                      variant='outlined'
                      value={values[item.id].percentage}
                      className={classes.percentageField}
                      disabled={!values[item.id].checked || disabled}
                      onChange={
                        event => {
                          const strInput = removeNotDigitChars(event.target.value);
                          if (strInput === '') {
                            values[item.id].percentage = '';
                          } else {
                            const parsedInput = Number(strInput);
                            const newValue = parsedInput < 0 ? 0 : parsedInput;
                            if (newValue <= 100) {
                              values[item.id].percentage = newValue;
                            }
                          }
                          onChange({
                            values,
                            total: this._calculateTotalPercentage(values)
                          });
                          this.setState({ values });
                        }
                      }
                    />
                    <Typography variant='body1'>%</Typography>
                  </ListItem>
                  <Divider />
                </div>
              );
            }
          )
        }
        <ListItem className={classes.listItem}>
          <ListItemText>
            <b className={helperTextStyles}>Te recordamos que la suma de los porcentajes debe ser igual a 100%</b>
          </ListItemText>
          <ListItemText className={classes.total}>
            <b>Total</b>
          </ListItemText>
          <TextField
            type='number'
            variant='outlined'
            value={totalPercentage}
            className={classes.percentageField}
            disabled={true}
          />
          <Typography variant='body1'>%</Typography>
        </ListItem>
        <Divider />
      </List>
    );
  }

  _calculateTotalPercentage = values => {
    return Object.keys(values).reduce(
      (acum, key) => {
        const value = values[key];
        if (!value.checked) return acum + 0;
        return acum + Number(value.percentage);
      },
      0
    )
  }
}

const removeNotDigitChars = str => {
  return str.replace(/\D/g,'');
}

const styles = theme => ({
  listItem: {
    paddingLeft: 0,
    paddingRight: 0
  },
  checkbox: {
    paddingLeft: 0
  },
  percentageField: {
    width: '7%',
    marginRight: 4
  },
  textField: {
    marginRight: 15,
    width: '40%'
  },
  total: {
    textAlign: 'right'
  },
  helperText: {
    color: 'black'
  },
  helperTextWithError: {
    color: 'red'
  }
});

export default withStyles(
  styles, { withTheme: true }
)(PercentageList);