const styles = theme => ({
  headerRoot: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    width: '100%'
  },
  clientDetails: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    minHeight: 84,
    flexWrap: 'wrap',
    padding: [[0, theme.spacing.unit * 3]],
    position: 'relative',
    '&:first-child': {
      borderBottom: '1px solid rgba(12,31,44,0.12)'
    }
  },
  details: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing.unit * 3
  },
  contactData: {
    wordBreak: 'break-all'
  }
});

export default styles;
