import _pickBy from 'lodash/pickBy';

const queryData = filters => {
  const { queryType, contract, identification, identificationType } = filters;

  const sanitizedFilters = {
    contract: queryType === 'contract' ? contract : null,
    identification: queryType === 'id' ? identification : null,
    type_of_identification: queryType === 'id' ? identificationType : null
  };

  return _pickBy(sanitizedFilters, filter => !!filter);
};

export default queryData;
