import moment from 'moment-timezone';
import { CUSTOM_DATE } from './constants';

export const statusOptions = [
  { value: 'todas', title: 'Todos' },
  { value: 'Anulado', title: 'Anulado' },
  { value: 'Aprobado', title: 'Aprobado' },
  { value: 'Registrado', title: 'Registrado' },
  { value: 'Rechazado', title: 'Rechazado' }
];

export const uniquePromissoryOptions = [
  { value: 'todas', title: 'Todos' },
  { value: 'true', title: 'Si' },
  { value: 'false', title: 'No' }
];

export const unmaterializedPromissoryOptions = [
  { value: 'todas', title: 'Todos' },
  { value: 'true', title: 'Si' },
  { value: 'false', title: 'No' }
];

export const originalDateOptions = [
  { value: 'todas', title: 'Todas' },
  { value: moment().format('YYYY-MM-DD'), title: 'Hoy' },
  {
    value: `${moment()
      .startOf('week')
      .format('YYYY-MM-DD')} - ${moment()
      .endOf('week')
      .format('YYYY-MM-DD')}`,
    title: 'Esta semana'
  },
  {
    value: `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')} - ${moment()
      .endOf('month')
      .format('YYYY-MM-DD')}`,
    title: 'Este mes'
  }
];

export const dateOptions = (customDate, registered_at) => [
  ...originalDateOptions,
  {
    value: customDate !== CUSTOM_DATE ? registered_at : CUSTOM_DATE,
    title: customDate !== CUSTOM_DATE ? registered_at : 'Personalizada'
  }
];
