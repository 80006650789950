import InsurancesList from './InsuranceList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchOrgData } from '../../../actions/global_setting_actions';
import { newAlert } from '../../../actions/alert_actions';
import { completeRequest } from '../../../actions/request_state';
import { fetchInsurances } from '../../../actions/insurance_actions';

function mapStateToProps(state) {
  return {
    serverErrors: state.user.errors,
    insurances: state.insurances.insuranceList,
    meta: state.insurances.meta,
    suppliers: state.globalSetting.orgData.suppliers,
    requestSend: state.requestState.requestSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchInsurances: bindActionCreators(fetchInsurances, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    completeRequest: bindActionCreators(completeRequest, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InsurancesList);
