import moment from 'moment';
import _pickBy from 'lodash/pickBy';

export const scoringSanitizer = (values, solidaryCosigner) => {
  return {
    supplier_id: values.supplierId,
    debtor_expedition_date: moment(values.debtorExpeditionDate, 'YYYY/MM/DD').format(
      'YYYY-MM-DD'
    ),
    cosigner_expedition_date: values.cosignerExpeditionDate
      ? moment(values.cosignerExpeditionDate, 'YYYY/MM/DD').format('YYYY-MM-DD')
      : null,
    cache_params: {
      debtor_identification_type: values.debtorIdentificationType,
      debtor_identification: values.debtorIdentification,
      debtor_contract_number: values.debtorContractNumber,
      debtor_birthdate: values.debtorBirthdate,
      cosigner_identification_type: values.cosignerIdentificationType || null,
      cosigner_identification: values.cosignerIdentification || null,
      cosigner_contract_number: values.cosignerContractNumber || null,
      cosigner_birthdate: values.cosignerBirthdate || null
    },
    cosigner_was_solidary: solidaryCosigner
  };
};

export const scoringConsultsListSanitizer = params => {
  const {
    date,
    finalValue,
    identification,
    contractId,
    birthDate,
    issueDate,
    page,
    perPage
  } = params;

  const dateRange = date ? date.split(' - ') : [];
  const birthRange = birthDate ? birthDate.split(' - ') : [];
  const issueRange = issueDate ? issueDate.split(' - ') : [];

  const sanitizedParams = {
    page,
    per_page: perPage,
    solicitude_date_gteq: dateRange[0] || date,
    solicitude_date_lteq: dateRange[1] || date,
    granted_quota_eq: finalValue,
    identification_eq: identification,
    contract_id_eq: contractId,
    request_birthdate_gteq: birthRange[0] || birthDate,
    request_birthdate_lteq: birthRange[1] || birthDate,
    request_expedition_date_gteq: issueRange[0] || issueDate,
    request_expedition_date_lteq: issueRange[1] || issueDate
  };

  return _pickBy(sanitizedParams, filter => !!filter);
};
