import _pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

export const editPromissorySanitizer = values => {
  const sanitizedFilters = {
    debtor: {
      name: values.debtorName,
      last_name: values.debtorLastName,
      date_of_birth: moment(values.debtor_date_of_birth, 'YYYY/MM/DD').format(
        'YYYY-MM-DD'
      ),
      phone: values.debtorPhone,
      mobile: values.debtorMobile,
      id_expedition_date: moment(
        values.debtorExpeditionDate,
        'YYYY/MM/DD'
      ).format('YYYY-MM-DD'),
      id_expedition_place: values.debtorExpeditionPlace
    },
    cosigner: values.cosignerName
      ? {
          name: values.cosignerName,
          last_name: values.cosignerLastName,
          date_of_birth: moment(
            values.cosigner_date_of_birth,
            'YYYY/MM/DD'
          ).format('YYYY-MM-DD'),
          phone: values.cosignerPhone,
          mobile: values.cosignerMobile,
          id_expedition_date: moment(
            values.cosignerExpeditionDate,
            'YYYY/MM/DD'
          ).format('YYYY-MM-DD'),
          id_expedition_place: values.cosignerExpeditionPlace
        }
      : null
  };
  const params = _pickBy(sanitizedFilters, filter => !!filter);
  return {
    ...params
  };
};

export const cancelPromissorySanitizer = values => {
  const sanitizedFilters = {
    cause: Number.parseInt(values.cause),
    observations: values.observations
  };
  const params = _pickBy(sanitizedFilters, filter => !!filter);
  return {
    ...params
  };
};

export const approvePromissorySanitizer = values => {
  const sanitizedFilters = {
    causal_id: Number.parseInt(values.cause),
    comments: values.observations
  };
  const params = _pickBy(sanitizedFilters, filter => !!filter);
  return {
    ...params
  };
};
