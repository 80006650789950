export const columns = [
  {
    title: 'ID',
    name: 'id'
  },
  {
    title: 'Mínimo número de cuotas',
    name: 'minNumOfInstalments'
  },
  {
    title: 'Máximo número de cuotas',
    name: 'maxNumOfInstalments'
  },
  {
    title: 'Porcentaje',
    name: 'percentage'
  }
];

export const mapData = data =>
  [...data].sort((a, b) => b.id - a.id).map(datum => {
    return {
      ...datum,
      minNumOfInstalments: datum.minNumOfInstalments.toString(),
      maxNumOfInstalments: datum.maxNumOfInstalments.toString(),
      percentage: Number(datum.percentage).toString(),
    };
  });
