import React from 'react';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import { Link } from 'react-router-dom';

import { Icon } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import _replace from 'lodash/replace';

import ActivityIndicator from '../../shared/ActivityIndicator';
import TypographyStyles from '../../../styles/typography';

export const RankingCard = ({
  classes,
  icon,
  title,
  subtitle,
  person = [],
  isLoading,
  selectedSupplier,
  selectedPos
}) => {
  function formatNameText(index) {
    if (!person[index].nameSeller) return '';

    let text = person[index].nameSeller;

    if (!selectedSupplier && person[index].supplier) {
      text += ` — ${person[index].supplier}`;
    }

    return text;
  }

  function formatDescriptionText() {
    let text = '';

    if (!!selectedSupplier) {
      text += ` para el aliado ${selectedSupplier}`;
    }

    if (!!selectedPos) {
      text += ` en el punto de venta ${selectedPos}`;
    }

    return text;
  }

  return (
    <Card className={classes.rootBase}>
      {isLoading && <ActivityIndicator className={classes.loadingIndicator} />}

      <Icon classes={{ root: classes.iconRoot }}>
        <img
          className={classes.imageIcon}
          src={icon}
          width="70px"
          height="70px"
        />
      </Icon>

      <CardContent>
        <h2 className={classes.title}>{title}</h2>
        {/* Redireccionamiento a tabla RANKING */}
        <Link to="/ranking">
          <h3 className={classes.subtitle}>{subtitle}</h3>
        </Link>

        <div className={classes.center}>
          {(person.length < 1 || !person.length) && (
            <p className={classes.message}>
              No se registran ventas en este periodo.
            </p>
          )}

          {person.length >= 1 && (
            <p className={classes.message}>
              {' '}
              Los siguientes son los vendedores con mayores ingresos en ventas
              durante el periodo seleccionado{formatDescriptionText()}:
            </p>
          )}
        </div>
      </CardContent>

      <div>
        <CardContent>
          {person.length == 1 && (
            <div className={classes.ranking}>
              <div className={classes.group}>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  className={(classes.label, classes.num)}
                >
                  1
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  block
                  className={(classes.btn, classes.btnYellow)}
                >
                  {formatNameText(0)}
                </Button>
              </div>
            </div>
          )}

          {person.length == 2 && (
            <div className={classes.ranking}>
              <div className={classes.group}>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  className={(classes.label, classes.num)}
                >
                  1
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  block
                  className={(classes.btn, classes.btnYellow)}
                >
                  {formatNameText(0)}
                </Button>
              </div>
              <div className={classes.group}>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  className={(classes.label, classes.num)}
                >
                  2
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  block
                  className={classes.btnYellow}
                >
                  {formatNameText(1)}
                </Button>
              </div>
            </div>
          )}

          {person.length >= 3 && (
            <div className={classes.ranking}>
              <div className={classes.group}>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  className={(classes.label, classes.num)}
                >
                  1
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  block
                  className={(classes.btn, classes.btnYellow)}
                >
                  {formatNameText(0)}
                </Button>
              </div>
              <div className={classes.group}>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  className={(classes.label, classes.num)}
                >
                  2
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  block
                  className={classes.btnYellow}
                >
                  {formatNameText(1)}
                </Button>
              </div>
              <div className={classes.group}>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  className={(classes.label, classes.num)}
                >
                  3
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  block
                  className={(classes.btn, classes.btnYellow)}
                >
                  {formatNameText(2)}
                </Button>
              </div>
            </div>
          )}
        </CardContent>
      </div>
    </Card>
  );
};

const styles = theme => ({
  ['@media (max-width: 680px)']: {
    rootBase: {
      padding: theme.spacing.unit + 'px !important',
      display: 'inline-grid',
      marginBottom: '15px',
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    subtitle: {
      marginTop: '4px'
    }
  },
  ['@media (max-width: 500px)']: {
    message: {
      fontSize: '14px !important',
      marginTop: '18px !important',
      marginBottom: '0px !important'
    },
    title: {
      fontSize: '25px !important'
    }
  },
  rootBase: {
    background: '#00000005 0% 0% no-repeat padding-box',
    opacity: '1',
    //minHeight: '560px',
    maxWidth: '100%',
    boxShadow: 'none',
    textAlign: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto !important'
  },
  title: {
    fontFamily: 'Rubik  !important',
    fontWeight: 'normal !important',
    marginBottom: '4px'
  },
  subtitle: {
    marginTop: '0px',
    color: '#DB4844',
    fontWeight: 'bold !important'
  },
  message: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#00000099',
    opacity: '1'
  },
  btnYellow: {
    width: '100%',
    maxWidth: '280px !important',
    marginBottom: '12px !important',
    lineHeight: '1rem',
    minHeight: '50px',
    boxShadow: 'none',
    background: '#FEAD002F 0% 0% no-repeat padding-box',
    color: '#FEAD00'
  },
  ranking: {
    display: 'inline-grid'
  },
  group: {
    display: 'inline-flex'
  },
  num: {
    background: '#FEAD00 0% 0% no-repeat padding-box',
    boxShadow: 'none',
    color: 'white',
    height: '50px',
    minWidth: '40px',
    fontSize: '30px'
  },
  center: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%'
  }
});

RankingCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  person: PropTypes.array.isRequired
};

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles)
)(RankingCard);
