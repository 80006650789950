import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';

import ProgressBar from '../ProgressBar';

import ActivityIndicator from '../ActivityIndicator';

const styles = theme => ({
  ['@media (max-width: 768px)']: {
    totalCount: {
      fontSize: '1.5rem',
      lineHeight: '1.3rem',
      fontWeight: '400 !important',
      fontWeight: 'bold !important'
    },
    message: {
      fontSize: '0.8rem !important',
      marginTop: '4px !important'
    },
    rootBase: {
      padding: theme.spacing.unit + 'px !important'
    }
  },
  ['@media (max-width: 500px)']: {
    totalCount: {
      fontSize: '1rem',
      lineHeight: '1rem',
      fontWeight: '400 !important',
      fontWeight: 'bold !important'
    },
    message: {
      fontSize: '9px !important',
      marginTop: '4px !important',
      marginBottom: '4px !important'
    }
  },
  rootBase: {
    backgroundColor: '#FFFFFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    padding: theme.spacing.unit * 3,
    width: '100%',
    position: 'relative'
  },
  loadingIndicator: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 1
  }
});

export const SummaryCard = ({
  classes,
  totalCount,
  message,
  ProgressBarProps,
  isLoading
}) => (
  <div className={classes.rootBase}>
    {isLoading && <ActivityIndicator className={classes.loadingIndicator} />}
    <p className={classnames(classes.headline4, classes.totalCount)}>
      {totalCount}
    </p>
    <p className={(classes.headline7, classes.message)}>{message}</p>
    <ProgressBar {...ProgressBarProps} />
  </div>
);

SummaryCard.propTypes = {
  totalCount: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  ProgressBarProps: PropTypes.shape({
    color: PropTypes.string,
    totalValue: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    resourceType: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles)
)(SummaryCard);
