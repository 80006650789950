import { LIST_CONTRACT_STRATUM } from '../constants/contract_stratum_constants';

const initialState = {
  list: [],
};

export function contractStratum(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case LIST_CONTRACT_STRATUM:
      return {
        ...state,
        list: action.data,
      };
    default:
      return state;
  }
}
