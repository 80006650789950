import * as Yup from 'yup';

export const validationSchema = invoiceValidation =>
  Yup.object().shape({
    contract: Yup.number()
      .nullable()
      .required('Número de contrato es requerido'),
    type: Yup.string()
      .nullable()
      .required('Tipo de servicio es requerido'),
    value: Yup.number()
      .nullable()
      .required('Valor de servicio es requerido'),
    invoice: invoiceValidation
      ? Yup.number()
          .nullable()
          .required('Número de factura es requerido')
      : null
  });
