import * as Yup from 'yup';

const names = /^[a-zA-Z\u00f1\u00d1]+(\s*[a-zA-Z\u00f1\u00d1]*)*[a-zA-Z\u00f1\u00d1\s]+$/;

export const debtorValidation = isUnmaterialized => Yup.object().shape({
  debtorName: Yup.string().required('El campo es requerido'),
  debtorLastName: Yup.string().required('El campo es requerido'),
  debtor_date_of_birth: Yup.string().required('El campo es requerido'),
  debtorExpeditionDate: Yup.string().required('El campo es requerido'),
  debtorExpeditionPlace: Yup.string().required('El campo es requerido'),
  debtorPhone: Yup.string().matches(
    /^([0-9]{7})$/,
    'El número ingresado debe tener 7 dígitos'
  ),
  debtorMobile: !isUnmaterialized ? Yup.string()
    .nullable()
    .required('El campo es requerido')
    .matches(/^([0-9]{10})$/, 'El número ingresado debe tener 10 dígitos.') : Yup.string()
      .nullable()
      .matches(/^([0-9]{10})$/, 'El número ingresado debe tener 10 dígitos.'),
  personalReferenceName: Yup.string()
    .nullable()
    .matches(names, 'No se permiten caracteres especiales o números.')
    .test('len', 'El nombre no puede tener más de 200 caracteres.', (val) => !val || val && val.toString().length <= 200),
  personalReferencePhone: Yup.string().nullable().matches(
    /^([0-9]{7})$/,
    'El número ingresado debe tener 7 dígitos'
  ),
  personalReferenceCellphone: Yup.string().nullable().matches(
    /^([0-9]{10})$/,
    'El número ingresado debe tener 10 dígitos.'
  )
});

export const cosignerValidation = Yup.object().shape({
  cosignerName: Yup.string().required('El campo es requerido'),
  cosignerLastName: Yup.string().required('El campo es requerido'),
  cosigner_date_of_birth: Yup.string().required('El campo es requerido'),
  cosignerExpeditionDate: Yup.string().required('El campo es requerido'),
  cosignerExpeditionPlace: Yup.string().required('El campo es requerido'),
  cosignerPhone: Yup.string().matches(
    /^([0-9]{7})$/,
    'El número ingresado debe tener 7 dígitos'
  ),
  cosignerMobile: Yup.string()
    .nullable()
    .required('El campo es requerido')
    .matches(/^([0-9]{10})$/, 'El número ingresado debe tener 10 dígitos.'),
  cosignerPersonalReferenceName: Yup.string()
    .nullable()
    .matches(names, 'No se permiten caracteres especiales o números.')
    .test('len', 'El nombre no puede tener más de 200 caracteres.', (val) => !val || val && val.toString().length <= 200),
  cosignerPersonalReferencePhone: Yup.string().nullable().matches(
    /^([0-9]{7})$/,
    'El número ingresado debe tener 7 dígitos'
  ),
  cosignerPersonalReferenceCellphone: Yup.string()
    .nullable()
    .matches(
      /^([0-9]{10})$/,
      'El número ingresado debe tener 10 dígitos.'
    )
});
