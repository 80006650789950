import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RemoteTeleshopping from './RemoteTeleshopping';
import { newAlert } from '../../../actions/alert_actions';
import {
  clearTeleshopping,
  persistTeleshopping,
  setNextStep,
  toggleBaseErrorModal as toggleBaseErrorModalTeleshopping
} from '../../../actions/teleshopping_actions';
import {
  resetStepper,
  setSteps,
  persistStepper,
  setCurrentStep
} from '../../../actions/stepper_actions';
import { getSteps } from '../../../helpers/stepperFunctions';
import { clearFinancing, persistFinancing, toggleBaseErrorModal } from '../../../actions/portal_financing_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;

  return {
    routes: state.stepper.routes,
    process: state.stepper.process,
    steps: state.stepper.steps,
    skips: state.stepper.skippedSteps,
    remoteTeleshoppingCompleted: state.teleshopping.teleshoppingCompleted,
    remoteTeleshoppingNext: state.stepper.remoteTeleshoppingNext,
    activeStep: state.stepper.activeStep,
    nextStep: state.stepper.nextStep,
    currentUser: state.authentication.currentUser,
    loadingParameters: state.requestState.requestFetchGlobalParametersSend,
    server_errors: state.teleshopping.errors || pf.errors,
    baseErrorOpen: state.teleshopping.baseErrorOpen || pf.baseErrorOpen,
    baseErrorText: state.teleshopping.baseErrorText || pf.baseErrorText,
    baseErrorTitle: state.teleshopping.baseErrorTitle || pf.baseErrorTitle || 'Televentas',
    baseErrorRoute: state.teleshopping.baseErrorRoute || pf.baseErrorRoute
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetStepper: bindActionCreators(resetStepper, dispatch),
    setSteps: bindActionCreators(setSteps, dispatch),
    getSteps: bindActionCreators(getSteps, dispatch),
    persistStepper: bindActionCreators(persistStepper, dispatch),
    toggleBaseErrorModalTeleshopping: bindActionCreators(toggleBaseErrorModalTeleshopping, dispatch),
    toggleBaseErrorModal: bindActionCreators(toggleBaseErrorModal, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    persistTeleshopping: bindActionCreators(persistTeleshopping, dispatch),
    clearTeleshopping: bindActionCreators(clearTeleshopping, dispatch),
    persistFinancing: bindActionCreators(persistFinancing, dispatch),
    clearFinancing: bindActionCreators(clearFinancing, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoteTeleshopping);
