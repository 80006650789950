import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';
import classnames from 'classnames';

const styles = theme => ({
  metricLabelContainer: {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    left: '50%',
    top: '50%',
    textAlign: 'center',
    '& p': {
      margin: 0
    }
  },
  chartContainer: {
    width: '100%',
    height: 175,
    position: 'relative',
    marginBottom: theme.spacing.unit * 3,
    marginBottom: 0,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerTitle: {
    fontFamily: theme.typography.font.second
  }
});
export class DonutChart extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    colors: PropTypes.array.isRequired,
    innerTitle: PropTypes.string.isRequired
  };

  constructor(props){
    super(props);
    this.state = {
      config: {
        labels: this.props.labels,
        datasets: [
          {
            data: [],
            backgroundColor: this.props.colors
          }
        ]
      },
      options: {
        maintainAspectRatio: true,
        responsive: false,
        legend: {
          display: false
        },
        cutoutPercentage: 70,
        tooltips: {
          enabled: false,
          custom: function(tooltipModel) {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
                return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function(title) {
                    innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';
                bodyLines.forEach(function(body, i) {
                    const text = body[0].split(': ');
                    const value = props.valueFormat ? props.valueFormat(parseInt(text[1])) : parseInt(text[1]);

                    const colors = tooltipModel.labelColors[i];
                    let style = 'background:' + colors.backgroundColor;
                    style += '; border-color:' + colors.borderColor;
                    style += '; border-radius: 2px';
                    style += '; display: inline-block';
                    style += '; height: 11px';
                    style += '; width: 11px';
                    style += '; top: 1px';
                    style += '; position: relative';
                    style += '; margin-right: 4px';
                    const span = '<span style="' + style + '"></span>';
                    innerHtml += `<tr><td>${span}${text[0]}: <strong>${value}</strong></td></tr>`;
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }
      },
      loading: false
    };
  }

  render() {
    const { config, options } = this.state;
    const { data, labels, innerText, innerTitle, classes, colors } = this.props;
    config.datasets[0].data = data;
    config.datasets[0].backgroundColor = colors;
    config.labels = labels;
    return (
      <div className={classes.chartContainer}>
        <div className={classes.metricLabelContainer}>
          <p className={classnames(classes.subtitle3, classes.innerTitle)}>
            {innerText}
          </p>
          <p className={classes.caption}>{innerTitle}</p>
        </div>
        <Doughnut data={config} width={175} height={175} options={options} />
      </div>
    );
  }
}

export default withStyles(combineStyles(TypographyStyles, styles))(DonutChart);
