// TODO hook other type of alerts to the component (aka AlertComponent)
// import AlertComponent from './Alerts';
import Snackbar from './Snackbar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  newAlert,
  closeAlert,
  clearAlerts
} from '../../../actions/alert_actions';

function mapStateToProps(state) {
  const { open, type, title, text } = state.alerts;
  return {
    AlertOpen: open,
    AlertType: type,
    AlertTitle: title,
    AlertText: text
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    closeAlert: bindActionCreators(closeAlert, dispatch),
    clear: bindActionCreators(clearAlerts, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Snackbar);
