import {
  NEW_COMMENTS,
  CLEAR_NEW_COMMENTS
} from '../constants/action_constants';

const initialState = {
  newComments: []
};

export function comment(state = initialState, action) {
  switch (action.type) {
    case NEW_COMMENTS:
      const newCom = [action.data, ...state.newComments];
      return {
        ...state,
        newComments: newCom
      };
    case CLEAR_NEW_COMMENTS:
      return {
        ...state,
        newComments: initialState.newComments
      };
    default:
      return state;
  }
}
