import {
  SET_INVENTORY,
  QUERY_INVENTORY_FILTERED,
  QUERY_INVENTORY_CATEGORIES,
  SET_SELECTED_PRODUCT,
  SET_STORE,
  OPEN_QUERY_INVENTORY_MODAL,
  CLOSE_QUERY_INVENTORY_MODAL,
  RESET_QUERY_DATA
} from '../constants/query_inventory_constants';
import _get from 'lodash/get';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { newAlert } from './alert_actions';
import AxiosRequest from '../services/axios_request';

const axiosRequest = new AxiosRequest();

export const openQueryInventoryModal = () => {
  return dispatch => {
    dispatch({
      type: OPEN_QUERY_INVENTORY_MODAL
    });
  };
};

export const closeQueryInventoryModal = () => {
  return dispatch => {
    dispatch(errorOnRequest('QueryInventory', null));
    dispatch({
      type: CLOSE_QUERY_INVENTORY_MODAL
    });
  };
};

export const resetQueryData = () => {
  return dispatch => {
    dispatch({
      type: RESET_QUERY_DATA
    });
  };
};

function setInventory(data) {
  return {
    type: SET_INVENTORY,
    data
  };
};

export function setCategoriesD(data) {
  return dispatch => {
    dispatch(setCategories(data));
  };
};

function setCategories(data) {
  return {
    type: QUERY_INVENTORY_CATEGORIES,
    data
  };
};

export function setFilteredInventory(data) {
  return dispatch => {
    dispatch({
      type: QUERY_INVENTORY_FILTERED,
      data
    });
  };
};

export function setSelectedProduct(data) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_PRODUCT,
      data
    });
  };
};

export function setStore(data) {
  return dispatch => {
    dispatch({
      type: SET_STORE,
      data
    });
  };
};

export function getCategories(inventory, provider) {
  const stores = {
    electroao: 'Categoria',
    samir: 'desc_grupo'
  };

  const categories = ['Todas'];

  inventory.map(item => {
    if (!categories.includes(item[stores[provider]])) {
      categories.push(item[stores[provider]]);
    }
  });

  return categories;
};

export const queryInventory = (params = {}) => async dispatch => {
  dispatch(sendRequest('QueryInventory'));
  try {
    const payload = await axiosRequest.get(
      `inventories/get_${params.store}_inventory`,
      {},
      true
    );

    dispatch(setInventory({ store: params.store, inventory: payload.data.inventory }));
    dispatch(setCategories(getCategories(payload.data.inventory, params.store)));
    dispatch(errorOnRequest('QueryInventory', null));
  } catch (errors) {
    dispatch(errorOnRequest('QueryInventory', errors));
    dispatch(setInventory({ store: params.store, inventory: [] }));
    dispatch(setCategories([]));
    _get(errors, 'errors.base') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('QueryInventory'));
  }
};
