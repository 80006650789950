// TODO: turn font sizes into variables for use in other styles
// TODO: change font sizes from strict px to something more relative/responsive
// TODO: apply font styles in theme
const styles = theme => ({
  headline4: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.second,
    fontSize: '34.54px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '41px',
    margin: 0
  },
  headline5: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.second,
    fontSize: '24.38px',
    fontWeight: 500,
    lineHeight: '29px',
    margin: 0
  },
  headline5Light: {
    color: theme.typography.color.second,
    fontFamily: theme.typography.font.second,
    fontSize: '24.38px',
    fontWeight: 500,
    lineHeight: '29px',
    margin: 0
  },
  headline6: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.main,
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '0.25px',
    lineHeight: '24px',
    margin: 0
  },
  headline7: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.main,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    margin: 0,
    flex: 1
  },
  headline8: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.main,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.25px',
    margin: 0
  },
  body1: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.main,
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    lineHeight: '28px',
    margin: 0
  },
  body1Light: {
    color: theme.typography.color.second,
    fontFamily: theme.typography.font.main,
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    lineHeight: '28px',
    margin: 0
  },
  body2: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.main,
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '20px',
    margin: 0,
    wordBreak: 'break-word'
  },
  body2Light: {
    color: theme.typography.color.second,
    fontFamily: theme.typography.font.main,
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '20px',
    margin: 0,
    wordBreak: 'break-word'
  },
  subtitle2: {
    color: theme.typography.color.second,
    flex: 1,
    fontFamily: theme.typography.font.main,
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    margin: 0,
    marginBottom: theme.spacing.unit
  },
  subtitle3: {
    color: theme.typography.color.main,
    flex: 1,
    fontFamily: theme.typography.font.main,
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    margin: 0,
    marginBottom: theme.spacing.unit
  },
  caption: {
    color: 'rgba(12,31,44,0.6)',
    fontFamily: theme.typography.font.main,
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '16px',
    margin: 0
  },
  overline: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.main,
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '2px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    margin: 0
  },
  tableLink: {
    marginBottom: theme.spacing.unit * 0.5,
    fontFamily: 'Roboto',
    fontSize: '13px',
    letterSpacing: '0.1px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    left: '4px'
  },
  lightWeight: {
    fontWeight: 300
  },
  normalWeight: {
    fontWeight: 400
  },
  mediumWeight: {
    fontWeight: 500
  },
  boldWeight: {
    fontWeight: 600
  },
  nameLabel: {
    textTransform: 'capitalize'
  }
});

export default styles;
