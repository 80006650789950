import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  withStyles
} from '@material-ui/core';
import {
  ArrowForwardRounded,
  LockOutlined,
  PersonOutlineRounded,
  VisibilityOffOutlined,
  VisibilityOutlined,
  WarningRounded
} from '@material-ui/icons';
import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import {
  default as LoginPageStyles,
  default as styles
} from './LoginPage.styles';

class LoginForm extends Component {
  state = {
    email: '',
    password: '',
    warningCaps: false,
    sitekey: process.env.SITE_KEY_CAPTCHA_INVISIBLE_V2,
    passwordVisible: false
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  togglePasswordVisibility = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  _isCapsLockOn = () => {
    const usernameField = document.getElementById('username-input');
    const passwordField = document.getElementById('password-input');
    if (passwordField) {
      passwordField.onkeydown = e => {
        if (e instanceof KeyboardEvent && e.getModifierState('CapsLock')) {
          this.setState({ warningCaps: true });
        } else {
          this.setState({ warningCaps: false });
        }
      };
    }
    if (usernameField) {
      usernameField.onkeydown = e => {
        if (e instanceof KeyboardEvent && e.getModifierState('CapsLock')) {
          this.setState({ warningCaps: true });
        } else {
          this.setState({ warningCaps: false });
        }
      };
    }
  };

  renderCapsLockWarning() {
    return (
      <div className={this.props.classes.warningAlert}>
        <WarningRounded style={{ marginRight: '16px' }} />
        <p style={{ margin: '8px 0' }}>
          Tienes el bloqueo de mayúsculas activado
        </p>
      </div>
    );
  }

  render() {
    const {
      classes,
      loading,
      loadingRecaptchaInvisible,
      isRecaptchaValidationActive,
      recaptchaRef,
      handleSubmit
    } = this.props;
    const { email, password, sitekey, passwordVisible } = this.state;
    this._isCapsLockOn();

    return (
      <Grid
        container
        className={classes.formContainer}
        direction="column"
        justify="center"
        alignItems="flex-start"
      >
        <h1 style={{ color: 'black', margin: 0 }}>
          Bienvenido al <b>portal de aliados</b>
        </h1>
        <p style={{ margin: '0 0 32px 0' }}>
          Para iniciar sesión ingresa tus datos
        </p>
        {this.state.warningCaps && this.renderCapsLockWarning()}
        <form
          name="form"
          onSubmit={handleSubmit(email, password)}
          className={classes.loginForm}
        >
          <TextField
            variant="outlined"
            id="username-input"
            label="Correo o Usuario"
            name="email"
            fullWidth
            className={classes.emailInput}
            InputProps={{
              startAdornment: <PersonOutlineRounded color="action" />
            }}
            value={email}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            id="password-input"
            label="Contraseña"
            name="password"
            fullWidth
            className={classes.passwordInput}
            type={passwordVisible ? 'text' : 'password'}
            InputProps={{
              startAdornment: <LockOutlined color="action" />,
              endAdornment: (
                <IconButton onClick={this.togglePasswordVisibility}>
                  {passwordVisible ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              )
            }}
            value={password}
            onChange={this.handleChange}
          />
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              className={classes.loginButton}
              disabled={
                loading || loadingRecaptchaInvisible || !email || !password
              }
            >
              Iniciar Sesión{' '}
              <ArrowForwardRounded
                style={{ position: 'relative', left: '8px' }}
              />
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
        <div className={classes.recoverPasswordWrapper}>
          <Link to="/reset-password" className={classes.recoverPasswordLink}>
            Olvidé mi contraseña
          </Link>
        </div>
        {isRecaptchaValidationActive && (
          <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={sitekey} />
        )}
      </Grid>
    );
  }
}

export default withStyles(
  combineStyles(styles, LoginPageStyles, ButtonStyles, TypographyStyles)
)(LoginForm);
