import ArticleList from './FinancialDetailsList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchDetailedPlans,
  createFinancialPlanDetail,
  updateFinancialPlanDetail
} from '../../../actions/financial_plan_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';

function mapStateToProps(state) {
  return {
    canUpdateFinancingPlanToCategories:
      state.authentication.currentUser.canUpdateFinancingPlanToCategories,
    canCreateFinancingPlanToCategories:
      state.authentication.currentUser.canCreateFinancingPlanToCategories,
    currentUser: state.authentication.currentUser,
    detailedPlans: state.financialPlans.detailedPlans,
    meta: state.financialPlans.detailedMeta,
    financialPlanNames: state.globalSetting.orgData.financial_plans,
    categoryNames: state.globalSetting.orgData.categories,
    contractTypeNames: state.globalSetting.orgData.contract_types,
    locationNames: state.globalSetting.orgData.geographic_locations,
    saleChannelNames: state.globalSetting.orgData.sale_channels,
    stratumNames: state.globalSetting.orgData.contract_strata,
    detailedPlanSaved: state.financialPlans.saved,
    detailedPlansLoading: state.requestState.requestFinancialDetailsSend,
    orgDataLoading: state.requestState.requestOrgDataSend,
    requestFinancialDetailsUpdateSend:
      state.requestState.requestFinancialDetailsUpdateSend,
    requestErrorsOnFinancialDetailsUpdate:
      state.requestState.requestErrorsOnFinancialDetailsUpdate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDetailedPlans: bindActionCreators(fetchDetailedPlans, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    createFinancialPlanDetail: bindActionCreators(
      createFinancialPlanDetail,
      dispatch
    ),
    updateFinancialPlanDetail: bindActionCreators(
      updateFinancialPlanDetail,
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ArticleList);
