import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog from '../../shared/Dialogs/CustomDialog';
import { history } from '../../../helpers/history';

class RemoteTeleshoppingErrors extends React.PureComponent {
  _closeBaseError = () => {
    const errorText = this.props.baseErrorText;
    this.props.toggleModal(false, errorText);
    this.props.toggleModalTS(false, errorText);
    if (this.props.baseErrorRoute) {
      history.push(this.props.baseErrorRoute);
    }
  };

  render() {
    const {
      server_errors = {},
      baseErrorOpen,
      baseErrorText,
      baseErrorTitle = 'Televentas'
    } = this.props;
    return (
      <CustomDialog
        open={baseErrorOpen}
        disableBackdropClick={true}
        onConfirm={this._closeBaseError}
        onClose={this._closeBaseError}
        title={`Error: ${baseErrorTitle}`}
        buttonText={`CONTINUAR`}
      >
        <p>{baseErrorText || server_errors.base}</p>
      </CustomDialog>
    );
  }
}

RemoteTeleshoppingErrors.propTypes = {
  server_errors: PropTypes.object,
  baseErrorOpen: PropTypes.bool,
  baseErrorText: PropTypes.string,
  toggleModal: PropTypes.func
};
export default RemoteTeleshoppingErrors;
