import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _every from 'lodash/every';
import _get from 'lodash/get';
import { Grid, TextField, withStyles } from '@material-ui/core';
import CustomDialog from '../../shared/Modals/CustomDialog';
import { QUOTA_ACTION_OPTIONS } from '../../../constants/enums';
import SimpleTable from '../../shared/Tables/SimpleTable';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
import { mapKeysDeepTo } from '../../../helpers/mappings/map';

import Movements from '../../shared/Movements/Movements';
import MovementComments from '../../shared/Movements/MovementComments';

class QuotaTransferRevision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      revoked: null
    };
  }

  _handleSave = revoked => {
    const { saleId, quotaTransfers } = this.props;
    const { comment } = this.state;
    this.setState({ revoked: revoked });
    const testBody = {
      quota_transfer: {
        comment: comment
      },
      id: quotaTransfers[0].id,
      sale_id: saleId
    };
    const action = revoked
      ? 'revoke'
      : quotaTransfers[0].status == 'reviewed'
      ? 'approve'
      : 'review';

    this.props.quotaTransferRevision(testBody, action).then(r => {
      if (r.success) {
        this.props.reFetch();
        this._handleClose();
      }
    });
  };

  _handleClose = () => {
    this.setState({ comment: '', revoked: null });
    this.props.onClose();
  };

  _handleChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  _getContracts = (quotaTransfers, sale) => {
    const transfers = [];
    const sale_mapped = mapKeysDeepTo(sale, 'snake');
    quotaTransfers.forEach((quota, idx) => {
      const quota_mapped = mapKeysDeepTo(quota, 'snake');
      transfers.push({
        key: idx,
        suppplier: _get(sale_mapped, 'supplier.name', '') || '--',
        contract_receives:
          _get(sale_mapped, 'contract.external_code', '') ||
          _get(sale_mapped, 'debtor_contract.id', '') ||
          '--',
        contract_gives:
          _get(quota_mapped, 'contract.external_code', '') || '--',
        value: _moneyFormat(quota_mapped.value) || '--'
      });
    });
    return transfers;
  };

  _showComment = () => {
    const { quotaTransfers } = this.props;
    const status = quotaTransfers[0].status;
    if (status == 'registered' || status == 'reviewed') return true;
    return false;
  };

  _modalProps = quotaTransfers => {
    const status = quotaTransfers[0].status;
    if (status == 'approved' || status == 'revoked') {
      return {
        onConfirm: () => null
      };
    }
    return {
      onConfirm: () => this._handleSave(false),
      buttonText: QUOTA_ACTION_OPTIONS[quotaTransfers[0].status].action,
      buttonSecondaryText: this.props.canRevokeQuotaTransfer
        ? 'RECHAZAR'
        : null,
      onConfirmSecondary: () => this._handleSave(true)
    };
  };

  _getMovements = () => {
    const { quotaTransfers, item } = this.props;
    const movements = quotaTransfers[0].movements;
    return [
      {
        status: 'registered',
        created_at: item.registered_at || item.registeredAt || '--',
        user: mapKeysDeepTo(item.registerer, 'snake')
      },
      ...movements
    ];
  };

  _disableSubmit = () => {
    const cantBeEmpty = {
      ...this.state,
      revoked: 'no-check'
    };
    if (!_every(cantBeEmpty, e => !!e)) return true;
    return false;
  };

  render() {
    const { classes, quotaTransfers, item, requestSend } = this.props;
    const { comment, revoked } = this.state;
    if (quotaTransfers.length == 0) return null;
    if (!QUOTA_ACTION_OPTIONS[quotaTransfers[0].status]) return null;
    const type =
      quotaTransfers[0].transferType == 'union' ||
      quotaTransfers[0].transfer_type == 'union'
    ? 'Unión' : 'Traslado';
    const movements_default = this._getMovements();
    return (
      <div>
        <CustomDialog
          open={true}
          loading={requestSend && !revoked}
          loadingSecondary={requestSend && revoked}
          disabled={requestSend || this._disableSubmit()}
          disableBackdropClick={false}
          headerSecondary={<div />}
          onClose={this._handleClose}
          title={`Solicitud de ${type} de Cupo`}
          {...this._modalProps(quotaTransfers)}
          classes={{
            root: classes.dialogRoot
          }}
        >
          <Grid container spacing={16}>
            {quotaTransfers[0].movements && (
              <Movements movements={movements_default} />
            )}
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  {
                    name: 'suppplier',
                    title: 'Aliado'
                  },
                  {
                    name: 'contract_gives',
                    title: 'Contrato cede:'
                  },
                  {
                    name: 'contract_receives',
                    title: 'Contrato recibe:'
                  },
                  {
                    name: 'value',
                    title: 'Valor'
                  }
                ]}
                rows={this._getContracts(quotaTransfers, item)}
              />
            </Grid>
            <div className={classes.textContainer}>
              {quotaTransfers[0].movements && (
                <MovementComments movements={movements_default} />
              )}
            </div>
            {this._showComment() ? (
              <Grid item xs={12}>
                <TextField
                  label="Agregar Comentario*"
                  name="comment"
                  margin="normal"
                  variant="outlined"
                  onChange={this._handleChange}
                  value={comment}
                  disabled={requestSend}
                  fullWidth
                  multiline={true}
                  rows={2}
                  inputProps={{ maxLength: 199 }}
                />
              </Grid>
            ) : (
                <div />
              )}
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = theme => ({
  dialogRoot: {
    width: 800
  },
  topInput: {
    marginTop: theme.spacing.unit * 2
  },
  subText: {
    color: theme.typography.color.light,
    paddingLeft: theme.spacing.unit / 2,
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px'
  },
  titleContainer: {
    color: theme.typography.color.main,
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingTop: '6px',
    margin: `0px ${theme.spacing.unit * 3}px `
  },
  textContainer:{
    display: 'block'
  }
});

QuotaTransferRevision.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(QuotaTransferRevision);
