import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import { columns } from './ArticlesTableMetadata';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '../../../shared/Table/Table';
import MUITableCell from '@material-ui/core/TableCell';
import { _moneyFormat } from '../../../../helpers/utilityFunctions';

import combineStyles from '../../../../helpers/combineStyles';
import TypographyStyles from '../../../../styles/typography';
import SpacingStyles from '../../../../styles/helpers/spacing';
import ArticlesTableStyles from './ArticlesTable.styles';

export class ArticlesTable extends Component {
  static propTypes = {
    rows: PropTypes.array.isRequired
  };

  _getDevolutionTotal = (rows, saleTotal) => {
    let total = 0
    const devolutions = rows.filter(row => row.prettyStatus == 'Anulado' || row.prettyStatus == 'Devuelto')
    devolutions.forEach(item => {
      total += parseInt(item.total.replace(/\$/g, '').replace(/\./g, ''))
    })
    return (parseInt(saleTotal) - total) + ".0"
  }

  _hasDevolution = rows => {
    if (!rows) return false
    return rows.filter(row => row.prettyStatus == 'Anulado' || row.prettyStatus == 'Devuelto').length == 0 ? false : true
  }

  _getCell = (props, classes) => {
    const { column, row } = props;
    const color = row.devolutions || row.prettyStatus == 'Anulado' || row.prettyStatus == 'Devuelto' ?
      classes.redTableCell
      : null
    if (row.totalRegister || row.devolutions) {
      if (column.name == 'articleName') {
        return (
          <React.Fragment key={`${row.id}.container`}>
            <MUITableCell
              key={`${row.id}.span`}
              classes={{
                root: classnames(classes.tableCellRoot),
                body: classnames(classes.tableCell, classes.body2, color)
              }}
              colSpan={4}
            >
            </MUITableCell>
            <MUITableCell
              key={`${row.id}.${column.name}`}
              classes={{
                root: classnames(classes.tableCellRoot),
                body: classnames(classes.tableCell, classes.body2, color)
              }}
              colSpan={2}
            >
              <strong>{_get(row, column.name) || ''}</strong>
            </MUITableCell>
          </React.Fragment>
        )
      }
      if (column.name == 'prettyStatus') {
        return (
          <MUITableCell
            key={`${row.id}.${column.name}`}
            classes={{
              root: classnames(classes.tableCellRoot),
              body: classnames(classes.tableCell, classes.body2, color)
            }}
            align="right"
          >
            <strong>{_get(row, column.name) || ''}</strong>
          </MUITableCell>
        )
      }
      return null
    }
    if (['articleName', 'prettyStatus'].includes(column.name)) {
      return (
        <Tooltip
          key={`${row.id}.${column.name}`}
          title={_get(row, column.name) || '--'}
          classes={{
            tooltip: classnames(classes.caption, classes.tooltip),
            popper: classes.tooltipPopper
          }}
        >
          <MUITableCell
            key={`${row.id}.${column.name}`}
            classes={{
              root: classnames(classes.tableCellRoot),
              body: classnames(
                classes.tableCell,
                classes.tableCellTooltip,
                classes.body2,
                color)
            }}
            data-label={column.title}
          >
            <span>{_get(row, column.name) || '--'}</span>
          </MUITableCell>
        </Tooltip>
      );
    }
    return (
      <MUITableCell
        key={`${row.id}.${column.name}`}
        classes={{
          root: classnames(classes.tableCellRoot),
          body: classnames(classes.tableCell, classes.body2, color)
        }}
        data-label={column.title}
      >
        {_get(row, column.name) || '--'}
      </MUITableCell>
    );
  };

  _totalRows = rows => {
    return this._hasDevolution(rows) ? [
      ...rows,
      {
        id: -1,
        totalRegister: true,
        articleName: 'Valor total de la venta:',
        prettyStatus: _moneyFormat(this.props.total)
      },
      {
        id: -2,
        devolutions: true,
        articleName: 'Valor total después de devoluciones:',
        prettyStatus: _moneyFormat(this._getDevolutionTotal(this.props.rows, this.props.total))
      }
    ]
    : [
      ...rows,
      {
        id: -1,
        totalRegister: true,
        articleName: 'Valor total de la venta:',
        prettyStatus: _moneyFormat(this.props.total)
      }
    ]
  }

  render() {
    const { classes, rows, title } = this.props;
    const newRows = this._totalRows(rows)
    return (
      <div>
        <h2 className={classnames(classes.title)}>
          {title}
        </h2>
        <Table
          columns={columns}
          data={newRows}
          withPagination={false}
          withColumnChooser={false}
          withToolbar={false}
          cellComponent={props => this._getCell(props, classes)}
          loading={false}
        />
      </div>
    );
  }
}

export default withStyles(
  combineStyles(ArticlesTableStyles, TypographyStyles, SpacingStyles)
)(ArticlesTable);
