import { geographicLocation } from "../../../reducers/geographic_location_reducer";

export const columns = [
  {
    title: 'Proveedor',
    name: 'supplierName'
  },
  {
    title: 'Categoría',
    name: 'categoryName'
  },
  {
    title: 'zona geográfica',
    name: 'geographicLocationName'
  }
];

export const mapData = data => (
  data.map(datum => {
    return {
      id: datum.id,
      supplierName: datum.supplier ? datum.supplier.name : '--',
      categoryName: datum.category ? datum.category.name : '--',
      geographicLocationName: datum.geographic_location ? datum.geographic_location.description : '--',
      supplierId: datum.supplier ? datum.supplier.id : '',
      categoryId: datum.category ? datum.category.id : '',
      geographicLocationId: datum.geographic_location ? datum.geographic_location.id : ''
    };
  })
);