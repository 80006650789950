import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import _compact from 'lodash/compact';
import _remove from 'lodash/remove';
import _findIndex from 'lodash/findIndex';

import ActivityIndicator from '../../shared/ActivityIndicator';
import { history } from '../../../helpers/history';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import CorporationProfile from './Profile';
import Suppliers from './Suppliers';

class CorporationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const permissions = this._getPermissions();
    this.setState({
      ...this.state,
      ...permissions
    });
    this.fetchDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchDetails();
    }
  }

  fetchDetails = () => {
    this.props.fetchCorporationInfo(this.props.match.params.id);
  };

  _toSupplier = id => {
    history.push(`/suppliers/${id}`);
  };

  _getPermissions = () => {
    return {
      canViewSuppliers:
        _findIndex(
          this.props.currentUser.permissions,
          p => p.action === 'view_suppliers'
        ) >= 0
    };
  };

  render() {
    const {
      classes,
      match,
      corporation = {},
      corporationLoading = true,
    } = this.props;

    const { canViewSuppliers } = this.state;

    if (!corporation) {
      return <ActivityIndicator />;
    }

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1
            className={classnames(
              classes.headline4,
              classes.noMarginBottom,
              classes.title
            )}
          >
            {`Empresa #${match.params.id}`}
          </h1>
        </div>
        <Grid container spacing={24}>
          <Grid item xs={12} lg={6}>
            <CorporationProfile
              corporation={corporation}
              isLoading={corporationLoading}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Suppliers
              canViewSuppliers={canViewSuppliers}
              suppliers={corporation.suppliers}
              isLoading={corporationLoading}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

CorporationDetail.propTypes = {
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.array,
  newAlert: PropTypes.func
};

export default withStyles(
  combineStyles(LayoutStyles, ButtonStyles, TypographyStyles, SpacingStyles)
)(CorporationDetail);
