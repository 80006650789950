import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import formatMoney from '../../../helpers/formatMoney';

import CustomDialog from '../../shared/Modals/CustomDialog';

class SkipValidateInvoiceModal extends PureComponent {
  render() {
    const { isPresent, saleCap, handleClose, handleConfirm } = this.props;

    return (
      <div>
        <CustomDialog
          open={isPresent}
          confirmDialog={true}
          title={'Omitir validación de facturas'}
          buttonText={'Omitir Paso'}
          onClose={handleClose}
          onConfirm={handleConfirm}
        >
          <p>{`Sólo puedes omitir la validación de facturas si el valor de la venta es inferior a ${formatMoney(
            saleCap
          )}`}</p>
        </CustomDialog>
      </div>
    );
  }
}
const styles = theme => ({
  container: {},
  segmentHeader: {
    marginTop: 3 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  segmentHeaderOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f5f5f5'
  },
  iconButtonRoot: {
    padding: 0,
    marginLeft: '2px'
  },
  segmentHeader2: {
    marginTop: '1em',
    marginBottom: '1.5em',
    padding: 2
  },
  gridItem: {
    paddingTop: '0 !important',
    paddingBottom: '12 !important'
  },
  table: {
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 3 * theme.spacing.unit
  },
  actionBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  question: {
    marginTop: 0,
    marginBottom: '15px',
    color: '#000000',
    opacity: 0.8,
    textTransform: 'uppercase'
  },
  mainButton: {
    alignSelf: 'flex-end',
    marginTop: 4 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  registerDateContainer: {
    marginTop: theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit
  },
  subtitle: {
    color: 'rgba(12,31,44,0.6)',
    fontSize: 15,
    textAlign: 'center',
    margin: 0,
    padding: 24,
    paddingTop: 20,
    paddingBottom: 0
  },
  dialog: {
    width: '80%'
  },
  dialogButton: {
    margin: 1 * theme.spacing.unit,
    marginTop: 0,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  button: {
    margin: 8,
    padding: '8px 16px'
  }
});

SkipValidateInvoiceModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isPresent: PropTypes.bool.isRequired,
  saleCap: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
};

export default withStyles(styles)(SkipValidateInvoiceModal);
