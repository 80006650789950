import {
    SET_EXCEPTION_CONFIGURATION_LIST_DATA,
    OPEN_EXCEPTION_CONFIGURATION_FORM_MODAL,
    CLOSE_EXCEPTION_CONFIGURATION_FORM_MODAL
  } from '../constants/location_exception_configurations_constants';
  
  const initialState = {
    configs: [],
    meta: {},
    formModalIsOpen: false
  };
  
  export function exceptionConfigurations(state = initialState, action) {
    switch (action.type) {
      case SET_EXCEPTION_CONFIGURATION_LIST_DATA:
        return {
          ...state,
          configs: action.data,
          meta: {
            totalCount: action.meta.totalCount,
            currentPage: action.meta.currentPage
          }
        };
      case OPEN_EXCEPTION_CONFIGURATION_FORM_MODAL:
        return {
          ...state,
          formModalIsOpen: true
        };
      case CLOSE_EXCEPTION_CONFIGURATION_FORM_MODAL:
        return {
          ...state,
          formModalIsOpen: false
        };
      default: {
        return state;
      }
    }
  };