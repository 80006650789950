import React, { PureComponent, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';
import { compose } from 'redux';
import List from '@material-ui/core/List';
import Chip from '@material-ui/core/Chip';
import ContactListItem from '../../shared/ListItem/ContactListItem';
import Divider from '@material-ui/core/Divider';
import TypographyStyles from '../../../styles/typography';
import { history } from '../../../helpers/history';

class SellersList extends PureComponent {
  _handleClick = (id, roles, freelanceRoles, coordinatorRoles, asesorRoles) => {
    if (this.props.canViewUsers && 
      roles.some(role => freelanceRoles.concat(coordinatorRoles).concat(asesorRoles).includes(role.name))) {
      history.push(`/users/${id}`);
    }
  };

  _isApproved = (pointOfSaleId, associations) => {
    let approved = false;
    associations.map(association => {
      if (association.pointOfSaleId == pointOfSaleId && association.status == "approved") {
        approved = true;
      }
    });
    return approved
  };

  render() {
    const { classes, sellers, pointOfSaleId, freelanceRoles, coordinatorRoles, asesorRoles} = this.props;
    return (
      <List classes={{ root: classes.list }}>
        {sellers.map((seller, i) => (
          <Fragment key={seller.id}>
            <ContactListItem
              photo={seller.photo}
              heading={`${seller.firstName} ${seller.lastName}`.replace(
                /_/,
                ' '
              )}
              onClick={() => this._handleClick(seller.id, seller.roles, freelanceRoles, coordinatorRoles, asesorRoles)}
            >
              <Fragment>
                {!this._isApproved(pointOfSaleId, seller.association) ? (
                  <div>
                    {`@${seller.username}`}
                    <br />
                    {seller.email}
                    <br />
                    {
                      seller.roles.length > 0 ? (
                        <Chip
                          variant="outlined"
                          size="small"
                          label={seller.roles[0].displayName}
                          classes={{
                            root: classes.root_label_primary,
                            label: classes.label
                          }}
                        />
                      ) : (
                          <Chip
                            variant="outlined"
                            size="small"
                            label="Sin rol"
                            classes={{
                              root: classes.root_label_primary,
                              label: classes.label
                            }}
                          />)
                    }
                  </div>
                ) : (
                    <div>
                      {`@${seller.username}`}
                      <br />
                      {seller.email}
                      <br />
                      {
                        seller.roles.length > 0 ? (
                          <Chip
                            variant="outlined"
                            size="small"
                            label={seller.roles[0].displayName}
                            color="secondary"
                            classes={{
                              root: classes.root_label_secondary,
                              label: classes.label
                            }}
                          />
                        ) : (
                            <Chip
                              variant="outlined"
                              size="small"
                              label="Sin rol"
                              color="secondary"
                              classes={{
                                root: classes.root_label_secondary,
                                label: classes.label
                              }}
                            />)
                      }
                    </div>
                  )}
              </Fragment>
            </ContactListItem>
            {i < sellers.length - 1 && <Divider component="li" />}
          </Fragment>
        ))}
      </List>
    );
  }
}

const styles = theme => ({
  list: {
    width: '100%',
    padding: '0 !important',
    backgroundColor: theme.palette.background.paper
  },
  root_label_primary: {
    color: 'rgba(12,31,44,0.38)',
    height: 24,
    'font-size': 12,
    'margin-top': 4
  },
  root_label_secondary: {
    height: 24,
    'font-size': 12,
    'margin-top': 4
  },
  label: {
    'padding-left': 6,
    'padding-right': 6
  }
});

export default compose(
  withStyles(combineStyles(styles, TypographyStyles), { withTheme: true })
)(SellersList);
