import {
  LIST_PREVIEW_ANNOUNCEMENTS,
  MODAL_CONTENT,
  LIST_NOTIFICATIONS
} from '../constants/announcements_constants';
import { ANNOUNCEMENT_TOGGLE_ACTION } from '../constants/action_constants';
import { sendRequest, completeRequest } from './request_state';
import RequestService from '../services/request_service';
import { serverStatusError } from './alert_actions';

const requestService = new RequestService();
const requestServiceNode = new RequestService('node');

function setPreviewAnnouncements(data) {
  return {
    type: LIST_PREVIEW_ANNOUNCEMENTS,
    data
  };
}

function setModalContent(data) {
  return {
    type: MODAL_CONTENT,
    data
  };
}

function setModal(action, open) {
  return {
    type: ANNOUNCEMENT_TOGGLE_ACTION,
    action: action,
    open: open
  };
}

export function toggleAnnouncementAction(action, open) {
  return dispatch => {
    dispatch(setModal(action, open));
  };
}

export const fetchAnnouncements = () => async dispatch => {
  dispatch(sendRequest('Announcements'));
  try {
    const payload = await requestServiceNode.get('announcements', { page: 1, perPage: 15 });
    dispatch(setPreviewAnnouncements(payload.data));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('Announcements'));
  }
};

export const announcementMarkAsSeen = (id, seen) => async dispatch => {
  dispatch(sendRequest('Announcements'));
  try {
    await requestServiceNode.put(`announcements/${id}/`, { seen });
    dispatch(fetchAnnouncements());
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('Announcements'));
  }
};

export const fetchModalsImage = () => async dispatch => {
  dispatch(sendRequest('ModalsImage'));
  try {
    const payload = await requestServiceNode.get('announcements/preview-modal');
    dispatch(setModalContent(payload.data));
    return payload.data;
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('ModalsImage'));
  }
};
