import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  city: Yup.string().nullable()
    .when('canUpdatePOSLocationInfo', {
      is: true,
      then: Yup.string()
        .required('El campo es requerido')
        .test('city', 'Seleccione una ciudad.', val => val !== 'any_type')
      ,
    }),
  address: Yup.string().required('El campo es requerido'),
});
