import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { ArrowBackRounded, PersonOutlineRounded } from '@material-ui/icons';
import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';

class ResetPasswordForm extends Component {
  state = { email: '', sitekey: process.env.SITE_KEY_CAPTCHA_V2 };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const {
      classes,
      handleOnChangeCaptcha,
      loading,
      captcha,
      handleSubmit
    } = this.props;
    const { email, sitekey } = this.state;

    return (
      <Grid
        container
        className={classes.formContainer}
        direction="column"
        justify="center"
        alignItems="flex-start"
      >
        <Link to="/login" className={classes.loginLink}>
          <ArrowBackRounded style={{ marginRight: '8px' }} /> Volver al inicio
        </Link>

        <h1 style={{ color: 'black', margin: 0 }}>
          <strong>Recuperación de contraseña</strong>
        </h1>
        <p style={{ margin: '0 0 32px 0' }}>
          Para recuperar tu contraseña ingresa tus datos
        </p>

        <TextField
          variant="outlined"
          id="username-input"
          label="Correo o Usuario"
          name="email"
          fullWidth
          className={classes.emailInput}
          type="email"
          InputProps={{
            startAdornment: <PersonOutlineRounded color="action" />
          }}
          value={email}
          onChange={this.handleChange}
        />
        <ReCAPTCHA
          sitekey={sitekey}
          onChange={handleOnChangeCaptcha}
          style={{ marginBottom: '32px' }}
        />

        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.sendButton}
            disabled={loading || !captcha || !email}
            onClick={handleSubmit({ email })}
          >
            Enviar
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
    );
  }
}

export default ResetPasswordForm;
