import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ActivityIndicator from '../../shared/ActivityIndicator';
import moment from 'moment';
import { Grid } from '@material-ui/core';

import LineChart from '../../shared/Graphs/LineChart';
import DonutChart from '../../shared/Graphs/DonutChart';
import DonutLegend from '../../shared/Graphs/DonutLegend';
import TimespanSelector from '../../shared/Graphs/TimespanSelector';
import DashboardStyles from './Dashboard.styles';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';

import { YEAR } from '../../../constants/timespan';

import { _moneyFormat } from '../../../helpers/utilityFunctions';

import DashboardHeader from './Header';

class Dashboard extends Component {
  state = {
    sales: {
      date: moment().format('YYYY-MM-DD'),
      startDate: moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .endOf('year')
        .format('YYYY-MM-DD'),
      increment: YEAR
    },
    topRated: {
      date: moment().format('YYYY-MM-DD'),
      startDate: moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .endOf('year')
        .format('YYYY-MM-DD'),
      increment: YEAR
    }
  };

  componentDidMount() {
    const {
      canListDashboardSummaries,
      canListDashboardSales,
      canListDashboardTopRated
    } = this.props;
    canListDashboardSummaries && this.props.fetchSummary();
    canListDashboardSales && this._fetchSales();
    canListDashboardTopRated && this._fetchTopRated();
  }

  _handleDateChange = (graphName, date) => {
    const field = { ...this.state[graphName] };
    field.date = date.format('YYYY-MM-DD');
    field.startDate = moment(field.date)
      .startOf(field.increment)
      .format('YYYY-MM-DD');
    field.endDate = moment(field.startDate)
      .endOf(field.increment)
      .format('YYYY-MM-DD');
    this.setState({ [graphName]: field }, () => {
      if (graphName === 'sales') {
        this._fetchSales();
      } else {
        this._fetchTopRated();
      }
    });
  };

  _handleIncrementChange = (graphName, increment) => {
    const field = { ...this.state[graphName] };

    field.increment = increment;
    field.startDate = moment(field.date)
      .startOf(increment)
      .format('YYYY-MM-DD');
    field.endDate = moment(field.startDate)
      .endOf(increment)
      .format('YYYY-MM-DD');
    this.setState({ [graphName]: field }, () => {
      if (graphName === 'sales') {
        this._fetchSales();
      } else {
        this._fetchTopRated();
      }
    });
  };

  _fetchSales = () => {
    this.props.fetchSales({
      startAt: this.state.sales.startDate,
      endAt: this.state.sales.endDate,
      interval: this.state.sales.increment
    });
  };

  _fetchTopRated = () => {
    this.props.fetchTopRated({
      startAt: this.state.topRated.startDate,
      endAt: this.state.topRated.endDate,
      interval: this.state.topRated.increment
    });
  };

  render() {
    const {
      theme,
      classes,
      requestDashboardSummarySend,
      requestDashboardSalesSend,
      requestDashboardTopRatedSend,
      dataSummary,
      dataSales,
      dataTopRated,
      canListDashboardSummaries,
      canListDashboardSales,
      canListDashboardTopRated
    } = this.props;

    const { sales = {}, topRated = {} } = this.state;

    const donutColors = ['#FEB00B', '#0071D9', '#4EB2FA', '#B6DDFD', '#EBEEF1'];

    return (
      <Grid container spacing={16}>
        {canListDashboardSummaries && (
          <Grid item xs={12}>
            <DashboardHeader
              isLoading={requestDashboardSummarySend}
              data={dataSummary}
            />
          </Grid>
        )}
        {canListDashboardSales && (
          <Grid item xs={12} md={6}>
            <div
              className={classnames(classes.wrapper, classes.rowCardWrapper)}
            >
              {requestDashboardSalesSend && (
                <ActivityIndicator className={classes.loadingIndicator} />
              )}
              <h2 className={classnames(classes.headline7, classes.graphTitle)}>
                {'Ventas'}
              </h2>
              <TimespanSelector
                onIncrementChange={this._handleIncrementChange}
                increment={sales.increment}
                startDate={sales.startDate}
                date={sales.date}
                endDate={sales.endDate}
                graphName={'sales'}
                onDateChange={this._handleDateChange}
              />
              <div>
                <LineChart
                  data={dataSales.map(sale => sale.count)}
                  labels={dataSales.map(sale => {
                    return sales.increment === YEAR
                      ? moment(sale.date).format('YYYY-MM')
                      : sale.date;
                  })}
                  backgroundColor={theme.palette.primary.main}
                  borderColor={theme.palette.primary.main}
                  pointBackgroundColor={theme.palette.primary.main}
                  pointHoverBackgroundColor={theme.palette.primary.main}
                  pointHoverBorderColor={theme.palette.secondary.light}
                />
              </div>
            </div>
          </Grid>
        )}
        {canListDashboardTopRated && (
          <Grid item xs={12} md={6}>
            <div
              className={classnames(classes.wrapper, classes.rowCardWrapper)}
            >
              {requestDashboardTopRatedSend && (
                <ActivityIndicator className={classes.loadingIndicator} />
              )}
              <h2 className={classnames(classes.headline7, classes.graphTitle)}>
                {'Top Puntos de Venta'}
              </h2>
              <TimespanSelector
                onIncrementChange={this._handleIncrementChange}
                increment={topRated.increment}
                startDate={topRated.startDate}
                date={topRated.date}
                endDate={topRated.endDate}
                onDateChange={this._handleDateChange}
                graphName={'topRated'}
              />
              <div className={classes.donutGraph}>
                <div className={classes.donutGraphItems}>
                  <DonutChart
                    data={dataTopRated.map(topRated => topRated.sales)}
                    labels={dataTopRated.map(
                      topRated => topRated.point_of_sale
                    )}
                    colors={donutColors}
                    innerTitle={'Ventas'}
                    innerText={
                      dataTopRated.map(topRated => topRated.sales).length
                        ? parseInt(
                            dataTopRated
                              .map(topRated => topRated.sales)
                              .reduce(
                                (prev, next) => parseInt(prev) + parseInt(next)
                              )
                          )
                        : 0
                    }
                  />
                  <DonutChart
                    data={dataTopRated.map(topRated => topRated.sold)}
                    labels={dataTopRated.map(
                      topRated => topRated.point_of_sale
                    )}
                    colors={donutColors}
                    innerTitle={'Vendido'}
                    innerText={
                      dataTopRated.map(topRated => topRated.sold).length
                        ? _moneyFormat(
                            parseInt(
                              dataTopRated
                                .map(topRated => topRated.sold)
                                .reduce(
                                  (prev, next) =>
                                    parseInt(prev) + parseInt(next)
                                )
                            )
                          )
                        : 0
                    }
                    valueFormat={_moneyFormat}
                  />
                </div>
                <div className={classes.donutGraphLegends}>
                  <DonutLegend
                    labels={dataTopRated.map(
                      topRated => topRated.point_of_sale
                    )}
                    colors={donutColors}
                  />
                </div>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

Dashboard.propTypes = {
  // classes: PropTypes.object.isRequired,
  // history: PropTypes.object.isRequired,
  // location: PropTypes.object.isRequired,
  // fetchCorporations: PropTypes.func.isRequired,
  // corporations: PropTypes.array.isRequired,
  requestSend: PropTypes.bool
};

export default withStyles(combineStyles(DashboardStyles, LayoutStyles), {
  withTheme: true
})(Dashboard);
