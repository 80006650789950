import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const DeleteProposalConfirmationModal = props => {
  const { open, onClose, onSubmit, proposal } = props;
  const message = `Estas a punto de borrar la propuesta de ${proposal.companyName}`;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Borrar propuesta</DialogTitle>
      <DialogContent>
        <DialogContentText>
          { message }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={onSubmit} color="secondary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProposalConfirmationModal;