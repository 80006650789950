import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('El campo es requerido'),
  businessName: Yup.string().required('El campo es requerido'),
  nit: Yup.number().nullable().required('El campo es requerido').min(
    1, 'Ingresa un nit válido'
  ).test(
    'nitHasCorrectLength',
    'El nit no puede tener mas de 11 caracteres',
    function(value) {
      return !!value && value.toString().length <= 11;
    }
  ),
  companySeniority: Yup.string().required('El campo es requerido'),
  positionInCompany: Yup.string().required('El campo es requerido'),
  officeQuantity: Yup.number().nullable().required('El campo es requerido').min(1, 'Ingresa una cantidad válida'),
  lastYearSalesAverage: Yup.string().nullable().required('El campo es requerido'),
  penultimateYearSalesAverage: Yup.string().nullable().required('El campo es requerido'),
  minuteType: Yup.number().nullable().required('El campo es requerido'),
  commission: Yup.number().nullable().required('El campo es requerido').min(0, 'Ingresa un porcentaje válido').max(100, 'Ingresa un porcentaje válido'),
  legalRepresentativeName: Yup.string().nullable().required('El campo es requerido'),
  legalRepresentativeIdentificationType: Yup.string().nullable().required('El campo es requerido'),
  legalRepresentativeIdentification: Yup.number().nullable().required('El campo es requerido').min(1, 'Ingresa una identificación válida'),
  legalRepresentativeCity: Yup.string().nullable().required('El campo es requerido'),
  contactAddress: Yup.string().nullable().required('El campo es requerido'),
  contactCity: Yup.string().nullable().required('El campo es requerido'),
  contactPhone: Yup.number().nullable().required('El campo es requerido').min(
    1, 'Ingresa un teléfono válido'
  ).test(
    'contactPhoneHasCorrectLength',
    'El teléfono debe tener 7 o 10 dígitos',
    function(value) {
      return !!value && [7,10].includes(value.toString().length);
    }
  ),
  chamberOfCommerceCity: Yup.string().nullable().required('El campo es requerido'),
});