import _deburr from 'lodash/deburr';
import _get from 'lodash/get';
import Fuse from 'fuse.js';
import moment from 'moment-timezone';
import { streetType } from '../constants/enums';
import { camelCase, snakeCase } from 'lodash';

export function _punctuationTwoDecimals(number) {
  let price = number.toString();
  if (price.length < 1) price = '0' + price;
  if (price.length < 2) price = '0' + price;
  if (price.length < 3) price = '0' + price;
  const at = price.length - 2;
  price = price.substr(0, at) + '.' + price.substr(at);
  if (price.length > 6)
    price =
      price.substr(0, price.length - 6) + '.' + price.substr(price.length - 6);
  return price;
}

export function filterListFuzzyly(value, list, key, amount) {
  const inputValue = _deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  const slices = amount || 5;
  const options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [key]
  };
  const fuse = new Fuse(list, options);
  const result = fuse.search(inputValue);
  return inputLength === 0 ? [] : result.slice(0, slices);
}
export function _nameFormat(entity, pathFirstName, pathLastName) {
  if (!entity) return '--';
  const fullName = `${_get(entity, pathFirstName, '--')} ${_get(
    entity,
    pathLastName,
    ''
  )}`;
  return fullName;
}

export function _numberFormat(number) {
  if (number) {
    const n = Number.parseFloat(number);
    return n.toLocaleString('es');
  } else {
    return '-';
  }
}

export function _dateFormat(date) {
  if (date) {
    return date.split('T')[0];
  } else {
    return '-';
  }
}

export function _dateFormatBackSlash(date) {
  if (!date) return '-';
  const fechaNF = date.split('T')[0].split('-');
  const formated = fechaNF[0] + '/' + fechaNF[1] + '/' + fechaNF[2];
  return formated;
}

export function _moneyFormat(item) {
  let sumValues = 0;
  if (item) {
    sumValues = Number.parseFloat(item);
  }
  sumValues = '$' + sumValues.toLocaleString('es');
  return sumValues;
}

export function _parsePOSname(name) {
  let parsedName = name;
  if (name.includes('FNB -')) parsedName = name.replace('FNB -', '');
  return parsedName;
}

export function removeAccents(string) {
  const strAccents = string.split('');
  let strAccentsOut = new Array();
  const strAccentsLen = strAccents.length;
  const accents =
    'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut =
    'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
  for (let y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(strAccents[y]) != -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else strAccentsOut[y] = strAccents[y];
  }
  strAccentsOut = strAccentsOut.join('');
  return strAccentsOut;
}

export function valueFromComment(comment, key) {
  if (!comment) return '';
  const splitted = comment.split(`${key}:`)[1];
  if (!splitted) return '';
  const value = splitted.split(',')[0];
  return toCapitalize(value) || '';
}

export function toCapitalize(str) {
  if (!str) return '';
  const trimmed = str.trim();
  return trimmed.charAt(0).toUpperCase() + trimmed.slice(1).toLowerCase();
}

export function removeFirstZeros(text) {
  const regularExpression = /0*([0-9a-zA-Z]+)/;
  return regularExpression.exec(text)[1];
}

export function formatOccurrenceDate(date, format = 'YYYY-MM-DD[T]HH:mm:ss') {
  const HOURS_IN_A_DAY = 24;
  const formattedDate = moment(date, format).locale('es');
  const today = moment(new Date());
  const duration = moment.duration(today.diff(formattedDate));
  const hours = duration.asHours();
  if (hours < HOURS_IN_A_DAY) {
    return `${Number.parseInt(hours)}h`;
  } else {
    return formattedDate.format('LL');
  }
}

export function truncateText(text, max_characters) {
  return text.length > max_characters
    ? text.substring(0, max_characters - 3) + '...'
    : text;
}

/**
 * Formats the sale status for display
 * @param {String} saleStatus Sale status
 * @returns The status formatted for display
 */
export function formatSaleStatusLabel(saleStatus) {
  if (saleStatus === 'Pendiente_Entrega') {
    return 'Pendiente Entrega';
  } else if (saleStatus === 'Pendiente_Revision') {
    return 'Pendiente Revisión';
  }

  return saleStatus;
}

/**
 * Returns the id of the given street type.
 * @param {String} streetTypeName Street type name
 * @returns Id of the street type with name streetTypeInput
 */
export function getStreetTypeId(streetTypeName) {
  return streetType.filter(el => el.name === streetTypeName)[0].id;
}

/**
 * Returns the name of the given street type.
 * @param {Number} streetTypeId Street type id
 * @returns The name of the street type with id streetTypeId, empty string if it doesn't exist.
 */
export function getStreetTypeName(streetTypeId) {
  const streetTypeF = streetType.find(el => el.id === streetTypeId);
  return streetTypeF ? streetTypeF.name : '';
}

/**
 * Removes extra, trailing and leading spaces.
 * E.g. " This is    a  string.  " -> "This is a string."
 * @param {String} sentence
 * @returns Sentence without any extra, trailing and leading spaces.
 */
export function removeExtraSpaces(sentence) {
  return sentence.replace(/\s+/g, ' ').trim();
}

export const Cases = Object.freeze({
  snakeCase: "snake_case",
  camelCase: "camelCase"
});

/**
 * Turns the keys of the given object to the selected case. Including arrays and nested objects
 * @param {Object} obj The object to turn the keys to the selected case
 * @param {Cases} toCase The case to convert to
 * @returns A new object with all keys as the selected case
 */
export function convertCase(obj, toCase) {
  if ((typeof obj !== 'object' && !Array.isArray(obj)) || obj === null || typeof obj === "undefined") return obj;

  if (Array.isArray(obj)) {
    let newValue = [];
    for(let element of obj) {
      newValue.push(convertCase(element, toCase));
    }

    return newValue;
  }

  let newObj = {};
  const selectedCase = toCase === Cases.snakeCase ? snakeCase : camelCase;

  for(const [key, value] of Object.entries(obj)) {
    let newValue = value;
    if (typeof value === "object" || Array.isArray(value)) {
      newValue = convertCase(value, toCase);
    }

    newObj[selectedCase(key)] = newValue;
  }

  return newObj;
}
