import RequestService from '../services/request_service';
import { history } from '../helpers/history';
import {
  LIST_COMMISSIONS,
  SAVED_COMMISSION,
  DATA_TO_CREATE_COMMISSIONS
} from '../constants/action_constants';
import { newAlert, clearAlerts, serverStatusError } from './alert_actions';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { stringifyQuery } from '../helpers/parseQuerystring';
import commissionFiltersSanitizer from '../helpers/sanitizers/commissionFilters';
import dataToCreateCommissionsFiltersSanitizer from '../helpers/sanitizers/data_to_create_commissions';
import createCommissionSanitizer from '../helpers/sanitizers/createCommissionSanitizer';

const requestService = new RequestService();
const nodeRequestService = new RequestService('node');

function setCommissions(data, meta) {
  return {
    type: LIST_COMMISSIONS,
    data,
    meta
  };
}

function setFetchData(data) {
  return {
    type: DATA_TO_CREATE_COMMISSIONS,
    data
  };
}

function savedCommission() {
  return {
    type: SAVED_COMMISSION
  };
}

export const fetchCommissions = (params = {}) => async dispatch => {
  dispatch(sendRequest('FetchCommissions'));
  history.replace(`/commissions?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get(
      'commissions',
      commissionFiltersSanitizer(params),
      true
    );
    dispatch(setCommissions(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('FetchCommissions'));
  }
};

export const fetchInfoToCreate = (params = {}) => async dispatch => {
  dispatch(sendRequest('fetchInfoToCreate'));
  try {
    const payload = await requestService.get(
      'commissions/search_to_create',
      dataToCreateCommissionsFiltersSanitizer(params),
      true
    );
    dispatch(setFetchData(payload));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('fetchInfoToCreate'));
  }
};

export const createCommission = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('SaveCommission'));
  try {
    await nodeRequestService.post(
      'commissions/create',
      createCommissionSanitizer(params),
      {},
      true
    );
    dispatch(savedCommission());
    dispatch(clearAlerts());
    dispatch(fetchCommissions(fetchParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('SaveCommission', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('SaveCommission'));
  }
};

export const updateCommission = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('SaveCommission'));
  try {
    await requestService.put(
      `commissions/${params.commission.id}`,
      params,
      {},
      true
    );
    dispatch(savedCommission());
    dispatch(clearAlerts());
    dispatch(fetchCommissions(fetchParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base'][0]));
      } else {
        dispatch(errorOnRequest('SaveCommission', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('SaveCommission'));
  }
};
