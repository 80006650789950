import * as Yup from 'yup';

export const validationSchema = (context = {}) => {
  const { validateWithOneInvoice, isGnp } = context;

  if (validateWithOneInvoice || isGnp) {
    return Yup.object().shape({
      isGnpSale: Yup.boolean(),
      invoice1: Yup.number().required('El campo es requerido'),
      invoice1Date: Yup.string()
        .required('El campo es requerido')
        .nullable()
    });
  } else {
    return Yup.object().shape({
      isGnpSale: Yup.boolean(),
      invoice1: Yup.number().required('El campo es requerido'),
      invoice2: Yup.number().required('El campo es requerido'),
      invoice1Date: Yup.string()
        .required('El campo es requerido')
        .nullable(),
      invoice2Date: Yup.string()
        .required('El campo es requerido')
        .nullable()
    });
  }
};
