import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import Grid from '@material-ui/core/Grid';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import SpacingStyles from '../../../styles/helpers/spacing';
import TypographyStyles from '../../../styles/typography';
import ClientInfoStyles from './__styles__/ClientInfo.styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';

export class ClientInfo extends React.Component {
  render() {
    const { classes, serviceDetail } = this.props;
    return (
      <section className={classes.headerRoot}>
        <div className={classes.clientDetails}>
          <h1 className={classes.headline8}>{'Información de cliente'}</h1>
        </div>
        <div className={classes.details}>
          <Grid container spacing={16} className={classes.contactData}>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{'Nombre:'}</p>
              <p className={classes.body2}>
                {`${serviceDetail.firstName} ${serviceDetail.lastName}` || '--'}
              </p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{'Tipo de identificación:'}</p>
              <p className={classes.body2}>
                {serviceDetail.typeOfIdentification || '--'}
              </p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{`${'Identificación'}:`}</p>
              <p className={classes.body2}>
                {serviceDetail.identification || '--'}
              </p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{`${'Fecha de nacimiento'}:`}</p>
              <p className={classes.body2}>{serviceDetail.birthDate || '--'}</p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{`${'Fecha de expedición'}:`}</p>
              <p className={classes.body2}>
                {serviceDetail.expeditionDate || '--'}
              </p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{`${'Correo electrónico'}:`}</p>
              <p className={classes.body2}>{serviceDetail.email || '--'}</p>
            </Grid>
            <Grid item lg={6}>
              <p className={classes.subtitle3}>{`${'Número de teléfono'}:`}</p>
              <p className={classes.body2}>{serviceDetail.phone || '--'}</p>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  }
}

export default withStyles(
  combineStyles(ClientInfoStyles, ButtonStyles, TypographyStyles)
)(ClientInfo);
