import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _compact from 'lodash/compact';
import Button from '@material-ui/core/Button';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import ProgressButton from '../../shared/Buttons/ProgressButton';

import Avatar from './Avatar';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import ProfileStyles from './__styles__/Profile.styles';

export const Profile = ({
  classes,
  isUpdatingUserProfile,
  isChangingPassword,
  user,
  canUpdateUsers,
  onChangePassword,
  onActivateUser,
  canUpdateUserPassword,
  onDeactivateUser,
  onEditProfile,
  isChangingStatus,
  canUpdateRoles,
  canResendConfirmationEmail,
  canUnblockUsers,
  isChangingResendConfirmationEmail,
  onResendConfirmationEmail,
  canUpdatePictureProfile,
  onEditProfilePicture,
  isUpdatingPictureProfile,
  OpenDeleteProfilePictureDialog,
  sendResetPassword,
  canEditBlackList,
  isBlackListActive,
  handleBlockModalConfirmation,
  handleToggleResetPasswordModal
}) => {
  const { passwordUpdateInfo } = user;
  return (
    <FormItemContainer
      title={'Datos Personales'}
      secondaryAction={
        !isUpdatingUserProfile && (user.isCurrentUser || canUpdateUsers) ? (
          <Button
            size={'small'}
            color={'secondary'}
            className={classnames(classes.smallButton, classes.actionButtons)}
            classes={{
              label: classes.buttonLabel
            }}
            onClick={onEditProfile}
          >
            {'Editar'}
          </Button>
        ) : null
      }
    >
      {isUpdatingUserProfile ? (
        <LoadingIndicator />
      ) : (
        <div className={classes.root}>
          <Avatar
            OpenDeleteProfilePictureDialog={OpenDeleteProfilePictureDialog}
            isUpdatingPictureProfile={isUpdatingPictureProfile}
            onEditProfilePicture={onEditProfilePicture}
            canUpdatePictureProfile={canUpdatePictureProfile}
            user={user}
          />
          <div className={classes.details}>
            <p className={classnames(classes.headline8, classes.userName)}>
              {_compact([user.firstName, user.lastName]).join(' ')}
            </p>
            <p className={classes.body2}>
              {user.email}, <em className={classes.body2}>@{user.username}</em>
            </p>
            <p className={classes.caption}>
              {user.supplier && user.supplier.name}
            </p>
            <p className={classes.caption}>
              {user.generic && 'Usuario genérico'}
            </p>

            <p className={classes.body2}>
              {user.hasBiometricReader && 'Usuario con lector biométrico'}
            </p>

            <p className={classes.body2}>
              {user.sendNotifications
                ? 'Este usuario recibe agendamientos'
                : 'Este usuario no recibe agendamientos'}
            </p>
            {passwordUpdateInfo.notify && (
              <p className={classes.body2}>
                {passwordUpdateInfo.dayLeft == 0
                  ? 'Su contraseña actual ha expirado'
                  : `Su contraseña vence en ${passwordUpdateInfo.dayLeft} ${
                      passwordUpdateInfo.dayLeft > 1 ? 'días' : 'día'
                    }`}
              </p>
            )}

            <div className={classes.actionContainer}>
              {canUpdateUserPassword && user.isCurrentUser && (
                <ProgressButton
                  className={classnames(classes.button, classes.action)}
                  classes={{
                    label: classes.buttonLabel
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={onChangePassword}
                  isSubmitting={isChangingPassword}
                >
                  {'Cambiar Contraseña'}
                </ProgressButton>
              )}
              {canResendConfirmationEmail && !user.emailConfirmedAt && (
                <ProgressButton
                  className={classnames(classes.button, classes.action)}
                  classes={{
                    label: classes.buttonLabel
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={onResendConfirmationEmail}
                  isSubmitting={isChangingResendConfirmationEmail}
                >
                  {'Reenviar correo de activacion'}
                </ProgressButton>
              )}
              {canUpdateRoles && (
                <ProgressButton
                  className={classnames(classes.button, classes.action)}
                  classes={{
                    label: classes.buttonLabel
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={!user.active ? onActivateUser : onDeactivateUser}
                  isSubmitting={isChangingStatus}
                >
                  {!user.active ? 'Activar' : 'Desactivar'}
                </ProgressButton>
              )}

              {!user.isCurrentUser &&
                canUnblockUsers && (
                  <ProgressButton
                    className={classnames(classes.button, classes.action)}
                    classes={{
                      label: classes.buttonLabel
                    }}
                    variant="outlined"
                    color="secondary"
                    onClick={handleToggleResetPasswordModal}
                  >
                    {'Desbloquear Usuario'}
                  </ProgressButton>
                )}

              {!user.isCurrentUser && canEditBlackList && isBlackListActive && (
                <ProgressButton
                  className={classnames(classes.button, classes.action)}
                  classes={{
                    label: classes.buttonLabel
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={handleBlockModalConfirmation}
                >
                  {!user.blocked
                    ? 'Añadir a la lista negra'
                    : 'Remover de la lista negra'}
                </ProgressButton>
              )}
            </div>
          </div>
        </div>
      )}
    </FormItemContainer>
  );
};

export default withStyles(
  combineStyles(ProfileStyles, ButtonStyles, TypographyStyles)
)(Profile);
