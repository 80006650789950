import {
  ARTICLES_SET,
  ARTICLES_INFO_SET,
  LIST_CONCEPTS,
  SAVED_ARTICLE
} from '../constants/action_constants';
import { newAlert, clearAlerts, serverStatusError } from './alert_actions';
import RequestService from '../services/request_service';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { history } from '../helpers/history';
import {
  articleFilterSanitizer,
  articleFilterSanitizerNode,
  articleSaveSanitizer
} from '../helpers/sanitizers/articleSanitizer';
import AxiosRequest from '../services/axios_request';
import MIMETYPES from '../constants/mimetypes';
import snakecaseKeys from 'snakecase-keys';

const requestService = new RequestService();
const requestNodeService = new RequestService('node');

//* PURE ACTION FUNCTIONS
function success(items, pagi) {
  return {
    type: ARTICLES_SET,
    data: items,
    meta: pagi
  };
}
function setArticleInfo(info) {
  return {
    type: ARTICLES_INFO_SET,
    data: info
  };
}
function setConceptDescriptions(descriptions) {
  return {
    type: LIST_CONCEPTS,
    data: descriptions
  };
}
function savedArticle() {
  return {
    type: SAVED_ARTICLE
  };
}

//* VIEW ACTION FUNCTIONS
export const fetchArticles = (params = {}) => async dispatch => {
  dispatch(sendRequest('ArticleList'));
  history.replace(`/articles?${stringifyQuery(params, false)}`);
  try {
    const payload = await requestService.get(
      'articles',
      articleFilterSanitizer(params)
    );
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
  } finally {
    dispatch(completeRequest('ArticleList'));
  }
};

export const fetchArticlesNode = (params = {}) => async dispatch => {
  dispatch(sendRequest('ArticleList'));
  try {
    const payload = await requestNodeService.get(
      'articles',
      articleFilterSanitizerNode(params)
    );
    const snakecasePayload = snakecaseKeys(payload);
    dispatch(success(snakecasePayload.data, snakecasePayload.meta));
    return payload;
  } catch (errors) {
  } finally {
    dispatch(completeRequest('ArticleList'));
  }
};

export const fetchConceptDescriptions = (params = {}) => async dispatch => {
  dispatch(sendRequest('ConceptList'));
  try {
    const payload = await requestService.get('concepts');
    dispatch(setConceptDescriptions(payload.data));
  } catch (errors) {
  } finally {
    dispatch(completeRequest('ConceptList'));
  }
};

export function fetchArticleInfo(id) {
  return dispatch => {
    return requestService.fetch(`articles/${id}`).then(
      payload => {
        dispatch(setArticleInfo(payload.data));
      },
      error => {
        error.json().then(e => {
          e.data &&
            e.data.message &&
            dispatch(newAlert('error', 'ERROR:', e.data.message));
          e.error &&
            e.error.message &&
            dispatch(newAlert('error', 'ERROR:', e.error.message));
          e.errors &&
            e.errors.title &&
            dispatch(newAlert('error', 'ERROR:', e.errors.title));
          e.message && dispatch(newAlert('error', 'ERROR:', e.message));
        });
      }
    );
  };
}

export const createArticle = (params = {}, queryParams) => async dispatch => {
  dispatch(sendRequest('SaveArticle'));
  try {
    const payload = await requestNodeService.post(
      'articles',
      articleSaveSanitizer(params),
      {}
    );
    dispatch(savedArticle());
    dispatch(fetchArticles(queryParams));
    dispatch(newAlert('success', 's', payload.data.message));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      dispatch(errorOnRequest('SaveArticle', e.errors));
      dispatch(clearAlerts());
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
        )
      );
    }
  } finally {
    dispatch(completeRequest('SaveArticle'));
  }
};

export const updateArticle = (params = {}, queryParams) => async dispatch => {
  dispatch(sendRequest('SaveArticle'));
  try {
    await requestNodeService.put(`articles/${params.id}`, articleSaveSanitizer(params), {});
    dispatch(savedArticle());
    dispatch(fetchArticles(queryParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      dispatch(errorOnRequest('SaveArticle', e.errors[0]));
      dispatch(clearAlerts());
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
        )
      );
    }
  } finally {
    dispatch(completeRequest('SaveArticle'));
  }
};

export const fetchArticleListExcelReportFile = (
  params = {}
) => async dispatch => {
  dispatch(sendRequest('ArticlesListExcelReportFile'));
  const axiosRequest = new AxiosRequest();
  try {
    const payload = await axiosRequest.get(
      'articles/excel_reports/index',
      articleFilterSanitizer(params, true),
      {
        responseType: 'blob'
      }
    );
    const file = new Blob([payload], { type: MIMETYPES['xlsx'] });
    const fileUrl = URL.createObjectURL(file);
    window.location.href = fileUrl;
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('ArticlesListExcelReportFile'));
  }
};
