import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SpacingStyles from '../../../../styles/helpers/spacing';
import TypographyStyles from '../../../../styles/typography';
import combineStyles from '../../../../helpers/combineStyles';
import classnames from 'classnames';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

import ItemStyles from './__styles__/item.styles';

import ActivityIndicator from '../../../shared/ActivityIndicator';

export class Item extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    url: PropTypes.string
  };

  state = {
    optionsMenu: null
  };

  _handleOpenOptionsMenu = event => {
    this.setState({ optionsMenu: event.currentTarget });
  };

  _handleCloseOptionsMenu = () => {
    this.setState({ optionsMenu: null });
  };

  render() {
    const { classes, name, url, loading, download = '' } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.fileTypeContainer}>
          {loading ? (
            <ActivityIndicator size={18} />
          ) : (
            <PictureAsPdfIcon className={classes.fileTypeIcon} />
          )}
        </div>
        <div className={classes.detail}>
          {loading ? (
            <p className={classnames(classes.subtitle3, classes.name)}>
              {name}
            </p>
          ) : download == '' ? (
            <a
              href={url || ''}
              rel="noopener noreferrer"
              target="_blank"
              className={classnames(classes.subtitle3, classes.name)}
            >
              {name}
            </a>
          ) : (
            <a
              href={url || ''}
              rel="noopener noreferrer"
              target="_blank"
              download={download}
              className={classnames(classes.subtitle3, classes.name)}
            >
              {name}
            </a>
          )}
        </div>
      </div>
    );
  }
}
export default withStyles(
  combineStyles(ItemStyles, TypographyStyles, SpacingStyles)
)(Item);
