import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

class CustomDialog extends React.Component {
  render() {
    const {
      open,
      title,
      buttonText,
      disabled,
      onConfirm,
      onClose,
      children,
      classes
    } = this.props;
    return (
      <Dialog
        fullWidth
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => onClose()}>
          {title || 'Comentario'}
        </DialogTitle>
        <DialogContent className={classes.contentContainer}>
          {children}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disabled}
            onClick={() => onConfirm()}
            color="secondary"
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
const styles = () => ({
  contentContainer: {
    minHeight: 150
  }
});
CustomDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.object,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  textPlaceholder: PropTypes.string,
  disabled: PropTypes.bool
};
export default compose(withStyles(styles, { withTheme: true }))(CustomDialog);
