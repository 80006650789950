import {
  ALERT_NEW,
  ALERT_CLOSE,
  ALERT_CLEAR
} from '../constants/action_constants';

export function newAlert(
  type = 'error',
  title = 'error',
  message = 'Disregarded error'
) {
  return {
    type: ALERT_NEW,
    alert: {
      open: true,
      title: title,
      text: message,
      type: type
    }
  };
}

export function closeAlert() {
  return { type: ALERT_CLOSE };
}

export function clearAlerts() {
  return { type: ALERT_CLEAR };
}

export const serverStatusError = (
  errors,
  unprocesableMessage
) => async dispatch => {
  if (errors && (errors.status > 499 || errors.status == 404)) {
    dispatch(
      newAlert(
        'error',
        'ERROR:',
        errors.title ||
          errors.statusText ||
          errors.error ||
          `Error ${errors.status}`
      )
    );
  } else if (errors && unprocesableMessage && errors.status == 422) {
    dispatch(newAlert('error', 'e:', unprocesableMessage));
  }
};
