import _get from 'lodash/get';
import { _moneyFormat } from '../../../../helpers/utilityFunctions';
import moment from 'moment';

export const columns = [
  { name: 'description', title: 'Descripción' },
  { name: 'value', title: 'Valor' },
  { name: 'date', title: 'Fecha' }
];

export const beneficiariesColumns = [
  { name: 'fullName', title: 'Nombre Completo' },
  { name: 'relationship', title: 'Parentesco' },
  { name: 'percentage', title: 'Porcentaje' }
];

export const formatData = items =>
  items.map(item => ({
    ...item,
    value: _moneyFormat(item.value),
    date: moment(item.date).format('DD-MM-YYYY[,] hh:mm A')
  }));

export const beneficiariesFormatData = items =>
  items.map(item => ({
    ...item,
    relationship: item.relationship.nombre,
    percentage: `${item.percentage}%`
  }));