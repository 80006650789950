import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import ActivityIndicator from '../../shared/ActivityIndicator';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import StepperButtons from '../../shared/StepperButtons';
import RefuseStepModal from '../../shared/Modals/RefuseStepModal';
import classnames from 'classnames';
import moment from 'moment-timezone';
import CustomDialog from '../../shared/Modals/CustomDialog';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import PromissoryPreview from '../PromissoryDetail/PromissoryPreview';
import { SampleNextArrow, SamplePrevArrow } from '../../shared/SliderArrow';
import Slider from 'react-slick';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

class DigitalSaleDocRevisionSale extends PureComponent {
  state = {
    submit: false,
    base64imgArray: [],
    voucherShouldOpen: false,
    voucherImageRef: null,
    isOpenedRefuseModal: false,
    causals: {}
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
    if (!this.props.pdfPromissoryUrl) {
      this.props.getDocumentRevisionImageBase64(
        this.props.docRevisionDetails.id,
        'voucher_sale_url'
      );
    }
  }

  _handleCloseVoucher = () => {
    this.setState({ voucherShouldOpen: false });
  };

  _handleOpenVoucherImage = id => {
    this.setState({ voucherShouldOpen: true, voucherImageRef: id });
  };

  renderSaleData = (data, classes) => {
    return (
      <Grid item sm={12} xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={16}>
            <Grid item className={classes.itemInline} sm={4} xs={4}>
              <p className={classnames(classes.subtitle3)}>{'Artículo'}</p>
            </Grid>
            <Grid item className={classes.itemInline} sm={2} xs={2}>
              <p className={classnames(classes.subtitle3)}>{'Cantidad'}</p>
            </Grid>
            <Grid item className={classes.itemInline} sm={2} xs={2}>
              <p className={classnames(classes.subtitle3)}>{'Valor'}</p>
            </Grid>
            <Grid item className={classes.itemInline} sm={2} xs={2}>
              <p className={classnames(classes.subtitle3)}>{'IVA'}</p>
            </Grid>
            <Grid item className={classes.itemInline} sm={2} xs={2}>
              <p className={classnames(classes.subtitle3)}>{'Subtotal'}</p>
            </Grid>
            {data.saleDetails.map(saleDetail => {
              return (
                <Fragment key={saleDetail.article.id}>
                  <Grid item className={classes.itemInline} sm={4} xs={4}>
                    <p className={classnames(classes.body2, classes.nameValue)}>
                      {saleDetail.article.name}
                    </p>
                  </Grid>
                  <Grid item className={classes.itemInline} sm={2} xs={2}>
                    <p className={classnames(classes.body2, classes.nameValue)}>
                      {saleDetail.amount}
                    </p>
                  </Grid>
                  <Grid item className={classes.itemInline} sm={2} xs={2}>
                    <p className={classnames(classes.body2, classes.nameValue)}>
                      {`$${saleDetail.value}`}
                    </p>
                  </Grid>
                  <Grid item className={classes.itemInline} sm={2} xs={2}>
                    <p className={classnames(classes.body2, classes.nameValue)}>
                      {`$${saleDetail.iva}`}
                    </p>
                  </Grid>
                  <Grid item className={classes.itemInline} sm={2} xs={2}>
                    <p className={classnames(classes.body2, classes.nameValue)}>
                      {`$${saleDetail.amount * parseFloat(saleDetail.value) +
                        parseFloat(saleDetail.iva)}`}
                    </p>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </Paper>
      </Grid>
    );
  };

  onSubmit = () => {
    const { docRevisionDetails, process, routes } = this.props;
    const params = {
      step: 'sale_data',
      type: 'approve'
    };
    this.props.digitalSalesDocumentRevisionCheckClient(
      docRevisionDetails.id,
      params,
      routes[this.nextStep],
      process
    );
  };

  _handleClose = () => {
    this.setState({ isOpenedRefuseModal: false });
  };

  refuseValidation = () => {
    this.setState({
      isOpenedRefuseModal: true,
      causals: this.props.docRevisionDetails.causals.filter(
        causal => causal.taskType === 4
      )
    });
  };

  _handleRefuse = causals => {
    const { docRevisionDetails, process, routes } = this.props;
    const params = {
      step: 'sale_data',
      type: 'refuse',
      causals: causals.causals
    };
    this.props.digitalSalesDocumentRevisionCheckClient(
      docRevisionDetails.id,
      params,
      routes[this.nextStep],
      process
    );
  };
  render() {
    const { classes, docRevisionDetails, voucherSaleUrl } = this.props;
    const innerWidthViewPort = window.innerWidth;
    const sliderSettings = {
      dots: true,
      speed: innerWidthViewPort > 600 ? 500 : 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <SamplePrevArrow />,
      nextArrow: <SampleNextArrow />,
      adaptiveHeight: true,
      variableWidth: true,
      centerMode: true
    };
    return (
      <div className={classes.container}>
        {docRevisionDetails ? (
          <Fragment>
            <CustomDialog
              open={
                this.state.voucherShouldOpen &&
                this.state.voucherImageRef !== null
              }
              loading={false}
              disabled={false}
              onClose={this._handleCloseVoucher}
              onConfirm={this._handleCloseVoucher}
              customWidth={true}
              maxWidth={innerWidthViewPort < 1000 ? '100%' : 1000}
              title={`Voucher`}
            >
              {this.state.voucherImageRef != null ? (
                <TransformWrapper>
                  <TransformComponent>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          '<img src="data:image/jpg;base64,' +
                          voucherSaleUrl[this.state.voucherImageRef] +
                          '" style="max-width: 100%;max-height: 500px"/>'
                      }}
                    />
                  </TransformComponent>
                </TransformWrapper>
              ) : null}
            </CustomDialog>
            <div className={classes.headerDataClientValidation}>
              <span>
                {'Comparación de artículos y valores con factura de venta'}
              </span>
              <span>{`Contrato ${docRevisionDetails.contractId}`}</span>
            </div>
            <div className={classes.parentColumnGrid}>
              <div className={classes.childColumnGridBig}>
                <Paper className={classes.paper}>
                  <div className={classes.wrapperData}>
                    {this.renderSaleData(docRevisionDetails, classes)}
                  </div>
                </Paper>
              </div>
              <div
                className={classes.childColumnGrid}
                style={{ width: '50%', maxWidth: 1000 }}
              >
                <Paper className={classes.paper}>
                  <div style={{ padding: 24, textAlign: 'center' }}>
                    {voucherSaleUrl ? (
                      voucherSaleUrl.length > 0 ? (
                        <Slider {...sliderSettings}>
                          {voucherSaleUrl.length > 0 &&
                            voucherSaleUrl.map((slide, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    padding: 10,
                                    maxWidth: innerWidthViewPort / 3
                                  }}
                                >
                                  <img
                                    onClick={() =>
                                      this._handleOpenVoucherImage(index)
                                    }
                                    src={'data:image/jpg;base64,' + slide}
                                    style={{
                                      maxWidth: innerWidthViewPort / 3
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </Slider>
                      ) : (
                        'No hay facturas'
                      )
                    ) : (
                      <ActivityIndicator />
                    )}
                  </div>
                </Paper>
              </div>
            </div>
            <MuiThemeProvider theme={materialTheme}>
              <Grid
                container
                spacing={24}
                alignItems="flex-start"
                className={classes.containerGrid}
              >
                {this.renderSaleData(docRevisionDetails, classes)}
              </Grid>
            </MuiThemeProvider>
            <Paper className={classes.paper}>
              <StepperButtons
                onSubmit={this.onSubmit}
                goBack={this.props.goBack}
                alternativeButtonText={'Rechazar'}
                alternativeButtonCallback={this.refuseValidation}
                nextText={'Aceptar'}
                nextIsPrimary={true}
              />
            </Paper>
            {this.state.isOpenedRefuseModal &&
              this.state.causals.length > 0 && (
                <RefuseStepModal
                  causals={this.state.causals}
                  onClose={this._handleClose}
                  onSave={this._handleRefuse}
                />
              )}
          </Fragment>
        ) : (
          <ActivityIndicator />
        )}
      </div>
    );
  }
}

const materialTheme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 479,
      md: 680,
      lg: 1281,
      xl: 1479
    }
  }
});

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  containerGrid: {
    [theme.breakpoints.up(680)]: {
      display: 'none'
    },
    display: 'flex',
    marginTop: 10,
    marginBottom: 10
  },
  itemInline: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerDataClientValidation: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2em'
  },
  parentColumnGrid: {
    [theme.breakpoints.up(680)]: {
      display: 'flex'
    },
    display: 'none',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10
  },
  childColumnGrid: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexBasis: '50%'
  },
  childColumnGridBig: {
    flexBasis: '50%',
    marginRight: 10,
    alignSelf: 'center',
    height: '100%'
  },
  paper: {
    padding: 5,
    height: '100%'
  },
  wrapperData: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    margin: '10px'
  },
  validationImg: {
    width: '100%'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  liDivider: {
    listStyleType: 'none'
  },
  item: {
    margin: 20,
    textAlign: 'center'
  },
  headline: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  subtitleRight: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.main,
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    margin: 0,
    marginBottom: theme.spacing.unit
  }
});

DigitalSaleDocRevisionSale.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(styles, LayoutStyles, TypographyStyles, SpacingStyles)
)(DigitalSaleDocRevisionSale);
