import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import InsuranceClientValidation from './InsuranceClientValidation';
import { newAlert } from '../../../actions/alert_actions';
import { validateInsuranceClient } from '../../../actions/insurance_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';

function mapStateToProps(state) {
  return {
    insuranceStore: state.insurances,
    serverErrors: state.insurances.errors,
    baseErrorOpen: state.insurances.baseErrorOpen,
    contractValid: state.insurances.contractValid,
    clientValid: state.insurances.clientValid,
    type: state.insurances.type,
    routes: state.stepper.routes,
    process: state.stepper.process
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    validateInsuranceClient: bindActionCreators(
      validateInsuranceClient,
      dispatch
    ),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InsuranceClientValidation);
