import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import PromissoryHeaderStyles from './__styles__/PromissoryHeader.styles';
import StatusLabel from '../SaleDetailPage/Promissory/StatusLabel';

import PromisoryRejectFormModal from './modals/PromisoryRejectForm';
import PromissoryApproveFormModal from './modals/PromisoryApproveForm';
import EditPromissoryModal from './modals/EditPromissoryModal';
import PromissoryCancelFormModal from './modals/PromissoryCancelForm';
import { PROMISSORY_STATUSES } from '../../../constants/enums';
// import { STATUSES } from '../PromissoryList/constants';
// import ProgressBar from './Status/ProgressBar';

export class PromissoryHeader extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.cancelPromissorySaved !== this.props.cancelPromissorySaved &&
      this.props.cancelPromissorySaved
    ) {
      this._handleCloseModal('cancelFormOpen');
    }
    if (
      prevProps.editPromissorySaved !== this.props.editPromissorySaved &&
      this.props.editPromissorySaved
    ) {
      this._handleCloseModal('isEditPromissoryOpen');
    }
    if (
      prevProps.approvePromissorySaved !== this.props.approvePromissorySaved &&
      this.props.approvePromissorySaved
    ) {
      this._handleCloseModal('approveFormOpen');
      this._handleCloseModal('rejectFormOpen');
    }
  }

  state = {
    approveFormOpen: false,
    rejectFormOpen: false,
    cancelFormOpen: false,
    anchorEl: null,
    moreMenuOpen: false,
    isEditPromissoryOpen: false
  };

  _handleMoreMenuClick = event =>
    this.setState({ anchorEl: event.currentTarget });

  _handleMoreMenuClose = () => this.setState({ anchorEl: null });

  _handleOpenModal = name => this.setState({ [name]: true });
  _handleCloseModal = name => this.setState({ [name]: false });

  _getMenuOptions = promissory => {
    const menuOptions = [];
    const {
      canUpdateUniquePromissory,
      canUpdateNoUniquePromissory,
      canCancelPromissory,
      canCancelUnmaterializedPromissory,
      canUpdateUnmaterializedPromissory
    } = this.props;

    const isEditable = (!promissory.isUnmaterialized || canUpdateUnmaterializedPromissory) &&
      ((canUpdateUniquePromissory && promissory.isUnique) || (canUpdateNoUniquePromissory && !promissory.isUnique));

    const IsPuiCase = promissory.isUnmaterialized && promissory.isUnique;

    if (isEditable && IsPuiCase) {
      if (promissory.promissoryStatus === 'canceled')
        menuOptions.push({
          label: 'Editar',
          action: () => this._handleOpenModal('isEditPromissoryOpen'),
        });
    } else if (isEditable) {
      menuOptions.push({
        label: 'Editar',
        action: () => this._handleOpenModal('isEditPromissoryOpen'),
      });
    }


    if (
      promissory.isUnique &&
      (canCancelPromissory &&
      !promissory.isUnmaterialized ||
      canCancelUnmaterializedPromissory &&
      promissory.isUnmaterialized)
      && promissory.promissoryStatus !== 'canceled'
    ) {
      menuOptions.push({
        label: 'Anular',
        action: () => this._handleOpenModal('cancelFormOpen')
      });
    }
    return menuOptions;
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      classes,
      promissory,
      canApprovePromissory,
      canUpdateUniquePromissory,
      canUpdateNoUniquePromissory,
      canCancelPromissory,
      canUpdateUnmaterializedPromissory
    } = this.props;
    const menuOptions = this._getMenuOptions(promissory);
    const isEditable = (!promissory.isUnmaterialized || canUpdateUnmaterializedPromissory) &&
      ((canUpdateUniquePromissory && promissory.isUnique) || (canUpdateNoUniquePromissory && !promissory.isUnique));
    const IsPuiCase = promissory.isUnmaterialized && promissory.isUnique;
    return (
      <section className={classes.headerRoot}>
        <div className={classes.promissoryDetails}>
          <h1 className={classnames(classes.headline5, classes.invoiceNumber)}>
            {'Pagaré'} #{promissory.code}
          </h1>
          <StatusLabel
            label={PROMISSORY_STATUSES[promissory.promissoryStatus]}
            type={PROMISSORY_STATUSES[promissory.promissoryStatus]}
            classes={{
              root: classnames(classes.noMarginBottom, classes.promissoryStatus)
            }}
          />
          <div className={classes.spacer} />
          <Fragment>
            {promissory.isUnique &&
              promissory.promissoryStatus != 'approved' &&
              promissory.promissoryStatus != 'canceled' &&
              !promissory.isUnmaterialized &&
              canApprovePromissory && (
                <Fragment>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => this._handleOpenModal('rejectFormOpen')}
                    className={classes.button}
                    classes={{
                      label: classes.buttonLabel
                    }}
                  >
                    {'Rechazar'}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this._handleOpenModal('approveFormOpen')}
                    className={classes.button}
                    classes={{
                      label: classes.buttonLabel
                    }}
                  >
                    {'Aprobar'}
                  </Button>
                </Fragment>
              )}
            {menuOptions.length > 0 && (
              <Fragment>
                <IconButton
                  id="moreButton"
                  title={'Acciones'}
                  aria-owns={this.state.anchorEl ? 'more-menu' : undefined}
                  aria-haspopup="true"
                  onClick={this._handleMoreMenuClick}
                  color={'secondary'}
                >
                  <MoreIcon className={classnames(classes.contentOption)} />
                </IconButton>
                <Menu
                  id="more-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this._handleMoreMenuClose}
                >
                  {menuOptions.map((option, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={option.action}
                        selected={index < 1}
                        classes={{
                          root: classes.menuItemRoot,
                          selected: classes.menuItemSelected
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Fragment>
            )}
          </Fragment>
        </div>
        {/* <div>
          <div className={classes.progressBarContainer}>
            <ProgressBar
              status={'Registrada'}
              promissory={promissory}
              movements={[
                { status: 'Registrada', fecha: 'testing' },
                { status: 'Rechazada', fecha: 'testing' },
                { status: 'Editada', fecha: 'testing' },
                { status: 'Aprobada', fecha: 'testing' }
              ]}
            />
          </div>
        </div> */}
        <div className={classes.promissoryDetails}>
          <div className={classes.invoiceDetailItem}>
            <p
              className={classnames(
                classes.subtitle3,
                classes.invoiceDetailAttribute
              )}
            >
              {'Tipo'}
            </p>
            <p className={classes.body2}>
              {promissory.typeOfPromissory
                ? promissory.typeOfPromissory == 'D'
                  ? 'Digital'
                  : 'Manual'
                : '--'}
            </p>
          </div>
          <div className={classes.invoiceDetailItem}>
            <p
              className={classnames(
                classes.subtitle3,
                classes.invoiceDetailAttribute
              )}
            >
              {'Único'}
            </p>
            <p className={classes.body2}>{promissory.isUnique ? 'Si' : 'No'}</p>
          </div>
          <div className={classes.invoiceDetailItem}>
            <p
              className={classnames(
                classes.subtitle3,
                classes.invoiceDetailAttribute
              )}
            >
              {'Inmaterializado'}
            </p>
            <p className={classes.body2}>
              {promissory.isUnmaterialized ? 'Si' : 'No'}
            </p>
          </div>
          <div className={classes.invoiceDetailItem}>
            <p
              className={classnames(
                classes.subtitle3,
                classes.invoiceDetailAttribute
              )}
            >
              {'Contrato'}
            </p>
            <p className={classes.body2}>
              {promissory.contractId ? promissory.contractId : '--'}
            </p>
          </div>
        </div>
        {this.state.approveFormOpen && canApprovePromissory && (
          <PromissoryApproveFormModal
            onSave={this.props.onApprove}
            onClose={() => this._handleCloseModal('approveFormOpen')}
            isSubmitting={this.props.requestApprovePromissorySend}
          />
        )}
        {this.state.rejectFormOpen && canApprovePromissory && (
          <PromisoryRejectFormModal
            onSave={this.props.onReject}
            onClose={() => this._handleCloseModal('rejectFormOpen')}
            rejectPromissoryCausals={this.props.rejectPromissoryCausals}
            isSubmitting={this.props.requestRejectPromissorySend}
          />
        )}
        {this.state.cancelFormOpen && (canCancelPromissory || canCancelUnmaterializedPromissory) && (
          <PromissoryCancelFormModal
            onSave={this.props.onCancel}
            onClose={() => this._handleCloseModal('cancelFormOpen')}
            cancelPromissoryCausals={this.props.cancelPromissoryCausals}
            isSubmitting={this.props.requestCancelPromissorySend}
          />
        )}
        {this.state.isEditPromissoryOpen && ((isEditable && IsPuiCase)) ? (
          this.props.promissory.promissoryStatus === 'canceled' && (
            <EditPromissoryModal
              promissory={promissory}
              geographicLocations={this.props.geographicLocations}
              onClose={() => this._handleCloseModal('isEditPromissoryOpen')}
              onSave={this.props.onEdit}
              errors={this.props.requestErrorsOnEditPromissory}
              isSubmitting={this.props.requestEditPromissorySend}
            />
          )
        ) : (this.state.isEditPromissoryOpen && isEditable && (
          <EditPromissoryModal
              promissory={promissory}
              geographicLocations={this.props.geographicLocations}
              onClose={() => this._handleCloseModal('isEditPromissoryOpen')}
              onSave={this.props.onEdit}
              errors={this.props.requestErrorsOnEditPromissory}
              isSubmitting={this.props.requestEditPromissorySend}
            />
        ))}
      </section>
    );
  }
}

const headerStyled = withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    PromissoryHeaderStyles
  )
)(PromissoryHeader);
export default withRouter(headerStyled);
