import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  pageSizeSelector: {
    ...theme.typography.caption,
    paddingRight: theme.spacing.unit * 5,
    // NOTE: fixes vertical alignment in FF
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: theme.spacing.unit * -0.25,
  },
  label: {
    paddingRight: theme.spacing.unit * 3,
  },
  pageSizeTitle: {
    width: 'auto',
    marginRight: theme.spacing.unit / 2,
  },
  inputRoot: {
    fontSize: theme.spacing.unit * 1.75,
    textAlign: 'right',
  },
  selectIcon: {
    top: 2,
  },
  '@media (max-width: 768px)': {
    label: {
      display: 'none',
    },
    pageSizeSelector: {
      paddingRight: theme.spacing.unit * 2,
    },
  },
});

const PageSizeSelectorBase = ({
  pageSize,
  onPageSizeChange,
  pageSizes,
  getMessage,
  classes,
}) => {
  return (
    <div className={classes.pageSizeSelector}>
      <span className={classes.label}>
        Filas por página
      </span>
      <Select
        value={pageSize}
        onChange={event => onPageSizeChange(event.target.value)}
        classes={{
          icon: classes.selectIcon,
        }}
        input={(
          <Input
            disableUnderline
            classes={{ root: classes.inputRoot }}
          />
)}
      >
        {pageSizes.map(item => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

PageSizeSelectorBase.propTypes = {
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  classes: PropTypes.object.isRequired,
};

export const PageSizeSelector = withStyles(styles, { name: 'PageSizeSelector' })(PageSizeSelectorBase);
export default PageSizeSelector;
