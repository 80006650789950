export const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  subTitle: {
    flex: 1,
    fontSize: 14,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  autocomplete: {
    marginTop: 16
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  dialogRoot: {
    width: 770
  },
  autocompleteInputGrid: {
    padding: '21px 10px 18px 10px !important',
    ['@media (max-width:600px)']: {
      padding: '0 12px !important'
    }
  },
  text: {
    padding: '5px 10px !important'
  }
});