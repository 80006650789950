import {
  CONTRACT_DETAIL_SET,
  CONTRACT_DETAIL_CLEAR
} from '../constants/action_constants';

const initialState = {
  contractDetail: null
};

export function contract(state = initialState, action) {
  switch (action.type) {
    case CONTRACT_DETAIL_SET:
      return {
        ...state,
        contractDetail: action.data
      };
    case CONTRACT_DETAIL_CLEAR:
      return {
        ...state,
        contractDetail: null
      };
    default:
      return state;
  }
}
