import _pickBy from 'lodash/pickBy';

export const corporationFilterSanitizer = filters => {
  const { name, identification, status, page, perPage } = filters;
  const sanitizedFilters = {
    name,
    identification,
    page: page,
    perPage
  };
  if (status === 'activos') {
    sanitizedFilters.active = true;
  }

  if (status === 'inactivos') {
    sanitizedFilters.active = false;
  }
  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};
