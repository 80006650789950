import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUICheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import classnames from 'classnames';
import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';

import FormikPropsValidations from './FormikPropsValidations';

const styles = theme => ({
  root: {},
  checkboxRoot: {
    left: '-10px',
    padding: theme.spacing.unit,
    position: 'absolute'
  },
  label: {},
  labelRoot: {
    margin: 0,
    marginTop: theme.spacing.unit * 3,
    paddingLeft: '30px',
    position: 'relative'
  },
  customLabelRoot: {
    margin: 0,
    paddingLeft: '30px',
    position: 'relative'
  }
});

const DEFAULT_VALUES = {
  true: true,
  false: false
};

export class Checkbox extends Component {
  static propTypes = {
    name: props => FormikPropsValidations('name', props),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: props => FormikPropsValidations('onChange', props)
  };

  _handleChange = ({ target: { checked } }) => {
    const { name, onChange, field = {}, values = DEFAULT_VALUES } = this.props;
    const inputName = name || field.name;
    const inputOnChange = onChange || field.onChange;
    inputOnChange({ target: { name: inputName, value: values[checked] } });
  };

  render() {
    const {
      classes,
      label,
      name,
      value,
      field = {},
      helperText = '',
      dataTest = '',
      values = DEFAULT_VALUES,
      disabled = false,
      customLabelRoot = false

    } = this.props;

    const inputName = name || field.name;
    const inputValue = value || field.value || false;
    const isChecked = values.true === inputValue;

    return (
      <>
        <FormControlLabel
          control={
            <MUICheckbox
              checked={isChecked}
              onChange={this._handleChange}
              value={inputName}
              color="secondary"
              classes={{ root: classes.checkboxRoot }}
              data-test={dataTest}
            />
          }
          label={label}
          classes={{
            root: customLabelRoot ? classnames(classes.customLabelRoot, classes.root) : classnames(classes.labelRoot, classes.root),
            label: classnames(classes.body2, classes.label)
          }}
          disabled={disabled}
        />
        {helperText && (<FormHelperText>{helperText}</FormHelperText>)}
      </>
    );
  }
}

export default withStyles(combineStyles(styles, TypographyStyles))(Checkbox);
