import moment from 'moment-timezone';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
import { MANUAL_QUOTA_STATUS, MANUAL_QUOTA_VERBOSE_STATUS } from '../../../constants/enums';
import _get from 'lodash/get';

export const columns = [
  {
    title: '# Contrato',
    name: 'contractId'
  },
  {
    title: 'Valor',
    name: 'value'
  },
  {
    title: 'Fecha Inicio',
    name: 'initialDate'
  },
  {
    title: 'Fecha Final',
    name: 'finalDate'
  },
  {
    title: 'Estado',
    name: 'statusPretty'
  },
  {
    title: 'Creador',
    name: 'creator'
  }
];

export const mapData = data =>
  data.map(datum => {
    const statusPretty = !datum.active && datum.status == MANUAL_QUOTA_STATUS.approved ? 'Desactivada' : Object.values(MANUAL_QUOTA_VERBOSE_STATUS)[datum.status];

    return {
      ...datum,
      contractId: datum.contract.id,
      initialDate: datum.initialDate ? moment(datum.initialDate).locale('es').format('DD MMM YYYY') : '--',
      finalDate: datum.finalDate ? moment(datum.finalDate).locale('es').format('DD MMM YYYY') : '--',
      value: _moneyFormat(datum.value) || '--',
      statusPretty: statusPretty || '--',
      creator: _get(datum.movements.find(movement => movement.status === 'registered'), 'user.username', '--')
    };
  });
