import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import Message from './Message';

class Page404 extends Component {
  state = {};

  componentDidMount() {}

  render() {
    return <Message message={'Sitio temporalmente en mantenimiento'} />;
  }
}

Page404.propTypes = {
  requestSend: PropTypes.bool
};

export default withStyles(combineStyles(LayoutStyles), {
  withTheme: true
})(Page404);
