import SecureCreditInstalmentsPercentageList from './SecureCreditInstalmentsPercentageList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchSecureCreditInstalmentsPercentages,
  createSecureCreditInstalmentsPercentage,
  updateSecureCreditInstalmentsPercentage,
  deleteSecureCreditInstalmentsPercentage
} from '../../../actions/secure_credit_instalments_percentage_actions';

function mapStateToProps(state) {
  return {
    secureCreditInstalmentsPercentageList: state.secureCreditInstalmentsPercentage.list,
    metadata: state.secureCreditInstalmentsPercentage.meta,
    requestSecureCreditInstalmentsPercentagesListSend: state.requestState.requestSecureCreditInstalmentsPercentagesListSend,
    canCreateSecureCreditInstalmentsPercentages:
      state.authentication.currentUser.canCreateSecureCreditInstalmentsPercentages,
    canUpdateSecureCreditInstalmentsPercentages:
      state.authentication.currentUser.canUpdateSecureCreditInstalmentsPercentages,
    canDeleteSecureCreditInstalmentsPercentages:
      state.authentication.currentUser.canDeleteSecureCreditInstalmentsPercentages,
    secureCreditInstalmentsPercentageSaved: state.secureCreditInstalmentsPercentage.saved,
    requestErrorsOnSecureCreditInstalmentsPercentagesSave: state.requestState.requestErrorsOnSecureCreditInstalmentsPercentagesSave,
    requestSecureCreditInstalmentsPercentagesSaveSend: state.requestState.requestSecureCreditInstalmentsPercentagesSaveSend,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSecureCreditInstalmentsPercentages: bindActionCreators(fetchSecureCreditInstalmentsPercentages, dispatch),
    createSecureCreditInstalmentsPercentage: bindActionCreators(createSecureCreditInstalmentsPercentage, dispatch),
    updateSecureCreditInstalmentsPercentage: bindActionCreators(updateSecureCreditInstalmentsPercentage, dispatch),
    deleteSecureCreditInstalmentsPercentage: bindActionCreators(deleteSecureCreditInstalmentsPercentage, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SecureCreditInstalmentsPercentageList);