import React, { PureComponent } from 'react';
import _find from 'lodash/find';
import { withStyles } from '@material-ui/core';

class MovementComments extends PureComponent {
  _getComments = movements => {
    const comments = [];
    const reviewed = _find(movements, { status: 'reviewed' });
    const rejected = _find(movements, { status: 'revoked' });
    const approved = _find(movements, { status: 'approved' });
    // Comentario de Revisión
    if (reviewed) {
      comments.push({
        title: 'Revisión: ',
        comment: reviewed.comment || '--'
      });
    }
    // Comentario de Rechazo
    if (rejected) {
      comments.push({
        title: 'Rechazo: ',
        comment: rejected.comment || '--'
      });
    }
    // Comentario de Aprobación
    if (approved) {
      comments.push({
        title: 'Aprobación: ',
        comment: approved.comment || '--'
      });
    }
    return comments;
  };

  render() {
    const { classes, movements } = this.props;
    if (!movements) return null;
    return this._getComments(movements).map((c, idx) => {
      return (
        <p key={`${c.title || ''} ${idx}`} className={classes.titleContainer}>
          {c.title}
          <a className={classes.subText}>{c.comment}</a>
        </p>
      );
    });
  }
}

const styles = theme => ({
  subText: {
    color: theme.typography.color.light,
    paddingLeft: theme.spacing.unit / 2,
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    wordBreak: 'break-all'
  },
  titleContainer: {
    color: theme.typography.color.main,
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingTop: '6px',
    margin: `0px ${theme.spacing.unit * 3}px `
  }
});

export default withStyles(styles, { withTheme: true })(MovementComments);
