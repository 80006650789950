import { SEGMENTS } from '../../../constants/enums.js'
export const columns = [
  {
    title: 'Segmento',
    name: 'customSegment'
  },
  {
    title: 'Sub Segmento',
    name: 'customName'
  },
];

export const mapData = data => (
  data.map(datum => {
    return {
      id: Number(datum.id),
      customSegment: SEGMENTS[datum.segment] || '--',
      segment: datum.segment,
      customName: datum.name.toUpperCase(),
      name: datum.name,
      lowerLimit: datum.lowerLimit,
      upperLimit: datum.upperLimit,
      quotaAssignmentTimeLimit: datum.quotaAssignmentTimeLimit,
      salesToBeRollover: datum.salesToBeRollover,
      code: datum.code
    };
  })
);