import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import StepperButtons from '../../shared/StepperButtons';
import SegmentHeader from '../../shared/SegmentHeader';
import { history } from '../../../helpers/history';

class DigitalSaleDocRevisionClient extends PureComponent {
  state = {
    isOpenedRefuseModal: false,
    causals: {}
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
  }

  onSubmit = () => {
    history.push('/digital-sales-document-revision');
  };

  render() {
    const { classes, docRevisionDetails } = this.props;
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <SegmentHeader className={classes.segmentHeader}>
            {`Revisión finalizada para el contrato ${
              docRevisionDetails.contractId
            }`}
          </SegmentHeader>
          <div className={classes.item} variant="caption">
            {'¿Desea continuar con la siguiente revisión o terminar?'}
          </div>
          <StepperButtons
            onSubmit={this.onSubmit}
            alternativeButtonText={'Siguiente revisión'}
            alternativeButtonCallback={this.props.getNextDocumentRevisionData}
          />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  item: {
    margin: 20,
    textAlign: 'center'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  }
});

DigitalSaleDocRevisionClient.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(DigitalSaleDocRevisionClient);
