import {
  NEW_COMMENTS,
  CLEAR_NEW_COMMENTS
} from '../constants/action_constants';

function addNewComment(data) {
  return { type: NEW_COMMENTS, data };
}

function clearNewComments() {
  return { type: CLEAR_NEW_COMMENTS };
}

export function addComment(comment) {
  return dispatch => {
    dispatch(addNewComment(comment));
  };
}

export function clearComments(action, open) {
  return dispatch => {
    dispatch(clearNewComments(action, open));
  };
}
