import {
  FINANCIAL_PLANS_SET,
  LOCATION_NAMES_SET,
  LOCATION_MAPPED_NAMES_SET,
  DETAILED_PLANS_SET,
  SAVED_DETAILED_PLAN
} from '../constants/action_constants';
const initialState = {
  financialPlans: [],
  financialPlanNames: [],
  meta: {},
  locationNames: [],
  cityNames: [],
  neighbourhoodNames: [],
  detailedPlans: [],
  detailedMeta: {},
  saved: false
};

export function financialPlans(state = initialState, action) {
  const locations = action.locationName || 'locationNames';
  switch (action.type) {
    case FINANCIAL_PLANS_SET:
      return {
        ...state,
        financialPlans: action.data,
        meta: action.meta
      };
    case DETAILED_PLANS_SET:
      return {
        ...state,
        detailedPlans: action.data,
        detailedMeta: action.meta,
        saved: false
      };
    case LOCATION_NAMES_SET:
      return {
        ...state,
        [locations]: action.data
      };
    case LOCATION_MAPPED_NAMES_SET:
      return {
        ...state,
        [locations]: action.data
      };
    case SAVED_DETAILED_PLAN:
      return {
        ...state,
        saved: true
      };
    default: {
      return state;
    }
  }
}
