import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  contract: Yup.number().required('El campo es requerido').min(1, 'Ingresa un contrato válido.'),
  personIdType: Yup.string().required('El campo es requerido'),
  personId: Yup.number().required('El campo es requerido').min(1, 'Ingresa una identificación válida.'),
  debtorBirthday: Yup.date().nullable().required('El campo es requerido'),
  debtorDocumentExpeditionDate: Yup.date().nullable().required('El campo es requerido'),
  cosignerContract: Yup.number().when('hasCosigner', {
    is: val => val === true,
    then: Yup.number()
      .notOneOf([Yup.ref('contract')], 'El contrato del codeudor no puede ser igual al del deudor')
      .required('El campo es requerido')
      .min(1, 'Ingresa un contrato válido.')
  }),
  cosignerIdentificationType: Yup.string().when('hasCosigner', {
    is: val => val === true,
    then: Yup.string()
      .required('El campo es requerido')
  }),
  cosignerIdentification: Yup.number().when('hasCosigner', {
    is: val => val === true,
    then: Yup.number()
      .notOneOf([Yup.ref('personId')], 'La identificación del codeudor no puede ser igual al del deudor')
      .required('El campo es requerido')
      .min(1, 'Ingresa una identificación válida.')
  }),
  cosignerBirthday: Yup.date().nullable().when('hasCosigner', {
    is: val => val === true,
    then: Yup.date().nullable().required('El campo es requerido')
  }),
  cosignerDocumentExpeditionDate: Yup.date().nullable().when('hasCosigner', {
    is: val => val === true,
    then: Yup.date().nullable().required('El campo es requerido')
  }),
});