/* eslint-disable complexity */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';

import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { doctypes } from '../../../constants/enums';
import { Formik } from 'formik';
import { validationSchema } from './QueryPromissoryValidation';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import CustomDialog from '../../shared/Modals/CustomDialog';
import InfoField from '../../shared/InfoField';
import Paper from '@material-ui/core/Paper';
import formatMoney from '../../../helpers/formatMoney';
import ActivityIndicator from '../../shared/ActivityIndicator';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import { PROMISSORY_STATUSES } from '../../../constants/enums';

class QueryPromissory extends PureComponent {
  state = {
    showDetail: false,
    contract: '--',
    debtorId: '--',
    debtorFullName: '--',
    cosignerFullName: '',
    uniquePromissoryId: '--',
    promissoryStatus: '--',
    promissoyId: '--'
  };

  onSubmit = values => {
    this.props.queryPromissories(values);
  };

  _handleOnClose = () => {
    this.props.resetQueryData();
    this.setState({
      showDetail: false
    });
    this.props.closeModal();
  };

  _goBack = () => {
    this.setState({ showDetail: false });
    this.props.setAvailableQuota('--');
  };

  _handleRowClick = props => {
    this.setState({
      showDetail: true,
      contract: props.contract,
      debtorId: props.debtor_details.identification,
      debtorIdType: props.debtor_details.type_of_identification,
      debtorFullName:
        props.debtor_details.name + ' ' + props.debtor_details.last_name,
      cosignerFullName:
        _get(props, 'cosigner_details', null) &&
        props.cosigner_details.name + ' ' + props.cosigner_details.last_name,
      uniquePromissoryId: props.code,
      promissoyId: props.id,
      promissoryStatus: props.promissory_status
    });
    this.props.fetchAvailableQuota(props.contract, {
      identification: props.debtor_details.identification,
      type_of_identification: props.debtor_details.type_of_identification
    });
  };

  _onKeyPress = event => {
    event.key === 'Enter' && event.preventDefault();
  };

  render() {
    const {
      classes,
      loading,
      serverErrors = {},
      promissories = [],
      isOpen,
      avQuota,
      avQuotaLoading,
      hasSearched
    } = this.props;
    const {
      showDetail,
      contract,
      debtorId,
      debtorIdType,
      debtorFullName,
      cosignerFullName,
      uniquePromissoryId,
      promissoryStatus,
      promissoyId
    } = this.state;
    return (
      isOpen && (
        <Formik
          initialValues={{
            queryType: 'contract',
            contract: '',
            identificationType: '',
            identification: ''
          }}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          onReset={(values, actions) => {
            actions.resetForm();
            this._handleOnClose();
          }}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleReset
          }) => (
            <CustomDialog
              onClose={handleReset}
              onConfirm={handleSubmit}
              onConfirmSecondary={this._goBack}
              loading={loading}
              open={isOpen}
              title={
                showDetail
                  ? `Contrato: ${contract}`
                  : 'Consulta de Pagarés Únicos'
              }
              buttonText={!showDetail ? 'Consultar' : ''}
              disabled={Object.keys(errors).length > 0 ? true : false}
              buttonSecondaryText={showDetail ? 'Regresar' : ''}
              classes={{
                root: classes.queryPromissoryDialogRoot,
                content: classes.queryPromissoryDialogContent
              }}
            >
              {showDetail ? (
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item sm={12} xs={12} className={classes.infoGrid}>
                    <InfoField label="Cupo Disponible:">
                      {avQuotaLoading ? (
                        <ActivityIndicator
                          size={20}
                          className={classes.activityIndicator}
                        />
                      ) : (
                        formatMoney(avQuota)
                      )}
                    </InfoField>
                  </Grid>
                  <Grid item sm={12} xs={12} className={classes.infoGrid}>
                    <InfoField label="Número Pagaré:">
                      <Link
                        to={`/promissories/${promissoyId}`}
                        className={classes.body2}
                        onClick={() => this._handleOnClose()}
                      >
                        {uniquePromissoryId}
                      </Link>
                    </InfoField>
                  </Grid>
                  <Grid item sm={12} xs={12} className={classes.infoGrid}>
                    <InfoField label="Estado Pagaré:">
                      {PROMISSORY_STATUSES[promissoryStatus].toUpperCase()}
                    </InfoField>
                  </Grid>
                  <Grid item sm={12} xs={12} className={classes.infoGrid}>
                    <InfoField label="Tipo de Documento de Titular:">{debtorIdType}</InfoField>
                  </Grid>
                  <Grid item sm={12} xs={12} className={classes.infoGrid}>
                    <InfoField label="Número de Documento de Titular:">{debtorId}</InfoField>
                  </Grid>
                  <Grid item sm={12} xs={12} className={classes.infoGrid}>
                    <InfoField
                      label="Nombre de Titular:"
                      className={
                        !cosignerFullName ? classes.infoFieldContainer : ''
                      }
                    >
                      {debtorFullName}
                    </InfoField>
                  </Grid>
                  {cosignerFullName && (
                    <Grid item sm={12} xs={12} className={classes.infoGrid}>
                      <InfoField
                        label="Nombre de Codeudor:"
                        className={classes.infoFieldContainer}
                      >
                        {cosignerFullName}
                      </InfoField>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <form autoComplete="off" className={classes.form}>
                  <Grid container spacing={24} alignItems="flex-start">
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <SimpleSelect
                        value={values.queryType}
                        onChange={handleChange}
                        name="queryType"
                        label="Consulta por:"
                        options={[
                          {
                            key: 'contract',
                            value: 'contract',
                            label: 'Contrato'
                          },
                          {
                            key: 'id',
                            value: 'id',
                            label: 'Identificación'
                          }
                        ]}
                      />
                    </Grid>
                    {values.queryType === 'contract' && (
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OutlinedTextField
                          onKeyPress={this._onKeyPress}
                          label="Número de contrato:"
                          name="contract"
                          type={'number'}
                          error={
                            (touched.contract && !!errors.contract) ||
                            !!_get(serverErrors, 'errors.contract[0]')
                          }
                          helperText={
                            (touched.contract && errors.contract) ||
                            _get(serverErrors, 'errors.contract[0]')
                          }
                          value={values.contract}
                          onChange={handleChange}
                        />
                      </Grid>
                    )}
                    {values.queryType === 'id' && (
                      <React.Fragment>
                        <Grid item sm={6} xs={12} className={classes.inputGrid}>
                          <SimpleSelect
                            value={values.identificationType}
                            onChange={handleChange}
                            name="identificationType"
                            label="Tipo de identificacion del deudor:"
                            options={mapListToOptions(doctypes, {}, true)}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12} className={classes.inputGrid}>
                          <OutlinedTextField
                            onKeyPress={this._onKeyPress}
                            type={'number'}
                            label="Identificacion del deudor:"
                            name="identification"
                            error={
                              (touched.identification &&
                                !!errors.identification) ||
                              (serverErrors && !!serverErrors.debtor)
                            }
                            helperText={
                              (touched.identification &&
                                errors.identification) ||
                              (serverErrors && serverErrors.debtor)
                            }
                            value={values.identification}
                            onChange={handleChange}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                  {(promissories.length > 0 || hasSearched) && !serverErrors && (
                    <div
                      className={classes.container}
                      id={'uniquePromissoryTable'}
                    >
                      <Paper className={classes.paper}>
                        {promissories.length === 0 ? (
                          <div className={classes.noPromissories}>
                            No tiene pagarés únicos.
                          </div>
                        ) : (
                          <Table
                            name={
                              promissories.length !== 1
                                ? 'Pagarés Únicos'
                                : 'Pagaré Único'
                            }
                            totalCount={promissories.length}
                            withToolbar={false}
                            withPagination={false}
                            columns={columns}
                            data={mapData(promissories)}
                            onClickOnRow={props => this._handleRowClick(props)}
                            loading={loading}
                          />
                        )}
                      </Paper>
                    </div>
                  )}
                </form>
              )}
            </CustomDialog>
          )}
        />
      )
    );
  }
}

const styles = (/* theme */) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '2rem',
    overflow: 'hidden'
  },
  paper: {
    flexGrow: 1,
    margin: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 0
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    marginRight: 0,
    padding: '6px 8px',
    width: 100
  },
  queryPromissoryDialogRoot: {
    ['@media (max-width:600px)']: {
      width: '95%',
      margin: '0 24px'
    }
  },
  queryPromissoryDialogContent: {
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  inputGrid: {
    padding: '6px 12px 6px 12px !important',
    ['@media (max-width:600px)']: {
      padding: '0 12px !important'
    }
  },
  infoGrid: {
    padding: '0 !important'
  },
  infoFieldContainer: {
    borderBottom: 'none'
  },
  activityIndicator: {
    justifyContent: 'flex-start'
  },
  noPromissories: {
    padding: '1rem',
    textAlign: 'center'
  }
});

QueryPromissory.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(QueryPromissory);
