import BrandList from './BrandList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchBrands, toggleModal } from '../../../actions/brands_actions';

function mapStateToProps(state) {
  return {
    brands: state.brands.brands,
    meta: state.brands.meta,
    permissions: state.authentication.permissions,
    requestSend: state.requestState.requestSend,
    canUpdateBrands: state.authentication.currentUser.canUpdateBrands,
    canCreateBrands: state.authentication.currentUser.canCreateBrands,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    fetchBrands: bindActionCreators(fetchBrands, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BrandList);
