import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const NotifyCommentsModal = props => {
  const { open, onClose, onSubmit, values } = props;
  const commentsCount = _getCommentsCount({ values });
  const message = `Estás apunto de enviar una notificación con ${commentsCount} comentarios`;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Notificar comentarios</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={onSubmit} color="secondary" disabled={commentsCount === 0}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const _getCommentsCount = params => {
  const { values } = params;
  return (values.documents || []).reduce(
    (acum, document) => {
      return acum + document.comments.filter(
        comment => {
          return !comment.notified;
        }
      ).length;
    },
    0
  );
}

export default NotifyCommentsModal;