import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QuotaTransferRevision from './QuotaTransferRevision';
import { quotaTransferRevision } from '../../../actions/quota_transfer_actions';
function mapStateToProps(state) {
  return {
    requestSend:
      state.requestState['requestTransfer-RevisionSend'] ||
      state.requestState['requestQuotaTransferSend'],
    canRevokeQuotaTransfer:
      state.authentication.currentUser.canRevokeQuotaTransfer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    quotaTransferRevision: bindActionCreators(quotaTransferRevision, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotaTransferRevision);
