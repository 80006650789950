import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CollapseWrapper from '../../../shared/CollapseWrapper.js';
import classnames from 'classnames';

import combineStyles from '../../../../helpers/combineStyles';
import SpacingStyles from '../../../../styles/helpers/spacing';
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import collapseWrapperStyles from '../../../../styles/components/collapseWrapper';

import Divider from '@material-ui/core/Divider';

import ActivityList from './ActivityList';
import moment from 'moment-timezone';

const styles = theme => ({
  activityLogButton: {
    marginBottom: 0,
    marginTop: theme.spacing.unit * 2
  }
});
export class Activity extends PureComponent {
  static propTypes = {
    activities: PropTypes.array.isRequired
  };

  render() {
    const { classes, activities } = this.props;
    const sortedActivities = [...activities].sort(
      (a, b) => moment(b.createdUtc) - moment(a.createdUtc)
    );
    const displayedActivities = sortedActivities;
    return (
      <CollapseWrapper
        expanded={true}
        classes={{
          root: classes.root,
          header: classes.panelHeader,
          expandButton: classes.expandButton,
          bodyRoot: classes.collapsePanelBodyRoot
        }}
        header={() => (
          <div className={classes.titleWrapper}>
            <h2 className={classnames(classes.headline8, classes.title)}>
              {'Actividad'}
            </h2>
          </div>
        )}
        body={
          <Fragment>
            <Divider />
            <div className={classes.body}>
              <ActivityList activities={displayedActivities} />
            </div>
          </Fragment>
        }
      />
    );
  }
}

export default withStyles(
  combineStyles(
    SpacingStyles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    styles,
    collapseWrapperStyles
  )
)(Activity);
