import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import StepperButtons from '../../shared/StepperButtons';
import SegmentHeader from '../../shared/SegmentHeader';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import { Formik } from 'formik';
import { validationSchema } from './InsuranceContractValidations';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import { insuranceTypes } from '../../../constants/enums';
import mapListToOptions from '../../../helpers/mapListToOptions';
import NumberFormat from 'react-number-format';

class InsuranceContractValidation extends Component {
  initialValues = store => {
    return {
      contract: store.contract || null,
      invoice: store.invoice || null,
      type: store.type || '',
      value: store.value || null
    };
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
  }

  onSubmit = values => {
    const { process, routes } = this.props;

    this.props.validateInsuranceContract(
      values,
      routes[this.nextStep],
      this.props.location.pathname,
      process
    );
  };

  NumberFormatCustom = props => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  };

  render() {
    const {
      classes,
      invoiceValidation,
      insuranceStore,
      serverErrors = {}
    } = this.props;

    const validateInvoice = invoiceValidation == '0' ? false : true;
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            ref={ref => (this.formik = ref)}
            enableReinitialize={true}
            initialValues={this.initialValues(insuranceStore)}
            validationSchema={validationSchema(validateInvoice)}
            onSubmit={this.onSubmit}
            // eslint-disable-next-line complexity
            render={({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange
            }) => (
              <form autoComplete="off" className={classes.form}>
                <SegmentHeader className={classes.segmentHeader}>
                  INFORMACIÓN DE CONTRATO
                </SegmentHeader>
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item sm={validateInvoice ? 6 : 12} xs={12}>
                    <OutlinedTextField
                      label="Número de contrato de gas"
                      name="contract"
                      type={'number'}
                      error={
                        (touched.contract && !!errors.contract) ||
                        (serverErrors && !!serverErrors.contract)
                      }
                      helperText={
                        (touched.contract && errors.contract) ||
                        (serverErrors && serverErrors.contract)
                      }
                      value={values.contract}
                      onChange={handleChange}
                    />
                  </Grid>
                  {validateInvoice && (
                    <Grid item sm={6} xs={12}>
                      <OutlinedTextField
                        label="Número de factura de gas"
                        name="invoice"
                        type={'number'}
                        error={
                          (touched.invoice && !!errors.invoice) ||
                          (serverErrors && !!serverErrors.invoice)
                        }
                        helperText={
                          (touched.invoice && errors.invoice) ||
                          (serverErrors && serverErrors.invoice)
                        }
                        value={values.invoice}
                        onChange={handleChange}
                      />
                    </Grid>
                  )}
                  <Grid item sm={6} xs={12} className={classes.typeField}>
                    <SimpleSelect
                      allowMargin={false}
                      value={values.type}
                      onChange={handleChange}
                      name="type"
                      label="Tipo de servicio"
                      error={
                        (touched.type && !!errors.type) ||
                        (serverErrors && !!serverErrors.type)
                      }
                      helperText={
                        (touched.type && errors.type) ||
                        (serverErrors && serverErrors.type)
                      }
                      options={mapListToOptions(
                        insuranceTypes,
                        { withId: false, byValue: true },
                        true
                      )}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OutlinedTextField
                      label="Valor del servicio"
                      name="value"
                      error={
                        (touched.value && !!errors.value) ||
                        (serverErrors && !!serverErrors.value)
                      }
                      helperText={
                        (touched.value && errors.value) ||
                        (serverErrors && serverErrors.value)
                      }
                      value={values.value}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: this.NumberFormatCustom
                      }}
                    />
                  </Grid>
                </Grid>
                <div className={classes.spacer} />
                <StepperButtons onSubmit={handleSubmit} process={'insurance'} />
              </form>
            )}
          />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {},
  infoFieldGrid: {
    alignItems: 'flex-end',
    marginBottom: 5 * theme.spacing.unit
  },
  buttonsGrid: {
    marginBottom: 2 * theme.spacing.unit
  },
  form: {
    paddingTop: 30
  },
  actionBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  button: {
    alignSelf: 'flex-end',
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  bigButton: {
    width: '100%',
    fontSize: '1rem',
    color: '#db4945'
  },
  observations: {
    marginTop: 5 * theme.spacing.unit
  },
  dateField: {
    paddingTop: 28
  },
  typeField: {
    paddingTop: '28px !important'
  }
});

export default withStyles(styles)(InsuranceContractValidation);
