import {
  SET_CURRENT_STEP,
  SET_STEPS,
  SET_OPTIONAL_STEPS,
  SET_FAILED_STEPS,
  STEPPER_NEXT,
  STEPPER_PREVIOUS,
  STEPPER_SKIP,
  RESET_STEPPER,
  STEPPER_PERSIST,
  RESET_NEXT_STEPS,
  SET_AUTO_SKIP_STEP,
  SET_PROCESS
} from '../constants/action_constants';
import { history } from '../helpers/history';

const CACHE_KEY = 'stepper';

export const persistStepper = () => dispatch => {
  const cache = sessionStorage.getItem(CACHE_KEY) || null;
  if (cache) {
    const data = JSON.parse(cache);
    dispatch({ type: STEPPER_PERSIST, data });
  }
};

export const setCurrentStep = step => dispatch => {
  dispatch({ type: SET_CURRENT_STEP, step });
};

export const setSteps = (steps, routes, process) => dispatch => {
  dispatch({ type: SET_STEPS, steps, routes, process, nextStepTitle: process });
};

export const setOptionalSteps = steps => dispatch => {
  dispatch({ type: SET_OPTIONAL_STEPS, data: steps });
};

export const setFailedSteps = steps => dispatch => {
  dispatch({ type: SET_FAILED_STEPS, data: steps });
};

export const nextStep = () => dispatch => {
  dispatch({ type: STEPPER_NEXT });
};

export const previousStep = () => dispatch => {
  dispatch({ type: STEPPER_PREVIOUS });
};

export const skipStep = () => dispatch => {
  dispatch({ type: STEPPER_SKIP });
};

export const resetStepper = () => dispatch => {
  dispatch({ type: RESET_STEPPER });
};

export const resetNextSteps = (process, nextStep) => dispatch => {
  dispatch({ type: RESET_NEXT_STEPS, process, nextStep });
};

export const autoSkipStep = route => dispatch => {
  dispatch({
    type: SET_AUTO_SKIP_STEP,
    route
  });
};

export const setProcess = process => {
  return {
    type: SET_PROCESS,
    process
  };
};

export const goToStep = (destination, originalProcess) => {
  return (dispatch, getState) => {
    dispatch(setProcess(originalProcess));

    const { stepper } = getState();
    sessionStorage.setItem(
      'stepper',
      JSON.stringify({ ...stepper, process: originalProcess })
    );

    history.push(destination);
  };
};
