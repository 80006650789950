import { salesConstants } from '../constants/sales_constants';
import { SALE_TOGGLE_ACTION } from '../constants/action_constants';
const initialState = {
  sales: [],
  meta: {},
  salesDetail: null,
  approveSale: null,
  approveSaleFailure: null
};

export function sales(state = initialState, action) {
  switch (action.type) {
    case SALE_TOGGLE_ACTION:
      const actionModal = `action${action.action}Open`;
      return {
        ...state,
        [actionModal]: action.open
      };
    case salesConstants.LIST_SALES_REQUEST:
      return {
        ...state
      };
    case salesConstants.LIST_SALES_SUCCESS:
      return {
        ...state,
        sales: action.data,
        meta: action.meta
      };
    case salesConstants.LIST_SALES_FAILURE:
      return {
        ...state,
        ...initialState
      };
    case salesConstants.DETAIL_SALES_REQUEST:
      return {
        ...state
      };
    case salesConstants.DETAIL_SALES_SUCCESS:
      return {
        ...state,
        salesDetail: action.data
      };
    case salesConstants.DETAIL_SALES_FAILURE:
      return {
        ...state,
        ...initialState
      };
    case salesConstants.APPROVE_SALE_REQUEST:
      return {
        ...state
      };
    case salesConstants.APPROVE_SALE_SUCCESS:
      return {
        ...state,
        approveSale: action.data,
        approveSaleFailure: null
      };
    case salesConstants.APPROVE_SALE_FAILURE:
      return {
        ...state,
        approveSaleFailure: action.error,
        approveSale: null
      };
    default:
      return state;
  }
}
