const styles = theme => ({
  baseRoot: {
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    position: 'relative',
    minHeight: 72
  },
  root: {},
  toolbarRoot: {
    borderBottom: 0,
    margin: 0,
    minHeight: 72,
    padding: 0,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 1.5
  },
  toolbarTitle: {
    display: 'inline-block',
    marginRight: theme.spacing.unit * 3
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  tableName: {
    color: 'rgba(12,31,44,0.87)',
    fontSize: '20px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    flex: 1,
    letterSpacing: '0.25px',
    lineHeight: '24px',
    margin: 0
  },
  tableHeadRowRoot: {
    height: 48,
    ['@media (max-width:899px)']: {
      display: 'none',
    }
  },
  tableRowHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(12,31,44, 0.04)'
    }
  },
  disableRowHover: {
    cursor: 'default !important',
    '&:hover': {
      backgroundColor: 'inherit !important'
    }
  },
  tableRowRoot: {
    ['@media (max-width:899px)']: {
      display: 'block',
      height: 'auto',
      borderBottom: '12px solid #eceef1',
    }
  },
  tableCellRoot: {
    padding: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:first-child': {
      paddingLeft: theme.spacing.unit * 3
    },
    '&:last-child': {
      paddingRight: theme.spacing.unit * 1.5
    },
    '& span': {
      width: '95%',
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    ['@media (max-width:899px)']: {
      padding: [[theme.spacing.unit * 1.75, theme.spacing.unit * 3], '!important'],
      display: 'block',
      textAlign: 'right',
      '&::before':{
        content: 'attr(data-label)',
        float: 'left',
        color: 'rgba(12,31,44,0.6)',
        fontSize: 12,
        lineHeight: '16px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        letterSpacing: '0.1px'
      }
    }
  },
  tableCellCheckbox: {
    ['@media (max-width:899px)']: {
      display: 'none',
    }
  },
  tableCellHead: {
    color: 'rgba(12,31,44,0.6)',
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '16px',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  tableCellSortIcon: {
    width: 20,
    height: 20,
    margin: 0,
    marginLeft: 1
  },
  tableCellBody: {
    color: 'rgba(12,31,44,0.87)',
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    fontWeight: 'normal',
    fontFamily: 'Roboto'
  },
  tableCellLink: {
    textDecoration: 'underline',
  },
  tablePaginationCaption: {
    color: 'rgba(12,31,44,0.6)',
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '16px'
  },
  tablePaginationToolbar: {
    paddingRight: theme.spacing.unit * 1.625
  },
  tablePaginationSelectRoot: {
    marginLeft: theme.spacing.unit * 2.875,
    outline: 'none'
  },
  tablePaginationSelectIcon: {
    right: -5
  },
  tablePaginationSelect: {
    position: 'relative',
    top: '1px',
    '&:focus': {
      backgroundColor: 'white'
    }
  },
  tablePaginationActions: {
    marginLeft: theme.spacing.unit * 4.125
  },
  tablePaginationMenuItem: {
    color: 'rgba(12,31,44,0.87)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    backgroundColor: '#FFF',
    height: '40px',
    padding: '0 9px',
    '&:hover': {
      backgroundColor: 'whitesmoke'
    }
  },
  menuItemSelected: {
    backgroundColor: '#ebebeb'
  },
  downloadButton: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  noDataCell: {
    borderBottom: 0
  },
  rootWithoutPagination: {
    paddingBottom: 0
  },
  rowsSelectedToolbar: {
  },
  rowsSelectedTextColor: {
    color: theme.palette.secondary.main
  },
  rowsSelectedButtonRoot: {
    '&:hover': {
      backgroundColor: 'rgba(0, 161, 251, 0.08)'
    }
  },
  rowsSelectedDeleting: {
    width: '24px !important',
    height: '24px !important',
    color: theme.palette.primary.main
  },
  menuItemRoot: {
    color: 'rgba(12,31,44,0.87)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    backgroundColor: '#FFF !important',
    height: '40px',
    padding: [[0, theme.spacing.unit * 2]],
    '&:hover': {
      backgroundColor: 'whitesmoke !important'
    }
  },
  menuItemSelected: {
    backgroundColor: '#ebebeb !important'
  },
});

export default styles;
