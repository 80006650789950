import _pickBy from 'lodash/pickBy';

export const secureCreditInstalmentsPercentageSanitizer = values => {
  const {
    minNumOfInstalments,
    maxNumOfInstalments,
    page,
    perPage
  } = values;
  const sanitizedFilters = {
    minNumOfInstalments: Number(minNumOfInstalments),
    maxNumOfInstalments: Number(maxNumOfInstalments),
    page,
    perPage
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};

export const secureCreditInstalmentsPercentageSaveSanitizer = values => {
  const {
    minNumOfInstalments,
    maxNumOfInstalments,
    percentage
  } = values;
  const sanitizedFilters = {
    minNumOfInstalments: Number(minNumOfInstalments),
    maxNumOfInstalments: Number(maxNumOfInstalments),
    percentage: Number(percentage)
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};