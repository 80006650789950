import _pickBy from 'lodash/pickBy';

const globalSettingFilters = filters => {
  const { settingName, description, page, per_page, tag } = filters;

  const sanitizedFilters = {
    name_cont: settingName,
    description_cont: description,
    tags_code_cont: tag,
    page,
    per_page
  };

  return _pickBy(sanitizedFilters, filter => !!filter);
};

export default globalSettingFilters;