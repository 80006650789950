import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import { validationSchema } from './validationSchema';
import _isEmpty from 'lodash/isEmpty';
import LoadingIndicator from '../../shared/LoadingIndicator';
import AppNavBar from '../../shared/AppNavBar';
import Footer from './Footer';
import GeneralDataStep from './GeneralDataStep/GeneralDataStep';
import CategoriesAndLocationsStep from './CategoriesAndLocationsStep';
import SaleChannelsStep from './SaleChannelsStep';
import CompanySalesStep from './CompanySalesStep';
import {
  SUPPLIER_PROPOSAL_DOCUMENT_TYPES,
  SUPPLIER_PROPOSAL_REJECTION_MOTIVES,
  SUPPLIER_PROPOSAL_MINUTE_TYPES
} from '../../../constants/enums';
import SubmitConfirmationModal from './submitConfirmationModal';
import AddDocumentModal from './AddDocumentModal';
import ListCommentsModal from './ListCommentsModal';
import moment from 'moment';
import AcceptProposalModal from './AcceptProposalModal';
import RejectProposalModal from './RejectProposalModal';
import ProductModal from './ProductModal';
import SignerModal from './SignerModal';
import SupplierDataStep from './SupplierDataStep';
import MinuteStep from './MinuteStep';
import MinuteFieldsStep from './MinuteFieldsStep';
import SignersStep from './SignersStep';
import Header from './Header';
import RejectMinuteModal from './RejectMinuteModal';
import NotifyCommentsModal from './NotifyCommentsModal';
import ConfigurationTemplatesStep from './ConfigurationTemplatesStep';
import DeleteProposalConfirmationModal from './DeleteProposalConfirmationModal';
import GenerateMinuteModal from './GenerateMinuteModal';
import {
  BANK_CERTIFICATE,
  BRAND_CERTIFICATE_NAME,
  BUSINESS_LOGO,
  GENERAL_DATA_DOCUMENTS,
  PERSONAL_DATA_POLICY,
  SUPPLIER_REGISTER_FILE,
  THIRD_LEVEL_SHAREHOLDER_COMPOSITION
} from '../../../constants/supplier_proposal_constants';

class SupplierProposalCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitConfirmationModalOpen: false,
      loadingDocumentIds: [],
      listOtherCommentsModalOpen: false,
      otherComments: [],
      acceptProposalModalOpen: false,
      rejectProposalModalOpen: false,
      newDocumentType: null,
      rejectMinuteModalOpen: false,
      notifyCommentsModalOpen: false,
      deleteProposalConfirmationModalOpen: false,
      generateMinuteModalOpen: false
    };
    this.state.steps = this._getSteps(props.proposal);
  }

  componentDidMount() {
    const {
      categories,
      fetchOrgData,
      fetchLineExecutives,
      lineExecutives
    } = this.props;
    if (!categories) fetchOrgData();
    if (!lineExecutives) fetchLineExecutives();
  }

  render() {
    const {
      classes,
      fetchingProposal,
      proposal,
      currentUser,
      logout,
      activeStep,
      createDocument,
      documentCreating,
      addDocumentModalOpen,
      setAddDocumentModalOpen,
      goToIndex,
      productModal,
      setProductModalOpen,
      fetchingOrgData,
      signerModal,
      setSignerModalOpen,
      creatingProduct,
      updatingProduct,
      creatingSigner,
      updatingSigner,
      lineExecutives = [],
      fetchingLineExecutives,
      deleteProposal,
      previewMinute
    } = this.props;
    const {
      submitConfirmationModalOpen,
      listOtherCommentsModalOpen,
      otherComments,
      acceptProposalModalOpen,
      steps,
      newDocumentType,
      rejectProposalModalOpen,
      rejectMinuteModalOpen,
      notifyCommentsModalOpen,
      deleteProposalConfirmationModalOpen,
      generateMinuteModalOpen
    } = this.state;
    if (fetchingProposal || fetchingOrgData || fetchingLineExecutives)
      return <LoadingIndicator />;
    return (
      <Grid container className={classes.mainContainer}>
        <Grid container item xs={12} className={classes.navBarContainer}>
          <AppNavBar logout={logout} currentUser={currentUser} />
        </Grid>
        <Grid container item xs={12} className={classes.contentContainer}>
          <Formik
            initialValues={this._getFormInitialValues(proposal)}
            validationSchema={validationSchema}
            onSubmit={() => {}}
            render={({
              values,
              handleChange,
              touched,
              setFieldValue,
              handleBlur,
              errors
            }) => {
              return (
                <Grid container item>
                  <Grid
                    container
                    item
                    alignItems="center"
                    xs={2}
                    className={classes.stepperContainer}
                  >
                    <Stepper
                      nonLinear
                      activeStep={activeStep}
                      orientation="vertical"
                    >
                      {steps.map(step => {
                        return (
                          <Step key={step.key}>
                            <StepLabel
                              className={classes.stepLabel}
                              onClick={this._onStepLabelClick({
                                step,
                                values,
                                setFieldValue
                              })}
                              completed={step.completed}
                              StepIconProps={{
                                classes: {
                                  active: classes.iconActive,
                                  completed:
                                    step.key === activeStep
                                      ? classes.iconActive
                                      : classes.iconCompleted
                                }
                              }}
                            >
                              {step.label}
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Grid>
                  <Grid container item xs={10}>
                    <SubmitConfirmationModal
                      open={submitConfirmationModalOpen}
                      onClose={() =>
                        this.setState({ submitConfirmationModalOpen: false })
                      }
                      onSave={() => {
                        this._onSave({
                          action: 'continue',
                          values,
                          setFieldValue,
                          sendIncompleteProposalEmail: true
                        });
                      }}
                      onSubmit={() => {
                        this._onSave({
                          action: 'continue',
                          values,
                          setFieldValue,
                          assignExecutive: true
                        });
                      }}
                    />
                    <AddDocumentModal
                      open={addDocumentModalOpen}
                      isSubmitting={documentCreating}
                      onClose={() => setAddDocumentModalOpen(false)}
                      onSubmit={document => {
                        createDocument({
                          document: {
                            ...document,
                            supplierProposalId: proposal.id,
                            docType: newDocumentType
                          },
                          documents: values.documents,
                          setDocuments: newDocs => {
                            setFieldValue('documents', newDocs);
                          }
                        });
                      }}
                    />
                    <ListCommentsModal
                      classes={classes}
                      open={listOtherCommentsModalOpen}
                      onClose={() => {
                        this.setState({
                          listOtherCommentsModalOpen: false,
                          otherComments: []
                        });
                      }}
                      comments={otherComments}
                    />
                    <AcceptProposalModal
                      classes={classes}
                      open={acceptProposalModalOpen}
                      onClose={() =>
                        this.setState({ acceptProposalModalOpen: false })
                      }
                      onSubmit={params => {
                        this._onSave({
                          action: 'save',
                          values: {
                            ...values,
                            committeeDate: params.committeeDate
                          },
                          setFieldValue,
                          acceptProposal: true
                        });
                      }}
                    />
                    <RejectMinuteModal
                      open={rejectMinuteModalOpen}
                      onClose={() =>
                        this.setState({ rejectMinuteModalOpen: false })
                      }
                      onSubmit={() => {
                        this._onSave({
                          action: 'save',
                          values: {
                            ...values,
                            rejectionMotive:
                              SUPPLIER_PROPOSAL_REJECTION_MOTIVES.NoAgreement
                          },
                          setFieldValue
                        });
                        this.setState({ rejectMinuteModalOpen: false });
                      }}
                    />
                    <NotifyCommentsModal
                      open={notifyCommentsModalOpen}
                      values={values}
                      onClose={() =>
                        this.setState({ notifyCommentsModalOpen: false })
                      }
                      onSubmit={() => {
                        this.setState({ notifyCommentsModalOpen: false });
                        this._onSave({
                          action: 'save',
                          values,
                          notifyComments: true,
                          setFieldValue
                        });
                      }}
                    />
                    <DeleteProposalConfirmationModal
                      open={deleteProposalConfirmationModalOpen}
                      proposal={proposal}
                      onClose={() =>
                        this.setState({
                          deleteProposalConfirmationModalOpen: false
                        })
                      }
                      onSubmit={() => {
                        this.setState({
                          deleteProposalConfirmationModalOpen: false
                        });
                        deleteProposal({ id: proposal.id });
                      }}
                    />
                    <GenerateMinuteModal
                      open={generateMinuteModalOpen}
                      onClose={() =>
                        this.setState({ generateMinuteModalOpen: false })
                      }
                      onPreview={() => {
                        this.setState({ generateMinuteModalOpen: false });
                        previewMinute({
                          id: proposal.id,
                          minuteType: values.minuteType,
                          commission: values.commission
                        });
                      }}
                      action={this._getGenerateMinuteModalAction({ values })}
                      onAction={() => {
                        this.setState({ generateMinuteModalOpen: false });
                        this._onSave({
                          action: 'save',
                          values,
                          setFieldValue,
                          sendMinute:
                            this._getGenerateMinuteModalAction({ values }) ===
                            'enviar',
                          generateMinute:
                            this._getGenerateMinuteModalAction({ values }) ===
                            'autocompletar'
                        });
                      }}
                    />
                    {rejectProposalModalOpen && (
                      <RejectProposalModal
                        classes={classes}
                        open={true}
                        onClose={() =>
                          this.setState({ rejectProposalModalOpen: false })
                        }
                        committeeDate={values.committeeDate}
                        onSubmit={params => {
                          this._onSave({
                            action: 'save',
                            values: {
                              ...values,
                              committeeDate: params.committeeDate,
                              rejectionMotive: params.rejectionMotive,
                              rejectionExplanation: params.rejectionExplanation
                            },
                            setFieldValue
                          });
                        }}
                      />
                    )}
                    {productModal.open && (
                      <ProductModal
                        classes={classes}
                        open={true}
                        isSubmitting={creatingProduct || updatingProduct}
                        product={productModal.product}
                        onClose={() => setProductModalOpen(false)}
                        categories={this._childrenCategories()}
                        onSubmit={productData => {
                          this._onSaveProduct({
                            productData: {
                              ...productData,
                              supplierProposalId: proposal.id
                            },
                            values,
                            setFieldValue
                          });
                        }}
                      />
                    )}
                    {signerModal.open && (
                      <SignerModal
                        classes={classes}
                        open={true}
                        signer={signerModal.signer}
                        isSubmitting={creatingSigner || updatingSigner}
                        onClose={() => setSignerModalOpen(false)}
                        onSubmit={signerData => {
                          this._onSaveSigner({
                            signerData: {
                              ...signerData,
                              supplierProposalId: proposal.id
                            },
                            values,
                            setFieldValue
                          });
                        }}
                      />
                    )}
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.headerContainer}
                    >
                      <Header
                        classes={classes}
                        goToIndexButtonDisabled={
                          this._isSaveButtonSubmitting() ||
                          this._isContinueButtonSubmitting()
                        }
                        goToIndex={goToIndex}
                        showAcceptProposalButton={this._showAcceptProposalButton(
                          { values }
                        )}
                        showRejectProposalButton={this._showRejectProposalButton(
                          { values }
                        )}
                        acceptProposalButtonDisabled={this._acceptProposalButtonDisabled(
                          values
                        )}
                        onAcceptProposal={this._onAcceptProposal}
                        onRejectProposal={this._onRejectProposal}
                        showSendMinuteButton={this._showSendMinuteButton({
                          values
                        })}
                        onSendMinute={this._onSendMinute}
                        showMinuteAcceptanceButtons={this._showMinuteAcceptanceButtons(
                          { values }
                        )}
                        onAcceptMinute={this._onAcceptMinute({
                          values,
                          setFieldValue
                        })}
                        onGiveUpMinuteTurn={this._onGiveUpMinuteTurn({
                          values,
                          setFieldValue
                        })}
                        acceptMinuteButtonDisabled={this._acceptMinuteButtonDisabled(
                          { values }
                        )}
                        onRejectMinute={this._onRejectMinute}
                        showNotifyCommentsButton={this._showNotifyCommentsButton(
                          { values }
                        )}
                        onNotifyComments={this._onNotifyComments({ values })}
                        showSendConfigurationTemplatesButton={this._showSendConfigurationTemplatesButton(
                          { values }
                        )}
                        onSendConfigurationTemplates={this._onSendConfigurationTemplates(
                          { values, setFieldValue }
                        )}
                        showGenerateMinuteButton={this._showGenerateMinuteButton(
                          { values }
                        )}
                        onGenerateMinute={this._onGenerateMinute({
                          values,
                          setFieldValue
                        })}
                        showDeleteProposalButton={this._showDeleteProposalButton()}
                        onDeleteProposal={this._onDeleteProposal}
                        showSupplierCreatedOnExternalSystemButton={this._showSupplierCreatedOnExternalSystemButton(
                          { values }
                        )}
                        onSupplierCreatedOnExternalSystem={this._onSupplierCreatedOnExternalSystem(
                          { values, setFieldValue }
                        )}
                        showMinuteSignedButton={this._showMinuteSignedButton({
                          values
                        })}
                        onMinuteSigned={this._onMinuteSigned({
                          values,
                          setFieldValue
                        })}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.activeStepViewContainer}
                    >
                      {this._renderStep({
                        values,
                        handleBlur,
                        handleChange,
                        touched,
                        errors,
                        setFieldValue
                      })}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.footerContainer}
                    >
                      <Footer
                        classes={classes}
                        saveButtonDisabled={this._isSaveButtonDisabled({
                          values,
                          errors,
                          touched
                        })}
                        continueButtonDisabled={this._isContinueButtonDisabled({
                          values,
                          errors,
                          touched
                        })}
                        onSave={() =>
                          this._onSave({
                            action: 'save',
                            values,
                            setFieldValue
                          })
                        }
                        onContinue={() =>
                          this._onContinue(values, setFieldValue)
                        }
                        saveButtonSubmitting={this._isSaveButtonSubmitting()}
                        continueButtonSubmitting={this._isContinueButtonSubmitting()}
                        goBack={this._goBack}
                        backButtonDisabled={
                          this._isSaveButtonSubmitting() ||
                          this._isContinueButtonSubmitting()
                        }
                        continueButtonText={this._continueButtonText()}
                        showChangeExecutiveField={this._showChangeExecutiveField(
                          { values }
                        )}
                        lineExecutives={lineExecutives}
                        lineExecutiveId={values.lineExecutiveId}
                        handleLineExecutiveChange={this._handleLineExecutiveChange(
                          { setFieldValue }
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Grid>
      </Grid>
    );
  }

  _renderStep = params => {
    const {
      values,
      handleBlur,
      handleChange,
      touched,
      errors,
      setFieldValue
    } = params;
    const {
      classes,
      activeStep,
      newAlert,
      currentUser,
      updateDocument,
      setProductModalOpen,
      fetchingOrgData,
      setSignerModalOpen,
      creator,
      proposal
    } = this.props;
    const { loadingDocumentIds } = this.state;
    const proposalWasSent = this._proposalWasSent();
    const fileMaxSize = 10;
    const fileSizeUnit = 'MB';
    const extensions = ['pdf'];
    const userIsAspiringSupplier = this._userIsAspiringSupplier();
    switch (activeStep) {
      case 0:
        return (
          <GeneralDataStep
            classes={classes}
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            proposalWasSent={proposalWasSent}
            handleChangeOnlyDigits={this._handleChangeOnlyDigits}
            creator={creator}
            onAddDocument={this._onAddDocument}
            canCreateDocuments={currentUser.canCreateSupplierProposalDocuments}
            handleFileChange={this._handleFileChange}
            fetchFileBase64={this._fetchFileBase64}
            handleFileError={this._handleFileError}
            handleFileAcceptanceChange={this._handleFileAcceptanceChange}
            getDocByDocType={this._getDocByDocType}
            updateDocument={updateDocument}
            loadingDocumentIds={loadingDocumentIds}
            onViewOtherComments={this._onViewOtherComments}
            onAddComment={this._onAddComment}
            canAcceptOrRejectFile={this._userCanAcceptOrRejectFile({
              values
            })}
            showCommentSection={this._showCommentSection({ values })}
            allowFileReplace={this._allowFileReplace({ values })}
            fileMaxSize={fileMaxSize}
            fileSizeUnit={fileSizeUnit}
            extensions={extensions}
            allowAddComment={this._allowAddComment({ values })}
            newAlert={newAlert}
            proposalWasRejected={this._proposalWasRejected}
            userIsAspiringSupplier={userIsAspiringSupplier}
          />
        );
      case 1:
        return (
          <CategoriesAndLocationsStep
            classes={classes}
            values={values}
            setFieldValue={setFieldValue}
            proposalWasSent={proposalWasSent}
          />
        );
      case 2:
        return (
          <SaleChannelsStep
            classes={classes}
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            proposalWasSent={proposalWasSent}
            handleChangeOnlyDigits={this._handleChangeOnlyDigits}
          />
        );
      case 3:
        return (
          <CompanySalesStep
            classes={classes}
            values={values}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            newAlert={newAlert}
            proposalWasSent={proposalWasSent}
            onAddDocument={this._onAddDocument}
            canCreateDocuments={currentUser.canCreateSupplierProposalDocuments}
            handleFileChange={this._handleFileChange}
            fetchFileBase64={this._fetchFileBase64}
            handleFileError={this._handleFileError}
            handleFileAcceptanceChange={this._handleFileAcceptanceChange}
            getDocByDocType={this._getDocByDocType}
            updateDocument={updateDocument}
            loadingDocumentIds={loadingDocumentIds}
            onViewOtherComments={this._onViewOtherComments}
            onAddComment={this._onAddComment}
            canAcceptOrRejectFile={this._userCanAcceptOrRejectFile({
              values
            })}
            showCommentSection={this._showCommentSection({ values })}
            allowFileReplace={this._allowFileReplace({ values })}
            fileMaxSize={fileMaxSize}
            fileSizeUnit={fileSizeUnit}
            extensions={extensions}
            allowAddComment={this._allowAddComment({ values })}
            proposalAccepted={this._proposalWasAccepted({ values })}
            proposalRejected={this._proposalWasRejected({ values })}
            handleChangeOnlyDigits={this._handleChangeOnlyDigits}
          />
        );
      case 4:
        return (
          <SupplierDataStep
            classes={classes}
            fileMaxSize={fileMaxSize}
            fileSizeUnit={fileSizeUnit}
            supplierFormDocExtensions={extensions}
            extensions={extensions}
            allowFileReplace={this._allowFileReplace({ values })}
            newAlert={newAlert}
            setFieldValue={setFieldValue}
            values={values}
            updateDocument={updateDocument}
            loadingDocumentIds={loadingDocumentIds}
            onViewOtherComments={this._onViewOtherComments}
            onAddComment={this._onAddComment}
            canAcceptOrRejectFile={this._userCanAcceptOrRejectFile({
              values
            })}
            showCommentSection={this._showCommentSection({ values })}
            canCreateDocuments={currentUser.canCreateSupplierProposalDocuments}
            handleFileChange={this._handleFileChange}
            fetchFileBase64={this._fetchFileBase64}
            handleFileError={this._handleFileError}
            handleFileAcceptanceChange={this._handleFileAcceptanceChange}
            getDocByDocType={this._getDocByDocType}
            onAddDocument={this._onAddDocument}
            allowAddComment={this._allowAddComment({ values })}
            proposalRejected={this._proposalWasRejected({ values })}
          />
        );
      case 5:
        return (
          <MinuteStep
            classes={classes}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            allowFileReplace={this._allowFileReplace({ values })}
            fileMaxSize={fileMaxSize}
            fileSizeUnit={fileSizeUnit}
            minuteExtensions={['docx', 'doc']}
            extensions={extensions}
            newAlert={newAlert}
            setFieldValue={setFieldValue}
            updateDocument={updateDocument}
            loadingDocumentIds={loadingDocumentIds}
            onViewOtherComments={this._onViewOtherComments}
            onAddComment={this._onAddComment}
            canAcceptOrRejectFile={this._userCanAcceptOrRejectFile({
              values
            })}
            showCommentSection={this._showCommentSection({ values })}
            handleFileChange={this._handleFileChange}
            fetchFileBase64={this._fetchFileBase64}
            handleFileError={this._handleFileError}
            handleFileAcceptanceChange={this._handleFileAcceptanceChange}
            getDocByDocType={this._getDocByDocType}
            allowAddComment={this._allowAddComment({ values })}
            openCreateProductModal={product =>
              setProductModalOpen(true, product)
            }
            fetchingCategories={fetchingOrgData}
            canCreateDocuments={currentUser.canCreateSupplierProposalDocuments}
            onAddDocument={this._onAddDocument}
            minuteWasSend={this._minuteWasSend({ values })}
            proposalRejected={this._proposalWasRejected({ values })}
          />
        );
      case 6:
        return (
          <MinuteFieldsStep
            classes={classes}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            userIsAspiringSupplier={userIsAspiringSupplier}
            proposalRejected={this._proposalWasRejected({ values })}
          />
        );
      case 7:
        return (
          <SignersStep
            classes={classes}
            values={values}
            setFieldValue={setFieldValue}
            userIsAspiringSupplier={userIsAspiringSupplier}
            openCreateSignerModal={signer => setSignerModalOpen(true, signer)}
            proposalRejected={this._proposalWasRejected({ values })}
          />
        );
      case 8:
        return (
          <ConfigurationTemplatesStep
            classes={classes}
            values={values}
            setFieldValue={setFieldValue}
            getDocByDocType={this._getDocByDocType}
            allowAddComment={this._allowAddComment({ values })}
            allowFileReplace={this._allowFileReplace({ values })}
            handleFileChange={this._handleFileChange}
            fetchFileBase64={this._fetchFileBase64}
            handleFileError={this._handleFileError}
            canAcceptOrRejectFile={this._userCanAcceptOrRejectFile({
              values
            })}
            handleFileAcceptanceChange={this._handleFileAcceptanceChange}
            onViewOtherComments={this._onViewOtherComments}
            onAddComment={this._onAddComment}
            showCommentSection={this._showCommentSection({ values })}
            fileMaxSize={fileMaxSize}
            fileSizeUnit={fileSizeUnit}
            extensions={['xlsx', 'xls']}
            newAlert={newAlert}
            updateDocument={updateDocument}
            loadingDocumentIds={loadingDocumentIds}
          />
        );
      default:
        throw new Error('Invalid step');
    }
  };

  _onSave = (params = {}, onComplete = null) => {
    const { proposal, createProposal, updateProposal, activeStep } = this.props;
    const method = _isEmpty(proposal) ? 'create' : 'update';
    const {
      assignExecutive = false,
      action,
      values,
      setFieldValue,
      sendMinute = false,
      acceptProposal = false,
      stepKey = activeStep,
      notifyComments = false,
      sendConfigurationTemplates = false,
      generateMinute = false,
      createdOnExternalSystem = false,
      minuteSigned = false,
      sendIncompleteProposalEmail = false
    } = params;
    if (method === 'create') {
      createProposal({
        values: {
          ...values,
          nit: values.nit.toString()
        },
        action,
        activeStep: stepKey,
        maxStep: this._maxStep(),
        onComplete
      });
    } else {
      updateProposal({
        id: proposal.id,
        values: {
          ...values,
          nit: values.nit.toString(),
          assignExecutive,
          sendMinute,
          acceptProposal,
          notifyComments,
          sendConfigurationTemplates,
          generateMinute,
          createdOnExternalSystem,
          minuteSigned,
          sendIncompleteProposalEmail,
          commission: values.commission === '' ? null : values.commission,
          documents: [...values.documents, ...values.generalDataDocuments]
        },
        action,
        activeStep: stepKey,
        maxStep: this._maxStep(),
        updateValues: respProp => {
          setFieldValue(
            'documents',
            respProp.documents.filter(
              doc => !GENERAL_DATA_DOCUMENTS.includes(doc.docType)
            )
          );
          setFieldValue(
            'generalDataDocuments',
            this.filterGeneralDataDocuments(respProp.documents)
          );
          setFieldValue('committeeDate', respProp.committeeDate);
          setFieldValue('acceptedBy', respProp.acceptedBy);
          setFieldValue('minuteSentAt', respProp.minuteSentAt);
          setFieldValue(
            'roleNameOfRoleInTurnInMinute',
            respProp.roleNameOfRoleInTurnInMinute
          );
          setFieldValue('minuteAcceptedBy', respProp.minuteAcceptedBy);
          setFieldValue('rejectionMotive', respProp.rejectionMotive);
          setFieldValue('rejectionExplanation', respProp.rejectionExplanation);
          setFieldValue(
            'configurationTemplatesSentAt',
            respProp.configurationTemplatesSentAt
          );
          setFieldValue('minuteGeneratedAt', respProp.minuteGeneratedAt);
          setFieldValue(
            'createdOnExternalSystemAt',
            respProp.createdOnExternalSystemAt
          );
          setFieldValue('minuteSignedAt', respProp.minuteSignedAt);
          this.setState({
            steps: this._getSteps(respProp)
          });
        }
      });
    }
  };

  _onContinue = (values, setFieldValue) => {
    const { activeStep, setCreationFormActiveStep, goToIndex } = this.props;
    const persistenceEnabled = this._isPersistenceEnabled({ values });
    if (activeStep === this._maxStep()) {
      const proposalWasSent = this._proposalWasSent();
      if (proposalWasSent) {
        if (persistenceEnabled) {
          this._onSave({ action: 'continue', values, setFieldValue });
        } else {
          goToIndex();
        }
      } else {
        this.setState({ submitConfirmationModalOpen: true });
      }
    } else {
      if (persistenceEnabled) {
        this._onSave({ action: 'continue', values, setFieldValue });
      } else {
        setCreationFormActiveStep(activeStep + 1);
      }
    }
  };

  _goBack = () => {
    const { goToIndex, activeStep, setCreationFormActiveStep } = this.props;
    if (activeStep === 0) {
      goToIndex();
    } else {
      setCreationFormActiveStep(activeStep - 1);
    }
  };

  _isContinueButtonDisabled = params => {
    if (this._isSaveButtonSubmitting()) return true;
    const { values, errors, touched } = params;
    if (this._proposalWasRejected({ values })) return false;
    const { activeStep } = this.props;
    if (this._touchedFieldsHasErrors({ touched, errors })) return true;
    return this._stepIncomplete({ stepKey: activeStep, values });
  };

  _isSaveButtonDisabled = params => {
    const { values, errors, touched } = params;
    const persistenceEnabled = this._isPersistenceEnabled({ values });
    if (!persistenceEnabled) return true;
    if (this._touchedFieldsHasErrors({ touched, errors })) return true;
    return (
      !values.companyName || !values.nit || this._isContinueButtonSubmitting()
    );
  };

  _isSaveButtonSubmitting = () => {
    const {
      creatingProposal,
      updatingProposal,
      action,
      updatingLineExecutive,
      deletingProposal,
      generatingMinute
    } = this.props;
    if (updatingLineExecutive || deletingProposal || generatingMinute)
      return true;
    return action === 'save' && (creatingProposal || updatingProposal);
  };

  _isContinueButtonSubmitting = () => {
    const { creatingProposal, updatingProposal, action } = this.props;
    return action === 'continue' && (creatingProposal || updatingProposal);
  };

  _getFormInitialValues = proposal => {
    if (_isEmpty(proposal)) {
      return {
        companyName: proposal.companyName || '',
        businessName: proposal.businessName || '',
        nit: proposal.nit || '',
        companySeniority: proposal.companySeniority || '',
        positionInCompany: proposal.positionInCompany || '',
        generalDataDocuments: this.fillGeneralDataDocuments(),
        documents: [],
        companySize: proposal.companySize
      };
    } else {
      return {
        lineExecutiveId: proposal.lineExecutiveId,
        companyName: proposal.companyName || '',
        businessName: proposal.businessName || '',
        nit: proposal.nit || '',
        companySeniority: proposal.companySeniority || '',
        positionInCompany: proposal.positionInCompany || '',
        atlanticoSalePercentage: proposal.atlanticoSalePercentage,
        magdalenaSalePercentage: proposal.magdalenaSalePercentage,
        cesarSalePercentage: proposal.cesarSalePercentage,
        otherLocationSalePercentage: proposal.otherLocationSalePercentage,
        coveragePercentageTotal:
          Number(proposal.atlanticoSalePercentage) +
          Number(proposal.magdalenaSalePercentage) +
          Number(proposal.cesarSalePercentage) +
          Number(proposal.otherLocationSalePercentage),
        categories: [...proposal.categories],
        categoriesPercentageTotal: proposal.categories.reduce(
          (acum, category) => {
            if (!category.percentage) return acum + 0;
            return acum + category.percentage;
          },
          Number(proposal.otherCategorySalePercentage)
        ),
        otherCategorySalePercentage: proposal.otherCategorySalePercentage,
        otherCategoryName: proposal.otherCategoryName || '',
        officeQuantity: proposal.officeQuantity,
        faceToFaceSalePercentage: proposal.faceToFaceSalePercentage,
        teleshoppingSalePercentage: proposal.teleshoppingSalePercentage,
        webPageSalePercentage: proposal.webPageSalePercentage,
        socialMediaSalePercentage: proposal.socialMediaSalePercentage,
        doorToDoorSalePercentage: proposal.doorToDoorSalePercentage,
        catalogueSalePercentage: proposal.catalogueSalePercentage,
        otherChannelSalePercentage: proposal.otherChannelSalePercentage,
        otherChannelName: proposal.otherChannelName || '',
        saleChannelPercentageTotal:
          Number(proposal.faceToFaceSalePercentage) +
          Number(proposal.teleshoppingSalePercentage) +
          Number(proposal.webPageSalePercentage) +
          Number(proposal.socialMediaSalePercentage) +
          Number(proposal.doorToDoorSalePercentage) +
          Number(proposal.catalogueSalePercentage) +
          Number(proposal.otherChannelSalePercentage),
        lastYearSalesAverage: proposal.lastYearSalesAverage,
        penultimateYearSalesAverage: proposal.penultimateYearSalesAverage,
        companySize: parseInt(proposal.companySize) || null,
        documents: proposal.documents.filter(
          doc => !GENERAL_DATA_DOCUMENTS.includes(doc.docType)
        ),
        generalDataDocuments: this.filterGeneralDataDocuments(
          proposal.documents
        ),
        acceptedBy: proposal.acceptedBy,
        committeeDate: proposal.committeeDate,
        minuteType: proposal.minuteType,
        commission: proposal.commission,
        minuteSentAt: proposal.minuteSentAt,
        roleNameOfRoleInTurnInMinute: proposal.roleNameOfRoleInTurnInMinute,
        products: [...proposal.products],
        minuteAcceptedBy: proposal.minuteAcceptedBy,
        legalRepresentativeName: proposal.legalRepresentativeName || '',
        legalRepresentativeIdentificationType:
          proposal.legalRepresentativeIdentificationType || '',
        legalRepresentativeIdentification:
          proposal.legalRepresentativeIdentification || '',
        legalRepresentativeCity: proposal.legalRepresentativeCity || '',
        contactAddress: proposal.contactAddress || '',
        contactCity: proposal.contactCity || '',
        contactPhone: proposal.contactPhone || '',
        chamberOfCommerceCity: proposal.chamberOfCommerceCity || '',
        signers: [...proposal.signers],
        rejectionMotive: proposal.rejectionMotive,
        rejectionExplanation: proposal.rejectionExplanation,
        configurationTemplatesSentAt: proposal.configurationTemplatesSentAt,
        canGenerateMinute: proposal.canGenerateMinute,
        minuteGeneratedAt: proposal.minuteGeneratedAt,
        createdOnExternalSystemAt: proposal.createdOnExternalSystemAt,
        minuteSignedAt: proposal.minuteSignedAt
      };
    }
  };

  fillGeneralDataDocuments = () => {
    return [
      {
        originalFileName: '',
        name: BRAND_CERTIFICATE_NAME,
        docType: SUPPLIER_PROPOSAL_DOCUMENT_TYPES.brandCertificate,
        comments: []
      },
      {
        originalFileName: '',
        name: BANK_CERTIFICATE,
        docType: SUPPLIER_PROPOSAL_DOCUMENT_TYPES.bankCertificate,
        comments: []
      },
      {
        originalFileName: '',
        name: PERSONAL_DATA_POLICY,
        docType: SUPPLIER_PROPOSAL_DOCUMENT_TYPES.personalDataPolicy,
        comments: []
      },
      {
        originalFileName: '',
        name: BUSINESS_LOGO,
        docType: SUPPLIER_PROPOSAL_DOCUMENT_TYPES.businessLogo,
        comments: []
      },
      {
        originalFileName: '',
        name: SUPPLIER_REGISTER_FILE,
        docType: SUPPLIER_PROPOSAL_DOCUMENT_TYPES.supplierRegisterFile,
        comments: []
      },
      {
        originalFileName: '',
        name: THIRD_LEVEL_SHAREHOLDER_COMPOSITION,
        docType:
          SUPPLIER_PROPOSAL_DOCUMENT_TYPES.thirdLevelShareholderComposition,
        comments: []
      }
    ];
  };

  _isPersistenceEnabled = params => {
    const { activeStep } = this.props;
    const { values, stepKey = activeStep } = params;
    if (this._proposalIsNew()) return true;
    if (this._proposalWasRejected({ values })) return false;
    if (stepKey === 1 || stepKey === 2) return !this._proposalWasSent();
    if (stepKey === 0 || stepKey === 3)
      return !this._proposalWasAccepted({ values });
    if (stepKey === 4) {
      return !values.configurationTemplatesSentAt;
    }
    if (stepKey === 5) {
      if (!this._minuteWasSend({ values })) return true;
      if (values.minuteAcceptedBy) return false;
      if (!this._userIsAspiringSupplier()) return true;
      return this._isCurrentUserInTurnInMinute({ values });
    }
    if (stepKey === 6) {
      return this._userIsAspiringSupplier();
    }
    if (stepKey === 7) return false;
    return true;
  };

  _continueButtonText = () => {
    const { activeStep } = this.props;
    if (activeStep !== this._maxStep()) return 'Continuar';
    return 'Finalizar';
  };

  _onAddComment = params => message => {
    const { documentId, setFieldValue, values, isGeneralDataDocument } = params;
    const newComment = {
      documentId,
      message,
      createdAt: moment().format('YYYY-MM-DD HH:mm:ss:SSS'),
      displayCreatedAt: moment().format('YYYY-MM-DD')
    };
    const documents = isGeneralDataDocument
      ? values.generalDataDocuments
      : values.documents;
    const newDocuments = documents.map(doc => {
      const newDoc = { ...doc };
      if (newDoc.id === documentId) {
        newDoc.comments = [newComment, ...doc.comments];
      }
      return newDoc;
    });
    setFieldValue(
      isGeneralDataDocument ? 'generalDataDocuments' : 'documents',
      newDocuments
    );
  };

  _onViewOtherComments = comments => {
    this.setState({
      listOtherCommentsModalOpen: true,
      otherComments: comments
    });
  };

  _addLoadingDocumentId = id => {
    const { loadingDocumentIds } = this.state;
    this.setState({ loadingDocumentIds: [...loadingDocumentIds, id] });
  };

  _removeLoadingDocumentId = id => {
    const { loadingDocumentIds } = this.state;
    this.setState({
      loadingDocumentIds: loadingDocumentIds.filter(
        currentDocId => currentDocId !== id
      )
    });
  };

  _allowAddComment = params => doc => {
    const { values } = params;
    if (this._proposalWasRejected({ values })) return false;
    if (SUPPLIER_PROPOSAL_DOCUMENT_TYPES.Minute === doc.docType) {
      if (values.minuteAcceptedBy) return false;
      return this._isCurrentUserInTurnInMinute({ values });
    }
    return !doc.accepted;
  };

  _onAddDocument = docType => () => {
    const { setAddDocumentModalOpen } = this.props;
    setAddDocumentModalOpen(true);
    this.setState({ newDocumentType: docType });
  };

  _userCanAcceptOrRejectFile = params => {
    if (this._userIsAspiringSupplier()) return false;
    const { activeStep } = this.props;
    const { values } = params;
    if (this._proposalWasRejected({ values })) return false;
    if (activeStep === 3) return !this._proposalWasAccepted({ values });
    if (activeStep === 4) return !values.configurationTemplatesSentAt;
    if (activeStep === 5) return !values.minuteAcceptedBy;
    return true;
  };

  _showCommentSection = params => doc => {
    if (!this._proposalWasSent()) return false;
    const { values } = params;
    if (this._proposalWasRejected({ values })) return doc.comments.length >= 1;
    if (SUPPLIER_PROPOSAL_DOCUMENT_TYPES.Minute === doc.docType) {
      if (values.minuteAcceptedBy) return false;
      if (this._isCurrentUserInTurnInMinute({ values })) return true;
      return doc.comments.length >= 1;
    }
    if (this._userIsAspiringSupplier() && doc.comments.length === 0)
      return false;
    if (doc.accepted) return doc.comments.length >= 1;
    return true;
  };

  _allowFileReplace = params => document => {
    if (!this._proposalWasSent()) return true;
    const { values } = params;
    if (this._proposalWasRejected({ values })) return false;
    const existingDocumentsBeforeSendProposal = [
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.LastYearFinancialStatement,
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.PenultimateYearFinancialStatement,
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.CommercialBrochure
    ];
    if (existingDocumentsBeforeSendProposal.includes(document.docType)) {
      if (!this._userIsAspiringSupplier()) return false;
      return document.accepted === false;
    }
    if (SUPPLIER_PROPOSAL_DOCUMENT_TYPES.Minute === document.docType) {
      if (values.minuteAcceptedBy) return false;
      const otherMinuteTypeId = SUPPLIER_PROPOSAL_MINUTE_TYPES.find(
        minType => minType.name === 'Cargar otro tipo de minuta'
      ).id;
      if (values.minuteType === otherMinuteTypeId) {
        if (!this._minuteWasSend({ values })) return true;
      }
      return this._isCurrentUserInTurnInMinute({ values });
    }
    if (this._supplierDataStepDocTypes().includes(document.docType)) {
      if (values.configurationTemplatesSentAt) return false;
    }
    if (!this._userIsAspiringSupplier()) return false;
    return document.accepted !== true;
  };

  _proposalWasSent = () => {
    const { proposal } = this.props;
    return !!proposal && !!proposal.lineExecutiveId;
  };

  _proposalWasAccepted = params => {
    const { values } = params;
    return !!values.acceptedBy;
  };

  _proposalWasRejected = params => {
    const { values } = params;
    return !!values.rejectionMotive;
  };

  _minuteWasSend = params => {
    const { values } = params;
    return !!values.minuteSentAt;
  };

  _showAcceptProposalButton = params => {
    if (this._userIsAspiringSupplier()) return false;
    const { activeStep } = this.props;
    const { values } = params;
    if (this._proposalWasRejected({ values })) return false;
    return activeStep === 3 && !this._proposalWasAccepted({ values });
  };

  _showRejectProposalButton = params => {
    if (this._userIsAspiringSupplier()) return false;
    const { activeStep } = this.props;
    const { values } = params;
    if (values.configurationTemplatesSentAt) return false;
    if (this._proposalWasRejected({ values })) return false;
    if (activeStep === 5) {
      return !this._minuteWasSend({ values }) || !!values.minuteAcceptedBy;
    }
    return true;
  };

  _acceptProposalButtonDisabled = values => {
    if (!values.documents) return true;
    return values.documents
      .filter(document => {
        return [
          SUPPLIER_PROPOSAL_DOCUMENT_TYPES.LastYearFinancialStatement,
          SUPPLIER_PROPOSAL_DOCUMENT_TYPES.PenultimateYearFinancialStatement,
          SUPPLIER_PROPOSAL_DOCUMENT_TYPES.CommercialBrochure,
          SUPPLIER_PROPOSAL_DOCUMENT_TYPES.AdditionalInCompanySalesStep,
          ...GENERAL_DATA_DOCUMENTS
        ].includes(document.docType);
      })
      .some(document => {
        const optionalDocuments = [
          SUPPLIER_PROPOSAL_DOCUMENT_TYPES.CommercialBrochure
        ];
        if (optionalDocuments.includes(document.docType)) {
          if (document.originalFileName) {
            return !document.accepted;
          } else {
            return false;
          }
        }
        return !document.accepted;
      });
  };

  _onAcceptProposal = () => {
    this.setState({ acceptProposalModalOpen: true });
  };

  _onRejectProposal = () => {
    this.setState({ rejectProposalModalOpen: true });
  };

  _getSteps = proposal => {
    const values = this._getFormInitialValues(proposal);
    const steps = [
      {
        key: 0,
        label: 'Datos generales',
        completed:
          !this._proposalIsNew() &&
          !this._stepIncomplete({ stepKey: 0, values })
      },
      {
        key: 1,
        label: 'Categorias y ubicación',
        completed:
          !this._proposalIsNew() &&
          !this._stepIncomplete({ stepKey: 1, values })
      },
      {
        key: 2,
        label: 'Canales de venta',
        completed:
          !this._proposalIsNew() &&
          !this._stepIncomplete({ stepKey: 2, values })
      },
      {
        key: 3,
        label: 'Ventas de la empresa',
        completed:
          !this._proposalIsNew() &&
          !this._stepIncomplete({ stepKey: 3, values })
      }
    ];
    let skipNextSteps = !this._proposalWasAccepted({ values });
    if (skipNextSteps) return steps;

    steps.push({
      key: 4,
      label: 'Registro de aliado / proveedor',
      completed: !this._stepIncomplete({ stepKey: 4, values })
    });
    skipNextSteps =
      this._userIsAspiringSupplier() && !this._minuteWasSend({ values });
    if (skipNextSteps) return steps;

    steps.push({
      key: 5,
      label: 'Minuta de convenio',
      completed: !this._stepIncomplete({ stepKey: 5, values })
    });
    skipNextSteps = !values.minuteAcceptedBy;
    if (skipNextSteps) return steps;

    steps.push({
      key: 6,
      label: 'Campos de Minuta',
      completed: !this._stepIncomplete({ stepKey: 6, values })
    });
    steps.push({
      key: 7,
      label: 'Firmantes',
      completed: !this._stepIncomplete({ stepKey: 7, values })
    });
    skipNextSteps = !values.configurationTemplatesSentAt;
    if (skipNextSteps) return steps;

    steps.push({
      key: 8,
      label: 'Plantillas de configuración',
      completed: !this._stepIncomplete({ stepKey: 8, values })
    });

    return steps;
  };

  _maxStep = () => {
    const { steps } = this.state;
    return steps.length - 1;
  };

  _showSendMinuteButton = params => {
    if (this._userIsAspiringSupplier()) return false;
    const { activeStep } = this.props;
    if (activeStep !== 5) return false;
    const { values } = params;
    if (this._proposalWasRejected({ values })) return false;
    if (this._minuteWasSend({ values })) return false;
    if (!values.minuteType) return false;
    const otherMinuteTypeId = SUPPLIER_PROPOSAL_MINUTE_TYPES.find(
      minType => minType.name === 'Cargar otro tipo de minuta'
    ).id;
    if (values.minuteType === otherMinuteTypeId) {
      const minuteFileIsSet = values.documents.some(document => {
        return (
          SUPPLIER_PROPOSAL_DOCUMENT_TYPES.Minute === document.docType &&
          document.originalFileName
        );
      });
      if (!minuteFileIsSet) return false;
    }
    return !!values.commission && !this._productsAreEmpty({ values });
  };

  _userIsAspiringSupplier = () => {
    const { currentUser } = this.props;
    return currentUser.roles.some(role => 'aliado_aspirante' === role.name);
  };

  _onSendMinute = () => {
    this.setState({ generateMinuteModalOpen: true });
  };

  _showMinuteAcceptanceButtons = params => {
    const { activeStep } = this.props;
    if (activeStep !== 5) return false;
    const { values } = params;
    if (this._proposalWasRejected({ values })) return false;
    if (!this._minuteWasSend({ values })) return false;
    if (values.minuteAcceptedBy) return false;
    return this._isCurrentUserInTurnInMinute({ values });
  };

  _isCurrentUserInTurnInMinute = params => {
    const { values } = params;
    if (this._userIsAspiringSupplier()) {
      return values.roleNameOfRoleInTurnInMinute === 'aliado_aspirante';
    }
    return values.roleNameOfRoleInTurnInMinute === 'ejecutivo_de_lineas';
  };

  _onAcceptMinute = params => () => {
    const { values, setFieldValue } = params;
    const { currentUser } = this.props;
    this._onSave({
      action: 'save',
      values: {
        ...values,
        minuteAcceptedBy: currentUser.id
      },
      setFieldValue
    });
  };

  _onRejectMinute = () => {
    this.setState({ rejectMinuteModalOpen: true });
  };

  _onGiveUpMinuteTurn = params => () => {
    const { values, setFieldValue } = params;
    const newRoleInTurn =
      values.roleNameOfRoleInTurnInMinute === 'aliado_aspirante'
        ? 'ejecutivo_de_lineas'
        : 'aliado_aspirante';
    this._onSave({
      action: 'save',
      values: {
        ...values,
        roleNameOfRoleInTurnInMinute: newRoleInTurn
      },
      setFieldValue
    });
  };

  _acceptMinuteButtonDisabled = params => {
    const { values } = params;
    if (this._proposalIsNew()) return true;
    if (!values.documents) return true;
    return values.documents.some(document => {
      if (SUPPLIER_PROPOSAL_DOCUMENT_TYPES.Minute === document.docType) {
        return !document.originalFileName;
      }
      return (
        SUPPLIER_PROPOSAL_DOCUMENT_TYPES.AdditionalInMinuteStep ===
          document.docType && !document.accepted
      );
    });
  };

  _handleFileChange = props => file => {
    const { proposal, updateDocument } = this.props;
    if (_isEmpty(proposal)) {
      this._onSave(
        {
          values: props.values,
          action: 'save'
        },
        (documents, proposalId) => {
          props.setFieldValue(
            'generalDataDocuments',
            this.filterGeneralDataDocuments(documents)
          );
          this.handleUpdateDocument({
            ...props,
            document: {
              ...props.document,
              id: documents.find(doc => doc.docType === props.document.docType)
                .id
            },
            documents,
            updateDocument,
            file,
            isOnCreateProposal: true,
            proposalId
          });
        }
      );

      return;
    }

    this.handleUpdateDocument({
      ...props,
      updateDocument,
      file,
      isOnCreateProposal: false,
      proposalId: null
    });
  };

  handleUpdateDocument = params => {
    const {
      document,
      newAlert,
      setFieldValue,
      updateDocument,
      values,
      file,
      isOnCreateProposal,
      proposalId
    } = params;

    if (document.id) {
      if (file.base64String) {
        newAlert('success', 'sucess', 'Archivo cargado correctamente');
      }
      this._addLoadingDocumentId(document.id);

      updateDocument({
        document: {
          ...document,
          base64: file.base64String,
          originalFileName: file.fileName
        },
        documents: params.documents
          ? params.documents
          : [...values.documents, ...values.generalDataDocuments],
        isOnCreateProposal,
        proposalId,
        setDocuments: newDocs => {
          this._removeLoadingDocumentId(document.id);

          setFieldValue(
            'documents',
            newDocs.filter(doc => !GENERAL_DATA_DOCUMENTS.includes(doc.docType))
          );
          setFieldValue(
            'generalDataDocuments',
            this.filterGeneralDataDocuments(newDocs)
          );
        }
      });
    }
  };

  _fetchFileBase64 = params => () => {
    const { document } = params;
    const { fetchDocument } = this.props;
    this._addLoadingDocumentId(document.id);
    fetchDocument({
      documentId: document.id,
      removeLoadingDocumentId: () => {
        this._removeLoadingDocumentId(document.id);
      }
    });
  };

  _handleFileError = props => errorMessage => {
    const { newAlert, fileMaxSize, fileSizeUnit } = props;
    if (errorMessage.includes('Must upload a file of type')) {
      newAlert('error', 'ERROR:', 'El tipo de archivo es inválido');
    } else if (errorMessage.includes('File size must be less than')) {
      newAlert(
        'error',
        'ERROR:',
        `El archivo debe pesar maximo ${fileMaxSize}${fileSizeUnit}`
      );
    } else {
      newAlert('error', 'ERROR:', 'Error al cargar el archivo');
    }
  };

  _handleFileAcceptanceChange = props => fileAccepted => {
    const { documentId, setFieldValue, values, isGeneralDataDocument } = props;
    const documents = isGeneralDataDocument
      ? values.generalDataDocuments
      : values.documents;
    const newDocuments = documents.map(document => {
      const newDoc = { ...document };
      if (document.id.toString() === documentId.toString()) {
        newDoc.accepted = fileAccepted;
      }
      return newDoc;
    });
    setFieldValue(
      isGeneralDataDocument ? 'generalDataDocuments' : 'documents',
      newDocuments
    );
  };

  _getDocByDocType = (docType, documents) => {
    const document = documents.find(doc => {
      return doc.docType === docType;
    });
    return document;
  };

  _onSaveProduct = params => {
    const { productData, values, setFieldValue } = params;
    const { createProduct, updateProduct } = this.props;
    if (productData.id) {
      updateProduct({
        product: productData,
        setProducts: respProduct => {
          const newProducts = values.products.map(product => {
            const newProduct = { ...product };
            if (newProduct.id.toString() === respProduct.id.toString()) {
              newProduct.name = respProduct.name;
              newProduct.location = respProduct.location;
              newProduct.channel = respProduct.channel;
            }
            return newProduct;
          });
          setFieldValue('products', newProducts);
        }
      });
    } else {
      createProduct({
        product: productData,
        setProducts: respProduct => {
          const newProduct = {
            id: respProduct.id,
            name: respProduct.name,
            location: respProduct.location,
            channel: respProduct.channel
          };
          const newProducts = [newProduct, ...values.products];
          setFieldValue('products', newProducts);
        }
      });
    }
  };

  _onSaveSigner = params => {
    const { signerData, values, setFieldValue } = params;
    const { createSigner, updateSigner } = this.props;
    if (signerData.id) {
      updateSigner({
        signer: signerData,
        setSigners: respSigner => {
          const newSigners = values.signers.map(signer => {
            const newSigner = { ...signer };
            if (newSigner.id.toString() === respSigner.id.toString()) {
              newSigner.name = respSigner.name;
              newSigner.identificationType = respSigner.identificationType;
              newSigner.identification = respSigner.identification;
              newSigner.email = respSigner.email;
              newSigner.phone = respSigner.phone;
              newSigner.signerType = respSigner.signerType;
            }
            return newSigner;
          });
          setFieldValue('signers', newSigners);
        }
      });
    } else {
      createSigner({
        signer: signerData,
        setSigners: respSigner => {
          const newSigner = {
            id: respSigner.id,
            name: respSigner.name,
            identificationType: respSigner.identificationType,
            identification: respSigner.identification,
            email: respSigner.email,
            phone: respSigner.phone,
            signerType: respSigner.signerType
          };
          const newSigners = [newSigner, ...values.signers];
          setFieldValue('signers', newSigners);
        }
      });
    }
  };

  _childrenCategories = () => {
    const { categories } = this.props;
    return categories.filter(c => c.depth > 0 && c.active === true);
  };

  _proposalIsNew = () => {
    const { proposal } = this.props;
    return _isEmpty(proposal);
  };

  _handleChangeOnlyDigits = (event, fieldName, setFieldValue) => {
    let change = event.target.value.replace(/\D/g, '');
    if (change !== '') change = Number(change).toString();
    setFieldValue(fieldName, change);
  };

  _onStepLabelClick = params => () => {
    const { step, values, setFieldValue } = params;
    const { setCreationFormActiveStep, activeStep, newAlert } = this.props;
    if (activeStep === step.key) return;
    if (this._stepIncomplete({ stepKey: activeStep, values })) {
      newAlert(
        'error',
        'ERROR:',
        'Existen campos requeridos incompletos. Para seguir debe llenarlos.'
      );
      return;
    }

    const goToStep = step.completed;
    if (goToStep) {
      if (this._isPersistenceEnabled({ values, stepKey: activeStep })) {
        this._onSave({
          action: 'save',
          values,
          setFieldValue,
          stepKey: step.key
        });
      } else {
        setCreationFormActiveStep(step.key);
      }
    }
  };

  _stepIncomplete = params => {
    const { stepKey, values } = params;
    const { submitConfirmationModalOpen } = this.state;
    const someSupplierDataStepDocIsEmpty = (values.documents || []).some(
      document => {
        return (
          this._supplierDataStepDocTypes().includes(document.docType) &&
          !document.originalFileName
        );
      }
    );
    switch (stepKey) {
      case 0:
        return (
          !values.companyName ||
          !values.nit ||
          !values.businessName ||
          !values.companySeniority ||
          !values.positionInCompany ||
          values.generalDataDocuments.some(doc => {
            return !doc.originalFileName;
          }) ||
          !values.companySize
        );
      case 1:
        return (
          values.coveragePercentageTotal !== 100 ||
          values.categoriesPercentageTotal !== 100 ||
          (!!values.otherCategorySalePercentage && !values.otherCategoryName)
        );
      case 2:
        return (
          !values.officeQuantity ||
          values.saleChannelPercentageTotal !== 100 ||
          (!!values.otherChannelSalePercentage && !values.otherChannelName)
        );
      case 3:
        const lastYearFinancialStatementDocLoaded = values.documents.some(
          document => {
            return (
              document.docType ===
                SUPPLIER_PROPOSAL_DOCUMENT_TYPES.LastYearFinancialStatement &&
              !!document.originalFileName
            );
          }
        );
        const penultimateYearFinancialStatementDocLoaded = values.documents.some(
          document => {
            return (
              document.docType ===
                SUPPLIER_PROPOSAL_DOCUMENT_TYPES.PenultimateYearFinancialStatement &&
              !!document.originalFileName
            );
          }
        );
        return (
          !values.lastYearSalesAverage ||
          !values.penultimateYearSalesAverage ||
          !lastYearFinancialStatementDocLoaded ||
          !penultimateYearFinancialStatementDocLoaded ||
          submitConfirmationModalOpen
        );
      case 4:
        if (!this._userIsAspiringSupplier()) return false;
        return someSupplierDataStepDocIsEmpty;
      case 5:
        if (this._userIsAspiringSupplier()) {
          return (
            someSupplierDataStepDocIsEmpty ||
            !values.minuteType ||
            !values.commission ||
            this._productsAreEmpty({ values })
          );
        }
        return (
          !values.minuteType ||
          !values.commission ||
          this._productsAreEmpty({ values })
        );
      case 6:
        if (!this._userIsAspiringSupplier()) return false;
        return this._minuteFieldsStepHasMissingFields({ values });
      case 7:
        if (!this._userIsAspiringSupplier()) return false;
        return this._minuteFieldsStepHasMissingFields({ values });
      case 8:
        if (!this._userIsAspiringSupplier()) return false;
        const configurationTemplatesDocIsEmpty = (values.documents || []).some(
          document => {
            return (
              document.docType ===
                SUPPLIER_PROPOSAL_DOCUMENT_TYPES.ConfigurationTemplates &&
              !document.originalFileName
            );
          }
        );
        return configurationTemplatesDocIsEmpty;
      default:
        throw new Error('Invalid step');
    }
  };

  _showNotifyCommentsButton = params => {
    if (this._userIsAspiringSupplier()) return false;
    const { activeStep } = this.props;
    const { values } = params;
    if (this._proposalWasRejected({ values })) return false;
    const validStep = [3, 4, 5, 8].includes(activeStep);
    if (!validStep) return false;
    if (activeStep === 5 && !this._minuteWasSend({ values })) return false;
    return this._isPersistenceEnabled({ values });
  };

  _onNotifyComments = () => () => {
    this.setState({ notifyCommentsModalOpen: true });
  };

  _showSendConfigurationTemplatesButton = params => {
    const { activeStep } = this.props;
    if (![4, 5].includes(activeStep)) return false;
    const { values } = params;
    if (this._proposalWasRejected({ values })) return false;
    if (values.configurationTemplatesSentAt) return false;
    if (!values.createdOnExternalSystemAt) return false;
    if (!values.minuteSignedAt) return false;
    return true;
  };

  _onSendConfigurationTemplates = params => () => {
    const { values, setFieldValue } = params;
    this._onSave({
      action: 'save',
      values,
      sendConfigurationTemplates: true,
      setFieldValue
    });
  };

  _showGenerateMinuteButton = params => {
    const { activeStep } = this.props;
    if (activeStep != 5) return false;
    const { values } = params;
    if (this._proposalWasRejected({ values })) return false;
    if (!this._proposalWasAccepted({ values })) return false;
    if (!values.minuteAcceptedBy) return false;
    if (this._userIsAspiringSupplier()) return false;
    if (this._minuteFieldsStepHasMissingFields({ values })) return false;
    return !values.minuteGeneratedAt && values.canGenerateMinute;
  };

  _onGenerateMinute = params => () => {
    this.setState({ generateMinuteModalOpen: true });
  };

  _supplierDataStepDocTypes = () => {
    return [
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.LegalRepresentativeIdentification,
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.Rut,
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.ChamberOfCommerce,
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.ShareholdingStructure,
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.SupplierForm,
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.BankCertification,
      SUPPLIER_PROPOSAL_DOCUMENT_TYPES.AdditionalInSupplierDataStep
    ];
  };

  _minuteFieldsStepHasMissingFields = params => {
    const { values } = params;
    return (
      !values.legalRepresentativeName ||
      !values.legalRepresentativeIdentificationType ||
      !values.legalRepresentativeIdentification ||
      !values.legalRepresentativeCity ||
      !values.contactAddress ||
      !values.contactCity ||
      !values.contactPhone ||
      !values.chamberOfCommerceCity
    );
  };

  _productsAreEmpty = params => {
    const { values } = params;
    const { products, minuteType } = values;
    const educationMinuteTypeId = SUPPLIER_PROPOSAL_MINUTE_TYPES.find(
      minType => minType.name === 'Educación'
    ).id;
    if (minuteType === educationMinuteTypeId) {
      return false;
    } else {
      return products.length === 0;
    }
  };

  _showChangeExecutiveField = params => {
    const { values } = params;
    if (this._proposalWasRejected({ values })) return false;
    const { currentUser } = this.props;
    return currentUser.roles.some(
      role => 'coordinador_de_estrategia' === role.name
    );
  };

  _handleLineExecutiveChange = params => event => {
    const { proposal, updateLineExecutive } = this.props;
    const lineExecutiveId = event.target.value;
    const { setFieldValue } = params;
    setFieldValue('lineExecutiveId', lineExecutiveId);
    updateLineExecutive({
      proposalId: proposal.id,
      lineExecutiveId
    });
  };

  _showDeleteProposalButton = () => {
    if (!this._userIsAspiringSupplier()) return false;
    if (this._proposalWasSent()) return false;
    const { proposal } = this.props;
    return !!proposal && !!proposal.id;
  };

  _onDeleteProposal = () => {
    this.setState({ deleteProposalConfirmationModalOpen: true });
  };

  _showSupplierCreatedOnExternalSystemButton = params => {
    const { values } = params;

    if (this._userIsAspiringSupplier()) return false;

    if (this._proposalWasRejected({ values })) return false;

    const { activeStep } = this.props;
    if (activeStep != 4) return false;

    const someSupplierDataStepDocIsNotAccepted = (values.documents || []).some(
      document => {
        return (
          this._supplierDataStepDocTypes().includes(document.docType) &&
          !document.accepted
        );
      }
    );
    if (someSupplierDataStepDocIsNotAccepted) return false;

    if (values.createdOnExternalSystemAt) return false;

    return true;
  };

  _onSupplierCreatedOnExternalSystem = params => () => {
    const { values, setFieldValue } = params;
    this._onSave({
      action: 'save',
      values,
      setFieldValue,
      createdOnExternalSystem: true
    });
  };

  _showMinuteSignedButton = params => {
    const { values } = params;

    if (this._userIsAspiringSupplier()) return false;

    if (this._proposalWasRejected({ values })) return false;

    const { activeStep } = this.props;
    if (activeStep != 5) return false;

    if (!values.minuteAcceptedBy) return false;

    if (values.canGenerateMinute && !values.minuteGeneratedAt) return false;

    if (values.minuteSignedAt) return false;

    return true;
  };

  _onMinuteSigned = params => () => {
    const { values, setFieldValue } = params;
    this._onSave({
      action: 'save',
      values,
      setFieldValue,
      minuteSigned: true
    });
  };

  _touchedFieldsHasErrors = params => {
    const { errors, touched } = params;
    return Object.keys(touched).some(
      touchedField => !_isEmpty(errors[touchedField])
    );
  };

  _getGenerateMinuteModalAction = params => {
    const { values } = params;
    if (!this._minuteWasSend({ values })) return 'enviar';
    return 'autocompletar';
  };

  filterGeneralDataDocuments = documents => {
    return documents
      .filter(doc => GENERAL_DATA_DOCUMENTS.includes(doc.docType))
      .sort((a, b) => a.docType - b.docType);
  };
}

export default withStyles(styles, { withTheme: true })(SupplierProposalCreator);
