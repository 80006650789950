import { SET_HOME_VISIT_LIST, UPDATED_HOME_VISIT, SET_HOME_VISIT_ARTICLES_LIST } from '../constants/action_constants';

const initialState = {
  homeVisits: [],
  meta: {},
  saved: false
};

export function homeVisits(state = initialState, action) {
  switch (action.type) {
    case SET_HOME_VISIT_LIST:
      return {
        ...state,
        homeVisits: action.data,
        meta: action.meta,
        saved: false
      };
    case UPDATED_HOME_VISIT:
        return {
          ...state,
          saved: true,
        };
    case SET_HOME_VISIT_ARTICLES_LIST:
        return {
          ...state,
          homeVisitArticles: action.data
        }
    default:
      return state;
  }
}
