import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _every from 'lodash/every';
import _find from 'lodash/find';
import { Grid, withStyles } from '@material-ui/core';
import SimpleTable from '../../../shared/Tables/SimpleTable';
import CustomDialog from '../../../shared/Modals/CustomDialog';
import Movements from '../../../shared/Movements/Movements';
import combineStyles from '../../../../helpers/combineStyles';
import TypographyStyles from '../../../../styles/typography';

class PointUserAssociationRevision extends Component {
  constructor(props) {
    super(props);
  }

  _handleSave = () => {
    const body = {
      id: this.props.pointUserAssociation.id
    };
    this.props.onSave(body);
  };
  _handleRevoke = () => { 
    const body = {
      id: this.props.pointUserAssociation.id
    };
    this.props.onRevoke(body);
  }

  _handleClose = () => {
    if (!this.props.requestApprove || !this.props.requestRevoke) {
      this.props.onClose();
    }
  };
  
  _modalProps = (pointUserAssociation) => {
    if (
      pointUserAssociation.status === 'Aprobada' ||
      pointUserAssociation.status === 'Rechazada'
    ) {
      return {
        title: 'Aprobación de asociación',
        onConfirm: () => null
      };
    }
    return {
      onConfirm: () => this._handleSave(),
      title: 'Aprobación de asociación',
      buttonText: 'Aprobar',
      buttonSecondaryText: true
        ? 'Rechazar'
        : null,
      onConfirmSecondary: () => {this._handleRevoke()}
    };
  };

  _formatHomeVisit = pointUserAssociation => {
    const visit = [];
    visit.push({
      ...pointUserAssociation,
      causal: pointUserAssociation.causal || '--',
      pointOfSale: _get(pointUserAssociation, 'point_of_sale.name') || '--'
    });
    return visit;
  };

  render() {
    const {
      classes,
      pointUserAssociation,
      requestApprove,
      requestRevoke,
      canApproveSeller,
      canRevokeSeller,
      causals
    } = this.props;
    if (!pointUserAssociation) return null;
    if (!pointUserAssociation.status) return null;
    if (!canApproveSeller) return null;
    if (!canRevokeSeller) return null;
    return (
      <div>
        <CustomDialog
          open={true}
          loading={requestApprove}
          loadingSecondary={requestRevoke}
          disabled={requestApprove || requestRevoke}
          disableBackdropClick={false}
          headerSecondary
          onClose={this._handleClose}
          {...this._modalProps(pointUserAssociation)}
          classes={{
            root: classes.dialogRoot
          }}
        >
          <Grid container spacing={16}>
            {pointUserAssociation.movements && (
              <Movements
                movements={pointUserAssociation.movements}
                disables={['reviewed']}
              />
            )}
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  {
                    name: 'Identificación',
                    title: 'Identificación'
                  },
                  {
                    name: 'asesor',
                    title: 'Nombre'
                  },
                  {
                    name: 'type',
                    title: 'Tipo'
                  },
                  {
                    name: 'pointOfSale',
                    title: 'Punto de Venta'
                  }
                ]}
                rows={this._formatHomeVisit(pointUserAssociation)}
              />
              {(pointUserAssociation.status === 'approved' ||
                pointUserAssociation.status === 'revoked') && (
                <Fragment>
                  <p
                    className={classnames(
                      classes.headline7,
                      classes.titleContainer
                    )}
                  >
                    {'Legalizado por:'}
                  </p>
                  <p
                    className={classnames(
                      classes.subtitle3,
                      classes.titleContainer
                    )}
                  >
                    {'Nombre: '}
                    <a className={classes.body2Light}>
                      {`${_get(pointUserAssociation, 'user.first_name') || '--'} ${_get(
                        pointUserAssociation,
                        'user.last_name',
                        ''
                      )}`}
                    </a>
                  </p>
                  <p
                    className={classnames(
                      classes.subtitle3,
                      classes.titleContainer
                    )}
                  >
                    {'Comentarios: '}
                    <a className={classes.body2Light}>
                      {pointUserAssociation.observations}
                    </a>
                  </p>
                </Fragment>
              )}
            </Grid>
           
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = theme => ({
  activeContainer: {
    padding: theme.spacing.unit * 2
  },
  dialogRoot: {
    width: 800
  },
  topInput: {
    marginTop: theme.spacing.unit * 2
  },
  titleContainer: {
    padding: '6px 0 0 0 !important',
    margin: `0px ${theme.spacing.unit * 3}px !important`
  },
  nameLabel: {
    textTransform: 'capitalize'
  }
});

PointUserAssociationRevision.propTypes = {
  open: PropTypes.bool
};

export default withStyles(combineStyles(styles, TypographyStyles), {
  withTheme: true
})(PointUserAssociationRevision);
