import ScoringResponsesList from './ScoringResponsesList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { newAlert } from '../../../actions/alert_actions';
import { fetchScoringList } from '../../../actions/scoring_actions';

function mapStateToProps(state) {
  return {
    scoringResponses: state.scoring.scoringList,
    meta: state.scoring.meta,
    requestSend: state.requestState.requestSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchScoringList: bindActionCreators(fetchScoringList, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ScoringResponsesList);
