import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import flatten from 'lodash/flatten';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedSelectStyles from './OutlinedSelect.styles';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import _get from 'lodash/get';

import FormikPropsValidations from './FormikPropsValidations';

export class OutlinedSelectChipped extends Component {
  state = {
    labelWidth: 0
  };

  static propTypes = {
    name: props => FormikPropsValidations('name', props),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.number
    ]),
    onChange: props => FormikPropsValidations('onChange', props),
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    multiple: PropTypes.bool
  };

  componentDidMount() {
    this.setState({
      labelWidth: this.InputLabelRef.offsetWidth
    });
  }

  _getChipText = selectedValue => {
    const { options } = this.props;
    const optionSelected = options.find(
      option => option.value === selectedValue
    );
    return optionSelected ? optionSelected.title : null;
  };

  _handleRemoveSelectedItem = item => {
    const { name, value, onChange, field = {} } = this.props;

    const inputOnChange = onChange || field.onChange;
    const inputName = name || field.name;
    const currentValues = value || field.value;

    inputOnChange({
      target: {
        name: inputName,
        type: 'delete',
        value: flatten([currentValues]).filter(value => value !== item)
      }
    });
  };

  render() {
    const {
      classes,
      name,
      value,
      label,
      onChange,
      options = [],
      helperText,
      withError = false,
      multiple = false,
      withChip = true,
      form = {},
      field = {},
      disabled = false,
    } = this.props;

    const inputName = name || field.name;
    const inputValue = value || field.value || '';
    const inputOnChange = onChange || field.onChange;
    const { errors = {} } = form;
    return (
      <FormControl
        variant="outlined"
        className={classnames(classes.formControl, classes.root)}
        error={!!_get(errors, inputName)}
      >
        <InputLabel
          htmlFor="outlined-select"
          component={props => (
            <label
              htmlFor=""
              {...props}
              ref={ref => (this.InputLabelRef = ref)}
            />
          )}
          classes={{
            root: classes.label,
            focused: classes.focused
          }}
        >
          {label}
        </InputLabel>
        <Select
          multiple={multiple}
          value={inputValue}
          onChange={inputOnChange}
          error={withError}
          disabled={disabled}
          input={
            <OutlinedInput
              color="secondary"
              labelWidth={this.state.labelWidth}
              name={inputName}
              id="outlined-select"
              classes={{
                root: classes.outlinedInput,
                focused: classes.focused,
                notchedOutline: classes.notchedOutline
              }}
            />
          }
          renderValue={selected => {
            return withChip ? (
              <div className={classes.chips}>
                {flatten([selected]).map(value => (
                  <Chip
                    key={value}
                    label={this._getChipText(value)}
                    className={classes.chip}
                    classes={{ label: classes.chipLabel }}
                    onDelete={() => this._handleRemoveSelectedItem(value)}
                    deleteIcon={<CloseIcon className={classes.chipIcon} />}
                  />
                ))}
              </div>
            ) : multiple ? (
              selected.length <= 1 ? (
                selected.map(value => this._getChipText(value)).join(', ')
              ) : (
                selected
                  .slice(0, 1)
                  .map(value => this._getChipText(value))
                  .join(', ') + ` Y ${selected.length - 1} MÁS`
              )
            ) : (
              flatten([selected]).map(value => this._getChipText(value))
            );
          }}
          classes={{
            select: classnames(classes.select, classes.selectWithChip),
            icon: classes.icon
          }}
        >
          {options.map((option, index) => {
            return multiple ? (
              <MenuItem
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.menuItemSelected
                }}
                value={option.value}
                key={index}
              >
                <Checkbox checked={inputValue.indexOf(option.value) > -1} />
                <ListItemText primary={option.title} />
              </MenuItem>
            ) : (
              <MenuItem
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.menuItemSelected
                }}
                value={option.value}
                key={index}
              >
                {option.title}
              </MenuItem>
            );
          })}
        </Select>
        {(helperText || _get(errors, inputName) || withError) && (
          <FormHelperText
            classes={{
              root: classes.formHelperTextRoot
            }}
            error={withError}
          >
            {helperText || _get(errors, inputName)}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(OutlinedSelectStyles)(OutlinedSelectChipped);
