import { formatBack } from '../../components/shared/Inputs/Masks';

export const nodeFormSanitizer = form => {
  const sanitizedForm = {
    articleType: form.articleType,
    totalValue: +formatBack(form.totalValue, 'currency'),
    voluntaryInsuranceValue: +formatBack(
      form.voluntaryInsuranceValue,
      'currency'
    ),
    numberOfInstalments: form.instalments,
    downPayment: +formatBack(form.downPayment, 'currency'),
    withVoluntaryInsurance: form.withVoluntaryInsurance,
    isSecureSale: Boolean(form.isSecureSale)
  };

  return sanitizedForm;
};

export const articleTypesResponseSanitizer = articleTypes => {
  if (articleTypes == undefined) {
    return [];
  }
  const allowedArticleTypes = ['generic', 'motorcycle'];
  const sanitizedArticleTypes = articleTypes.map(articleType => {
    return {
      id: articleType.code,
      name: parseArticleTypeName(articleType)
    };
  });
  return sanitizedArticleTypes.filter(articleType => {
    return allowedArticleTypes.includes(articleType.id);
  });
};

const parseArticleTypeName = (articleType) => {
  if (articleType.code === 'generic') {
    return 'OTRO';
  } else {
    return articleType.name;
  }
};
