import _pickBy from 'lodash/pickBy';

export const getGlobalSettings = gs => {
    const sanitizedFilters = gs.data
    sanitizedFilters.forEach( setting => {
        setting.attributes.value = setting.attributes.setting_type == 'boolean'
            ? setting.attributes.value == '1'
                ? 'Verdadero'
                : 'Falso'
            : setting.attributes.value
        
    })
    return sanitizedFilters
}

export const saveGlobalSetting = gs => {
    const { global_setting } = gs
    let sanitizedFilters = {
        global_setting
    }
    if(sanitizedFilters.global_setting.setting_type == 'boolean'){
        sanitizedFilters.global_setting.value = sanitizedFilters.global_setting.value == 'Verdadero'? '1' : '0'
    }
    return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
}
