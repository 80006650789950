import _get from 'lodash/get';

export const mapOptions = options =>
  options.map(datum => {
    if(datum.name != 'ÉXITO'){  
        return{
        ...datum,
        title: datum.id + " " + datum.name,
        value: datum.id
        }
    }else{
        return {...datum}
    }
  });