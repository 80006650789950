/* eslint-disable no-dupe-keys */
import React from 'react';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';

import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import _replace from 'lodash/replace';

import ActivityIndicator from '../../shared/ActivityIndicator';
import TypographyStyles from '../../../styles/typography';

const styles = theme => ({
  rootBase: {
    marginTop: '20px !important',
    textAlign: 'center'
  },
  loadingIndicator: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 1
  }
});

export const CardMessage = ({
  classes,
  message,
  isLoading
}) => (
  <div className={classes.rootBase}>
    {isLoading && <ActivityIndicator className={classes.loadingIndicator} />}
    <h5>{message}</h5>
  </div>

);

CardMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles)
)(CardMessage);
