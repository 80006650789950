import {
  removeAccents,
  removeExtraSpaces,
  getStreetTypeName
} from './utilityFunctions';

/**
 * Builds the address string and returns it.
 * @param {*} data
 * @returns The address in string format. Null if not all the fields are filled.
 */
export function buildAddress(data) {
  const city = removeAccents(removeExtraSpaces(data.city));
  const department = removeAccents(removeExtraSpaces(data.department));
  const initialStreet = getStreetTypeName(data.initialStreet);
  const initialStreetNumber = removeExtraSpaces(data.initialStreetNumber);
  const intersectingStreet = getStreetTypeName(data.intersectingStreet);
  const intersectingStreetNumber = removeExtraSpaces(
    data.intersectingStreetNumber
  );
  const houseNumber = removeExtraSpaces(data.houseNumber);
  const complement = removeExtraSpaces(data.complement);

  let streetAddress = '';

  if (allAddressFieldsFilled(data)) {
    streetAddress = `${initialStreet} ${initialStreetNumber} ${intersectingStreet} ${intersectingStreetNumber} ${houseNumber}`;

    return `${city},${department},${streetAddress},${complement}`;
  } else {
    return null;
  }
}

/**
 * Checks whether all the address fields are filled if at least one is filled, or if none are filled.
 * @param {*} data
 * @returns True if all the address fields are filled if at least one is, or none is, false otherwise
 */
export function allAddressFieldsFilled(data) {
  const cityFilled = removeExtraSpaces(data.city).length !== 0;
  const departmentFilled = removeExtraSpaces(data.department).length !== 0;
  const initialStreetFilled =
    getStreetTypeName(data.initialStreet).length !== 0;
  const initialStreetNumberFilled =
    removeExtraSpaces(data.initialStreetNumber).length !== 0;
  const intersectingStreetFilled =
    getStreetTypeName(data.intersectingStreet).length !== 0;
  const intersectingStreetNumberFilled =
    removeExtraSpaces(data.intersectingStreetNumber).length !== 0;
  const houseNumberFilled = removeExtraSpaces(data.houseNumber).length !== 0;

  const allFieldsFilled =
    cityFilled &&
    departmentFilled &&
    initialStreetFilled &&
    initialStreetNumberFilled &&
    intersectingStreetFilled &&
    intersectingStreetNumberFilled &&
    houseNumberFilled;

  return allFieldsFilled;
}

/**
 * Checks whether not one address field is filled.
 * @param {*} data
 * @returns True if at not one address field is filled, false otherwise
 */
export function NotOneAddressFieldFilled(data) {
  const cityNotFilled = removeExtraSpaces(data.city).length === 0;
  const departmentNotFilled = removeExtraSpaces(data.department).length === 0;
  const initialStreetNotFilled =
    getStreetTypeName(data.initialStreet).length === 0;
  const initialStreetNumberNotFilled =
    removeExtraSpaces(data.initialStreetNumber).length === 0;
  const intersectingStreetNotFilled =
    getStreetTypeName(data.intersectingStreet).length === 0;
  const intersectingStreetNumberNotFilled =
    removeExtraSpaces(data.intersectingStreetNumber).length === 0;
  const houseNumberNotFilled = removeExtraSpaces(data.houseNumber).length === 0;
  const complementNotFilled = removeExtraSpaces(data.complement).length === 0;

  const notOneFieldFilled =
    cityNotFilled &&
    departmentNotFilled &&
    initialStreetNotFilled &&
    initialStreetNumberNotFilled &&
    intersectingStreetNotFilled &&
    intersectingStreetNumberNotFilled &&
    houseNumberNotFilled &&
    complementNotFilled;

  return notOneFieldFilled;
}
