import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';

export const CustomSelect = ({ menuItems, ...props }) => {
  return (
    <TextField {...props} select fullWidth margin="normal" variant="outlined">
      {menuItems &&
        menuItems.map((item, idx) => {
          return (
            <MenuItem key={item.key || idx} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
    </TextField>
  );
};
CustomSelect.propTypes = {
  menuItems: PropTypes.array
};
export default CustomSelect;
