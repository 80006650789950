import {
  ALERT_NEW,
  ALERT_CLOSE,
  ALERT_CLEAR
} from '../constants/action_constants';

const initialState = {
  open: false,
  type: null,
  title: null,
  text: null
};

export function alerts(state = initialState, action) {
  switch (action.type) {
    case ALERT_NEW:
      return {
        ...state,
        ...action.alert
      };
    case ALERT_CLOSE:
      return {
        ...state,
        open: false
      };
    case ALERT_CLEAR:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
