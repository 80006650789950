import React, { PureComponent, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

class Preview extends PureComponent {
  previewRef = React.createRef();
  componentDidMount() {
    this.drawPreview();
  }

  componentDidUpdate() {
    this.drawPreview();
  }

  drawPreview = () => {
    const { base64img, width } = this.props;
    if (base64img) {
      const canvas = this.previewRef.current;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = base64img;
      image.onload = function() {
        canvas.width = width;
        canvas.height = (canvas.width * image.height) / image.width;
        ctx.scale(canvas.width / image.width, canvas.height / image.height);
        ctx.drawImage(image, 0, 0);
      };
    }
  };

  render() {
    const { classes, onClick, onDelete, width } = this.props;
    return (
      <Fragment>
        <div className={classes.previewParent} style={{ width: width }}>
          <CloseIcon
            onClick={onDelete}
            className={classes.closeIcon}
            color="secondary"
          />
          <canvas
            ref={this.previewRef}
            className={
              this.props.base64img ? classes.preview : classes.previewEmpty
            }
            onClick={onClick}
          />
        </div>
      </Fragment>
    );
  }
}

const styles = () => ({
  previewParent: {
    position: 'relative',
    margin: 5,
    borderRadius: 4,
    boxShadow: '0 1px 4px rgba(0, 0, 0, .6)',
    lineHeight: 0
  },
  previewEmpty: {
    display: 'none'
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer'
  }
});

export default withStyles(styles)(Preview);
