export const salesConstants = {
  LIST_SALES_REQUEST: 'LIST_SALES_REQUEST',
  LIST_SALES_SUCCESS: 'LIST_SALES_SUCCESS',
  LIST_SALES_FAILURE: 'LIST_SALES_FAILURE',

  DETAIL_SALES_REQUEST: 'DETAIL_SALES_REQUEST',
  DETAIL_SALES_SUCCESS: 'DETAIL_SALES_SUCCESS',
  DETAIL_SALES_FAILURE: 'DETAIL_SALES_FAILURE',

  APPROVE_SALE_REQUEST: 'APPROVE_SALE_REQUEST',
  APPROVE_SALE_SUCCESS: 'APPROVE_SALE_SUCCESS',
  APPROVE_SALE_FAILURE: 'APPROVE_SALE_FAILURE',

  FILTERED_OUT_SALE_REQUEST: 'FILTERED_OUT_SALE_REQUEST',
  FILTERED_OUT_SALE_SUCCESS: 'FILTERED_OUT_SALE_SUCCESS',
  FILTERED_OUT_SALE_FAILURE: 'FILTERED_OUT_SALE_FAILURE'
};
