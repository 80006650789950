const styles = theme => ({
  backgroundTop: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: -1,
    ['@media (max-width:860px)']: {
      marginTop: '-20%'
    },
    ['@media (max-width:560px)']: {
      marginTop: '-50%'
    },
    ['@media (max-width:460px)']: {
      marginTop: '-80%'
    },
    ['@media (max-width:380px)']: {
      marginTop: '-150%'
    },
    ['@media (max-width:600px)']: {
      display: 'none'
    },
    '&:before': {
      content: 'open-quote',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '80%',
      height: '70%',
      backgroundColor: '#de1303',
      borderBottom: '30px solid hsl(6, 100%, 38%)',
      transformOrigin: '100% 100%',
      transform: 'skewY(30deg) scaleX(3)',
      boxShadow: '0px 0px 20px #89898a',
      ['@media (max-width:380px)']: {
        right: '-20%'
      }
    }
  },
  backgroundBottom: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: -1,
    '&:before': {
      content: 'open-quote',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '50%',
      height: '100%',
      backgroundColor: '#de1303',
      borderRight: '50px solid hsl(6, 100%, 38%)',
      transformOrigin: '100% 100%',
      transform: 'skewX(60deg)',
      boxShadow: '0px 0px 20px #89898a',
      ['@media (max-width:600px)']: {
        display: 'none'
      },
      ['@media (max-width:860px)']: {
        width: '60%'
      },
      ['@media (max-width:460px)']: {
        width: '90%'
      },
      ['@media (max-width:380px)']: {
        width: '100%',
        bottom: '-20%'
      }
    }
  },
  resetContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  resetForm: {
    width: '420px',
    background: 'transparent',
    borderRadius: '.25em .25em .4em .4em',
    textAlign: 'center',
    color: 'white',
    ['@media (max-width:600px)']: {
      width: '100%'
    },
  },
  header: {
    width: '100%',
    textAlign: 'center',
    padding: '30px !important',
    background: '#db4945',
    ['@media (max-width:600px)']: {
      height: 125
    },
  },
  body: {
    width: '100%',
    padding: 0,
    ['@media (max-width:600px)']: {
      opacity: '1 !important',
      height: 'calc(100vh - 125px - 41px)'
    },
  },
  footer: {
    opacity: 0.8,
    padding: '10px !important',
    background: theme.palette.primary.main,
    width: '100%',
    ['@media (max-width:600px)']: {
      opacity: '1 !important',
      height: 41
    },
  },
  inputField: {
    padding: '12px 24px 0 24px'
  },
  customButton: {
    // backgroundColor: '#f31a02',
    // color: '#ecf0f1',
    // textTransform: 'none',
    // lineHeight: theme.spacing.unit / 3,
    // '&:hover': {
    //   backgroundColor: '#c11402'
    // },
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    ['@media (max-width:600px)']: {
      height: [48, '!important'],
      width: '100%'
    },
  }
});

export default styles;
