import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LineExecutiveProfileList from './LineExecutiveProfileList';
import {
  fetchProfiles,
  setSaveModalOpen,
  createProfile,
  updateProfile,
} from '../../../actions/line_executive_profile_actions';

function mapStateToProps(state) {
  return {
    profiles: state.lineExecutiveProfile.profilesList,
    metaData: state.lineExecutiveProfile.metaData,
    saveModal: state.lineExecutiveProfile.saveProfileModal,
    fetchingProfiles: state.requestState.requestLineExecutiveProfileListSend,
    currentUser: state.authentication.currentUser,
    lineExecutives: state.lineExecutiveProfile.lineExecutives,
    categories: state.lineExecutiveProfile.categories,
    creatingProfile: state.requestState.requestCreateLineExecutiveProfileSend,
    updatingProfile: state.requestState.requestUpdateLineExecutiveProfileSend,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchProfiles: bindActionCreators(fetchProfiles, dispatch),
    setSaveModalOpen: bindActionCreators(setSaveModalOpen, dispatch),
    createProfile: bindActionCreators(createProfile, dispatch),
    updateProfile: bindActionCreators(updateProfile, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineExecutiveProfileList);