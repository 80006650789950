import UserList from './UserList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchUsers,
  createUser,
  validateUser
} from '../../../actions/user_actions';
import { fetchAllRoles, fetchRoles } from '../../../actions/role_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';
import { newAlert } from '../../../actions/alert_actions';
import { completeRequest } from '../../../actions/request_state';

import {
  setCreationInfo,
  clearCreationInfo,
  userCreateError,
  userClearError
} from '../../../actions/user_actions';

function mapStateToProps(state) {
  return {
    serverErrors: state.user.errors,
    canUpdateRoles: state.authentication.currentUser.canUpdateRoles,
    currentUser: state.authentication.currentUser,
    creationInfo: state.user.creationInfo,
    users: state.user.userList,
    meta: state.user.meta,
    roles: state.role.roles,
    suppliers: state.globalSetting.orgData.suppliers,
    requestSend: state.requestState.requestSend,
    requestUpdateRolesSend: state.requestState.requestUpdateRolesSend,
    requestNewUserSend: state.requestState.requestNewUserSend,
    requestErrorsOnNewUser: state.requestState.requestErrorsOnNewUser,
    adminRoles: state.globalSetting.orgData.admin_roles,
    freelanceRoles: state.globalSetting.orgData.asesor_freelance_roles,
    asesorRoles: state.globalSetting.orgData.asesor_fnb_roles,
    salesmanRoles: state.globalSetting.orgData.salesman_roles,
    salesmanRolesIds: state.globalSetting.orgData.salesmans_roles_ids,
    coordinadorRoles: state.globalSetting.orgData.coordinador_aliado_roles,
    hugeSurfaceCoordinatorRoles:
      state.globalSetting.orgData.huge_surface_coordinator_roles,
    osfMaxIdLength: state.globalSetting.orgData.osf_max_id_length
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearCreationInfo: bindActionCreators(clearCreationInfo, dispatch),
    setCreationInfo: bindActionCreators(setCreationInfo, dispatch),
    userCreateError: bindActionCreators(userCreateError, dispatch),
    userClearError: bindActionCreators(userClearError, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchUsers: bindActionCreators(fetchUsers, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    fetchAllRoles: bindActionCreators(fetchAllRoles, dispatch),
    fetchRoles: bindActionCreators(fetchRoles, dispatch),
    validateUser: bindActionCreators(validateUser, dispatch),
    createUser: bindActionCreators(createUser, dispatch),
    completeRequest: bindActionCreators(completeRequest, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserList);
