import {
  LIST_REVISIONS_SUCCESS,
  SET_DOCUMENT_REVISION_DATA,
  CHECK_CLIENT_VALIDATION,
  STEP_LOADING_DOCUMENT_REVISION,
  STEP_SUCCESS_DOCUMENT_REVISION,
  STEP_ERROR_DOCUMENT_REVISION,
  DOCUMENT_REVISION_CLEAR,
  DOCUMENT_REVISION_PERSIST,
  SET_DOCUMENT_REVISION_IMAGE_KEY
} from '../constants/action_constants';
const initialState = {
  digitalSalesDocumentRevisions: [],
  revisionCompleted: false,
  digitalSalesDocumentRevisionDetails: [],
  dataClientValidated: false,
  stepIsLoading: false,
  meta: {}
};

export function digitalSalesDocumentRevisions(state = initialState, action) {
  switch (action.type) {
    case LIST_REVISIONS_SUCCESS:
      return {
        ...state,
        digitalSalesDocumentRevisions: action.data,
        meta: action.meta
      };
    case SET_DOCUMENT_REVISION_DATA:
      return {
        ...state,
        digitalSalesDocumentRevisionDetails: action.data
      };
    case SET_DOCUMENT_REVISION_IMAGE_KEY:
      return {
        ...state,
        [action.dataKey]: action.data
      };
    case CHECK_CLIENT_VALIDATION:
      return {
        ...state,
        [action.stepName]: action.stepValue
      };
    case STEP_LOADING_DOCUMENT_REVISION:
      return {
        ...state,
        stepIsLoading: true
      };
    case STEP_SUCCESS_DOCUMENT_REVISION:
      return {
        ...state,
        ...action.data,
        stepIsLoading: false,
        errors: {}
      };
    case STEP_ERROR_DOCUMENT_REVISION:
      return {
        ...state,
        stepIsLoading: false,
        errors: action.errors
      };
    case DOCUMENT_REVISION_CLEAR:
      return initialState;
    case DOCUMENT_REVISION_PERSIST:
      return action.data;
    default:
      return state;
  }
}
