import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

import routes from './routes';

const brillaLogo = require('../../../assets/images/brilla-logo.png');

export class SidebarMenu extends PureComponent {
  state = {
    mobileOpen: false,
    auth: true,
    anchorEl: null,
    alliesOpen: true,
    subMenuIdOpened: null,
    subMenuIdSelected: null
  };
  _handleToggleDrawer = () => {
    const { toggleDrawer } = this.props;
    if (toggleDrawer) {
      toggleDrawer();
    }
  };
  _handleToggleSubMenu = subMenuId => {
    const { subMenuIdOpened } = this.state;
    if (!subMenuId) this._handleToggleDrawer();
    this.setState({
      subMenuIdOpened: subMenuIdOpened === subMenuId ? null : subMenuId
    });
  };

  _handleFunction = route => {
    if (route.function) {
      route.function();
    }
  };

  _handlePath = route => {
    if (!route.path) {
      return `${this.props.location.pathname}${this.props.location.search}`;
    }
    if (route.path === this.props.location.pathname) {
      return `${route.path}${this.props.location.search}`;
    }
    return (
      { pathname: route.path, state: { from: this.props.location.pathname } } ||
      '#'
    );
  };

  render() {
    const { currentUser, menuFunctions, classes, companyCode } = this.props;

    const routeList = routes(currentUser, menuFunctions);

    return (
      <div className={classes.list}>
        <div className={classes.toolbar}>
          <div>
            {companyCode && (
              <img
                className={classes.header}
                src={brillaLogo}
              />
            )}
          </div>
        </div>
        <List className={classes.primaryNavRoot}>
          {routeList
            .filter(route => !route.parentId && route.displayInSidebar)
            .map((route, index) => {
              const nestedLinks = routeList.filter(
                nestedLink => nestedLink.parentId === route.groupId
              );

              const submenuSelected =
                nestedLinks
                  .map(nestedLinkItem => nestedLinkItem.path)
                  .indexOf(location.pathname) >= 0;

              return (
                currentUser.mobileConfirmedAt &&
                route.isPermitted &&
                this.props.location.pathname !== '/404' && (
                  <Fragment key={index}>
                    <ListItem
                      key={route.name}
                      button
                      selected={route.path === this.props.location.pathname}
                      component={!route.groupId && route.path !== this.props.location.pathname && NavLink}
                      to={this._handlePath(route)}
                      onClick={() => {
                        this._handleToggleSubMenu(route.groupId);
                        this._handleFunction(route);
                      }}
                      activeClassName={
                        route.groupId
                          ? classes.groupExpanded
                          : classes.primaryMenuActive
                      }
                      classes={{
                        button: classnames(classes.menuDefaultColor),
                        root: classnames(classes.menuRow)
                      }}
                      isActive={(_match, location) => {
                        if (route.groupId) {
                          return submenuSelected;
                        }
                        return route.path === location.pathname;
                      }}
                    >
                      <ListItemIcon>
                        {route.icon && <route.icon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={route.name}
                        classes={{
                          primary: classnames(classes.menuText, 'menuText'),
                          root: classnames(classes.menuDefaultColor)
                        }}
                      />
                      {route.groupId ? (
                        this.state.subMenuIdOpened === route.groupId ? (
                          <ExpandLessIcon
                            className={classes.collapseMenuIcon}
                          />
                        ) : (
                          <ExpandMoreIcon
                            className={classes.collapseMenuIcon}
                          />
                        )
                      ) : null}
                    </ListItem>
                    <Fragment>
                      {route.groupId && (
                        <Collapse
                          in={this.state.subMenuIdOpened === route.groupId}
                          timeout="auto"
                          unmountOnExit
                        >
                          {nestedLinks
                            .filter(nestedLink => nestedLink.isPermitted)
                            .map(nestedLink => (
                              <ListItem
                                key={nestedLink.name}
                                button
                                selected={
                                  nestedLink.path ===
                                  this.props.location.pathname
                                }
                                component={!nestedLink.function && nestedLink.path !== this.props.location.pathname && NavLink}
                                to={this._handlePath(nestedLink)}
                                onClick={() => {
                                  this._handleToggleDrawer();
                                  this._handleFunction(nestedLink);
                                }}
                                classes={{
                                  button: classnames(classes.menuDefaultColor),
                                  root: classnames(classes.menuRow)
                                }}
                              >
                                <ListItemText
                                  primary={nestedLink.name}
                                  classes={{
                                    primary: classnames(
                                      classes.menuText,
                                      'menuText'
                                    ),
                                    root: classnames(
                                      classes.secondaryMenuText,
                                      classes.menuDefaultColor
                                    )
                                  }}
                                />
                              </ListItem>
                            ))}
                        </Collapse>
                      )}
                    </Fragment>
                  </Fragment>
                )
              );
            })}
        </List>
      </div>
    );
  }
}

export default SidebarMenu;
