export const submitSanitizer = params => {
  return {
    user: {
      first_name: params.names,
      last_name: params.lastNames,
      email: params.email,
      mobile: params.mobile,
      type_of_identification: params.identificationType,
      identification: params.identification
    }
  };
};
