import React, { Component } from 'react';
import MUIModal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ModalStyles from './Modal.styles';

export class Modal extends Component {
  render() {
    const {
      classes,
      open,
      onClose,
      body,
      footer,
      refObject = {},
      ...rest
    } = this.props;
    return (
      <MUIModal
        open={open}
        onClose={onClose}
        classes={{ root: classnames(classes.modalRootBase, classes.rootBase) }}
        {...rest}
        disableRestoreFocus={true}
      >
        <div
          className={classnames(classes.baseRoot, classes.root, classes.customForm? classes.customForm : classes.defaultWidth)}
          ref={refObject.modal}
        >
          <div className={classnames(classes.baseBody, classes.body)}>
            {body}
          </div>
          <div className={classnames(classes.baseFooter, classes.footer)}>
            {footer}
          </div>
        </div>
      </MUIModal>
    );
  }
}

export default withStyles(ModalStyles)(Modal);
