import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import flatten from 'lodash/flatten';
import OutlinedSelectStyles from './OutlinedSelect.styles';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import { DATATYPES } from './../../../constants/input_types';
import FormikPropsValidations from './FormikPropsValidations';
import _isFunction from 'lodash/isFunction';
import FieldValidationPopup from '../Poppers/FieldValidation';
export class OutlinedSelect extends PureComponent {
  state = {
    labelWidth: 0,
    anchorEl: null,
    fieldError: '',
    fieldName: this.props.name || _get(this.props, 'field.name')
  };

  static propTypes = {
    name: props => FormikPropsValidations('name', props),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.number
    ]),
    onChange: props => FormikPropsValidations('onChange', props),
    validateField: PropTypes.func,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    multiple: PropTypes.bool,
    datatype: PropTypes.oneOf([DATATYPES.STRING, DATATYPES.NUMBER]),
    form: PropTypes.object
  };

  componentDidMount() {
    this.setState({
      labelWidth: this.InputLabelRef.offsetWidth
    });
    this._setFieldError();
  }
  _setFieldError = () => {
    const { fieldName } = this.state;
    const { form = {} } = this.props;
    const { errors } = form;
    const error = _get(errors, fieldName, '');
    this.setState({ fieldError: error });
  };
  componentDidUpdate(prevProps) {
    const { validateField } = this.props;
    const { fieldName } = this.state;
    if (
      _get(prevProps, 'form.errors', {}) !== _get(this.props, 'form.errors', {})
    ) {
      this._setFieldError();
    }
    if (prevProps.value !== this.props.value) {
      if (_isFunction(validateField)) {
        validateField(fieldName, this.props.value);
      }
    }
  }
  _handleShowError = event => {
    const { fieldError } = this.state;
    if (fieldError) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };
  _handleHideError = () => {
    this.setState({ anchorEl: null });
  };
  _handleRemoveSelectedItem = item => {
    const { value, onChange, field = {} } = this.props;
    const { fieldName } = this.state;
    const inputOnChange = onChange || field.onChange;
    const currentValues = value || field.value;

    inputOnChange({
      target: {
        name: fieldName,
        value: flatten([currentValues]).filter(value => value !== item)
      }
    });
  };
  _handleBlur = ({ target: { value, name } }) => {
    const { validateField } = this.props;
    if (_isFunction(validateField)) {
      validateField(name, value);
    }
  };
  _handleChange = ({ target: { value, name } }) => {
    const { field = {}, datatype, validateField, form = null } = this.props;
    const { fieldError, fieldName } = this.state;

    const onChange = this.props.onChange || field.onChange;
    let dataValue;
    if (datatype && datatype === DATATYPES.NUMBER) {
      dataValue = parseFloat(value);
    } else {
      dataValue = String(value);
    }

    if (fieldError && _isFunction(validateField)) {
      validateField(name, dataValue);
    }
    onChange({ target: { name, value: dataValue } });
    if (form) {
      form.setFieldTouched(fieldName);
    }
  };

  render() {
    const {
      classes,
      value,
      label,
      options = [],
      multiple = false,
      field = {}
    } = this.props;
    const { anchorEl, fieldName, fieldError } = this.state;
    //this next line deals with reference fields that pass in an array even though its a select.  pick first value if it exists
    let inputValue = value || field.value || '';
    if (_isArray(inputValue)) {
      if (inputValue.length > 0) {
        inputValue = inputValue[0];
      } else {
        inputValue = '';
      }
    }

    return (
      <FormControl
        variant="outlined"
        className={classnames(classes.formControl, classes.root)}
        error={!!fieldError}
        onMouseEnter={this._handleShowError}
        onFocus={this._handleShowError}
        onMouseOut={this._handleHideError}
      >
        <InputLabel
          htmlFor="outlined-select"
          component={props => (
            <label
              htmlFor=""
              {...props}
              ref={ref => (this.InputLabelRef = ref)}
            />
          )}
        >
          {label}
        </InputLabel>
        <Select
          multiple={multiple}
          value={inputValue}
          onChange={this._handleChange}
          onBlur={this._handleBlur}
          native
          input={
            <OutlinedInput
              labelWidth={this.state.labelWidth}
              name={fieldName}
              id="outlined-select"
            />
          }
          classes={{
            select: classnames(classes.select),
            icon: classes.icon
          }}
        >
          <option value="" />
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.title}
            </option>
          ))}
        </Select>
        <FieldValidationPopup anchorEl={anchorEl} message={fieldError} />
      </FormControl>
    );
  }
}
export default withStyles(OutlinedSelectStyles)(OutlinedSelect);
