import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';

import TeleshoppingEndPage from '../TeleshoppingEndPage/TeleshoppingEndPage.js';
import { newAlert } from '../../../actions/alert_actions';
import { createTeleshopping, setNextStep } from '../../../actions/teleshopping_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';

function mapStateToProps(state) {
  const orgData = _get(state, 'globalSetting.orgData');

  return {
    serverErrors: state.teleshopping.errors,
    baseErrorOpen: state.teleshopping.baseErrorOpen,
    validContract: state.teleshopping.validContract,
    stepValidateContract: state.teleshopping.stepValidateContract,
    stepCreateTeleshopping: state.teleshopping.stepCreateTeleshopping,
    routes: state.stepper.routes,
    process: state.stepper.process,
    product_name: state.teleshopping.product_name,
    reference_sale: state.teleshopping.reference_sale,
    reference_sale_supplier: state.teleshopping.reference_sale_supplier,
    reference_sale_pointOfSale: state.teleshopping.reference_sale_pointOfSale,
    reference_sale_salesman: state.teleshopping.reference_sale_salesman,
    reference_sale_salesman_id: state.teleshopping.reference_sale_salesman_id,
    salesmanName: state.teleshopping.salesmanName,
    salesmanId: state.teleshopping.salesmanId,
    reference_sale_registered_at: state.teleshopping.reference_sale_registered_at,
    sellers: state.teleshopping.sellers,
    contract: state.teleshopping.contract,
    client_name: state.teleshopping.client_name + ' ' + state.teleshopping.client_last_name,
    document: state.teleshopping.document,
    verification_sale: state.teleshopping.verification_sale,
    price: state.teleshopping.price,
    iva: state.teleshopping.iva,
    instalments: state.teleshopping.instalments,
    sold_at: state.teleshopping.sold_at,
    orgSellerDataAuthorization: _get(orgData, 'get_seller_data_authorization'),
    isBlackListActive: _get(orgData, 'get_blacklist_active'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    createTeleshopping: bindActionCreators(createTeleshopping, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeleshoppingEndPage);
