import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import ProgressButton from '../../shared/Buttons/ProgressButton';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';

const styles = theme => ({
  tag: {
    margin: 0,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: 'inline-block',
    padding: [[theme.spacing.unit * 0.5, theme.spacing.unit * 1]],
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#FEB00B',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:last-child':{
      marginRight: 0,
    }
  }
});

export const Roles = ({
  classes,
  isLoading,
  roles=[],
  onEditRoles,
  canUpdateRoles
}) => {
  return (
    <FormItemContainer
      title={'Roles'}
      secondaryAction={
        !isLoading && canUpdateRoles ?
        <Button
          size={'small'}
          color={'secondary'}
          className={classnames(classes.smallButton, classes.actionButtons)}
          classes={{
            label: classes.buttonLabel
          }}
          onClick={onEditRoles}
        >
          {'Editar'}
        </Button>
        : null
      }
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div>
          <p className={classes.body2}>
            {!roles.length && 'No hay roles establecidos para este usuario'}
            {roles.map(role => <span className={classes.tag} key={role.id}>{role.displayName}</span>)}
          </p>
        </div>
      )}
    </FormItemContainer>
  );
};

export default withStyles(combineStyles(ButtonStyles, TypographyStyles, styles))(Roles);
