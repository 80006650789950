import * as Yup from 'yup';

export const debtorValidation = Yup.object().shape({
  debtorEmail: Yup.string().nullable().email('Debe ingresar un email válido.'),
  debtorPhone: Yup.string().matches(
    /^([0-9]{7})$/,
    'El número ingresado debe tener 7 dígitos.'
  ),
  debtorCellphone: Yup.string()
    .required('El campo es requerido')
    .test('len', 'Debe tener 10 dígitos.', val => val && val.length === 10)
});

export const cosignerValidation = Yup.object().shape({
  cosignerEmail: Yup.string()
    .nullable()
    .email('Debe ingresar un email válido.')
    .when(["debtorEmail"], (debtorEmail, schema) => {
      return debtorEmail && schema.notOneOf([debtorEmail], "No puede ser el mismo correo que el del deudor");
    }),
  cosignerPhone: Yup.string().matches(
    /^([0-9]{7})$/,
    'El número ingresado debe tener 7 dígitos.'
  ),
  cosignerCellphone: Yup.string()
    .required('El campo es requerido')
    .test('len', 'Debe tener 10 dígitos.', val => val && val.length === 10)
    .when(["debtorCellphone"], (debtorCellphone, schema) => {
      return schema.notOneOf([debtorCellphone], "No puede ser el mismo celular que el del deudor");
    }),
  
});
