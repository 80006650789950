import { DASHBOARD_SUMMARY, DASHBOARD_SALES, DASHBOARD_TOP_RATED} from '../constants/dashboard_constants';

const initialState = {
  summary: {
      all: 0,
      approved: 0,
      registered: 0,
      delivered: 0,
      revoked: 0,  
    },
  sales: [{}],
  topRated: [
    {point_of_sale: 'GC 301', sold: 0, sales: 0},
    {point_of_sale: 'GC 303', sold: 0, sales: 0},
    {point_of_sale: 'GC 302', sold: 0, sales: 0},
    {point_of_sale: 'GC 306', sold: 0, sales: 0},
    {point_of_sale: 'GC 307', sold: 0, sales: 0},
  ],
};

export function dashboard(state = initialState, action) {

  switch (action.type) {
    case DASHBOARD_SUMMARY:
      return {
        ...state,
        summary: action.data,
      };
    case DASHBOARD_SALES:
      return {
        ...state,
        sales: action.data,
      };
    case DASHBOARD_TOP_RATED:
      return {
        ...state,
        topRated: action.data,
      };
    default:
      return state;
  }
}
