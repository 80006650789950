import {
  BRANDS_SET,
  BRAND_SHOW
} from '../constants/action_constants';
const initialState = {
  brands: [],
  meta: {},
  brandDetail: null,
  modalOpen: false,
  isEditing: false
};

export function brands(state = initialState, action) {
  switch (action.type) {
    case BRANDS_SET:
      return {
        ...state,
        brands: action.data,
        meta: action.meta
      };
    case BRAND_SHOW:
      return {
        ...state,
        brandDetail: action.data,
        modalOpen: action.open,
        isEditing: action.isEditing
      };
    default: {
      return state;
    }
  }
}
