import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import classnames from 'classnames';
import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';
import LoadingIndicator from '../../shared/LoadingIndicator';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { CSSTransition } from 'react-transition-group';

const styles = theme => ({
  root: {
    width: 164,
    height: 164,
    borderRadius: 16.4,
    position: 'relative'
  },
  transition: {
    transition: `visibility 0s, opacity 250ms ease-in-out`
  },
  initials: {
    color: 'rgba(12,31,44,0.38)',
    fontFamily: theme.typography.font.second,
    fontSize: 65.6,
    fontWeight: 500,
    letterSpacing: '4.1px',
    margin: 0,
    height: '100%',
    width: '100%',
    backgroundColor: '#EBEEF1',
    borderRadius: 16.4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatarPicture: {
    width: '100%',
    height: '100%',
    borderRadius: 16.4,
    border: '1px solid rgba(12,31,44,0.38)',
    objectFit: 'cover'
  },
  opacity: {
    opacity: '0.3'
  },
  icons: {
    position: 'absolute',
    color: '#F2A138',
    zIndex: 3,
    fontSize: '27px'
  },
  iconRight: {
    right: 0
  },
  displayF: {
    visibility: 'hidden',
    opacity: '0'
  },
  displayT: {
    visibility: 'visible',
    opacity: '1'
  }
});
class Avatar extends React.Component {
  state = {
    isShown: false
  };
  render() {
    const {
      classes,
      user,
      onEditProfilePicture,
      canUpdatePictureProfile,
      isUpdatingPictureProfile,
      OpenDeleteProfilePictureDialog
    } = this.props;
    let isSho = false;
    return (
      <div
        onMouseEnter={() => this.setState({ isShown: true })}
        onMouseLeave={() => this.setState({ isShown: false })}
        className={classnames(classes.root)}
      >
        {canUpdatePictureProfile && (
          <>
            {user.photo && (
              <IconButton
                onClick={OpenDeleteProfilePictureDialog}
                className={classnames(
                  classes.icons,
                  classes.transition,
                  this.state.isShown ? classes.displayT : classes.displayF
                )}
              >
                <DeleteForeverIcon />
              </IconButton>
            )}

            <IconButton
              onClick={onEditProfilePicture}
              className={classnames(
                classes.icons,
                classes.iconRight,
                classes.transition,
                this.state.isShown ? classes.displayT : classes.displayF
              )}
            >
              <EditIcon />
            </IconButton>
          </>
        )}

        {isUpdatingPictureProfile ? (
          <LoadingIndicator />
        ) : user.photo ? (
          <img
            src={user.photo}
            alt={user.firstName}
            className={classnames(
              classes.avatarPicture,
              classes.transition,
              canUpdatePictureProfile && this.state.isShown
                ? classes.opacity
                : null
            )}
          />
        ) : (
          <p
            className={classnames(
              classes.initials,
              classes.transition,
              canUpdatePictureProfile && this.state.isShown
                ? classes.opacity
                : null
            )}
          >
            {user.firstName ? user.firstName[0] : 'N'}
            {user.lastName ? user.lastName[0] : 'N'}
          </p>
        )}
      </div>
    );
  }
}

export default withStyles(combineStyles(styles, TypographyStyles))(Avatar);
