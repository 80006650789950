import QuotaTransferList from './QuotaTransferList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchTransfers,
  quotaTransferRevision
} from '../../../actions/quota_transfer_actions';

function mapStateToProps(state) {
  return {
    currentUser: state.authentication.currentUser,
    transfers: state.quotaTransfer.transfers,
    meta: state.quotaTransfer.meta,
    requestQuotaTransferSend: state.requestState.requestQuotaTransferSend,
    requestErrorsOnQuotaTransfer:
      state.requestState.requestErrorsOnQuotaTransfer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTransfers: bindActionCreators(fetchTransfers, dispatch),
    quotaTransferRevision: bindActionCreators(quotaTransferRevision, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QuotaTransferList);
