import React from 'react';
import Grid from '@material-ui/core/Grid';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import Typography from '@material-ui/core/Typography';
import { doctypes } from '../../../constants/enums';
import _capitalize from 'lodash/capitalize';

const MinuteFieldsStep = props => {
  const {
    classes,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    userIsAspiringSupplier,
    proposalRejected,
  } = props;
  return (
    <Grid container item className={classes.minuteFieldsStepContainer}>
      <Grid item xs={12}>
        <Typography variant='h3'>Campos de minuta</Typography>
      </Grid>
      <Grid item className={classes.minuteFieldsStepSubtitle}>
        <Typography variant='body1'>
          Completa la siguiente información para poder diligenciar la minuta
        </Typography>
      </Grid>
      <Grid container item>
        <Grid container item justify='flex-start' xs={4}>
          <OutlinedTextField
            className={classes.minuteFieldsStepTextField}
            name='legalRepresentativeName'
            label='Nombre completo del representante legal'
            value={values.legalRepresentativeName}
            onBlur={handleBlur}
            onChange={
              event => {
                const change = event.target.value.toUpperCase();
                setFieldValue('legalRepresentativeName', change);
              }
            }
            helperText={touched.legalRepresentativeName && errors.legalRepresentativeName}
            error={touched.legalRepresentativeName && !!errors.legalRepresentativeName}
            disabled={!userIsAspiringSupplier || proposalRejected}
          />
        </Grid>
        <Grid container item justify='center' xs={4}>
          <SimpleSelect
            style={{width: '90%'}}
            name='legalRepresentativeIdentificationType'
            label='Tipo identificación del representante legal'
            options={mapListToOptions(doctypes, {}, true)}
            value={values.legalRepresentativeIdentificationType}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.legalRepresentativeIdentificationType && errors.legalRepresentativeIdentificationType}
            error={touched.legalRepresentativeIdentificationType && !!errors.legalRepresentativeIdentificationType}
            disabled={!userIsAspiringSupplier || proposalRejected}
          />
        </Grid>
        <Grid container item justify='flex-end' xs={4}>
          <OutlinedTextField
            className={classes.minuteFieldsStepTextField}
            name='legalRepresentativeIdentification'
            type='number'
            label='Identificación del representante legal'
            value={values.legalRepresentativeIdentification}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.legalRepresentativeIdentification && errors.legalRepresentativeIdentification}
            error={touched.legalRepresentativeIdentification && !!errors.legalRepresentativeIdentification}
            disabled={!userIsAspiringSupplier || proposalRejected}
          />
        </Grid>
        <Grid container item justify='flex-start' xs={4}>
          <OutlinedTextField
            className={classes.minuteFieldsStepTextField}
            name='legalRepresentativeCity'
            label='Ciudad de domicilio del representante legal'
            value={values.legalRepresentativeCity}
            onBlur={handleBlur}
            onChange={
              event => {
                const change = _capitalize(event.target.value);
                setFieldValue('legalRepresentativeCity', change);
              }
            }
            helperText={touched.legalRepresentativeCity && errors.legalRepresentativeCity}
            error={touched.legalRepresentativeCity && !!errors.legalRepresentativeCity}
            disabled={!userIsAspiringSupplier || proposalRejected}
          />
        </Grid>
        <Grid container item justify='center' xs={4}>
          <OutlinedTextField
            className={classes.minuteFieldsStepTextField}
            name='contactAddress'
            label='Dirección de notificación principal en Colombia'
            value={values.contactAddress}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.contactAddress && errors.contactAddress}
            error={touched.contactAddress && !!errors.contactAddress}
            disabled={!userIsAspiringSupplier || proposalRejected}
          />
        </Grid>
        <Grid container item justify='flex-end' xs={4}>
          <OutlinedTextField
            className={classes.minuteFieldsStepTextField}
            name='contactCity'
            label='Ciudad de notificación principal en colombia'
            value={values.contactCity}
            onBlur={handleBlur}
            onChange={
              event => {
                const change = _capitalize(event.target.value);
                setFieldValue('contactCity', change);
              }
            }
            helperText={touched.contactCity && errors.contactCity}
            error={touched.contactCity && !!errors.contactCity}
            disabled={!userIsAspiringSupplier || proposalRejected}
          />
        </Grid>
        <Grid container item justify='flex-start' xs={4}>
          <OutlinedTextField
            className={classes.minuteFieldsStepTextField}
            name='contactPhone'
            label='Teléfono de contacto principal en colombia'
            type='number'
            value={values.contactPhone}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.contactPhone && errors.contactPhone}
            error={touched.contactPhone && !!errors.contactPhone}
            disabled={!userIsAspiringSupplier || proposalRejected}
          />
        </Grid>
        <Grid container item justify='center' xs={4}>
          <OutlinedTextField
            className={classes.minuteFieldsStepTextField}
            name='chamberOfCommerceCity'
            label='Ciudad expedición de la cámara de comercio'
            value={values.chamberOfCommerceCity}
            onBlur={handleBlur}
            onChange={
              event => {
                const change = _capitalize(event.target.value);
                setFieldValue('chamberOfCommerceCity', change);
              }
            }
            helperText={touched.chamberOfCommerceCity && errors.chamberOfCommerceCity}
            error={touched.chamberOfCommerceCity && !!errors.chamberOfCommerceCity}
            disabled={!userIsAspiringSupplier || proposalRejected}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MinuteFieldsStep;