export const causeOptions = [
  { value: 'all', title: 'Todas' },
  { value: 'Cliente desiste', title: 'Cliente desiste' },
  { value: 'Situación económica', title: 'Situación económica' },
  { value: 'Mala asesoría', title: 'Mala asesoría' },
  { value: 'Demora en la entrega', title: 'Demora en la entrega' },
  { value: 'Propietario no autorizó', title: 'Propietario no autorizó' },
  { value: 'Garantía', title: 'Garantía' },
  { value: 'Demora en la instalación', title: 'Demora en la instalación' },
  { value: 'Pérdida de solidaridad', title: 'Pérdida de solidaridad' },
  { value: 'Presunto fraude', title: 'Presunto fraude' },
  { value: 'Cambio de referencia', title: 'Cambio de referencia' },
  { value: 'Error en digitación', title: 'Error en digitación' },
  {
    value:
      'Se omitió información/la información no fue clara al momento de la venta',
    title:
      'Se omitió información/la información no fue clara al momento de la venta'
  },
  {
    value: 'No desea continuar con el seguro',
    title: 'No desea continuar con el seguro'
  },
  { value: 'Por Siniestro', title: 'Por Siniestro' },
  {
    value: 'Necesita realizar refinanciaciones o cambio de plazos en su deuda',
    title: 'Necesita realizar refinanciaciones o cambio de plazos en su deuda'
  },
  { value: 'Situación económica', title: 'Situación económica' },
  {
    value: 'Tiene una mejor oferta de seguro',
    title: 'Tiene una mejor oferta de seguro'
  }
];

export const statusOptions = [
  { value: 'all', title: 'Todas' },
  { value: 'registered', title: 'Registrada' },
  { value: 'reviewed', title: 'Revisada' },
  { value: 'approved', title: 'Aprobada' },
  { value: 'revoked', title: 'Rechazada' }
];
