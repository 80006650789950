import React, { Fragment, PureComponent } from 'react';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import CollapseWrapper from '../../shared/CollapseWrapper.js';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import collapseWrapperStyles from '../../../styles/components/collapseWrapper';
import CommentInput from '../../shared/Inputs/CommentInput';
import CommentItem from '../../shared/ListItem/CommentItem';

class Comment extends PureComponent {
  state = {
    comment: ''
  };

  componentWillUnmount() {
    this.props.clearComments();
  }

  handleChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value });
  };
  handleSend = () => {
    const { comment } = this.state;
    const { entityName, object } = this.props;
    if (comment) {
      const commentBody = {
        comment: {
          ...this._mapComment(this.state.comment, this.props.currentUser)
        }
      };
      if (entityName == 'Sale') {
        this.props.addSaleComment({
          ...commentBody,
          sale_id: object.id
        });
      }
      if (entityName == 'Promissory') {
        this.props.addPromissoryComment({
          ...commentBody,
          promissory_id: object.id
        });
      }
      this.setState({ comment: '' });
    }
  };
  _mapComment = (comment, newCommentUser) => {
    if (!comment) return {};
    if (newCommentUser) {
      return {
        photo: _get(newCommentUser, 'photo', ''),
        name: `${_get(newCommentUser, 'firstName', '')} ${_get(
          newCommentUser,
          'lastName',
          ''
        )}`,
        username: _get(newCommentUser, 'username', 'anónimo'),
        comment: comment,
        date: moment().subtract(5, 'hours').format()
      };
    }
    return {
      photo: _get(comment, 'user.photo', ''),
      name: `${_get(comment, 'user.firstName', '--')} ${_get(
        comment,
        'user.lastName',
        ''
      )}`,
      username: _get(comment, 'user.username', 'anónimo'),
      comment: _get(comment, 'comment', '--'),
      date: _get(comment, 'createdAt', ''),
      type: _get(comment, 'type', ''),
      status: _get(comment, 'status', '').toUpperCase()
    };
  };

  _getComments = () => {
    const { newComments, previousComments } = this.props;
    const actualComments = [];
    newComments.forEach(c => {
      actualComments.push(c);
    });
    previousComments.forEach(c => {
      actualComments.push(this._mapComment(c));
    });
    return actualComments;
  };

  render() {
    const { classes, requestSend, disableCreation } = this.props;
    return (
      <CollapseWrapper
        expanded={true}
        classes={{
          root: classes.root,
          header: classes.panelHeader,
          expandButton: classes.expandButton,
          bodyRoot: classes.collapsePanelBodyRoot
        }}
        header={() => (
          <div className={classes.titleWrapper}>
            <h2 className={classnames(classes.headline8, classes.title)}>
              {'Comentarios'}
            </h2>
          </div>
        )}
        body={
          <Fragment>
            {!disableCreation && (
              <div>
                <CommentInput
                  multiline={true}
                  onSend={this.handleSend}
                  loading={requestSend}
                  name="comment"
                  placeholder="Añadir comentario"
                  onChange={this.handleChange}
                  value={this.state.comment}
                />
              </div>
            )}
            <Divider />
            {this._getComments().map((comment, index) => (
              <Fragment key={index}>
                <CommentItem {...comment} withAvatar />
              </Fragment>
            ))}
          </Fragment>
        }
      />
    );
  }
}
const styles = (/* theme */) => ({});

export default withStyles(
  combineStyles(
    collapseWrapperStyles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    styles
  )
)(Comment);
