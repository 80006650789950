import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import CalculateInstalments from './CalculateInstalments';
import {
  closeCalculateInstalmentsModal,
  calculateInstalments
} from '../../../actions/calculate_instalments_actions';
import { articleTypesResponseSanitizer } from '../../../helpers/sanitizers/calculateInstalmentsSanitizer';

const mapStateToProps = state => {
  return {
    isOpen: state.calculateInstalments.isCalculateInstalmentsModalOpen,
    calculatingInstalments: state.requestState.requestInstalmentsCalculationSend,
    instalmentsValue: state.calculateInstalments.instalmentsValue,
    obligatoryInsurance: state.calculateInstalments.obligatoryInsurance,
    valueToFinance: state.calculateInstalments.valueToFinance,
    serverErrors: state.requestState.requestErrorsOnInstalmentsCalculation,
    secureValue: state.calculateInstalments.secureValue,
    maxInstalmentsNumber: state.calculateInstalments.maxInstalmentsNumber,
    articleTypes: articleTypesResponseSanitizer(state.globalSetting.orgData.article_types),
    voluntaryInsurances: state.globalSetting.orgData.voluntary_insurances_by_custom_type,
    isSecureSaleCheckboxActive: state.globalSetting.orgData.secure_sale_in_instalments_calculation_active,
    layerTwoValue: state.globalSetting.orgData.voluntary_insurance_layer_two_value,
    layerTwoName: state.globalSetting.orgData.voluntary_insurance_layer_two_name,
    supplier: state.authentication.currentUser.supplier,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: bindActionCreators(closeCalculateInstalmentsModal, dispatch),
    calculateInstalments: bindActionCreators(calculateInstalments, dispatch)
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CalculateInstalments);
