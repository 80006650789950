import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import SystemStatus from './SystemStatus';
import {
  fetchServicesStatus,
} from '../../../actions/system_status_actions';

function mapStateToProps(state) {
  return {
    serviceStatus: state.systemStatus.statuses,
    requestServicesStatusSend: state.requestState.requestServicesStatusSend,
    timeValue: state.globalSetting.orgData.health_timeout,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchServicesStatus: bindActionCreators(fetchServicesStatus, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SystemStatus);