import SecureProductMovements from './SecureProductMovements';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchSecureProductMovements,
  getSecureProducts,
  resetProductData,
  updateSecureProduct
} from '../../../actions/secure_product_movements_actions';

function mapStateToProps(state) {
  const currentUser = state.authentication.currentUser;
  const { movements, meta } = state.secureProductMovements;
  const canUpdateSecureProduct = currentUser ? currentUser.canUpdateSecureProduct : false;
  return {
    movements,
    meta,
    requestSend: state.requestState.requestSecureProductMovementListSend,
    product_data: state.secureProductMovements.product_data || [],
    canUpdateSecureProduct: canUpdateSecureProduct
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSecureProductMovements: bindActionCreators(fetchSecureProductMovements, dispatch),
    getSecureProducts: bindActionCreators(getSecureProducts, dispatch),
    resetProductData: bindActionCreators(resetProductData, dispatch),
    updateSecureProduct: bindActionCreators(updateSecureProduct, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SecureProductMovements);
