import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import { Formik } from 'formik';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import * as Yup from 'yup';
import _isEmpty from 'lodash/isEmpty';
import SimpleSelect from '../../../components/shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import CategorySelector from './CategorySelector';
import { DialogContentText } from '@material-ui/core';

const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required('El campo es requerido'),
  lineExecutiveId: Yup.number().nullable().required('El campo es requerido')
});

const SaveModal = props => {
  const {
    open,
    classes,
    onClose,
    onSubmit,
    isSubmitting,
    profile,
    lineExecutives=[],
    categories=[],
  } = props;
  const action = !!profile.id ? 'update' : 'create';
  const dialogTitle = action === 'create' ? 'Crear perfil' : 'Editar perfil';
  return (
    <Formik
      initialValues={{
        id: profile.id,
        name: profile.name || '',
        lineExecutiveId: profile.lineExecutiveId || '',
        categoryIds: profile.categoryIds || []
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={
        ({
          values,
          handleSubmit,
          handleChange,
          touched,
          handleBlur,
          errors,
          setFieldValue,
        }) => {
          return (
            <Dialog open={open} onClose={onClose}>
              <DialogTitle>{dialogTitle}</DialogTitle>
              <DialogContent className={classes.saveModalContent}>
                <OutlinedTextField
                  name='name'
                  label='Nombre'
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.name && errors.name}
                  error={touched.name && !!errors.name}
                />
                <SimpleSelect
                  name='lineExecutiveId'
                  label='Ejecutivo'
                  options={mapListToOptions(lineExecutives, {}, false)}
                  value={values.lineExecutiveId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.lineExecutiveId && errors.lineExecutiveId}
                  error={touched.lineExecutiveId && !!errors.lineExecutiveId}
                />
                <DialogContentText className={classes.categoriesSubtitle}>
                  Categorias
                </DialogContentText>
                <CategorySelector
                  categories={categories}
                  selectedCategoryIds={values.categoryIds}
                  categoryIsDisabled={
                    category => {
                      if (_isEmpty(profile)) return !!category.lineExecutiveProfileId;
                      return (
                        !!category.lineExecutiveProfileId &&
                        category.lineExecutiveProfileId.toString() !== profile.id.toString()
                      )
                    }
                  }
                  onChange={
                    params => {
                      const { categoryIds } = params;
                      setFieldValue('categoryIds', categoryIds);
                    }
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancelar
                </Button>
                <ProgressButton
                  color="secondary"
                  onClick={() => handleSubmit(values)}
                  isSubmitting={isSubmitting}
                >
                  Guardar
                </ProgressButton>
              </DialogActions>
            </Dialog>
          );
        }
      }
    />
  );
};

export default SaveModal;