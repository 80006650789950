import _pickBy from 'lodash/pickBy';

const sinisterFilters = filters => {
  const { saleId, contractId, causal, status, page, perPage } = filters;
  const sanitizedFilters = {
    saleId,
    contractId,
    causal,
    status,
    page,
    perPage
  };

  const STATUSES = {
    registered: 0,
    approved: 1,
    revoked: 2,
  };

  const params = _pickBy(sanitizedFilters, filter => !!filter);

  return {
    ...params,
    status: STATUSES[status]
  };
};

export default sinisterFilters;
