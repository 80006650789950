import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import classnames from 'classnames';
import combineStyles from '../../../../helpers/combineStyles';
import TypographyStyles from '../../../../styles/typography';
import LayoutStyles from '../../../../styles/layout';
import SpacingStyles from '../../../../styles/helpers/spacing';
import ProgressBar from './ProgressBar';
import { IconButton, Fade } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MoreHoriz';
import RefreshIcon from '@material-ui/icons/Refresh';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import StatusStyles from './__styles__/Status.styles';
import StatusLabel from './StatusLabel';
import FlashMessage from '../../../shared/Alerts/Flash/FlashMessage';
import { formatSaleStatusLabel } from '../../../../helpers/utilityFunctions';

export class Status extends Component {
  static propTypes = {
    saleDetail: PropTypes.object.isRequired
  };

  state = {
    menuElement: null,
    isFlashOpen: true
  };

  _handleMenuClick = event => {
    this.setState({ menuElement: event.currentTarget });
  };

  _handleMenuClose = () => {
    this.setState({ menuElement: null });
  };

  _handleCloseFlash = () => {
    this.setState({ isFlashOpen: !this.state.isFlashOpen });
  };

  _permissionsTransfer = () => {
    const { saleDetail } = this.props;
    if (!saleDetail) return false;
    if (
      !saleDetail['hasPendingQuotaTransfer'] &&
      !saleDetail['hasPendingQuotaUnion']
    )
      return false;
    if (saleDetail.quotaTransfers.length == 0) return false;
    if (
      saleDetail.quotaTransfers[0].status == 'registered' &&
      this.props.canReviewTransfer
    ) {
      return 'Revisar';
    }
    if (
      saleDetail.quotaTransfers[0].status == 'reviewed' &&
      this.props.canApproveTransfer
    ) {
      return 'Aprobar';
    }
    return false;
  };

  _getMenuOptions = sale => {
    const menuOptions = [];
    if (!sale.canceledAt) {
      if (!sale.deliveredAt && this.props.canDeliver) {
        menuOptions.push({
          label: 'Entregar productos',
          action: this._productDelivery
        });
      }
      this._permissionsTransfer() &&
        menuOptions.push({
          label: `${this._permissionsTransfer()} solicitud`,
          action: this._transferRevision
        });
      if (!sale.approvedAt && this.props.canApproveSale) {
        menuOptions.push({
          label: 'Aprobar',
          action: this._approveSale
        });
      }
      if (sale.deliveredAt && this.props.canReturnSale) {
        menuOptions.push({
          label: 'Realizar Devolución',
          action: this._returnSale.bind(this, 'Return-Sale')
        });
      }
      if (sale.deliveredAt && this.props.canReturnManual) {
        menuOptions.push({
          label: 'Realizar Devolución Manual',
          action: this._returnSale.bind(this, 'Return-SaleManual')
        });
      }
      if (
        !sale.deliveredAt &&
        (this.props.canRevokeSales || this.props.canRevokeArticlesSales)
      ) {
        menuOptions.push({
          label: 'Anular',
          action: this._cancelSale
        });
      }
      if (
        sale.deliveredAt &&
        this.props.canRegisterSinisters &&
        sale.isSecureSale &&
        moment().diff(sale.deliveredAt, 'days', true) >=
          this.props.daysForSinister
      ) {
        menuOptions.push({
          label: 'Registrar siniestro',
          action: this.registerSinister
        });
      }
    }

    return menuOptions;
  };

  _productDelivery = () => {
    this.props.toggleAction('Product-Delivery', true);
    this._handleMenuClose();
  };

  _approveSale = () => {
    this.props.toggleAction('Approve-Sale', true);
    this._handleMenuClose();
  };

  _returnSale = action => {
    this.props.toggleAction(action, true);
    this._handleMenuClose();
  };

  _cancelSale = () => {
    this.props.toggleAction('Cancel-Sale', true);
    this._handleMenuClose();
  };

  registerSinister = () => {
    this.props.toggleAction('Register-Sinister', true);
    this._handleMenuClose();
  };

  _transferRevision = () => {
    this.props.onOpenRevision('quotaTransferOpen');
    this._handleMenuClose();
  };

  getFlashMessage = saleDetail => {
    if (!saleDetail) return '';
    if (!saleDetail.externalCode) return 'La venta está siendo registrada...';
    if (saleDetail.hasPendingSinister)
      return 'Pendiente por solicitud de siniestro';
  };

  render() {
    const { classes, saleDetail } = this.props;

    const { menuElement, isFlashOpen } = this.state;

    const menuOptions = this._getMenuOptions(saleDetail);
    const message = this.getFlashMessage(saleDetail);
    const hideStatusMenu =
      saleDetail['hasApprovedSinister'] || saleDetail['hasRevokedSinister'];
    return (
      <div className={classnames(classes.wrapperNoPadding, classes.root)}>
        <div
          className={classnames(
            classes.titleContentDivider,
            classes.titleRowContainer
          )}
        >
          <h2 className={classnames(classes.headline8)}>{'Estado'}</h2>
          <div className={classes.statusTextContainer}>
            <StatusLabel
              label={formatSaleStatusLabel(saleDetail.saleStatus)}
              type={saleDetail.saleStatus}
            />
          </div>
          <div className={classes.bannerContainer}>
            {Boolean(message) ? (
              <FlashMessage
                variant="info"
                message={message}
                actionIcon={<RefreshIcon />}
                actionClick={this.props.reFetch}
              />
            ) : (
              <Fragment>
                {saleDetail['hasPendingQuotaTransfer'] && (
                  <Fade in={isFlashOpen} unmountOnExit={true}>
                    <FlashMessage
                      variant="label"
                      message="Solicitud de Traslado pendiente"
                    />
                  </Fade>
                )}
                {saleDetail['hasPendingQuotaUnion'] && (
                  <Fade in={isFlashOpen} unmountOnExit={true}>
                    <FlashMessage
                      variant="label"
                      message="Solicitud de Unión pendiente"
                    />
                  </Fade>
                )}
                {saleDetail['hasApprovedSinister'] && (
                  <Fade in={isFlashOpen} unmountOnExit={true}>
                    <FlashMessage
                      variant="label"
                      message="Solicitud de siniestro aprobada"
                    />
                  </Fade>
                )}
                {saleDetail['hasRevokedSinister'] && (
                  <Fade in={isFlashOpen} unmountOnExit={true}>
                    <FlashMessage
                      variant="label"
                      message="Solicitud de siniestro anulada"
                    />
                  </Fade>
                )}
                {saleDetail['hasPendingReturnProcess'] && (
                  <Fade in={isFlashOpen} unmountOnExit={true}>
                    <FlashMessage
                      variant="label"
                      message={`Solicitud de devolución ${
                        saleDetail['returnProcessType'] == 'total'
                          ? 'total'
                          : 'parcial'
                      } pendiente...`}
                    />
                  </Fade>
                )}
                {!hideStatusMenu && (
                  <div className={classes.statusMenu}>
                    <IconButton
                      color="secondary"
                      onClick={this._handleMenuClick}
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
        <div>
          <div className={classes.progressBarContainer}>
            <ProgressBar
              status={saleDetail.saleStatus}
              saleDetail={saleDetail}
            />
          </div>
        </div>
        <Menu
          anchorEl={menuElement}
          open={Boolean(menuElement)}
          onClose={this._handleMenuClose}
        >
          {menuOptions.map((option, index) => {
            return (
              <MenuItem
                key={index}
                onClick={option.action}
                selected={index < 1}
                classes={{
                  selected: classes.selectedItemMenu
                }}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing.unit * 3.75
  }
});

export default withStyles(
  combineStyles(
    StatusStyles,
    TypographyStyles,
    SpacingStyles,
    LayoutStyles,
    styles
  )
)(Status);
