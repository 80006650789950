import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { OpenInNewRounded } from '@material-ui/icons';
import React from 'react';
import {
  COMPANY_SENIORITY_RANGES,
  COMPANY_SIZES
} from '../../../../constants/enums';
import mapListToOptions from '../../../../helpers/mapListToOptions';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';
import OutlinedSelectChippedInput from '../../../shared/Inputs/OutlinedSelectChipped';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import StepTitle from '../StepTitle';
import GeneralDataDocuments from './GeneralDataDocuments';

const GeneralDataStep = props => {
  const {
    classes,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    proposalWasSent,
    setFieldValue,
    handleChangeOnlyDigits,
    creator,
    onAddDocument,
    canCreateDocuments,
    updateDocument,
    loadingDocumentIds,
    onViewOtherComments,
    onAddComment,
    canAcceptOrRejectFile,
    showCommentSection,
    allowFileReplace,
    fileMaxSize,
    fileSizeUnit,
    extensions,
    allowAddComment,
    handleFileChange,
    handleFileError,
    handleFileAcceptanceChange,
    getDocByDocType,
    fetchFileBase64,
    newAlert,
    proposalWasRejected,
    userIsAspiringSupplier
  } = props;

  return (
    <Grid container item className={classes.generalDataStepContainer}>
      <StepTitle />
      {creator && (
        <Grid container item>
          <Grid item className={classes.generalDataStepSubtitle}>
            <Typography variant="body1">Información Aspirante</Typography>
          </Grid>
          <Grid container item>
            <Grid container item justify="flex-start" xs={4}>
              <OutlinedTextField
                className={classes.generalDataStepTextField}
                name="creatorName"
                label="Nombre"
                value={creator.name.toUpperCase()}
                disabled
              />
            </Grid>
            <Grid container item justify="center" xs={4}>
              <OutlinedTextField
                className={classes.generalDataStepTextField}
                name="creatorIdType"
                label="Tipo de Identificación"
                value={creator.idType.toUpperCase()}
                disabled
              />
            </Grid>
            <Grid container item justify="flex-end" xs={4}>
              <OutlinedTextField
                className={classes.generalDataStepTextField}
                name="creatorIdentification"
                label="Identificación"
                value={creator.identification}
                disabled
              />
            </Grid>
            <Grid container item justify="flex-start" xs={4}>
              <OutlinedTextField
                className={classes.generalDataStepTextField}
                name="creatorMobile"
                label="Número Celular"
                value={creator.mobile}
                disabled
              />
            </Grid>
            <Grid container item justify="center" xs={4}>
              <OutlinedTextField
                className={classes.generalDataStepTextField}
                name="creatorEmail"
                label="Correo Electrónico"
                value={creator.email}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item className={classes.generalDataStepSubtitle}>
        <Typography variant="body1">
          Completa la siguiente información general de la empresa
        </Typography>
      </Grid>
      <Grid container item>
        <Grid container item justify="flex-start" xs={4}>
          <OutlinedTextField
            className={classes.generalDataStepTextField}
            name="companyName"
            label="Nombre comercial"
            value={values.companyName}
            onBlur={handleBlur}
            onChange={event => {
              const change = event.target.value.toUpperCase();
              setFieldValue('companyName', change);
            }}
            helperText={touched.companyName && errors.companyName}
            error={touched.companyName && !!errors.companyName}
            disabled={proposalWasSent}
          />
        </Grid>
        <Grid container item justify="center" xs={4}>
          <OutlinedTextField
            className={classes.generalDataStepTextField}
            name="nit"
            label="NIT"
            value={values.nit}
            onBlur={handleBlur}
            inputProps={{ maxLength: 11 }}
            onChange={event => {
              handleChangeOnlyDigits(event, 'nit', setFieldValue);
            }}
            helperText={
              touched.nit && !!errors.nit
                ? touched.nit && errors.nit
                : 'Por favor incluir dígito de verificación sin guión'
            }
            error={touched.nit && !!errors.nit}
            disabled={proposalWasSent}
          />
        </Grid>
        <Grid container item justify="flex-end" xs={4}>
          <OutlinedTextField
            className={classes.generalDataStepTextField}
            name="businessName"
            label="Razón social"
            value={values.businessName}
            onBlur={handleBlur}
            onChange={event => {
              const change = event.target.value.toUpperCase();
              setFieldValue('businessName', change);
            }}
            helperText={touched.businessName && errors.businessName}
            error={touched.businessName && !!errors.businessName}
            disabled={proposalWasSent}
          />
        </Grid>
        <Grid container item justify="flex-start" xs={4}>
          <SimpleSelect
            style={{ width: '90%' }}
            name="companySeniority"
            label="Antigüedad de la empresa"
            options={mapListToOptions(
              COMPANY_SENIORITY_RANGES,
              { withId: false },
              true
            )}
            value={values.companySeniority}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.companySeniority && errors.companySeniority}
            error={touched.companySeniority && !!errors.companySeniority}
            disabled={proposalWasSent}
          />
        </Grid>
        <Grid container item justify="center" xs={4}>
          <OutlinedTextField
            className={classes.generalDataStepTextField}
            name="positionInCompany"
            label="Su cargo en la empresa"
            value={values.positionInCompany}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.positionInCompany && errors.positionInCompany}
            error={touched.positionInCompany && !!errors.positionInCompany}
            disabled={proposalWasSent}
          />
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} container justify="center">
          <img src="/images/company_size.jpg" height={300} />
          <a href="/images/company_size.jpg" target="_blank">
            <Tooltip title={'Expandir'} placement="top">
              <OpenInNewRounded
                style={{
                  position: 'relative',
                  top: '0',
                  left: '0'
                }}
                color="secondary"
              />
            </Tooltip>
          </a>
        </Grid>
        <Grid item xs={4}>
          <OutlinedSelectChippedInput
            label={'Tamaño de la empresa'}
            classes={{
              root: classes.selectRoot,
              filterColor: classes.filterColor
            }}
            name={'companySize'}
            options={COMPANY_SIZES}
            onChange={handleChange}
            inputLabel={'Tamaño de la empresa'}
            value={values.companySize}
            onBlur={handleBlur}
            helperText={touched.companySize && errors.companySize}
            error={touched.companySize && !!errors.companySize}
            disabled={proposalWasSent}
            withChip={false}
          />
        </Grid>
      </Grid>
      <GeneralDataDocuments
        values={values}
        onViewOtherComments={onViewOtherComments}
        onAddComment={onAddComment}
        canAcceptOrRejectFile={canAcceptOrRejectFile}
        showCommentSection={showCommentSection}
        fileMaxSize={fileMaxSize}
        fileSizeUnit={fileSizeUnit}
        extensions={extensions}
        allowAddComment={allowAddComment}
        handleFileError={handleFileError}
        newAlert={newAlert}
        proposalWasSent={proposalWasSent}
        proposalWasRejected={proposalWasRejected}
        userIsAspiringSupplier={userIsAspiringSupplier}
        classes={classes}
        setFieldValue={setFieldValue}
        fetchFileBase64={fetchFileBase64}
        handleFileChange={handleFileChange}
        handleFileAcceptanceChange={handleFileAcceptanceChange}
        loadingDocumentIds={loadingDocumentIds}
      />
    </Grid>
  );
};

export default GeneralDataStep;
