import React, { PureComponent, Fragment } from 'react';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CollapseWrapper from '../../../shared/CollapseWrapper.js';
import classnames from 'classnames';

import combineStyles from '../../../../helpers/combineStyles';
import SpacingStyles from '../../../../styles/helpers/spacing';
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import collapseWrapperStyles from '../../../../styles/components/collapseWrapper';

import Divider from '@material-ui/core/Divider';

import ActivityList from './ActivityList';

const styles = theme => ({
  activityLogButton: {
    marginBottom: 0,
    marginTop: theme.spacing.unit * 2
  },
  activityModule: {
    marginTop: theme.spacing.unit * 3
  },
  root: {
    backgroundColor: '#FFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    width: '100%'
  }
});
export class Activity extends PureComponent {
  static propTypes = {
    activities: PropTypes.array.isRequired
  };

  render() {
    const { classes, activities } = this.props;
    const sortedActivities = _sortBy([...activities], ['date']);
    const displayedActivities = _reverse(sortedActivities);
    return (
      <CollapseWrapper
        expanded={true}
        classes={{
          root: classes.root,
          header: classes.panelHeader,
          expandButton: classes.expandButton,
          bodyRoot: classes.collapsePanelBodyRoot
        }}
        header={() => (
          <div className={classes.titleWrapper}>
            <h2 className={classnames(classes.headline8, classes.title)}>
              {'Actividad'}
            </h2>
          </div>
        )}
        body={
          <Fragment>
            <Divider variant={'middle'} />
            <div className={classes.body}>
              <ActivityList activities={displayedActivities} />
            </div>
          </Fragment>
        }
      />
    );
  }
}

export default withStyles(
  combineStyles(
    SpacingStyles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    styles,
    collapseWrapperStyles
  )
)(Activity);
