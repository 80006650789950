import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  contract: Yup.number().required('El campo es requerido').min(1, 'Ingresa un contrato válido.'),
  identificationType: Yup.string().required('El campo es requerido'),
  identification: Yup.number().required('El campo es requerido').min(1, 'Ingresa una identificación válida.'),
  debtorBirthday: Yup.date().nullable().required('El campo es requerido'),
  debtorDocumentExpeditionDate: Yup.date().nullable().required('El campo es requerido'),
  coDebtorContract: Yup.number().when('hasCoDebtor', {
    is: val => val === true,
    then: Yup.number()
      .required('El campo es requerido')
      .min(1, 'Ingresa un contrato válido.')
  }),
  coDebtorIdentificationType: Yup.string().when('hasCoDebtor', {
    is: val => val === true,
    then: Yup.string()
      .required('El campo es requerido')
  }),
  coDebtorIdentification: Yup.number().when('hasCoDebtor', {
    is: val => val === true,
    then: Yup.number()
      .required('El campo es requerido')
      .min(1, 'Ingresa una identificación válida.')
  }),
  coDebtorBirthday: Yup.date().nullable().when('hasCoDebtor', {
    is: val => val === true,
    then: Yup.date().nullable()
      .required('El campo es requerido')
  }),
  coDebtorDocumentExpeditionDate: Yup.date().nullable().when('hasCoDebtor', {
    is: val => val === true,
    then: Yup.date().nullable()
      .required('El campo es requerido')
  }),
  mobile: Yup.string()
  .min(10, "Número invalido")
  .max(10, "Número invalido"),

});