import _get from 'lodash/get';
import { _moneyFormat } from '../../../helpers/utilityFunctions';

export const columns = [
  {
    title: 'PUESTO',
    name: 'rank'
  },
  {
    title: 'ASESOR',
    name: 'nameSeller'
  },
  {
    title: '# VENTAS',
    name: 'counter'
  },
  {
    title: 'TOTAL VENDIDO',
    name: 'sales'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      rank: datum.rank,
      nameSeller: datum.nameSeller || '',
      counter: datum.counter || '',
      sales: _moneyFormat(datum.sales) || ''
    };
  });
