import React, { Fragment } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Avatar,
  Button,
  ListItem,
  Popover,
  ListItemText,
  IconButton
} from '@material-ui/core';
import { history } from '../../../helpers/history';

class AvatarLayout extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    logout: PropTypes.func,
    currentUser: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false
    };
  }

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open
    }));
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  goToMyProfile = () => {
    this.handleClose();
    if (this.props.location.pathname !== '/my-profile') {
      history.push(`/my-profile`);
    }
  };
  goOut = () => {
    this.props.logout();
  };

  goToBrillaELearning  = () => {
    this.handleClose();
    window.open('https://brilla.pandoty.com/', '_blank');
  };

  render() {
    const { classes, currentUser } = this.props;
    const { anchorEl, open } = this.state;
    const id = open ? 'simple-popper' : null;
    const {canSetPortalELearningButtomInNavProfile} = this.props.currentUser;
    if (!currentUser) {
      return null;
    }
    // console.log('CurrentUser', currentUser)
    return (
      <Fragment>
        <IconButton
          disableRipple={true}
          id="profileButton"
          aria-label="Visibility"
          title={'My Profile'}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{ backgroundColor: 'transparent' }}
        >
          {currentUser.photo ? (
            <Avatar src={currentUser.photo} />
          ) : (
            <Avatar className={classes.avatar} classes={{
              colorDefault: classes.abbrevation
            }}>
              <span classes={classes.abbrevation}>
                {currentUser.firstName ? currentUser.firstName[0] : ''}
                {currentUser.lastName ? currentUser.lastName[0] : ''}
              </span>
            </Avatar>
          )}
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onBackdropClick={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Paper style={{ minWidth: 350 }}>
            <ListItem>
              {currentUser.photo ? (
                <Avatar className={classes.bigAvatar} src={currentUser.photo} />
              ) : (
                <Avatar className={classes.bigAvatar}>
                  {currentUser.firstName
                    ? (currentUser.firstName[0] || '').toUpperCase()
                    : ''}
                  {currentUser.lastName
                    ? (currentUser.lastName[0] || '').toUpperCase()
                    : ''}
                </Avatar>
              )}
              <ListItemText
                primary={`${currentUser.firstName ||
                  'Sin nombre'} ${currentUser.lastName || 'Sin nombre'}`}
                secondary={currentUser.email || 'john@mail.com'}
              />
            </ListItem>
            <Grid
              container
              justify="flex-start"
              style={{ paddingRight: 24, paddingLeft: 24, paddingBottom: 12 }}
            >
              <Grid item>
                <Button color="secondary" onClick={() => this.goToMyProfile()}>
                  Mi perfil
                </Button>
              </Grid>
              {canSetPortalELearningButtomInNavProfile && (
                <Grid item>
                  <Button color="secondary" onClick={() => this.goToBrillaELearning()}>
                    Portal E-Learning
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button color="secondary" onClick={() => this.goOut()}>
                  Salir
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Popover>
      </Fragment>
    );
  }
}
const styles = theme => ({
  textList: {
    fontSize: 18,
    color: '#ffebee'
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  avatar: {
    backgroundColor: '#FFF'
  },
  abbrevation: {
    color: theme.typography.color.second,
    fontWeight: 'bold'
  }
});
export default compose(withStyles(styles, { withTheme: true }))(AvatarLayout);
