import _pickBy from 'lodash/pickBy';

export const pointOfSaleFilterSanitizer = filters => {
  const { name, address, supplier, status, hasSecurityQuestions, hasFingerprintBiometric, page, perPage } = filters;
  const sanitizedFilters = {
    name: name,
    address: address,
    supplierName: supplier,
    page: page,
    perPage
  };
  if (status === 'activos') {
    sanitizedFilters.active = true;
  }

  if (status === 'inactivos') {
    sanitizedFilters.active = false;
  }
  if (hasSecurityQuestions === 'SI') {
    sanitizedFilters.hasSecurityQuestions = true;
  }

  if (hasSecurityQuestions === 'NO') {
    sanitizedFilters.hasSecurityQuestions = false;
  }

  if (hasFingerprintBiometric === 'SI') {
    sanitizedFilters.hasFingerprintBiometric = true;
  }

  if (hasFingerprintBiometric === 'NO') {
    sanitizedFilters.hasFingerprintBiometric = false;
  }
  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

export const schedulePointOfSaleFilterSanitizer = params => {
  const { categoryId, contractId } = params;
  const sanitizedFilters = {
    categoryId,
    contractId
  };

  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

export const posDashboardFilterSanitizer = filters => {
  const { supplier, selectedZones } = filters;
  const sanitizedFilters = {
    supplier_id: supplier,
    selected_zones: selectedZones.map(zone => zone.value).join(',')
  };

  return _pickBy(sanitizedFilters, filter => !!filter);
};

export const setSellersSanitizer = sellers => {
  const mappedSellers = [];
  if (!sellers) {
    return mappedSellers;
  }
  sellers.map(seller =>
    mappedSellers.push({
      id: seller.id,
      firstName: seller.firstName || seller.first_name || '',
      lastName: seller.lastName || seller.last_name || '',
      email: seller.email || '',
      username: seller.username || '',
      photo: seller.photo || '',
      association: seller.association || [],
      roles: seller.roles || []
    })
  );
  return mappedSellers;
};
