import _pickBy from 'lodash/pickBy';

const dataToCreateCommissionsFilters = filters => {
  const { supplier_name, category_name, article_name } = filters;

  const sanitizedFilters = {
    name_cont: supplier_name,
    supplies_name_cont: article_name,
    supplies_active_eq: true,
    supplies_category_name_cont: category_name,
    supplies_category_depth_eq: 1,
    supplies_category_active_eq: true
  };

  return _pickBy(sanitizedFilters, filter => !!filter);
};

export default dataToCreateCommissionsFilters;
