import { BULK_LOAD_SET } from '../constants/action_constants';
import { bulkLoadSanitizer } from '../helpers/sanitizers/bulkLoadSanitizer';
import RequestService from '../services/request_service';
import { completeRequest, sendRequest } from './request_state';
import { newAlert } from './alert_actions';
import { GRACEPERIOD } from '../constants/bulk_load';


const requestService = new RequestService('node');

//* PURE ACTION FUNCTIONS
function success(items, pagi) {
  return {
    type: BULK_LOAD_SET,
    data: items
  };
}

// * INDEX *
export const fetchBulkProcesses = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  try {
    const payload = await requestService.get(
      'bulk-processes',
      bulkLoadSanitizer(params)
    );
    dispatch(success(payload));
  } catch (errors) {
    console.log(errors);
  } finally {
    dispatch(completeRequest());
  }
};

export const createGracePeriodMassive = (body = {}) => async dispatch => {
  dispatch(sendRequest('createGracePeriodMassive'));
  try {
    const data = new FormData();
    data.append('file', body.file, body.file.name);
    let payload;
    switch (body.option) {
      case GRACEPERIOD:
        payload = await requestService.post(
          'sales/massive',
          data,
          {},
          true,
          {},
          false
        );
        break;
    }
    dispatch(newAlert('success', 's', payload.data.message));
    return { success: true, payload };
  } catch (errors) {

    if (typeof errors !== 'undefined') {
      const e = await errors.json();
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
      return errors;
    }

    dispatch(
      newAlert(
        'error',
        'ERROR:',
        'Surgió un problema al subir el archivo. Intenté de nuevo.'
      )
    );
  } finally {
    dispatch(completeRequest('createGracePeriodMassive'));
  }
};
