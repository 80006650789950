/* eslint-disable no-dupe-keys */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import ActivityIndicator from '../../shared/ActivityIndicator';

const styles = theme => ({
  ['@media (max-width: 1540px)']: {
    rootBase: {
      padding: theme.spacing.unit + 'px !important',
      //maxWidth: '560px !important'
    }
  },
  ['@media (max-width: 1440px)']: {
    totalCount: {
      fontSize: '30px !important'
    },
    month: {
      fontSize: '30px !important'
    }
  },
  ['@media (max-width: 620px)']: {
    totalCount: {
      fontSize: '1rem !important',
      marginTop: '5px'
    },
    month: {
      fontSize: '20px !important',
      margin: '0px !important',
      textAlign: 'left !important',

    },
    card: {
      display: 'inline !important'
    },
    message: {
      marginTop: '3px',
      marginBottom: '8px'
    }
  },
  rootBase: {
    display: 'inline-flex',
    backgroundColor: '#FFFFFF',
    borderRadius: '2px',
    height: '174px',
    alignItems: 'center',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    padding: theme.spacing.unit * 3,
    width: '100%',
    maxWidth: '660px',
    position: 'relative'
  },
  loadingIndicator: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 1
  },
  totalCount: {
    lineHeight: '1.3rem',
    fontSize: '40px',
    textAlign: 'left',
    fontWeight: 'bold !important',
    fontFamily: 'Roboto',
    marginBottom: '20px'
  },
  month: {
    marginRight: '20px',
    marginBottom: '0px',
    marginTop: '30px',
    fontSize: '40px',
    alignItems: 'center'
  },
  imageIcon: {
    height: '100%'
  },
  iconRoot: {
    width: '70px',
    height: '70px',
    margin: '20px'
  },
  message: {
    fontSize: '20px',
    textAlign: 'left',
    marginBottom: '10px',
    marginRight: '10px',
    color: '#00000099',
    fontWeight: '600'
  },
  card: {
    display: 'inline-flex',
    paddingLeft: '0 !important'
  }
});

export const SellerCard = ({
  classes,
  icon,
  month,
  message,
  totalCount,
  isLoading
}) => (
  <Card className={classes.rootBase}>
    {isLoading && <ActivityIndicator className={classes.loadingIndicator} />}

    <Icon classes={{ root: classes.iconRoot }}>
      <img className={classes.imageIcon} src={icon} />
    </Icon>

    <CardContent className={classes.card}>
      <p className={classes.month}>{month}</p>
      <div>
        <p className={classes.message}>{message}</p>
        <p className={classnames(classes.headline4, classes.totalCount)}
          style={{ color: totalCount.color }}>
          {totalCount.value}
        </p>
      </div>
    </CardContent>
  </Card>
);

SellerCard.propTypes = {
  icon: PropTypes.string.isRequired,
  month: PropTypes.string,
  message: PropTypes.string.isRequired,
  totalCount: PropTypes.shape({
    color: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles)
)(SellerCard);
