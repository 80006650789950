const styles = theme => ({
  progressBarContainer: {
    paddingLeft: theme.spacing.unit * 5.75,
    paddingRight: theme.spacing.unit * 5.75,
    paddingTop: theme.spacing.unit * 3.75
  },
  locationsContainer: {
    marginTop: theme.spacing.unit * 3.5,
    paddingLeft: theme.spacing.unit * 5.25,
    paddingRight: theme.spacing.unit * 5.875,
    paddingBottom: theme.spacing.unit * 5
  },
  statusTextContainer: {
    display: 'inline-flex',
    flex: 1,
    paddingLeft: theme.spacing.unit * 3
  },
  locationName: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing.unit * 3
  },
  titleRowContainer: {
    display: 'flex',
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2.5,
    alignItems: 'center',
    position: 'relative'
  },
  shipmentTenderedButton: {
    width: '190px',
    display: 'inline-flex',
    marginRight: theme.spacing.unit * 3.125
  },

  statusMenu: {
    flex: 0.2,
    marginLeft: theme.spacing.unit * 3
  },

  secondaryActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center'
  },

  bannerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center'
  },

  readOnlyColor: {
    color: 'rgba(12,31,44,0.38) !important'
  },
  tooltip: {
    backgroundColor: '#2A3C47',
    color: '#FFFFFF !important',
    padding: '4px 12px'
  },
  tooltipPopper: {
    top: `-${theme.spacing.unit}px !important`
  },
  // rootItemMenu: {
  //   width: '300px'
  // },
  selectedItemMenu: {
    '&:focus': {
      backgroundColor: 'white !important'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.14) !important'
    },
    backgroundColor: 'white !important'
  }
});

export default styles;
