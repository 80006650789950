import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LayoutStyles from '../../../styles/layout';
import combineStyles from '../../../helpers/combineStyles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import ButtonStyles from '../../../styles/button';
import { Grid } from '@material-ui/core';
import SystemStatusHeader from './Header'
import DetailStatusCard from './DetailStatusCard';
import CachedIcon from '@material-ui/icons/Cached';
import moment from 'moment';
import _throttle from 'lodash/throttle';

const check_icon = require('../../../assets/icons/check-circle.svg')

class SystemStatus extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      seconds: '',
      minutes: '1',
      lastUpdateTime: '',
    }
    this.secondsRemaining;
    this.intervalId;
    this.handleChange = this.handleChange.bind(this);
    this.startCountDown = this.startCountDown.bind(this);
    this.tick = this.tick.bind(this);
  }

  handleChange(event) {
    this.setState({
      seconds: event.target.seconds
    })
  }

  tick() {
    let min = Math.floor(this.secondsRemaining / 60);
    let sec = this.secondsRemaining - (min * 60);

    this.setState({
      seconds: sec,
    })

    if (sec === 0) {
      this.startCountDown();
      this.secondsRemaining = 60;
    }
    this.secondsRemaining--
  }

  startCountDown() {
    this.refresh(this.props.timeValue);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(this.tick, 1000);
    this.secondsRemaining = 60;
  }

  componentDidMount() {
    this.startCountDown();
  }

  refresh = _.throttle((timeoutValue) => {
    this.props.fetchServicesStatus(timeoutValue)
    this.setState({
      lastUpdateTime: moment().tz('America/Bogota').format('LTS')
    });
  }, 3000, { 'trailing': false });

  render() {
    const {
      classes,
      serviceStatus = [],
      requestServicesStatusSend,
    } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} className={classes.updateTextStyle}>
          <div>
            <p className={classes.timerTags}>
              Ultima actualización: {this.state.lastUpdateTime}
              <br/>
              Próxima actualización en {(this.state.seconds).toString().padStart(2,'0')} segundos
            </p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.titleRootWrapper}>
            <Grid item xs={12}>
              <p className={classes.titleStyle}>{'Sistema Brilla'}</p>
              <h1 className={classes.servicesTitle} style={{marginTop: '-10px'}}>
                {'Estado de los servicios'}
              </h1>
            </Grid>
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              style={{backgroundColor: "#00838F", marginTop: '-10px'}}
              onClick= {() => {
                this.startCountDown();
              }}
            >
              <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                <div style={{color: '#FFFFFF', marginRight: '3px'}}>
                  <CachedIcon
                  />
                </div>
                <div style={{color: '#FFFFFF', marginTop: '4px'}}>
                  {'Actualizar'}
                </div>
              </div>

            </Button>
          </div>
        </Grid>
        <SystemStatusHeader
          isLoading={requestServicesStatusSend}
          data={serviceStatus}
        />
        <Grid item xs={12}>
          <h1 className={classes.servicesTitle} style={{marginBottom: '-5px'}}>
            {'Listado de servicios'}
          </h1>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: 'flex', flexDirection: 'row',  flex: 1}}>
          <DetailStatusCard
            title={'Ventas'}
            messages={['-Registro', '-Legalización', '-Consulta de cupo']}
            data={serviceStatus['health']}
            icon={check_icon}
            isLoading={requestServicesStatusSend}
          />
          <DetailStatusCard
            title={'Consultas'}
            messages={['-Dashboard y cómo vamos', '-Notificaciones y anuncios',
              '-Administración de pagarés', '-Simulado de crédito']}
            data={serviceStatus['health2']}
            icon={check_icon}
            isLoading={requestServicesStatusSend}
          />
          <DetailStatusCard
            title={'Otros servicios'}
            messages={['-Venta de seguros', '-Configuraciones del sistema',
            '-Campañas', '-Vinculación de afiliados', '-Consulta de clientes']}
            data={serviceStatus['health_node']}
            icon={check_icon}
            isLoading={requestServicesStatusSend}
          />
        </Grid>
      </Grid>
    )
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  },

  iconRoot: {
    width: '70px',
    height: '70px',
    margin: '20px'
  },

  imageIcon: {
    height: '70%',
    width: '22px',
  },

  rowCardWrapper: {
    height: '336px',
    width: '368px',
    marginLeft: '8px',
    marginRight: '8px',
  },

  titleStyle: {
    color: '#00000099',
    fontSize: 'medium',
    marginLeft: '6px',
    fontWeight: 700
  },

  servicesTitle: {
    marginLeft: '5px',
    fontSize: '24px',
    color: '#000000DE',
    fontWeight: 700
  },

  updateTextStyle: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent:'flex-end'
  },
  timerTags: {
    fontSize: '12px',
    color: '#00000061'
  }
});

SystemStatus.propTypes = {
  //serviceStatus: PropTypes.array.isRequired,
}

export default withStyles(
  combineStyles(
    ButtonStyles,
    LayoutStyles,
    styles
  )
) (SystemStatus);