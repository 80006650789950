import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import DigitalSaleContactData from './DigitalSaleContactData';
import { newAlert } from '../../../actions/alert_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import {
  searchCacheSaleResponse,
  setNextStep,
  updateContactData
} from '../../../actions/portal_financing_actions';
import { doctypes } from '../../../constants/enums';
import { removeFirstZeros } from '../../../helpers/utilityFunctions';
import _get from 'lodash/get';
import { updateContactDataTeleshopping } from '../../../actions/teleshopping_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  const ts = state.teleshopping;
  const isRemoteTeleshopping = state.stepper.process === 'remoteTeleshopping';
  const debtor_details = _get(pf, 'promissory.debtor_details');
  const debtor_details_ = _get(pf, 'promissory.debtor');
  const cosigner_details = _get(pf, 'promissory.cosigner_details');
  const cosigner_details_ = _get(pf, 'promissory.cosigner');
  const debtor = debtor_details || debtor_details_;
  const cosigner = cosigner_details || cosigner_details_;
  const articles = [{
    article_id: _get(state, 'globalSetting.orgData.teleshopping_voluntary_insurance.cardif.article.id'),
    amount: 1,
    value: ts.price,
    num_monthly_instalments: ts.instalments,
  }];

  const data = {
    debtor: {
      name: pf.client_debtor_name || ts.client_name || debtor.name,
      last_name: pf.client_debtor_last_name || ts.client_last_name || debtor.last_name,
      debtor_doctype_id: debtor ? debtor.type_of_identification &&
        doctypes.find(o => o.name === debtor.type_of_identification).id : ts.document.split(' ')[0],
      type_of_identification: ts.document.split(' ')[0] || debtor.type_of_identification,
      identification: ts.document.split(' ')[1] || removeFirstZeros(debtor.identification),
      email: ts.client_email || _get(debtor, 'email', ''),
      phone: ts.client_phone || _get(debtor, 'phone', ''),
      mobile: ts.client_mobile || _get(debtor, 'mobile', '')
    },
    cosigner: cosigner ? {
      name: pf.client_cosigner_name || cosigner.name,
      last_name: pf.client_cosigner_last_name || cosigner.last_name,
      cosigner_doctype_id:
        cosigner.type_of_identification &&
        doctypes.find(o => o.name === cosigner.type_of_identification).id,
      type_of_identification: cosigner.type_of_identification,
      identification: removeFirstZeros(cosigner.identification),
      email: cosigner.email || '',
      phone: cosigner.phone || '',
      mobile: cosigner.mobile || ''
    } : null,
    saleDetails: {
      downPayment: isRemoteTeleshopping ? 0 : pf.validatedSaleDetails.sale.down_payment,
      articles: isRemoteTeleshopping ? articles : pf.validatedSaleDetails.articles,
      pointOfSaleID: isRemoteTeleshopping ? 0 : pf.validatedSaleDetails.sale.point_of_sale_id,
      salesmanId: isRemoteTeleshopping ? state.teleshopping.salesmanId : pf.validatedSaleDetails.sale.salesman_id,
    }
  };
  return {
    ...data,
    process: state.stepper.process,
    digitalSaleWithUUPNext: state.stepper.digitalSaleWithUUPNext,
    digitalSaleWithouFaceIdNext: state.stepper.digitalSaleWithouFaceIdNext,
    digitalSaleWithoutUUPonContractNext:
      state.stepper.digitalSaleWithoutUUPonContractNext,
    serverErrors: pf.errors,
    contract: isRemoteTeleshopping ? ts.contract : pf.debtor_contract_number,
    isRemoteTeleshopping,
    routes: state.stepper.routes,
    promissory: pf.promissory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    updateContactData: bindActionCreators(updateContactData, dispatch),
    searchCacheSaleResponse: bindActionCreators(searchCacheSaleResponse, dispatch),
    updateContactDataTeleshopping: bindActionCreators(updateContactDataTeleshopping, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitalSaleContactData);
