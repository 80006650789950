/* DASHBOARD SELLER */
import _pickBy from 'lodash/pickBy';

export const salesPerSellerFilters = filters => {
  const { pointOfSale, supplier, year, month, page, per_page, test, saleType, selectedZones, channelId } = filters;
  const zoneIds = selectedZones.map(z => z.value);

  const sanitizedFilters = {
    pointOfSaleId: pointOfSale, 
    supplierSellerId: supplier,
    year: year,
    month: month,
    page: page,
    perPage: per_page,
    test: test,
    saleType,
    zoneIds,
    saleChannelId: channelId
  };
  return _pickBy(sanitizedFilters, filter => !!filter);
};

export const dataSalesmanSupplierSanitizer = filters => {
  const { start_at, end_at, interval, supplier, page } = filters;

  const sanitizedFilters = {
    startAt: start_at,
    endAt: end_at,
    interval,
    supplierSellerId: supplier
  };
  return _pickBy(sanitizedFilters, filter => !!filter);
};
