import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CancelSale from './CancelSale';
import {
  cancelSale,
  toggleSaleAction,
  returnSale
} from '../../../actions/sales_actions';
import _get from 'lodash/get';

function mapStateToProps(state) {
  return {
    open: state.sales[`actionCancel-SaleOpen`],
    requestSend: state.requestState[`requestCancel-SaleSend`],
    cardif: _get(
      state,
      'globalSetting.orgData.voluntary_insurance.cardif.article.id'
    ),
    cardifLayerTwoId: _get(
      state,
      'globalSetting.orgData.voluntary_insurance_layer_two_article_id'
    ),
    canRevokeArticlesSales:
    state.authentication.currentUser.canRevokeArticlesSales,
    canRevokeSales:
    state.authentication.currentUser.canRevokeSales,
    revokeSaleCausals: state.globalSetting.orgData.sale_revoke_causes,
    secureSaleArticleId: state.globalSetting.orgData.secure_sale_article_id,
    b2bSelectedPos: state.globalSetting.orgData.b2b_selected_pos,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    returnSale: bindActionCreators(returnSale, dispatch),
    cancelSale: bindActionCreators(cancelSale, dispatch),
    toggleSaleAction: bindActionCreators(toggleSaleAction, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelSale);
