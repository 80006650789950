import { SET_POINT_USER_ASSOCIATION_LIST, UPDATED_POINT_USER_ASSOCIATION } from '../constants/action_constants';

const initialState = {
  pointUserAssociation: [],
  meta: {},
  saved: false
};

export function pointUserAssociation(state = initialState, action) {
  switch (action.type) {
    case SET_POINT_USER_ASSOCIATION_LIST:
      return {
        ...state,
        pointUserAssociation: action.data,
        meta: action.meta,
        saved: false
      };
    case UPDATED_POINT_USER_ASSOCIATION:
        return {
          ...state,
          saved: true,
        };
    default:
      return state;
  }
}
