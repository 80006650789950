import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import SegmentHeader from '../../shared/SegmentHeader';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';
import OtpValidationField from '../../shared/Fields/OtpValidationField';
import StepperButtons from '../../shared/StepperButtons';

class RemoteSaleEnrollmentOTP extends PureComponent {
  constructor(props) {
    super(props);

    this.setCode = this.setCode.bind(this);
  }

  setCode(code) {
    this.setState({
      code: code
    });
  }

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
    this.sendOtp();
  }

  sendOtp(isWhatsappMessage = false) {
    const mobile = this.props.mobile;

    if (mobile) {
      const params = {
        mobile: parseInt(mobile),
        entityType: 'Promissory',
        entityId: 2,
        isWhatsappMessage,
        customMessage: `Al dar el código de verificación, usted acepta las políticas de tratamiento de datos personales que puede consultar en: ${this.props.termsAndConditionsUrl} #Brilla`
      };
      this.props.sendOtp(params);
    }
  }

  onSubmit = () => {
    this.props.checkEnrollmentOTP({
      mobile: parseInt(this.props.mobile),
      otp: this.state.code
    }, this.props.routes[this.nextStep]);
  };

  state = {
    activeStep: 0,
    input: '',
  };

  render() {
    const {
      classes,
      mobile,
      canSendWhatsappOtpValidationMessage
    } = this.props;

    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <div className={classes.segmentHeaderOptions}>
            <SegmentHeader className={classes.segmentHeader}>
              {`Se le enviará un mensaje de texto al número ${mobile} `}
            </SegmentHeader>
          </div>
          <OtpValidationField
            onKeyPress={()=>{}}
            type='number'
            label="Teléfono movil(opcional):"
            name="mobile"
            value={mobile}
            onChange={()=>{}}
            onChangeCustom={this.setCode}
            sendOtp={() => this.sendOtp(canSendWhatsappOtpValidationMessage)}
            validateOtp={()=>{}}
            mobile={mobile}
            requestOtpSend={()=>{}}
            requestValidateOtpSend={()=>{}}
            otpSent={false}
            mobileValidated={false}
            resetOtpInfoResponse={()=>{}}
            isModal={false}
            termsAndConditionsUrl={this.props.termsAndConditionsUrl}
          />
          <StepperButtons onSubmit={this.onSubmit} goBack={this.props.goBack} />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  item: {
    margin: 20,
    textAlign: 'center'
  },
  headline: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    marginTop: 3 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 2
    }
  },
  liDivider: {
    listStyleType: 'none'
  },
  segmentHeaderOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5'
  },
  button: {
    margin: 2,
    padding: '8px'
  }
});

RemoteSaleEnrollmentOTP.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(RemoteSaleEnrollmentOTP);
