import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DigitalSaleDocumentRevision from './DigitalSaleDocRevision';
import { newAlert } from '../../../actions/alert_actions';
import {
  resetStepper,
  setSteps,
  setOptionalSteps,
  persistStepper,
  setCurrentStep
} from '../../../actions/stepper_actions';
import {
  persistDocumentRevision,
  toggleBaseErrorModal,
  clearDocumentRevision,
  setNextStep
} from '../../../actions/digital_sale_document_revisions_actions';
import _get from 'lodash/get';

function mapStateToProps(state) {
  return {
    routes: state.stepper.routes,
    skips: state.stepper.skippedSteps,
    process: state.stepper.process,
    revisionCompleted: state.digitalSalesDocumentRevisions.revisionCompleted,
    digitalSalesDocumentRevisionNext:
      state.stepper.digitalSalesDocumentRevisionNext,
    digitalSalesDocumentRevisionPromissoryNext:
      state.stepper.digitalSalesDocumentRevisionPromissoryNext,
    digitalSalesDocumentRevisionSaleNext:
      state.stepper.digitalSalesDocumentRevisionSaleNext,
    server_errors: state.portalFinancing.errors,
    baseErrorOpen: state.portalFinancing.baseErrorOpen,
    baseErrorText: state.portalFinancing.baseErrorText,
    clientDataValidated:
      state.digitalSalesDocumentRevisions.client_data_validated,
    documentDataValidated:
      state.digitalSalesDocumentRevisions.document_data_validated,
    promissoryDataValidated:
      state.digitalSalesDocumentRevisions.promissory_data_validated,
    saleDataValidated: state.digitalSalesDocumentRevisions.sale_data_validated,
    voucherDataValidated:
      state.digitalSalesDocumentRevisions.voucher_data_validated,
    globalParameters: {
      validateSaleInvoices: state.portalFinancing.validate_sale_invoices,
      validatePromissoryInvoices:
        state.portalFinancing.validate_promissory_invoices,
      showScoringStep: _get(
        state,
        'authentication.currentUser.supplier.hasScoringExtraQuotum'
      )
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetStepper: bindActionCreators(resetStepper, dispatch),
    setSteps: bindActionCreators(setSteps, dispatch),
    setOptionalSteps: bindActionCreators(setOptionalSteps, dispatch),
    persistDocumentRevision: bindActionCreators(
      persistDocumentRevision,
      dispatch
    ),
    clearDocumentRevision: bindActionCreators(clearDocumentRevision, dispatch),
    toggleBaseErrorModal: bindActionCreators(toggleBaseErrorModal, dispatch),
    persistStepper: bindActionCreators(persistStepper, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DigitalSaleDocumentRevision);
