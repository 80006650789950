import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';

import parseQuerystring from '../../../helpers/parseQuerystring';
import ClientFilters from './ClientFilters';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import ListsStyle from '../__styles__/Lists.style';

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class ClientList extends React.PureComponent {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE
  };

  componentDidMount() {
    this._fetchItems();
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchClients({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleRowClick = id => this.props.history.push(`/clients/${id}`);

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  render() {
    const { clients = [], meta = {}, classes, requestSend } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    // console.log('clients', clients);
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Clientes'}
          </h1>
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <ClientFilters onChangeFilter={this._fetchItems} />
          </div>
        </div>
        <div>
          <Table
            name={'Cliente'}
            columns={columns}
            data={mapData(clients)}
            onDownloadClick={this._handleDownloadCSV}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props => this._handleRowClick(props.id)}
            totalCount={clients.length}
            loading={requestSend}
            withNoCountPagination={true}
            nextPage={meta.nextPage}
          />
        </div>
      </div>
    );
  }
}

ClientList.propTypes = {
  classes: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  fetchClients: PropTypes.func.isRequired,
  meta: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(ClientList);
