import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsCircle from './NotificationsCircle';
import NotificationItem from './NotificationItem';
import NotificationModal from './NotificationModal';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import _find from 'lodash/find';

import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';

import { ACTION_TYPES, STATUS } from './NotificationItem.constant';
import MOCKED_DATA from './Notifications.metadata';

const styles = () => ({
  iconButtonRoot: {
    position: 'relative'
  },
  menulist: {
    maxHeight: 'calc(100vh - 120px)',
    overflow: 'hidden',
    overflowY: 'auto',
    padding: 0
  },
  settingsIcon: {
    color: 'rgba(12,31,44,0.54)'
  }
});

export class Notifications extends Component {
  state = {
    open: false,
    notifications: [],
    notification: {},
    openedModal: false
  };

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickAway);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickAway);
  }

  _handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  _handleClickAway = ({ target }) => {
    if (
      // eslint-disable-next-line
      ReactDOM.findDOMNode(target).getAttribute('data-action-type') ===
      ACTION_TYPES.LINK
    ) {
      return this.setState({
        open: false
      });
    }

    if (
      // eslint-disable-next-line
      (this.node && ReactDOM.findDOMNode(this.node).contains(target)) ||
      this.state.openedModal
    ) {
      return this.setState({
        open: true
      });
    }

    return this.setState({
      open: false
    });
  };

  _handleClick = id => {
    const notifications = [...this.state.notifications];
    const notification = _find(notifications, { id });

    if (notification.status === STATUS.DONE) {
      return false;
    }

    notification['status'] = STATUS.DONE;
    this.setState({
      notifications: notifications.map(notificationItem => {
        return notificationItem.id === notification.id
          ? notification
          : notificationItem;
      })
    });

    switch (notification.metadata.type) {
      case ACTION_TYPES.LINK:
        return this.props.history.push(notification.metadata.url);
      default:
        return this.setState({ openedModal: true, notification });
    }
  };

  _handleCloseModal = () => this.setState({ openedModal: false });

  render() {
    const {
      classes,
      list,
      markAsSeen,
      freelanceRoles,
      asesorRoles,
      currentUser
    } = this.props;
    const hasUnreadNotifications = list.filter(item => !item.seen);
    return (
      <Fragment>
        <IconButton
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-label="Visibility"
          classes={{ root: classes.iconButtonRoot }}
          onClick={this._handleToggle}
          title={'Notificaciones'}
        >
          <NotificationsIcon style={{ color: '#FFF' }} />
          {!!hasUnreadNotifications.length && <NotificationsCircle />}
        </IconButton>
        <Popper
          open={this.state.open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          modifiers={{
            offset: {
              enabled: true,
              offset: '10,4.5'
            }
          }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: 'center top' }}
            >
              <Paper
                style={{ borderRadius: 0, width: 256 }}
                ref={node => (this.node = node)}
              >
                <Fragment>
                  <MenuItem
                    style={{ backgroundColor: 'transparent', height: 35 }}
                  >
                    <p className={classes.headline7}>{'Notificaciones'}</p>
                  </MenuItem>
                  <Divider />
                  <MenuList className={classes.menulist}>
                    {list.map(item => (
                      <NotificationItem
                        {...item}
                        {...item.notification}
                        onClick={this._handleClick}
                        key={item.id}
                        markAsSeen={markAsSeen}
                        freelanceRoles={freelanceRoles}
                        asesorRoles={asesorRoles}
                        currentUser={currentUser}
                      />
                    ))}
                  </MenuList>
                </Fragment>
              </Paper>
            </Grow>
          )}
        </Popper>
        <NotificationModal
          open={this.state.openedModal}
          onClose={this._handleCloseModal}
          notification={this.state.notification}
        />
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(combineStyles(TypographyStyles, styles))(Notifications)
);
