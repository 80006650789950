const styles = () => ({
  root: {
    borderRadius: 2,
    backgroundColor: 'rgba(12,31,44,0.08)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    position: 'relative',
  },
  icon: {
    fontSize: 180,
    color: 'rgba(12,31,44,0.38)'
  }
});

export default styles;
