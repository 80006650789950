import {
  LIST_COMMISSIONS,
  SAVED_COMMISSION,
  DATA_TO_CREATE_COMMISSIONS
} from '../constants/action_constants';

const initialState = {
  commissions: [],
  meta: {},
  fetchData: {},
  saved: false
};

export function commissions(state = initialState, action) {
  switch (action.type) {
    case LIST_COMMISSIONS:
      return {
        ...state,
        commissions: action.data,
        meta: action.meta,
        saved: false
      };
    case DATA_TO_CREATE_COMMISSIONS:
      return {
        ...state,
        fetchData: action.data
      };
    case SAVED_COMMISSION:
      return {
        ...state,
        saved: true
      };
    default:
      return state;
  }
}
