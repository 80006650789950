import CategoryList from './CategoryList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  exportToXlsx,
  fetchCategories,
  createCategory,
  updateCategory
} from '../../../actions/category_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';

function mapStateToProps(state) {
  return {
    canUpdateCategories: state.authentication.currentUser.canUpdateCategories,
    canCreateCategories: state.authentication.currentUser.canCreateCategories,
    canUpdateCategoriesNameInSupplierProposalModuleField:
      state.authentication.currentUser
        .canUpdateCategoriesNameInSupplierProposalModuleField,
    canSetMaxItemsPerCategory:
      state.authentication.currentUser.canSetMaxItemsPerCategory,
    canExportCategories: state.authentication.currentUser.canExportCategories,
    categories: state.category.categories,
    categorySaved: state.category.saved,
    meta: state.category.meta,
    allCategories: state.globalSetting.orgData.categories,
    requestFetchCategoriesSend: state.requestState.requestFetchCategoriesSend,
    requestErrorsOnSaveCategory: state.requestState.requestErrorsOnSaveCategory,
    requestSaveCategorySend: state.requestState.requestSaveCategorySend,
    requestExportToXlsxSend: state.requestState.requestExportToXlsxSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    fetchCategories: bindActionCreators(fetchCategories, dispatch),
    createCategory: bindActionCreators(createCategory, dispatch),
    updateCategory: bindActionCreators(updateCategory, dispatch),
    exportToXlsx: bindActionCreators(exportToXlsx, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CategoryList);
