/* eslint-disable no-dupe-keys */
import React from 'react';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';

import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import _replace from 'lodash/replace';
import ProfileStyles from '../../pages/UserDetail/__styles__/Profile.styles';

import ActivityIndicator from '../../shared/ActivityIndicator';
import TypographyStyles from '../../../styles/typography';

const styles = theme => ({
  rootBase: {
    maxWidth: '100%',
    height: '110px',
    display: 'flex',
    fontFamily: 'Roboto !important',
    fontWeight: 'bold',
    ['@media (max-width: 425px)']: {
      minWidth: '100%'
    }
  },
  avatar: {
    marginRight: '0px !important',
    width: '60px',
    height: '60px',
    ['@media (max-width: 811px)']: {
      width: '70px',
      height: '70px'
    },
    ['@media (max-width: 375px)']: {
      width: '50px',
      height: '50px'
    }
  },
  rank: {
    fontSize: '20x',
    color: '#FEAD00',
    margin: '0px',
    textAlign: 'left !important',
    ['@media (max-width: 1440px)']: {
      fontSize: '22px'
    }
  },
  name: {
    textTransform: 'uppercase',
    fontSize: '16px',
    marginTop: '0px',
    marginBottom: '0px',
    fontWeight: 'normal',
    color: '#00000099',
    ['@media (max-width: 1440px)']: {
      fontSize: '14px'
    },
    ['@media (max-width: 768px)']: {
      fontSize: '16px !important'
    },
    ['@media (max-width: 375px)']: {
      fontSize: '14px !important'
    }
  },
  count: {
    display: 'inline'
  },
  info: {
    paddingLeft: '0px',
    paddingTop: '10px'
  },
  loadingIndicator: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 1
  }
});

export const RankingTop = ({
  classes,
  totalCount,
  rank,
  avatar,
  nameSeller,
  isLoading
}) => (
  <Card className={classes.rootBase}>
    {isLoading && <ActivityIndicator className={classes.loadingIndicator} />}

    <CardHeader
      className={classes.card}
      avatar={
        avatar ? (
          <Avatar className={classes.avatar} src={avatar} />
        ) : (
          <Avatar aria-label="recipe" className={classes.avatar}>
            <p>
              {nameSeller ? nameSeller[0] + nameSeller.split(' ')[1][0] : 'NN'}
            </p>
          </Avatar>
        )
      }
    />
    <CardContent className={classes.info}>
      <div>
        <p className={classes.rank} style={{ color: rank.color }}>
          {rank.value}
        </p>
        <p className={classes.name}>{nameSeller}</p>
        <p className={classes.count} style={{ color: totalCount.color }}>
          {totalCount.value}
        </p>
      </div>
    </CardContent>
  </Card>
);

RankingTop.propTypes = {
  rank: PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.number
  }),
  seller: PropTypes.string,
  totalCount: PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles)
)(RankingTop);
