import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';
import { compose } from 'redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TypographyStyles from '../../../styles/typography';
import classnames from 'classnames';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';

class AnnouncementListItem extends PureComponent {
  render() {
    const {
      classes,
      heading,
      children,
      button = true,
      onClick,
      onMarkAsSeenHandler,
      seen = false
    } = this.props;

    return (
      <ListItem
        button={button}
        onClick={onClick}
        alignItems="flex-start"
        classes={{ root: classes.listItem }}
      >
        <ListItemText
          classes={
            seen
              ? {
                  primary: classnames(
                    classes.itemSeen,
                    classes.subtitle3,
                    classes.primary
                  ),
                  secondary: classnames(classes.itemSeen, classes.caption)
                }
              : {
                  primary: classnames(
                    classes.itemNotSeen,
                    classes.subtitle3,
                    classes.primary
                  ),
                  secondary: classnames(classes.itemNotSeen, classes.caption)
                }
          }
          primary={heading}
          secondary={<React.Fragment>{children}</React.Fragment>}
          secondaryTypographyProps={{
            component: 'div'
          }}
        />
        <Tooltip
          title={seen ? 'Marcar como no leído' : 'Marcar como leído'}
          aria-label="markAsSeen"
        >
          <IconButton
            disableRipple={true}
            onClick={onMarkAsSeenHandler}
            style={{ backgroundColor: 'transparent' }}
          >
            {seen ? (
              <VisibilityIcon
                color="disabled"
                style={{ fontSize: 20 }}
                className={classes.iconMarkAsNotSeen}
              />
            ) : (
              <VisibilityIcon color="secondary" style={{ fontSize: 20 }} />
            )}
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
}

const styles = theme => ({
  listItem: {
    padding: '10px 24px !important',
    '&:hover svg': {
      color: `${theme.palette.secondary.main}`
    }
  },
  iconMarkAsNotSeen: {
    color: 'transparent'
  },
  itemNotSeen: {
    opacity: 1
  },
  itemSeen: {
    opacity: 0.4
  },
  primary: {
    margin: '0 !important'
  }
});

export default compose(
  withStyles(combineStyles(styles, TypographyStyles), { withTheme: true })
)(AnnouncementListItem);
