import _get from 'lodash/get';

export const columns = [
  {
    title: 'Contrato',
    name: 'contract'
  },
  {
    title: 'Dirección',
    name: 'address'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      contract: datum.contract_id,
      address: _get(datum, 'debtor_details.address.body', '--')
    };
  });
