import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import parseQuerystring from '../../../helpers/parseQuerystring';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import TextFieldInput from '../../shared/Inputs/TextField';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';

import { filterListFuzzyly } from '../../../helpers/utilityFunctions';

import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';
import { hasBiometricReaderOptions } from './dropDownOptions';

class UserFilters extends PureComponent {
  state = {
    name: '',
    username: '',
    email: '',
    identification: '',
    supplier_name: '',
    supplier_id: '',
    hasBiometricReader: 'todos',
    selectedSupplier: false,
    serial: '',
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    this.setState({
      ...this.state,
      ...query,
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true,
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }, others) =>
    this.setState({ [name]: value, ...others }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, (filter) => !!filter),
      'name',
      'username',
      'email',
      'identification',
      'supplier_id',
      'hasBiometricReader',
      'serial'
    );
    return filterParams;
  };

  _getSuggestions = async (value) => {
    const suppliers = this.props.suppliers.map((supplier) => ({
      label: supplier.name,
      value: supplier.id,
    }));

    const suggestions = filterListFuzzyly(value, suppliers, 'label');

    return Promise.resolve(suggestions);
  };

  render() {
    const { classes, suppliers, isBlackList } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={this._handleChange}
                  label="Nombre"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <TextFieldInput
                  id="username"
                  name="username"
                  value={this.state.username}
                  onChange={this._handleChange}
                  label="Usuario"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <TextFieldInput
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={this._handleChange}
                  label="Correo Eléctronico"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type="email"
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <TextFieldInput
                  id="identification"
                  name="identification"
                  value={this.state.identification}
                  onChange={this._handleChange}
                  label="# Identificación"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type="number"
                />
              </Grid>
              {!isBlackList && (
                <Grid item xs={4} sm={2}>
                  <AutocompleteInput
                    id="supplier"
                    name="supplier"
                    label="Aliado"
                    margin="normal"
                    variant="outlined"
                    error={''}
                    helperText={''}
                    value={this.state.supplier_name || ''}
                    onChange={(change) => {
                      if (this.state.supplier_name && !change) {
                        this._handleSelectChange(
                          { target: { name: 'supplier_name', value: '' } },
                          { supplier_id: change }
                        );
                      }
                      this.setState({
                        supplier_name: change,
                        selectedSupplier: false,
                      });
                    }}
                    suggestions={suppliers}
                    getSuggestions={this._getSuggestions}
                    onSuggestionSelected={(supplier) => {
                      this._handleSelectChange(
                        {
                          target: {
                            name: 'supplier_name',
                            value: supplier.label,
                          },
                        },
                        { supplier_id: supplier.value }
                      );
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={2}>
                <OutlinedSelectChippedInput
                  label={'Lector biométrico'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'hasBiometricReader'}
                  options={hasBiometricReaderOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'hasBiometricReader'}
                  value={this.state.hasBiometricReader}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={4} sm={1}>
                <TextFieldInput
                  id="serial"
                  name="serial"
                  value={this.state.serial}
                  onChange={this._handleChange}
                  label="Serial"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = (theme) => ({});

UserFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(UserFilters);
