import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  contract: Yup.number()
    .required('El campo es requerido')
    .min(1, 'Ingresa un contrato válido.'),
  debtorDoctype: Yup.string().required('El campo es requerido'),
  debtorDocid: Yup.string()
    .when('debtorDoctype', {
      is: 'Cédula',
      then: Yup.string()
        .matches(
          /^[0-9]+$/,
          'Tipo de documento no admite caracteres especiales o letras.'
        )
        .required('El campo es requerido')
    })
    .when('debtorDoctype', {
      is: 'Pasaporte',
      then: Yup.string()
        .matches(
          /^[A-Z-a-z-0-9-]+$/,
          'Tipo de documento no admite caracteres especiales.'
        )
        .required('El campo es requerido')
    })
    .when('debtorDoctype', {
      is: 'Cédula de Extranjería',
      then: Yup.string()
        .matches(
          /^[A-Z-a-z-0-9-]+$/,
          'Tipo de documento no admite caracteres especiales.'
        )
        .required('El campo es requerido')
    })
    .when('debtorDoctype', {
      is: 'Nit / Rut',
      then: Yup.string()
        .matches(
          /^[0-9-]+$/,
          'Tipo de documento solo admite números y el caracter - '
        )
        .required('El campo es requerido')
    }),
  birthday: Yup.string()
    .required('El campo es requerido')
    .nullable()
});

export const validationSchemaOTP = Yup.object().shape({
  debtorCode: Yup.number().required('El campo es requerido')
});
