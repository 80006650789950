import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
//import Icon from './Icon';

export function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      type="button"
      onClick={onClick}
      className={`button button--text button--icon ${className}`}
    >
      <ArrowForwardIosIcon />
    </div>
  );
}

export function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      type="button"
      onClick={onClick}
      className={`button button--text button--icon ${className}`}
    >
      <ArrowBackIosIcon />
    </div>
  );
}
