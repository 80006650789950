import SchedulePointOfSaleConfigurationList from './SchedulePointOfSaleConfigurationList';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  fetchSchedulePointOfSaleConfigurationList,
  createSchedulePointOfSaleConfiguration,
  openSchedulePointOfSaleConfigurationFormModal,
  closeSchedulePointOfSaleConfigurationFormModal,
  updateSchedulePointOfSaleConfiguration,
  fetchCategories
} from '../../../actions/schedule_point_of_sale_configuration_actions';
import { fetchPointsOfSaleNames } from '../../../actions/points_of_sale_actions';

function mapStateToProps(state) {
  return {
    configs: state.schedulePointOfSaleConfigurations.configs,
    meta: state.schedulePointOfSaleConfigurations.meta,
    loading: state.requestState.requestSchedulePointOfSaleConfigurationListSend,
    canCreateSchedulePointOfSaleConfigurations: state.authentication.currentUser.canCreateSchedulePointOfSaleConfigurations,
    canUpdateSchedulePointOfSaleConfigurations: state.authentication.currentUser.canUpdateSchedulePointOfSaleConfigurations,
    categories: state.schedulePointOfSaleConfigurations.categories,
    loadingCategories: state.requestState.requestSchedulePointOfSaleConfigurationFormCategoriesListSend,
    geographicLocations: state.globalSetting.orgData.geographic_locations,
    pointOfSales: state.pointOfSale.pointsOfSaleNames,
    loadingPointOfSales: state.requestState.requestPointOfSalesSend,
    modalSubmitLoading: (
      state.requestState.requestSchedulePointOfSaleConfigurationCreateSend ||
      state.requestState.requestSchedulePointOfSaleConfigurationUpdateSend
    ),
    formModalIsOpen: state.schedulePointOfSaleConfigurations.formModalIsOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSchedulePointOfSaleConfigurationList: bindActionCreators(fetchSchedulePointOfSaleConfigurationList, dispatch),
    fetchPointsOfSales: bindActionCreators(fetchPointsOfSaleNames, dispatch),
    createConfig: bindActionCreators(createSchedulePointOfSaleConfiguration, dispatch),
    openFormModal: bindActionCreators(openSchedulePointOfSaleConfigurationFormModal, dispatch),
    closeFormModal: bindActionCreators(closeSchedulePointOfSaleConfigurationFormModal, dispatch),
    updateConfig: bindActionCreators(updateSchedulePointOfSaleConfiguration, dispatch),
    fetchCategories: bindActionCreators(fetchCategories, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SchedulePointOfSaleConfigurationList);