import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class LabeledCheckbox extends React.Component {
  render() {
    const {
      classes,
      handleChange,
      label,
      selectedIDS,
      selectedItem,
      checked,
      allSelector,
      isHeader
    } = this.props;
    const check = allSelector ? checked : selectedIDS.includes(selectedItem.id);
    return (
      <FormControlLabel
        classes={{
          root: check
            ? allSelector
              ? classNames(
                  classes.formControlLabel,
                  classes.checkedLabel,
                  classes.border,
                  classes.header
                )
              : isHeader
                ? classNames(classes.formControlLabel, classes.header)
                : classNames(classes.formControlLabel, classes.checkedLabel)
            : allSelector
            ? classNames(classes.formControlLabel, classes.border, classes.header)
            : isHeader
              ? classNames(classes.formControlLabel, classes.header)
              : classes.formControlLabel,  
          label: allSelector
            ? classes.header_label
            : isHeader
              ? classes.header_label
              : classes.formControlLabel_label
        }}
        control={
          <Checkbox checked={check} onChange={handleChange} color={'primary'} />
        }
        label={label}
      />
    );
  }
}

const styles = () => ({
  formControlLabel: {
    height: '40px',
    width: '100%',
    margin: 0,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#f3f3f3'
    }
  },
  border: {
    transition: 0,
    borderBottom: '1px solid #dddddd'
  },
  checkedLabel: {
    backgroundColor: '#f3f3f3'
  },
  header: {
    backgroundColor: '#f3f3f3',
  },
  header_label:{
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  formControlLabel_label: {
    fontSize: '0.9rem',
    color: '#666666'
  }
});

LabeledCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(LabeledCheckbox);
