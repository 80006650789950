import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import _remove from 'lodash/remove';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import FormikPropsValidations from './FormikPropsValidations';
import FieldValidationPopup from '../Poppers/FieldValidation';
import ChipFieldStyles from './__styles__/ChipField.styles';
const CHIP_ICON_WIDTH = 15;
const EllipsisText = props => {
  const { children, chipNode } = props;
  //Less padding

  let maxWidth;
  if (chipNode) {
    let chipNodeWidthLessPadding = chipNode.clientWidth - 35 - 12;
    maxWidth = chipNodeWidthLessPadding - CHIP_ICON_WIDTH;
  } else {
    maxWidth = 'auto';
  }

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: maxWidth
      }}
    >
      {children}
    </div>
  );
};
export class ChipFieldWrapper extends Component {
  static propTypes = {
    name: props => FormikPropsValidations('name', props),
    label: PropTypes.string,
    value: PropTypes.string,
    values: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onTextChange: PropTypes.func.isRequired,
    chipOnBlur: PropTypes.func.isRequired
  };

  state = {
    value: this.props.value || [],
    anchorEl: null,
    fieldError: '',
    fieldName: this.props.name || _get(this.props, 'field.name')
  };

  componentDidMount() {
    this._setFieldError();
  }

  componentDidUpdate(prevProps) {
    const { validateField } = this.props;
    const { fieldName } = this.state;
    if (_get(prevProps, 'form.errors') !== _get(this.props, 'form.errors')) {
      this._setFieldError();
    }

    if (prevProps.values !== this.props.values) {
      if (_isFunction(validateField)) {
        validateField(fieldName, this.props.values);
      }
      this.setState({ values: this.props.values || '' });
    }
  }

  _setFieldError = () => {
    const { fieldName } = this.state;
    const { form = {} } = this.props;
    const { errors } = form;
    const error = _get(errors, fieldName, '');
    this.setState({ fieldError: error });
  };

  _handleShowError = event => {
    const { fieldError } = this.state;
    if (fieldError) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };

  _handleHideError = () => {
    this.setState({ anchorEl: null });
  };

  _handleAdd = chip => {
    const { onChange, values } = this.props;
    values.push(chip);
    onChange({ target: { name: this.state.fieldName, value: values } });
  };

  _handleDelete = chip => {
    const { onChange, value } = this.props;
    const values = [...value];
    _remove(values, value => value === chip);
    onChange({ target: { name: this.state.fieldName, value: values } });
  };

  render() {
    const {
      classes,
      label,
      values = [],
      onTextChange,
      onDelete,
      chipOnBlur,
      readOnly,
      helperText
    } = this.props;

    return (
      <Fragment>
        <ChipInput
          fullWidth
          fullWidthInput
          disabled={readOnly}
          onDelete={onDelete}
          label={label}
          inputRef={node => {
            this.chipNode = node;
          }}
          value={values}
          variant={'outlined'}
          onMouseEnter={this._handleShowError}
          onFocus={this._handleShowError}
          onMouseOut={this._handleHideError}
          onBlur={chipOnBlur}
          onUpdateInput={change => {
            onTextChange(change.target.value);
            //          this.handleSuggestionsFetchRequested(change.target);
          }}
          error={!!this.state.fieldError}
          chipRenderer={({ text, handleDelete }, key) => {
            return (
              <Chip
                key={key}
                label={
                  <EllipsisText chipNode={this.chipNode}>{text}</EllipsisText>
                }
                className={classes.chip}
                classes={{ label: classes.chipLabel }}
                onDelete={readOnly ? null : handleDelete}
                deleteIcon={
                  readOnly ? null : <CloseIcon className={classes.chipIcon} />
                }
              />
            );
          }}
          classes={{
            inputRoot: classes.chipInputRoot,
            input: classes.chipInput,
            label: classes.chipInputLabel,
            focused: classes.focused
          }}
          InputLabelProps={{
            classes: {
              focused: classes.labelFocused
            }
          }}
        />
        {helperText == null && (
          <FieldValidationPopup
            anchorEl={this.state.anchorEl}
            message={this.state.fieldError}
          />
        )}
      </Fragment>
    );
  }
}

export default withStyles(ChipFieldStyles)(ChipFieldWrapper);
