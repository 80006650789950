const styles = theme => ({
  headerRoot: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: '#FFFFFF',
    width: '100%'
  },
  details: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing.unit * 3
  },
  contactData: {
    wordBreak: 'break-all'
  }
});

export default styles;
