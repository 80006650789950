import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = theme => ({
  baseRoot: {
    fontSize: 22,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    marginLeft: theme.spacing.unit * 0.5
  },
  root: {}
})

export const Percentage = ({classes}) => {
  return (
    <span className={classnames(classes.baseRoot, classes.root)}>%</span>
  )
}

export default withStyles(styles)(Percentage);
