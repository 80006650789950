import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required('Nombre es requerido'),
  value:[
    Yup.number()
      .min(0.01, 'El valor minimo a ingresar es 0')
      .required('Valor es requerido'),
    Yup.string()
      .required('Se necesita un valor')
  ]
});

export default validationSchema;
