import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import FormItemContainer from "../../shared/Containers/FormItemContainer";

import LoadingIndicator from '../../shared/LoadingIndicator';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';

const styles = theme => ({
  tag: {
    margin: 0,
    display: 'inline-block',
    padding: [[theme.spacing.unit * 1, theme.spacing.unit * 1.5]],
    borderRadius: '18px',
    color: '#FFFFFF',
    textAlign: 'left',
    fontSize: '16px',
    fontFamily: 'Roboto',
    letterSpacing: '0.25px',
    opacity: 1
  },

  blueLagoonColor: {
    backgroundColor: '#00838F',
    marginRight: '0.25rem'
  },

  primaryColor: {
    backgroundColor: '#DB4945',
    marginLeft: '0.25rem'
  },

  pandotyButton: {
    padding: '8px 16px 8px 16px',
    border: '2px solid #F2A138',
    borderRadius: '5px'
  },

  contentDisposition: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  grayColor: {
    color: '#747B87',
  },

  headerLinks: {
    fontSize: '12px',
    fontFamily: 'Roboto'
  },

  secondaryColor: {
    color: '#F2A138'
  },

  progressBar: {
    height: '0.25rem',
    appearance: 'none',
    '&::-webkit-progress-value': {
      backgroundColor: '#00838F',
      borderRadius: '20px'
    },
    '&::-webkit-progress-bar': {
      borderRadius: '20px',
      backgroundColor: '#00838F3D'
    },
    marginBottom: '5px'
  },

  progressInfoDisposition: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  porcentageStyle: {
    alignSelf: 'end',
    fontSize: '16px',
    fontFamily: 'Roboto',
    color: '#000000DE',
    opacity: 1
  },

  textTitleCourse: {
    fontSize: '34px',
    fontFamily: 'Rubik',
    color: '#000000DE'
  },

  errorMessage: {
    minHeight: "180px",
    backgroundColor: "#FFFFFF99",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "x-large",
    borderRadius: "5px",
    marginTop: "10px"
  },

  courseDetail: {
    boxShadow:
    '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    marginBottom: '27px'
  },

  titleContent: {
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px'
  },

  nameTestStyle: {
    alignSelf: 'center',
    fontSize: '20px',
    fontWeight: 'bold'
  },

  dFlex: {
    display: 'flex'
  },

  attemptsContentStyle: {
    marginRight: '0.25rem',
    display: 'flex',
    flexDirection: 'column'
  },

  qualifyTag: {
    fontSize: '14px',
    height: 'min-content',
    alignSelf: 'center',
    marginLeft: '0.25rem'
  }, 

  attempts: {
    fontSize: '12px',
    color: '#00000061',
    paddingBottom: '0.25rem',
    fontWeight: 'bold'
  },

  numberAttempts: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingTop: '0.25rem'
  },

  approvalDetail: {
    backgroundColor: '#FFF',
    display: 'flex',
    padding: '24px'
  },

  approvalPercentageContent: {
    marginRight: '1rem'
  },

  minimumApprovalPercentageContent: {
    marginLeft: '1rem'
  },

  percentageTextStyle: {
    fontSize: "16px",
    fontFamily: "'Roboto'",
    color: "#00000099",
    letterSpacing: "0.12px",
  },

  headerNavBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }, 

  maringInX: {
    marginRight: '0.25rem',
    marginLeft: '0.25rem'
  }
});

class PandotyCourseDetail extends React.Component {
  state = {
    isLoading: true,
    currentCourse: []
  };

  _goToBrillaELearning = () => {
    window.open('https://brilla.pandoty.com/', '_blank');
  };

  componentDidMount = async () => {
    const currentUser = this.props.currentUser
    var currentCourseInfo = []
    if (currentUser && currentUser.pandotyCourses && currentUser.pandotyCourses.courses) {
      currentCourseInfo = await currentUser.pandotyCourses.courses.find(course => course.id == this.props.match.params.id);
    }
    await this.props.fetchCoursesDetails(this.props.match.params.id);
    return this.setState({ isLoading: false, currentCourse: currentCourseInfo });
  };

  render() {
    const {
      classes,
      courseDetail
    } = this.props;
    return (
      <div>
        <div className={classes.contentDisposition}>
          <div className={classes.headerNavBar}>
            <a className={classnames(classes.headerLinks, classes.grayColor)} href='/my-profile'>Mi perfil</a>
            <span className={classnames(classes.headerLinks, classes.grayColor, classes.maringInX)}>{' > '}</span> 
            <a href='#' className={classnames(classes.headerLinks, classes.grayColor)}>Cursos</a> 
            {!this.state.isLoading && courseDetail && this.state.currentCourse && (
              <div className={classes.headerNavBar}>
                <span className={classnames(classes.headerLinks, classes.grayColor, classes.maringInX)}>{' > '}</span>
                <span className={classnames(classes.headerLinks, classes.secondaryColor)}>{this.state.currentCourse.attributes.name}</span>
              </div>
            )}
          </div>
          <Button
            size={'small'}
            color={'secondary'}
            className={classnames(classes.smallButton, classes.actionButtons, classes.pandotyButton)}
            classes={{
              label: classes.buttonLabel
            }}
            onClick={() => this._goToBrillaELearning()}
          >
            {'IR AL PORTAL DE CURSOS'}
          </Button>
        </div>
        {this.state.isLoading || !courseDetail ? <LoadingIndicator /> : (
          courseDetail.error ? (
            <div className={classes.errorMessage}>
              {courseDetail.message}
            </div>
          ) : (
            <div>
              <div className={classes.contentDisposition}>
                <h2 className={classes.textTitleCourse}>{this.state.currentCourse.attributes.name}</h2>
                <div className={classes.progressInfoDisposition}>
                  <progress className={classes.progressBar} value={this.state.currentCourse.progress_user.slice(0, -1)} max="100"></progress>
                  <span className={classes.porcentageStyle}>{this.state.currentCourse.progress_user}</span>
                </div>
              </div>
              {courseDetail.message ? (
                <div className={classes.errorMessage}>
                  {courseDetail.message}
                </div>
              ) : (
                <FormItemContainer
                  title={`Tests (${_numberOfTest(courseDetail)})`}
                  generalCustomContents={true}
                  customContents={classes.contents}
                  secondaryAction={
                    <div>
                      <span className={classnames(classes.tag, classes.blueLagoonColor)}>{`Test aprobados (${_countApprovedTest(courseDetail)})`}</span>
                      <span className={classnames(classes.tag, classes.primaryColor)}>{`Tests reprobados (${_countDisapprovedTest(courseDetail)})`}</span>
                    </div>
                  }
                >
                  <div>
                  {courseDetail.map(course =>
                    course.attempts.map(attempt =>
                      <div className={classes.courseDetail}>
                        <div className={classes.titleContent}>
                          <span className={classes.nameTestStyle}>{course.name}</span>
                          <div className={classes.dFlex}>
                            <div className={classes.attemptsContentStyle}>
                              <span className={classes.attempts}>Intentos</span>
                              <span className={classes.numberAttempts}>{attempt.attemptNumber} de {course.attempts.length}</span>
                            </div>
                            {attempt.approved ? (
                              <span className={classnames(classes.tag, classes.blueLagoonColor, classes.qualifyTag)}>Aprobado</span>
                            ) : (
                              <span className={classnames(classes.tag, classes.primaryColor, classes.qualifyTag)}>Reprobado</span>
                            )}
                          </div>
                        </div>
                        <div className={classes.approvalDetail}>
                          <div className={classnames(classes.progressInfoDisposition, classes.approvalPercentageContent)}>
                            <progress className={classes.progressBar} value={attempt.approvalPercentage.slice(0, -1)} max="100"></progress>
                            <span className={classes.percentageTextStyle}>Porcentaje de aprobacion: {attempt.approvalPercentage}</span>
                          </div>
                          <div className={classnames(classes.progressInfoDisposition, classes.minimumApprovalPercentageContent)}>
                            <progress className={classes.progressBar} value={course.minimumApprovalPercentage.slice(0, -1)} max="100"></progress>
                            <span className={classes.percentageTextStyle}>Porcentaje minimo requerido: {course.minimumApprovalPercentage}</span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  </div>
                </FormItemContainer>
              )}
            </div>
          )
        )}
      </div>
    )
  }
}

const _countApprovedTest = (coursesDetail) => {
  let approved = 0
  coursesDetail.map(courseDetail => {
    const testApproved = courseDetail.attempts.filter(attempt => attempt.approved == true)
    approved += testApproved.length
  })
    return approved
}

const _countDisapprovedTest = (coursesDetail) => {
  let disapproved = 0
  coursesDetail.map(courseDetail => {
    const testdisapproved = courseDetail.attempts.filter(attempt => attempt.approved == false)
    disapproved += testdisapproved.length
  })
    return disapproved
}

const _numberOfTest = (coursesDetail) => {
  let tests = 0
  coursesDetail.map(courseDetail => {
    tests += courseDetail.attempts.length
  })
  return tests
}

export default withStyles(combineStyles(ButtonStyles, TypographyStyles, styles))(PandotyCourseDetail)