import {
  LIST_POINTS_OF_SALE,
  LIST_POINTS_OF_SALE_NAMES,
  FETCH_POINT_OF_SALE,
  FETCH_SUPPLIER_POINTS_OF_SALE,
  SET_SALE_CHANNELS,
  SELECTED_SELLERS_SET,
  LIST_POINTS_OF_SALE_NAMES_ASSOCIATIONS,
  LIST_POINTS_OF_SALE_HOME_VISITS,
  SET_POINT_OF_SALE_SALE_CHANNELS
} from '../constants/action_constants';

const initialState = {
  pointsOfSale: [],
  pointsOfSaleNames: [],
  selectedSellers: [],
  meta: {},
  supplierPointsOfSale: [],
  saleChannels: [],
  pointOfSaleSaleChannels: []
};

export function pointOfSale(state = initialState, action) {
  switch (action.type) {
    case FETCH_POINT_OF_SALE: {
      return {
        ...state,
        pointOfSaleInfo: action.data
      };
    }
    case LIST_POINTS_OF_SALE: {
      return {
        ...state,
        pointsOfSale: action.data,
        meta: action.meta
      };
    }
    case LIST_POINTS_OF_SALE_NAMES: {
      return {
        ...state,
        pointsOfSaleNames: action.data
      };
    }
    case LIST_POINTS_OF_SALE_HOME_VISITS: {
      return {
        ...state,
        pointsOfSaleNames: action.data
      };
    }
    case LIST_POINTS_OF_SALE_NAMES_ASSOCIATIONS: {
      return {
        ...state,
        pointsOfSaleNamesAssociation: action.data
      };
    }
    case SET_SALE_CHANNELS: {
      return {
        ...state,
        saleChannels: action.data
      };
    }
    case SET_POINT_OF_SALE_SALE_CHANNELS: {
      return {
        ...state,
        pointOfSaleSaleChannels: action.data
      }
    }
    case SELECTED_SELLERS_SET: {
      return {
        ...state,
        selectedSellers: action.data
      };
    }
    case FETCH_SUPPLIER_POINTS_OF_SALE: {
      return {
        ...state,
        supplierPointsOfSale: action.data
      };
    }
    default: {
      return state;
    }
  }
}
