import { Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import OtpInput from 'react-otp-input';
import CustomDialog from '../../shared/Modals/CustomDialog';
import * as Yup from 'yup';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';

const validationSchema = Yup.object().shape({
  otp: Yup.string().test(
    'len',
    'Debe tener exactamente 6 caracteres',
    val => val && val.toString().length === 6
  )
});

class OTPValidationProcess extends PureComponent {
  state = {
    timerMinutes: '0',
    timerSeconds: '0',
    interval: null
  };

  _handleChange = (otp, handleChange) => {
    const evt = { target: { value: otp, name: 'otp' } };
    handleChange(evt);
  };

  getRemainingTime = () => {
    const timeLimit = Date.parse(this.props.identityOTP.timestampOTP);
    const remainingTime = timeLimit - Date.now();
    const minutes = (remainingTime / 1000 / 60) % 60;
    const seconds = (remainingTime / 1000) % 60;

    this.setState(prev => ({
      ...prev,
      timerMinutes: minutes >= 1 ? `0${Math.floor(minutes)}` : '00',
      timerSeconds:
        seconds >= 0 ? `${seconds < 10 ? '0' : ''}${Math.floor(seconds)}` : '00'
    }));
  };

  _handleValidateOtp = otp => {
    const identityOTP = this.props.identityOTP;
    this.props.verifyIdentityOtp(
      {
        regValidation: identityOTP.regValidation,
        identification: this.props.identification,
        identificationType: this.props.identificationType,
        otpCode: otp,
        otpTransactionId: identityOTP.transactionId,
        contractId: this.props.contractId,
        mobile: this.props.mobile,
        email: this.props.email,
        cosigner: this.props.cosigner,
        currentClient: this.props.currentClient
      },
      this.props.route,
      this.props.process
    );
  };

  componentDidMount() {
    this.setState(prev => ({
      ...prev,
      interval: setInterval(() => this.getRemainingTime(), 1000)
    }));
  }

  componentDidUpdate() {
    if (this.state.timerMinutes < 0 && this.state.timerSeconds < 0) {
      clearInterval(this.state.interval);
    }
  }

  componentWillUnmount() {
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }

  render() {
    const {
      open,
      identityOTP,
      email,
      mobile,
      classes,
      handleCloseOtpModal
    } = this.props;
    const emailText = identityOTP.emailSent ? `correo (${email})` : '';
    const mobileText = identityOTP.mobileSent ? `celular (${mobile})` : '';

    return (
      <CustomDialog
        open={open}
        title={`Validación OTP`}
        contentText={`Ingrese el código enviado a su ${mobileText} ${
          mobileText.length !== 0 && emailText.length !== 0 ? 'o ' : ''
        }${emailText}.`}
        onClose={handleCloseOtpModal}
        disableBackdropClick={false}
      >
        <Formik
          initialValues={{ otp: '' }}
          onSubmit={async values => this._handleValidateOtp(values.otp)}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          render={({ values, handleChange, handleSubmit }) => {
            return (
              <div className={classes.otpbody}>
                <b>{`Tiempo restante ${this.state.timerMinutes}:${
                  this.state.timerSeconds
                }`}</b>
                <Field
                  name="otp"
                  render={({ field, form }) => {
                    return (
                      <OtpInput
                        numInputs={6}
                        isInputNum={true}
                        onChange={otp => this._handleChange(otp, handleChange)}
                        value={values.otp}
                        field={field}
                        form={form}
                        shouldAutoFocus={true}
                        inputStyle={classNames(classes.otpInputStyle)}
                      />
                    );
                  }}
                />
                <PrimaryButton
                  onClick={handleSubmit}
                  className={classes.button}
                  disabled={values.otp.length !== 6}
                >
                  Enviar
                </PrimaryButton>
              </div>
            );
          }}
        />
      </CustomDialog>
    );
  }
}

OTPValidationProcess.propTypes = {
  open: PropTypes.bool.isRequired,
  identityOTP: PropTypes.object.isRequired,
  email: PropTypes.string,
  mobile: PropTypes.string,
  identification: PropTypes.string.isRequired,
  identificationType: PropTypes.string.isRequired,
  contractId: PropTypes.number.isRequired,
  verifyIdentityOtp: PropTypes.func.isRequired,
  handleCloseOtpModal: PropTypes.func.isRequired,
  currentClient: PropTypes.string.isRequired,
  cosigner: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  process: PropTypes.string.isRequired
};

const styles = theme => ({
  otpInputStyle: {
    width: '3rem !important',
    margin: '20px 1rem',
    borderBottom: '2px solid #707070',
    color: '#2C3C47',
    fontFamily: 'Roboto',
    fontSize: '50px'
  },
  otpbody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: `${theme.spacing.unit * 7.375}px !important`,
    color: '#707070'
  }
});

export default withStyles(styles)(OTPValidationProcess);
