import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import _compact from 'lodash/compact';
import _remove from 'lodash/remove';
import _findIndex from 'lodash/findIndex';

import ActivityIndicator from '../../shared/ActivityIndicator';
import { history } from '../../../helpers/history';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import SupplierProfile from './Profile';
import PointsOfSale from './PointsOfSale';
import Corporation from './Corporation';
import SupplierPreferences from './SupplierPreferences';

import SupplierEdit from './modals/SupplierEdit';
import SupplierEditConfig from './modals/SupplierEditConfig';

class SupplierDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditSupplierOpen: false,
      isEditSupplierAttributeOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      ...this.props.currentUser
    });
    this.fetchDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchDetails();
    }
  }

  fetchDetails = () => {
    this.props.fetchSupplierInfo(this.props.match.params.id);
  };

  _handleOpenEditSupplierModal = () =>
    this.setState({ isEditSupplierOpen: true });
  _handleCloseEditSupplierModal = () =>
    this.setState({ isEditSupplierOpen: false });

  _handleOpenEditSupplierAttributeModal = () =>
    this.setState({ isEditSupplierAttributeOpen: true });
  _handleCloseEditSupplierAttributeModal = () =>
    this.setState({ isEditSupplierAttributeOpen: false });

  _handleUpdateSupplier = fields => {
    const { supplier } = this.props;
    const body = { supplier: { ...fields } };
    this.props.updateSupplierConfig(supplier.id, body);
    this._handleCloseEditSupplierModal();
  };

  _toCorporation = id => {
    history.push(`/corporations/${id}`);
  };

  render() {
    const {
      classes,
      match,
      supplier = {},
      supplierLoading = true,
      supplierUpdating,
      locationNames,
      supplierGeographicLocationsIds,
      canUpdateSupplierGeographicLocations
    } = this.props;

    const { canUpdateSupplier, canViewCorporations, canViewPOS } = this.state;
    const currentLocationsIds = Object.entries(supplier).length === 0 ? [] : supplier.geographicLocationIds.map(geo => geo.geographicLocationId);
    const currectSecureSaleLocationsIds = Object.entries(supplier).length === 0 ? [] : supplier.secureSaleGeographicLocationIds.map(geo => geo.geographicLocationId);
    const currentGeographicLocations = locationNames ? locationNames.filter(l => currentLocationsIds.includes(l.id)) : []
    const currentSecureSaleGeographicLocations = locationNames ? locationNames.filter(l => currectSecureSaleLocationsIds.includes(l.id)) : []

    if (!supplier) {
      return <ActivityIndicator />;
    }

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1
            className={classnames(
              classes.headline4,
              classes.noMarginBottom,
              classes.title
            )}
          >
            {`Proveedor #${match.params.id}`}
          </h1>
        </div>
        <Grid container spacing={24}>
          <Grid item xs={12} lg={6}>
            <SupplierProfile
              supplier={supplier}
              isLoading={supplierLoading || supplierUpdating}
              canUpdateSupplier={canUpdateSupplier}
              onEditSupplierAttribute={
                this._handleOpenEditSupplierAttributeModal
              }
              currentGeographicLocations={currentGeographicLocations}
              currentSecureSaleGeographicLocations={currentSecureSaleGeographicLocations}
            />
            <Corporation
              canViewCorporations={canViewCorporations}
              isLoading={supplierLoading}
              corporation={supplier.corporation}
              toCorporation={this._toCorporation}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <PointsOfSale
              canViewPOS={canViewPOS}
              pointsOfSale={supplier.pointOfSales}
              isLoading={supplierLoading}
            />
            <SupplierPreferences
              canUpdateSupplier={canUpdateSupplier}
              onEditSupplier={this._handleOpenEditSupplierModal}
              supplier={supplier}
              isLoading={supplierLoading || supplierUpdating}
            />
          </Grid>
        </Grid>
        {this.state.isEditSupplierOpen && (
          <SupplierEditConfig
            supplierInfo={supplier}
            updateSupplier={this._handleUpdateSupplier}
            loading={supplierUpdating}
            saleChannels={this.props.saleChannels}
            onClose={this._handleCloseEditSupplierModal}
          />
        )}

        {this.state.isEditSupplierAttributeOpen && (
          <SupplierEdit
            supplier={supplier}
            supplierGeographicLocationsIds={supplierGeographicLocationsIds}
            canUpdateSupplierGeographicLocations={canUpdateSupplierGeographicLocations}
            onSave={this._handleUpdateSupplier}
            onClose={this._handleCloseEditSupplierAttributeModal}
            isSubmitting={supplierUpdating}
            locationNames={locationNames}
            currentGeographicLocations={currentGeographicLocations}
            currentSecureSaleGeographicLocations={currentSecureSaleGeographicLocations}
          />
        )}
      </div>
    );
  }
}

SupplierDetail.propTypes = {
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  permissions: PropTypes.array,
  newAlert: PropTypes.func
};

export default withStyles(
  combineStyles(LayoutStyles, ButtonStyles, TypographyStyles, SpacingStyles)
)(SupplierDetail);
