import { SINISTER_STATUS } from '../../../constants/enums';
import moment from 'moment-timezone';
import _get from 'lodash/get';

export const columns = [
  {
    title: '# Venta',
    name: 'saleId',
    componentType: 'Link'
  },
  {
    title: 'Fecha',
    name: 'date'
  },
  {
    title: 'Estado',
    name: 'status'
  },
  {
    title: 'Creador',
    name: 'creator'
  },
  {
    title: 'Tipo',
    name: 'type'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      saleId: datum.saleId || '--',
      saleIdLinkTo: `/sales/${datum.saleId}`,
      date:
        moment(datum.registeredAt)
          .locale('es')
          .format('DD MMM YYYY') || '--',
      status: SINISTER_STATUS[datum.status],
      order_id: datum.id || 'En proceso',
      creator: _get(datum.registerer, 'username', '--'),
      type: datum.type || '--'
    };
  });
