import _find from 'lodash/find';
import _range from 'lodash/range';

export function mapSaleDetails(list, selected) {
  return list.map(item => {
    let hasAlready = false;
    if (selected) {
      hasAlready = _find(selected, { id: item.id });
    }
    return {
      key: item.id,
      id: item.id,
      value: item.id,
      label: item.article.name,
      disabled: hasAlready ? true : false
    };
  });
}

export function mapNumberOptions(min, max, nameSingular, namePlural) {
  const installments = _range(min, max + 1).map(num => ({
    key: `${num}-N`,
    id: num,
    value: num,
    label:
      num === 1 ? `${num}${nameSingular || ''}` : `${num}${namePlural || ''}`
  }));
  return installments;
}

export function mapOperatingZones(operatingZones) {
  if (!operatingZones) return [];
  return operatingZones.map(item => {
    return {
      key: item.id,
      id: item.id,
      value: item.id,
      label: item.name
    };
  });
}

export function mapLocationMappedNames(locationNames) {
  if (!locationNames) return [];
  return locationNames.map(item => {
    return {
      key: item.id,
      id: item.id,
      value: item.id,
      label: item.description
    };
  });
}

export function mapCausals(causals) {
  if (!causals) return [];
  return causals.map(causal => {
    return {
      value: causal.id,
      title: causal.description
    };
  });
}

export function mapReturnCancelCausals(revokeSaleCausals, ...cardif) {
  if (!revokeSaleCausals) return [];
  return revokeSaleCausals.map(item => {
    return {
      id: parseInt(item.id),
      name: item.name,
      allExcept: item.applies_to != '1' ? [] : cardif,
      only: item.applies_to != '2' ? [] : cardif
    };
  });
}
