import * as Yup from 'yup';

export const validationSchema = (context={}) => {
  let {maxInstalmentsNumber} = context

  return Yup.object().shape({
    maxInstalmentsNumber: Yup.number(),
    totalValue: Yup.string().required('El campo es requerido'),
    instalments: Yup.number().required('El campo es requerido').min(1, 'El número de cuotas debe ser mayor a 0').max(maxInstalmentsNumber, `El número máximo de cuotas es ${maxInstalmentsNumber}`),
    downPayment: Yup.string().required('El campo es requerido')
  });
};