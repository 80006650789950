import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  pointOfSaleId: Yup.number(),
  categoryId: Yup.number()
    .required('El campo es requerido'),
  geographicLocationId: Yup.number()
    .required('El campo es requerido')
});

export default validationSchema;