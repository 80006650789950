import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';

const ListCommentsModal = props => {
  const { open, onClose, comments, classes } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Comentarios</DialogTitle>
      <DialogContent>
        {
          comments.map(
            (comment, index) => {
              return (
                <Grid key={index} container item className={classes.listCommentsModalTextContainer}>
                  <Grid item xs={9}>
                    <DialogContentText className={classes.listCommentsModalText}>
                      {comment.message}
                    </DialogContentText>
                  </Grid>
                  <Grid container item justify='flex-end' alignItems='flex-end' xs={3}>
                    <DialogContentText>
                      {comment.createdAt}
                    </DialogContentText>
                  </Grid>
                </Grid>
              );
            }
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Salir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ListCommentsModal;