import { LinearProgress, Tooltip, withStyles } from '@material-ui/core';
import classnames from 'classnames';
import { default as React } from 'react';
import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import SpacingStyles from '../../../styles/helpers/spacing';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import ListsStyle from '../__styles__/Lists.style';

class BulkProgressBar extends React.PureComponent {
  render() {
    const {
      classes,
      value,
      valueBuffer,
      total,
      finalValue,
      finalValueBuffer,
      finished
    } = this.props;

    const percentageValue = finished
      ? (finalValue / total) * 100
      : (value / total) * 100;
    const percentageValueBuffer = (valueBuffer / total) * 100;

    const successfulMessage = finished
      ? `${finalValue} de ${total} registros exitosos`
      : `${value} de ${total} registros exitosos`;
    const processedMessage = finished
      ? `${finalValueBuffer} de ${total} registros procesados`
      : `${valueBuffer} de ${total} registros procesados`;

    return (
      <Tooltip
        title={
          <div>
            <p>{successfulMessage}</p>
            <p>{processedMessage}</p>
          </div>
        }
        classes={{
          tooltip: classnames(classes.caption, classes.tooltip),
          popper: classes.tooltipPopper
        }}
        placement="top-end"
      >
        {finished ? (
          <LinearProgress
            variant="determinate"
            value={percentageValue}
            color="secondary"
            style={{
              height: 10,
              borderRadius: 5,
              width: '75%'
            }}
          />
        ) : (
          <LinearProgress
            variant="buffer"
            value={percentageValue}
            valueBuffer={percentageValueBuffer}
            color="secondary"
            style={{
              height: 10,
              borderRadius: 5,
              width: '75%'
            }}
          />
        )}
      </Tooltip>
    );
  }
}

const styles = theme => ({
  tooltip: {
    backgroundColor: '#2A3C47',
    color: '#FFFFFF !important',
    padding: '4px 12px'
  },
  tooltipPopper: {
    top: `-${theme.spacing.unit}px !important`
  }
});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(BulkProgressBar);
