export const causeOptions = [
  { value: 'all', title: 'Todas' },
  { value: 274, title: 'Por Siniestro' },
];

export const statusOptions = [
  { value: 'all', title: 'Todas' },
  { value: 'registered', title: 'Registrada' },
  { value: 'approved', title: 'Aprobada' },
  { value: 'revoked', title: 'Rechazada' }
];

export const typeOptions = [
  { value: 'all', title: 'Todas' },
  { value: 'Fianza', title: 'Fianza' },
]