import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import { Formik } from 'formik';

import LoadingIndicator from '../../shared/LoadingIndicator';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { doctypes } from '../../../constants/enums';
import CustomDialog from '../../shared/Modals/CustomDialog';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import ActivityIndicator from '../../shared/ActivityIndicator';
import SimpleTable from '../../shared/Tables/SimpleTable';

import { validationSchema } from './RegisterScheduleValidation';

class RegisterSchedule extends Component {
  state = {
    pointOfSaleName: '',
    posSearched: false,
    schedulePointOfSales: [],
    subline: null,
    contract_id: '',
    api_errors: null,
    pointOfSaleError: false,
    isRegisteredScheduleOpen: false,
    lastSchedule: null,
  };

  onSubmit = values => {
    const {
      isValidationRegisteredScheduleByContractActive,
      homevisit,
      checkIfContractHasRegisteredSchedule
    } = this.props;

    if (homevisit) {
      this.props.updateSchedule({
        id: this.props.homevisit.id,
        receptionTypeId: Number(values.receptionMedium),
        pointOfSaleId: Number(values.pointOfSaleId),
        additionalComments: values.additionalComments,
        creatorEmail: values.email,
        creatorMobile: `${values.mobile}`,
        requestObservations: `Solicitud de visita de venta de Brilla generada
          desde Sistema Brilla para el contrato ${values.contract}, a nombre
          del usuario ${values.name.replace(/\w\S*/g, w =>
          w.replace(/^\w/, c => c.toUpperCase())
        )}, con
          ${doctypes.find(type => type.id === values.identificationType).name} ${values.identification
          }, correo ${values.email} y número
          de celular ${values.mobile}, para el articulo ${this.props.scheduleCategories.find(sc => sc.id === values.subline).name
          }`,
      });
    } else {
      if (this.state.isRegisteredScheduleOpen || !isValidationRegisteredScheduleByContractActive) {
        this.registerSchedule(values);
        this.setState({ isRegisteredScheduleOpen: false });
        setTimeout(() => this.setState({ lastSchedule: null }), 1000);
      } else {
        checkIfContractHasRegisteredSchedule({
          contractId: values.contract,
        }).then(lastSchedule => {
          if (lastSchedule) {
            this.setState({ isRegisteredScheduleOpen: true, lastSchedule });
          } else {
            this.registerSchedule(values);
          }
        });
      }
    }
    this.setState({
      pointOfSaleName: '',
      subline: null,
      pointOfSaleError: false
    });
  };

  registerSchedule = values => {
    const name = values.name.split(' ');

    this.props.registerSchedule({
      contractId: values.contract,
      clientIdentificationTypeId: values.identificationType,
      clientIdentification: values.identification,
      clientName: name[0],
      clientLastName: name.splice(1, name.length).join(' '),
      categoryId: values.subline,
      requestObservations: `Solicitud de visita de venta de Brilla generada
        desde Sistema Brilla para el contrato ${values.contract}, a nombre
        del usuario ${values.name.replace(/\w\S*/g, w =>
        w.replace(/^\w/, c => c.toUpperCase())
      )}, con
        ${doctypes.find(type => type.id === values.identificationType).name} ${values.identification
        }, correo ${values.email} y número
        de celular ${values.mobile}, para el artículo ${this.props.scheduleCategories.find(sc => sc.id === values.subline).name
        }`,
      receptionTypeId: values.receptionMedium,
      pointOfSaleId: Number(values.pointOfSaleId),
      email: values.email,
      additionalComments: values.additionalComments,
      creatorEmail: values.email,
      creatorMobile: `${values.mobile}`,
    });
  };

  _handleOnClose = () => {
    this.props.closeRegisterModal();
    this.props.clearContract();
    this.setState({
      pointOfSaleName: '',
      schedulePointOfSales: [],
      subline: null,
      pointOfSaleError: false,
      posSearched: false,
      schedulePointOfSales: [],
    });
  };

  _onKeyPress = event => {
    event.key === 'Enter' && event.preventDefault();
  };

  _getSublines = categoryId => {
    const categories = this.props.categories;
    const scheduleCategories = this.props.scheduleCategories;
    const validLines = scheduleCategories.map(sb => sb.id);
    return categories.filter(
      c => c.parent_id === categoryId && validLines.includes(c.id)
    );
  };

  _getSuggestions = async value => {
    let items = this.state.schedulePointOfSales.map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  _getScheduleSaleChannels = isUpdate => {
    const { homevisit, scheduleSaleChannels } = this.props;

    if (!isUpdate) {
      return scheduleSaleChannels;
    }

    const defaultSaleChannel = homevisit ? [{ id: Number(homevisit.saleChannel.id), name: homevisit.saleChannel.name }] : [];
    const isReceptionMediumActive = Boolean(scheduleSaleChannels.find(s => s.id === Number(_get(homevisit, 'saleChannel.id'))));
    const completeScheduleSaleChannels = isReceptionMediumActive ? scheduleSaleChannels : [...scheduleSaleChannels, ...defaultSaleChannel];

    return completeScheduleSaleChannels;
  };

  _getContract = contractInfo => {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'COP'
    });
    if (!contractInfo) return [];
    const contract = {
      key: contractInfo.id,
      address: contractInfo.address || '--',
      availableQuota: formatter.format(Number(contractInfo.availableQuota)),
      assignedQuota: formatter.format(Number(contractInfo.assignedQuota)),
    };
    return [contract];
  };

  _fetchContractInfo = () => {
    const { contract_id } = this.state;
    contract_id &&
      this.props.fetchContract(contract_id).then(r => {
        if (!r.success) {
          this.setState({ api_errors: r.errors });
        }
      });
  };

  _handleContract = _debounce(
    () => {
      this._fetchContractInfo();
    },
    1000,
    {
      leading: false,
      trailing: true
    }
  );

  _handlePointOfSale = (e, contract, setFieldValue, setErrors) => {
    setFieldValue('pointOfSaleId', '');
    setFieldValue('pointOfSaleName', '');
    this.setState({
      schedulePointOfSales: [],
      pointOfSaleName: '',
      subline: e.target.value,
      pointOfSaleError: false
    });
    this.props.fetchSchedulePointOfSale({ categoryId: e.target.value, contractId: contract }).then(data => {
      if (!(this.props.isOpen && this.state.subline)) return;

      if (data && data.length > 0) {
        if (this.props.isAutomaticSchedulePointOfSaleActive) {
          setFieldValue('pointOfSaleId', data[0].id);
          setFieldValue('pointOfSaleName', data[0].name);
        } else {
          this.setState({ schedulePointOfSales: data });
        }
      } else {
        setErrors({ pointOfSaleName: 'No se encontró punto de venta configurado para la sublinea seleccionada' });
        this.setState({ pointOfSaleError: true });
      }
    });
  };

  _handlePointOfSaleWhenUpdate = (defaultPos, setErrors = null) => {
    this.setState({
      schedulePointOfSales: [],
      pointOfSaleName: '',
      pointOfSaleError: false
    });
    const { homevisit } = this.props;
    this.props.fetchSchedulePointOfSale({ categoryId: homevisit.category.id, contractId: homevisit.contract.id }).then(data => {
      if (!this.props.isOpen) return;
      if (data && data.length > 0) {
        this.setState({ schedulePointOfSales: !data.find(d => d.id === defaultPos[0].id) ? [...data, ...defaultPos] : data });
      } else if (defaultPos && defaultPos.length > 0) {
        this.setState({ schedulePointOfSales: defaultPos });
      } else {
        if (setErrors) setErrors({ pointOfSaleName: 'No se encontró punto de venta configurado para la sublinea seleccionada' });
        this.setState({ pointOfSaleError: true });
      }
    });
  };

  _getJoinError = element => {
    const ErrorsOnRegisterSchedule =
      this.props.serverErrors || '';
    return ErrorsOnRegisterSchedule[element] == null
      ? ''
      : ErrorsOnRegisterSchedule[element];
  };

  clean_inputs_errors = (input_type) => {
    const errorsRegisterSchedule = {
      ...this.props.serverErrors
    };
    if (this._getJoinError(input_type) != '') {
      delete errorsRegisterSchedule[input_type];
      this.props.updateErrorsOnRegisterSchedule(errorsRegisterSchedule);
    }
  };

  render() {
    const {
      classes,
      loading,
      isOpen,
      homevisit,
      categories = [],
      scheduleCategories = [],
      scheduleSaleChannels = [],
      serverErrors = {},
      loadingPointOfSales,
      registerScheduleWithEmail,
      contractDetail,
      requestSendContract,
      isAutomaticSchedulePointOfSaleActive
    } = this.props;
    const isUpdate = Boolean(homevisit);
    const { api_errors, schedulePointOfSales, isRegisteredScheduleOpen, lastSchedule } = this.state;

    const validLines = scheduleCategories.map(sb => sb.parent_id);
    const lines = categories.filter(c => validLines.includes(c.id));
    const defaultLine = lines.find(l => l.id === _get(homevisit, 'category.parentId'));
    const defaultPos = homevisit ? [{ id: homevisit.pointOfSaleId, name: homevisit.pointOfSale }] : [];
    const loadingCategories = categories.length === 0 || scheduleCategories.length === 0;

    if (isUpdate && !this.state.posSearched && schedulePointOfSales.length === 0) {
      this._handlePointOfSaleWhenUpdate(defaultPos);
      this.setState({ posSearched: true });
    }

    return (
      isOpen && (
        <Formik
          initialValues={{
            name: isUpdate ? `${homevisit.clientName} ${homevisit.clientLastName}` : '',
            contract: isUpdate ? homevisit.contract.id : '',
            identificationType: 1,
            identification: isUpdate ? homevisit.clientIdentification : '',
            email: isUpdate && homevisit.creatorEmail ? homevisit.creatorEmail : '',
            mobile: isUpdate && homevisit.creatorMobile ? homevisit.creatorMobile : '',
            line: isUpdate ? Number(homevisit.category.parentId) : '',
            subline: isUpdate ? Number(homevisit.category.id) : '',
            pointOfSaleId: isUpdate ? Number(homevisit.pointOfSaleId) : '',
            pointOfSaleName: isUpdate ? homevisit.pointOfSale : '',
            receptionMedium: isUpdate ? homevisit.saleChannel.id : '',
            registerScheduleWithEmail,
            additionalComments: isUpdate ? homevisit.additionalComments : ''
          }}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          onReset={(values, actions) => {
            actions.resetForm();
            this._handleOnClose();
          }}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            handleReset,
            setFieldValue,
            setErrors
          }) => (
            <CustomDialog
              onClose={handleReset}
              onConfirm={handleSubmit}
              loading={loading}
              open={isOpen}
              title={!homevisit ? 'Registro de Agendamiento' : 'Actualización de Agendamiento'}
              buttonText={!homevisit ? 'Registrar' : 'Actualizar'}
              disabled={loadingCategories || Object.keys(errors).length > 0 || api_errors || requestSendContract}
              classes={{
                root: classes.registerScheduleDialogRoot,
                content: classes.registerScheduleDialogContent
              }}
            >
              <form
                autoComplete="off"
                className={loadingCategories ? classes.loading : classes.form}
              >
                {loadingCategories ? (
                  <LoadingIndicator />
                ) : (
                  <Grid container spacing={24} alignItems="flex-start">
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <TextField
                        name={'contract'}
                        type="number"
                        value={isUpdate ? homevisit.contract.id : values.contract}
                        label={'Contrato*'}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={e => {
                          this.clean_inputs_errors('contract');
                          handleChange(e);
                          setFieldValue('line', '');
                          setFieldValue('subline', '');
                          setFieldValue('pointOfSaleId', '');
                          setFieldValue('pointOfSaleName', '');
                          this.setState({
                            contract_id: e.target.value,
                            api_errors: null
                          });
                          this._handleContract(e.target.value);
                        }}
                        disabled={loading || isUpdate}
                        InputProps={{
                          endAdornment: requestSendContract ? (
                            <ActivityIndicator size={24} />
                          ) : null
                        }}
                        error={
                          (touched.contract && !!errors.contract) ||
                          (serverErrors &&
                            serverErrors.base) ||
                          (serverErrors &&
                            serverErrors.contract)
                        }
                        helperText={
                          (touched.contract && errors.contract) ||
                          (serverErrors &&
                            serverErrors.base) ||
                          (serverErrors &&
                            serverErrors.contract)
                        }
                        onKeyPress={this._onKeyPress}
                      />
                    </Grid>
                    {!requestSendContract && contractDetail && (
                      <Grid item xs={12} className={classes.table}>
                        <SimpleTable
                          headers={[
                            {
                              name: 'address',
                              title: 'Dirección'
                            },
                            {
                              name: 'availableQuota',
                              title: 'Cupo disponible'
                            },
                            {
                              name: 'assignedQuota',
                              title: 'Cupo asignado'
                            }
                          ]}
                          rows={this._getContract(contractDetail)}
                        />
                      </Grid>
                    )}
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <OutlinedTextField
                        onKeyPress={this._onKeyPress}
                        disabled={loading || isUpdate}
                        label="Nombre y apellidos"
                        name="name"
                        type={'string'}
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <SimpleSelect
                        disabled={loading || isUpdate}
                        value={isUpdate ? homevisit.clientIdentificationTypeId : values.identificationType}
                        onChange={handleChange}
                        name="identificationType"
                        label="Tipo de identificación del deudor"
                        options={mapListToOptions(doctypes)}
                        error={
                          touched.identificationType &&
                          !!errors.identificationType
                        }
                        helperText={
                          touched.identificationType &&
                          errors.identificationType
                        }
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <OutlinedTextField
                        onKeyPress={this._onKeyPress}
                        disabled={loading || isUpdate}
                        type={'string'}
                        label="Identificación del deudor"
                        name="identification"
                        error={
                          touched.identification && !!errors.identification
                        }
                        helperText={
                          touched.identification && errors.identification
                        }
                        value={values.identification}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <OutlinedTextField
                        onKeyPress={this._onKeyPress}
                        disabled={loading}
                        type={'string'}
                        label="Email"
                        name="email"
                        error={
                          (touched.email && !!errors.email) ||
                          (serverErrors &&
                            serverErrors.email &&
                            !!serverErrors.email[0])
                        }
                        helperText={
                          (touched.email && errors.email) ||
                          (serverErrors &&
                            serverErrors.email &&
                            serverErrors.email[0])
                        }
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <OutlinedTextField
                        onKeyPress={this._onKeyPress}
                        disabled={loading}
                        type={'number'}
                        label="Celular"
                        name="mobile"
                        error={touched.mobile && !!errors.mobile}
                        helperText={touched.mobile && errors.mobile}
                        onInput={e => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <SimpleSelect
                        disabled={loading || isUpdate || loadingPointOfSales || (isAutomaticSchedulePointOfSaleActive && (!requestSendContract && !contractDetail))}
                        value={values.line}
                        onChange={change => {
                          handleChange(change);
                          setFieldValue('subline', '');
                          setFieldValue('pointOfSaleId', '');
                          setFieldValue('pointOfSaleName', '');
                          this.setState({
                            schedulePointOfSales: [],
                            pointOfSaleName: '',
                            subline: null
                          });
                        }}
                        name="line"
                        label="Linea"
                        options={isUpdate ? mapListToOptions([defaultLine]) : mapListToOptions(lines)}
                        error={touched.line && !!errors.line}
                        helperText={touched.line && errors.line}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <SimpleSelect
                        disabled={
                          loading ||
                          values.line === '' ||
                          values.contract === '' ||
                          loadingPointOfSales ||
                          (isAutomaticSchedulePointOfSaleActive && (!requestSendContract && !contractDetail)) ||
                          isUpdate
                        }
                        value={values.subline}
                        onChange={change => {
                          handleChange(change);
                          this._handlePointOfSale(change, values.contract, setFieldValue, setErrors);
                        }}
                        name="subline"
                        label="Sublinea"
                        options={mapListToOptions(
                          this._getSublines(values.line)
                        )}
                        error={
                          (touched.subline && !!errors.subline) ||
                          (serverErrors &&
                            serverErrors.category &&
                            !!serverErrors.category[0])
                        }
                        helperText={
                          (touched.subline && errors.subline) ||
                          (serverErrors &&
                            serverErrors.category &&
                            serverErrors.category[0])
                        }
                      />
                    </Grid>
                    {isAutomaticSchedulePointOfSaleActive && !isUpdate ? (
                      <Grid
                        item
                        sm={6}
                        xs={12}
                        className={classes.autocompleteInputGrid}
                      >
                        <AutocompleteInput
                          id="pointOfSaleId"
                          name="pointOfSaleId"
                          label="Punto de venta"
                          margin="normal"
                          variant="outlined"
                          disabled={
                            loading ||
                            loadingPointOfSales ||
                            values.subline === '' ||
                            isAutomaticSchedulePointOfSaleActive
                          }
                          loading={loadingPointOfSales}
                          value={
                            this.state.pointOfSaleName ||
                            values.pointOfSaleName ||
                            ''
                          }
                          onChange={e => {
                            setFieldValue('pointOfSaleId', '');
                            setFieldValue('pointOfSaleName', '');
                            this.setState({ pointOfSaleName: e });
                          }}
                          suggestions={this.state.schedulePointOfSales}
                          getSuggestions={value => this._getSuggestions(value)}
                          onSuggestionSelected={item => {
                            setFieldValue('pointOfSaleName', item.label);
                            setFieldValue('pointOfSaleId', Number(item.value));
                          }}
                          error={this.state.pointOfSaleError && !!errors.pointOfSaleName}
                          helperText={this.state.pointOfSaleError && errors.pointOfSaleName}
                        />
                      </Grid>
                    ) : !isUpdate ? (
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <SimpleSelect
                          disabled={
                            loading ||
                            loadingPointOfSales ||
                            values.subline === '' ||
                            schedulePointOfSales.length === 0 ||
                            (isAutomaticSchedulePointOfSaleActive && (!requestSendContract && !contractDetail))
                          }
                          endAdornment={loadingPointOfSales ? <ActivityIndicator size={24} /> : null}
                          value={values.pointOfSaleId}
                          onChange={e => {
                            handleChange(e);
                            setFieldValue('pointOfSaleName', schedulePointOfSales.find(p => p.id === e.target.value).name);
                          }}
                          name="pointOfSaleId"
                          label="Punto de venta"
                          options={mapListToOptions(schedulePointOfSales)}
                          error={this.state.pointOfSaleError && !!errors.pointOfSaleName}
                          helperText={this.state.pointOfSaleError && errors.pointOfSaleName}
                        />
                      </Grid>
                    ) : (
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <SimpleSelect
                          disabled={
                            loading ||
                            loadingPointOfSales ||
                            schedulePointOfSales.length === 0
                          }
                          endAdornment={loadingPointOfSales ? <ActivityIndicator size={24} /> : null}
                          value={values.pointOfSaleId}
                          onClick={change => {
                            if (schedulePointOfSales.length === 0) {
                              handleChange(change);
                              this._handlePointOfSaleWhenUpdate(defaultPos, setErrors);
                            }
                          }}
                          onChange={e => {
                            handleChange(e);
                            setFieldValue('pointOfSaleName', (schedulePointOfSales.length !== 0 ? schedulePointOfSales : defaultPos).find(p => p.id === e.target.value).name);
                          }}
                          name="pointOfSaleId"
                          label="Punto de venta"
                          options={mapListToOptions(schedulePointOfSales.length !== 0 ? schedulePointOfSales : defaultPos)}
                          error={this.state.pointOfSaleError && !!errors.pointOfSaleName}
                          helperText={this.state.pointOfSaleError && errors.pointOfSaleName}
                        />
                      </Grid>
                    )}
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <SimpleSelect
                        disabled={loading || loadingPointOfSales}
                        value={values.receptionMedium}
                        onChange={handleChange}
                        name="receptionMedium"
                        label="Medio de Recepción"
                        options={mapListToOptions(this._getScheduleSaleChannels(isUpdate))}
                        error={touched.line && !!errors.line}
                        helperText={touched.line && errors.line}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={handleChange}
                        disabled={loading}
                        label="Comentarios adicionales"
                        name="additionalComments"
                        margin="normal"
                        variant="outlined"
                        multiline={true}
                        fullWidth
                        value={values.additionalComments}
                        rows={2}
                        inputProps={{ maxLength: 199 }}
                      />
                    </Grid>
                  </Grid>
                )}
                <CustomDialog
                  onClose={() => {
                    this.setState({ isRegisteredScheduleOpen: false });
                    setTimeout(() => this.setState({ lastSchedule: null }), 1000);
                  }}
                  onConfirm={handleSubmit}
                  loading={loading}
                  open={isRegisteredScheduleOpen}
                  title={'Registro de Agendamiento'}
                  buttonText={'Continuar'}
                  classes={{
                    root: classes.registerScheduleDialogRoot,
                    content: classes.registerScheduleDialogContent
                  }}
                >
                  El contrato ya cuenta con un agendamiento en curso desde la fecha {new Date(lastSchedule && lastSchedule.registrationDate).toLocaleDateString('es-CO')}. ¿Desea continuar?
                </CustomDialog>
              </form>
            </CustomDialog>
          )}
        />
      )
    );
  }
}

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 5
  },
  button: {
    marginRight: 0,
    padding: '6px 8px',
    width: 100
  },
  registerScheduleDialogRoot: {
    ['@media (max-width:600px)']: {
      width: '95%',
      margin: '0 24px',
      minHeight: 'px'
    }
  },
  registerScheduleDialogContent: {
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  inputGrid: {
    padding: '6px 12px 6px 12px !important',
    ['@media (max-width:600px)']: {
      padding: '0 12px !important'
    }
  },
  autocompleteInputGrid: {
    padding: '22px 12px 6px 12px !important',
    ['@media (max-width:600px)']: {
      padding: '0 12px !important'
    }
  },
  title: {
    color: '#111'
  },
  loading: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 20
  }
});

RegisterSchedule.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(RegisterSchedule);
