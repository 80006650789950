import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { doctypes } from '../../../constants/enums';
import { Formik } from 'formik';
import { validationSchema } from './QueryQuotaValidation';
import CustomDialog from '../../shared/Modals/CustomDialog';
import formatMoney from '../../../helpers/formatMoney';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';

class QueryQuota extends PureComponent {
  onSubmit = values => {
    this.props.queryQuotas(values);
  };

  _handleOnClose = () => {
    this.props.resetQueryData();
    this.props.closeModal();
  };

  resetForm = () => { };

  _onKeyPress = event => {
    event.key === 'Enter' && event.preventDefault();
  };

  _extraQuota = (quota, extra_quota) => {
    const extra =
      parseInt(quota.assignedQuota) +
      parseInt(extra_quota) -
      (parseInt(quota.usedQuota) + parseInt(quota.availableQuota));
    return extra > 0 ? extra : 0;
  };

  _showExtraQuota = quota => {
    if (quota.assignedQuota == 0) return false;
    if (!quota.extraQuotas) return false;
    if (quota.extraQuotas.length == 0) return false;
    let show = false;
    quota.extraQuotas.forEach(extraQuota => {
      if (this._extraQuota(quota, extraQuota.value) > 0) show = true;
    });
    return show;
  };

  renderClientRolloverTitle = classes => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', color: 'black' }}
        className={classes.grayContainer}
      >
        <strong>Cliente Rollover</strong>
      </div>
    );
  };

  render() {
    const {
      classes,
      loading,
      serverErrors = {},
      quotas = [],
      isOpen,
      hasSearched,
      clientIsRollover = false
    } = this.props;
    const successfulRequest = hasSearched && _isEmpty(serverErrors);

    return (
      isOpen && (
        <Formik
          initialValues={{
            queryType: 'contract',
            contract: '',
            identificationType: '',
            identification: ''
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            this.resetForm = resetForm;
            this.onSubmit(values);
          }}
          onReset={(_, actions) => {
            actions.resetForm();
            this._handleOnClose();
          }}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleReset,
          }) => (
            <CustomDialog
              onClose={handleReset}
              onConfirm={successfulRequest ? handleReset : handleSubmit}
              onConfirmSecond={() => {
                this.props.resetQueryData();
                this.resetForm();
              }}
              loading={loading}
              open={isOpen}
              title={'Consulta de Cupo'}
              buttonText={successfulRequest ? 'Cerrar' : 'Consultar'}
              secondButtonText={successfulRequest ? 'Limpiar' : ''}
              disabled={Object.keys(errors).length > 0 ? true : false}
              classes={{
                root: classes.queryQuotaDialogRoot,
                content: classes.queryQuotaDialogContent
              }}
            >
              <form autoComplete="off" className={classes.form}>
                {quotas.length === 0 && !successfulRequest ? (
                  <Grid container spacing={24} alignItems="flex-start">
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                      <SimpleSelect
                        disabled={successfulRequest}
                        value={values.queryType}
                        onChange={handleChange}
                        name="queryType"
                        label="Consulta por:"
                        options={[
                          {
                            key: 'contract',
                            value: 'contract',
                            label: 'Contrato'
                          },
                          {
                            key: 'id',
                            value: 'id',
                            label: 'Identificación'
                          }
                        ]}
                      />
                    </Grid>
                    {values.queryType === 'contract' && (
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OutlinedTextField
                          onKeyPress={this._onKeyPress}
                          disabled={successfulRequest}
                          label="Numero de contrato:"
                          name="contract"
                          type={'number'}
                          error={
                            (touched.contract && !!errors.contract) ||
                            !!_get(serverErrors, 'errors.contract')
                          }
                          helperText={
                            (touched.contract && errors.contract) ||
                            _get(serverErrors, 'errors.contract')
                          }
                          value={values.contract}
                          onChange={handleChange}
                        />
                      </Grid>
                    )}
                    {values.queryType === 'id' && (
                      <React.Fragment>
                        <Grid item sm={6} xs={12} className={classes.inputGrid}>
                          <SimpleSelect
                            disabled={successfulRequest}
                            value={values.identificationType}
                            onChange={handleChange}
                            name="identificationType"
                            label="Tipo de identificacion del deudor:"
                            options={mapListToOptions(doctypes, {}, true)}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12} className={classes.inputGrid}>
                          <OutlinedTextField
                            onKeyPress={this._onKeyPress}
                            disabled={successfulRequest}
                            type={'number'}
                            label="Identificacion del deudor:"
                            name="identification"
                            error={
                              (touched.identification &&
                                !!errors.identification) ||
                              (serverErrors && !!serverErrors.debtor)
                            }
                            helperText={
                              (touched.identification &&
                                errors.identification) ||
                              (serverErrors && serverErrors.debtor)
                            }
                            value={values.identification}
                            onChange={handleChange}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                ) : (
                  <React.Fragment>
                    {quotas.filter(
                      quota => quota.observations[0] != 'El contrato no existe'
                    ).length !== 0 ? (
                      <div className={classes.queryResults}>
                        <div>
                          {values.queryType === 'id' &&
                            clientIsRollover &&
                            this.renderClientRolloverTitle(classes)}
                        </div>
                        {quotas
                          .filter(
                            quota =>
                              quota.observations[0] != 'El contrato no existe'
                          )
                          .map(quota => {
                            return (
                              <div
                                key={quota.id}
                                className={classes.resultContainer}
                              >
                                <CollapseWrapper
                                  expanded={true}
                                  header={() => (
                                    <div className={classes.titleWrapper}>
                                      <h2
                                        className={classnames(
                                          classes.headline8,
                                          classes.title
                                        )}
                                      >
                                        {'Contrato ' + quota.id}
                                      </h2>
                                    </div>
                                  )}
                                  body={
                                    <React.Fragment>
                                      <div className={classes.grayContainer}>
                                        <div className={classes.dataContainer}>
                                          <div className={classes.dataColumn}>
                                            <p className={classes.dataRegister}>
                                              <strong>Cliente: </strong>
                                              {quota.clientName}
                                            </p>
                                            <p className={classes.dataRegister}>
                                              <strong>Identificación: </strong>
                                              {quota.identification}
                                            </p>
                                            <p className={classes.dataRegister}>
                                              <strong>Dirección: </strong>
                                              {quota.address}
                                            </p>
                                            <p className={classes.dataRegister}>
                                              <strong>Segmentación: </strong>
                                              {quota.segment + ' - ' + quota.contractStratum}
                                            </p>
                                          </div>
                                          <div className={classes.dataColumn}>
                                            <p className={classes.dataRegister}>
                                              <strong>Cupo Asignado: </strong>
                                              {formatMoney(
                                                quota.assignedQuota
                                              )}
                                            </p>
                                            <p className={classes.dataRegister}>
                                              <strong>Cupo Usado: </strong>
                                              {formatMoney(quota.usedQuota)}
                                            </p>
                                            <p className={classes.dataRegister}>
                                              <strong>Cupo disponible: </strong>
                                              {formatMoney(
                                                quota.availableQuota
                                              )}
                                            </p>
                                            <p className={classes.dataRegister}>
                                              <strong>Localidad: </strong>
                                              {quota.location}
                                            </p>
                                          </div>
                                          {values.queryType === 'contract' && (
                                            <div className={classes.insurance}>
                                              <p
                                                className={classes.dataRegister}
                                              >
                                                <strong>
                                                  Contrato con Seguro
                                                  Voluntario:{' '}
                                                </strong>
                                                {quota.canBuyVoluntaryInsurance ===
                                                  true
                                                  ? 'NO'
                                                  : 'SI'}
                                              </p>
                                            </div>
                                          )}
                                          {values.queryType === 'contract' && (
                                            <div className={classes.insurance}>
                                              <p className={classes.dataRegister}>
                                                <strong>
                                                  Contrato con Seguro
                                                  Voluntario Complementario:{' '}
                                                </strong>
                                                {quota.canBuyVoluntaryInsuranceLayerTwo ===
                                                  true
                                                  ? 'NO'
                                                  : 'SI'}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                        {quota.observations.length > 0 && (
                                          <div>
                                            <strong>Observaciones</strong>
                                            <ul
                                              className={
                                                classes.observationsList
                                              }
                                            >
                                              {quota.observations.map(
                                                (observation, index) => {
                                                  return (
                                                    <li
                                                      key={index}
                                                      className={
                                                        classes.dataRegister
                                                      }
                                                    >
                                                      {observation}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                      {this._showExtraQuota(quota) && (
                                        <div>
                                          <strong>Extra cupos</strong>
                                          {quota.extraQuotas.map(
                                            extraQuota => {
                                              return (
                                                <div
                                                  key={extraQuota.id}
                                                  className={
                                                    classes.extraQuotaItem
                                                  }
                                                >
                                                  <p
                                                    className={
                                                      classes.dataRegister
                                                    }
                                                  >
                                                    {extraQuota.name}
                                                  </p>
                                                  <p
                                                    className={
                                                      classes.dataRegister
                                                    }
                                                  >
                                                    {formatMoney(
                                                      this._extraQuota(
                                                        quota,
                                                        extraQuota.value
                                                      )
                                                    )}
                                                  </p>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                    </React.Fragment>
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <div className={classes.notFound}>
                        <div>
                          {values.queryType === 'id' &&
                            clientIsRollover &&
                            this.renderClientRolloverTitle(classes)}
                        </div>
                        <h2
                          className={classnames(
                            classes.headline8,
                            classes.title
                          )}
                        >
                          No se encontraron contratos
                        </h2>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </form>
            </CustomDialog>
          )}
        />
      )
    );
  }
}

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 0
  },
  notFound: {
    marginTop: 10,
    color: 'red'
  },
  dataContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '5px 0px'
  },
  dataColumn: {
    width: '50%',
    ['@media (max-width:600px)']: {
      width: '100% !important'
    }
  },
  dataRegister: {
    margin: '2.5px 0px'
  },
  observationsList: {
    paddingLeft: 30
  },
  extraQuotaItem: {
    borderTop: 'solid 1px #BCBCBC',
    padding: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'solid 1px #BCBCBC'
    }
  },
  queryResults: {
    marginTop: 10,
    display: 'block',
    width: '100%'
  },
  insurance: {
    margin: '2% 0 2% 0',
    flex: 1
  },
  resultContainer: {
    minHeight: 50,
    width: '100%',
    display: 'block',
    backgroundColor: '#FFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    marginBottom: theme.spacing.unit * 2,
    padding: '10px 16px'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    marginRight: 0,
    padding: '6px 8px',
    width: 100
  },
  queryQuotaDialogRoot: {
    ['@media (max-width:600px)']: {
      width: '95%',
      margin: '0 24px'
    }
  },
  queryQuotaDialogContent: {
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  inputGrid: {
    padding: '6px 12px 6px 12px !important',
    ['@media (max-width:600px)']: {
      padding: '0 12px !important'
    }
  },
  grayContainer: {
    backgroundColor: '#EFF1F4',
    marginBottom: '5%',
    padding: 20
  },
  title: {
    color: '#111'
  }
});

QueryQuota.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(QueryQuota);
