import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  password: Yup.string()
    .nullable()
    .required('Contraseña es requerida'),
  passwordConfirmation: Yup.string()
    .required('Contraseña es requerida')
    .oneOf([Yup.ref('password'), null], 'Contraseñas no coinciden')
});

export const validationSchemaWithCurrentPassword = Yup.object().shape({
  currentPassword: Yup.string()
    .nullable()
    .required('Contraseña actual es requerida'),
  password: Yup.string()
    .nullable()
    .required('Contraseña es requerida'),
  passwordConfirmation: Yup.string()
    .required('Contraseña es requerida')
    .oneOf([Yup.ref('password'), null], 'Contraseñas no coinciden')
});

export const initialValues = { password: '', passwordConfirmation: '' };
export const initialValuesWithCurrentPassword = {
  currentPassword: '',
  password: '',
  passwordConfirmation: ''
};
