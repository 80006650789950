import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import { SUPPLIER_PROPOSAL_MINUTE_TYPES } from '../../../constants/enums';
import FileUploader from './FileUploader';
import { SUPPLIER_PROPOSAL_DOCUMENT_TYPES } from '../../../constants/enums'
import Divider from '@material-ui/core/Divider';
import ResourceManagementTable from './ResourceManagementTable';
import ProgressButton from '../../shared/Buttons/ProgressButton';

const MinuteStep = props => {
  const {
    classes,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    allowFileReplace,
    fileMaxSize,
    fileSizeUnit,
    minuteExtensions,
    extensions,
    newAlert,
    setFieldValue,
    updateDocument,
    loadingDocumentIds,
    onViewOtherComments,
    onAddComment,
    showCommentSection,
    getDocByDocType,
    handleFileChange,
    handleFileError,
    handleFileAcceptanceChange,
    canAcceptOrRejectFile,
    allowAddComment,
    openCreateProductModal,
    canCreateDocuments,
    onAddDocument,
    minuteWasSend,
    proposalRejected,
    fetchFileBase64,
  } = props;
  const showMinuteForm = !minuteWasSend;
  const minuteDoc = getDocByDocType(
    SUPPLIER_PROPOSAL_DOCUMENT_TYPES.Minute,
    values.documents
  );
  return (
    <Grid container item className={classes.minuteStepContainer}>
      <Grid item xs={12}>
        <Typography variant='h3'>Minuta de convenio</Typography>
      </Grid>
      {
        showMinuteForm ? (
          <MinuteForm
            classes={classes}
            values={values}
            handleChange={handleChange}
            allowFileReplace={allowFileReplace}
            fileMaxSize={fileMaxSize}
            fileSizeUnit={fileSizeUnit}
            minuteExtensions={minuteExtensions}
            newAlert={newAlert}
            setFieldValue={setFieldValue}
            updateDocument={updateDocument}
            loadingDocumentIds={loadingDocumentIds}
            onViewOtherComments={onViewOtherComments}
            onAddComment={onAddComment}
            showCommentSection={showCommentSection}
            handleFileChange={handleFileChange}
            handleFileError={handleFileError}
            handleFileAcceptanceChange={handleFileAcceptanceChange}
            canAcceptOrRejectFile={canAcceptOrRejectFile}
            allowAddComment={allowAddComment}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            openCreateProductModal={openCreateProductModal}
            proposalRejected={proposalRejected}
            fetchFileBase64={fetchFileBase64}
            minuteDoc={minuteDoc}
          />
        ) : (
          <MinuteNegotiationForm
            classes={classes}
            values={values}
            allowFileReplace={allowFileReplace}
            fileMaxSize={fileMaxSize}
            fileSizeUnit={fileSizeUnit}
            minuteExtensions={minuteExtensions}
            extensions={extensions}
            newAlert={newAlert}
            setFieldValue={setFieldValue}
            updateDocument={updateDocument}
            loadingDocumentIds={loadingDocumentIds}
            onViewOtherComments={onViewOtherComments}
            onAddComment={onAddComment}
            showCommentSection={showCommentSection}
            handleFileChange={handleFileChange}
            handleFileError={handleFileError}
            handleFileAcceptanceChange={handleFileAcceptanceChange}
            canAcceptOrRejectFile={canAcceptOrRejectFile}
            allowAddComment={allowAddComment}
            canCreateDocuments={canCreateDocuments}
            onAddDocument={onAddDocument}
            proposalRejected={proposalRejected}
            fetchFileBase64={fetchFileBase64}
            minuteDoc={minuteDoc}
          />
        )
      }
    </Grid>
  );
};

const MinuteForm = props => {
  const {
    classes,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    openCreateProductModal,
    fetchingCategories,
    proposalRejected,
    minuteDoc,
    allowFileReplace,
    fileMaxSize,
    fileSizeUnit,
    minuteExtensions,
    newAlert,
    setFieldValue,
    updateDocument,
    loadingDocumentIds,
    onViewOtherComments,
    onAddComment,
    showCommentSection,
    handleFileChange,
    handleFileError,
    handleFileAcceptanceChange,
    allowAddComment,
    fetchFileBase64,
  } = props;
  const educationMinuteTypeId = SUPPLIER_PROPOSAL_MINUTE_TYPES.find(
    minType => minType.name === 'Educación'
  ).id;
  const otherMinuteTypeId = SUPPLIER_PROPOSAL_MINUTE_TYPES.find(
    minType => minType.name === 'Cargar otro tipo de minuta'
  ).id;
  return (
    <Grid container item className={classes.minuteFormContainer}>
      <Grid container item alignItems='center'>
        <Grid item xs={6}>
          <Typography variant='body1'>
            Selecciona el tipo de minuta que le debe aparecer al solicitante
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <SimpleSelect
            name='minuteType'
            label='Tipo de minuta'
            options={mapListToOptions(SUPPLIER_PROPOSAL_MINUTE_TYPES, {}, false)}
            value={values.minuteType || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.minuteType && errors.minuteType}
            error={touched.minuteType && !!errors.minuteType}
            disabled={proposalRejected}
          />
        </Grid>
      </Grid>
      {
        values.minuteType === otherMinuteTypeId && (
          <Grid container item justify='center' xs={12} className={classes.minuteFileUploaderContainer}>
            <Grid item xs={8}>
              <FileUploader
                allowFileReplace={allowFileReplace(minuteDoc)}
                fileName={minuteDoc.originalFileName}
                fetchFileBase64={fetchFileBase64({document: minuteDoc})}
                label={minuteDoc.name}
                description={minuteDoc.description}
                maxSize={fileMaxSize}
                sizeUnit={fileSizeUnit}
                extensions={minuteExtensions}
                onChange={
                  handleFileChange({
                    values,
                    document: minuteDoc,
                    newAlert,
                    setFieldValue,
                    updateDocument,
                  })
                }
                onError={handleFileError({ newAlert, fileMaxSize, fileSizeUnit })}
                submitting={loadingDocumentIds.includes(minuteDoc.id)}
                comments={minuteDoc.comments}
                onViewOtherComments={
                  comments => onViewOtherComments(comments)
                }
                onAddComment={
                  onAddComment({
                    documentId: minuteDoc.id,
                    setFieldValue,
                    values
                  })
                }
                canAcceptOrRejectFile={false}
                showCommentSection={showCommentSection(minuteDoc)}
                onFileAcceptanceChange={
                  handleFileAcceptanceChange({
                    documentId: minuteDoc.id,
                    setFieldValue,
                    values,
                  })
                }
                fileAccepted={minuteDoc.accepted}
                allowAddComment={allowAddComment(minuteDoc)}
              />
            </Grid>
          </Grid>
        )
      }
      <Grid container item alignItems='center'>
        <Grid item xs={6}>
          <Typography variant='body1'>
            Indica el porcentaje de comisión de la propuesta
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <OutlinedTextField
            name='commission'
            type='number'
            label='Comisión'
            value={values.commission}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.commission && errors.commission}
            error={touched.commission && !!errors.commission}
            disabled={proposalRejected}
          />
        </Grid>
      </Grid>
      {
        !!values.minuteType && values.minuteType !== educationMinuteTypeId && (
          <Grid container item xs={12}>
            <Grid item xs={12} className={classes.dividerContainer}>
              <Divider />
            </Grid>
            <Grid container item xs={12} className={classes.minuteStepProductsTableContainer}>
              <ProductsTable
                values={values}
                openCreateProductModal={openCreateProductModal}
                fetchingCategories={fetchingCategories}
                proposalRejected={proposalRejected}
              />
            </Grid>
          </Grid>
        )
      }
    </Grid>
  );
};

const MinuteNegotiationForm = props => {
  const {
    classes,
    allowFileReplace,
    values,
    fileMaxSize,
    fileSizeUnit,
    minuteExtensions,
    extensions,
    newAlert,
    setFieldValue,
    updateDocument,
    loadingDocumentIds,
    onViewOtherComments,
    onAddComment,
    showCommentSection,
    handleFileChange,
    handleFileError,
    handleFileAcceptanceChange,
    canAcceptOrRejectFile,
    allowAddComment,
    canCreateDocuments,
    onAddDocument,
    proposalRejected,
    fetchFileBase64,
    minuteDoc,
  } = props;
  const otherDocuments = _getOtherDocuments(values.documents);
  return (
    <Grid container item className={classes.minuteFormContainer}>
      <Grid item xs={12}>
        <Typography variant='body1'>
          Si deseas realizar algún comentario acerca de la minuta, recuerda que debes hacerlo con control de cambios <b>(de lo contrario no serán tenidos en cuenta).</b>
        </Typography>
      </Grid>
      <Grid container item justify='center' xs={12} className={classes.minuteFileUploaderContainer}>
        <Grid item xs={8}>
          <FileUploader
            allowFileReplace={allowFileReplace(minuteDoc)}
            fileName={minuteDoc.originalFileName}
            fetchFileBase64={fetchFileBase64({document: minuteDoc})}
            label={minuteDoc.name}
            description={minuteDoc.description}
            maxSize={fileMaxSize}
            sizeUnit={fileSizeUnit}
            extensions={minuteExtensions}
            onChange={
              handleFileChange({
                values,
                document: minuteDoc,
                newAlert,
                setFieldValue,
                updateDocument,
              })
            }
            onError={handleFileError({ newAlert, fileMaxSize, fileSizeUnit })}
            submitting={loadingDocumentIds.includes(minuteDoc.id)}
            comments={minuteDoc.comments}
            onViewOtherComments={
              comments => onViewOtherComments(comments)
            }
            onAddComment={
              onAddComment({
                documentId: minuteDoc.id,
                setFieldValue,
                values
              })
            }
            canAcceptOrRejectFile={false}
            showCommentSection={showCommentSection(minuteDoc)}
            onFileAcceptanceChange={
              handleFileAcceptanceChange({
                documentId: minuteDoc.id,
                setFieldValue,
                values,
              })
            }
            fileAccepted={minuteDoc.accepted}
            allowAddComment={allowAddComment(minuteDoc)}
          />
        </Grid>
      </Grid>
      {
        canCreateDocuments && !values.minuteAcceptedBy && !proposalRejected && (
          <Grid container item justify='center'>
            <Grid item xs={12} className={classes.dividerContainer}>
              <Divider />
            </Grid>
            <Grid container item xs={8} className={classes.addDocumentButtonContainer}>
              <Grid item xs={6}>
                <Typography variant='body1'>
                  ¿Consideras que hace falta algún documento por adjuntar?
                </Typography>
              </Grid>
              <Grid container item justify='flex-end' xs={6}>
                <ProgressButton
                  variant="contained"
                  color="secondary"
                  onClick={onAddDocument(SUPPLIER_PROPOSAL_DOCUMENT_TYPES.AdditionalInMinuteStep)}
                >
                  Agregar documento
                </ProgressButton>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      {
        otherDocuments.length > 0 && (
          <Grid container item justify='center'>
            <Grid item xs={12} className={classes.dividerContainer}>
              <Divider />
            </Grid>
            <Grid container item xs={12}>
              <Typography variant='h5'>
                Documentos adicionales
              </Typography>
            </Grid>
            {
              otherDocuments.map(
                document => {
                  return (
                    <Grid key={document.id} container item xs={8} className={classes.companySalesStepFileUploaderContainer}>
                      <FileUploader
                        allowFileReplace={allowFileReplace(document)}
                        fileName={document.originalFileName}
                        fetchFileBase64={fetchFileBase64({document: document})}
                        label={document.name}
                        description={document.description}
                        maxSize={fileMaxSize}
                        sizeUnit={fileSizeUnit}
                        extensions={extensions}
                        onChange={
                          handleFileChange({
                            values,
                            document,
                            newAlert,
                            setFieldValue,
                            updateDocument
                          })
                        }
                        onError={handleFileError({ newAlert, fileMaxSize, fileSizeUnit })}
                        submitting={loadingDocumentIds.includes(document.id)}
                        comments={document.comments}
                        onViewOtherComments={
                          comments => onViewOtherComments(comments)
                        }
                        onAddComment={
                          onAddComment({
                            documentId: document.id,
                            setFieldValue,
                            values
                          })
                        }
                        canAcceptOrRejectFile={canAcceptOrRejectFile}
                        showCommentSection={showCommentSection(document)}
                        onFileAcceptanceChange={
                          handleFileAcceptanceChange({
                            documentId: document.id,
                            setFieldValue,
                            values,
                          })
                        }
                        fileAccepted={document.accepted}
                        allowAddComment={allowAddComment(document)}
                      />
                    </Grid>
                  );
                }
              )
            }
          </Grid>
        )
      }
    </Grid>
  );
};

const ProductsTable = props => {
  const {
    values,
    openCreateProductModal,
    fetchingCategories,
    proposalRejected,
  } = props;
  return (
    <ResourceManagementTable
      loading={fetchingCategories}
      resourceName='Relación de los productos y/o servicios que pueden ser financiados a través del programa brilla'
      columns={
        [
          {
            title: 'Producto/Categoría',
            name: 'name'
          },
          {
            title: 'Cobertura geográfica aprobada',
            name: 'location'
          },
          {
            title: 'Entrega aprobada',
            name: 'channel'
          },
        ]
      }
      rows={values.products}
      showCreateButton={!proposalRejected}
      onCreateButtonClick={product => openCreateProductModal(product)}
      canClickRows={!proposalRejected}
      onRowClick={product => openCreateProductModal(product)}
    />
  );
}

const _getOtherDocuments = documents => {
  return documents.filter(
    doc => doc.docType === SUPPLIER_PROPOSAL_DOCUMENT_TYPES.AdditionalInMinuteStep
  );
}

export default MinuteStep;