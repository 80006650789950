import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import _findIndex from 'lodash/findIndex';

import ActivityIndicator from '../../shared/ActivityIndicator';
import { history } from '../../../helpers/history';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import POSProfile from './Profile';
import Sellers from './Sellers';
import Supplier from './Supplier';

import EditSellersModal from './modals/EditSellersModal';
import EditPOSInfoModal from './modals/EditPosInfoModal';

class PointOfSaleDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditSellersOpen: false,
      isEditPointOfSaleInfoOpen: false
    };
  }

  componentDidMount() {
    const permissions = this._getPermissions();
    this.setState({
      ...this.state,
      ...permissions
    });
    this.fetchDetails();
    !this.props.userNames;
    !this.props.locationMappedNames && this.props.fetchLocationMappedNames();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchDetails();
    }

    if (prevProps.pointOfSaleInfo !== this.props.pointOfSaleInfo) {

      this._handleCloseSellersModal();
    }
  }

  fetchDetails = () => {
    this.props.fetchPointOfSaleInfo(this.props.match.params.id);
  };

  _handleOpenSellersModal = () => this.setState({ isEditSellersOpen: true });
  _handleCloseSellersModal = () => this.setState({ isEditSellersOpen: false });
  _handleSaveSellers = newSellers => {
    this.props.setPointOfSaleSellers(this.props.pointOfSaleInfo.id, {
      point_of_sale: {
        users: newSellers
      }
    });
  };

  _handleUpdatePOS = pos => {
    const { pointOfSaleInfo } = this.props;
    const body = {
      ...pos,
      id: pointOfSaleInfo.id
    };
    this.props.updatePointOfSale(body).then(() => {
      this._handleCloseEditPOSInfoModal();
    });
  };

  _handleOpenEditPOSInfoModal = () =>
    this.setState({ isEditPointOfSaleInfoOpen: true });
  _handleCloseEditPOSInfoModal = () =>
    this.setState({ isEditPointOfSaleInfoOpen: false });

  _toSupplier = (id, canViewUsers) => {
    if (canViewUsers) {
      history.push(`/suppliers/${id}`);
    }
  };

  _getLocationName = (city_id, locationNames) => {
    if (!locationNames) return '';
    const location = locationNames.find(location => location.id == city_id);
    if (!location) return '';
    return location.description;
  };

  _getPermissions = () => {
    return {
      canUpdatePOS:
        _findIndex(
          this.props.currentUser.permissions,
          p => p.action === 'update_point_of_sales'
        ) >= 0,
      canUpdatePOSLocationInfo:
        _findIndex(
          this.props.currentUser.permissions,
          p => p.action === 'update_point_of_sale_location_info'
        ) >= 0,
      canUpdateSellers:
        _findIndex(
          this.props.currentUser.permissions,
          p => p.action === 'relate_point_of_sale_to_seller'
        ) >= 0 ||
        _findIndex(
          this.props.currentUser.permissions,
          p => p.action === 'relate_point_of_sales_and_users'
        ) >= 0,
      canViewSuppliers:
        _findIndex(
          this.props.currentUser.permissions,
          p => p.action === 'view_suppliers'
        ) >= 0,
      canViewUsers:
        _findIndex(
          this.props.currentUser.permissions,
          p => p.action === 'view_users'
        ) >= 0
    };
  };

  _getRoles = (coordinatorRoles, hugeSurfaceCoordinatorRoles) => {
    return coordinatorRoles && hugeSurfaceCoordinatorRoles
      ? coordinatorRoles.concat(hugeSurfaceCoordinatorRoles)
      : [];
  };

  render() {
    const {
      classes,
      match,
      pointOfSaleInfo = {},
      userNames = [],
      selectedSellers = [],
      locationMappedNames,
      pointOfSaleInfoLoading = true,
      pointOfSaleSellersUpdating,
      updatePointOfSaleLoading,
      userNamesLoading,
      canAddCoordinator,
      coordinatorRoles,
      hugeSurfaceCoordinatorRoles,
      freelanceRoles,
      asesorRoles
    } = this.props;

    const cityName = locationMappedNames && pointOfSaleInfo.attributes
      ? this._getLocationName(pointOfSaleInfo.attributes.city, locationMappedNames)
      : '';
    const {
      canUpdatePOS,
      canUpdatePOSLocationInfo,
      canUpdateSellers,
      canViewSuppliers,
      canViewUsers
    } = this.state;
    if (!pointOfSaleInfo) {
      return <ActivityIndicator />;
    }

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1
            className={classnames(
              classes.headline4,
              classes.noMarginBottom,
              classes.title
            )}
          >
            {`Punto de Venta #${match.params.id}`}
          </h1>
        </div>
        <Grid container spacing={24}>
          <Grid item xs={12} lg={6}>
            <POSProfile
              posInfo={pointOfSaleInfo.attributes}
              cityName={cityName}
              isLoading={pointOfSaleInfoLoading}
              canUpdatePOS={canUpdatePOS}
              canUpdatePOSLocationInfo={canUpdatePOSLocationInfo}
              locationMappedNames={locationMappedNames}
              onEditPOS={this._handleOpenEditPOSInfoModal}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Sellers
              canUpdateSellers={canUpdateSellers}
              canViewUsers={canViewUsers}
              onEditSellers={this._handleOpenSellersModal}
              sellers={selectedSellers}
              freelanceRoles={freelanceRoles}
              asesorRoles={asesorRoles}
              coordinatorRoles={this._getRoles(
                coordinatorRoles,
                hugeSurfaceCoordinatorRoles
              )}
              isLoading={
                pointOfSaleInfoLoading ||
                pointOfSaleSellersUpdating ||
                userNamesLoading
              }
              pointOfSaleId={match.params.id}
            />
            <Supplier
              canViewSuppliers={canViewSuppliers}
              isLoading={pointOfSaleInfoLoading}
              supplier={pointOfSaleInfo.supplier}
              toSupplier={this._toSupplier}
            />
          </Grid>
        </Grid>
        {this.state.isEditPointOfSaleInfoOpen && (
          <EditPOSInfoModal
            posInfo={pointOfSaleInfo}
            update={this._handleUpdatePOS}
            cityName={cityName}
            locationMappedNames={locationMappedNames}
            saleChannels={this.props.saleChannels}
            api_errors={this.props.updatePointOfSaleErrors}
            loading={updatePointOfSaleLoading}
            canUpdatePOS={canUpdatePOS}
            canUpdatePOSLocationInfo={canUpdatePOSLocationInfo}
            clearErrors={this.props.clearUpdatePOSErrors}
            onClose={this._handleCloseEditPOSInfoModal}
          />
        )}
        {this.state.isEditSellersOpen && (
          <EditSellersModal
            onClose={this._handleCloseSellersModal}
            sellers={userNames}
            selectedSellers={selectedSellers}
            onSave={this._handleSaveSellers}
            canAddCoordinator={canAddCoordinator}
            coordinatorRoles={this._getRoles(
              coordinatorRoles,
              hugeSurfaceCoordinatorRoles
            )}
          />
        )}
      </div>
    );
  }
}

PointOfSaleDetail.propTypes = {
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  permissions: PropTypes.array,
  newAlert: PropTypes.func
};

export default withStyles(
  combineStyles(LayoutStyles, ButtonStyles, TypographyStyles, SpacingStyles)
)(PointOfSaleDetail);
