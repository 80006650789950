import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import parseQuerystring from '../../../helpers/parseQuerystring';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import _get from 'lodash/get';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';

import { filterListFuzzyly } from '../../../helpers/utilityFunctions';

import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';

class FinancialDetailsFilters extends PureComponent {
  state = {
    category: '',
    plan: ''
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    this.setState({
      ...this.state,
      ...query
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter),
      'category',
      'plan'
    );
    return filterParams;
  };

  _getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label:
        item.name ||
        item.description ||
        _get(item, 'attributes.name') ||
        _get(item, 'attributes.description'),
      value: item.id
    }));
    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  render() {
    const { financialPlans, categories } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item sm={6} xs={12}>
                <AutocompleteInput
                  id="plan"
                  name="plan"
                  label="Plan de Financiación"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.plan || ''}
                  onChange={change => {
                    if (this.state.plan && !change) {
                      this._handleSelectChange({
                        target: { name: 'plan', value: '' }
                      });
                    }
                    this.setState({ plan: change });
                  }}
                  suggestions={financialPlans}
                  getSuggestions={value =>
                    this._getSuggestions('financialPlans', value)
                  }
                  onSuggestionSelected={plan => {
                    this._handleSelectChange({
                      target: { name: 'plan', value: plan.label }
                    });
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <AutocompleteInput
                  id="category"
                  name="category"
                  label="Categoria"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.category || ''}
                  onChange={change => {
                    if (this.state.category && !change) {
                      this._handleSelectChange({
                        target: { name: 'category', value: '' }
                      });
                    }
                    this.setState({ category: change });
                  }}
                  suggestions={categories}
                  getSuggestions={value =>
                    this._getSuggestions('categories', value)
                  }
                  onSuggestionSelected={category => {
                    this._handleSelectChange({
                      target: { name: 'category', value: category.label }
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

FinancialDetailsFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(FinancialDetailsFilters);
