import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Icon from '@material-ui/core/Icon';
import { Grid } from '@material-ui/core';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import ActivityIndicator from '../../shared/ActivityIndicator';
import Divider from '@material-ui/core/Divider';
import GridItem from '../../shared/GridItem';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const red_check_circle = require('../../../assets/icons/red-check-circle.svg');
const check_circle = require('../../../assets/icons/check-circle.svg');

const styles = theme  => ({
  iconRoot: {
    height: 'inherit',
  },

  imageIcon: {
    height: 'inherit',
    width: 'inherit',
    paddingBottom: '25px'
  },

  rowCardWrapper: {
    minHeight: '336px',
    marginLeft: '8px',
    marginRight: '8px',
    width: '100%',
  },

  statusIcon: {
    width: '42px',
    height: '42px',
    marginRight: '8px',
  }
});

export const DetailStatusCard = ({
  classes,
  title,
  messages,
  data,
  icon,
  isLoading
}) => (
  <div className={classnames(classes.wrapper, classes.rowCardWrapper)}>
    {isLoading && <ActivityIndicator className={classes.loadingIndicator} />}
    <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
      {data==='Ok' ? (
        <FiberManualRecordIcon
          className={classes.statusIcon} style={{color: '#00C853'}}
        />
      ) : (
        <FiberManualRecordIcon
          className={classes.statusIcon} style={{color: '#DB4945'}}
        />
      )}
      <div>
        <p className={classnames(classes.headline7)}>
          { 'Servicio' }
        </p>
        <h2 className={classnames(classes.headline4, classes.graphTitle)} style={{fontSize: '24px'}}>
          {title}
        </h2>
      </div>
    </div>
    <Divider />
    {data==='Ok' ? (
      <Grid container spacing={2} style={{display: 'flex', flexDirection: 'row', flex: 1, justifyContent:'flex-end'}}>
        <Grid item style={{display: 'flex', flexDirection: 'row'}}>
          <Icon classes={{ root: classes.iconRoot }}>
            <img className={classes.imageIcon} src={check_circle} />
          </Icon>
          <p style={{display: 'flex', color: '#00C853'}} className={classnames(classes.headline7)}>
            { 'Servicio estable' }
          </p>
       </Grid>
      </Grid>
      ) : (
        <Grid container spacing={2} style={{display: 'flex', flexDirection: 'row', flex: 1, justifyContent:'flex-end'}}>
          <Grid item style={{display: 'flex', flexDirection: 'row'}}>
            <Icon classes={{ root: classes.iconRoot }}>
              <img className={classes.imageIcon} src={red_check_circle} />
            </Icon>
            <p style={{display: 'flex', color: '#DB4945'}} className={classnames(classes.headline7)}>
              { 'No disponible en el momento' }
            </p>
          </Grid>
        </Grid>
      )
    }
    <div>
      {messages.map(m => (<p>{m}</p>))}
    </div>
  </div>
);

DetailStatusCard.protoTypes = {

}

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles)
)(DetailStatusCard);