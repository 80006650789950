import {
  SET_SCHEDULE_POINT_OF_SALE_CONFIGURATIONS_LIST_DATA,
  OPEN_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL,
  CLOSE_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL,
  SET_CATEGORIES_IN_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL
} from '../constants/schedule_point_of_sale_configuration_constants';
import { sendRequest, completeRequest } from './request_state';
import { serverStatusError, newAlert } from './alert_actions';
import { listSanitizer, createSanitizer, updateSanitizer, categoriesSanitizer } from '../helpers/sanitizers/schedulePointOfSaleConfigurationSanitizer';
import AxiosRequest from '../services/axios_request';

const axios = new AxiosRequest();

export const fetchSchedulePointOfSaleConfigurationList = params => (
  async dispatch => {
    dispatch(sendRequest('SchedulePointOfSaleConfigurationList'));
    try {
      const payload = await axios.get(
        '/schedule_point_of_sale_configurations',
        listSanitizer(params)
      );
      dispatch(setSchedulePointOfSaleConfigurationListData(payload));
    } catch (errors) {
      dispatch(serverStatusError(errors));
    } finally {
      dispatch(completeRequest('SchedulePointOfSaleConfigurationList'));
    }
  }
);

export const createSchedulePointOfSaleConfiguration = params => (
  async dispatch => {
    dispatch(sendRequest('SchedulePointOfSaleConfigurationCreate'));
    try {
      await axios.post(
        '/schedule_point_of_sale_configurations',
        createSanitizer(params.fields)
      );
      dispatch(_closeSchedulePointOfSaleConfigurationFormModal());
      await fetchSchedulePointOfSaleConfigurationList({ page: 1, rowsPerPage: params.rowsPerPage })(dispatch);
    } catch (payload) {
      dispatch(newAlert('error', 'ERROR:', payload.errors));
    } finally {
      dispatch(completeRequest('SchedulePointOfSaleConfigurationCreate'));
    }
  }
);

export const updateSchedulePointOfSaleConfiguration = params => (
  async dispatch => {
    dispatch(sendRequest('SchedulePointOfSaleConfigurationUpdate'));
    try {
      await axios.put(
        `/schedule_point_of_sale_configurations/${params.fields.id}`,
        updateSanitizer(params.fields)
      );
      dispatch(_closeSchedulePointOfSaleConfigurationFormModal());
      await fetchSchedulePointOfSaleConfigurationList({ page: params.page, rowsPerPage: params.rowsPerPage })(dispatch);
    } catch (payload) {
      dispatch(newAlert('error', 'ERROR:', Object.values(payload.errors).join(' ')));
    } finally {
      dispatch(completeRequest('SchedulePointOfSaleConfigurationUpdate'));
    }
  }
);

export const openSchedulePointOfSaleConfigurationFormModal = () => (
  dispatch => {
    dispatch(_openSchedulePointOfSaleConfigurationFormModal());
  }
);

export const closeSchedulePointOfSaleConfigurationFormModal = () => (
  dispatch => {
    dispatch(_closeSchedulePointOfSaleConfigurationFormModal());
  }
);

export const fetchCategories = () => (
  async dispatch => {
    dispatch(sendRequest('SchedulePointOfSaleConfigurationFormCategoriesList'));
    try {
      const payload = await axios.get(
        '/schedules/categories'
      );
      dispatch(setCategories(payload));
    } catch (errors) {
      dispatch(serverStatusError(errors));
    } finally {
      dispatch(completeRequest('SchedulePointOfSaleConfigurationFormCategoriesList'));
    }
  }
);

const setSchedulePointOfSaleConfigurationListData = payload => {
  return {
    type: SET_SCHEDULE_POINT_OF_SALE_CONFIGURATIONS_LIST_DATA,
    data: payload.data.schedule_point_of_sale_configurations,
    meta: payload.meta
  };
};

const _openSchedulePointOfSaleConfigurationFormModal = () => {
  return {
    type: OPEN_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL
  };
};

const _closeSchedulePointOfSaleConfigurationFormModal = () => {
  return {
    type: CLOSE_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL
  };
};

const setCategories = payload => {
  return {
    type: SET_CATEGORIES_IN_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL,
    data: categoriesSanitizer(payload.data)
  };
};