import PromissoryRanges from './PromissoryRanges';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchPromissoryRanges } from '../../../actions/promissory_actions';
import { fetchCorporationNames } from '../../../actions/corporations_actions';

function mapStateToProps(state) {
  return {
    ranges: state.promissory.ranges,
    rangesMeta: state.promissory.rangesMeta,
    canCreatePromissoryRanges:
      state.authentication.currentUser.canCreatePromissoryRanges,
    canUpdatePromissoryRanges:
      state.authentication.currentUser.canUpdatePromissoryRanges,
    requestSend: state.requestState.requestSend,
    corporations: state.corporations.corporationNames
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPromissoryRanges: bindActionCreators(fetchPromissoryRanges, dispatch),
    fetchCorporationNames: bindActionCreators(fetchCorporationNames, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PromissoryRanges);
