import {
  QUERY_PROMISSORIES,
  OPEN_QUERY_PROMISSORY_MODAL,
  CLOSE_QUERY_PROMISSORY_MODAL,
  RESET_QUERY_DATA,
  SET_AVAILABLE_QUOTA
} from '../constants/query_promissory_constants';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import RequestService from '../services/request_service';
import AxiosRequest from '../services/axios_request';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { history } from '../helpers/history';
import queryPromissoryFiltersSanitizer from '../helpers/sanitizers/queryPromissoryFilters';

const requestService = new RequestService();
const axiosRequest = new AxiosRequest();

export const openQueryPromissoryModal = () => {
  return dispatch => {
    dispatch({
      type: OPEN_QUERY_PROMISSORY_MODAL
    });
  };
};

export const closeQueryPromissoryModal = () => {
  return dispatch => {
    dispatch(errorOnRequest('QueryPromissories', null));
    dispatch({
      type: CLOSE_QUERY_PROMISSORY_MODAL
    });
  };
};

export const setAvailableQuota = availableQuota => {
  return dispatch => {
    dispatch({
      type: SET_AVAILABLE_QUOTA,
      availableQuota
    });
  };
};

export const resetQueryData = () => {
  return dispatch => {
    dispatch({
      type: RESET_QUERY_DATA
    });
  };
};

function setPromissories(data) {
  return {
    type: QUERY_PROMISSORIES,
    data
  };
}

export const queryPromissories = (params = {}) => async dispatch => {
  dispatch(sendRequest('QueryPromissories'));
  try {
    const payload = await axiosRequest.get(
      '/promissories/query',
      queryPromissoryFiltersSanitizer(params),
      true
    );
    dispatch(setPromissories(payload.data.promissories));
    dispatch(errorOnRequest('QueryPromissories', null));
  } catch (errors) {
    dispatch(errorOnRequest('QueryPromissories', errors));
    dispatch(setPromissories([]));
  } finally {
    dispatch(completeRequest('QueryPromissories'));
  }
};

export const fetchAvailableQuota = (contract, params) => async dispatch => {
  dispatch(sendRequest('QueryPromissoryAvailableQuota'));
  try {
    const payload = await axiosRequest.post(`/contracts/${contract}`, params);
    dispatch(setAvailableQuota(payload.data.available_quota));
  } catch (errors) {
  } finally {
    dispatch(completeRequest('QueryPromissoryAvailableQuota'));
  }
};