import React, { Fragment } from 'react';
import CollapseWrapper from '../../shared/CollapseWrapper';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import _compact from 'lodash/compact';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import collapseWrapperStyles from '../../../styles/components/collapseWrapper';
import { _nameFormat } from '../../../helpers/utilityFunctions';

export const RegistererDetail = ({ classes, sale, expanded = false }) => (
  <CollapseWrapper
    expanded={expanded}
    classes={{
      root: classes.root,
      header: classes.panelHeader,
      expandButton: classes.expandButton,
      bodyRoot: classes.collapsePanelBodyRoot
    }}
    header={() => (
      <div className={classes.titleWrapper}>
        <h2 className={classnames(classes.headline8, classes.title)}>
          {'Datos de Registro'}
        </h2>
      </div>
    )}
    body={
      <Fragment>
        <Divider />
        <div className={classes.body}>
          <Grid container spacing={16}>
            <Grid item lg={12}>
              <p className={classnames(classes.subtitle3)}>Proveedor:</p>
              <p className={classes.body2}>{_get(sale, 'supplier.name')}</p>
            </Grid>
            {sale.referencePointOfSale && <Grid item lg={12}>
              <p className={classnames(classes.subtitle3)}>Proveedor de Referencia:</p>
              <p className={classes.body2}>{_get(sale, 'referencePointOfSale.supplier.name')}</p>
            </Grid>}
            <Grid item lg={12}>
              <p className={classnames(classes.subtitle3)}>Punto de Venta:</p>
              <p className={classes.body2}>{_get(sale, 'pointOfSale.name')}</p>
            </Grid>
            {sale.referencePointOfSale && <Grid item lg={12}>
              <p className={classnames(classes.subtitle3)}>Punto de Venta de Referencia:</p>
              <p className={classes.body2}>{_get(sale, 'referencePointOfSale.name')}</p>
            </Grid>}
            <Grid item lg={12}>
              <p className={classnames(classes.subtitle3)}>Vendedor:</p>
              <p className={classes.body2}>
                {_compact([
                  _get(sale, 'salesman.firstName'),
                  _get(sale, 'salesman.lastName')
                ]).join(' ')}
              </p>
            </Grid>
            {sale.cardifSalesman && <Grid item lg={12}>
              <p className={classnames(classes.subtitle3)}>Vendedor Cardif:</p>
              <p className={classes.body2}>
                {_compact([
                  _get(sale, 'cardifSalesman.firstName'),
                  _get(sale, 'cardifSalesman.lastName')
                ]).join(' ')}
              </p>
            </Grid>}
            <Grid item lg={6}>
              <p className={classnames(classes.subtitle3)}>Código Externo:</p>
              <p className={classes.body2}>
                {sale.externalCode || <strong>En Proceso...</strong>}
              </p>
            </Grid>
            <Grid item lg={6}>
              <p className={classnames(classes.subtitle3)}>Registra:</p>
              <p className={classes.body2}>
                {`${_nameFormat(sale.registerer, 'firstName', 'lastName')}`}
              </p>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    }
  />
);
export default withStyles(
  combineStyles(
    collapseWrapperStyles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles
  )
)(RegistererDetail);
