import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Fade, Grid, TextField, withStyles } from '@material-ui/core';
import CustomDialog from '../../shared/Modals/CustomDialog';
import Toggle from '../../shared/Buttons/Toggle';
import CancelPartially from './CancelPartially/CancelPartially';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { mapReturnCancelCausals } from '../../../helpers/mappings/mapToOptions';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _get from 'lodash/get';

class CancelSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      observation: '',
      cancel_total: props.canRevokeArticlesSales && !props.canRevokeSales ? false : true,
      cause: -1,
      articles: []
    };
  }

  _handleSave = () => {
    const { sale, batch } = this.props;
    const { cancel_total, observation, cause, articles } = this.state;
    if (!batch) {
      if (cancel_total) {
        const params = {
          sale: {
            id: sale.id,
            cause: cause,
            observation: observation
          }
        };
        this.props.cancelSale(params);
      } else {
        const params = {
          sale: {
            id: sale.id,
            observation: observation,
            articles: articles
          }
        };
        this.props.cancelSale(params, 'revoke_articles');
      }
    } else {
      // TODO: ITERATIVE REQUESTS TO API
    }
  };

  _handleClose = () => {
    if (!this.props.requestSend) {
      this.props.toggleSaleAction('Cancel-Sale', false);
      this.setState({
        observation: '',
        cancel_total: true,
        cause: -1,
        articles: []
      });
    }
  };

  _handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  _handleActive = a => {
    this.setState({
      cancel_total: a,
      observation: '',
      cause: -1,
      articles: []
    });
  };
  _handleArticles = articles => {
    this.setState({ articles });
  };

  _disabledSubmit = cancel_total => {
    const { articles, cause } = this.state;
    if (cancel_total) {
      return cause == -1 ? true : false;
    }
    if (!cancel_total) {
      if (articles.length == 0) return true;
      const any = _find(
        articles,
        e => e.id == -1 || e.amount == -1 || e.cause == -1
      );
      return any ? true : false;
    }
    return false;
  };

  _getCausal = sale => {
    if (!sale) return [];
    const { cardif, cardifLayerTwoId } = this.props;
    const details = sale.saleDetails;
    const contains_cardif = _find(
      details,
      a => [cardif, cardifLayerTwoId].includes(a.article.id) && a.status == 'for_delivery'
    );
    const other_articles = _find(
      details,
      a => ![cardif, cardifLayerTwoId].includes(a.article.id) && a.status == 'for_delivery'
    );
    const returnCancelCausals = mapReturnCancelCausals(
      this.props.revokeSaleCausals,
      cardif
    );
    let filteredCausalList = returnCancelCausals;
    // CARDIFF & OTHER ARTICLES
    if (
      (contains_cardif && other_articles) ||
      (!contains_cardif && !other_articles)
    ) {
      filteredCausalList = _filter(returnCancelCausals, i => {
        if (i.only.length == 0 && i.allExcept.length == 0) return true;
        return false;
      });
    }
    // CARDIFF ONLY
    if (contains_cardif && !other_articles) {
      filteredCausalList = _filter(returnCancelCausals, i => {
        if (i.allExcept.includes(cardif) || i.allExcept.includes(cardifLayerTwoId)) return false;
        if (i.only.includes(cardif) || i.only.includes(cardifLayerTwoId)) return true;
        if (i.only.length == 0) return true;
        return false;
      });
    }
    // ARTICLES ONLY W/O CARFIFF
    if (!contains_cardif && other_articles) {
      filteredCausalList = _filter(returnCancelCausals, i => {
        if (i.allExcept.includes(cardif) || i.allExcept.includes(cardifLayerTwoId)) return true;
        if (i.only.length == 0) return true;
        return false;
      });
    }
    return mapListToOptions(filteredCausalList);
  };

  render() {
    const { open = false, requestSend, sale, batch, cardif, cardifLayerTwoId, secureSaleArticleId, canRevokeArticlesSales, canRevokeSales, b2bSelectedPos = [] } = this.props;
    const { observation, cancel_total, cause } = this.state;
    const isB2bSale = _get(sale, 'isB2b');
    const isSecureSale = sale ? sale.saleDetails.some(sd => sd.article.id === secureSaleArticleId) : false;

    return (
      <div>
        <CustomDialog
          open={open}
          loading={requestSend}
          disabled={requestSend || this._disabledSubmit(cancel_total)}
          disableBackdropClick={true}
          onClose={this._handleClose}
          onConfirm={this._handleSave}
          title={`Anulación ${batch ? 'total de ventas' : 'de venta'}`}
          contentText={`Para poder anular ${!canRevokeArticlesSales && !cancel_total ? 'parcialmente' : 'totalmente'
            } esta${batch ? 's' : ''} venta${batch ? 's' : ''}, debe escoger ${!cancel_total
              ? 'los artículos que desee anular, la cantidad y un causal de anulación para cada uno. Además,'
              : `un causal de anulación que aplicará a todos los artículos de esta${batch ? 's' : ''
              } y,`
            } si lo desea, podrá añadir una observación.`}
          buttonText={'ANULAR'}
          headerSecondary={
            canRevokeArticlesSales &&
            canRevokeSales && !batch && (
              <Toggle
                name="cancel_total"
                value={cancel_total}
                onChange={this._handleActive}
                inactiveLabel="Parcial"
                activeLabel="Total"
                disabled={isSecureSale || isB2bSale}
              />
            )
          }
        >
          <Grid container>
            {!cancel_total && (
              <CancelPartially
                sale={sale}
                setArticles={this._handleArticles}
                cardif={cardif}
                cardifLayerTwoId={cardifLayerTwoId}
                revokeSaleCausals={this.props.revokeSaleCausals}
                isB2bSale={isB2bSale}
              />
            )}
            {cancel_total && (
              <Fade in={true}>
                <Grid item xs={12}>
                  <SimpleSelect
                    required
                    label="Causal de Anulación"
                    name="cause"
                    options={this._getCausal(sale)}
                    value={cause}
                    onChange={this._handleChange}
                    disabled={false}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Fade>
            )}
            <Grid item xs={12}>
              <TextField
                label="Observación"
                name="observation"
                margin="normal"
                variant="outlined"
                onChange={this._handleChange}
                value={observation}
                disabled={requestSend}
                fullWidth
              />
            </Grid>
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = (/* theme */) => ({});

CancelSale.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(CancelSale);
