import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import classnames from 'classnames';
import _get from 'lodash/get';

const styles = theme => ({
  gridContainer: {
    '& b': {
      color: 'rgba(12,31,44,0.87)'
    }
  },
  itemValue: {
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  },
  column: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    '& p:nth-child(1)': {
      flex: '80%'
    },
    '& p:nth-child(2)': {
      flex: '20%',
      paddingLeft: 10
    }
  },
  divider: {
    width: '100%',
    margin: '5px 0'
  }
});

const Section = ({ classes, title, content, row = true }) => {
  return (
    <div className={row ? classes.row : classes.column}>
      <p className={classnames(classes.subtitle3)}>{title}</p>
      <p className={classnames(classes.body2, classes.itemValue)}>{content}</p>
    </div>
  );
};

const _getLabel = (list, value, defaultLabel) => {
  const result = list.find(item => item.value === value);
  return result ? result.label : defaultLabel;
};

const _checkBool = user => {
  return user.personalDataHandling === null ? 'Pendiente' : user.personalDataHandling ? 'Aceptada' : 'No Aceptada';
};

export const UserPreferences = ({
  classes,
  user,
  isLoading,
  onEditUser,
  canUpdateUser
}) => {
  return (
    <FormItemContainer
      title={'Autorización y tratamiento de datos personales'}
      secondaryAction={
        !isLoading && canUpdateUser ? (
          <Button
            size={'small'}
            color={'secondary'}
            className={classnames(classes.smallButton, classes.actionButtons)}
            classes={{
              label: classes.buttonLabel
            }}
            onClick={() => onEditUser(user.personalDataHandling)}
          >
            {'Editar'}
          </Button>
        ) : null
      }
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Grid container spacing={16} className={classes.gridContainer}>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'Autorización'}
              content={_checkBool(user)}
            />
          </Grid>
        </Grid>
      )}
    </FormItemContainer>
  );
};

export default withStyles(
  combineStyles(ButtonStyles, TypographyStyles, styles)
)(UserPreferences);
