import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import parseQuerystring from '../../../helpers/parseQuerystring';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import TextFieldInput from '../../shared/Inputs/TextField';
import OurDatePicker from '../../shared/CustomDatePicker';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import _find from 'lodash/find';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';

import { filterListFuzzyly } from '../../../helpers/utilityFunctions';

import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';
import moment from 'moment';

class UserFilters extends PureComponent {
  state = {
    category: '',
    supplier: '',
    geographicLocation: '',
    contractStratum: '',
    extraQuotumName: '',
    date: null
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    this.setState({
      ...this.state,
      ...query
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter),
      'category',
      'supplier',
      'geographicLocation',
      'contractStratum',
      'extraQuotumName',
      'date'
    );
    return filterParams;
  };

  _getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  render() {
    const { suppliers, categories } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="extraQuotumName"
                  name="extraQuotumName"
                  label="Nombre"
                  margin="normal"
                  variant="outlined"
                  value={this.state.extraQuotumName || ''}
                  onChange={this._handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <OurDatePicker
                  id="date"
                  name="date"
                  label="Fecha"
                  value={this.state.date}
                  onChange={date => {
                    const parsedDate =
                      date === null ? null : moment(date).format('YYYY/MM/DD');
                    const event = {
                      target: { value: parsedDate, name: 'date' }
                    };
                    this._handleChange(event);
                  }}
                  fullWidth
                  variant="outlined"
                  disableFuture={false}
                  invalidDateMessage="Fecha inválida"
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <AutocompleteInput
                  id="supplier"
                  name="supplier"
                  label="Aliado"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.supplier || ''}
                  onChange={change => {
                    if (this.state.supplier && !change) {
                      this._handleSelectChange({
                        target: { name: 'supplier', value: '' }
                      });
                    }
                    this.setState({ supplier: change });
                  }}
                  suggestions={suppliers}
                  getSuggestions={value =>
                    this._getSuggestions('suppliers', value)
                  }
                  onSuggestionSelected={supplier => {
                    this._handleSelectChange({
                      target: { name: 'supplier', value: supplier.label }
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <AutocompleteInput
                  id="category"
                  name="category"
                  label="Categoria"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.category || ''}
                  onChange={change => {
                    if (this.state.category && !change) {
                      this._handleSelectChange({
                        target: { name: 'category', value: '' }
                      });
                    }
                    this.setState({ category: change });
                  }}
                  suggestions={categories}
                  getSuggestions={value =>
                    this._getSuggestions('categories', value)
                  }
                  onSuggestionSelected={category => {
                    this._handleSelectChange({
                      target: { name: 'category', value: category.label }
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <AutocompleteInput
                  id="geographicLocation"
                  name="geographicLocation"
                  label="Ubicación Geografica"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.geographicLocation || ''}
                  onChange={change => {
                    if (this.state.geographicLocation && !change) {
                      this._handleSelectChange({
                        target: { name: 'geographicLocation', value: '' }
                      });
                    }
                    this.setState({ geographicLocation: change });
                  }}
                  suggestions={this.props.geographicLocations}
                  getSuggestions={value =>
                    this._getSuggestions('geographicLocations', value)
                  }
                  onSuggestionSelected={geographicLocation => {
                    this._handleSelectChange({
                      target: {
                        name: 'geographicLocation',
                        value: geographicLocation.label
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <AutocompleteInput
                  id="contractStratum"
                  name="contractStratum"
                  label="Estrato Social"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.contractStratum || ''}
                  onChange={change => {
                    if (this.state.contractStratum && !change) {
                      this._handleSelectChange({
                        target: { name: 'contractStratum', value: '' }
                      });
                    }
                    this.setState({ contractStratum: change });
                  }}
                  suggestions={this.props.contractStrata}
                  getSuggestions={value =>
                    this._getSuggestions('contractStrata', value)
                  }
                  onSuggestionSelected={contractStratum => {
                    this._handleSelectChange({
                      target: {
                        name: 'contractStratum',
                        value: contractStratum.label
                      }
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
UserFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(UserFilters);
