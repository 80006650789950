import React from 'react';
import Grid from '@material-ui/core/Grid';
import AvatarLayout from './AvatarLayout';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/styles';

const brillaLogo = require('../../../assets/images/brilla-logo.png');

const AppNavBar = props => {
  const { classes, logout, currentUser } = props;
  return (
    <Grid container justify='space-between' className={classes.toolbar}>
      <Grid item>
        <img
          className={classes.header}
          src={brillaLogo}
        />
      </Grid>
      {
        !!currentUser && (
          <Grid item>
            <AvatarLayout
              currentUser={currentUser}
              logout={logout}
            />
          </Grid>
        )
      }
    </Grid>
  );
};

export default withStyles(
  styles, { withTheme: true }
)(AppNavBar);