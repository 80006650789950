import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _find from 'lodash/find';
import _compact from 'lodash/compact';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import Grid from '@material-ui/core/Grid';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import ProfileStyles from './__styles__/Profile.styles';

export const Profile = ({ classes, corporation, isLoading }) => {
  return (
    <FormItemContainer title={'Información de la empresa'}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className={classes.root}>
          <div className={classes.details}>
            <Grid container spacing={16} className={classes.data}>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Nombre de la empresa:'}</p>
                <p className={classes.body2}>{corporation.description || '--'}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Estado:'}</p>
                <p className={classes.body2}>
                  {corporation.active ? 'ACTIVO' : 'INACTIVO'}
                </p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Tipo de identificación:'}</p>
                <p className={classes.body2}>
                  {corporation.kindOfIdentification || '--'}
                </p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Identificación:'}</p>
                <p className={classes.body2}>
                  {corporation.identification || '--'}
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </FormItemContainer>
  );
};

export default withStyles(
  combineStyles(ProfileStyles, ButtonStyles, TypographyStyles)
)(Profile);
