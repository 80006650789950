import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _isNumber from 'lodash/isNumber';

import { withStyles } from '@material-ui/core/styles';
import ActivityItem from './Item';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 1.5,
    marginBottom: theme.spacing.unit * 2
  }
});

export class ActivityList extends PureComponent {
  static propTypes = {
    displayCount: PropTypes.number,
    activities: PropTypes.array.isRequired
  };

  render() {
    const { classes, activities, displayCount } = this.props;

    const activitiesToDisplay = _isNumber(displayCount)
      ? activities.slice(0, displayCount)
      : activities;

    return (
      <div className={classes.root}>
        {activitiesToDisplay.map((activity, index) => {
          return <ActivityItem key={index} activity={activity} />;
        })}
      </div>
    );
  }
}
export default withStyles(styles)(ActivityList);
