import _get from 'lodash/get';

export const salesColumns = [
  {
    title: '# Venta',
    name: 'id'
  },
  {
    title: 'Aliado',
    name: 'supplierName'
  },
  {
    title: 'Estado',
    name: 'state'
  }
];

export const salesMapData = (data, currentUser) =>
  data.map(datum => {
    const ownSale = datum.supplierName === _get(currentUser, 'supplier.name');
    return {
      ...datum,
      disableRowHover: !ownSale,
      canViewSale: ownSale
    };
  });
