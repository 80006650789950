import _pickBy from 'lodash/pickBy';

export const financialPlanFilterSanitizer = filters => {
  const filtersObj = filters;

  if (filtersObj.description) {
    filtersObj['description'] = encodeURI(filtersObj.description);
  }

  return _pickBy(filtersObj, filter => !!filter || filter === false);
};
