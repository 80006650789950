import { LIST_SCORING_RESPONSES } from '../constants/action_constants';
import { newAlert } from './alert_actions';
import RequestService from '../services/request_service';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { scoringConsultsListSanitizer } from '../helpers/sanitizers/scoringSanitizer';
import { history } from '../helpers/history';

const requestService = new RequestService();

function setScoringList(items, pagi) {
  return {
    type: LIST_SCORING_RESPONSES,
    data: items,
    meta: pagi
  };
}

export const fetchScoringList = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/scoring_responses?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get(
      'scoring_responses',
      scoringConsultsListSanitizer(params)
    );
    dispatch(setScoringList(payload.data, payload.meta));
  } catch (errors) {
    console.log(errors);
    dispatch(errorOnRequest());
    errors.json().then(e => {
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    });
  } finally {
    dispatch(completeRequest());
  }
};
