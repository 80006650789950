import _pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

export const movementsFilterSanitizer = values => {
  const {
    page,
    perPage,
    contractId,
    productId,
    created_at,
    action,
    username
  } = values;
  const sanitizedFilters = {
    page,
    perPage,
    contractId,
    productId,
    action,
    username
  };
  const created_at_range = created_at ? created_at.split(' - ') : [];


  if (created_at_range.length > 1) {
    sanitizedFilters.createdAtGteq = moment(
      created_at_range[0]
    ).format();
    sanitizedFilters.createdAtLteq = moment(created_at_range[1])
      .endOf('day')
      .format();
  } else if (created_at_range.length > 0 && created_at != "todas") {
    sanitizedFilters.createdAtGteq = moment(
      created_at_range[0]
    ).format();
    sanitizedFilters.createdAtLteq = moment(created_at_range[0])
      .endOf('day')
      .format();
  }

  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};

export const secureProductSanitizer = values => {
  const sanitizedFilters = {
    willBlock: values.block != 'Y' ? true : false,
    contractId: Number(values.body.contract_id),
    productId: Number(values.body.product_id)
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return params;
};