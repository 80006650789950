import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Checkbox from '../../shared/Inputs/Checkbox';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { doctypes } from '../../../constants/enums';
import { Formik } from 'formik';
import { validationSchema } from './QueryScoringQuotaValidation';
import CustomDialog from '../../shared/Modals/CustomDialog';
import formatMoney from '../../../helpers/formatMoney';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import OurDatePicker from '../../shared/CustomDatePicker';
import OtpValidationField from '../../shared/Fields/OtpValidationField';

class QueryScoringQuota extends PureComponent {
  onSubmit = values => {
    this.props.queryScoringQuotas({ ...values, mobileConfirmedAt: this.props.otpMobileConfirmedAt});
  };

  _handleOnClose = () => {
    this.props.resetQueryScoringData();
    this.props.closeQueryScoringQuotaModal();
  };

  _onKeyPress = event => {
    event.key === 'Enter' && event.preventDefault();
  };

  handleCheckBox(values, setFieldValue) {
    setFieldValue('hasCoDebtor', !values.hasCoDebtor);
    if (!values.hasCoDebtor) {
      setFieldValue('coDebtorContract', '');
      setFieldValue('coDebtorIdentification', '');
      setFieldValue('coDebtorDocumentExpeditionDate', null);
      setFieldValue('coDebtorBirthday', null);
    }
  }

  renderResponse() {
    const {
      categoriesUsedToScoringMaximumValue,
      serverErrors = [],
      classes,
      quotas = {}
    } = this.props;
    if (serverErrors.length > 0) {
      return (
        <div className={classes.errors}>
          <p>{serverErrors}</p>
        </div>
      );
    }

    const categoriesExist = categoriesUsedToScoringMaximumValue && categoriesUsedToScoringMaximumValue.length !== 0;

    return (
      <div>
        <br></br>
        <div className={classes.extraQuotaItem}>
          <p className={classes.dataRegister}>El cupo total utilizable con Scoring es de <b>{formatMoney(quotas.new_available_quota)}</b>.</p>
        </div>
      </div>
    );
  }

  render() {
    const {
      classes,
      loading,
      quotas = {},
      isOpen,
      hasSearched,
      requestOtpSend,
      requestValidateOtpSend,
      otpSent,
      mobileValidated,
      canVerifyPhoneCode
    } = this.props;
    return (
      isOpen && (
        <Formik
          initialValues={{
            queryType: 'contract',
            hasCoDebtor: false,
            contract: '',
            coDebtorContract: '',
            identificationType: 'Cédula',
            coDebtorIdentificationType: 'Cédula',
            identification: '',
            coDebtorIdentification: '',
            debtorBirthday: null,
            coDebtorBirthday: null,
            debtorDocumentExpeditionDate: null,
            coDebtorDocumentExpeditionDate: null,
            mobile: ''
          }}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          onReset={(values, actions) => {
            actions.resetForm();
            this.props.resetOtpInfoResponse();
            this._handleOnClose();
          }}
          render={({
            values,
            errors,
            touched,
            dirty,
            handleSubmit,
            handleChange,
            handleReset,
            setFieldValue
          }) => (
            <CustomDialog
              onClose={handleReset}
              onConfirm={handleSubmit}
              loading={loading}
              open={isOpen}
              title={'Consulta de Scoring'}
              buttonText={'Consultar'}
              disabled={(Object.keys(errors).length > 0 ? true : false) || !dirty || (values.mobile.toString().length && !mobileValidated)}
              classes={{
                root: classes.queryQuotaDialogRoot,
                content: classes.queryQuotaDialogContent
              }}
            >
              <form autoComplete="off" className={classes.form}>
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item sm={6} xs={12} className={classes.inputGrid}>
                    <OutlinedTextField
                      onKeyPress={this._onKeyPress}
                      label="Numero de contrato deudor:"
                      name="contract"
                      type={'number'}
                      error={
                        (touched.contract && !!errors.contract)
                      }
                      helperText={
                        (touched.contract && errors.contract)
                      }
                      value={values.contract}
                      onChange={(e) => {
                        this.props.resetOtpInfoResponse();
                        handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} className={classes.inputGrid}>
                    <SimpleSelect
                      value={values.identificationType}
                      onChange={(e) => {
                        this.props.resetOtpInfoResponse();
                        handleChange(e);
                      }}
                      name="identificationType"
                      label="Tipo de identificación del deudor:"
                      options={mapListToOptions(doctypes, {}, true)}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} className={classes.inputGrid}>
                    <OutlinedTextField
                      onKeyPress={this._onKeyPress}
                      type={'number'}
                      label="Identificación del deudor:"
                      name="identification"
                      error={
                        (touched.identification &&
                          !!errors.identification)
                      }
                      helperText={
                        (touched.identification &&
                          errors.identification)
                      }
                      value={values.identification}
                      onChange={(e) => {
                        this.props.resetOtpInfoResponse();
                        handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} className={classes.inputGrid}>
                    <OurDatePicker
                      name={'debtorBirthday'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="Fecha inválida"
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha nacimiento deudor:"
                      value={values.debtorBirthday}
                      onChange={e => {
                        setFieldValue('debtorBirthday', e);
                        this.props.resetOtpInfoResponse();
                      }}
                      onError={() => setFieldValue('debtorBirthday', null)}
                      error={
                        (touched.debtorBirthday && !!errors.debtorBirthday)
                      }
                      helperText={
                        (touched.debtorBirthday && errors.debtorBirthday)
                      }
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} className={classes.inputGrid}>
                    <OurDatePicker
                      name={'debtorDocumentExpeditionDate'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="Fecha inválida"
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha expedición documento deudor:"
                      value={values.debtorDocumentExpeditionDate}
                      onChange={e => {
                        setFieldValue('debtorDocumentExpeditionDate', e);
                        this.props.resetOtpInfoResponse();
                      }}
                      onError={() => setFieldValue('debtorDocumentExpeditionDate', null)}
                      error={
                        (touched.debtorDocumentExpeditionDate && !!errors.debtorDocumentExpeditionDate)
                      }
                      helperText={
                        (touched.debtorDocumentExpeditionDate && errors.debtorDocumentExpeditionDate)
                      }
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  { canVerifyPhoneCode && (
                    <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OtpValidationField
                          onKeyPress={this._onKeyPress}
                          type='number'
                          label="Teléfono movil(opcional):"
                          name="mobile"
                          errors={errors}
                          value={values.mobile}
                          onChange={handleChange}
                          sendOtp={this.props.sendOtp}
                          validateOtp={this.props.validateOtp}
                          mobile={values.mobile}
                          requestOtpSend={requestOtpSend}
                          requestValidateOtpSend={requestValidateOtpSend}
                          otpSent={otpSent}
                          mobileValidated={mobileValidated}
                          resetOtpInfoResponse={this.props.resetOtpInfoResponse}
                          entityType={'scoring_consult'}
                          termsAndConditionsUrl={this.props.termsAndConditionsUrl}
                          onInput={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 10);
                          }}
                        />
                    </Grid>
                  )}

                  <Grid xs={12} className={classes.Checkbox}>
                    {((hasSearched && quotas.cosigner_is_required) && !values.hasCoDebtor) && (
                      <Grid>
                        <span className={classes.tag}>
                          {'Por politica se requiere codeudor obligatorio'}
                        </span>
                      </Grid>
                    )}
                    <Checkbox
                      name={'hasCoDebtor'}
                      value={values.hasCoDebtor}
                      label={'Codeudor'}
                      disabled={loading}
                      onChange={() =>
                        this.handleCheckBox(values, setFieldValue)
                      }
                      customLabelRoot={true}
                    />
                  </Grid>
                  {values.hasCoDebtor && (
                    <React.Fragment>
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OutlinedTextField
                          onKeyPress={this._onKeyPress}
                          label="Numero de contrato codeudor:"
                          name="coDebtorContract"
                          type={'number'}
                          error={
                            (touched.coDebtorContract && !!errors.coDebtorContract)
                          }
                          helperText={
                            (touched.coDebtorContract && errors.coDebtorContract)
                          }
                          value={values.coDebtorContract}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <SimpleSelect
                          value={values.coDebtorIdentificationType}
                          onChange={handleChange}
                          name="coDebtorIdentificationType"
                          label="Tipo de identificación del codeudor:"
                          options={mapListToOptions(doctypes, {}, true)}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OutlinedTextField
                          onKeyPress={this._onKeyPress}
                          type={'number'}
                          label="Identificación del codeudor:"
                          name="coDebtorIdentification"
                          error={
                            (touched.coDebtorIdentification &&
                              !!errors.coDebtorIdentification)
                          }
                          helperText={
                            (touched.coDebtorIdentification &&
                              errors.coDebtorIdentification)
                          }
                          value={values.coDebtorIdentification}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OurDatePicker
                          name={'coDebtorBirthday'}
                          invalidLabel="Fecha inválida"
                          maxDateMessage="Fecha inválida"
                          minDateMessage="Fecha inválida"
                          invalidDateMessage={'Fecha inválida'}
                          label="Fecha de nacimiento codeudor:"
                          value={values.coDebtorBirthday}
                          onChange={e => {
                            setFieldValue('coDebtorBirthday', e);
                          }}
                          onError={() => setFieldValue('coDebtorBirthday', null)}
                          error={
                            (touched.coDebtorBirthday && !!errors.coDebtorBirthday)
                          }
                          helperText={
                            (touched.coDebtorBirthday && errors.coDebtorBirthday)
                          }
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OurDatePicker
                          name={'coDebtorDocumentExpeditionDate'}
                          invalidLabel="Fecha inválida"
                          maxDateMessage="Fecha inválida"
                          minDateMessage="Fecha inválida"
                          invalidDateMessage={'Fecha inválida'}
                          label="Fecha expedición documento codeudor:"
                          value={values.coDebtorDocumentExpeditionDate}
                          onChange={e => {
                            setFieldValue('coDebtorDocumentExpeditionDate', e);
                          }}
                          onError={() => setFieldValue('coDebtorDocumentExpeditionDate', null)}
                          error={
                            (touched.coDebtorDocumentExpeditionDate && !!errors.coDebtorDocumentExpeditionDate)
                          }
                          helperText={
                            (touched.coDebtorDocumentExpeditionDate && errors.coDebtorDocumentExpeditionDate)
                          }
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
                {(hasSearched && !loading) && (
                  <Grid xs={12}>
                    {this.renderResponse()}
                  </Grid>
                )}
              </form>
            </CustomDialog>
          )}
        />
      )
    );
  }
}

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 0
  },
  Checkbox: {
    marginLeft: '12px',
    marginBottom: '12px',
    marginTop: '12px'
  },
  notFound: {
    marginTop: 10,
    color: 'red'
  },
  dataContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '5px 0px'
  },
  dataRegister: {
    margin: '2.5px 0px'
  },
  extraQuotaItem: {
    marginTop: 10,
    borderTop: 'solid 1px #BCBCBC',
    padding: 10,
    width: '100%',
  },
  errors: {
    marginTop: '30px',
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    marginRight: 0,
    padding: '6px 8px',
    width: 100
  },
  queryQuotaDialogRoot: {
    ['@media (max-width:600px)']: {
      width: '95%',
      margin: '0 24px'
    }
  },
  queryQuotaDialogContent: {
    overflowX: 'hidden',
    overflowY: 'auto',
    minHeight: '340px',
  },
  inputGrid: {
    padding: '6px 12px 6px 12px !important',
    ['@media (max-width:600px)']: {
      padding: '0 12px !important'
    }
  },
  grayContainer: {
    backgroundColor: '#EFF1F4',
    marginBottom: '5%',
    padding: 20
  },
  title: {
    color: '#111'
  },
  tag: {
    margin: 0,
    fontSize: '13px',
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: 'inline-block',
    padding: [[theme.spacing.unit * 0.5, theme.spacing.unit * 1]],
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#fe0b0b',
    fontWeight: 'bold',
    '&:last-child': {
      marginRight: 0
    }
  }
});

QueryScoringQuota.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(QueryScoringQuota);
