const styles = theme => ({
  title: {
    marginBottom: `${theme.spacing.unit * 5}px !important`
  },
  chartsRow: {
    marginTop: theme.spacing.unit * 2.75,
    flexGrow: 1,
    paddingLeft: theme.spacing.unit * 0.5,
    paddingRight: theme.spacing.unit * 0.5
  },
  pickedUpShipments: {
    marginRight: theme.spacing.unit * 1.375
  },
  topCarriers: {
    marginLeft: theme.spacing.unit * 1.375
  },
  graphTitle: {
    marginBottom: theme.spacing.unit * 3
  },
  donutGraphs: {
    marginTop: theme.spacing.unit * 7.5,
  },
  donutGraph: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  donutGraphItems: {
    padding: 16,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  donutGraphLegends: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16
  },
  pickedUpShipmentsGraph: {
    [theme.breakpoints.up('lg')]: {
      marginRight: '2%'
    }
  },
  gridItem: {
    padding: `${theme.spacing.unit * 3}px !important`,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '49%'
    },
    boxShadow: '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
  },
  rowCardWrapper: {
    margin: '0px !important'
  },
  loadingIndicator: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 1,
  }
});

export default styles;
