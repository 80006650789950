const styles = theme => ({
  root: {},
  formControl: {
    width: '100%'
  },
  select: {
    padding: '8px 14px',
    minHeight: 39,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    '&:focus': {
      background: 'none'
    }
  },
  selectWithChip: {
    padding: '8px 12px'
  },
  icon: {
    right: 8
  },
  menuItemRoot: {
    color: 'rgba(12,31,44,0.87)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    backgroundColor: '#FFF !important',
    height: '40px',
    padding: '0 9px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'whitesmoke !important'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    backgroundColor: 'rgba(254,176,11,0.12)',
    margin: 0,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 0.5,
    marginBottom: theme.spacing.unit * 0.5,
    padding: '6px 35px 6px 12px',
    position: 'relative',
    '&:last-child': {
      marginRight: 0
    },
    '&:focus': {
      backgroundColor: 'rgba(254,176,11,0.12)'
    }
  },
  chipLabel: {
    margin: 0,
    padding: 0,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    textTransform: 'capitalize'
  },
  chipIcon: {
    backgroundColor: theme.palette.secondary.main,
    color: '#E0F4FF',
    borderRadius: '50%',
    fontSize: '15px',
    position: 'absolute',
    pointerEvents: 'all',
    right: '5px',
    '&:hover': {
      color: '#E0F4FF'
    }
  },
  menuItemSelected: {
    backgroundColor: '#ebebeb !important'
  },
  formHelperTextRoot: {
    margin: '3px 0 0 16px',
    color: '#F22D12',
    fontFamily: theme.typography.font.main,
    fontSize: '0.75rem',
    letterSpacing: '0.1px',
    lineHeight: '1em',
    minHeight: '1em'
  },
  outlinedInput: {
    '&$focused $notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
      borderWidth: 2
    }
  },
  label: {
    color: 'rgba(12,31,44,0.6)',
    '&$focused': {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  focused: {},
  notchedOutline: {}
});

export default styles;
