import React, { PureComponent, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import ChippedAutocompleteInput from '../../../shared/Inputs/ChippedAutocomplete';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  error: {
    color: '#ff0000',
    fontSize: 'smaller'
  },
  password: {
    fontWeight: 700
  }
});

export class ResetPasswordModal extends PureComponent {
  state = {
    error: false,
    errorMessage: '',
    newPassword: ''
  };

  render() {
    const {
      classes,
      user,
      open = true,
      closeModal,
      sendChangePasswordByAdmin
    } = this.props;

    const { newPassword } = this.state;

    return (
      <Modal
        open={open}
        classes={{ footer: classes.modalFooter }}
        onBackdropClick={() => {
          closeModal();
          this.setState({ newPassword: '' });
        }}
        body={
          <Fragment>
            <div className={classes.titleWrapper}>
              <p className={classnames(classes.headline6, classes.title)}>
                {'Restablecer contraseña'}
              </p>
            </div>
            <Grid container justify='center' spacing={16}>
              {newPassword ? (
                <GridItem xs={12}>
                  <p>La nueva contraseña del usuario es: <span className={classes.password}>{this.state.newPassword}</span></p>
                </GridItem>
              ) : (
                <>
                  <GridItem xs={6}>
                    <ProgressButton
                      className={classnames(classes.button, classes.action)}
                      classes={{ label: classes.buttonLabel }}
                      variant="contained"
                      color="secondary"
                      onClick={async () => {
                        const response = await sendChangePasswordByAdmin({ userId: user.id }, true);

                        if (response.newPassword) {
                          this.setState({ newPassword: response.newPassword });
                        }
                      }}
                    >
                      {'Generar nueva contraseña'}
                    </ProgressButton>
                  </GridItem>
                  <GridItem xs={6}>
                    <ProgressButton
                      className={classnames(classes.button, classes.action)}
                      classes={{ label: classes.buttonLabel }}
                      variant="outlined"
                      color="secondary"
                      onClick={() => this.props.sendResetPassword(user.email)}
                    >
                      {'Restablecer contraseña al correo'}
                    </ProgressButton>
                  </GridItem>
                </>
              )}
            </Grid>
          </Fragment>
        }
        footer={
          <Fragment>
            <div className={classes.spacer} />
            <ProgressButton
              onClick={() => {
                closeModal();
                this.setState({ newPassword: '' });
              }}
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              color={'secondary'}
            >
              {'Cerrar'}
            </ProgressButton>
          </Fragment>
        }
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(ResetPasswordModal);
