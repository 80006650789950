import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';
import classnames from 'classnames';

const FieldValidationStyles = theme => ({
  messageBody: {
    padding: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 2,
    position: 'relative',
    top: '-13px'
  },
  popper: {
    zIndex: 1500
  }
});
export class FieldValidation extends PureComponent {
  static propTypes = {
    message: PropTypes.string,
    anchorEl: PropTypes.instanceOf(Element)
  };

  render() {
    const { message, anchorEl, classes } = this.props;

    return (
      <Popper
        open={Boolean(anchorEl) && Boolean(message)}
        anchorEl={anchorEl}
        placement={'top-start'}
        className={classes.popper}
      >
        <Paper
          classes={{ root: classnames(classes.messageBody, classes.body2) }}
        >
          {message}
        </Paper>
      </Popper>
    );
  }
}

export default withStyles(
  combineStyles(FieldValidationStyles, TypographyStyles)
)(FieldValidation);
