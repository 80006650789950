import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RemoteSaleEnrollmentOTP from './RemoteSaleEnrollmentOTP';
import { newAlert } from '../../../actions/alert_actions';
import { setNextStep, checkEnrollmentOTP } from '../../../actions/portal_financing_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import { sendOtp, resetOtpInfoResponse } from '../../../actions/otp_validation_actions';
import _get from 'lodash/get';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  const debtor = pf.promissory ? _get(pf, 'promissory.debtor') : _get(pf, 'tempPromissory.debtor');
  return {
    process: state.stepper.process,
    routes: state.stepper.routes,
    remoteDigitalSaleWithUUPNext: state.stepper.remoteDigitalSaleWithUUPNext,
    remoteDigitalSaleWithoutUUPNext: state.stepper.remoteDigitalSaleWithoutUUPNext,
    canSendWhatsappOtpValidationMessage: state.authentication.currentUser.canSendWhatsappOtpValidationMessage,
    mobile: debtor.mobile || '',
    termsAndConditionsUrl: state.globalSetting.orgData.terms_and_conditions_url,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    sendOtp: bindActionCreators(sendOtp, dispatch),
    checkEnrollmentOTP: bindActionCreators(checkEnrollmentOTP, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoteSaleEnrollmentOTP);
