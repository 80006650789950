import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  cosignerPresent: Yup.boolean(),
  debtorExpeditionDate: Yup.string()
    .required('El campo es requerido')
    .nullable(),
  cosignerExpeditionDate: Yup.string()
    .nullable()
    .when('cosignerPresent', {
      is: true,
      then: Yup.string()
        .required('El campo es requerido')
        .nullable()
    }),
    cosignerContractNumber: Yup.string()
    .when('cosignerPresent', {
      is: true,
      then: Yup.string()
        .required('El campo es requerido')
        .nullable()
  }).nullable()
});
