import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  financialPlan: Yup.number()
    .min(1, 'Ingrese un plan de financiación válido.')
    .required('El campo es requerido')
    .nullable(),
  category: Yup.number()
    .min(1, 'Ingrese una categoría válida.')
    .required('El campo es requerido')
    .nullable()
});

export default validationSchema;
