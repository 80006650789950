const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    width: '100%',
    marginBottom: theme.spacing.unit * 3
  },
  panelHeader: {
    padding: theme.spacing.unit * 3
  },
  expandButton: {
    marginTop: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    top: -6
  },
  collapsePanelBodyRoot: {
    marginTop: '0 !important',
    paddingTop: '0 !important'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    flex: 'none',
    marginRight: [theme.spacing.unit * 2, '!important'],
  },
  titleContentDivider: {
    borderTop: '1px solid rgba(12,31,44,0.1)'
  },
  body: {
    padding: theme.spacing.unit * 3
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  }
});

export default styles;
