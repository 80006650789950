import { LIST_GEOGRAPHIC_LOCATIONS } from '../constants/geographic_location_constants';

const initialState = {
  list: [],
};

export function geographicLocation(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case LIST_GEOGRAPHIC_LOCATIONS:
      return {
        ...state,
        list: action.data,
      };
    default:
      return state;
  }
}
