import React, { PureComponent, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import ChippedAutocompleteInput from '../../../shared/Inputs/ChippedAutocomplete';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  error: {
    color: '#ff0000',
    fontSize: 'smaller'
  }
});

export class EditRolesModal extends PureComponent {
  state = {
    error: false,
    errorMessage: ''
  };
  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  _handleOnSave = data => {
    const { onSave } = this.props;
    onSave(data.roles.map(roles => roles.value));
  };

  _getSuggestions = async (value, roles) => {
    const currentItemsSelected = roles.map(r => r.value);
    const items = this.props.roles.filter(
      roles => !currentItemsSelected.includes(roles.value)
    );

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  _limitNumberOfRoles = async (evt, handleChange, roles) => {
    const copyEvt = { ...evt };
    const { signInRole } = this.props;
    const {
      target: { value, type }
    } = evt;
    const signIn = [
      {
        value: String(signInRole.id),
        label: signInRole.displayName
      }
    ];
    const roleSelected = value[value.length - 1];
    const includeSignIn =
      roles.filter(roles => roles.value == signInRole.id).length > 0;
    if (type == 'delete') {
      if (
        this.props.canListSalesmanRoles &&
        !this._isAdmin() &&
        includeSignIn
      ) {
        let ids = evt.target.value.map(role => role.value);
        if (!signIn.some(v => ids.includes(v.value))) {
          this.setState({
            error: true,
            errorMessage: 'No tiene permisos para eliminar rol'
          });
        } else {
          this.setState({
            error: false
          });
          roles = value ? value : null;
        }
      } else {
        roles = value ? value : null;
      }
    } else {
      if (!roles.includes(roleSelected)) {
        if (
          (includeSignIn && roles.length > 1) ||
          (!includeSignIn && roles.length == 1 && roleSelected != signIn)
        ) {
          roles.pop();
          roles.push(roleSelected);
        } else {
          roleSelected.value == signInRole.id
            ? (roles = [roleSelected, ...roles])
            : roles.push(roleSelected);
        }
      }
    }
    copyEvt.target = { value: roles, name: 'roles' };
    handleChange(copyEvt);
  };

  _isAdmin() {
    let currentUserRoles = [];
    this.props.currentUser.roles.map(role => {
      currentUserRoles.push(role.name);
    });

    if (this.props.adminRoles.some(r => currentUserRoles.includes(r))) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { classes, open = true, isSubmitting, currentRoles } = this.props;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          roles: currentRoles
        }}
        onSubmit={async values => {
          this._handleOnSave(values);
          this._handleOnClose();
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({ values, handleChange, handleSubmit, handleReset }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              footer: classes.modalFooter
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {'Editar Roles'}
                  </p>
                </div>
                <Grid container spacing={16}>
                  <GridItem xs={12}>
                    <Field
                      name="roles"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <ChippedAutocompleteInput
                            label={'Roles'}
                            name={'roles'}
                            getSuggestions={value =>
                              this._getSuggestions(value, values.roles)
                            }
                            values={values.roles}
                            onChange={evt => {
                              this._limitNumberOfRoles(
                                evt,
                                handleChange,
                                values.roles
                              );
                            }}
                            field={field}
                            form={form}
                            margin="normal"
                          />
                        );
                      }}
                    />
                    {this.state.error && (
                      <span className={classes.error}>
                        {this.state.errorMessage}
                      </span>
                    )}
                  </GridItem>
                </Grid>
              </Fragment>
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  isSubmitting={isSubmitting}
                >
                  {'Guardar'}
                </ProgressButton>
              </Fragment>
            }
          />
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(EditRolesModal);
