import { CATEGORIES_SET, CATEGORY_SAVED } from '../constants/action_constants';

const initialState = {
  categories: [],
  meta: {},
  saved: false
};

export function category(state = initialState, action) {
  switch (action.type) {
    case CATEGORIES_SET:
      return {
        ...state,
        categories: action.data,
        meta: action.meta,
        saved: false
      };
    case CATEGORY_SAVED:
      return {
        ...state,
        saved: true
      };
    default:
      return state;
  }
}
