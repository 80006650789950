import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import GridItem from '../../shared/GridItem';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import * as Yup from 'yup';
import { AppBar, Grid, CssBaseline, Paper, Tooltip } from '@material-ui/core';
import {
  initialValues,
  validationSchema
} from '../../shared/ChangePassword.helpers';
import PasswordInput from '../../shared/PasswordInput';
import classnames from 'classnames';
import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';
import UserUpdatePasswordStyles from './styles/UserUpdatePassword.styles';

const brillaLogo = require('../../../assets/images/brilla-logo.png');

class UserUpdatePassword extends Component {
  state = {
    email: '',
    username: '',
    token: '',
    errors: null
  };

  componentDidMount = () => {
    this._validateUserToken();
  };

  _validateUserToken = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('confirmation_token');
    this.props.validateUserToken(token).then(isCorrect => {
      if (isCorrect.errors) {
        this.setState({
          errors: isCorrect.errors
        });
      } else {
        this.setState({
          email: isCorrect.email,
          username: isCorrect.username,
          token: token
        });
      }
    });
  };

  goOut = () => {
    this.props.logout();
  };

  handleSubmit = values => {
    const token = this.state.token;
    // this.props
    //   .updatePasswordWithToken({
    //     user: {
    //       token: token,
    //       password: values['password'],
    //       passwordConfirmation: values['password_confirmation']
    //     }
    //   })
    //   .then(isCorrect => {
    //   if (isCorrect) {
    //     this.props.confirmUserEmail(
    //       {
    //         confirmationToken: token,
    //         user: {
    //           password: values['password'],
    //           passwordConfirmation: values['password_confirmation']
    //         }
    //       },
    //       'NewUser'
    //     );
    //   }
    // });

    this.props.confirmUserEmail(
      {
        confirmationToken: token,
        user: {
          password: values['password'],
          passwordConfirmation: values['passwordConfirmation']
        }
      },
      'NewUser'
    );
  };

  render() {
    const { classes, isSubmitting } = this.props;
    return (
      <Grid className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <div>
            <img className={classes.header} src={brillaLogo} />
          </div>
        </AppBar>
        <main className={classes.content}>
          {!this.state.errors ? (
            <Paper className={classes.paper}>
              <Fragment>
                <h5
                  className={classnames(classes.headline5, classes.titleText)}
                >
                  Confirma tu cuenta
                </h5>
                <Formik
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  initialValues={{
                    email: this.state.email,
                    username: this.state.username,
                    ...initialValues
                  }}
                  onSubmit={(values, actions, dirty, touched, errors) => {
                    this.handleSubmit(values);
                  }}
                  onReset={() => {
                    this.goOut();
                  }}
                  render={({
                    values,
                    handleChange,
                    handleSubmit,
                    handleReset,
                    handleBlur,
                    dirty,
                    touched,
                    errors,
                    setFieldValue
                  }) => (
                    <Fragment>
                      <Grid container spacing={16}>
                        <GridItem xs={12}>
                          <OutlinedTextField
                            name="email"
                            onChange={handleChange}
                            className={classes.formControl}
                            value={values.email}
                            label={'Email'}
                            fullWidth
                            disabled={true}
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <OutlinedTextField
                            name="username"
                            onChange={handleChange}
                            className={classes.formControl}
                            value={values.username}
                            label={'Usuario'}
                            fullWidth
                            disabled={true}
                          />
                        </GridItem>
                      </Grid>
                      <PasswordInput
                        values={values}
                        touched={touched}
                        errors={errors}
                        showCurrentPassword={false}
                        handleChange={handleChange}
                      />
                      <div className={classes.button}>
                        <ProgressButton
                          onClick={handleSubmit}
                          classes={{
                            disabled: classes.buttonDisabled,
                            label: classes.buttonLabel
                          }}
                          color={'secondary'}
                          disabled={!dirty || isSubmitting}
                          isSubmitting={isSubmitting}
                        >
                          {'Siguiente'}
                        </ProgressButton>
                      </div>
                    </Fragment>
                  )}
                />
              </Fragment>
            </Paper>
          ) : (
            <Paper className={classes.paper}>
              <Fragment>
                <h5
                  className={classnames(classes.headline5, classes.errorText)}
                >
                  {this.state.errors.errors.base}. Contacte con el
                  administrador.
                </h5>
              </Fragment>
            </Paper>
          )}
        </main>
      </Grid>
    );
  }
}

const styles = () => ({
  formControl: {
    marginTop: '0px'
  },
  tooltip: {
    fontSize: '1em',
    padding: '0.5em'
  }
});

export default withStyles(
  combineStyles(styles, UserUpdatePasswordStyles, TypographyStyles),
  { withTheme: true }
)(UserUpdatePassword);
