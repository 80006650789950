import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import GridItem from '../../shared/GridItem';

import combineStyles from '../../../helpers/combineStyles';
import { buildAddress } from '../../../helpers/addressFunctions';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import { indexOf } from 'lodash';

const styles = theme => ({
  tag: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    margin: 0,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: 'inline-block',
    padding: [[theme.spacing.unit * 0.5, theme.spacing.unit * 1]],
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#FEB00B',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:last-child': {
      marginRight: 0
    }
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  }
});

export const AdditionalInfo = ({
  classes,
  isLoading,
  additionalInfo = {},
  onEditAdditionalInfo,
  canUpdateAdditionalInfo
}) => {
  const _getElementInChip = name => {
    return _getElementsByName(name).map(el => (
      <span className={classes.tag} key={el.id}>
        {el.name}
      </span>
    ));
  };
  const _getElementsByName = name =>
    additionalInfo.activities.filter(el => el.activityType == name);

  const _checkActivitiesProperties = () => {
    var isEmpy = false;
    for (const key in additionalInfo)
      if (additionalInfo[key] != null && 0 != additionalInfo[key].length)
        isEmpy = true;
    return isEmpy;
  };

  return (
    <FormItemContainer
      title={'Información adicional'}
      secondaryAction={
        !isLoading && canUpdateAdditionalInfo ? (
          <Button
            size={'small'}
            color={'secondary'}
            className={classnames(classes.smallButton, classes.actionButtons)}
            classes={{
              label: classes.buttonLabel
            }}
            onClick={onEditAdditionalInfo}
          >
            {'Editar'}
          </Button>
        ) : null
      }
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div>
          {_checkActivitiesProperties() ? (
            <Grid container spacing={16}>
              {additionalInfo.gender && (
                <GridItem xs={6}>
                  <p className={classes.body2}>Género</p>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {additionalInfo.gender}
                  </p>
                </GridItem>
              )}
              {additionalInfo.dateOfBirth && (
                <GridItem xs={6}>
                  <p className={classes.body2}>Fecha de nacimiento</p>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {moment(additionalInfo.dateOfBirth).format('YYYY/MM/DD')}
                  </p>
                </GridItem>
              )}
              {additionalInfo.maritalStatus && (
                <GridItem xs={6}>
                  <p className={classes.body2}>Estado civil</p>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {additionalInfo.maritalStatus}
                  </p>
                </GridItem>
              )}
              {additionalInfo.numOfChildren !== null && (
                <GridItem xs={6}>
                  <p className={classes.body2}>¿Cuántos hijos tienes?</p>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {additionalInfo.numOfChildren}
                  </p>
                </GridItem>
              )}
              {additionalInfo.address.city.length !== 0 && (
                <GridItem xs={6}>
                  <p className={classes.body2}>Dirección de Residencia</p>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {buildAddress(additionalInfo.address)}
                  </p>
                </GridItem>
              )}
              {_getElementsByName('sport').length > 0 && (
                <GridItem xs={6}>
                  <p className={classes.body2}>¿Qué deportes practicas?</p>
                  {_getElementInChip('sport')}
                </GridItem>
              )}
              {_getElementsByName('hobby').length > 0 && (
                <GridItem xs={6}>
                  <p className={classes.body2}>¿Cuáles son tus hobbies?</p>
                  {_getElementInChip('hobby')}
                </GridItem>
              )}
              {_getElementsByName('liking').length > 0 && (
                <GridItem xs={6}>
                  <p className={classes.body2}>¿Qué te gusta?</p>
                  {_getElementInChip('liking')}
                </GridItem>
              )}
            </Grid>
          ) : (
            <p className={classes.body2}>
              Aún no has ingresado información. ¡Déjanos conocer qué te gusta!
            </p>
          )}
        </div>
      )}
    </FormItemContainer>
  );
};

export default withStyles(
  combineStyles(ButtonStyles, TypographyStyles, styles)
)(AdditionalInfo);
