import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import TextFieldInput from '../../../shared/Inputs/TextField';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';
import Divider from '@material-ui/core/Divider';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import moment from 'moment-timezone';
import OurDatePicker from '../../../shared/CustomDatePicker';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';

import _get from 'lodash/get';
import _forEach from 'lodash/forEach';

import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';

import {
  debtorValidation,
  cosignerValidation
} from './EditPromissoryModal.validation';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `0 !important`,
    padding: 16,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  subtitleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 1.25}px !important`,
    position: 'relative',
    marginTop: 16
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  subtitle: {
    flex: 1,
    fontWeight: 'bold !important',
    fontSize: '15px !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 8
  },
  divider: {
    margin: '5px 0'
  },
  body: {
    maxHeight: '70vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0 16px !important'
  },
  modalRootBase: {
    padding: '0 24px'
  },
  modalBody: {
    padding: 0
  }
});

const OPTIONS_DROPDOWN = [
  { title: 'Valor', value: 'V' },
  { title: 'Porcentaje', value: 'P' }
];

export class EditPromissoryModal extends Component {
  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.erros !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnSave = async params => {
    const { onSave } = this.props;
    onSave(params);
  };

  _getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      promissory,
      serverErrors = {},
      canUpdateUniquePromissory,
      canUpdateNoUniquePromissory
    } = this.props;
    const { debtorDetails, cosignerDetails } = promissory;

    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        validationSchema={
          cosignerDetails
            ? debtorValidation(promissory.isUnmaterialized).concat(cosignerValidation)
            : debtorValidation(promissory.isUnmaterialized)
        }
        initialValues={{
          promissoryCode: promissory.code,
          promissoryIsUnique: promissory.isUnique,
          debtorName: debtorDetails.name,
          debtorLastName: debtorDetails.lastName,
          debtorExpeditionPlace: debtorDetails.idExpeditionPlace,
          debtorExpeditionDate: moment(
            debtorDetails.idExpeditionDate,
            'YYYY-MM-DD'
          ).format('YYYY/MM/DD'),
          debtor_date_of_birth: moment(
            debtorDetails.dateOfBirth,
            'YYYY-MM-DD'
          ).format('YYYY/MM/DD'),
          debtorPhone: debtorDetails.phone,
          debtorMobile: debtorDetails.mobile,
          personalReferenceName: debtorDetails.personalReferenceName || null,
          personalReferencePhone: debtorDetails.personalReferencePhone || null,
          personalReferenceCellphone: debtorDetails.personalReferenceCellphone || null,
          cosignerName: cosignerDetails && cosignerDetails.name,
          cosignerLastName: cosignerDetails && cosignerDetails.lastName,
          cosignerExpeditionPlace:
            cosignerDetails && cosignerDetails.idExpeditionPlace,
          cosignerExpeditionDate:
            cosignerDetails &&
            moment(cosignerDetails.idExpeditionDate, 'YYYY-MM-DD').format(
              'YYYY/MM/DD'
            ),
          cosigner_date_of_birth:
            cosignerDetails &&
            moment(cosignerDetails.dateOfBirth, 'YYYY-MM-DD').format(
              'YYYY/MM/DD'
            ),
          cosignerPhone: cosignerDetails && cosignerDetails.phone,
          cosignerMobile: cosignerDetails && cosignerDetails.mobile,
          cosignerPersonalReferenceName: cosignerDetails && cosignerDetails.personalReferenceName || null,
          cosignerPersonalReferencePhone: cosignerDetails && cosignerDetails.personalReferencePhone || null,
          cosignerPersonalReferenceCellphone: cosignerDetails && cosignerDetails.personalReferenceCellphone || null
        }}
        onSubmit={(values, actions) => {
          this._handleOnSave(values);
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty,
          setFieldValue,
          touched,
          errors
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              modalRootBase: classes.modalRootBase,
              body: classes.modalBody,
              footer: classes.modalFooter
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {`Editar Pagaré: ${values.promissoryCode}`}
                  </p>
                </div>
                <div className={classes.body}>
                  <div className={classes.subtitleWrapper}>
                    <p
                      className={classnames(
                        classes.headline6,
                        classes.subtitle
                      )}
                    >
                      DEUDOR
                    </p>
                  </div>
                  <Grid container spacing={16}>
                    <GridItem md={6} xs={12}>
                      <OutlinedTextField
                        label={'Nombre'}
                        name={'debtorName'}
                        disabled={promissory.isUnmaterialized}
                        value={values.debtorName}
                        onChange={handleChange}
                        fullWidth
                        error={touched.debtorName && !!errors.debtorName}
                        helperText={touched.debtorName && errors.debtorName}
                      />
                    </GridItem>
                    <GridItem md={6} xs={12}>
                      <OutlinedTextField
                        label={'Apellido'}
                        name={'debtorLastName'}
                        disabled={promissory.isUnmaterialized}
                        value={values.debtorLastName}
                        onChange={handleChange}
                        fullWidth
                        error={
                          touched.debtorLastName && !!errors.debtorLastName
                        }
                        helperText={
                          touched.debtorLastName && errors.debtorLastName
                        }
                      />
                    </GridItem>
                    <GridItem md={6} xs={12}>
                      <OurDatePicker
                        name={'debtor_date_of_birth'}
                        disabled={promissory.isUnmaterialized}
                        invalidLabel="Fecha inválida"
                        maxDateMessage="Fecha inválida"
                        minDateMessage="Fecha inválida"
                        invalidDateMessage={'Fecha inválida'}
                        label="Fecha de nacimiento"
                        value={values.debtor_date_of_birth || null}
                        onChange={e => {
                          setFieldValue('debtor_date_of_birth', e);
                        }}
                        onError={() =>
                          setFieldValue('debtor_date_of_birth', null)
                        }
                        error={
                          (touched.debtor_date_of_birth &&
                            !!errors.debtor_date_of_birth) ||
                          (serverErrors && serverErrors.debtor_date_of_birth)
                        }
                        helperText={
                          (touched.debtor_date_of_birth &&
                            errors.debtor_date_of_birth) ||
                          (serverErrors && serverErrors.debtor_date_of_birth)
                        }
                        fullWidth
                        margin="normal"
                        variant="outlined"
                      />
                    </GridItem>
                    <GridItem md={6} xs={12}>
                      <OurDatePicker
                        name={'debtorExpeditionDate'}
                        disabled={promissory.isUnmaterialized}
                        invalidLabel="Fecha inválida"
                        maxDateMessage="Fecha inválida"
                        minDateMessage="Fecha inválida"
                        invalidDateMessage={'Fecha inválida'}
                        label="Fecha de expedición"
                        value={values.debtorExpeditionDate || null}
                        onChange={e => {
                          setFieldValue('debtorExpeditionDate', e);
                        }}
                        onError={() =>
                          setFieldValue('debtorExpeditionDate', null)
                        }
                        error={
                          (touched.debtorExpeditionDate &&
                            !!errors.debtorExpeditionDate) ||
                          (serverErrors && serverErrors.debtorExpeditionDate)
                        }
                        helperText={
                          (touched.debtorExpeditionDate &&
                            errors.debtorExpeditionDate) ||
                          (serverErrors && serverErrors.debtorExpeditionDate)
                        }
                        fullWidth
                        margin="normal"
                        variant="outlined"
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <OutlinedTextField
                        label={'Lugar de expedición'}
                        name={'debtorExpeditionPlace'}
                        disabled={promissory.isUnmaterialized}
                        id={'debtorExpeditionPlace'}
                        value={values.debtorExpeditionPlace || ''}
                        onChange={handleChange}
                        fullWidth
                        error={
                          touched.debtorExpeditionPlace &&
                          (!!errors.debtorExpeditionPlace ||
                            !!errors.debtorExpeditionPlace)
                        }
                        helperText={
                          touched.debtorExpeditionPlace &&
                          (errors.debtorExpeditionPlace ||
                            errors.debtorExpeditionPlace)
                        }
                      />
                    </GridItem>
                    <GridItem md={6} xs={12}>
                      <OutlinedTextField
                        name="debtorPhone"
                        disabled={promissory.isUnmaterialized}
                        label="Teléfono:"
                        value={values.debtorPhone}
                        onChange={handleChange}
                        error={touched.debtorPhone && !!errors.debtorPhone}
                        helperText={touched.debtorPhone && errors.debtorPhone}
                        type={'number'}
                        onInput={e => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 7);
                        }}
                        min={0}
                      />
                    </GridItem>
                    <GridItem md={6} xs={12}>
                      <OutlinedTextField
                        name="debtorMobile"
                        label="Telefono celular:"
                        value={values.debtorMobile}
                        onChange={handleChange}
                        type={'number'}
                        error={touched.debtorMobile && !!errors.debtorMobile}
                        helperText={touched.debtorMobile && errors.debtorMobile}
                        onInput={e => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        min={0}
                      />
                    </GridItem>
                  </Grid>
                  {debtorDetails.personalReferenceName && (
                    <>
                      <div className={classes.subtitleWrapper}>
                        <p
                          className={classnames(
                            classes.headline6,
                            classes.subtitle
                          )}
                        >
                          REFERENCIA PERSONAL
                        </p>
                      </div>
                      <Grid container spacing={16}>
                        <GridItem md={6} xs={12}>
                          <OutlinedTextField
                            label={'Nombre'}
                            name={'personalReferenceName'}
                            disabled={promissory.isUnmaterialized}
                            value={values.personalReferenceName}
                            onChange={handleChange}
                            fullWidth
                            error={
                              touched.personalReferenceName &&
                              !!errors.personalReferenceName
                            }
                            helperText={
                              touched.personalReferenceName &&
                              errors.personalReferenceName
                            }
                          />
                        </GridItem>
                        <GridItem md={6} xs={12}>
                          <OutlinedTextField
                            name="personalReferencePhone"
                            disabled={promissory.isUnmaterialized}
                            label="Telefono"
                            value={values.personalReferencePhone}
                            onChange={handleChange}
                            type={'number'}
                            error={
                              touched.personalReferencePhone &&
                              !!errors.personalReferencePhone
                            }
                            helperText={
                              touched.personalReferencePhone &&
                              errors.personalReferencePhone
                            }
                            onInput={e => {
                              e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 7);
                            }}
                            min={0}
                          />
                        </GridItem>
                        <GridItem md={6} xs={12}>
                          <OutlinedTextField
                            name="personalReferenceCellphone"
                            disabled={promissory.isUnmaterialized}
                            label="Telefono celular"
                            value={values.personalReferenceCellphone}
                            onChange={handleChange}
                            type={'number'}
                            error={
                              touched.personalReferenceCellphone &&
                              !!errors.personalReferenceCellphone
                            }
                            helperText={
                              touched.personalReferenceCellphone &&
                              errors.personalReferenceCellphone
                            }
                            onInput={e => {
                              e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 10);
                            }}
                            min={0}
                          />
                        </GridItem>
                      </Grid>
                    </>
                  )}
                  {cosignerDetails && (
                    <Fragment>
                      <Divider light className={classes.divider} />
                      <div className={classes.subtitleWrapper}>
                        <p
                          className={classnames(
                            classes.headline6,
                            classes.subtitle
                          )}
                        >
                          CODEUDOR
                        </p>
                      </div>
                      <Grid container spacing={16}>
                        <GridItem md={6} xs={12}>
                          <OutlinedTextField
                            label={'Nombre'}
                            name={'cosignerName'}
                            disabled={promissory.isUnmaterialized}
                            value={values.cosignerName}
                            onChange={handleChange}
                            fullWidth
                            error={
                              touched.cosignerName && !!errors.cosignerName
                            }
                            helperText={
                              touched.cosignerName && errors.cosignerName
                            }
                          />
                        </GridItem>
                        <GridItem md={6} xs={12}>
                          <OutlinedTextField
                            label={'Apellido'}
                            name={'cosignerLastName'}
                            disabled={promissory.isUnmaterialized}
                            value={values.cosignerLastName}
                            onChange={handleChange}
                            fullWidth
                            error={
                              touched.cosignerLastName &&
                              !!errors.cosignerLastName
                            }
                            helperText={
                              touched.cosignerLastName &&
                              errors.cosignerLastName
                            }
                          />
                        </GridItem>
                        <GridItem md={6} xs={12}>
                          <OurDatePicker
                            name={'cosigner_date_of_birth'}
                            disabled={promissory.isUnmaterialized}
                            invalidLabel="Fecha inválida"
                            maxDateMessage="Fecha inválida"
                            minDateMessage="Fecha inválida"
                            invalidDateMessage={'Fecha inválida'}
                            label="Fecha de nacimiento"
                            value={values.cosigner_date_of_birth || null}
                            onChange={e => {
                              setFieldValue('cosigner_date_of_birth', e);
                            }}
                            onError={() =>
                              setFieldValue('cosigner_date_of_birth', null)
                            }
                            error={
                              (touched.cosigner_date_of_birth &&
                                !!errors.cosigner_date_of_birth) ||
                              (serverErrors &&
                                serverErrors.cosigner_date_of_birth)
                            }
                            helperText={
                              (touched.cosigner_date_of_birth &&
                                errors.cosigner_date_of_birth) ||
                              (serverErrors &&
                                serverErrors.cosigner_date_of_birth)
                            }
                            fullWidth
                            margin="normal"
                            variant="outlined"
                          />
                        </GridItem>
                        <GridItem md={6} xs={12}>
                          <OurDatePicker
                            name={'cosignerExpeditionDate'}
                            disabled={promissory.isUnmaterialized}
                            invalidLabel="Fecha inválida"
                            maxDateMessage="Fecha inválida"
                            minDateMessage="Fecha inválida"
                            invalidDateMessage={'Fecha inválida'}
                            label="Fecha de expedición"
                            value={values.cosignerExpeditionDate || null}
                            onChange={e => {
                              setFieldValue('cosignerExpeditionDate', e);
                            }}
                            onError={() =>
                              setFieldValue('cosignerExpeditionDate', null)
                            }
                            error={
                              (touched.cosignerExpeditionDate &&
                                !!errors.cosignerExpeditionDate) ||
                              (serverErrors &&
                                serverErrors.cosignerExpeditionDate)
                            }
                            helperText={
                              (touched.cosignerExpeditionDate &&
                                errors.cosignerExpeditionDate) ||
                              (serverErrors &&
                                serverErrors.cosignerExpeditionDate)
                            }
                            fullWidth
                            margin="normal"
                            variant="outlined"
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <OutlinedTextField
                            label={'Lugar de expedición'}
                            name={'cosignerExpeditionPlace'}
                            disabled={promissory.isUnmaterialized}
                            id={'cosignerExpeditionPlace'}
                            value={values.cosignerExpeditionPlace || ''}
                            onChange={handleChange}
                            fullWidth
                            error={
                              touched.cosignerExpeditionPlace &&
                              !!errors.cosignerExpeditionPlace
                            }
                            helperText={
                              touched.cosignerExpeditionPlace &&
                              errors.cosignerExpeditionPlace
                            }
                          />
                        </GridItem>
                        <GridItem md={6} xs={12}>
                          <OutlinedTextField
                            name="cosignerPhone"
                            label="Teléfono:"
                            disabled={promissory.isUnmaterialized}
                            value={values.cosignerPhone}
                            onChange={handleChange}
                            error={
                              touched.cosignerPhone && !!errors.cosignerPhone
                            }
                            helperText={
                              touched.cosignerPhone && errors.cosignerPhone
                            }
                            type={'number'}
                            onInput={e => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 7);
                            }}
                            min={0}
                          />
                        </GridItem>
                        <GridItem md={6} xs={12}>
                          <OutlinedTextField
                            name="cosignerMobile"
                            disabled={promissory.isUnmaterialized}
                            label="Celular:"
                            value={values.cosignerMobile}
                            onChange={handleChange}
                            error={
                              touched.cosignerMobile && !!errors.cosignerMobile
                            }
                            helperText={
                              touched.cosignerMobile && errors.cosignerMobile
                            }
                            type={'number'}
                            onInput={e => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                            min={0}
                          />
                        </GridItem>
                      </Grid>
                      {cosignerDetails.personalReferenceName && (
                        <>
                          <div className={classes.subtitleWrapper}>
                            <p
                              className={classnames(
                                classes.headline6,
                                classes.subtitle
                              )}
                            >
                              REFERENCIA PERSONAL
                            </p>
                          </div>
                          <Grid container spacing={16}>
                            <GridItem md={6} xs={12}>
                              <OutlinedTextField
                                label={'Nombre'}
                                name={'cosignerPersonalReferenceName'}
                                disabled={promissory.isUnmaterialized}
                                value={values.cosignerPersonalReferenceName}
                                onChange={handleChange}
                                fullWidth
                                error={
                                  touched.cosignerPersonalReferenceName &&
                                  !!errors.cosignerPersonalReferenceName
                                }
                                helperText={
                                  touched.cosignerPersonalReferenceName &&
                                  errors.cosignerPersonalReferenceName
                                }
                              />
                            </GridItem>
                            <GridItem md={6} xs={12}>
                              <OutlinedTextField
                                name="cosignerPersonalReferencePhone"
                                disabled={promissory.isUnmaterialized}
                                label="Telefono"
                                value={values.cosignerPersonalReferencePhone}
                                onChange={handleChange}
                                type={'number'}
                                error={
                                  touched.cosignerPersonalReferencePhone &&
                                  !!errors.cosignerPersonalReferencePhone
                                }
                                helperText={
                                  touched.cosignerPersonalReferencePhone &&
                                  errors.cosignerPersonalReferencePhone
                                }
                                onInput={e => {
                                  e.target.value = Math.max(0, parseInt(e.target.value))
                                    .toString()
                                    .slice(0, 7);
                                }}
                                min={0}
                              />
                            </GridItem>
                            <GridItem md={6} xs={12}>
                              <OutlinedTextField
                                name="cosignerPersonalReferenceCellphone"
                                disabled={promissory.isUnmaterialized}
                                label="Telefono celular"
                                value={values.cosignerPersonalReferenceCellphone}
                                onChange={handleChange}
                                type={'number'}
                                error={
                                  touched.cosignerPersonalReferenceCellphone &&
                                  !!errors.cosignerPersonalReferenceCellphone
                                }
                                helperText={
                                  touched.cosignerPersonalReferenceCellphone &&
                                  errors.cosignerPersonalReferenceCellphone
                                }
                                onInput={e => {
                                  e.target.value = Math.max(0, parseInt(e.target.value))
                                    .toString()
                                    .slice(0, 10);
                                }}
                                min={0}
                              />
                            </GridItem>
                          </Grid>
                        </>
                      )}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={
                    (!dirty &&
                      (canUpdateUniquePromissory ||
                        canUpdateNoUniquePromissory)) ||
                    isSubmitting
                  }
                  isSubmitting={isSubmitting}
                >
                  {'Guardar'}
                </ProgressButton>
              </Fragment>
            }
          />
        )}
      />
    );
  }
}

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(EditPromissoryModal);
