import AxiosRequest from '../services/axios_request'
import AxiosNodeRequest from '../services/axios_node_request'
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { STATUS_ARRAY } from '../constants/system_status_constants'
const axiosRequest = new AxiosRequest();
const axiosNodeRequest = new AxiosNodeRequest();

function setServicesStatus(data) {
  return {
    type: STATUS_ARRAY,
    data
  };
}

export const fetchServicesStatus = (timeValue) => async dispatch => {
  dispatch(sendRequest('ServicesStatus'));
  let statusArray = {
    'health': 'empty',
    'health2': 'empty',
    'health_node': 'empty'
  };
  try {
    for (const s in statusArray) {
      statusArray[s] = await healthEndpoints(s, timeValue);
    }
    dispatch(setServicesStatus(statusArray));
  } catch (errors) {

  } finally {
    dispatch(completeRequest('ServicesStatus'));
  }
};

const healthEndpoints = async (index, timeValue) => {
  let payload;
  try {
    if(index != 'health_node') {
      payload = await axiosRequest.get(index, {}, { timeout: timeValue });
      payload = payload.status;
    } else {
      payload = await axiosNodeRequest.get('health', {}, { timeout: timeValue });
    }
    return payload;
  } catch (errors) {
    return 'timeout';
  }
}