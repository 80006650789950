import moment from 'moment-timezone';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
import { HOME_VISIT_STATUS } from '../../../constants/enums';
import _get from 'lodash/get';

export const columns = [
  {
    title: 'ID',
    name: 'id'
  },
  {
    title: 'Contrato',
    name:  'contractId'
  },
  {
    title: 'Dirección',
    name: 'address'
  },
  {
    title: 'Punto de Venta',
    name: 'pointOfSale'
  },
  {
    title: 'Fecha Solicitud',
    name: 'registrationDate'
  },
  {
    title: 'Estado',
    name: 'status'
  },
  {
    title: 'Registrado por',
    name: 'creatorUser'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      id: datum.id,
      contractId: _get(datum, 'contract.id'),
      address: _get(datum, 'contract.address') || '--',
      registrationDate: datum.registrationDate
        ? moment(datum.registrationDate)
            .locale('es')
            .format('DD MMM YYYY')
        : '--',
      status: HOME_VISIT_STATUS[datum.orderStatus] || '--',
      pointOfSaleId: _get(datum, 'pointOfSale.id') || '--',
      pointOfSale: _get(datum, 'pointOfSale.name') || '--',
      creatorUser: _get(datum, 'creator.firstName') + ' ' + _get(datum, 'creator.lastName') || '--',
      supplies: _get(datum, 'pointOfSale.supplier.supplies') || {}
    };
  });
