import PointOfSaleList from './PointOfSaleList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchPointsOfSale } from '../../../actions/points_of_sale_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';

function mapStateToProps(state) {
  return {
    pointsOfSale: state.pointOfSale.pointsOfSale,
    meta: state.pointOfSale.meta,
    suppliers: state.globalSetting.orgData.suppliers,
    requestSend: state.requestState.requestPointsOfSaleSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPointsOfSale: bindActionCreators(fetchPointsOfSale, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PointOfSaleList);