import {
  LIST_GLOBAL_SETTINGS,
  SAVE_GLOBAL_SETTING,
  LOAD_ORG_DATA
} from '../constants/global_setting_constants';

const initialState = {
  list: [],
  setting: '',
  saved: false,
  orgData: {}
};

export function globalSetting(state = initialState, action) {
  switch (action.type) {
    case LIST_GLOBAL_SETTINGS:
      return {
        ...state,
        list: action.data,
        metadata: action.meta,
        saved: false
      };
    case SAVE_GLOBAL_SETTING:
      return {
        ...state,
        saved: true
      };
    case LOAD_ORG_DATA:
      return {
        ...state,
        orgData: action.data,
        saved: false
      };
    default:
      return state;
  }
}
