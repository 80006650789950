import moment from 'moment-timezone';

export const columns = [
  {
    title: 'Contrato',
    name: 'contractId'
  },
  {
    title: 'Producto',
    name: 'productId'
  },
  {
    title: 'Acción Realizada',
    name: 'action'
  },
  {
    title: 'Fecha',
    name: 'date'
  },
  {
    title: 'Usuario',
    name: 'username'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      contractId: datum.contract_id || '--',
      productId: datum.product_id || '--',
      action: datum.action || '--',
      date:
        moment(datum.created_at)
          .locale('es')
          .format('DD MMM YYYY') || '--',
      username: datum.user.username || '--'
    };
  });
