import CampaignList from './CampaignList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchCampaign, createCampaign, updateCampaign, deleteCampaign } from '../../../actions/campaign_actions';
import { fetchGeographicLocations } from '../../../actions/geographic_location_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';
import { fetchContractStrata } from '../../../actions/contract_stratum_actions';

function mapStateToProps(state) {
  return {
    suppliers: state.globalSetting.orgData.suppliers,
    geographicLocations: state.geographicLocation.list,
    categories: state.globalSetting.orgData.categories,
    saleChannels: state.globalSetting.orgData.sale_channels,
    contractStrata: state.contractStratum.list,
    canCreateCampaign: state.authentication.currentUser.canCreateCampaign,
    canUpdateCampaign: state.authentication.currentUser.canUpdateCampaign,
    campaignList: state.campaign.list,
    campaignSaved: state.campaign.saved,
    metadata: state.campaign.metadata,
    requestErrorsOnSaveCampaign:
    state.requestState.requestErrorsOnSaveCampaign,
    requestCampaignListSend: state.requestState.requestCampaignListSend,
    requestSaveCampaignSend: state.requestState.requestSaveCampaignSend,
    requestDeleteCampaignSend: state.requestState.requestDeleteCampaignSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCampaign: bindActionCreators(fetchCampaign, dispatch),
    createCampaign: bindActionCreators(createCampaign, dispatch),
    updateCampaign: bindActionCreators(updateCampaign, dispatch),
    deleteCampaign: bindActionCreators(deleteCampaign, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    fetchGeographicLocations: bindActionCreators(
      fetchGeographicLocations,
      dispatch
    ),
    fetchContractStrata: bindActionCreators(fetchContractStrata, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CampaignList);
