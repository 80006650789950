import ManualQuotaList from './ManualQuotaList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchManualQuotas } from '../../../actions/manual_quota_actions';

function mapStateToProps(state) {
  return {
    canCreateManualQuota: state.authentication.currentUser.canCreateManualQuota,
    manualQuotas: state.manualQuota.manualQuotas,
    meta: state.manualQuota.meta,
    requestSend: state.requestState.requestSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchManualQuotas: bindActionCreators(fetchManualQuotas, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ManualQuotaList);
