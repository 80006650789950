import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import _find from 'lodash/find';
import parseQuerystring from '../../../helpers/parseQuerystring';
import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';
import TextFieldInput from '../../shared/Inputs/TextField';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import { actionOptions, originalDateOptions, dateOptions } from './dropDownOptions';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import CollapseWrapper from '../../shared/CollapseWrapper.js';
import PickCustomDateModal from '../../shared/Inputs/PickCustomDateModal';
import { CUSTOM_DATE } from './constants';

class SecureProductMovementsFilters extends PureComponent {
  state = {
    contractId: '',
    action: 'all',
    created_at: 'todas',
    productId: '',
    username: '',
    openPickCustomDate: null,
    customDate: CUSTOM_DATE
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    const custom = {};
    if (query && query.created_at) {
      const split = query.created_at.split(' - ');
      if (split[1]) custom.customDate = query.created_at;
    }
    this.setState({
      ...this.state,
      ...query,
      ...custom
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter && filter != 'all'),
      ['contractId', 'action', 'productId', 'username', 'created_at']
    );
    return filterParams;
  };

  _handleDateFilter = ({ target: { name, value } }) => {
    if (value === this.state.customDate) {
      this.setState({ openPickCustomDate: name });
    } else {
      this.setState(
        { [name]: value, customDate: CUSTOM_DATE },
        this._handleChangeFilter
      );
    }
  };

  _handleDateRangeFilter = ({ target: { name, value } }) => {
    const customDate = !_find(
      originalDateOptions,
      availableOption => availableOption.value === value
    )
      ? value
      : CUSTOM_DATE;
    this.setState(
      { [name]: value, openPickCustomDate: null, customDate },
      this._handleChangeFilter
    );
  };

  _handleClosePickCustomDate = () =>
    this.setState({ openPickCustomDate: null });

  _getSuggestions = async (type, value) => {
    const zones = this.props[type].filter(item => item.area_type === 2);

    const items = zones.map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  render() {
    const { classes } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="contractId"
                  name="contractId"
                  value={this.state.contractId}
                  onChange={this._handleChange}
                  label="# Contrato"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="productId"
                  name="productId"
                  value={this.state.productId}
                  onChange={this._handleChange}
                  label="# Producto"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <OutlinedSelectChippedInput
                  label={'Acción Realizada'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'action'}
                  options={actionOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'Action'}
                  value={this.state.action}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={10} sm={3}>
                <OutlinedSelectChippedInput
                  label={'Fecha'}
                  name={'created_at'}
                  options={dateOptions(
                    this.state.customDate,
                    this.state.created_at
                  )}
                  value={this.state.created_at}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  inputLabel={'Date'}
                  onChange={this._handleDateFilter}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="username"
                  name="username"
                  value={this.state.username}
                  onChange={this._handleChange}
                  label="Usuario"
                  type="text"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {!!this.state.openPickCustomDate && (
              <PickCustomDateModal
                name={this.state.openPickCustomDate}
                onClose={this._handleClosePickCustomDate}
                onSave={this._handleDateRangeFilter}
                value={this.state.created_at}
              />
            )}
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
SecureProductMovementsFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(SecureProductMovementsFilters);
