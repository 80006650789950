import React, { Component } from 'react';
import Table from '../../shared/Table/Table';
import { withStyles } from '@material-ui/core/styles';
import { columns, mapData } from './TableMetadata';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveModal from './SaveModal';

class LineExecutiveProfileList extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
  }

  componentDidMount() {
    const { fetchProfiles } = this.props;
    const { page, rowsPerPage } = this.state;
    fetchProfiles({ page, rowsPerPage });
  }

  render() {
    const {
      profiles,
      setSaveModalOpen,
      metaData,
      fetchingProfiles=false,
      classes,
      currentUser,
      saveModal,
      lineExecutives,
      categories,
      creatingProfile,
      updatingProfile,
    } = this.props;
    const { page, rowsPerPage } = this.state;
    return (
      <Grid container>
        <Grid container item xs={12} className={classes.titleContainer}>
          <Grid item xs={6}>
            <Typography className={classes.title}>
              Perfiles de ejecutivos de línea
            </Typography>
          </Grid>
          {
            currentUser.canCreateLineExecutiveProfiles && (
              <Grid container item justify='flex-end' xs={6}>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => setSaveModalOpen(true)}
                >
                  Crear Perfil
                </Button>
              </Grid>
            )
          }
        </Grid>
        <Grid item xs={12}>
          <Table
            name={'Perfiles de ejecutivos de línea'}
            columns={columns}
            data={mapData(profiles)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            onClickOnRow={row => setSaveModalOpen(true, row)}
            totalCount={metaData.totalCount}
            loading={fetchingProfiles}
          />
        </Grid>
        {
          saveModal.open && (
            <SaveModal
              open={true}
              classes={classes}
              profile={saveModal.profile}
              onClose={() => setSaveModalOpen(false)}
              onSubmit={this._onSave}
              isSubmitting={creatingProfile || updatingProfile}
              lineExecutives={lineExecutives}
              categories={categories}
            />
          )
        }
      </Grid>
    );
  }

  _handleChangePage = page => {
    const { rowsPerPage } = this.state;
    const { fetchProfiles } = this.props;
    this.setState({page});
    fetchProfiles({ page, rowsPerPage });
  }

  _handleChangeRowsPerPage = rowsPerPage => {
    const { fetchProfiles } = this.props;
    const page = 0;
    this.setState({rowsPerPage, page});
    fetchProfiles({ page, rowsPerPage });
  }

  _onSave = values => {
    const { createProfile, updateProfile } = this.props;
    const { page, rowsPerPage } = this.state;
    if (!values.id) {
      createProfile({ profile: values, page, rowsPerPage });
    } else {
      updateProfile({ profile: values, page, rowsPerPage });
    }
  }
}

export default withStyles(
  styles, { withTheme: true }
)(LineExecutiveProfileList);