import React, { PureComponent, Fragment } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import parseQuerystring from '../../../helpers/parseQuerystring';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import TextFieldInput from '../../shared/Inputs/TextField';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';
import { statusOptions, commissionTypes } from './dropDownOptions';
import _get from 'lodash/get';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

class CommissionFilters extends PureComponent {
  state = {
    category_name: '',
    cache_category_name: '',
    article_name: '',
    supplier_name: '',
    cache_supplier_name: '',
    sale_channel_name: '',
    cache_sale_channel_name: '',
    status: 'all',
    type: 'all'
  };

  componentDidMount() {
    this._handleChangeFilter();
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleChangeFilter = () => {
    const filterParams = this._getFilterParams();
    this.props.fetchInfoToCreate(filterParams);
    this.props.onChangeFilter(filterParams);
  };

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter),
      'supplier_name',
      'category_name',
      'article_name',
      'sale_channel_name',
      'status',
      'type'
    );
    return filterParams;
  };

  _getSuggestions = async (type, value) => {
    const items = this.props.dataToCreateCommissions[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));
    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  render() {
    const { classes } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <AutocompleteInput
                  id="category"
                  name="category_name"
                  label="Categoría"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.cache_category_name || ''}
                  onChange={change => {
                    if (this.state.category_name) {
                      this.setState(
                        { category_name: '' },
                        this._handleChangeFilter
                      );
                    }
                    this.setState({ cache_category_name: change });
                  }}
                  getSuggestions={value =>
                    this._getSuggestions('categories', value)
                  }
                  onSuggestionSelected={category => {
                    this.setState(
                      { category_name: category.label },
                      this._handleChangeFilter
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <AutocompleteInput
                  id="supplier"
                  name="supplier_name"
                  label="Proveedor"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.cache_supplier_name || ''}
                  onChange={change => {
                    if (this.state.supplier_name) {
                      this.setState(
                        { supplier_name: '' },
                        this._handleChangeFilter
                      );
                    }
                    this.setState({ cache_supplier_name: change });
                  }}
                  getSuggestions={value =>
                    this._getSuggestions('suppliers', value)
                  }
                  onSuggestionSelected={supplier => {
                    this.setState(
                      { supplier_name: supplier.label },
                      this._handleChangeFilter
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextFieldInput
                  id="article"
                  name="article_name"
                  value={this.state.article_name || ''}
                  onChange={this._handleChange}
                  label="Artículo"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <AutocompleteInput
                  id="sale_channel"
                  name="sale_channel_name"
                  label="Medio de recepción"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.cache_sale_channel_name || ''}
                  onChange={change => {
                    if (this.state.sale_channel_name) {
                      this.setState(
                        { sale_channel_name: '' },
                        this._handleChangeFilter
                      );
                    }
                    this.setState({ cache_sale_channel_name: change });
                  }}
                  getSuggestions={value =>
                    this._getSuggestions('saleChannels', value)
                  }
                  onSuggestionSelected={sale_channel => {
                    this.setState(
                      { sale_channel_name: sale_channel.label },
                      this._handleChangeFilter
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Tipo'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'type'}
                  options={commissionTypes}
                  onChange={({ target: { name, value } }) => {
                    this.setState({ [name]: value }, this._handleChangeFilter);
                  }}
                  inputLabel={'Type'}
                  value={this.state.type}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Estado'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'status'}
                  options={statusOptions}
                  onChange={({ target: { name, value } }) => {
                    this.setState({ [name]: value }, this._handleChangeFilter);
                  }}
                  inputLabel={'Status'}
                  value={this.state.status}
                  withChip={false}
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({});

CommissionFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(CommissionFilters);
