import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

import combineStyles from '../../../helpers/combineStyles';
import PromissoryPreviewStyles from './__styles__/PromissoryPreview.styles';
import LoadingIndicator from '../../shared/LoadingIndicator';

export class PromissoryPreview extends PureComponent {
  state = {
    numPages: null,
    pageNumber: 1,
    loading: true
  };

  _handleLoadPDF = () => this.setState({ loading: false });

  render() {
    const { classes, url } = this.props;

    return (
      <div className={classes.root}>
        {this.state.loading && <LoadingIndicator />}

        <iframe
          src={url}
          style={{ width: '100%', height: '100vh', border: 0 }}
          onLoad={this._handleLoadPDF}
        />
      </div>
    );
  }
}

export default withStyles(combineStyles(PromissoryPreviewStyles))(
  PromissoryPreview
);
