import React from 'react';
import Grid from '@material-ui/core/Grid';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import Button from '@material-ui/core/Button';

const Header = props => {
  const {
    classes,
    goToIndexButtonDisabled,
    goToIndex,
    showAcceptProposalButton,
    showRejectProposalButton,
    acceptProposalButtonDisabled,
    onAcceptProposal,
    showSendMinuteButton,
    onSendMinute,
    showMinuteAcceptanceButtons,
    onAcceptMinute,
    onGiveUpMinuteTurn,
    acceptMinuteButtonDisabled,
    onRejectProposal,
    onRejectMinute,
    showNotifyCommentsButton,
    onNotifyComments,
    showSendConfigurationTemplatesButton,
    onSendConfigurationTemplates,
    showGenerateMinuteButton,
    onGenerateMinute,
    showDeleteProposalButton,
    onDeleteProposal,
    showSupplierCreatedOnExternalSystemButton,
    onSupplierCreatedOnExternalSystem,
    showMinuteSignedButton,
    onMinuteSigned,
  } = props;
  const onlyShowRejectProposalButton = (
    showRejectProposalButton &&
    !showAcceptProposalButton &&
    !showSendMinuteButton &&
    !showSendConfigurationTemplatesButton &&
    !showGenerateMinuteButton &&
    !showSupplierCreatedOnExternalSystemButton &&
    !showMinuteSignedButton
  );
  return (
    <Grid container alignItems='center'>
      <Grid container item xs={6}>
        <Grid item xs={4}>
          <Button onClick={goToIndex} disabled={goToIndexButtonDisabled}>
            Volver al listado
          </Button>
        </Grid>
        {
          showNotifyCommentsButton && (
            <Grid container item xs={8}>
              <ProgressButton
                variant="contained"
                color='secondary'
                onClick={onNotifyComments}
              >
                Notificar comentarios
              </ProgressButton>
            </Grid>
          )
        }
        {
          showDeleteProposalButton && (
            <Grid container item xs={8}>
              <ProgressButton
                variant="contained"
                className={classes.rejectProposalButton}
                onClick={onDeleteProposal}
              >
                Borrar propuesta
              </ProgressButton>
            </Grid>
          )
        }
      </Grid>
      {
        showAcceptProposalButton && (
          <Grid container item justify='flex-end' xs={6}>
            <Grid container item justify='flex-end' xs={5}>
              <ProgressButton
                variant='contained'
                className={classes.rejectProposalButton}
                onClick={onRejectProposal}
              >
                Rechazar propuesta
              </ProgressButton>
            </Grid>
            <Grid container item justify='flex-end' xs={5}>
              <ProgressButton
                variant='contained'
                className={classes.acceptProposalButton}
                disabled={acceptProposalButtonDisabled}
                onClick={onAcceptProposal}
              >
                Aceptar propuesta
              </ProgressButton>
            </Grid>
          </Grid>
        )
      }
      {
        showSupplierCreatedOnExternalSystemButton && (
          <Grid container item justify='flex-end' xs={6}>
            <Grid container item justify='flex-end' xs={6}>
              <ProgressButton
                variant='contained'
                className={classes.rejectProposalButton}
                onClick={onRejectProposal}
              >
                Rechazar propuesta
              </ProgressButton>
            </Grid>
            <Grid container item justify='flex-end' xs={4}>
              <ProgressButton
                variant='contained'
                color='secondary'
                onClick={onSupplierCreatedOnExternalSystem}
              >
                Aliado creado
              </ProgressButton>
            </Grid>
          </Grid>
        )
      }
      {
        showSendMinuteButton && (
          <Grid container item justify='flex-end' xs={6}>
            <Grid container item justify='flex-end' xs={5}>
              <ProgressButton
                variant='contained'
                className={classes.rejectProposalButton}
                onClick={onRejectProposal}
              >
                Rechazar propuesta
              </ProgressButton>
            </Grid>
            <Grid container item justify='flex-end' xs={4}>
              <ProgressButton
                className={classes.sendMinuteButton}
                variant="contained"
                color='secondary'
                onClick={onSendMinute}
              >
                Enviar minuta
              </ProgressButton>
            </Grid>
          </Grid>
        )
      }
      {
        showMinuteAcceptanceButtons && (
          <Grid container item justify='flex-end' xs={6}>
            <Grid container item justify='flex-end' xs={4}>
              <ProgressButton
                variant='contained'
                className={classes.rejectProposalButton}
                onClick={onRejectMinute}
              >
                Rechazar minuta
              </ProgressButton>
            </Grid>
            <Grid container item justify='flex-end' xs={4}>
              <ProgressButton
                className={classes.giveUpMinuteButton}
                variant='contained'
                color='secondary'
                onClick={onGiveUpMinuteTurn}
              >
                Devolver
              </ProgressButton>
            </Grid>
            <Grid container item justify='flex-end' xs={4}>
              <ProgressButton
                variant='contained'
                className={classes.acceptProposalButton}
                onClick={onAcceptMinute}
                disabled={acceptMinuteButtonDisabled}
              >
                Aceptar minuta
              </ProgressButton>
            </Grid>
          </Grid>
        )
      }
      {
        showGenerateMinuteButton && (
          <Grid container item justify='flex-end' xs={6}>
            <Grid container item justify='flex-end' xs={6}>
              <ProgressButton
                variant='contained'
                className={classes.rejectProposalButton}
                onClick={onRejectProposal}
              >
                Rechazar propuesta
              </ProgressButton>
            </Grid>
            <Grid container item justify='flex-end' xs={6}>
              <ProgressButton
                variant="contained"
                color='secondary'
                onClick={onGenerateMinute}
              >
                Autocompletar minuta
              </ProgressButton>
            </Grid>
          </Grid>
        )
      }
      {
        showMinuteSignedButton && (
          <Grid container item justify='flex-end' xs={6}>
            <Grid container item justify='flex-end' xs={6}>
              <ProgressButton
                variant='contained'
                className={classes.rejectProposalButton}
                onClick={onRejectProposal}
              >
                Rechazar propuesta
              </ProgressButton>
            </Grid>
            <Grid container item justify='flex-end' xs={4}>
              <ProgressButton
                variant="contained"
                color='secondary'
                onClick={onMinuteSigned}
              >
                Minuta firmada
              </ProgressButton>
            </Grid>
          </Grid>
        )
      }
      {
        showSendConfigurationTemplatesButton && (
          <Grid container item justify='flex-end' xs={6}>
            <Grid container item justify='flex-end' xs={6}>
              <ProgressButton
                variant='contained'
                className={classes.rejectProposalButton}
                onClick={onRejectProposal}
              >
                Rechazar propuesta
              </ProgressButton>
            </Grid>
            <Grid container item justify='flex-end' xs={6}>
              <ProgressButton
                variant='contained'
                className={classes.acceptProposalButton}
                onClick={onSendConfigurationTemplates}
              >
                Enviar plantillas de excel
              </ProgressButton>
            </Grid>
          </Grid>
        )
      }
      {
        onlyShowRejectProposalButton && (
          <Grid container item justify='flex-end' xs={6}>
            <ProgressButton
              variant='contained'
              className={classes.rejectProposalButton}
              onClick={onRejectProposal}
            >
              Rechazar propuesta
            </ProgressButton>
          </Grid>
        )
      }
    </Grid>
  );
}

export default Header;