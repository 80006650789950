import {
  LIST_EXTRA_QUOTA,
  SAVED_EXTRA_QUOTUM
} from '../constants/extra_quotum_constants';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import RequestService from '../services/request_service';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { history } from '../helpers/history';
import {
  geographicLocationFilters,
  extraQuotumSaveSanitizer,
  extraQuotumUpdateSanitizer
} from '../helpers/sanitizers/extraQuotumFilters';
import { newAlert, clearAlerts, serverStatusError } from './alert_actions';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';

const requestService = new RequestService();
const requestServiceNode = new RequestService('node');

function setExtraQuota(data, meta) {
  return {
    type: LIST_EXTRA_QUOTA,
    data,
    meta
  };
}

function savedExtraQuotum() {
  return {
    type: SAVED_EXTRA_QUOTUM
  };
}

export const fetchExtraQuota = (params = {}) => async dispatch => {
  dispatch(sendRequest('ExtraQuotaList'));
  history.replace(`/extra-quota?${stringifyQuery(params)}`);
  try {
    const response = await requestServiceNode.get(
      'extra-quota/getListExtraQuota',
      geographicLocationFilters(params),
      true
    );
    const payload = snakecaseKeys(response);
    dispatch(setExtraQuota(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('ExtraQuotaList'));
  }
};

export const createExtraQuotum = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('SaveExtraQuotum'));
  try {
    await requestServiceNode.post(
      'extra-quota/create',
      extraQuotumSaveSanitizer(camelcaseKeys(params.extra_quotum))
    );
    
    dispatch(savedExtraQuotum());
    dispatch(fetchExtraQuota(fetchParams));
    dispatch(newAlert('success', 's', 'Extracupo creado exitosamente.'));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('SaveExtraQuotum', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('SaveExtraQuotum'));
  }
};

export const updateExtraQuotum = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('SaveExtraQuotum'));
  try {
    const response = await requestServiceNode.post(
      `extra-quota/update`,
      extraQuotumUpdateSanitizer(camelcaseKeys(params.extra_quotum)),
      {},
      true
    );

    const payload = snakecaseKeys(response);
    dispatch(savedExtraQuotum());
    dispatch(fetchExtraQuota(fetchParams));
    dispatch(newAlert('success', 's', "Extracupo modificado exitosamente."));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('SaveExtraQuotum', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('SaveExtraQuotum'));
  }
};
