import {
  LIST_CORPORATIONS_REQUEST,
  CORPORATIONS_INFO_REQUEST,
  LIST_CORPORATION_NAMES_REQUEST
} from '../constants/action_constants';
import { newAlert, serverStatusError } from './alert_actions';
import { sendRequest, completeRequest } from './request_state';
import { corporationFilterSanitizer } from '../helpers/sanitizers/corporationSanitizer';
import _get from 'lodash/get';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import RequestService from '../services/request_service';
const requestNodeService = new RequestService('node');

//* PURE ACTION FUNCTIONS
function setCorporations(items, pagi) {
  return {
    type: LIST_CORPORATIONS_REQUEST,
    data: items,
    meta: pagi
  };
}

function setCorporationNames(items) {
  return {
    type: LIST_CORPORATION_NAMES_REQUEST,
    data: items
  };
}

function setCorporationInfo(info) {
  return {
    type: CORPORATIONS_INFO_REQUEST,
    data: info
  };
}

//* VIEW ACTION FUNCTIONS
export const fetchCorporations = (params = {}) => async dispatch => {
  dispatch(sendRequest('Corporations'));
  history.replace(`/corporations?${stringifyQuery(params)}`);
  try {
    const payload = await requestNodeService.get(
      'corporations',
      corporationFilterSanitizer(params)
    );
    dispatch(setCorporations(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('Corporations'));
  }
};

export const fetchCorporationNames = () => async dispatch => {
  dispatch(sendRequest('CorporationNames'));
  try {
    const payload = await requestNodeService.get('corporations/names');
    dispatch(setCorporationNames(payload.data));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('CorporationNames'));
  }
};

export const fetchCorporationInfo = id => async dispatch => {
  dispatch(sendRequest('CorporationInfo'));
  try {
    const payload = await requestNodeService.get(`corporations/${id}`);
    dispatch(setCorporationInfo(payload.data));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('CorporationInfo'));
  }
};
