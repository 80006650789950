import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import parseQuerystring from '../../../helpers/parseQuerystring';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import TextFieldInput from '../../shared/Inputs/TextField';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';

import { statusOptions } from './dropDownOptions';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';

class ArticleFilters extends PureComponent {
  state = {
    name: '',
    category: '',
    brand: '',
    status: 'todos',
    types: [],
    supplier: ''
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    // console.log('query', query);
    this.setState({
      ...this.state,
      ...query
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter),
      'name',
      'category',
      'brand',
      'status',
      'types',
      'supplier'
    );
    return filterParams;
  };

  _getSupplierSuggestions = async value => {
    const suppliers = this.props.suppliers.map(supplier => ({
      label: supplier.name,
      value: supplier.id
    }));

    const suggestions = filterListFuzzyly(value, suppliers, 'label');

    return Promise.resolve(suggestions);
  };

  _typesInputSize = () => {
    return this.props.enableSupplierFilter ? 6 : 2;
  };

  _typesSmallInputSize = () => {
    return this.props.enableSupplierFilter ? 2 : 4;
  };

  render() {
    const { classes, articleTypes, enableSupplierFilter } = this.props;
    const articleTypesOptions = !!articleTypes
      ? articleTypes.map(articleType => ({
          value: articleType.code,
          title: articleType.name
        }))
      : [];
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={this._handleChange}
                  label="Nombre Artículo"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="category"
                  name="category"
                  value={this.state.category}
                  onChange={this._handleChange}
                  label="Nombre Categoría"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="brand"
                  name="brand"
                  value={this.state.brand}
                  onChange={this._handleChange}
                  label="Nombre Marca"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={this._typesInputSize()}
                sm={this._typesSmallInputSize()}
              >
                <OutlinedSelectChippedInput
                  label={'Tipos'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'types'}
                  options={articleTypesOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'Types'}
                  value={this.state.types}
                  withChip={false}
                  multiple={true}
                />
              </Grid>
              {enableSupplierFilter && (
                <Grid item xs={6} sm={2}>
                  <AutocompleteInput
                    id="supplier"
                    name="supplier"
                    label="Aliado"
                    margin="normal"
                    variant="outlined"
                    error={''}
                    helperText={''}
                    value={this.state.supplier}
                    onChange={change => {
                      if (this.state.supplier && !change) {
                        this._handleSelectChange({
                          target: { name: 'supplier', value: '' }
                        });
                      }
                      this.setState({ supplier: change });
                    }}
                    suggestions={this.props.suppliers}
                    getSuggestions={this._getSupplierSuggestions}
                    onSuggestionSelected={supplier =>
                      this._handleSelectChange({
                        target: { name: 'supplier', value: supplier.label }
                      })
                    }
                  />
                </Grid>
              )}
              <Grid item xs={6} sm={2}>
                <OutlinedSelectChippedInput
                  label={'Estado'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'status'}
                  options={statusOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'Status'}
                  value={this.state.status}
                  withChip={false}
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
ArticleFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(ArticleFilters);
