import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import GridItem from '../../../shared/GridItem';
import { Formik } from 'formik';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../ModalStyles';
import CustomDialog from '../../../shared/Modals/CustomDialog';
import { SUBSEGMENTS_CODES } from '../../../../constants/enums.js';
import { validationSchema } from '../SubSegmentValidation';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';

class FormModal extends Component {
  _handleSave = params => {
    this.props.updateConfig({
      fields: params.values,
      perPage: params.rowsPerPage,
      page: params.currentPage
    });
  }

  _modalProps = (handleSubmit) => {
    return {
      onConfirm: () => handleSubmit(),
      title: 'Modificar Sub Segmento',
      buttonText: 'Guardar',
    };
  };

  _handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.props.clearErrors();
  };

  render() {
    const {
      onClose,
      classes,
      submitLoading,
      rowsPerPage,
      currentPage,
      config
    } = this.props;
    return (
      <div>
        <Formik
          initialValues={{ ...config }}
          validationSchema={validationSchema}
          onSubmit={values => this._handleSave({ values, rowsPerPage, currentPage })}
          onReset={(values, actions) => {
            actions.resetForm();
          }}
          render={({
            values,
            handleSubmit,
            handleReset,
            handleChange,
            touched,
            errors,
            dirty
          }) => {
            return (
              <CustomDialog
                open={true}
                loading={submitLoading}
                disabled={submitLoading || !dirty}
                disableBackdropClick={false}
                headerSecondary
                onClose={() => {
                  handleReset();
                  onClose();
                }}
                {...this._modalProps(handleSubmit)}
                classes={{
                  root: classes.dialogRoot
                }}
              >
                <Grid container spacing={16}>
                  <GridItem xs={12} sm={12}>
                    <OutlinedTextField
                      id="name"
                      name="name"
                      label="Nombre"
                      type="string"
                      margin="normal"
                      variant="outlined"
                      value={values.name}
                      onChange={handleChange}
                      error={
                        touched.name && !!errors.name
                      }
                      helperText={
                        touched.name && errors.name
                      }
                      classes={{
                        root: classes.autocomplete
                      }}
                    />
                  </GridItem>
                  {(values.code === SUBSEGMENTS_CODES.DistantFuture || values.code === SUBSEGMENTS_CODES.MidFuture) && (
                    <GridItem xs={12} sm={12}>
                      <OutlinedTextField
                        id="lowerLimit"
                        name="lowerLimit"
                        label="Límite inferior"
                        type="number"
                        margin="normal"
                        variant="outlined"
                        value={values.lowerLimit}
                        onChange={handleChange}
                        error={
                          touched.lowerLimit && !!errors.lowerLimit
                        }
                        helperText={
                          touched.lowerLimit && errors.lowerLimit
                        }
                        classes={{
                          root: classes.autocomplete
                        }}
                      />
                    </GridItem>
                  )
                  }
                  {(values.code === SUBSEGMENTS_CODES.NearFuture || values.code === SUBSEGMENTS_CODES.MidFuture) && (
                    <GridItem xs={12} sm={12}>
                      <OutlinedTextField
                        id="upperLimit"
                        name="upperLimit"
                        label="Límite superior"
                        type="number"
                        margin="normal"
                        variant="outlined"
                        value={values.upperLimit}
                        onChange={handleChange}
                        error={
                          touched.upperLimit && !!errors.upperLimit
                        }
                        helperText={
                          touched.upperLimit && errors.upperLimit
                        }
                        classes={{
                          root: classes.autocomplete
                        }}
                      />
                    </GridItem>
                  )
                  }
                  {(values.code === SUBSEGMENTS_CODES.New || values.code === SUBSEGMENTS_CODES.Undecided) && (
                    <GridItem xs={12} sm={12}>
                      <OutlinedTextField
                        id="quotaAssignmentTimeLimit"
                        name="quotaAssignmentTimeLimit"
                        label="Tiempo límite de asignación de cupo"
                        type="number"
                        margin="normal"
                        variant="outlined"
                        value={values.quotaAssignmentTimeLimit}
                        onChange={handleChange}
                        error={
                          touched.quotaAssignmentTimeLimit && !!errors.quotaAssignmentTimeLimit
                        }
                        helperText={
                          touched.quotaAssignmentTimeLimit && errors.quotaAssignmentTimeLimit
                        }
                        classes={{
                          root: classes.autocomplete
                        }}
                      />
                    </GridItem>
                  )
                  }
                  {(values.code === SUBSEGMENTS_CODES.RolloverWithDebt || values.code === SUBSEGMENTS_CODES.RolloverWithoutDebt) && (
                    <GridItem xs={12} sm={12}>
                      <OutlinedTextField
                        id="salesToBeRollover"
                        name="salesToBeRollover"
                        label="Número de ventas para ser rollover"
                        type="number"
                        margin="normal"
                        variant="outlined"
                        value={values.salesToBeRollover}
                        onChange={handleChange}
                        error={
                          touched.salesToBeRollover && !!errors.salesToBeRollover
                        }
                        helperText={
                          touched.salesToBeRollover && errors.salesToBeRollover
                        }
                        classes={{
                          root: classes.autocomplete
                        }}
                      />
                    </GridItem>
                  )
                  }
                </Grid>
              </CustomDialog>
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(FormModal);
