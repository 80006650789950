import * as Yup from 'yup';
import { SUBSEGMENTS_CODES } from '../../../constants/enums.js';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('El campo es requerido'),
  lowerLimit: Yup.number()
    .when('code', code => {
      if ([SUBSEGMENTS_CODES.DistantFuture, SUBSEGMENTS_CODES.MidFuture].includes(code)) {
        return Yup.number().required(
          'El campo es requerido'
        );
      } else {
        return Yup.number().nullable();
      }
    }),
  upperLimit: Yup.number()
    .when('code', code => {
      if ([SUBSEGMENTS_CODES.NearFuture, SUBSEGMENTS_CODES.MidFuture].includes(code)) {
        return Yup.number().required(
          'El campo es requerido'
        );
      } else {
        return Yup.number().nullable();
      }
    }),
  quotaAssignmentTimeLimit: Yup.number()
    .when('code', code => {
      if ([SUBSEGMENTS_CODES.New, SUBSEGMENTS_CODES.Undecided].includes(code)) {
        return Yup.number().required(
          'El campo es requerido'
        );
      } else {
        return Yup.number().nullable();
      }
    }),
  salesToBeRollover: Yup.number()
    .when('code', code => {
      if ([SUBSEGMENTS_CODES.RolloverWithDebt, SUBSEGMENTS_CODES.RolloverWithoutDebt].includes(code)) {
        return Yup.number().required(
          'El campo es requerido'
        );
      } else {
        return Yup.number().nullable();
      }
    })
});
