import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { debtorValidation, cosignerValidation } from './DigitalSaleContactDataValidation';

import SegmentHeader from '../../shared/SegmentHeader';
import StepperButtons from '../../shared/StepperButtons';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import {
  initialValuesSanitizer,
  submitSanitizer
} from '../../../helpers/sanitizers/digitalSaleContactDataSanitizer';

import { doctypes } from '../../../constants/enums';

class DigitalSaleContactData extends PureComponent {
  state = {
    externalTabOpened: false
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
  }

  initialValues = () => {
    return initialValuesSanitizer(this.props);
  };

  getDocTypeValue = debtorIdentificationType => {
    return doctypes.find(type => type.id === debtorIdentificationType || type.name === debtorIdentificationType).value;
  };

  onSubmit = values => {
    const parsedValues = submitSanitizer(values, this.props.saleDetails, this.props.process);
    if (this.props.isRemoteTeleshopping) {
      this.props.updateContactDataTeleshopping(
        parsedValues,
        this.props.contract,
        this.props.routes[this.nextStep],
        this.props.process
      );
    } else {
      this.props.searchCacheSaleResponse(this.props.contract).then(() => {
        this.props.updateContactData(
          parsedValues,
          this.props.contract,
          this.props.routes[this.nextStep],
          this.props.process
        );
      })
    }
  };

  render() {
    const { classes, promissory, cosigner, isRemoteTeleshopping } = this.props;

    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            initialValues={{
              ...this.initialValues(),
              promissory
            }}
            validationSchema={cosigner ? debtorValidation.concat(cosignerValidation) : debtorValidation}
            onSubmit={this.onSubmit}
            render={({
              values,
              handleChange,
              errors,
              touched,
              handleSubmit
            }) => (
              <Form>
                <SegmentHeader className={classes.segmentHeader}>
                  {'Datos de contacto del Deudor'}
                </SegmentHeader>
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item md={4} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled
                      name="debtorName"
                      label="Nombres del titular: *"
                      value={values.debtorName}
                      onChange={handleChange}
                      error={touched.debtorName && !!errors.debtorName}
                      helperText={touched.debtorName && errors.debtorName}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled
                      name="debtorLastname"
                      label="Apellidos del titular:"
                      value={values.debtorLastname}
                      onChange={handleChange}
                      error={touched.debtorLastname && !!errors.debtorLastname}
                      helperText={
                        touched.debtorLastname && errors.debtorLastname
                      }
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled
                      name="debtorDoctype"
                      label="Tipo de documento:"
                      value={this.getDocTypeValue(values.debtorDoctype)}
                      onChange={handleChange}
                      error={touched.debtorDoctype && !!errors.debtorDoctype}
                      helperText={touched.debtorDoctype && errors.debtorDoctype}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled
                      name="debtorDocid"
                      label="Numero:"
                      value={values.debtorDocid}
                      onChange={handleChange}
                      error={touched.debtorDocid && !!errors.debtorDocid}
                      helperText={touched.debtorDocid && errors.debtorDocid}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled={isRemoteTeleshopping}
                      name="debtorEmail"
                      label="Correo electronico:"
                      value={values.debtorEmail}
                      onChange={handleChange}
                      error={touched.debtorEmail && !!errors.debtorEmail}
                      helperText={touched.debtorEmail && errors.debtorEmail}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled={isRemoteTeleshopping}
                      name="debtorPhone"
                      label="Telefono:"
                      value={values.debtorPhone}
                      onChange={handleChange}
                      error={touched.debtorPhone && !!errors.debtorPhone}
                      helperText={touched.debtorPhone && errors.debtorPhone}
                      type={'number'}
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 7);
                      }}
                      min={0}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled={isRemoteTeleshopping}
                      name="debtorCellphone"
                      label="Telefono celular:"
                      value={values.debtorCellphone}
                      onChange={handleChange}
                      error={
                        touched.debtorCellphone && !!errors.debtorCellphone
                      }
                      helperText={
                        touched.debtorCellphone && errors.debtorCellphone
                      }
                      type={'number'}
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      min={0}
                    />
                  </Grid>
                </Grid>
                {
                  cosigner && (
                  <div>
                    <SegmentHeader className={classes.segmentHeader}>
                    {'Datos de contacto del Codeudor'}
                    </SegmentHeader>
                    <Grid container spacing={24} alignItems="flex-start">
                      <Grid item md={4} sm={6} xs={12}>
                        <OutlinedTextField
                          disabled
                          name="cosignerName"
                          label="Nombres del codeudor: *"
                          value={values.cosignerName}
                          onChange={handleChange}
                          error={touched.cosignerName && !!errors.cosignerName}
                          helperText={touched.cosignerName && errors.cosignerName}
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <OutlinedTextField
                          disabled
                          name="cosignerLastname"
                          label="Apellidos del codeudor:"
                          value={values.cosignerLastname}
                          onChange={handleChange}
                          error={touched.cosignerLastname && !!errors.cosignerLastname}
                          helperText={
                            touched.cosignerLastname && errors.cosignerLastname
                          }
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <OutlinedTextField
                          disabled
                          name="cosignerDoctype"
                          label="Tipo de documento:"
                          value={this.getDocTypeValue(values.cosignerDoctype)}
                          onChange={handleChange}
                          error={touched.cosignerDoctype && !!errors.cosignerDoctype}
                          helperText={touched.cosignerDoctype && errors.cosignerDoctype}
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <OutlinedTextField
                          disabled
                          name="cosignerDocid"
                          label="Numero:"
                          value={values.cosignerDocid}
                          onChange={handleChange}
                          error={touched.cosignerDocid && !!errors.cosignerDocid}
                          helperText={touched.cosignerDocid && errors.cosignerDocid}
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <OutlinedTextField
                          name="cosignerEmail"
                          label="Correo electronico:"
                          value={values.cosignerEmail}
                          onChange={handleChange}
                          error={touched.cosignerEmail && !!errors.cosignerEmail}
                          helperText={touched.cosignerEmail && errors.cosignerEmail}
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <OutlinedTextField
                          name="cosignerPhone"
                          label="Telefono:"
                          value={values.cosignerPhone}
                          onChange={handleChange}
                          error={touched.cosignerPhone && !!errors.cosignerPhone}
                          helperText={touched.cosignerPhone && errors.cosignerPhone}
                          type={'number'}
                          onInput={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 7);
                          }}
                          min={0}
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <OutlinedTextField
                          name="cosignerCellphone"
                          label="Telefono celular:"
                          value={values.cosignerCellphone}
                          onChange={handleChange}
                          error={
                            touched.cosignerCellphone && !!errors.cosignerCellphone
                          }
                          helperText={
                            touched.cosignerCellphone && errors.cosignerCellphone
                          }
                          type={'number'}
                          onInput={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 10);
                          }}
                          min={0}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  )
                }
                <StepperButtons
                  onSubmit={handleSubmit}
                  goBack={this.props.goBack}
                />
              </Form>
            )}
          />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  liDivider: {
    listStyleType: 'none'
  },
  item: {
    margin: 20,
    textAlign: 'center'
  },
  headline: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  }
});

DigitalSaleContactData.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(DigitalSaleContactData);
