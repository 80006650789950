import { newAlert } from './alert_actions';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { history } from '../helpers/history';
import { submitSanitizer } from '../helpers/sanitizers/aspiringSupplierSignUpPageActionsSanitizer';
import AxiosRequest from '../services/axios_request';
import { setCurrentUser } from './authentication_actions';
import changeCaseKeys from 'change-case-keys';

const axiosRequest = new AxiosRequest();

export const submit = data => async dispatch => {
  dispatch(sendRequest('AspiringSupplierSignUp'));
  try {
    const payload = await axiosRequest.post(
      'users/aspiring_suppliers',
      submitSanitizer(data.params)
    );
    const currentUser = changeCaseKeys(payload.data, 'camelize');
    dispatch(setCurrentUser(currentUser));
    dispatch(newAlert('success', 'sucess', 'Usuario Creado exitosamente'));
    history.push('/supplier-proposals');
  } catch (errors) {
    const key = Object.keys(errors.errors)[0];
    const message = errors.errors[key][0];
    dispatch(newAlert('error', 'ERROR:', message));
  } finally {
    dispatch(completeRequest('AspiringSupplierSignUp'));
  }
};
