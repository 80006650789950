import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import combineStyles from '../../helpers/combineStyles';
import TypographyStyles from '../../styles/typography';
import ProgressBarStyles from './__styles__/ProgressBar.styles';

export const ProgressBar = ({
  classes,
  color,
  totalValue,
  value,
  resourceType = ''
}) => {
  const progressPercentage =
    value > 0 ? (value / totalValue * 100).toFixed(4) : 0;
  return (
    <Tooltip
      title={`${progressPercentage}% of ${totalValue} ${resourceType}`}
      classes={{
        tooltip: classnames(classes.caption, classes.tooltip),
        popper: classes.tooltipPopper
      }}
      placement="top-end"
    >
      <div className={classes.root}>
        <div
          className={classes.indicatorBar}
          style={{ backgroundColor: color }}
        />
        <div
          className={classes.progressBar}
          style={{ backgroundColor: color, width: `${progressPercentage}%` }}
        />
      </div>
    </Tooltip>
  );
};

ProgressBar.propTypes = {
  color: PropTypes.string,
  totalValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  resourceType: PropTypes.string
};

export default withStyles(combineStyles(TypographyStyles, ProgressBarStyles))(
  ProgressBar
);
