import {
  LIST_CORPORATIONS_REQUEST,
  CORPORATIONS_INFO_REQUEST,
  LIST_CORPORATION_NAMES_REQUEST
} from '../constants/action_constants';

const initialState = {
  corporations: [],
  meta: {},
  corporationNames: []
};

export function corporations(state = initialState, action) {
  switch (action.type) {
    case LIST_CORPORATIONS_REQUEST:
      return {
        ...state,
        corporations: action.data,
        meta: action.meta
      };
    case CORPORATIONS_INFO_REQUEST:
      return {
        ...state,
        corporationInfo: action.data
      };
    case LIST_CORPORATION_NAMES_REQUEST:
      return {
        ...state,
        corporationNames: action.data
      };
    default:
      return state;
  }
}
