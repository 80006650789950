import {
  SET_SUBSEGMENTS_LIST_DATA,
  OPEN_SUBSEGMENTS_FORM_MODAL,
  CLOSE_SUBSEGMENTS_FORM_MODAL
} from '../constants/action_constants';
import { sendRequest, completeRequest } from './request_state';
import { serverStatusError, newAlert } from './alert_actions';
import { listSanitizer, updateSanitizer } from '../helpers/sanitizers/subSegmentSanitizer';
import RequestService from '../services/request_service';

const requestService = new RequestService('node');

export const fetchSubSegmentsList = params => (
  async dispatch => {
    dispatch(sendRequest('SubSegmentList'));
    try {
      const payload = await requestService.get(
        'contracts/subsegments',
        listSanitizer(params)
      );
      dispatch(setSubSegmentsListData(payload));
    } catch (errors) {
      dispatch(serverStatusError(errors));
      if (errors) {
        const e = await errors.json();
        e &&
          dispatch(
            newAlert('error', 'ERROR:', e.errors.base)
          );
      }
    } finally {
      dispatch(completeRequest('SubSegmentList'));
    }
  }
);

export const updateSubSegment = params => (
  async dispatch => {
    dispatch(sendRequest('SubSegmentUpdate'));
    try {
      await requestService.put(
        `contracts/subsegment/${params.fields.id}`,
        updateSanitizer(params.fields)
      );
      dispatch(_closeSubSegmentsFormModal());
      await fetchSubSegmentsList({ page: params.page, perPage: params.perPage })(dispatch);
    } catch (errors) {
      dispatch(serverStatusError(errors));
      if (errors) {
        const e = await errors.json();
        e &&
          dispatch(
            newAlert('error', 'ERROR:', e.errors.base)
          );
      }
    } finally {
      dispatch(completeRequest('SubSegmentUpdate'));
    }
  }
);

export const openSubSegmentsFormModal = () => (
  dispatch => {
    dispatch(_openSubSegmentsFormModal());
  }
)

export const closeSubSegmentsFormModal = () => (
  dispatch => {
    dispatch(_closeSubSegmentsFormModal());
  }
)


const setSubSegmentsListData = payload => {
  return {
    type: SET_SUBSEGMENTS_LIST_DATA,
    data: payload.data,
    meta: payload.meta
  };
};

const _openSubSegmentsFormModal = () => {
  return {
    type: OPEN_SUBSEGMENTS_FORM_MODAL
  };
}

const _closeSubSegmentsFormModal = () => {
  return {
    type: CLOSE_SUBSEGMENTS_FORM_MODAL
  };
}
