export function pointOfSaleValidation(
  default_point_of_sale,
  default_point_of_sale_id,
  can_select_point_of_sale,
  api_error,
  returnBool
) {
  if (!default_point_of_sale && !can_select_point_of_sale)
    return returnBool ? true : 'Debes seleccionar un punto de venta.'
  if (!default_point_of_sale) return returnBool ? false : '';
  if (api_error && api_error.default_point_of_sale_id)
    return returnBool ? true : api_error.default_point_of_sale_id[0];
  if (!default_point_of_sale_id)
    return returnBool ? true : 'El punto de venta tiene que ser válido.';
  return returnBool ? false : '';
}

export function saleChannelValidation(
  default_sale_channel,
  default_sale_channel_id,
  can_select_sale_channel,
  api_error,
  returnBool
) {
  if (!default_sale_channel && !can_select_sale_channel)
    return returnBool ? true : 'Debes seleccionar un canal venta.'
  if (!default_sale_channel) return returnBool ? false : '';
  if (api_error && api_error.recovery_percentage)
    return returnBool ? true : api_error.default_sale_channel_id[0];
  if (!default_sale_channel_id)
    return returnBool ? true : 'El canal de venta tiene que ser válido.';
  return returnBool ? false : '';
}
