/* eslint-disable complexity */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import StepperButtons from '../../shared/StepperButtons';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { doctypes } from '../../../constants/enums';
import OurDatePicker from '../../shared/CustomDatePicker';
import SegmentHeader from '../../shared/SegmentHeader';

import { Formik } from 'formik';
import { validationSchema } from './PortalRegisterFinancingValidation';
import moment from 'moment-timezone';
import RadioField from '../../shared/Inputs/RadioField';
import { options } from './PortalRegisterMetadata';

class PortalRegisterFinancingPage extends Component {
  state = {
    contract: '',
    debtorDoctype: 'Cédula',
    debtorDocid: '',
    birthday: null
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
    this.props.resetQueryCampaign();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.baseErrorOpen !== this.props.baseErrorOpen &&
      !this.props.baseErrorOpen
    ) {
      this.formik.resetForm(this.state);
    }
  }

  onSubmit = values => {
    const {
      contract,
      debtorDoctype,
      debtorDocid,
      birthday,
      useGnpSale
    } = values;
    const { process, routes } = this.props;
    const params = {
      debtorContractNumber: contract,
      debtorIdentificationType: debtorDoctype,
      debtorIdentification: debtorDocid,
      debtorBirthdate: moment(birthday).format('YYYY-MM-DD')
    };
    if (process === 'portal' || process === 'remoteDigitalSaleWithUUP') {
      params['makeGnpSale'] = useGnpSale === 'true' ? true : false;
      this.props.locationValidityCheck(params,  process).then(canSell => canSell && this.props.checkFirstStepRegisterSale(
        params,
        routes[this.nextStep],
        process
      ));
    } else if (process === 'promissory') {
      this.props.checkFirstStepRegisterPromissory(
        params,
        routes[this.nextStep],
        process
      );
    }
  };

  docTypesWithoutIdentificationCard = () => {
    return doctypes.filter(docType => docType.name !== 'Tarjeta de Identidad');
  };

  render() {
    const {
      classes,
      serverErrors = {},
      gnpSupplierId,
      userSupplierId,
      process,
      isBlocked = false,
      isBlackListActive
    } = this.props;
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            ref={ref => (this.formik = ref)}
            enableReinitialize={true}
            initialValues={this.state}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            // eslint-disable-next-line complexity
            render={({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              setFieldValue
            }) => (
              <form autoComplete="off" className={classes.form}>
                <Grid container spacing={24} alignItems="flex-start">
                  <SegmentHeader className={classes.segmentHeader}>
                    INFORMACIÓN DEUDOR
                  </SegmentHeader>
                  <Grid item sm={6} xs={12}>
                    <OutlinedTextField
                      label="Numero de contrato:"
                      name="contract"
                      type={'number'}
                      error={
                        (touched.contract && !!errors.contract) ||
                        (serverErrors && !!serverErrors.contract)
                      }
                      helperText={
                        (touched.contract && errors.contract) ||
                        (serverErrors && serverErrors.contract)
                      }
                      value={values.contract}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <SimpleSelect
                      value={values.debtorDoctype}
                      onChange={handleChange}
                      name="debtorDoctype"
                      label="Tipo de identificacion del deudor:"
                      options={mapListToOptions(
                        this.docTypesWithoutIdentificationCard(),
                        {},
                        true
                      )}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OutlinedTextField
                      label="Identificacion del deudor:"
                      name="debtorDocid"
                      error={
                        (touched.debtorDocid && !!errors.debtorDocid) ||
                        (serverErrors && !!serverErrors.debtor)
                      }
                      helperText={
                        (touched.debtorDocid && errors.debtorDocid) ||
                        (serverErrors && serverErrors.debtor)
                      }
                      value={values.debtorDocid}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OurDatePicker
                      name={'birthday'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="Fecha inválida"
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha nacimiento deudor:"
                      value={values.birthday}
                      onChange={e => {
                        setFieldValue('birthday', e);
                      }}
                      onError={() => setFieldValue('birthday', null)}
                      error={
                        (touched.birthday && !!errors.birthday) ||
                        (serverErrors && serverErrors.debtor_birthdate)
                      }
                      helperText={
                        (touched.birthday && errors.birthday) ||
                        (serverErrors && serverErrors.debtor_birthdate)
                      }
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {gnpSupplierId === userSupplierId && process === 'portal' && (
                  <React.Fragment>
                    <SegmentHeader className={classes.segmentHeader}>
                      USAR VENTA ESPECIAL A GNP (OPCIONAL)
                    </SegmentHeader>
                    <Grid container spacing={24} alignItems="flex-start">
                      <Grid item xs={12}>
                        <RadioField
                          options={options}
                          onChange={handleChange}
                          value={values.useGnpSale}
                          name="useGnpSale"
                          classes={{
                            radioButton: classes.radioButtonContainer
                          }}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                <div className={classes.spacer} />
                <StepperButtons
                  onSubmit={handleSubmit}
                  disabledNext={isBlocked && isBlackListActive}
                />
              </form>
            )}
          />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  }
});

PortalRegisterFinancingPage.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(PortalRegisterFinancingPage);
