import { LIST_SCORING_RESPONSES } from '../constants/action_constants';

const initialState = {
  scoringList: [],
  meta: {}
};

export function scoring(state = initialState, action) {
  switch (action.type) {
    case LIST_SCORING_RESPONSES:
      return {
        ...state,
        scoringList: action.data,
        meta: action.meta
      };
    default:
      return state;
  }
}
