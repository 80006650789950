import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  minNumOfInstalments: Yup
    .number()
    .typeError('Debe ser un número')
    .integer('Debe ser entero')
    .positive('Debe ser positivo')
    .required('La cuota mínima es requerida'),
  maxNumOfInstalments: Yup.number()
    .typeError('Debe ser un número')
    .integer('Debe ser entero')
    .positive('Debe ser positivo')
    .when('minNumOfInstalments', () => {
      return Yup.number()
        .typeError('Debe ser un número')
        .integer('Debe ser entero')
        .positive('Debe ser positivo')
        .moreThan(Yup.ref('minNumOfInstalments'), 'La cuota máxima debe ser mayor la cuota mínima');
    }).required('La cuota máxima es requerida'),
  percentage: Yup
    .number()
    .typeError('Debe ser un número')
    .positive('Debe ser positivo')
    .max(100, 'El valor maximo a ingresar es 100')
    .required('El porcentaje es requerido'),
});

export default validationSchema;