import { RETURN_STATUS } from '../../../constants/enums';
import moment from 'moment-timezone';
import _get from 'lodash/get';

export const columns = [
  {
    title: '# Venta',
    name: 'saleId',
    componentType: 'Link'
  },
  {
    title: 'Fecha',
    name: 'date'
  },
  {
    title: '# Contrato',
    name: 'contract'
  },
  {
    title: 'Estado',
    name: 'status'
  },
  {
    title: 'Creador',
    name: 'creator'
  },
  {
    title: 'Revisor',
    name: 'reviewer'
  },
  {
    title: 'Departamento',
    name: 'department'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      saleId: datum.sale_id || '--',
      saleIdLinkTo: `/sales/${datum.sale_id}`,
      type:
        datum.sale_return_details[0].return_type == 'canceled'
          ? 'Anulación'
          : 'Devolución',
      date:
        moment(datum.sale_return_details[0].created_at)
          .locale('es')
          .format('DD MMM YYYY') || '--',
      status: RETURN_STATUS[datum.sale_return_details[0].status],
      order_id: datum.order_number || 'En proceso',
      creator: _get(
        datum.movements.find(movement => movement.status === 'registered'),
        'user.username',
        '--'
      ),
      reviewer: _get(
        datum.movements.find(movement => movement.status === 'reviewed'),
        'user.username',
        '--'
      ),
      department: datum.zone || '--'
    };
  });
