import { REQUEST_SEND, REQUEST_COMPLETE, REQUEST_ERRORS } from '../constants/action_constants';

const initialState = {
  requestSend: false,
};

export default (state = initialState, action = {}) => {
  const requestType = `request${action.data}Send`
  const requestFormCompleted = `requestFormCompletedFor${action.data}`

  switch (action.type) {
    case REQUEST_SEND:
      return {
        ...state,
        [requestType]: true
      };
    case REQUEST_COMPLETE:
      return {
        ...state,
        [requestType]: false
      };
    case REQUEST_ERRORS:
      const requestErrorType = `requestErrorsOn${action.data}`
      return {
        ...state,
        [requestErrorType]: action.errors
      };
    default:
      return state;
  }
};
