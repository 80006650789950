const styles = theme => ({
  filterOptionsWrapper: {
    minHeight: 70,
    backgroundColor: '#FFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2 + 'px ' + theme.spacing.unit * 3 + 'px',
    paddingTop: theme.spacing.unit
  },
  collapseFilterOptionsWrapper: {
    minHeight: 88,
    backgroundColor: '#FFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2 + 'px ' + theme.spacing.unit * 3 + 'px'
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(12,31,44, 0.04)'
    }
  }
});

export default styles;
