import UserUpdatePassword from './UserUpdatePassword';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { logout } from '../../../actions/authentication_actions';
import { withRouter } from 'react-router-dom';
import { newAlert } from '../../../actions/alert_actions';
import {
  fetchSessionData,
  updatePasswordWithToken,
  validateUserToken
} from '../../../actions/session_actions';
import { confirmUserEmail } from '../../../actions/user_actions';

function mapStateToProps(state) {
  return {
    permissions: state.authentication.permissions,
    currentUser: state.authentication.currentUser,
    requestOrgDataSend: state.requestState.requestOrgDataSend,
    requestAnnouncementsSend: state.requestState.requestAnnouncementsSend,
    orgData: state.globalSetting.orgData,
    notifications: state.notification.previewList,
    companyCode: state.sessionData.companyName,
    announcements: state.announcement.previewList,
    routes: state.stepper.routes,
    process: state.stepper.process,
    steps: state.stepper.steps,
    isSubmitting: state.requestState.requestNewUserSend,
    teleshoppingCompleted: state.teleshopping.teleshoppingCompleted,
    teleshoppingNext: state.stepper.teleshoppingNext,
    activeStep: state.stepper.activeStep,
    nextStep: state.stepper.nextStep
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: bindActionCreators(logout, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchSessionData: bindActionCreators(fetchSessionData, dispatch),
    confirmUserEmail: bindActionCreators(confirmUserEmail, dispatch),
    validateUserToken: bindActionCreators(validateUserToken, dispatch),
    updatePasswordWithToken: bindActionCreators(
      updatePasswordWithToken,
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserUpdatePassword);
