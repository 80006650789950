export const previewPromissorySanitizer = values => {
  const {
    promissoryId,
    unmaterializedPromissoryProcessId
  } = values;

  return {
    promissoryId,
    documentType: 'promissory',
    externalProcessId: unmaterializedPromissoryProcessId
  }
}
