import moment from 'moment-timezone';

export const statusOptions = [
  { value: 'todas', title: 'Todas' },
  { value: 'registered', title: 'Registrada' },
  { value: 'approved', title: 'Aprobada' }
];

export const originalDateOptions = [
  { value: 'todas', title: 'Todas' },
  {
    value: `${moment()
      .startOf('week')
      .format('YYYY-MM-DD')} - ${moment()
      .endOf('week')
      .format('YYYY-MM-DD')}`,
    title: 'Esta semana'
  },
  {
    value: `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')} - ${moment()
      .endOf('month')
      .format('YYYY-MM-DD')}`,
    title: 'Este mes'
  }
];

export const dateOptions = (customDate, registered_at) => [
  ...originalDateOptions,
  {
    value: customDate !== 'pickCustomDate' ? registered_at : 'pickCustomDate',
    title: customDate !== 'pickCustomDate' ? registered_at : 'Personalizada'
  }
];