const styles = theme => ({
  progressBarContainer: {
    paddingLeft: theme.spacing.unit * 5.75,
    paddingRight: theme.spacing.unit * 5.75,
    paddingTop: theme.spacing.unit * 3.75
  },
  rejectText: {
    color: '#F22D12',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold !important'
  },
  headerRoot: {
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    width: '100%'
  },
  promissoryDetails: {
    alignItems: 'center',
    display: 'flex',
    minHeight: 84,
    flexWrap: 'wrap',
    padding: [[0, theme.spacing.unit * 3]],
    position: 'relative',
    '&:first-child': {
      borderBottom: '1px solid rgba(12,31,44,0.12)'
    }
  },
  backButton: {
    position: 'absolute',
    left: 16
  },
  invoiceNumber: {
    marginRight: [theme.spacing.unit, '!important']
  },
  promissoryStatus: {
    borderRadius: 18,
    height: 36,
    padding: [[0, theme.spacing.unit * 1.5]],
    display: 'flex',
    alignItems: 'center',
    marginLeft: [theme.spacing.unit * 2, '!important'],
    fontWeight: ['bold', '!important'],
    position: ['relative', '!important'],
    left: [0, '!important']
  },
  inReview: {
    backgroundColor: '#EBEFF3',
    color: ['rgba(12,31,44,0.54)', '!important']
  },
  error: {
    backgroundColor: '#FDE5E2',
    color: ['#F22D12', '!important']
  },
  invoiceDetailItem: {
    marginRight: [theme.spacing.unit * 8, '!important'],
    '&:last-child': {
      marginRight: 0
    }
  },
  invoiceDetailAttribute: {
    marginBottom: [theme.spacing.unit * 0.5, '!important']
  },
  root: {
    backgroundColor: '#FFF',
    borderRadius: '2px',
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    padding: theme.spacing.unit * 4,
    width: '100%',
    marginBottom: '10px'
  },
  headerSection: {
    flex: 1,
    paddingBottom: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    borderBottom: '1px solid rgba(0,0,0,0.12)'
  },
  bodySection: {
    display: 'flex',
    flex: 1
  },
  sidebarInfo: {
    paddingRight: theme.spacing.unit * 4,
    width: '22.169%'
  },
  contentDetails: {
    borderLeft: '1px solid rgba(0,0,0,0.12)',
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    width: '53.616%'
  },
  sidebarActivity: {
    borderLeft: '1px solid rgba(0,0,0,0.12)',
    paddingLeft: theme.spacing.unit * 4,
    width: '24.213%'
  },
  contentDividerInt: {
    paddingBottom: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 3.5,
    borderBottom: '1px solid rgba(0,0,0,0.12)',
    '&:last-child': {
      marginBottom: 0,
      borderBottom: 0
    }
  },
  shipmentContentTitle: {
    marginBottom: theme.spacing.unit * 2
  },
  noMarginBottom: {
    marginBottom: 0
  },
  hideSection: {
    display: 'none'
  },
  editButton: {
    paddingRight: '0 !important',
    height: '28px !important'
  },
  buttonRoot: {
    '&:hover': {
      //you want this to be the same as the backgroundColor above
      backgroundColor: '#fff'
    }
  },
  toolbarRoot: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing.unit * 5
  },
  actionButtons: {
    '& button': {
      marginLeft: '16px',
      height: '40px'
    }
  },
  buttonsRoot: {
    padding: 0
  },
  container: {
    flex: '1 auto',
    '& span': {
      float: 'left'
    }
  },
  estimatedArrival: {
    marginLeft: '16px'
  },
  listIcon: {
    float: 'left'
  },
  mapIcon: {
    float: 'right',
    textAlign: 'right'
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  statusBar: {
    minHeight: '48px',
    minWidth: '220px',
    backgroundColor: '#FEE5B1',
    border: '1px solid #FEB00B',
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    fontFamily: 'Roboto',
    fontSize: '16px'
  },
  statusLabel: {
    fontWeight: 'bold'
  },
  statusDropdown: {
    marginRight: '0'
  },
  shipmentId: {
    fontFamily: 'Rubik',
    fontSize: '24px',
    fontWeight: '500'
  },
  shipmentIdRow: {
    marginBottom: '27px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    minHeight: '29px',
    minWidth: '220px'
  },
  verticalLine: {
    width: '1px',
    backgroundColor: '#E0E4E6',
    height: '17px',
    float: 'left',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  viewButtons: {
    width: '80px',
    height: '24px',
    '& .icon': {
      display: 'inline',
      width: '50%',
      height: '100%'
    },
    marginLeft: '30px'
  },
  menuItemRoot: {
    color: 'rgba(12,31,44,0.87)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    backgroundColor: '#FFF !important',
    height: '40px',
    padding: '0 9px',
    '&:hover': {
      backgroundColor: 'whitesmoke !important'
    }
  },
  menuItemSelected: {
    backgroundColor: '#ebebeb !important'
  }
});

export default styles;
