import ChangePasswordPage from './ChangePasswordPage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { newAlert } from '../../../actions/alert_actions';
import {
  fetchSessionData,
  updatePasswordWithToken,
  validateUserToken
} from '../../../actions/session_actions';

function mapStateToProps(state) {
  return {
    loading: state.requestState.requestLoginSend,
    requestChangePasswordSend: state.requestState.requestUpdatePasswordSend,
    requestErrorsOnChangePassword:
      state.requestState.requestErrorsOnUpdatePassword,
    companyCode: state.sessionData.companyName
  };
}
function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchSessionData: bindActionCreators(fetchSessionData, dispatch),
    validateUserToken: bindActionCreators(validateUserToken, dispatch),
    updatePasswordWithToken: bindActionCreators(
      updatePasswordWithToken,
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordPage);
