import DigitalSalesDocumentRevisionList from './DigitalSalesDocumentRevisionList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  fetchDigitalSalesDocumentRevision,
  getDocumentRevisionData
} from '../../../actions/digital_sale_document_revisions_actions';
import { withRouter } from 'react-router-dom';
import { fetchOrgData } from '../../../actions/global_setting_actions';
import { fetchPointsOfSaleNames } from '../../../actions/points_of_sale_actions';

function mapStateToProps(state) {
  const {
    digitalSalesDocumentRevisions,
    meta
  } = state.digitalSalesDocumentRevisions;
  return {
    digitalSalesDocumentRevisions,
    meta,
    currentUserSupplier: state.authentication.currentUser.supplier,
    currentUserPos: state.authentication.currentUser.pointOfSales,
    canFilterSalesBy: state.authentication.currentUser.canFilterSalesBy,
    requestSend: state.requestState.requestSend,
    suppliers: state.globalSetting.orgData.suppliers,
    pointsOfSales: state.pointOfSale.pointsOfSaleNames,
    requestOrgDataSend: state.requestState.requestOrgDataSend,
    requestPointOfSalesSend: state.requestState.requestPointOfSalesSend
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    fetchDigitalSalesDocumentRevision: bindActionCreators(
      fetchDigitalSalesDocumentRevision,
      dispatch
    ),
    fetchPointsOfSaleNames: bindActionCreators(
      fetchPointsOfSaleNames,
      dispatch
    ),
    getDocumentRevisionData: bindActionCreators(getDocumentRevisionData, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitalSalesDocumentRevisionList);
