import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import { Formik } from 'formik';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import * as Yup from 'yup';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import _isEmpty from 'lodash/isEmpty';

const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required('El campo es requerido'),
  location: Yup.string().nullable().required('El campo es requerido'),
  channel: Yup.string().nullable().required('El campo es requerido'),
});

const ProductModal = props => {
  const {
    open,
    classes,
    onClose,
    onSubmit,
    isSubmitting,
    product,
    categories,
  } = props;
  const action = !!product.id ? 'update' : 'create';
  const dialogTitle = action === 'create' ? 'Crear producto' : 'Editar producto';
  return (
    <Formik
      initialValues={{
        id: product.id,
        name: product.name || '',
        location: product.location,
        channel: product.channel,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={
        ({
          values,
          handleSubmit,
          handleChange,
          touched,
          handleBlur,
          errors,
          handleReset,
          setFieldValue,
          setFieldTouched,
        }) => {
          const _onClose = () => {
            handleReset();
            onClose();
          };
          return (
            <Dialog open={open} onClose={_onClose}>
              <DialogTitle>{dialogTitle}</DialogTitle>
              <DialogContent className={classes.productModalDialogContent}>
                <AutocompleteInput
                  id="name"
                  name="name"
                  label="Producto/Categoría"
                  margin="normal"
                  variant="outlined"
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  value={values.name}
                  onChange={change => {
                    setFieldValue('name', change);
                    setFieldTouched('name', true);
                  }}
                  suggestions={categories}
                  getSuggestions={ 
                    value => {
                      const items = categories.map(item => ({
                        label: item.name,
                        value: item.name
                      }));
                      const suggestions = filterListFuzzyly(value, items, 'label');
                      return suggestions;
                    }
                  }
                  onSuggestionSelected={handleChange}
                />
                <OutlinedTextField
                  name='location'
                  label='Cobertura geográfica aprobada'
                  value={values.location}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.location && errors.location}
                  error={touched.location && !!errors.location}
                />
                <OutlinedTextField
                  name='channel'
                  label='Entrega aprobada'
                  value={values.channel}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.channel && errors.channel}
                  error={touched.channel && !!errors.channel}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={_onClose} color="secondary">
                  Cancelar
                </Button>
                <ProgressButton
                  color="secondary"
                  onClick={ () => {
                    handleSubmit(values);
                    if (_isEmpty(errors) && !_isEmpty(touched)) {
                      setTimeout(handleReset, 1000);
                    }
                  }}
                  isSubmitting={isSubmitting}
                >
                  Guardar
                </ProgressButton>
              </DialogActions>
            </Dialog>
          );
        }
      }
    />
  );
};

export default ProductModal;