import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const StepTitle = props => {
  return (
    <Grid container item>
      <Grid item xs={12}>
        <Typography variant='h3'>Creación de Propuestas</Typography>
      </Grid>
    </Grid>
  );
};

export default StepTitle;