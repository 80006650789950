/* eslint-disable no-unused-vars */
import * as faceapi from 'face-api.js';
//This calls are necessary to generate public path which faceAPIUses
const faceLandmarkModel = require('../assets/models/face_landmark_68_tiny_model-weights_manifest.json');
const faceLandmarkModelShard = require('../assets/models/face_landmark_68_tiny_model-shard1.shard');
const tinyFaceDetectorModel = require('../assets/models/tiny_face_detector_model-weights_manifest.json');
const tinyFaceDetectorModelShard = require('../assets/models/tiny_face_detector_model-shard1.shard');
const faceExpressionsModel = require('../assets/models/face_expression_model-weights_manifest.json');
const faceExpressionsModelShard = require('../assets/models/face_expression_model-shard1.shard');
// Load models and weights
export async function loadModels() {
  await faceapi.nets.tinyFaceDetector.loadFromUri('/');
  await faceapi.nets.faceLandmark68TinyNet.loadFromUri('/');
  await faceapi.nets.faceExpressionNet.loadFromUri('/');
}

export async function getFullFaceDescription(blob, inputSize = 512) {
  // tiny_face_detector options
  const scoreThreshold = 0.5;
  const OPTION = new faceapi.TinyFaceDetectorOptions({
    inputSize,
    scoreThreshold
  });
  const useTinyModel = true;

  // fetch image to api
  const img = await faceapi.fetchImage(blob);

  // detect all faces and generate full description from image
  // including landmark and descriptor of each face
  const fullDesc = await faceapi
    .detectAllFaces(img, OPTION)
    .withFaceLandmarks(useTinyModel)
    .withFaceExpressions();
  return fullDesc;
}

export function getEyeWidth(eyePoints) {
  const leftMorePoint = eyePoints.reduce((a, b) => {
    return a._x < b._x ? a : b;
  })._x;
  const rightMorePoint = eyePoints.reduce((a, b) => {
    return a._x > b._x ? a : b;
  })._x;
  return rightMorePoint - leftMorePoint;
}
