import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import TeleshoppingRegistration from './TeleshoppingRegistration.js';
import { newAlert } from '../../../actions/alert_actions';
import { validateContractToTeleshopping } from '../../../actions/teleshopping_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';

function mapStateToProps(state) {
  return {
    serverErrors: state.teleshopping.errors,
    baseErrorOpen: state.teleshopping.baseErrorOpen,
    validContract: state.teleshopping.validContract,
    stepCreateTeleshopping: state.teleshopping.stepCreateTeleshopping,
    routes: state.stepper.routes,
    process: state.stepper.process,
    isBlocked: state.authentication.currentUser.blocked,
    isBlackListActive: state.globalSetting.orgData.get_blacklist_active
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    validateContractToTeleshopping: bindActionCreators(
      validateContractToTeleshopping,
      dispatch
    ),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TeleshoppingRegistration);
