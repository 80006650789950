export const listSanitizer = params => {
  const sanitizedParams = {
    page: params.page,
    per_page: params.perPage
  };

  return sanitizedParams;
};

export const createSanitizer = params => {
  const sanitizedParams = {
    schedule_point_of_sale_configuration: {
      point_of_sales_id: params.pointOfSales.map(pointOfSale => pointOfSale.value).join(','),
      category_id: params.categoryId,
      geographic_location_id: params.geographicLocationId
    }
  };

  return sanitizedParams;
};

export const updateSanitizer = params => {
  const sanitizedParams = {
    schedule_point_of_sale_configuration: {
      point_of_sale_id: params.pointOfSaleId,
      category_id: params.categoryId,
      geographic_location_id: params.geographicLocationId
    }
  };

  return sanitizedParams;
};

export const categoriesSanitizer = categories => {
  return categories.map(category => {
    return {
      id: category.id,
      active: category.attributes.active,
      depth: category.attributes.depth,
      name: category.attributes.name
    };
  });
};