import {
  CONTRACT_DETAIL_SET,
  CONTRACT_DETAIL_CLEAR
} from '../constants/action_constants';
import { sendRequest, completeRequest } from './request_state';
import { newAlert, serverStatusError } from './alert_actions';
import RequestService from '../services/request_service';
const requestService = new RequestService('node');

function setContract(data) {
  return {
    type: CONTRACT_DETAIL_SET,
    data
  };
}

export function clearContract() {
  return {
    type: CONTRACT_DETAIL_CLEAR
  };
}

export const fetchContract = (
  id
) => async dispatch => {
  dispatch(sendRequest('Contract-Detail'));
  try {
    const payload = await requestService.get('contracts/contractInfo/'+id);
    dispatch(setContract(payload.data));
    return { success: true };
  }catch (error) {
    dispatch(clearContract());
    dispatch(serverStatusError(error));
    return error.json().then(e => {
      if (e.errors) {
        e.errors.base && dispatch(newAlert('error', 'ERROR:', e.errors.base));
      }
      return e;
    });
  } finally {
    dispatch(completeRequest('Contract-Detail'));
  }
};
