import _get from 'lodash/get';
import { _moneyFormat } from '../../../../helpers/utilityFunctions';

const STATUS = {
  canceled: 'Anulado',
  returned: 'Devuelto',
  delivered: 'Entregado',
  for_delivery: 'Por Entregar',
  returning: 'En Devolución',
  returned_manual: 'Devuelto'
};

export const columns = [
  { name: 'articleName', title: 'Nombre' },
  { name: 'amount', title: 'Cantidad' },
  { name: 'value', title: 'Valor' },
  { name: 'ivaPretty', title: 'I.V.A.' },
  { name: 'total', title: 'Total' },
  { name: 'numMonthlyInstalments', title: 'Cuotas' },
  { name: 'prettyStatus', title: 'Estado' }
];

export const formatData = items =>
  items.map(item => ({
    ...item,
    articleName: _get(item, 'article.description', '--') || '--',
    ivaPretty: _moneyFormat(item.iva),
    value: _moneyFormat(item.value),
    total: _moneyFormat(
      item.amount * (parseFloat(item.iva) + parseFloat(item.value))
    ),
    prettyStatus: STATUS[item.status]
  }));
