import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import {
  fetchBulkProcesses,
  createGracePeriodMassive,
} from '../../../actions/bulk_load_actions';
import BulkLoadList from './BulkLoadList';

function mapStateToProps(state) {
  return {
    bulkProcesses: state.bulkProcesses.bulkProcesses,
    requestSend: state.requestState.requestSend,
    canCreateMassivePeriodGrace:
      state.authentication.currentUser.canCreateMassivePeriodGrace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchProcesses: bindActionCreators(fetchBulkProcesses, dispatch),
    createGracePeriodMassive: bindActionCreators(
      createGracePeriodMassive,
      dispatch
    ),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BulkLoadList);
