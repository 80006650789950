export const createSanitizer = params => {
    const sanitizedParams = {
      suppliers:params.suppliers.map(a => a.value),
      geographicLocationId: params.geographicLocationId
    };

    return sanitizedParams;
  };

  export const updateSanitizer = params => {
    const sanitizedParams = {
      suppliers: params.suppliers.map(a => a.value),
      geographicLocationId: parseInt(params.geographicLocationId)
    };

    return sanitizedParams;
  };

  export const locationCheckSanitizier = params => {
    const sanitiziedParams = {
      contractId: params.debtorContractNumber || params.debtor_contract_number
    }
     return sanitiziedParams;
  }