import { sinisterConstants } from '../constants/sinister_constants';
import { sendRequest, completeRequest } from './request_state';
import RequestService from '../services/request_service';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { newAlert } from './alert_actions';
import sinisterFilters from '../helpers/sanitizers/sinisterFilters';

const requestService = new RequestService();
const requestServiceNode = new RequestService('node');

//* PURE ACTION FUNCTIONS (This ones call the reducer)
function success(items, pagina) {
  return {
    type: sinisterConstants.LIST_SINISTERS_REQUEST,
    data: items,
    meta: pagina
  };
}

// * INDEX *
export const fetchSinisters = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/sinister?${stringifyQuery(params)}`);
  try {
    const payload = await requestServiceNode.get(
      'sinisters',
      sinisterFilters(params),
      true
    );
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
    dispatch(newAlert('error', 'ERROR:', 'Ocurrió un error al cargar los siniestros'));
  } finally {
    dispatch(completeRequest());
  }
};

export const approveSinister = (data = {}, queryParams = {}) => async dispatch => {
  dispatch(sendRequest('ApproveSinister'));
  try {
    const payload = await requestServiceNode.post(
      `sinisters/approve`,
      data,
      true
    );

    dispatch(newAlert('success', 's', payload.data.message));
    dispatch(fetchSinisters(queryParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    }
  } finally {
    dispatch(completeRequest('ApproveSinister'));
  }
};

export const revokeSinister = (data = {}, queryParams = {}) => async dispatch => {
  dispatch(sendRequest('RevokeSinister'));
  try {
    const payload = await requestServiceNode.post(
      `sinisters/revoke`,
      data,
      true
    );

    dispatch(newAlert('success', 's', payload.data.message));
    dispatch(fetchSinisters(queryParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    }
  } finally {
    dispatch(completeRequest('RevokeSinister'));
  }
};
