import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import React, { createRef, useEffect, useRef } from 'react';
import combineStyles from '../../../helpers/combineStyles';
import SummaryCard from '../../shared/Cards/SummaryCard';
import TypographyStyles from '../../../styles/typography';
import LayoutStyles from '../../../styles/layout';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Component } from 'react';
import { DASHBOARD_COUNTER_SUMMARY_NAME } from '../../../constants/enums';
import ActivityIndicator from '../../shared/ActivityIndicator';
import { _moneyFormat } from '../../../helpers/utilityFunctions';

class SaleCountSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPercentage: 0,
      saleCounts: []
    };
  }

  /**
   * _A scrolling event on the GridList triggers this method._
   *
   * Gets the current values of the scrollbar and calculates the scroll percentage.
   * @param {*} event
   */
  onScroll = event => {
    const scrollValue = event.currentTarget.scrollLeft;
    const offsetWidth = event.currentTarget.offsetWidth;
    const scrollWidth = event.currentTarget.scrollWidth;

    const maxScroll = scrollWidth - offsetWidth;
    const scrollPercentage = (scrollValue / maxScroll) * 100;

    this.setState({ scrollPercentage: scrollPercentage });
  };

  handleLeftClick = event => {
    const gridList =
      event.currentTarget.parentNode.parentNode.childNodes[1].childNodes[0];

    const maxScroll = gridList.scrollWidth - gridList.offsetWidth;
    const scrollDistance = maxScroll / 16;
    const left = gridList.scrollLeft - scrollDistance * 2.38;
    gridList.scrollTo({ left: left, behavior: 'smooth' });
  };

  handleRightClick = event => {
    const gridList =
      event.currentTarget.parentNode.parentNode.childNodes[1].childNodes[0];

    const maxScroll = gridList.scrollWidth - gridList.offsetWidth;
    const scrollDistance = maxScroll / 16;
    const left = scrollDistance * 2.38 + gridList.scrollLeft;

    gridList.scrollTo({ left: left, behavior: 'smooth' });
  };

  render() {
    const { classes, isLoading, counterSummary } = this.props;
    const { scrollPercentage } = this.state;

    return (
      <Grid
        container
        className={classes.rootBase}
        alignItems="center"
        justify="space-between"
        spacing={8}
      >
        <Grid item xs className={classes.alignIcon}>
          {scrollPercentage !== 0 && (
            <NavigateBeforeIcon
              fontSize={'large'}
              onClick={this.handleLeftClick}
              className={classes.cursorPointer}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <GridList
            className={classes.gridList}
            cols={2.5}
            cellHeight={'auto'}
            onScroll={this.onScroll}
          >
            {!counterSummary
              ? Object.entries(DASHBOARD_COUNTER_SUMMARY_NAME).map(
                  ([key, value]) => {
                    return (
                      <GridListTile key={key} cols={1}>
                        <SummaryCard
                          totalCount={0}
                          message={value}
                          ProgressBarProps={{
                            color: '#FEB00B',
                            totalValue: 100,
                            value: 0,
                            resourceType: 'Ventas'
                          }}
                          isLoading={true}
                        />
                      </GridListTile>
                    );
                  }
                )
              : Object.entries(DASHBOARD_COUNTER_SUMMARY_NAME).map(
                  ([key, value]) => {
                    const saleCount = counterSummary[key];
                    if (typeof saleCount != 'undefined') {
                      if (
                        key.includes('delivery') &&
                        !key.includes('pending')
                      ) {
                        const totalSales =
                          counterSummary.total_delivery
                            .num_ventas;
                        return (
                          <GridListTile key={key} cols={1}>
                            <SummaryCard
                              totalCount={
                                saleCount.num_ventas
                              }
                              message={value}
                              ProgressBarProps={{
                                color: '#00C396',
                                totalValue: totalSales,
                                value: saleCount.num_ventas,
                                resourceType: 'Ventas'
                              }}
                              isLoading={false}
                            />
                          </GridListTile>
                        );
                      } else if (
                        key.includes('registered')
                      ) {
                        const totalSales =
                          counterSummary.total_registered
                            .num_ventas;
                        return (
                          <GridListTile key={key} cols={1}>
                            <SummaryCard
                              totalCount={
                                saleCount.num_ventas
                              }
                              message={value}
                              ProgressBarProps={{
                                color: '#00C396',
                                totalValue: totalSales,
                                value: saleCount.num_ventas,
                                resourceType: 'Ventas'
                              }}
                              isLoading={false}
                            />
                          </GridListTile>
                        );
                      } else if (
                        key.includes('pending_delivery')
                      ) {
                        const totalSales =
                          counterSummary
                            .total_pending_delivery
                            .num_ventas;
                        return (
                          <GridListTile key={key} cols={1}>
                            <SummaryCard
                              totalCount={
                                saleCount.num_ventas
                              }
                              message={value}
                              ProgressBarProps={{
                                color: '#00C396',
                                totalValue: totalSales,
                                value: saleCount.num_ventas,
                                resourceType: 'Ventas'
                              }}
                              isLoading={false}
                            />
                          </GridListTile>
                        );
                      } else if (
                        key.includes('pending_revision')
                      ) {
                        const totalSales =
                          counterSummary
                            .total_pending_revision
                            .num_ventas;
                        return (
                          <GridListTile key={key} cols={1}>
                            <SummaryCard
                              totalCount={
                                saleCount.num_ventas
                              }
                              message={value}
                              ProgressBarProps={{
                                color: '#00C396',
                                totalValue: totalSales,
                                value: saleCount.num_ventas,
                                resourceType: 'Ventas'
                              }}
                              isLoading={false}
                            />
                          </GridListTile>
                        );
                      } else if (key == 'ticket_promedio') {
                        const totalSales =
                          counterSummary.total_sales
                            .num_ventas;
                        const totalDelivery =
                          counterSummary.total_delivery
                            .num_ventas;
                        return (
                          <GridListTile key={key} cols={1}>
                            <SummaryCard
                              totalCount={_moneyFormat(
                                saleCount
                              )}
                              message={value}
                              ProgressBarProps={{
                                color: '#0071D9',
                                totalValue: totalSales,
                                value: totalDelivery,
                                resourceType: 'Ventas'
                              }}
                              isLoading={false}
                            />
                          </GridListTile>
                        );
                      }
                    }
                  }
                )}
          </GridList>
        </Grid>
        <Grid item xs className={classes.alignIcon}>
          {scrollPercentage !== 100 && (
            <NavigateNextIcon
              fontSize={'large'}
              onClick={this.handleRightClick}
              className={classes.cursorPointer}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  rootBase: {
    background: '#00000005 0% 0% no-repeat padding-box',
    opacity: '1',
    boxShadow: 'none',
    padding: `${theme.spacing.unit * 2}px`
  },
  alignIcon: {
    textAlign: 'center'
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    overflow: 'scroll'
  },
  cursorPointer: {
    cursor: 'pointer'
  }
});

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles),
  {
    withTheme: true
  }
)(SaleCountSummary);
