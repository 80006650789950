import React from 'react';
import PropTypes from 'prop-types';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';

import { Icon } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';

import ActivityIndicator from '../../shared/ActivityIndicator';
import TypographyStyles from '../../../styles/typography';

export const SalesCard = ({
  classes,
  icon,
  message,
  partialCount,
  isSurfaceCoordinator,
  totalCount,
  isLoading,
  freelance,
  admin,
  canSeePosText,
  posSelected,
  supplierSelected
}) => (
  <Card className={classes.rootBase}>
    {isLoading && <ActivityIndicator className={classes.loadingIndicator} />}

    <Icon classes={{ root: classes.iconRoot }}>
      <img
        className={classes.imageIcon}
        src={icon}
        width="70px"
        height="70px"
      />
    </Icon>

    <div>
      <CardContent>
        <p className={classes.message}>{message}</p>
        <h3 className={(classes.headline7, classes.partialCount)}>
          {partialCount}
        </h3>
        {canSeePosText && (
          <p className={classes.subtitle}>
            {posSelected ? 'Total punto de venta' : 'Total puntos de venta'}
          </p>
        )}
        {!canSeePosText && <p className={classes.subtitle}>Mis ventas</p>}
      </CardContent>
      {(!freelance || admin) && (
        <CardContent>
          <h4 className={(classes.headline7, classes.totalCount)}>
            {totalCount}
          </h4>
          <p className={classes.subtitle}>
            {supplierSelected ? 'Total ventas Aliado' : 'Total ventas Aliados'}
          </p>
        </CardContent>
      )}
    </div>
  </Card>
);

const styles = theme => ({
  ['@media (max-width: 930px)']: {
    subtitle: {
      fontSize: '18px !important'
    },
    partialCount: {
      fontSize: '1.3rem !important'
    },
    totalCount: {
      fontSize: '20px !important'
    },
    rootBase: {
      minHeight: 'auto !important'
    }
  },
  ['@media (max-width: 680px)']: {
    message: {
      fontSize: '20px !important',
      marginTop: '20px !important',
      marginBottom: '0px'
    },
    rootBase: {
      padding: theme.spacing.unit + 'px !important',
      display: 'flex',
      marginBottom: '15px',
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    subtitle: {
      marginTop: '4px'
    }
  },
  ['@media (max-width: 500px)']: {
    partialCount: {
      fontSize: '1.3rem !important'
    },
    message: {
      marginTop: '18px !important',
      marginBottom: '0px !important'
    }
  },
  rootBase: {
    background: '#00000005 0% 0% no-repeat padding-box',
    opacity: '1',
    maxWidth: '100%',
    boxShadow: 'none',
    textAlign: 'center',
    fontFamily: 'Roboto',
    justifyContent: 'center',
    minHeight: '500px'
  },
  totalCount: {
    lineHeight: '1.3rem',
    fontWeight: '400 !important',
    fontWeight: 'bold !important',
    textAlign: 'center',
    fontSize: '25px',
    marginBottom: '6px'
  },
  partialCount: {
    fontSize: '30px',
    color: '#DB4844',
    textAlign: 'center',
    fontWeight: 'bold !important',
    marginBottom: '6px',
    marginTop: '0px'
  },
  message: {
    marginBottom: '0px',
    textAlign: 'center',
    fontFamily: 'Rubik',
    fontSize: '25px'
  },
  subtitle: {
    fontSize: '20px',
    textAlign: 'center',
    color: '#00000099',
    fontWeight: '600'
  }
});

SalesCard.propTypes = {
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  partialCount: PropTypes.string.isRequired,
  totalCount: PropTypes.string.isRequired
};

export default withStyles(
  combineStyles(TypographyStyles, LayoutStyles, styles)
)(SalesCard);
