import { FETCH_SUPPLIER_ARTICLES } from '../constants/action_constants';

const initialState = {
  entities: {}
};

export function supplierArticles(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUPPLIER_ARTICLES:
      const entities = {};
      for (const item of action.data) {
        entities[item.id] = item;
      }
      return {
        ...state,
        entities
      };
    default: {
      return state;
    }
  }
}
