const styles = () => ({
  formContainer: {
    background: '#FFF',
    padding: '56px',
    height: '100%'
  },
  emailInput: {
    maxWidth: '514px',
    width: 'calc(100% + 14px)',
    marginBottom: '16px'
  },
  loginLink: {
    textDecoration: 'none',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '32px'
  },
  wrapper: {
    position: 'relative',
    maxWidth: '528px',
    width: '100%',
    marginBottom: '8px'
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  sendButton: {
    maxWidth: '528px',
    width: 'calc(100% + 28px)',
    boxShadow: 'none'
  }
});

export default styles;
