import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, TextField } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import CustomDialog from '../../../shared/Modals/CustomDialog';
import FlashMessage from '../../../shared/Alerts/Flash/FlashMessage';
import Movements from '../../../shared/Movements/Movements';

import { _moneyFormat } from '../../../../helpers/utilityFunctions';
import { statusOptions } from '../dropDownOptions';

class SinisterDetailForm extends Component {
  state = { comment: '' };

  handleApprove = () => {
    this.props.onApprove({ id: this.props.sinisterDetail.id, comment: this.state.comment });
  };

  handleRevoke = () => {
    this.props.onRevoke({ id: this.props.sinisterDetail.id, comment: this.state.comment });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getStatus = () => {
    return statusOptions.find(status => status.title === this.props.sinisterDetail.status).value;
  };

  getOrder = () => {
    return this.props.sinisterDetail.id;
  };

  getActionText = () => {
    const { canApproveSinister } = this.props;
    const type = this.getStatus();

    switch (type) {
      case 'registered':
        if (canApproveSinister) return 'Aprobar';
        return null;
      default:
        return null;
    }
  };

  getRevokeActionText = () => {
    const { canRevokeSinister } = this.props;
    const type = this.getStatus();

    switch (type) {
      case 'registered':
        if (canRevokeSinister) return 'Rechazar';
        return null;
      default:
        return null;
    }
  };

  isCreatingOrder = () => {
    const order = this.getOrder();
    return order ? order.toString().includes('temp_') : null;
  };

  getDescription = status => {
    switch (status) {
      case 'registered':
        return 'Solicitud';
      case 'revoked':
        return 'Rechazo';
      case 'approved':
        return 'Aprobación';
      default:
        return '';
    }
  };

  render() {
    const {
      sinisterDetail,
      classes,
      open = true,
      isSaving,
      isRevoking,
      onClose
    } = this.props;

    const { comment } = this.state;
    const movements = sinisterDetail.movements;

    return (
      <div>
        <CustomDialog
          open={open}
          loading={isSaving}
          loadingSecondary={isRevoking}
          disabled={this.isCreatingOrder()}
          disableBackdropClick={false}
          headerSecondary={
            this.isCreatingOrder() ? (
              <Grid item className={classes.flashMessage}>
                <FlashMessage
                  variant="label"
                  message={'Orden en proceso de creación...'}
                />
              </Grid>
            ) : (
              <div />
            )
          }
          onClose={onClose}
          onConfirm={this.handleApprove}
          title="Solicitud de siniestro"
          contentText={''}
          buttonText={this.getActionText()}
          buttonSecondaryText={this.getRevokeActionText()}
          onConfirmSecondary={this.handleRevoke}
          disabledSecondary={_isEmpty(comment) || this.isCreatingOrder()}
          classes={{
            root: classes.dialogRoot
          }}
        >
          <Grid container spacing={16}>
            {sinisterDetail.movements && (
              <Movements movements={sinisterDetail.movements} disables={['reviewed']} />
            )}
            <div className={classes.textContainer}>
              <div className={classes.commentsContainer}>
                {movements.map(mov => {
                  const movComment = mov.comment || mov.comments[0] ? mov.comments[0].comment : '';

                  return (
                    <p className={classes.titleContainer} key={mov.id}>
                      {`${this.getDescription(mov.status)}: `}
                      <a className={classes.subText}>{movComment}</a>
                    </p>
                  );
                })}
              </div>
            </div>
            {!this.isCreatingOrder() && this.getActionText() && (
              <Grid item xs={12}>
                <TextField
                  label="Comentarios*"
                  name="comment"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  value={comment}
                  multiline={true}
                  rows={2}
                  inputProps={{ maxLength: 199 }}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = theme => ({
  flashMessage: {
    marginTop: theme.spacing.unit / 1.5,
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2
  },
  dialogRoot: {
    width: 800
  },
  topInput: {
    marginTop: theme.spacing.unit * 2
  },
  subtitleContainer: {
    color: '#DB4945 !important',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingTop: '6px',
    margin: `0px ${theme.spacing.unit * 3}px `,
    marginLeft: theme.spacing.unit * 20
  },
  commentsContainer: {
    marginTop: theme.spacing.unit * 1
  },
  textContainer: {
    display: 'block'
  },
  subContainer: {
    display: 'flex'
  },
  subText: {
    color: theme.typography.color.light,
    paddingLeft: theme.spacing.unit / 2,
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingRight: '81px',
    wordBreak: 'break-all'
  },
  titleContainer: {
    color: theme.typography.color.main,
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingTop: '6px',
    margin: `0px ${theme.spacing.unit * 3}px `,
    overflowWrap: 'anywhere'
  },
  heading: {
    color: 'rgba(12,31,44,0.87)',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily
  },
  comments: {
    marginTop: theme.spacing.unit * 2
  }
});

SinisterDetailForm.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(SinisterDetailForm);
