import * as Yup from 'yup';

const names = /^[a-zA-Z\u00f1\u00d1]+(\s*[a-zA-Z\u00f1\u00d1]*)*[a-zA-Z\u00f1\u00d1\s]+$/;
const lastNames = /^[a-z áéíóúüñ']+(\s*[a-z áéíóúüñ'\-]*)*[a-z áéíóúüñ'\s]+$/i;

export const debtorValidation = (isSecureSale, isB2bSale) => Yup.object().shape({
  promissoryType: Yup.string()
    .required()
    .test('type', 'Seleccione un tipo de pagaré.', val => val !== 'any_type'),
  saleNumber: Yup.number().when('promissoryType', {
    is: 'M',
    then: Yup.number()
      .required('El campo es requerido')
      .min(1, 'Ingresa un consecutivo válido.')
  }),
  isInvoiceHolder: Yup.boolean(),
  requiresCosigner: Yup.boolean(),
  newName: Yup.string()
    .required('El campo es requerido')
    .matches(names, 'No se permiten caracteres especiales o números.'),
  newLastname: Yup.string()
    .required('El campo es requerido')
    .matches(lastNames, 'No se permiten caracteres especiales o números.'),
  debtorName: Yup.string().required('El campo es requerido'),
  debtorLastname: Yup.string().nullable().when('newLastname', {
    is: val => !val,
    then: Yup.string().required('El campo es requerido')
  }),
  debtorMaritalStatus: Yup.number().required('El campo es requerido'),
  debtorDoctype: Yup.number().required('El campo es requerido'),
  debtorDocid: Yup.string()
    .when('debtorDoctype', {
      is: 'Cédula',
      then: Yup.string()
        .matches(
          /^[0-9]+$/,
          'Tipo de documento no admite caracteres especiales o letras'
        )
        .required('El campo es requerido')
    })
    .when('debtorDoctype', {
      is: 'Pasaporte',
      then: Yup.string()
        .matches(
          /^[a-z-A-Z-0-9]+$/,
          'Tipo de documento no admite caracteres especiales'
        )
        .required('El campo es requerido')
    })
    .when('debtorDoctype', {
      is: 'Cédula de Extranjería',
      then: Yup.string()
        .matches(
          /^[a-z-A-Z-0-9]+$/,
          'Tipo de documento no admite caracteres especiales'
        )
        .required('El campo es requerido')
    })
    .when('debtorDoctype', {
      is: 'Nit / Rut',
      then: Yup.string()
        .matches(
          /^[0-9-]+$/,
          'Tipo de documento solo admite números y el caracter - '
        )
        .required('El campo es requerido')
    }),
  debtorDocExpeditionLocality: Yup.string().nullable().required('El campo es requerido'),
  debtorDocExpeditionDate: Yup.mixed().default(null).required('El campo es requerido'),
  debtorGender: Yup.number().required('El campo es requerido'),
  debtorFormationLevel: Yup.number().required('El campo es requerido'),
  debtorEmail: isB2bSale ? Yup.string()
    .required('El campo es requerido')
    .email("Debe ingresar un email válido.") : Yup.string()
    .nullable()
    .email("Debe ingresar un email válido."),
  debtorPhone: Yup.string().matches(
    /^([0-9]{7})$/,
    'El número ingresado debe tener 7 dígitos.'
  ),
  debtorCellphone: Yup.string()
    .required('El campo es requerido')
    .matches(/^([0-9]{10})$/, 'El número ingresado debe tener 10 dígitos.')
    .test('equal', 'El número de celular debe ser diferente al del codeudor.',
      function (v) { return v !== this.resolve(Yup.ref('cosignerCellphone')); }
    ),
  debtorNeighbourhood: Yup.mixed().required('El campo es requerido'),
  debtorAddress: Yup.string().required('El campo es requerido'),
  debtorHouseType: Yup.number().required('El campo es requerido'),
  debtorProfession: Yup.number()
    .required('El campo es requerido')
    .min(1, 'Ingresa una profesión válida.'),
  debtorOccupation: Yup.string().required('El campo es requerido'),
  debtorCompany: Yup.string().when('debtorProfession', {
    is: 689,
    then: Yup.string()
      .required('El campo es requerido')
      .min(1, 'Ingresa un nombre válido.')
  }),
  debtorBirthPlace: isSecureSale ? Yup.string()
  .required('El campo es requerido')
  .test('len', 'El nombre debe estar entre 4 y 50 caracteres.', (val) => val && val.toString().length <= 50 && val.toString().length >= 4)
  .matches(lastNames, 'No se permiten caracteres especiales o números.')
  : Yup.string()
});

export const cosignerValidation = isSolidarityCosigner => Yup.object().shape({
  cosignerContract: Yup.number().nullable(),
  cosignerName: Yup.string()
    .required('El campo es requerido')
    .matches(names, 'No se permiten caracteres especiales o números.'),
  cosignerLastname: Yup.string()
    .required('El campo es requerido')
    .matches(lastNames, 'No se permiten caracteres especiales o números.'),
  cosignerMaritalStatus: Yup.number().required('El campo es requerido'),
  cosignerDoctype: Yup.number().required('El campo es requerido'),
  cosignerDocid: Yup.string()
    .when('cosignerDoctype', {
      is: 'Cédula',
      then: Yup.string()
        .matches(
          /^[0-9]+$/,
          'Tipo de documento no admite caracteres especiales o letras'
        )
        .required('El campo es requerido')
    })
    .when('cosignerDoctype', {
      is: 'Pasaporte',
      then: Yup.string()
        .matches(
          /^[a-z-A-Z-0-9]+$/,
          'Tipo de documento no admite caracteres especiales'
        )
        .required('El campo es requerido')
    })
    .when('cosignerDoctype', {
      is: 'Cédula de Extranjería',
      then: Yup.string()
        .matches(
          /^[a-z-A-Z-0-9]+$/,
          'Tipo de documento no admite caracteres especiales'
        )
        .required('El campo es requerido')
    })
    .when('cosignerDoctype', {
      is: 'Nit / Rut',
      then: Yup.string()
        .matches(
          /^[0-9-]+$/,
          'Tipo de documento solo admite números y el caracter - '
        )
        .required('El campo es requerido')
    }),
  cosignerDocExpeditionLocality: Yup.string().required('El campo es requerido'),
  cosignerDocExpeditionDate: Yup.mixed().required('El campo es requerido'),
  cosignerGender: Yup.number().required('El campo es requerido'),
  cosignerFormationLevel: Yup.number().required('El campo es requerido'),
  cosignerEmail: Yup.string()
    .nullable()
    .email('Debe ingresar un email válido.'),

  cosignerPhone: Yup.string().matches(
    /^([0-9]{7})$/,
    'El número ingresado debe tener 7 dígitos.'
  ),
  cosignerCellphone: Yup.string()
    .required('El campo es requerido')
    .matches(/^([0-9]{10})$/, 'El número ingresado debe tener 10 dígitos.')
    .test('equal', 'El número de celular debe ser diferente al del deudor.',
      function (v) { return v !== this.resolve(Yup.ref('debtorCellphone')); }
    ),
  cosignerNeighbourhood: isSolidarityCosigner ?
    Yup.number().required('El campo es requerido').min(1, 'Ingresa un barrio válido.') :
    Yup.mixed().required('El campo es requerido'),
  cosignerAddress: Yup.string().required('El campo es requerido'),
  cosignerHouseType: Yup.number().required('El campo es requerido'),
  cosignerProfession: Yup.number()
    .required('El campo es requerido')
    .min(1, 'Ingresa una profesión válida.'),
  cosignerOccupation: Yup.string().required('El campo es requerido'),
  cosignerCompany: Yup.string().when('cosignerProfession', {
    is: 689,
    then: Yup.string()
      .required('El campo es requerido')
      .min(1, 'Ingresa un nombre válido.')
  }),
  cosignerStratum: Yup.number().required('El campo es requerido')
});
