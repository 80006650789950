import { LIST_GEOGRAPHIC_LOCATIONS } from '../constants/geographic_location_constants';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import RequestService from '../services/request_service';

const requestService = new RequestService();

function setGeographicLocations(data, meta) {
  return {
    type: LIST_GEOGRAPHIC_LOCATIONS,
    data,
  };
}

export const fetchGeographicLocations = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  try {
    const payload = await requestService.get('geographic_locations', {}, true);
    dispatch(setGeographicLocations(payload.geographic_locations));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest());
  }
};
