import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import DigitalSaleIdentityValidation from './DigitalSaleIdentityValidation';
import { newAlert } from '../../../actions/alert_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import {
  setNextStep,
  checkDigitalSaleIdentityValidation,
  digitalSaleSetDebtorIdentification,
  saveGestureValidation,
  checkDigitalSaleIdentityValidationByOTP
} from '../../../actions/portal_financing_actions';
import { locationValidityCheck } from '../../../actions/location_exception_configuration_actions';

import _get from 'lodash/get';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  return {
    process: state.stepper.process,
    digitalSaleWithUUPNext: state.stepper.digitalSaleWithUUPNext,
    digitalSaleWithouFaceIdNext: state.stepper.digitalSaleWithouFaceIdNext,
    digitalSaleWithoutUUPonContractNext:
      state.stepper.digitalSaleWithoutUUPonContractNext,
    serverErrors: pf.errors,
    globalParameters: {
      validateSaleInvoices: pf.validate_sale_invoices,
      validatePromissoryInvoices: pf.validate_promissory_invoices,
      showScoringStep: _get(
        state,
        'authentication.currentUser.supplier.hasScoringExtraQuotum'
      )
    },
    contract: pf.debtor_contract_number,
    baseErrorOpen: pf.baseErrorOpen,
    routes: state.stepper.routes,
    debtorIdentification: pf.debtor_identification,
    debtorIdentificationType: pf.debtor_identification_type,
    debtorIsOnFacialDb: pf.debtor_is_on_facial_db,
    stepIsLoading: state.portalFinancing.stepIsLoading,
    digitalSaleWithPUI: pf.digital_sale_with_pui,
    isBlocked: state.authentication.currentUser.blocked,
    isBlackListActive: state.globalSetting.orgData.get_blacklist_active
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    digitalSaleSetDebtorIdentification: bindActionCreators(
      digitalSaleSetDebtorIdentification,
      dispatch
    ),
    checkDigitalSaleIdentityValidation: bindActionCreators(
      checkDigitalSaleIdentityValidation,
      dispatch
    ),
    checkDigitalSaleIdentityValidationByOTP: bindActionCreators(
      checkDigitalSaleIdentityValidationByOTP,
      dispatch
    ),
    locationValidityCheck: bindActionCreators(locationValidityCheck, dispatch),
    saveGestureValidation: bindActionCreators(saveGestureValidation, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitalSaleIdentityValidation);
