/* eslint-disable*/
import { withStyles, Grid, TextField } from '@material-ui/core';

import _capitalize from 'lodash/capitalize';
import _debounce from 'lodash/debounce';
import _every from 'lodash/every';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _join from 'lodash/join';

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import combineStyles from '../../../../helpers/combineStyles';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import { Formik } from 'formik';
import Modal from '../../../shared/Modals/Modal';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import SimpleSelect from '../../../../components/shared/Selects/SimpleSelect';
import mapListToOptions from '../../../../helpers/mapListToOptions';
import OutlinedSelectChippedInput from '../../../shared/Inputs/OutlinedSelectChipped';


export class SecureProductMovementsCreate extends Component {
  state = {
    product: '',
  };

  _handleOnClose = () => this.props.onClose();

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  _setCategoryValues = async (data, id, setFieldValue) => {
    const article = data.articles.filter(item => item.id == id)[0];
    const category = data.categories.filter(
      item => item.id == article.category_id
    )[0];

    setFieldValue('category_id', category.id);
    setFieldValue('category_name', category.name);
    this.setState({ category_name: '' });
  };

  _handleContract = _debounce(
    () => {
      this._fetchProducts();
    },
    1000,
    {
      leading: false,
      trailing: true
    }
  );

  _fetchProducts = () => {
    const { contractId } = this.state;
    if (contractId) {
      this.props.getSecureProducts(contractId);
    } else {
      this.props.resetProductData()
    }
  }

  _getProduct = () => {
    return this.props.product_data.map(({ productId }) => ({
      key: productId,
      value: productId,
      label: productId
    }));
  }

  _isBlock = (selected) => {
    const selectedProduct = this.props.product_data.filter(product => product.productId == selected);
    if (selectedProduct.length == 0) {
      return true;
    }

    return selectedProduct && selectedProduct[0].block == 'Y' ? true : false;
  }

  _handleOnSave = (data) => {
    const { onSave, product_data, params } = this.props;
    onSave({...data, product_data, params});
  };

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      product_data
    } = this.props;
    let initialValues = {
      contract_id: '',
      product_id: ''
    };
    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {this._handleOnSave(values)}}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          setErrors,
          errors,
          touched
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {'Actualizar Producto'}
                  </p>
                </div>
                <Grid container spacing={16}>
                  <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
                    <TextField
                      label="Contrato"
                      required
                      type="number"
                      name="contract_id"
                      margin="normal"
                      variant="outlined"
                      onChange={e => {
                        handleChange(e);
                        this.setState({
                          contractId: e.target.value,
                        });
                        this._handleContract(e.target.value);
                      }}
                      value={values.contractId}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      error={
                        touched.name &&
                        (!!errors.name || _get(this.state.serverErrors, 'name[0]') &&
                          !!this.state.serverErrors['name'][0])
                      }
                      helperText={
                        touched.name &&
                        (errors.name || _get(this.state.serverErrors, 'name[0]') &&
                          this.state.serverErrors['name'][0])
                      }
                      onKeyPress={this._onKeyPress}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
                    <SimpleSelect
                      name={'product_id'}
                      label={'Producto'}
                      options={this._getProduct()}
                      onChange={handleChange}
                      value={values.product_id}
                      disabled={
                        product_data.length == 0
                      }
                    />
                  </Grid>
                </Grid>
              </Fragment>
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={product_data.length == 0 || !values.product_id}
                  isSubmitting={isSubmitting}
                >
                  { this._isBlock(values.product_id) ? 'DESBLOQUEAR' : 'BLOQUEAR' }
                </ProgressButton>
              </Fragment>
            }
          >
          </Modal>
        )}
      />
    );
  }
}

const styles = theme => ({
  dialogRoot: {
    width: 800
  }
});

SecureProductMovementsCreate.propTypes = {
  open: PropTypes.bool
};

export default withStyles(
  combineStyles(styles, TypographyStyles, ButtonStyles),
  {
    withTheme: true
  }
)(SecureProductMovementsCreate);
