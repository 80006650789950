import { createMuiTheme } from '@material-ui/core';
import { theme } from '../../Theme';
const materialTheme = (overrides = {}) => {
  const { MuiFormControl = {} } = overrides;
  const { root: MuiFormControlRoot = {} } = MuiFormControl;
  return createMuiTheme({
    ...theme,
    palette: {
      primary: {
        main: '#DB4945'
      },
      error: {
        light: 'rgba(233, 46, 21, 0.06)',
        main: '#F22D12'
      },
      background: {
        paper: '#fff',
        default: '#eceef1'
      },
      disabled: {
        main: 'rgba(12,31,44,0.24)'
      }
    },
    overrides: {
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: 'rgba(0, 0, 0, 0.87)'
          }
        }
      },
      MuiFormControl: {
        root: {
          width: '100%',
          ...MuiFormControlRoot
        }
      },
      MuiOutlinedInput: {
        root: {
          position: 'relative',
          '&$focused $notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
            borderWidth: 1
          }
        }
      },
      MuiInputAdornment: {
        root: {
          position: 'absolute',
          right: 4
        }
      },
      MuiIconButton: {
        root: {
          padding: 8
        }
      }
    }
  });
};

export default materialTheme;
