import {
  QUERY_MEGA_QUOTAS,
  OPEN_QUERY_MEGA_QUOTA_MODAL,
  CLOSE_QUERY_MEGA_QUOTA_MODAL,
  RESET_QUERY_MEGA_QUOTA_DATA,
} from '../constants/query_mega_quota_constants';
import _get from 'lodash/get';

import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import RequestService from '../services/request_service';

import { doctypes } from '../constants/enums';

const requestServiceNode = new RequestService('node');

const getDocTypeValue = debtorIdentificationType => {
  return doctypes.find(type => type.id === debtorIdentificationType || type.name === debtorIdentificationType).value;
};

export const openQueryMegaQuotaModal = () => {
  return dispatch => {
    dispatch({
      type: OPEN_QUERY_MEGA_QUOTA_MODAL
    });
  };
};

export const closeQueryMegaQuotaModal = () => {
  return dispatch => {
    dispatch(errorOnRequest('QueryQuotas', null));
    dispatch({
      type: CLOSE_QUERY_MEGA_QUOTA_MODAL
    });
  };
};

export const resetQueryMegaQuotaData = () => {
  return dispatch => {
    dispatch({
      type: RESET_QUERY_MEGA_QUOTA_DATA
    });
  };
};

function setMegaQuotas(data) {
  return {
    type: QUERY_MEGA_QUOTAS,
    data
  };
}

export const queryMegaQuotas = (params = {}) => async dispatch => {
  dispatch(sendRequest('QueryMegaQuotas'));
  dispatch(resetQueryMegaQuotaData());

  try {
    const response = await requestServiceNode.post('check-mega-quota-promigas', params);
    const usedQuota = response.data.usedQuota + response.data.usedQuotaByClient;
    const availableQuota = response.data.availableQuota;

    dispatch(setMegaQuotas({ ...response.data, usedQuota, availableQuota }));
    dispatch(errorOnRequest('QueryMegaQuotas', []));

    return response.data;
  } catch (errors) {
    const e = await errors.json();
    dispatch(errorOnRequest('QueryMegaQuotas', e.errors.base || e.errors.contract));
    dispatch(setMegaQuotas({ megaQuotas: [] }));
  } finally {
    dispatch(completeRequest('QueryMegaQuotas'));
  }
};
