import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductDelivery from './ProductDelivery';
import {
  productDelivery,
  toggleSaleAction
} from '../../../actions/sales_actions';

function mapStateToProps(state) {
  return {
    open: state.sales[`actionProduct-DeliveryOpen`],
    requestSend: state.requestState['requestProduct-DeliverySend']
  };
}

function mapDispatchToProps(dispatch) {
  return {
    productDelivery: bindActionCreators(productDelivery, dispatch),
    toggleSaleAction: bindActionCreators(toggleSaleAction, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDelivery);
