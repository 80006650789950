import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

const SimpleTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#db4945',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

export default SimpleTableCell;
