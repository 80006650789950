import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import parseQuerystring from '../../../helpers/parseQuerystring';

import TextFieldInput from '../../shared/Inputs/TextField';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import PickCustomDateModal from '../../shared/Inputs/PickCustomDateModal';

import _debounce from 'lodash/debounce';
import _find from 'lodash/find';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';

import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';
import { CUSTOM_DATE } from './constants';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import {
  originalDateOptions,
  dateOptions,
  modifiedDateOptions
} from './dropdownOptions';

class ScoringResponsesFilters extends PureComponent {
  state = {
    date: 'todas',
    finalValue: '',
    identification: '',
    contractId: '',
    birthDate: 'todas',
    issueDate: 'todas',
    openPickCustomDate: null,
    openPickCustomBirthDate: null,
    openPickCustomIssueDate: null,
    customDate: CUSTOM_DATE,
    customBirthDate: CUSTOM_DATE,
    customIssueDate: CUSTOM_DATE
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    this.setState({
      ...this.state,
      ...query
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleDateFilter = ({ target: { name, value } }) => {
    if (
      value === this.state.customDate ||
      value === this.state.customBirthDate ||
      value === this.state.customIssueDate
    ) {
      this.setState({ [`openPickCustom${this.capitalizeFirst(name)}`]: name });
    } else {
      this.setState(
        { [name]: value, [`custom${this.capitalizeFirst(name)}`]: CUSTOM_DATE },
        this._handleChangeFilter
      );
    }
  };

  _handleDateRangeFilter = ({ target: { name, value } }) => {
    const customDate = !_find(
      originalDateOptions,
      availableOption => availableOption.value === value
    )
      ? value
      : CUSTOM_DATE;
    this.setState(
      {
        [name]: value,
        [`openPickCustom${this.capitalizeFirst(name)}`]: null,
        [`custom${this.capitalizeFirst(name)}`]: customDate
      },
      this._handleChangeFilter
    );
  };

  capitalizeFirst = str => str.charAt(0).toUpperCase() + str.slice(1);

  _handleClosePickCustomDate = () =>
    this.setState({ openPickCustomDate: null });

  _handleClosePickCustomBirthDate = () =>
    this.setState({ openPickCustomBirthDate: null });

  _handleClosePickCustomIssueDate = () =>
    this.setState({ openPickCustomIssueDate: null });

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter && filter != 'todas'),
      ['date', 'contractId', 'identification', 'birthDate', 'issueDate']
    );
    return filterParams;
  };

  render() {
    const { classes } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
      <Fragment>
        <Grid container spacing={16}>
          <Grid item xs={4} sm={3} >
            <TextFieldInput
              id="contractId"
              name="contractId"
              value={this.state.contractId}
              onChange={this._handleChange}
              label="# Contrato"
              type="number"
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <OutlinedSelectChippedInput
              label={'Fecha de consulta'}
              name={'date'}
              options={dateOptions(this.state.customDate, this.state.date)}
              value={this.state.date}
              classes={{
                root: classes.selectRoot,
                filterColor: classes.filterColor
              }}
              inputLabel={'Consult Date'}
              onChange={this._handleDateFilter}
              withChip={false}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextFieldInput
              id="identification"
              name="identification"
              value={this.state.identification}
              onChange={this._handleChange}
              label="# Identificación"
              type="number"
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <OutlinedSelectChippedInput
              label={'Fecha de nacimiento'}
              name={'birthDate'}
              options={modifiedDateOptions(
                this.state.customBirthDate,
                this.state.birthDate
              )}
              value={this.state.birthDate}
              classes={{
                root: classes.selectRoot,
                filterColor: classes.filterColor
              }}
              inputLabel={'Birth Date'}
              onChange={this._handleDateFilter}
              withChip={false}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <OutlinedSelectChippedInput
              label={'Fecha de expedición'}
              name={'issueDate'}
              options={dateOptions(
                this.state.customIssueDate,
                this.state.issueDate
              )}
              value={this.state.issueDate}
              classes={{
                root: classes.selectRoot,
                filterColor: classes.filterColor
              }}
              inputLabel={'Issue Date'}
              onChange={this._handleDateFilter}
              withChip={false}
            />
          </Grid>
        </Grid>
        {!!this.state.openPickCustomDate && (
          <PickCustomDateModal
            name={this.state.openPickCustomDate}
            onClose={this._handleClosePickCustomDate}
            onSave={this._handleDateRangeFilter}
            value={this.state.date}
          />
        )}
        {!!this.state.openPickCustomBirthDate && (
          <PickCustomDateModal
            name={this.state.openPickCustomBirthDate}
            onClose={this._handleClosePickCustomBirthDate}
            onSave={this._handleDateRangeFilter}
            value={this.state.birthDate}
          />
        )}
        {!!this.state.openPickCustomIssueDate && (
          <PickCustomDateModal
            name={this.state.openPickCustomIssueDate}
            onClose={this._handleClosePickCustomIssueDate}
            onSave={this._handleDateRangeFilter}
            value={this.state.issueDate}
          />
        )}
      </Fragment>
      }
      />
    );
  }
}

const styles = theme => ({});

ScoringResponsesFilters.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(ScoringResponsesFilters);
