import BrandEditCreate from './BrandEditCreate';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  toggleModal,
  updateBrand,
  createBrand
} from '../../../actions/brands_actions';

function mapStateToProps(state) {
  return {
    brand: state.brands.brandDetail,
    open: state.brands.modalOpen,
    isEditing: state.brands.isEditing
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createBrand: bindActionCreators(createBrand, dispatch),
    updateBrand: bindActionCreators(updateBrand, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BrandEditCreate);
