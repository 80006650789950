import moment from 'moment-timezone';
import _get from 'lodash/get';

export const columns = [
  {
    title: 'Tipo',
    name: 'type'
  },
  {
    title: 'Categoría',
    name: 'category'
  },
  {
    title: 'Proveedor',
    name: 'supplier'
  },
  {
    title: 'Articulo',
    name: 'article'
  },
  {
    title: 'Medio de Recepción',
    name: 'sale_channel'
  },
  {
    title: 'Cobro',
    name: 'recoveryPercentage'
  },
  {
    title: 'Pago',
    name: 'payment_percentage'
  },
  {
    title: 'Inicio',
    name: 'initialDate'
  },
  {
    title: 'Estado',
    name: 'status'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      status: datum.active ? 'Activa' : 'Inactiva',
      type:
        _get(datum, 'commission_type.code') === 'sale' ? 'Venta' : 'Publicidad',
      category: datum.category_name || '--',
      supplier: datum.supplier_name || '--',
      category_name: datum.category_name || '',
      supplier_name: datum.supplier_name || '',
      article: datum.article_name || '--',
      sale_channel: datum.sale_channel_name || '--',
      categoryId: datum.category_id || '',
      supplierId: datum.supplier_id || '',
      recoveryPercentage: datum.recovery_percentage || '--',
      payment_percentage: datum.payment_percentage || '--',
      initialDate: datum.initial_date
        ? moment(datum.initial_date, 'YYYY-MM-DD').format('YYYY/MM/DD')
        : '--'
    };
  });
