import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedTextField from './Fields/OutlinedTextField';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import GridItem from './GridItem';
import combineStyles from '../../helpers/combineStyles';
import LayoutStyles from '../../styles/layout';
import TypographyStyles from '../../styles/typography';
import ButtonStyles from '../../styles/button';
import SpacingStyles from '../../styles/helpers/spacing';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { errorOnRequest } from '../../actions/request_state';
import { bindActionCreators } from 'redux';
import { compose } from 'redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  tooltip: {
    fontSize: '1em',
    padding: '0.5em',
    marginTop: '-2.5em'
  }
});
function mapStateToProps(state) {
  return {
    requestErrorsOnUpdatePassword:
      state.requestState.requestErrorsOnUpdatePassword
  };
}

const mapDispatchToProps = (dispatch, props) => ({
  UpdateErrorsOnUpdatePassword: value =>
    dispatch(errorOnRequest('UpdatePassword', value))
});

export class ChangePasswordModal extends Component {
  componentWillUnmount() {
    this.props.UpdateErrorsOnUpdatePassword({});
  }

  _getJoinError = element => {
    const ErrorsOnUpdatePassword = this.props.requestErrorsOnUpdatePassword || '';
    return ErrorsOnUpdatePassword[element] || '';
  };

  clean_inputs_errors = input_type => {
    const errorsOnUpdatePassword = {
      ...this.props.requestErrorsOnUpdatePassword
    };
    if (this._getJoinError(input_type) != '') {
      delete errorsOnUpdatePassword[input_type];
      this.props.UpdateErrorsOnUpdatePassword(errorsOnUpdatePassword);
    }
  };

  render() {
    const {
      classes,
      values,
      touched,
      errors,
      handleChange,
      UpdateErrorsOnUpdatePassword,
      titleText,
      showCurrentPassword
    } = this.props;

    const regexError = this._getJoinError('regex');
    const currentPasswordError = this._getJoinError('currentPassword');
    const passwordError = this._getJoinError('password');

    return (
      <Fragment>
        {titleText && (
          <div className={classes.titleWrapper}>
            <p className={classnames(classes.headline6, classes.title)}>
              {titleText}
            </p>
          </div>
        )}

        <Grid container spacing={16}>
          {showCurrentPassword && (
            <GridItem xs={12}>
              <OutlinedTextField
                name="currentPassword"
                label={'Contraseña Actual'}
                value={values.currentPassword}
                type={'password'}
                fullWidth
                error={
                  (touched.currentPassword && !!errors.currentPassword) ||
                  !!currentPasswordError
                }
                helperText={
                  (touched.currentPassword && errors.currentPassword) ||
                  currentPasswordError
                }
                onChange={event => {
                  this.clean_inputs_errors('currentPassword');
                  handleChange(event);
                }}
              />
            </GridItem>
          )}
          <GridItem xs={12}>
            <ClickAwayListener
              onClickAway={() => {
                this.clean_inputs_errors('regex');
              }}
            >
              <OutlinedTextField
                name="password"
                label={'Contraseña'}
                value={values.password}
                type={'password'}
                fullWidth
                error={
                  (touched.password && !!errors.password) ||
                  !!passwordError ||
                  regexError
                }
                helperText={
                  (touched.password && errors.password && regexError) ||
                  passwordError ||
                  regexError
                }
                onChange={event => {
                  this.clean_inputs_errors('password');
                  this.clean_inputs_errors('regex');
                  handleChange(event);
                }}
              />
            </ClickAwayListener>
          </GridItem>
          <GridItem xs={12}>
            <OutlinedTextField
              name="passwordConfirmation"
              label={'Confirmar Contraseña'}
              value={values.passwordConfirmation}
              type={'password'}
              fullWidth
              error={touched.passwordConfirmation && !!errors.passwordConfirmation}
              helperText={touched.passwordConfirmation && errors.passwordConfirmation}
              onChange={handleChange}
            />
          </GridItem>
        </Grid>
      </Fragment>
    );
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(
    combineStyles(
      styles,
      LayoutStyles,
      TypographyStyles,
      ButtonStyles,
      SpacingStyles
    )
  )
)(ChangePasswordModal);
