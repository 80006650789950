const styles = theme => ({
  title: {
    marginBottom: `${theme.spacing.unit * 5}px !important`
  },
  graphTitle: {
    marginBottom: theme.spacing.unit * 3,
    textAlign: 'center'
  },
  section: {
    justifyContent: 'center',
    display: 'flex',
    textAlign: 'center'
  },
  textSection: {
    fontSize: '20px'
  },
  button: {
    height: '35px',
    width: '186px',
    marginTop: '1em',
    marginBottom: '1em',
    marginLeft: '19px',
    background: '#F2A138DE 0% 0% no-repeat padding-box',
    borderColor: '#F2A138DE',
    borderRadius: '3px',
    boxShadow: '1px 1px 3px #00000057',
    color: 'white',
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  ['@media (max-width: 425px)']: {
    textSection: {
      fontSize: '12px !important'
    },
    button: {
      width: '120px !important'
    }
  },
  pickedUpShipmentsGraph: {
    [theme.breakpoints.up('lg')]: {
      marginRight: '2%'
    }
  },
  tableSellers: {
    marginTop: '20px !important',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1300px'
  },
  report: {
    margin: '45px'
  },
  gridItem: {
    padding: `${theme.spacing.unit * 3}px !important`,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '49%'
    },
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    borderRadius: 2,
    backgroundColor: '#FFFFFF'
  },
  rowCardWrapper: {
    margin: '0px !important',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  filter: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '80%'
  },
  '@media (max-width: 880px)': {
    filter: {
      width: '100% !important'
    }
  },
  wrapperFilter: {
    width: '100%',
    padding: '24px',
    paddingTop: '35px',
    position: 'relative',
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    boxShadow:
      '0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)',
    backgroundColor: '#FFF'
  },
  wrapperRank: {
    margin: '0px !important',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100% !important',
    maxWidth: '1000px'
  },
  headerRanking: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  topRanking: {
    paddingLeft: '10%',
    paddingRight: '10%',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 811px)': {
      display: 'grid'
    },
    '@media (max-width: 425px)': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  loadingIndicator: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 1
  },
  arrow: {
    alignItems: 'center',
    marginTop: '25px'
  }
});

export default styles;
