import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { newAlert } from '../../../actions/alert_actions';
import InsuranceDetail from './InsuranceDetail';
import {
  fetchInsuranceInfo,
  editInsurance,
  cancelInsurance,
  fetchCancellationCausals,
  toggleBaseErrorModal
} from '../../../actions/insurance_actions';

function mapStateToProps(state) {
  return {
    currentUser: state.authentication.currentUser,
    insuranceDetail: state.insurances.insuranceDetail,
    clientDetail: state.insurances.clientDetail,
    affiliatesDetail: state.insurances.affiliatesDetail,
    secondInsuredDetail: state.insurances.secondInsuredDetail,
    beneficiariesDetail: state.insurances.beneficiariesDetail,
    movementsDetails: state.insurances.movementsDetails,
    cancellationCausals: state.insurances.cancellationCausals,
    currentUserId: state.authentication.currentUser.id,
    requestSend: state.requestState.requestSend,
    server_errors: state.insurances.errors,
    baseErrorOpen: state.insurances.baseErrorOpen,
    baseErrorText: state.insurances.baseErrorText,
    baseErrorTitle: state.insurances.baseErrorTitle,
    baseErrorRoute: state.insurances.baseErrorRoute
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchInsuranceInfo: bindActionCreators(fetchInsuranceInfo, dispatch),
    editInsurance: bindActionCreators(editInsurance, dispatch),
    cancelInsurance: bindActionCreators(cancelInsurance, dispatch),
    fetchCancellationCausals: bindActionCreators(
      fetchCancellationCausals,
      dispatch
    ),
    toggleBaseErrorModal: bindActionCreators(toggleBaseErrorModal, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceDetail);
