import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUITable from '@material-ui/core/Table';
import MUITableBody from '@material-ui/core/TableBody';
import MUITableCell from '@material-ui/core/TableCell';
import MUITableHead from '@material-ui/core/TableHead';
import MUITableRow from '@material-ui/core/TableRow';
import MUITableSortLabel from '@material-ui/core/TableSortLabel';
import MUITooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import LoadingIndicator from '../LoadingIndicator';
import TableStyles from './Table.Styles';
import _isEqual from 'lodash/isEqual';
import _remove from 'lodash/remove';
import { columnChooserItems } from './helpers';
import classnames from 'classnames';

import TableRow from './TableRow';
import TablePagination from './TablePagination';
import NoCountTablePagination from './NoCountTablePagination';
import TableToolbar from './TableToolbar';

export class Table extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    onChangePage: PropTypes.func,
    onChangePageRowsPerPage: PropTypes.func,
    onClickOnRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    rowsSelected: PropTypes.array,
    rowsPerPage: PropTypes.number,
    rowsPerPageOptions: PropTypes.array,
    page: PropTypes.number,
    totalCount: PropTypes.number,
    name: PropTypes.string,
    orderBy: PropTypes.string,
    order: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    withSelection: PropTypes.bool,
    withDownload: PropTypes.bool,
    withToolbar: PropTypes.bool,
    withColumnChooser: PropTypes.bool,
    withNoCountPagination: PropTypes.bool,
    batchItemsOptions: PropTypes.array
  };

  state = {
    selectedAll: false,
    hiddenColumnNames: []
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !_isEqual(this.props.data, nextProps.data) ||
      !_isEqual(this.props.columns, nextProps.columns) ||
      !_isEqual(this.props.loading, nextProps.loading) ||
      !_isEqual(this.props.downloadUrl, nextProps.downloadUrl) ||
      !_isEqual(this.props.rowsSelected, nextProps.rowsSelected) ||
      !_isEqual(this.state.selectedAll, nextState.selectedAll) ||
      !_isEqual(this.state.hiddenColumnNames, nextState.hiddenColumnNames) ||
      !_isEqual(this.props.loadingDownload, nextProps.loadingDownload)
    ) {
      return true;
    }
    return false;
  }

  _handleSelectAllRows = () => {
    const { onSelectRow = null, rowsSelected, data } = this.props;
    if (rowsSelected.length === 0) {
      if (onSelectRow) {
        onSelectRow(data.map(r => r.id));
      }
    } else {
      if (onSelectRow) {
        onSelectRow([]);
      }
    }
  };

  _handleToggleColumnVisibility = columnName => {
    const currentHiddenColumnNames = [...this.state.hiddenColumnNames];
    if (currentHiddenColumnNames.includes(columnName)) {
      _remove(
        currentHiddenColumnNames,
        hiddenColumnName => hiddenColumnName === columnName
      );
      this.setState({ hiddenColumnNames: [...currentHiddenColumnNames] });
    } else {
      this.setState({
        hiddenColumnNames: [...currentHiddenColumnNames, columnName]
      });
    }
  };

  render() {
    const {
      classes,
      columns = [],
      data: rows = [],
      onChangePage,
      onChangePageRowsPerPage,
      rowsPerPage = 10,
      rowsPerPageOptions = [10, 25, 50, 100],
      page = 1,
      name = '',
      orderBy,
      order = 'desc',
      onClickOnRow = null,
      onSelectRow = null,
      rowsSelected = [],
      withSelection = false,
      withToolbar = true,
      withDownload = false,
      downloadUrl = '',
      loadingDownload = false,
      withColumnChooser = true,
      withNoCountPagination = false,
      nextPage= false,
      withPagination = !withNoCountPagination,
      cellComponent = null,
      loading,
      totalCount = 0,
      filterToolbar,
      batchItemsOptions,
      onDownloadIconClick
    } = this.props;
    return (
      <Paper className={classnames(classes.baseRoot, classes.root)}>
        {loading && <LoadingIndicator />}
        {!loading && (
          <Fragment>
            {withToolbar && (
              <TableToolbar
                classes={classes}
                loading={loading}
                columns={columns}
                name={name}
                rowsSelected={rowsSelected}
                totalCount={totalCount}
                filterToolbar={filterToolbar}
                withDownload={withDownload}
                downloadUrl={downloadUrl}
                onDownloadIconClick={onDownloadIconClick}
                loadingDownload={loadingDownload}
                withSelection={withSelection}
                withColumnChooser={withColumnChooser}
                batchItemsOptions={batchItemsOptions}
                toggleColumnVisibility={this._handleToggleColumnVisibility}
                hiddenColumnNames={this.state.hiddenColumnNames}
                noCount={withNoCountPagination}
              />
            )}
            <div className={classes.tableWrapper}>
              <MUITable>
                <MUITableHead>
                  <MUITableRow classes={{ root: classes.tableHeadRowRoot }}>
                    {withSelection && (
                      <MUITableCell padding="checkbox" style={{ width: 8 }}>
                        <Checkbox
                          indeterminate={
                            rowsSelected.length > 0 &&
                            rowsSelected.length < rows.length
                          }
                          checked={rowsSelected.length === rows.length}
                          onChange={this._handleSelectAllRows}
                        />
                      </MUITableCell>
                    )}
                    {columnChooserItems(columns, this.state.hiddenColumnNames)
                      .filter(column => !column.hidden)
                      .map(({ column }, index) => (
                        <MUITableCell
                          key={index}
                          sortDirection={
                            orderBy === column.name ? order : false
                          }
                          classes={{
                            root: classes.tableCellRoot,
                            head: classes.tableCellHead
                          }}
                        >
                          <MUITooltip title="" enterDelay={300}>
                            <MUITableSortLabel
                              active={orderBy === column.name}
                              direction={order}
                              onClick={() => null}
                              classes={{
                                active: classes.tableCellHead
                              }}
                            >
                              {column.title}
                            </MUITableSortLabel>
                          </MUITooltip>
                        </MUITableCell>
                      ))}
                  </MUITableRow>
                </MUITableHead>
                <MUITableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={`${row.id}-${index}`}
                      columns={columns}
                      hiddenColumnNames={this.state.hiddenColumnNames}
                      row={row}
                      classes={classes}
                      withSelection={withSelection}
                      onClickOnRow={onClickOnRow}
                      onSelectRow={onSelectRow}
                      rowsSelected={rowsSelected}
                      cellComponent={cellComponent}
                      showArrayValuesAsChips
                    />
                  ))}
                </MUITableBody>
              </MUITable>
            </div>
            {withPagination && (
              <TablePagination
                currentPage={page}
                totalCount={totalCount}
                pageSize={rowsPerPage}
                pageSizes={rowsPerPageOptions}
                onPageSizeChange={onChangePageRowsPerPage}
                onCurrentPageChange={onChangePage}
              />
            )}
            {withNoCountPagination && (
              <NoCountTablePagination
                currentPage={page}
                pageSize={rowsPerPage}
                dataCount={totalCount}
                nextPage={nextPage}
                pageSizes={rowsPerPageOptions}
                onPageSizeChange={onChangePageRowsPerPage}
                onCurrentPageChange={onChangePage}
              />
            )}
          </Fragment>
        )}
      </Paper>
    );
  }
}

export default withStyles(TableStyles)(Table);
