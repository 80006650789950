import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '../../../shared/Table/Table';

import combineStyles from '../../../../helpers/combineStyles';
import TypographyStyles from '../../../../styles/typography';
import SpacingStyles from '../../../../styles/helpers/spacing';
import MovementsStyles from './Movements.styles';

import { beneficiariesColumns, beneficiariesFormatData } from './Metadata';

class Beneficiaries extends React.Component {
  render() {
    const { classes, title, rows } = this.props;
    return (
      <section className={classes.headerRoot}>
        <div className={classes.movementsDetails}>
          <h1 className={classes.headline8}>{'Información de los beneficiarios'}</h1>
        </div>
        <div className={classes.details}>
          <Table
            columns={beneficiariesColumns}
            data={beneficiariesFormatData(rows)}
            withPagination={false}
            withColumnChooser={false}
            withToolbar={false}
            loading={false}
          />
        </div>
      </section>
    );
  }
}

export default withStyles(
  combineStyles(MovementsStyles, TypographyStyles, SpacingStyles)
)(Beneficiaries);
