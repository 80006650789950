import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import InsuranceContractValidation from './InsuranceContractValidation';
import { newAlert } from '../../../actions/alert_actions';
import { validateInsuranceContract } from '../../../actions/insurance_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';

function mapStateToProps(state) {
  return {
    insuranceStore: state.insurances,
    serverErrors: state.insurances.errors,
    baseErrorOpen: state.insurances.baseErrorOpen,
    contractValid: state.insurances.contractValid,
    invoiceValidation:
      state.globalSetting.orgData.proexequial_invoice_validation,
    routes: state.stepper.routes,
    process: state.stepper.process
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    validateInsuranceContract: bindActionCreators(
      validateInsuranceContract,
      dispatch
    ),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InsuranceContractValidation);
