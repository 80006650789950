import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from './Modal';
import { Formik, Field } from 'formik';
import MultipleSelectBox from '../../shared/Selects/MultiSelect/MultiSelectBox';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../shared/GridItem';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import ButtonStyles from '../../../styles/button';
import SpacingStyles from '../../../styles/helpers/spacing';

import _isEqual from 'lodash/isEqual';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  }
});

export class EditProfileModal extends Component {
  state = {
    mappedCausals: [],
    selectedCausals: [],
    previousSelectedCausals: [],
    loadingCausals: true
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentDidMount() {
    this._mapCausals(this.props.causals);
  }

  _mapCausals = causals => {
    const mapped = [];
    causals.map(c => {
      mapped.push({
        value: Number.parseInt(c.id),
        title: c.description
      });
    });
    console.log(mapped);
    this.setState({
      mappedCausals: mapped,
      loadingCausals: false
    });
  };

  changeItems = selectedItems => {
    console.log('change', selectedItems);
    this.setState({ selectedCausals: selectedItems });
  };

  _handleOnSave = async data => {
    const { onSave } = this.props;
    onSave(data);
  };

  _isDirty = () => {
    return !_isEqual(
      this.state.previousSelectedCausals,
      this.state.selectedCausals
    );
  };

  render() {
    const { loadingCausals, mappedCausals, selectedCausals } = this.state;
    const { classes, open = true, causals } = this.props;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          causals: selectedCausals
        }}
        onSubmit={async (values, actions) => {
          this._handleOnSave(values);
          this._handleOnClose();
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              footer: classes.modalFooter
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {'Rechazar Validación'}
                  </p>
                </div>
                <Grid container spacing={16}>
                  <GridItem xs={12}>
                    <Field
                      name="causals"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <OutlinedSelectChippedInput
                            label={'Causales'}
                            classes={{
                              root: classes.selectRoot,
                              filterColor: classes.filterColor
                            }}
                            name={'causals'}
                            options={mappedCausals}
                            inputLabel={'Causales'}
                            value={values.causals}
                            multiple={true}
                            withChip={true}
                            field={field}
                            form={form}
                          />
                        );
                      }}
                    />
                  </GridItem>
                </Grid>
              </Fragment>
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={!dirty}
                  isSubmitting={false}
                >
                  {'Enviar'}
                </ProgressButton>
              </Fragment>
            }
          />
        )}
      />
    );
  }
}

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(EditProfileModal);
