import {
  INSURANCES_SET,
  SET_INSURANCE_INFO,
  CLEAR_INSURANCE_INFO,
  SET_INSURANCE_ERROR,
  CLEAR_INSURANCE_ERROR,
  SET_INSURANCE_CONTRACT_VALID,
  SET_INSURANCE_CLIENT_VALID,
  INSURANCE_TOGGLE_BASE_MODAL,
  SET_INSURANCE_DETAIL,
  SET_INSURANCE_CLIENT_DETAIL,
  SET_INSURANCE_MOVEMENTS_DETAILS,
  SET_INSURANCE_CANCELLATION_CAUSALS,
  INSURANCE_PERSIST,
  SET_INSURANCE_AFFILIATES_DETAIL,
  SET_INSURANCE_BENEFICIARIES_DETAIL,
  SET_INSURANCE_SECONDINSURED_DETAIL
} from '../constants/action_constants';

const initialState = {
  contractValid: false,
  clientValid: false,

  insuranceList: [],
  meta: null,

  insuranceDetail: null,
  clientDetail: null,
  affiliatesDetail: null,
  beneficiariesDetail: null,
  secondInsuredDetail: null,
  movementsDetails: null,

  contract: null,
  invoice: null,
  type: '',
  value: null,
  typeOfIdentification: '',
  identification: null,
  firstName: null,
  lastName: null,
  mobile: null,
  email: null,
  birthDate: null,
  expeditionDate: null,
  affiliates: null,

  cancellationCausals: null,

  errors: {},
  stepIsLoading: false,
  baseErrorOpen: false,
  baseErrorText: '',
  baseErrorTitle: '',
  baseErrorRoute: ''
};

export function insurances(state = initialState, action) {
  switch (action.type) {
    case SET_INSURANCE_INFO:
      return {
        ...state,
        ...action.data,
        errors: {}
      };
    case CLEAR_INSURANCE_INFO:
      return {
        ...state,
        ...initialState
      };
    case SET_INSURANCE_ERROR:
      return {
        ...state,
        stepIsLoading: false,
        errors: action.errors
      };
    case CLEAR_INSURANCE_ERROR:
      return {
        ...state,
        errors: {}
      };
    case SET_INSURANCE_CONTRACT_VALID:
      return {
        ...state,
        contractValid: action.data
      };
    case SET_INSURANCE_CLIENT_VALID:
      return {
        ...state,
        clientValid: action.data
      };
    case INSURANCE_TOGGLE_BASE_MODAL:
      return {
        ...state,
        baseErrorOpen: action.open,
        baseErrorText: action.text,
        baseErrorTitle: action.title,
        baseErrorRoute: action.route
      };
    case INSURANCES_SET:
      return {
        ...state,
        insuranceList: action.data,
        meta: action.meta
      };
    case SET_INSURANCE_DETAIL:
      return {
        ...state,
        insuranceDetail: action.data
      };
    case SET_INSURANCE_CLIENT_DETAIL:
      return {
        ...state,
        clientDetail: action.data
      };
    case SET_INSURANCE_AFFILIATES_DETAIL:
      return {
        ...state,
        affiliatesDetail: action.data
      };
    case SET_INSURANCE_BENEFICIARIES_DETAIL:
      return {
        ...state,
        beneficiariesDetail: action.data
      };
    case SET_INSURANCE_SECONDINSURED_DETAIL:
      return {
        ...state,
        secondInsuredDetail: action.data
      };
    case SET_INSURANCE_MOVEMENTS_DETAILS:
      return {
        ...state,
        movementsDetails: action.data
      };
    case SET_INSURANCE_CANCELLATION_CAUSALS:
      return {
        ...state,
        cancellationCausals: action.data
      };
    case INSURANCE_PERSIST:
      return {
        ...action.data
      };
    default:
      return state;
  }
}
