import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  pointOfSaleId: Yup.string()
    .nullable()
    .required('Se debe escoger un punto de venta'),
  mac: Yup.string()
    .nullable()
    .required('MAC es requerido'),
  serial: Yup.string()
    .nullable()
    .required('Serial es requerido'),
  methodValidation: Yup.string()
    .nullable()
    .required('Se debe escoger un método de validación')
});

export default validationSchema;
