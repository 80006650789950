import moment from 'moment-timezone';
import _compact from 'lodash/compact';
import formatMoney from '../../../../helpers/formatMoney';

export const salesColumns = [
  {
    title: '# Venta',
    name: 'id'
  },
  {
    title: 'Contrato',
    name: 'contractId'
  },
  {
    title: 'Fecha',
    name: 'registeredDate'
  },
  {
    title: 'Aliado',
    name: 'supplierName'
  },
  {
    title: 'Valor Total',
    name: 'saleTotal'
  },
  {
    title: 'Estado',
    name: 'state'
  }
];

export const salesMapData = data =>
  data.map(datum => {
    return {
      ...datum,
      registeredDate:
        moment(datum.registeredAt)
          .locale('es')
          .format('DD MMM YYYY') || '--',
      saleTotal: formatMoney(datum.totalValue)
    };
  });
