import _pickBy from 'lodash/pickBy';

export const geographicLocationFilters = filters => {
  const {
    supplier,
    category,
    geographicLocation,
    contractStratum,
    extraQuotumName,
    date,
    page,
    per_page
  } = filters;
  const parsedDate =
    date && date.includes('/') ? date.replace(/\//g, '-') : date;
  const sanitizedFilters = {
    supplierName: supplier,
    categoryName: category,
    contractStratumName: contractStratum,
    geographicLocationDescription: geographicLocation,
    name: extraQuotumName,
    startAt: parsedDate,
    endAt: parsedDate,
    page,
    perPage: per_page
  };

  return _pickBy(sanitizedFilters, filter => !!filter);
};

export const extraQuotumSaveSanitizer = values => {
  const { suppliers } = values;
  const sanitizedFilters = {
    ...values,
    supplierIds: suppliers.map(s => s.value)
  };
  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

export const extraQuotumUpdateSanitizer = values => {
  const { suppliers } = values;
  const sanitizedFilters = {
    ...values,
    id: values.id,
    supplierIds: suppliers.map(s => s.value)
  };
  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};
