import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import TextFieldInput from '../../../shared/Inputs/TextField';
import { formatBack } from '../../../shared/Inputs/Masks';
import DateFieldInput from '../../../shared/Inputs/DateField';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';
import OutlinedSelectInput from '../../../shared/Inputs/OutlinedSelect';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import PercentageIcon from '../../../icons/Percentage';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import _forEach from 'lodash/forEach';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import ExtraQuotumFormValidation from './ExtraQuotumForm.validation';
import ChippedAutocompleteInput from '../../../shared/Inputs/ChippedAutocomplete';
import CheckboxInput from '../../../shared/Inputs/Checkbox';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  scrollHandler: {
    maxHeight: '650px',
    overflow: "auto"
  },
  centerCheckBox: {
    display: 'flex'
  }
});

const OPTIONS_DROPDOWN = [
  {
    title: 'Valor',
    value: 'V'
  },
  {
    title: 'Porcentaje',
    value: 'P'
  }
];

export class ExtraQuotumFormModal extends Component {
  state = {
    category: '',
    contract_stratum: '',
    geographic_location: '',
    add_on_invoice: false
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  state = {};

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnSave = async extraQuotum => {
    const { onSave } = this.props;
    const mapSubmit = {
      ...extraQuotum,
      value:
        extraQuotum.option == 'V'
          ? formatBack(extraQuotum.value, 'currency')
          : extraQuotum.value
    };
    onSave(mapSubmit);
  };

  _getSuggestions = async (type, value) => {
    let items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    if (type == 'suppliers') {
      const currentItemsSelected = this.formik.state.values[type].map(
        r => r.value
      );
      items = items.filter(item => !currentItemsSelected.includes(item.value));
    }

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v));
    return errors;
  };

  render() {
    const { classes, open = true, isSubmitting, extraQuotum = {}, companyName } = this.props;

    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        validationSchema={ExtraQuotumFormValidation(companyName)}
        initialValues={{
          ...extraQuotum,
          category_name: extraQuotum.category ? extraQuotum.category.name : '',
          geographic_location_name: extraQuotum.geographic_location ? extraQuotum.geographic_location.description : '',
          contract_stratum_name: extraQuotum.contract_stratum ? extraQuotum.contract_stratum.name : '',
          suppliers: extraQuotum.suppliers.map(s => ({ label: s.name, value: s.id }))
        }}
        onSubmit={(values) => {
          this._handleOnSave(values);
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty,
          setFieldValue,
          errors,
          touched
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              footer: classes.modalFooter,
              body: classes.scrollHandler
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {values.isNew ? 'Crear Extra Cupo' : 'Editar Extra Cupo'}
                  </p>
                </div>
                <Grid container spacing={16}>
                  <GridItem xs={12}>
                    <Field
                      name="name"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <TextFieldInput
                            value={values.name}
                            label={'Nombre'}
                            fullWidth
                            field={field}
                            form={form}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="option"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <OutlinedSelectInput
                            value={values.option}
                            field={field}
                            form={form}
                            label={'Tipo'}
                            options={OPTIONS_DROPDOWN}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="value"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <TextFieldInput
                            value={values.value}
                            label={'Valor'}
                            fullWidth
                            field={field}
                            form={form}
                            startAdornment={
                              values.option === 'V' ? (
                                <AttachMoneyIcon />
                              ) : (
                                <PercentageIcon />
                              )
                            }
                            mask={
                              values.option === 'V' ? 'currency' : 'percentage'
                            }
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="startAt"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <DateFieldInput
                            value={values.start_at || null}
                            label={'Fecha de Inicio'}
                            fullWidth
                            field={field}
                            form={form}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="endAt"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <DateFieldInput
                            value={values.end_at || null}
                            label={'Fecha de Finalización'}
                            fullWidth
                            field={field}
                            form={form}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="categoryName"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <AutocompleteInput
                            id="category_name"
                            name="category_id"
                            label="Categoria"
                            margin="normal"
                            variant="outlined"
                            error={
                              touched.category_id &&
                              (!!errors.category_name || !!errors.category_id)
                            }
                            helperText={
                              touched.category_id &&
                              (errors.category_name || errors.category_id)
                            }
                            value={
                              this.state.category || values.category_name || ''
                            }
                            onChange={change => {
                              setFieldValue('category_id', null);
                              setFieldValue('category_name', change);
                              this.setState({ category: change });
                            }}
                            suggestions={this.props.categories}
                            getSuggestions={value =>
                              this._getSuggestions('categories', value)
                            }
                            onSuggestionSelected={category => {
                              setFieldValue('category_name', category.label);
                              setFieldValue('category_id', category.value);
                            }}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="geographicLocationName"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <AutocompleteInput
                            id="geographic_location_name"
                            name="geographic_location_id"
                            label="Ubicación Geografica"
                            margin="normal"
                            variant="outlined"
                            error={
                              touched.geographic_location_id &&
                              (!!errors.geographic_location_name ||
                                !!errors.geographic_location_id)
                            }
                            helperText={
                              touched.geographic_location_id &&
                              (errors.geographic_location_name ||
                                errors.geographic_location_id)
                            }
                            value={
                              this.state.geographic_location ||
                              values.geographic_location_name ||
                              ''
                            }
                            onChange={change => {
                              setFieldValue('geographic_location_id', '');
                              setFieldValue('geographic_location_name', change);
                              this.setState({ geographic_location: change });
                            }}
                            suggestions={this.props.geographicLocations}
                            getSuggestions={value =>
                              this._getSuggestions('geographicLocations', value)
                            }
                            onSuggestionSelected={geographicLocation => {
                              setFieldValue(
                                'geographic_location_name',
                                geographicLocation.label
                              );
                              setFieldValue(
                                'geographic_location_id',
                                geographicLocation.value
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="contractStratumName"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <AutocompleteInput
                            id="contract_stratum_name"
                            name="contract_stratum_id"
                            label="Estrato Social"
                            margin="normal"
                            variant="outlined"
                            error={
                              touched.contract_stratum_id &&
                              (!!errors.contract_stratum_name ||
                                !!errors.contract_stratum_id)
                            }
                            helperText={
                              touched.contract_stratum_id &&
                              (errors.contract_stratum_name ||
                                errors.contract_stratum_id)
                            }
                            value={
                              this.state.contract_stratum ||
                              values.contract_stratum_name ||
                              ''
                            }
                            onChange={change => {
                              setFieldValue('contract_stratum_id', '');
                              setFieldValue('contract_stratum_name', change);
                              this.setState({ contract_stratum: change });
                            }}
                            suggestions={this.props.contractStrata}
                            getSuggestions={value =>
                              this._getSuggestions('contractStrata', value)
                            }
                            onSuggestionSelected={contractStratum => {
                              setFieldValue(
                                'contract_stratum_name',
                                contractStratum.label
                              );
                              setFieldValue(
                                'contract_stratum_id',
                                contractStratum.value
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </GridItem>
                <GridItem
                  xs={6}
                  className={classes.centerCheckBox}
                >
                  <Field
                    name={'add_on_invoice'}
                    render={({ field, form }) => (
                      <CheckboxInput
                        label={'Sumar a cupo en factura'}
                        field={field}
                        form={form}
                        value={this.state.add_on_invoice || values.addOnInvoice}
                        onChange={e => {
                          handleChange(e);
                        }}
                        classes={{
                          root: classnames(
                            classes.noMarginTop,
                            classes.checkboxContainer
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="suppliers"
                      render={({ field, form }) => (
                        <ChippedAutocompleteInput
                          getSuggestions={value =>
                            this._getSuggestions('suppliers', value)
                          }
                          value={this.state.supplier}
                          values={values.suppliers}
                          onChange={evt => {
                            const {
                              target: { value }
                            } = evt;
                            this.setState({ supplier: value });
                            handleChange(evt);
                          }}
                          field={field}
                          form={form}
                          label={'Proveedores'}
                        />
                      )}
                    />
                  </GridItem>
                </Grid>
              </Fragment>
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={!dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Guardar'}
                </ProgressButton>
              </Fragment>
            }
          />
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(ExtraQuotumFormModal);
