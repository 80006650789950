import Page404 from './Page404';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { openQueryPromissoryModal } from '../../../actions/query_promissory_actions';
import { openQueryQuotaModal } from '../../../actions/query_quota_actions';
import { openQueryScoringQuotaModal } from '../../../actions/query_scoring_quota_action';
import { openCalculateInstalmentsModal } from '../../../actions/calculate_instalments_actions';

function mapStateToProps(state) {
  return {
    permissions: state.authentication.permissions,
    currentUser: state.authentication.currentUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    menuFunctions: {
      openQueryPromissoryModal: bindActionCreators(
        openQueryPromissoryModal,
        dispatch
      ),
      openQueryQuotaModal: bindActionCreators(openQueryQuotaModal, dispatch),
      openQueryScoringQuotaModal: bindActionCreators(openQueryScoringQuotaModal, dispatch),
      openCalculateInstalmentsModal: bindActionCreators(
        openCalculateInstalmentsModal,
        dispatch
      )
    }
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Page404);
