export const statusOptions = [
  { value: 'todos', title: 'Todos' },
  { value: 'activos', title: 'Activos' },
  { value: 'inactivos', title: 'Inactivos' }
];

export const hasSecurityQuestionsOptions = [
  { value: 'todos', title: 'Todos' },
  { value: 'SI', title: 'SI' },
  { value: 'NO', title: 'NO' }
];