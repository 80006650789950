import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import SecureCreditInstalmentsPercentageFilters from './Filters';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import FormModal from './modals/FormModal';

class SecureCreditInstalmentsPercentageList extends PureComponent {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    secureCreditInstalmentsPercentage: {
      isNew: true
    },
    isFormModalOpened: false
  };

  componentDidMount() {
    this._fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.secureCreditInstalmentsPercentageSaved !== this.props.secureCreditInstalmentsPercentageSaved &&
      this.props.secureCreditInstalmentsPercentageSaved
    ) {
      this.setState({ isFormModalOpened: false });
    }
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};

    this.props.fetchSecureCreditInstalmentsPercentages({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleOpenCreateModal = () => {
    this.setState({
      secureCreditInstalmentsPercentage: {
        isNew: true
      },
      isFormModalOpened: true
    });
  };

  _handleCloseCreateModal = () => {
    this.setState({
      isFormModalOpened: false
    });
  };

  _handleSave = secureCreditInstalmentsPercentage => {
    const query = parseQuerystring(this.props) || {};
    if (secureCreditInstalmentsPercentage.isNew) {
      this.props.createSecureCreditInstalmentsPercentage(
        {
          ...secureCreditInstalmentsPercentage
        },
        {
          ...query,
          page: query.page || 1,
          per_page: this.state.rowsPerPage
        }
      ).then(() => this._handleCloseCreateModal());
    } else {
      this.props.updateSecureCreditInstalmentsPercentage(
        {
          ...secureCreditInstalmentsPercentage
        },
        {
          ...query,
          page: query.page || 1,
          per_page: this.state.rowsPerPage
        }
      ).then(() => this._handleCloseCreateModal());
    }
  };

  _handleRowClick = (secureCreditInstalmentsPercentage, canUpdateSecureCreditInstalmentsPercentages) => {
    if (canUpdateSecureCreditInstalmentsPercentages) {
      this.setState({
        secureCreditInstalmentsPercentage: {
          ...this.state.secureCreditInstalmentsPercentage,
          ...secureCreditInstalmentsPercentage,
          isNew: false
        },
        isFormModalOpened: true
      });
    }
  };

  render() {
    const {
      classes,
      secureCreditInstalmentsPercentageList = [],
      metadata = {},
      requestSecureCreditInstalmentsPercentagesListSend = true,
      canCreateSecureCreditInstalmentsPercentages,
      canUpdateSecureCreditInstalmentsPercentages,
      canDeleteSecureCreditInstalmentsPercentages,
      deleteSecureCreditInstalmentsPercentage,
      requestErrorsOnSecureCreditInstalmentsPercentagesSave,
      requestSecureCreditInstalmentsPercentagesSaveSend
    } = this.props;

    const { page } = parseQuerystring(this.props) || {};

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Porcentajes de fianza por número de cuotas'}
          </h1>
          {canCreateSecureCreditInstalmentsPercentages && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenCreateModal}
            >
              {'Crear Porcentaje para fianza'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <SecureCreditInstalmentsPercentageFilters
              onChangeFilter={this._fetchItems}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Porcentajes de fianza por número de cuotas'}
            columns={columns}
            data={mapData(secureCreditInstalmentsPercentageList)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            totalCount={(metadata && metadata.totalCount) || 0}
            loading={requestSecureCreditInstalmentsPercentagesListSend}
            onClickOnRow={props =>
              this._handleRowClick(props, canUpdateSecureCreditInstalmentsPercentages)
            }
          />
        </div>
        {this.state.isFormModalOpened && (
          <FormModal
            onClose={this._handleCloseCreateModal}
            onSave={this._handleSave}
            onDelete={deleteSecureCreditInstalmentsPercentage}
            errors={requestErrorsOnSecureCreditInstalmentsPercentagesSave}
            isSubmitting={requestSecureCreditInstalmentsPercentagesSaveSend}
            canDeleteSecureCreditInstalmentsPercentages={canDeleteSecureCreditInstalmentsPercentages}
            secureCreditInstalmentsPercentage={this.state.secureCreditInstalmentsPercentage}
          />
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

SecureCreditInstalmentsPercentageList.propTypes = {
  metadata: PropTypes.object,
  secureCreditInstalmentsPercentageList: PropTypes.array.isRequired
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(SecureCreditInstalmentsPercentageList);