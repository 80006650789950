import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import SegmentHeader from '../../shared/SegmentHeader';
import ContactListItem from '../../shared/ListItem/ContactListItem';
import Divider from '@material-ui/core/Divider';

import StepperButtons from '../../shared/StepperButtons';

class DigitalSaleListContracts extends PureComponent {
  state = {
    contractId:
      this.props.contractList.length > 0 && this.props.contractList[0].id
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
  }

  onSubmit = () => {
    const {
      process,
      routes,
      debtorIdentificationType,
      debtorIdentification
    } = this.props;
    this.props.checkDigitalSaleSelectContract(
      {
        debtor_identification_type: debtorIdentificationType,
        debtor_identification: debtorIdentification,
        debtor_contract_number: this.state.contractId
      },
      routes[this.nextStep],
      process
    );
  };

  _handleClick = contractId => {
    this.setState({ contractId: contractId });
  };
  // eslint-disable-next-line complexity
  render() {
    const { classes, contractList } = this.props;
    // if (promissory && !promissoryCosigner && !transfer.requestMade) {
    //   return <ActivityIndicator />;
    // }
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <SegmentHeader className={classes.segmentHeader}>
            SELECCIONAR CONTRATO
          </SegmentHeader>
          {contractList.map((contract, i) => (
            <Fragment key={contract.id}>
              <ContactListItem
                heading={`${contract.id}`.replace(/_/, ' ')}
                onClick={() => this._handleClick(contract.id)}
                selected={contract.id === this.state.contractId}
              >
                <Fragment>
                  {contract.address}
                  <br />
                  {'Cupo disponible: ' + contract.available_quota}
                  <br />
                  {contract.has_unmaterialized_promissory &&
                    'Pagaré Inmaterializado '}
                </Fragment>
              </ContactListItem>
              {i < contractList.length - 1 && (
                <Divider component="li" className={classes.liDivider} />
              )}
            </Fragment>
          ))}
          <StepperButtons onSubmit={this.onSubmit} goBack={this.props.goBack} />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  liDivider: {
    listStyleType: 'none'
  }
});

DigitalSaleListContracts.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(DigitalSaleListContracts);
