import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import classnames from 'classnames';
import SalesReturnFilters from './SalesReturnFilters';
import parseQuerystring from '../../../helpers/parseQuerystring';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import SaleReturnDetailForm from './modal/SaleReturnDetailForm';
import { history } from '../../../helpers/history';
import RequestService from '../../../services/request_service';
import SaleReturnCreate from './modal/SaleReturnCreate';

const requestService = new RequestService();

class SalesReturnList extends React.Component {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    saleReturnDetail: null,
    createModal: false,
  };

  async componentDidMount() {
    this._fetchItems();
    this.props.fetchGeographicLocations();

    const {
      location: { state }
    } = this.props;

    if (state) {
      history.replace({
        pathname: window.location.pathname,
        search: window.location.search,
        state: null
      });
      if (state.entityId) {
        const item = await this._fetchItem(state.entityId);
        if (item) {
          this.setState({ saleReturnDetail: item });
        }
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.requestChangeReturnStatusSend === true &&
      this.props.requestChangeReturnStatusSend === false) {
      this._handleCloseForm();
    }

    const {
      location: { state }
    } = this.props;

    if (state) {
      history.replace({
        pathname: window.location.pathname,
        search: window.location.search,
        state: null
      });
      if (state.entityId) {
        const item = await this._fetchItem(state.entityId);
        if (item) {
          this.setState({ saleReturnDetail: item });
        }
      }
    }
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchSalesRevoke({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _fetchItem = async id => {
    try {
      const payload = await requestService.get(
        'sale_return_details',
        { sale_return_details_id_in: [id] },
        true
      );
      return payload.sales[0];
    } catch (errors) {
      return null;
    }
  };

  _handleRowClick = saleReturnDetail => this.setState({ saleReturnDetail });

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleCloseForm = () => this.setState({ saleReturnDetail: null });

  _handleOpenCreateModal = () => {
    this.setState({ createModal: true });
  };

  _handleCloseCreateModal = () => {
    this.setState({ createModal: false });
  };

  _handleRevoke = (id, comment) => {
    const query = parseQuerystring(this.props) || {};
    this.props.changeReturnStatus(id, { comment, action: 'revoked' }, {
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _handleApprove = (id, comment) => {
    const query = parseQuerystring(this.props) || {};
    this.props.changeReturnStatus(id, { comment, action: 'approved' }, {
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _handleReview = (id, comment) => {
    const query = parseQuerystring(this.props) || {};
    this.props.changeReturnStatus(id, { comment, action: 'reviewed' }, {
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  render() {
    const {
      classes,
      salesRevoke = [],
      meta = {},
      requestSend,
      geographicLocations,
      returnArticlesSalesMassive,
      canReturnArticlesSalesMassive
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Devolución'}
          </h1>
          {canReturnArticlesSalesMassive && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenCreateModal}
            >
              {'Nueva solicitud'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <SalesReturnFilters
              onChangeFilter={this._fetchItems}
              geographicLocations={geographicLocations}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Solicitud'}
            columns={columns}
            data={mapData(salesRevoke)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props => this._handleRowClick(props)}
            totalCount={(meta && meta.total_count) || 0}
            loading={requestSend}
          />
        </div>
        {!!this.state.saleReturnDetail && (
          <SaleReturnDetailForm
            saleReturnDetail={this.state.saleReturnDetail}
            canReviewReturnSale={this.props.canReviewReturnSale}
            canApproveReturnSale={this.props.canApproveReturnSale}
            canRevokeReturnSale={this.props.canRevokeReturnSale}
            companyName={this.props.companyName}
            onClose={this._handleCloseForm}
            onRevoke={this._handleRevoke}
            onApprove={this._handleApprove}
            onReview={this._handleReview}
            isSaving={this.props.requestChangeReturnStatusSend}
          />
        )}
        {this.state.createModal && canReturnArticlesSalesMassive && (
          <SaleReturnCreate
            onClose={this._handleCloseCreateModal}
            returnArticlesSalesMassive={returnArticlesSalesMassive}
            canReturnArticlesSalesMassive={canReturnArticlesSalesMassive}
          />
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

SalesReturnList.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
  salesRevoke: PropTypes.array.isRequired,
  fetchSalesRevoke: PropTypes.func.isRequired,
  meta: PropTypes.object
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(SalesReturnList);
