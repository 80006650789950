const styles = theme => ({
  root: {
    display: 'flex',
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  data: {
    wordBreak: 'break-all'
  },
  detailsTitle: {
    marginBottom: '25px !important'
  },
  divider: {
    width: '100%',
    margin: '20px 0'
  }
});

export default styles;