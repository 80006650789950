export const promissoryTypes = [
  { label: 'Digital', key: 1, value: 'digital' },
  { label: 'Manual', key: 2, value: 'manual' },
  { label: 'Digital y manual', key: 3, value: 'any_type' }
];

export const processesOrder = [
  {
    label:
      'Revisión de documentos antes de entrega de producto',
    key: 1,
    value: 'document_revision_before_delivery'
  },
  {
    label:
      'Entrega de producto antes de revisión de documentos',
    key: 2,
    value: 'delivery_before_document_revision'
  },
  { label: 'Cualquier orden', key: 3, value: 'any_order' }
];