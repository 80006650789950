import SalesList from './SalesList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { fetchSales, toggleSaleAction, exportToXlsx } from '../../../actions/sales_actions';
import { withRouter } from 'react-router-dom';
import { fetchOrgData } from '../../../actions/global_setting_actions';
import { fetchPointsOfSaleNames } from '../../../actions/points_of_sale_actions';

function mapStateToProps(state) {
  const { sales, meta } = state.sales;
  return {
    sales,
    meta,
    currentUserSupplier: state.authentication.currentUser.supplier,
    currentUserPos: state.authentication.currentUser.pointOfSales,
    canFilterSalesBy: state.authentication.currentUser.canFilterSalesBy,
    requestSend: state.requestState.requestSend,
    suppliers: state.globalSetting.orgData.suppliers,
    pointsOfSales: state.pointOfSale.pointsOfSaleNames,
    requestOrgDataSend: state.requestState.requestOrgDataSend,
    requestPointOfSalesSend: state.requestState.requestPointOfSalesSend,
    canExportSalesToExcel: state.authentication.currentUser.canExportSalesToExcel,
    requestXlsxUrl: state.requestState.requestexportToXlsxSend,
    canListDashboardSellersSummaries: 
      state.authentication.currentUser.canListDashboardSellersSummaries,
    canViewSummary: state.authentication.currentUser.canViewSummary,
    dataSellersSummary: state.dashboardSellers.sellersSummary,
    requestDashboardSellersSummarySend: 
      state.requestState.requestDashboardSellersSummarySend
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    fetchSales: bindActionCreators(fetchSales, dispatch),
    toggleAction: bindActionCreators(toggleSaleAction, dispatch),
    fetchPointsOfSaleNames: bindActionCreators(fetchPointsOfSaleNames, dispatch),
    exportToXlsx: bindActionCreators(exportToXlsx, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SalesList);
