import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import _capitalize from 'lodash/capitalize';

const GenerateMinuteModal = props => {
  const { open, onClose, onAction, onPreview, action } = props;
  const title = `${_capitalize(action)} minuta`;
  const message = `Desea previsualizar o ${action} la minuta?`;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{ title }</DialogTitle>
      <DialogContent>
        <DialogContentText>
          { message }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onPreview} color="secondary">
          Previsualizar
        </Button>
        <Button onClick={onAction} color="secondary">
          {_capitalize(action)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateMinuteModal;