import { Router, Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import AspiringSupplierSignUpPage from './pages/AspiringSupplierSignUpPage';
import DashboardPage from './pages/DashboardPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import UserUpdatePassword from './pages/UserUpdatePassword';
import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import { history } from '../helpers/history';
import AlertWrapper from './shared/Alerts/index.js';
import { theme } from './Theme';
import { bindActionCreators } from 'redux';
import { fetchUserSessionData } from '../actions/user_actions';
import LoadingIndicator from './shared/LoadingIndicator';
import SupplierProposalCreator from './pages/SupplierProposalCreator';
import SupplierProposalShow from './pages/SupplierProposalShow';

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

// CSS IMPORTS
import '@kenshooui/react-multi-select/dist/style.css';
import {} from '../assets/styles/GlobalStyle.css';

const mapStateToProps = state => ({
  loggedIn: state.authentication.loggedIn,
  currentUser: state.authentication.currentUser,
  requestFetchUserSessionDataSend:
    state.requestState.requestFetchUserSessionDataSend
});

function mapDispatchToProps(dispatch) {
  return {
    fetchUserSessionData: bindActionCreators(fetchUserSessionData, dispatch)
  };
}

class App extends React.Component {
  componentDidMount() {
    this._getCurrentUserDataWhenReload();
  }
  _getCurrentUserDataWhenReload = () => {
    const user = this._validateLocalStorageUser();

    const { fetchUserSessionData } = this.props;
    if (window.performance) {
      if (performance.navigation.type == 1 && user) {
        fetchUserSessionData(user);
      }
    }

    if (sessionStorage.createTS && user) { 
      // Chrome at least has a blank window.name and we can use this
      // signal this is a duplicated tab.
      if (!window.name) {
        sessionStorage.createTS = Date.now(); // treat as new
        sessionStorage.removeItem('user');
        window.location = "/login"; // force to  signon screen
      }
    } else {
      sessionStorage.createTS = Date.now();
    }
  };

  _validateLocalStorageUser = () => {
    const maybeUserString = sessionStorage.getItem('user');
    if (!maybeUserString) {
      return;
    }

    try {
      const maybeUser = JSON.parse(maybeUserString);

      if (typeof maybeUser !== 'object' || maybeUser === null) {
        return;
      }

      return maybeUser;
    } catch (error) {
      // pass
    }
  };

  render() {
    const {
      loggedIn,
      requestFetchUserSessionDataSend,
      currentUser
    } = this.props;
    const user = this._validateLocalStorageUser();
    return (
      <MuiThemeProvider theme={theme}>
        <AlertWrapper>
          <Router history={history}>
            <Switch>
              <Route
                exact
                path="/reset-password"
                render={renderProps => {
                  if (loggedIn) {
                    return <Redirect to="/login" />;
                  }

                  return <ResetPasswordPage {...renderProps} />;
                }}
              />
              <Route
                exact
                path="/reset-password/change-password"
                render={renderProps => {
                  if (loggedIn) {
                    return <Redirect to="/login" />;
                  }

                  return <ChangePasswordPage {...renderProps} />;
                }}
              />
              <Route
                exact
                path="/updatePass"
                render={() => {
                  return <UserUpdatePassword />;
                }}
              />
              <Route
                exact
                path="/login"
                render={() => {
                  if (loggedIn) {
                    return <Redirect to="/" />;
                  }
                  return <LoginPage />;
                }}
              />
              <Route
                exact
                path="/aspiring-supplier-sign-up"
                render={() => {
                  return <AspiringSupplierSignUpPage />;
                }}
              />
              <Route
                exact
                path="/supplier-proposals-creation"
                render={() => {
                  if (
                    (requestFetchUserSessionDataSend ||
                      requestFetchUserSessionDataSend == undefined) &&
                    !loggedIn &&
                    user != undefined
                  ) {
                    return <LoadingIndicator />;
                  }
                  if (loggedIn) {
                    return <SupplierProposalCreator />;
                  }
                  return <Redirect to="/login" />;
                }}
              />
              <Route
                exact
                path="/supplier-proposals/:id"
                render={({ match }) => {
                  if (
                    (requestFetchUserSessionDataSend ||
                      requestFetchUserSessionDataSend == undefined) &&
                    !loggedIn &&
                    user != undefined
                  ) {
                    return <LoadingIndicator />;
                  }
                  if (loggedIn) {
                    return <SupplierProposalShow match={match} />;
                  }
                  return <Redirect to="/login" />;
                }}
              />
              <Route
                path="/"
                render={renderProps => {
                  if (
                    (requestFetchUserSessionDataSend ||
                      requestFetchUserSessionDataSend == undefined) &&
                    !loggedIn &&
                    user != undefined
                  ) {
                    return <LoadingIndicator />;
                  }
                  if (!loggedIn) {
                    return <Redirect to="/login" />;
                  }
                  return <DashboardPage {...renderProps} />;
                }}
              />
            </Switch>
          </Router>
        </AlertWrapper>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
