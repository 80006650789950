import {
  TELESHOPPING_STEP_SUCCESS,
  TELESHOPPING_TOGGLE_BASE_MODAL,
  TELESHOPPING_STEP_ERROR,
  STEP_LOADING,
  STEP_NOT_LOADING,
  SET_NEXT_STEP,
  TELESHOPPING_PERSIST,
  TELESHOPPING_CLEAR,
  SET_DEBTOR_MOBILE,
} from '../constants/action_constants';
import { SET_DATA_TELESHOPPING_REGISTER } from '../constants/teleshopping_cosntants';
import RequestService from '../services/request_service';
import { newAlert } from './alert_actions';
import { history } from '../helpers/history';
import { teleshoppingSanitizer } from '../helpers/sanitizers/teleshoppingSanitizer';
import { completeRequest, sendRequest } from './request_state';
import { clearUrl, setUrl } from './file_actions';
import _get from 'lodash/get';
import { handleRequestError } from './session_actions';

const CACHE_KEY = 'teleshopping';
const requestService = new RequestService();
const requestServiceNode = new RequestService('node');

export const persistTeleshopping = () => dispatch => {
  const cache = sessionStorage.getItem(CACHE_KEY) || null;
  if (cache) {
    const data = JSON.parse(cache);
    dispatch({ type: TELESHOPPING_PERSIST, data });
  }
};

export const cacheTeleshopping = state => {
  const { teleshopping, stepper } = state;
  sessionStorage.setItem(CACHE_KEY, JSON.stringify(teleshopping));
  sessionStorage.setItem('stepper', JSON.stringify(stepper));
};

export const clearTeleshopping = () => dispatch => {
  localStorage.removeItem(CACHE_KEY);
  localStorage.removeItem('stepper');
  dispatch({ type: TELESHOPPING_CLEAR });
};

export function toggleBaseErrorModal(open, text, title, route) {
  return {
    type: TELESHOPPING_TOGGLE_BASE_MODAL,
    open,
    text,
    title,
    route
  };
}

export function setNextStep(process, nextStep) {
  return dispatch => {
    dispatch({
      type: SET_NEXT_STEP,
      process,
      nextStep,
      nextStepTitle: process
    });
  };
}

function stepSuccess(data) {
  return (dispatch, getState) => {
    dispatch({
      type: TELESHOPPING_STEP_SUCCESS,
      data
    });
    cacheTeleshopping(getState());
  };
}

function setDataTeleshoppingRegister(data) {
  return dispatch => {
    dispatch({
      type: SET_DATA_TELESHOPPING_REGISTER,
      data
    });
  };
}

function stepLoading() {
  return {
    type: STEP_LOADING
  };
}

function stopStepLoading() {
  return {
    type: STEP_NOT_LOADING
  };
}

function stepError(errors) {
  return {
    type: TELESHOPPING_STEP_ERROR,
    errors
  };
}

function setDebtorMobile(debtorMobile) {
  return dispatch => {
    dispatch({
      type: SET_DEBTOR_MOBILE,
      debtorMobile
    });
  };
}

export function updateContactDataTeleshopping(params = {}, contract, route, process) {
  return dispatch => {
    dispatch(sendRequest('VoucherV2TsUrl'));
    dispatch(clearUrl('voucherV2Ts'));
    dispatch(stepLoading());
    return requestService
      .post(
        `promissories/${contract}/contact_data`,
        { ...params, process },
        null
      )
      .then(
        payload => {
          if (payload.data.valid) {
            dispatch(setNextStep(process));
            dispatch(setDebtorMobile(payload.data.mobile));
            if (payload.data.base64 && payload.data.external_process_id) {
              dispatch(setUrl('voucherV2Ts', payload.data.base64))
              dispatch(completeRequest('VoucherV2TsUrl'));
            }
            dispatch(stepSuccess({ ...payload.data, base64: null }));
            history.push(route);
            dispatch(stopStepLoading());
          } else {
            dispatch(clearUrl('voucherV2Ts'));
            dispatch(stopStepLoading());
            const _error = payload.data;
            _error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
          }
        },
        error => {
          dispatch(stopStepLoading());
          error &&
            error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
        }
      );
  };
}

export function validateOtpV2Teleshopping(params = {}) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post('my-promissories-v2/validateOtp', params, {}, true)
      .then(
        payload => {
          if (params.signer == "deudor") {
            dispatch(stepSuccess({
              otpValidatorDebtor: payload.data.valid
            }));
          } else {
            dispatch(stepSuccess({
              otpValidatorCosigner: payload.data.valid
            }));
          }
          dispatch(stopStepLoading());

          if (payload.data.valid) {
            dispatch(newAlert('success', 's', payload.data.response));
          } else {
            dispatch(newAlert('error', 'ERROR:', payload.data.message));
          }
          return {
            valid: payload.data.valid,
          };
        },
        error => {
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(newAlert('error', 'ERROR:', e.errors.base));
                },
                response: e
              })(dispatch);
              dispatch(stopStepLoading());
            });
        }
      );
  };
};

export function sendOtpV2Teleshopping(params = {}) {
  return dispatch => {
    dispatch(stepLoading());
    dispatch(sendRequest('SendV2Otp'));
    return requestServiceNode
      .post('my-promissories-v2/sendOtpVoucher', params, null)
      .then(
        payload => {
          dispatch(completeRequest('SendV2Otp'))
          dispatch(stopStepLoading());
          if (payload.data.valid && payload.data.transaccionId) {
            dispatch(
              newAlert('success', 'Success:', 'OTP enviado exitosamente.')
            );
            const data = { otpV2TransaccionId: payload.data.transaccionId }
            dispatch(stepSuccess(data));
            return true;
          }
        },
        error => {
          dispatch(completeRequest('SendV2Otp'))
          dispatch(stopStepLoading());
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
                },
                response: e
              })(dispatch);
            });
        }
      );
  };
}

export function reSendOtpV2Teleshopping (params = {}) {
  return dispatch => {
    dispatch(sendRequest('NextOtpV2'));
    dispatch(stepLoading());
    return requestServiceNode
      .post('my-promissories-v2/reSendOtp', params, null)
      .then(
        payload => {
          if (payload.data.valid) {
            dispatch(completeRequest('NextOtpV2'));
            dispatch(
              newAlert('success', 'Success:', 'OTP reenviado exitosamente.')
            );
            dispatch(stopStepLoading());
            return true;
          }
        },
        error => {
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(newAlert('error', 'ERROR:', e.errors.base));
                },
                response: e
              })(dispatch);
            });
        }
      );
  }
};

export function validateSignedDocumentV2Teleshopping(params = {}) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .get('my-promissories-v2/documentState', params)
      .then(
        payload => {
          if (payload.data.valid) {
            dispatch(stepSuccess({
              voucherSerialV2: payload.data.documentSerial
            }));
            return {
              valid: true,
              voucherSerial: payload.data.documentSerial
            };
          }
        },
        error => {
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
                },
                response: e
              })(dispatch);
              dispatch(stopStepLoading());
            });
        }
      );
  };
};

export const validateContractToTeleshopping = (
  params = {},
  route,
  process
) => async dispatch => {
  try {
    dispatch(stepLoading());
    const payload = await requestService.post(
      'teleshopping/validate',
      { ...teleshoppingSanitizer(params) },
      {}
    );
    if (payload.data.valid) {
      dispatch(setDataTeleshoppingRegister({ ...payload.data }));
      dispatch(setNextStep(process));
      dispatch(stepSuccess({ ...payload.data, ...params }));
      dispatch(toggleBaseErrorModal(false, '', ''));
      if (params.process === 'remoteTeleshopping') {
        const sellers = await requestServiceNode.post('register-sale/sellers', { supplierId: payload.data.sale_summary.reference_sale_supplier_id });
        if (sellers.status === 'success') dispatch(stepSuccess({ sellers: sellers.data }));
        dispatch(stepSuccess({ ...payload.data, ...params }));
      }
      history.push(route);
    }
    dispatch(stopStepLoading());
  } catch (errors) {
    dispatch(stopStepLoading());
    const e = await errors.json();
    if (e.errors) {
      dispatch(stepError(e.errors));
      if (e.errors.base) {
        dispatch(toggleBaseErrorModal(true, e.errors.base[0], 'Televentas'));
      }
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
        )
      );
    }
  }
};

export const createTeleshopping = (
  params = {},
  process,
  routes,
  nextStep
) => async dispatch => {
  if (process === 'remoteTeleshopping') {
    if (params.salesmanId) dispatch(stepSuccess({ salesmanId: params.salesmanId, salesmanName: params.salesmanName }));

    dispatch(setNextStep(process, nextStep));
    history.push(routes[nextStep]);
    return;
  }

  dispatch(stepLoading());

  try {
    const payload = await requestService.post(
      'teleshopping',
      {
        ...teleshoppingSanitizer(params)
      },
      {}
    );
    dispatch(stopStepLoading());
    if (payload.data.valid) {
      dispatch(stepSuccess({ ...payload.data, ...params }));
      history.push(`/sales/${payload.data.sale_id}`);
    }
  } catch (errors) {
    dispatch(stopStepLoading());
    const e = await errors.json();
    if (e.errors) {
      dispatch(stepError(e.errors));
      if (e.errors.base) {
        dispatch(newAlert('error', 'ERROR:', e.errors.base[0]));
      }
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
        )
      );
    }
  }
};

export const createRemoteTeleshopping = (params = {}) => async dispatch => {
  dispatch(stepLoading());

  try {
    const payload = await requestService.post(
      'teleshopping/create_remote',
      teleshoppingSanitizer(params),
      {}
    );
    dispatch(stopStepLoading());
    if (payload.data.valid) {
      dispatch(stepSuccess({ ...payload.data, ...params }));
      history.push(`/sales/${payload.data.sale_id}`);
    }
  } catch (errors) {
    dispatch(stopStepLoading());
    const e = await errors.json();
    if (e.errors) {
      dispatch(stepError(e.errors));
      if (e.errors.base) {
        dispatch(newAlert('error', 'ERROR:', e.errors.base[0]));
      }
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
        )
      );
    }
  }
};
