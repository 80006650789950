import {
  LOAD_SESSION_DATA,
  OPEN_DEPLOY_MODAL,
  CLOSE_DEPLOY_MODAL,
  SET_RECAPTCHA_VALIDATION
} from '../constants/session_constants';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import RequestService from '../services/request_service';
import AxiosRequest from '../services/axios_request';

import { setCurrentUser } from '../actions/authentication_actions';
import { newAlert, serverStatusError } from './alert_actions';

const requestService = new RequestService();
const requestNodeService = new RequestService('node');
const axiosRequest = new AxiosRequest();

function getSessionData(data, meta) {
  return {
    type: LOAD_SESSION_DATA,
    data,
    meta
  };
}

function setRecaptchaValidation(data, meta) {
  return {
    type: SET_RECAPTCHA_VALIDATION,
    data,
    meta
  };
}

function _openDeployModal(data) {
  return {
    type: OPEN_DEPLOY_MODAL,
    data
  };
}

function _closeDeployModal() {
  return {
    type: CLOSE_DEPLOY_MODAL
  };
}

export const openDeployModal = (data = {}) => async dispatch => {
  dispatch(_openDeployModal(data));
};

export const closeDeployModal = () => async dispatch => {
  dispatch(_closeDeployModal());
};

export const handleRequestError = params => async dispatch => {
  const { beforeHandle, response, handleRequest } = params;
  beforeHandle && beforeHandle();
  if (response.status === 'http_logout') {
    const data = {
      action: response.status,
      body: {
        message: response.errors.base.toString()
      }
    };
    openDeployModal(data)(dispatch);
  } else {
    handleRequest();
  }
};

export const fetchSessionData = (params = {}) => async dispatch => {
  dispatch(sendRequest('GlobalSettingsCompanyCode'));
  try {
    const payload = await requestNodeService.get(
      'global-settings/company-code',
      params,
      true
    );
    dispatch(getSessionData(payload.data.value));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('GlobalSettingsCompanyCode'));
  }
};

export const recaptchaValidationActivation = (params = {}) => async dispatch => {
  dispatch(sendRequest('GlobalSettingsRecaptchaValidation'));
  try {
    const payload = await requestNodeService.get(
      'global-settings/recaptcha-validation',
      params,
      true
    );
    dispatch(setRecaptchaValidation(payload.data.active));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('GlobalSettingsRecaptchaValidation'));
  }
};

export const sendChangePasswordByAdmin = (
  params = {},
  showMessages
) => async dispatch => {
  dispatch(sendRequest('ChangePasswordByAdmin'));
  try {
    const payload = await requestNodeService.post(`users/unblock/${params.userId}`, {}, {}, true);
    if (showMessages) dispatch(newAlert('success', 'success', payload.data.message));

    return payload.data;
  } catch (errors) {
    dispatch(serverStatusError(errors));
    if (showMessages) {
      const e = await errors.json();
      e && e.errors && dispatch(newAlert('error', 'ERROR:', e.errors.base));
    }
    return { email: undefined };
  } finally {
    dispatch(completeRequest('ChangePasswordByAdmin'));
  }
};

export const sendResetPassword = (
  params = {},
  showMessages
) => async dispatch => {
  dispatch(sendRequest('ResetPassword'));
  try {
    const payload = await requestNodeService.post('users/resetPassword', params, {}, true);
    if (showMessages) dispatch(newAlert('success', 'success', payload.data.message));
    return payload.data;
  } catch (errors) {
    dispatch(serverStatusError(errors));
    if (showMessages) {
      const e = await errors.json();
      e && e.errors && dispatch(newAlert('error', 'ERROR:', e.errors.base));
    }
    return { email: undefined };
  } finally {
    dispatch(completeRequest('ResetPassword'));
  }
};

export const validateUserToken = (token, type = '') => async dispatch => {
  dispatch(sendRequest(type));
  try {
    const payload = await requestNodeService.get(
      `users/getUserFromToken/${token}`
    );
    return {
      email: payload.data.email,
      username: payload.data.username
    };
  } catch (errors) {
    const e = await errors;
    return { errors: e };
  } finally {
    dispatch(completeRequest(type));
  }
};

export const confirmTokenRecaptchaInvisible = (
  params = {}
) => async dispatch => {
  dispatch(sendRequest('ConfirmTokenRecaptchaInvisible'));
  try {
    const payload = await requestNodeService.post(
      'confirmTokenInvisible',
      params
    );
    return payload.data;
  } catch (errors) {
    dispatch(serverStatusError(errors));
    return { success: false };
  } finally {
    dispatch(completeRequest('ConfirmTokenRecaptchaInvisible'));
  }
};

export const confirmTokenRecaptchaV2 = (params = {}) => async dispatch => {
  dispatch(sendRequest('confirmTokenRecaptchaV2'));
  try {
    const payload = await requestNodeService.post('confirmToken', params, true);
    return payload.data;
  } catch (errors) {
    dispatch(serverStatusError(errors));
    return { success: false };
  } finally {
    dispatch(completeRequest('confirmTokenRecaptchaV2'));
  }
};

export const updatePasswordWithToken = (
  params = {},
  user = null
) => async dispatch => {
  dispatch(errorOnRequest('UpdatePassword', {}));
  dispatch(sendRequest('UpdatePassword'));
  try {
    const payload = await requestNodeService.put('users/updatePasswordWithToken', params);
    if (user) {
      user = Object.assign(user, payload.data.user);
      dispatch(setCurrentUser(user));
    }

    payload.data && payload.data.message && dispatch(newAlert('success', 'sucess', payload.data.message));
    setTimeout(() => window.location.reload(), 1000);

    return true;
  } catch (errors) {
    const e = await errors.json();

    e && e.errors && e.errors.base && dispatch(newAlert('error', 'ERROR:', e.errors.base));
    e && e.errors && dispatch(errorOnRequest('UpdatePassword', e.errors));
    return false;
  } finally {
    dispatch(completeRequest('UpdatePassword'));
  }
};
