import {
  LIST_GLOBAL_SETTINGS,
  SAVE_GLOBAL_SETTING,
  LOAD_ORG_DATA
} from '../constants/global_setting_constants';
import { sendRequest, completeRequest } from './request_state';
import { history } from '../helpers/history';
import globalSettingFiltersSanitizer from '../helpers/sanitizers/globalSettingFilters';
import { stringifyQuery } from '../helpers/parseQuerystring';
import RequestService from '../services/request_service';
import { serverStatusError, newAlert } from './alert_actions';
import { errorOnRequest } from './request_state';
import {
  saveGlobalSetting,
  getGlobalSettings
} from '../helpers/sanitizers/globalSettingSanitizer';
import snakecaseKeys from 'snakecase-keys';

const requestService = new RequestService();

const requestNodeService = new RequestService('node');

function setGlobalSettings(data, meta) {
  return {
    type: LIST_GLOBAL_SETTINGS,
    data,
    meta
  };
}

function savedGlobalSetting() {
  return {
    type: SAVE_GLOBAL_SETTING
  };
}

function setOrgData(data) {
  return {
    type: LOAD_ORG_DATA,
    data
  };
}

export const fetchGlobalSettings = (params = {}) => async dispatch => {
  dispatch(sendRequest('GlobalSettings'));
  history.replace(`/global-settings?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get(
      'global_settings',
      globalSettingFiltersSanitizer(params),
      true
    );
    dispatch(setGlobalSettings(getGlobalSettings(payload), payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('GlobalSettings'));
  }
};

export const fetchOrgData = (params = {}) => async dispatch => {
  dispatch(sendRequest('OrgData'));
  try {
    const payload = await requestNodeService.get(
      'global-settings/org-data',
      params,
      true
    );
    dispatch(setOrgData(snakecaseKeys(payload.data)));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('OrgData'));
  }
};

export const updateGlobalSetting = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('SaveGlobalSetting'));
  try {
    await requestService.put(
      `global_settings/${params.global_setting.id}`,
      saveGlobalSetting(params),
      {},
      true
    );
    dispatch(savedGlobalSetting());
    dispatch(fetchGlobalSettings(fetchParams));
  } catch (errors) {
    const e = await errors.json();
    if ('base' in e.errors) {
      dispatch(newAlert('error', 'ERROR:', e.errors['base'][0]));
    } else {
      dispatch(errorOnRequest('SaveGlobalSetting', e.errors));
    }
  } finally {
    dispatch(completeRequest('SaveGlobalSetting'));
  }
};
