import _pickBy from 'lodash/pickBy';

const homeVisitFilters = filters => {
  const {
    order,
    name = '',
    registered = '',
    pointOfSale,
    contract,
    status,
    date_range,
    page,
    perPage
  } = filters;
  const range = date_range ? date_range.split(' - ') : [];

  const sanitizedFilters = {
    registrationDateGteq: range[0] || date_range,
    registrationDateLteq: range[1] || date_range,
    externalCode: order,
    creator: name,
    saleChannel: registered,
    orderStatus: status,
    pointOfSale,
    contract,
    page,
    perPage
  };

  if (registered) {
    sanitizedFilters.saleChannel = Number(registered);
  }

  if (status === 'registered') {
    sanitizedFilters.orderStatus = 0;
  }
  if (status === 'approved') {
    sanitizedFilters.orderStatus = 2;
  }
  if (status === 'revoked') {
    sanitizedFilters.orderStatus = 3;
  }

  return _pickBy(sanitizedFilters, filter => !!filter || filter == 0);
};

export default homeVisitFilters;
