import ExtraQuotumList from './ExtraQuotumList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchExtraQuota,
  createExtraQuotum,
  updateExtraQuotum
} from '../../../actions/extra_quotum_actions';
import { fetchGeographicLocations } from '../../../actions/geographic_location_actions';
import { fetchContractStrata } from '../../../actions/contract_stratum_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';

function mapStateToProps(state) {
  return {
    suppliers: state.globalSetting.orgData.suppliers,
    categories: state.globalSetting.orgData.categories,
    requestExtraQuotaListSend: state.requestState.requestExtraQuotaListSend,
    canCreateExtraQuota: state.authentication.currentUser.canCreateExtraQuota,
    canUpdateExtraQuota: state.authentication.currentUser.canUpdateExtraQuota,
    extraQuota: state.extraQuotum.list,
    extraQuotumSaved: state.extraQuotum.saved,
    metadata: state.extraQuotum.metadata,
    geographicLocations: state.geographicLocation.list,
    contractStrata: state.contractStratum.list,
    requestErrorsOnSaveExtraQuotum:
      state.requestState.requestErrorsOnSaveExtraQuotum,
    requestSaveExtraQuotumSend: state.requestState.requestSaveExtraQuotumSend,
    companyName: state.globalSetting.orgData.company_name
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchExtraQuota: bindActionCreators(fetchExtraQuota, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    fetchGeographicLocations: bindActionCreators(
      fetchGeographicLocations,
      dispatch
    ),
    fetchContractStrata: bindActionCreators(fetchContractStrata, dispatch),
    createExtraQuotum: bindActionCreators(createExtraQuotum, dispatch),
    updateExtraQuotum: bindActionCreators(updateExtraQuotum, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ExtraQuotumList);
