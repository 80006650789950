import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import TextFieldInput from '../../../shared/Inputs/TextField';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import PasswordInput from '../../../shared/PasswordInput';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';

import {
  validationSchema,
  validationSchemaWithCurrentPassword,
  initialValues,
  initialValuesWithCurrentPassword
} from '../../../shared/ChangePassword.helpers';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  }
});

export class ChangePasswordModal extends Component {
  static propTypes = {
    isCurrentUser: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  _handleOnSave = newPassword => {
    const { onSave, isLogOut = false } = this.props;
    onSave(newPassword).then(isCorrect => {
      if (isCorrect && !isLogOut) this._handleOnClose();
    });
  };

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      isCurrentUser,
      isLogOut = false
    } = this.props;
    return (
      <Formik
        initialValues={
          isCurrentUser ? initialValuesWithCurrentPassword : initialValues
        }
        onSubmit={this._handleOnSave}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        enableReinitialize={false}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={
          isCurrentUser ? validationSchemaWithCurrentPassword : validationSchema
        }
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          touched,
          errors,
          dirty
        }) => (
          <Modal
            open={open}
            classes={{
              footer: classes.modalFooter
            }}
            body={
              <PasswordInput
                values={values}
                touched={touched}
                errors={errors}
                titleText={'Cambiar Contraseña'}
                showCurrentPassword={isCurrentUser}
                handleChange={handleChange}
              />
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {isLogOut ? 'Salir' : 'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={!dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Guardar'}
                </ProgressButton>
              </Fragment>
            }
          />
        )}
      />
    );
  }
}

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(ChangePasswordModal);
