import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MultipleSelectBox from '../../../shared/Selects/MultiSelect/MultiSelectBox';
import { _parsePOSname } from '../../../../helpers/utilityFunctions';
import Modal from '../../../shared/Modals/Modal';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import ProgressButton from '../../../shared/Buttons/ProgressButton';

import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';

import _isEqual from 'lodash/isEqual';

class SellersEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedSellers: [],
      selectedSellers: [],
      previousSelectedSellers: [],
      coordinator: [],
      loadingNames: true
    };
  }

  componentDidMount() {
    this._mapActualSellers(this.props.selectedSellers);
  }

  _savePOS = () => {
    const newPOS = [];
    this.state.selectedSellers.concat(this.state.coordinator).map(r => newPOS.push(r.id));
    this.props.onSave(newPOS);
    this.props.onClose();
  };

  _mapActualSellers = actualSellers => {
    const initialSelected = [];
    const coordinator = [];
    if (actualSellers) {
      actualSellers.map(s => {
        if (!s.roles.some(role => this.props.coordinatorRoles.includes(role.name)) ||
          this.props.canAddCoordinator) {
          initialSelected.push({
            id: Number.parseInt(s.id),
            label: `${s.firstName} ${s.lastName}`
          });
        } else {
          coordinator.push({
            id: Number.parseInt(s.id),
            label: `${s.firstName} ${s.lastName ? s.lastName : ''}`
          });
        }
      });
    }
    this.setState({
      selectedSellers: initialSelected,
      coordinator: coordinator,
      previousSelectedSellers: [...initialSelected]
    });
    this._mapSellers(this.props.sellers, initialSelected);
  };

  _mapSellers = (sellers, selected) => {
    const mapped = [];
    sellers.map(s => {
      mapped.push({
        id: Number.parseInt(s.id),
        label: `${s.firstName} ${s.lastName ? s.lastName : ''}`
      });
    });
    const users = Object.values(mapped.concat(selected).reduce((prev, next) => Object.assign(prev, { [next.id]: next }), {}));
    this.setState({
      mappedSellers: users,
      loadingNames: false
    });
  };
  changeItems = selectedItems => {
    this.setState({ selectedSellers: selectedItems });
  };

  _isDirty = () => {
    return !_isEqual(
      this.state.previousSelectedSellers,
      this.state.selectedSellers
    );
  };

  render() {
    const { mappedSellers, selectedSellers, loadingNames } = this.state;
    const { classes, loading, isSubmitting, onClose, onSave } = this.props;
    return (
      <Modal
        open={true}
        onClose={onClose}
        classes={{
          footer: classes.modalFooter,
          root: classes.modalRoot
        }}
        body={
          <Fragment>
            <div className={classes.titleWrapper}>
              <p className={classnames(classes.headline6, classes.title)}>
                {'Editar Vendedores'}
              </p>
            </div>
            <MultipleSelectBox
              loadingOptions={loadingNames}
              nameSingular="usuario"
              namePlural="usuarios"
              items={mappedSellers}
              selectedItems={selectedSellers}
              changeItems={this.changeItems}
              selectAllOption
            />
          </Fragment>
        }
        footer={
          <Fragment>
            <div className={classes.spacer} />
            <Button
              className={classes.button}
              onClick={onClose}
              color={'secondary'}
              classes={{ label: classes.buttonLabel }}
            >
              {'Cancelar'}
            </Button>
            <ProgressButton
              onClick={this._savePOS}
              className={classes.button}
              classes={{
                disabled: classes.buttonDisabled,
                label: classes.buttonLabel
              }}
              color={'secondary'}
              disabled={!this._isDirty()}
              isSubmitting={isSubmitting}
            >
              {'Guardar'}
            </ProgressButton>
          </Fragment>
        }
      />
    );
  }
}
const styles = theme => ({
  container: {},
  cardContainer: {
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  modalRoot: {
    width: 900
  }
});

SellersEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  actualPOS: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool
};

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles,
    styles
  ),
  { withTheme: true }
)(SellersEditor);
