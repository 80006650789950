const styles = theme => ({
  chipInputRoot: {
    minHeight: 55,
    padding: [[theme.spacing.unit, theme.spacing.unit * 1.75], '!important'],
    pointerEvents: 'none',
  },
  chipInput: {
    padding: [[theme.spacing.unit, 0, 0], '!important'],
  },
  chipInputLabel: {
    top: [0, '!important'],
  },
  labelFocused: {
    color: ['rgba(0, 0, 0, 0.87)', '!important']
  },
  focused: {
    '& fieldset': {
      borderColor: ['rgba(0, 0, 0, 0.87)', '!important'],
      borderWidth: [2, '!important']
    }
  },
  notchedOutline: {},
  chip: {
    backgroundColor: 'rgba(254,176,11,0.12)',
    margin: 0,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 0.5,
    marginBottom: theme.spacing.unit * 0.5,
    padding: '6px 35px 6px 12px',
    position: 'relative',
    '&:last-child': {
      marginRight: 0
    },
    '&:focus': {
      backgroundColor: 'rgba(254,176,11,0.12)'
    }
  },
  chipLabel: {
    margin: 0,
    padding: 0,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    textTransform: 'capitalize'
  },
  chipIcon: {
    backgroundColor: theme.palette.secondary.main,
    color: '#E0F4FF',
    borderRadius: '50%',
    fontSize: '15px',
    position: 'absolute',
    pointerEvents: 'all',
    right: '5px',
    '&:hover': {
      color: '#E0F4FF'
    }
  }
});

export default styles;
