import { DASHBOARD_SUMMARY, DASHBOARD_SALES, DASHBOARD_TOP_RATED} from '../constants/dashboard_constants';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { newAlert, serverStatusError } from './alert_actions'
import { history } from '../helpers/history';
import RequestService from '../services/request_service';
import AxiosRequest from '../services/axios_request'

const requestService = new RequestService();
const requestServiceNode = new RequestService('node');
const axiosRequest = new AxiosRequest();

function setDashboardSummary(data) {
  return {
    type: DASHBOARD_SUMMARY,
    data,
  };
}

function setDashboardSales(data) {
  return {
    type: DASHBOARD_SALES,
    data,
  };
}

function setDashboardTopRated(data) {
  return {
    type: DASHBOARD_TOP_RATED,
    data,
  };
}

export const fetchSummary = (params = {}) => async dispatch => {
  dispatch(sendRequest('DashboardSummary'));
  try {
    const payload = await requestServiceNode.post('dashboard/summary', params, true);
    dispatch(setDashboardSummary(payload.data));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest('DashboardSummary'));
  }
};

export const fetchSales = (params = {}) => async dispatch => {
  dispatch(sendRequest('DashboardSales'));
  try {
    const payload = await requestServiceNode.post('dashboard/sales', {...params}, true);
    dispatch(setDashboardSales(payload.data));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest('DashboardSales'));
  }
};

export const fetchTopRated = (params = {}) => async dispatch => {
  dispatch(sendRequest('DashboardTopRated'));
  try {
    const payload = await requestServiceNode.post('dashboard/topRated', {...params}, true);
    dispatch(setDashboardTopRated(payload.data));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest('DashboardTopRated'));
  }
};
