import _find from 'lodash/find';

export default function mapListToOptions(
  list,
  opts = {},
  byProperty,
  selected
) {
  const {
    raw = false,
    property = 'name',
    secondProperty = 'last_name',
    byAttribute = false,
    secondItem = false,
    withId = true,
    byValue = false
  } = opts;
  return list.map(item => {
    let hasAlready = false;
    if (selected) {
      hasAlready = _find(selected, { id: item.id });
    }
    return {
      key: item.id,
      id: item.id,
      value: byValue ? item['value'] : byProperty ? item[property] : item.id,
      label: raw
        ? item[property]
        : `${withId ? `${item.id} - ` : ''}${
            secondItem
              ? (byAttribute ? item['attributes'][property] : item[property]) +
                ' ' +
                (byAttribute
                  ? item['attributes'][secondProperty]
                  : item[secondProperty])
              : byAttribute
              ? item['attributes'][property]
              : item[property]
          }`,
      disabled: hasAlready ? true : false
    };
  });
}
// export default function mapListToOptions(list, opts = {}, byProperty) {
//   const { raw = false, property = 'name' } = opts;
//   return list.map(item => ({
//     key: item.id,
//     value: byProperty ? item[property] : item.id,
//     label: raw ? item[property] : `${item.id} - ${item[property]}`
//   }));
// };
