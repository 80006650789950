const styles = theme => ({
  root: {
    position: 'relative'
  },
  indicatorBar: {
    backgroundColor: '#F22D12',
    height: 4,
    marginTop: theme.spacing.unit * 2,
    opacity: 0.24,
    position: 'relative',
    width: '100%'
  },
  progressBar: {
    height: 4,
    width: '15%',
    backgroundColor: '#F22D12',
    position: 'absolute',
    top: 0
  },
  tooltip: {
    backgroundColor: '#2A3C47',
    color: '#FFFFFF !important',
    padding: '4px 12px'
  },
  tooltipPopper: {
    top: `-${theme.spacing.unit}px !important`
  }
});

export default styles;
