import { CircularProgress, Grid, withStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import classnames from 'classnames';
import { default as React } from 'react';
import combineStyles from '../../../helpers/combineStyles';
import parseQuerystring from '../../../helpers/parseQuerystring';
import ButtonStyles from '../../../styles/button';
import SpacingStyles from '../../../styles/helpers/spacing';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';
import ListsStyle from '../__styles__/Lists.style';
import BulkProcess from './BulkProcess';
import BulkLoadFilters from './filters/BulkLoadFilters';
import socketIOClient from 'socket.io-client';
import GracePeriod from './modal/GracePeriod';

class BulkLoadList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { progresses: {} };
  }

  state = {
    createModal: false,
  };

  _handleChangeFilter = params => {
    this._fetchItems(params);
  };

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchProcesses({
      ...query
    });
  };

  _handleOpenCreateModal = () => {
    this.setState({ createModal: true });
  };

  _handleCloseCreateModal = () => {
    this.setState({ createModal: false });
  };

  subscribeToSocket = () => {
    const socket = socketIOClient(process.env.REACT_APP_NODE_API);
    socket.on('bulk-load-progress', data => {
      this.setState(prevState => ({
        progresses: { ...prevState.progresses, [data.id]: data }
      }));
    });
  };

  componentDidMount() {
    this.subscribeToSocket();
    this._fetchItems();
  }

  render() {
    const {
      classes,
      bulkProcesses,
      requestSend,
      createGracePeriodMassive,
      canCreateMassivePeriodGrace,
    } = this.props;
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Cargues Masivos'}
          </h1>
          {canCreateMassivePeriodGrace && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenCreateModal}
            >
              {'Nueva solicitud'}
            </Button>
          )}
        </div>
        <div className={classes.filterOptionsWrapper}>
          <BulkLoadFilters onChangeFilter={this._handleChangeFilter} />
        </div>
        {requestSend ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          bulkProcesses.map(process => (
            <BulkProcess
              process={process}
              key={process.id}
              progress={this.state.progresses[process.id]}
            />
          ))
        )}
        {this.state.createModal && (
          <GracePeriod
            onClose={this._handleCloseCreateModal}
            createGracePeriodMassive={createGracePeriodMassive}
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(BulkLoadList);
