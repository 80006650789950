import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import _isFunction from 'lodash/isFunction';
import _last from 'lodash/last';
import AutocompleteStyles from './Autocomplete.styles';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import FormikPropsValidations from './FormikPropsValidations';
import combineStyles from './../../../helpers/combineStyles';
import FieldValidationPopup from '../Poppers/FieldValidation';
import TypographyStyles from '../../../styles/typography';
import { AUTOCOMPLETE_MAX_RESULTS } from '../../../constants/input_types';
//chip imports
import ChipFieldWrapper from './ChipFieldWrapper';
import _remove from 'lodash/remove';

const styles = () => ({
  testContainer: {
    visibility: 'hidden',
    position: 'relative',
    top: -27,
    height: '1px'
  },
  formControlRoot: {
    display: 'flex'
  }
});

export class ChippedAutocomplete extends Component {
  static propTypes = {
    getSuggestions: PropTypes.func.isRequired,
    maxResults: PropTypes.number,
    getSuggestionValue: PropTypes.func,
    onSuggestionSelected: PropTypes.func,
    onChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    name: props => FormikPropsValidations('name', props),
    field: PropTypes.object,
    form: PropTypes.object,
    helperText: PropTypes.string,
    validateField: PropTypes.func,
    autoWidth: PropTypes.bool
  };
  state = {
    value: '',
    suggestions: [],
    fieldName: this.props.name || _get(this.props, 'field.name'),
    fieldError: '',
    anchorEl: null
  };
  componentDidMount() {
    this._setFieldError();
  }
  componentDidUpdate(prevProps) {
    const { values, validateField } = this.props;

    const { fieldName } = this.state;
    if (_get(prevProps, 'form.errors') !== _get(this.props, 'form.errors')) {
      this._setFieldError();
    }
    if (values !== prevProps.values) {
      this.setState({ value: '' });
    }
    if (values !== prevProps.value && _isFunction(validateField)) {
      validateField(fieldName, values);
    }
  }
  _setFieldError = () => {
    const { fieldName } = this.state;
    const { form = {} } = this.props;
    const { errors } = form;
    const error = _get(errors, fieldName, '');
    this.setState({ fieldError: error });
  };

  _handleHideError = () => {
    this.setState({ anchorEl: null });
  };

  _handleDelete = chip => {
    const { onChange, values: currentValues } = this.props;
    const values = [...currentValues];
    _remove(values, value => value.label === chip);
    onChange({
      target: { name: this.state.fieldName, type: 'delete', value: values }
    });
  };

  _handleOnInputChange = value => {
    this.setState({ value });
    //const { values } = this.props;
    if (value) {
      this.handleSuggestionsFetchRequested({ value });
    }
  };

  renderInputComponent = inputProps => {
    const {
      classes,
      inputRef = () => { },
      ref,
      form,
      field,
      ...other
    } = inputProps;
    const { label, values, helperText, error, readOnly = false } = this.props;
    const { fieldName, fieldError } = this.state;
    const defaultHelperText = null;
    const { value } = this.state;

    return (
      <Fragment>
        <ChipFieldWrapper
          label={label}
          name={fieldName}
          onTextChange={this._handleOnInputChange}
          value={value}
          values={values.map(value => value.label)}
          onDelete={this._handleDelete}
          chipOnBlur={() => {
            setTimeout(
              function () {
                this.setState({ suggestions: [] });
              }.bind(this),
              250
            );
          }}
          helperText={helperText}
          form={form}
          field={field}
          readOnly={readOnly}
        />
        <TextField
          fullWidth
          variant="outlined"
          name={fieldName}
          label={''}
          value={value}
          debounce={0}
          onMouseEnter={this._handleShowError}
          onFocus={this._handleShowError}
          onMouseOut={this._handleHideError}
          InputProps={{
            inputRef: node => {
              ref(node);
              inputRef(node);
            },
            classes: {
              root: classes.testContainer
            }
          }}
          {...other}
          FormHelperTextProps={{
            classes: {
              root: classes.formHelperTextRoot
            }
          }}
          error={!!fieldError || !!error}
          helperText={helperText || defaultHelperText}
          classes={{
            root: classes.formControlRoot
          }}
        />
      </Fragment>
    );
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);
    const { classes } = this.props;
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div className={classes.suggestionRow}>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={String(index)} className={classes.boldWeight}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} className={classes.normalWeight}>
                {part.text}
              </strong>
            );
          })}
        </div>
      </MenuItem>
    );
  };

  _handleGetSuggestions = async value => {
    const inputValue = value ? value.trim() : '';
    const { values } = this.props;
    const {
      getSuggestions,
      maxResults = AUTOCOMPLETE_MAX_RESULTS
    } = this.props;

    const lastMatch = _last(values);
    if (_get(lastMatch, 'label', null) === value) {
      this.setState({ suggestions: [] });
    } else {
      const suggestions = await getSuggestions(value);
      this.setState({
        suggestions:
          inputValue.length === 0 ? [] : suggestions.slice(0, maxResults)
      });
    }
  };

  debouncedLoadSuggestions = _debounce(this._handleGetSuggestions, 500);

  handleSuggestionsFetchRequested = ({ value }) => {
    this.debouncedLoadSuggestions(value);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = name => (_event, { newValue }) => {
    const { validateField, form = null } = this.props;
    const { fieldError, fieldName } = this.state;
    if (fieldError && _isFunction(validateField)) {
      validateField(fieldName, newValue);
    }
    //todo this is still wrong
    this.setState({
      [name]: newValue
    });
    if (form) {
      form.setFieldTouched(fieldName);
    }
  };

  _handleBlur = ({ target: { value, name } }) => {
    const { validateField } = this.props;
    if (_isFunction(validateField)) {
      validateField(name, value);
    }
  };

  _handleSuggestionSelected = (_event, { suggestion }) => {
    const { onChange, values } = this.props;
    this.handleSuggestionsClearRequested();
    this.setState({ value: '' });
    const currentValues = [...values];
    currentValues.push(suggestion);
    onChange({ target: { name: this.state.fieldName, value: currentValues } });
    this.handleSuggestionsClearRequested();
  };

  _handleShowError = event => {
    const { fieldError } = this.state;
    if (fieldError) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };

  _handleHideError = () => {
    this.setState({ anchorEl: null });
  };

  _handleGetSuggestionValue = value => {
    const { getSuggestionValue } = this.props;
    if (_isFunction(getSuggestionValue)) {
      return getSuggestionValue(value);
    } else {
      return value.label;
    }
  };
  render() {
    const { classes, autoWidth = false, helperText } = this.props;
    const { fieldError, anchorEl } = this.state;
    const fieldValue = this.state.value;
    const autosuggestProps = {
      alwaysRenderSuggestions: true,
      renderInputComponent: this.renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this._handleGetSuggestionValue,
      onSuggestionSelected: this._handleSuggestionSelected,
      renderSuggestion: this.renderSuggestion
    };
    const defaultWidth = this.popperNode ? this.popperNode.clientWidth : null;
    const resultWidth = autoWidth ? autoWidth : defaultWidth;

    return (
      <div className={classes.root}>
        <Autosuggest
          alwaysRenderSuggestions={true}
          {...autosuggestProps}
          inputProps={{
            classes,
            value: fieldValue,
            onChange: this.handleChange(''),
            onBlur: this._handleBlur,
            inputRef: node => {
              this.popperNode = node;
            },
            InputLabelProps: {},
            form: this.props.form,
            field: this.props.field
          }}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
            containerOpen: classes.suggestionContainer,
            container: classes.suggestionContainer
          }}
          renderSuggestionsContainer={options => (
            <Popper
              anchorEl={this.popperNode}
              open={Boolean(options.children)}
              className={classes.suggestionPopper}
              placement={'bottom-start'}
            >
              <Paper
                square
                {...options.containerProps}
                style={{
                  width: resultWidth
                }}
              >
                {options.children}
              </Paper>
            </Popper>
          )}
        />
        {helperText == null && (
          <FieldValidationPopup
            anchorEl={this.state.anchorEl}
            message={this.state.fieldError}
          />
        )}
      </div>
    );
  }
}

export default withStyles(
  combineStyles(styles, AutocompleteStyles, TypographyStyles)
)(ChippedAutocomplete);
