import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog from '../../../shared/Dialogs/CustomDialog';

class GnpGasApplianceInfoModal extends React.PureComponent {
  _onClose = () => {
    this.props.closeModal();
  };

  render() {
    const { open = true, categories = [] } = this.props;
    const listItems = categories.map((category, index) => (
      <li key={index}>{category.name}</li>
    ));
    return (
      <CustomDialog
        open={open}
        disableBackdropClick={true}
        onConfirm={this._onClose}
        onClose={this._onClose}
        title={'Información registro de venta'}
        buttonText={`CONTINUAR`}
      >
        <div>
          <p>
            {
              'Venta válida solo para artículos que pertenezcan a las siguientes categorías:'
            }
          </p>
          <ul>{listItems}</ul>
        </div>
      </CustomDialog>
    );
  }
}

GnpGasApplianceInfoModal.propTypes = {
  open: PropTypes.bool,
  categories: PropTypes.array,
  closeModal: PropTypes.func
};
export default GnpGasApplianceInfoModal;
