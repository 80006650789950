import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import Typography from '@material-ui/core/Typography';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { doctypes } from '../../../constants/enums';
import ReCAPTCHA from 'react-google-recaptcha';
import ProgressButton from '../../shared/Buttons/ProgressButton';
import { validationSchema } from './validationSchema';
import _isEmpty from 'lodash/isEmpty';
import styles from './styles/styles';
import Button from '@material-ui/core/Button';
import AppNavBar from '../../shared/AppNavBar';

class AspiringSupplierSignUpPage extends Component {
  state = {
    recaptchaRef: React.createRef(),
    sitekey: process.env.SITE_KEY_CAPTCHA_INVISIBLE_V2
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.mainPaper}>
        <Grid container>
          <Grid item xs={12}>
            <AppNavBar />
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                names: '',
                lastNames: '',
                identificationType: '',
                identification: '',
                email: '',
                mobile: '',
                termsAndConditionsAccepted: false,
                isHuman: false
              }}
              validationSchema={validationSchema}
              onSubmit={this._submit}
              render={this._renderForm}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  _submit = params => {
    this.props.submit({ params });
  };

  _renderForm = ({
    values,
    handleSubmit,
    handleChange,
    touched,
    setFieldValue,
    handleBlur,
    errors
  }) => {
    const {
      loading,
      loadingRecaptcha,
      confirmToken,
      newAlert,
      classes
    } = this.props;
    return (
      <Grid container justify="center">
        <Grid container item justify="center" xs={6} spacing={16}>
          <Title classes={classes} />
          <Divisor />
          <PersonalData
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <LoginData
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <Divisor />
          <TermsAndConditions values={values} setFieldValue={setFieldValue} />
          <IsHumanValidator
            recaptchaRef={this.state.recaptchaRef}
            sitekey={this.state.sitekey}
            setFieldValue={setFieldValue}
            confirmToken={confirmToken}
            newAlert={newAlert}
          />
          <Footer
            errors={errors}
            touched={touched}
            loading={loading}
            loadingRecaptcha={loadingRecaptcha}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    );
  };
}

const Title = props => {
  const { classes } = props;
  return (
    <Grid container item xs={12} className={classes.title}>
      <Grid item xs={12}>
        <Typography variant="h4">Registro de aspirante a aliado</Typography>
      </Grid>
    </Grid>
  );
};

const PersonalData = props => {
  const { values, handleChange, handleBlur, touched, errors } = props;
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant="body1">Ingresa tus datos personales</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item justify="space-between" xs={12}>
          <Grid item xs={5}>
            <OutlinedTextField
              name="names"
              label="Nombres"
              value={values.names}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.names && errors.names}
              error={touched.names && !!errors.names}
            />
          </Grid>
          <Grid item xs={5}>
            <OutlinedTextField
              name="lastNames"
              label="Apellidos"
              value={values.lastNames}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.lastNames && errors.lastNames}
              error={touched.lastNames && !!errors.lastNames}
            />
          </Grid>
        </Grid>
        <Grid container item justify="space-between" xs={12}>
          <Grid item xs={5}>
            <SimpleSelect
              name="identificationType"
              label="Tipo de identificación"
              options={mapListToOptions(doctypes, {}, true)}
              value={values.identificationType}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={
                touched.identificationType && errors.identificationType
              }
              error={touched.identificationType && !!errors.identificationType}
            />
          </Grid>
          <Grid item xs={5}>
            <OutlinedTextField
              name="identification"
              type="number"
              label="Identificación"
              value={values.identification}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.identification && errors.identification}
              error={touched.identification && !!errors.identification}
            />
          </Grid>
          <Grid item xs={5}>
            <OutlinedTextField
              name="mobile"
              type="number"
              label="Número Celular"
              value={values.mobile}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.mobile && errors.mobile}
              error={touched.mobile && !!errors.mobile}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Divisor = () => {
  return (
    <Grid item xs={12}>
      <Divider />
    </Grid>
  );
};

const LoginData = props => {
  const { values, handleChange, handleBlur, touched, errors } = props;
  return (
    <Grid container item spacing={16}>
      <Grid item xs={12}>
        <Typography variant="body1">
          Ingresa tus datos de usuario para iniciar sesión
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OutlinedTextField
          name="email"
          label="Correo electrónico"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.email && errors.email}
          error={touched.email && !!errors.email}
        />
      </Grid>
      <Grid item xs={12}>
        <b>
          Recuerde que la contraseña asignada inicialmente será su número de
          identificación sin puntos ni letras
        </b>
      </Grid>
    </Grid>
  );
};

const TermsAndConditions = props => {
  const { values, setFieldValue } = props;
  return (
    <Grid container item justify="center" xs={12}>
      <Grid container item xs={1} justify="center">
        <Grid item xs={6}>
          <Checkbox
            checked={values.termsAndConditionsAccepted}
            onChange={() => {
              setFieldValue(
                'termsAndConditionsAccepted',
                !values.termsAndConditionsAccepted
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={11}>
        He leído y acepto los{' '}
        <a
          target="_blank"
          href="https://gascaribe.com/politica-de-tratamiento-de-la-informacion-y-datos-personales/"
        >
          términos y condiciones de uso, la política de privacidad
        </a>{' '}
        y autorizo el tratamiento de mis datos personales
      </Grid>
    </Grid>
  );
};

const IsHumanValidator = props => {
  const {
    recaptchaRef,
    sitekey,
    setFieldValue,
    confirmToken,
    newAlert
  } = props;
  return (
    <Grid container item justify="center" xs={12}>
      <Grid item xs={5}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={sitekey}
          render="explicit"
          onChange={async token => {
            const response = await confirmToken({ token });
            if (response.success) {
              setFieldValue('isHuman', true);
            } else {
              setFieldValue('isHuman', false);
              recaptchaRef.current.reset();
              newAlert(
                'error',
                'ERROR:',
                'No fue posible validar que eres un humano'
              );
            }
          }}
          onExpired={() => {
            setFieldValue('isHuman', false);
            recaptchaRef.current.reset();
          }}
          hl="es-419"
        />
      </Grid>
    </Grid>
  );
};

const Footer = props => {
  const { errors, touched, loading, loadingRecaptcha, handleSubmit } = props;
  const disabled =
    loading || loadingRecaptcha || !_isEmpty(errors) || _isEmpty(touched);
  const isSubmitting = loading || loadingRecaptcha;
  return (
    <Grid container item justify="space-between" xs={12}>
      <Grid item xs={2}>
        <Button
          onClick={() =>
            (window.location.href = 'https://www.brillagascaribe.com')
          }
        >
          Atrás
        </Button>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'right' }}>
        <ProgressButton
          disabled={disabled}
          variant="contained"
          color="secondary"
          isSubmitting={isSubmitting}
          onClick={handleSubmit}
        >
          Registrarme
        </ProgressButton>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(
  AspiringSupplierSignUpPage
);
