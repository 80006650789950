const styles = theme => ({
  disabled: {},
  label: {},
  root: {
    position: 'relative'
  },
  progressContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '2%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  progressContainerContained: {
    backgroundColor: theme.palette.primary.main
  },
  progressContainerContainedSecondary: {
    backgroundColor: theme.palette.secondary.main
  },
  progressContainerOutlined: {
    border: '1px solid rgba(219,73,69,0.5)',
    borderRadius: 2
  },
  progressContainerOutlinedSecondary: {
    border: '1px solid rgba(242,161,56,0.5)',
    borderRadius: 2
  },
  progressIcon: {
    color: 'rgb(242,161,56)',
    width: '24px !important',
    height: '24px !important'
  },
  progressIconContained: {
    color: '#FFFFFF'
  },
  noBorder: {
    border: '0 !important'
  },
  hideText: {
    opacity: 0
  }
});

export default styles;
