import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';

import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import SpacingStyles from '../../../../styles/helpers/spacing';

import OutlinedSelectChippedInput from '../../../shared/Inputs/OutlinedSelectChipped';
import TextFieldInput from '../../../shared/Inputs/TextField';
import DialogForm from '../../../shared/Modals/DialogForm';
// import { mapOptions } from '../TableMetadata';
import ProgressButton from '../../../shared/Buttons/ProgressButton';

import * as Yup from 'yup';

export class CancelInsuranceModal extends Component {
  _handleOnClose = () => {
    this.props.onClose();
  };

  _handleOnSave = async values => {
    const { onSave } = this.props;
    try {
      await onSave(values);
      return true;
    } catch {
      return false;
    }
  };

  render() {
    const { classes, isSubmitting } = this.props;

    const validationSchema = Yup.object().shape({
      causal: Yup.number().required('La Causa es requerida')
    });

    return (
      <Formik
        ref={ref => (this.formik = ref)}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          causal: '',
          observations: ''
        }}
        onSubmit={values => {
          this._handleOnSave(values);
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty
        }) => (
          <DialogForm
            open={true}
            onClose={handleReset}
            title={
              <div className={classes.titleContainer}>
                <p className={classnames(classes.headline6, classes.title)}>
                  Cancelar Servicio
                </p>
              </div>
            }
            footer={
              <Fragment>
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={!dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Enviar'}
                </ProgressButton>
              </Fragment>
            }
          >
            <Grid container spacing={16}>
              <GridItem xs={12}>
                <Field
                  name="causal"
                  onChange={handleChange}
                  render={({ field, form }) => {
                    return (
                      <OutlinedSelectChippedInput
                        value={values.cause}
                        field={field}
                        form={form}
                        label={'Causa*'}
                        withChip={false}
                        options={this.props.cancellationCausals}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="observations"
                  onChange={handleChange}
                  render={({ field, form }) => {
                    return (
                      <TextFieldInput
                        multiline
                        rows={4}
                        name="observations"
                        label="Observaciones"
                        value={values.observations}
                        onChange={handleChange}
                      />
                    );
                  }}
                />
              </GridItem>
            </Grid>
          </DialogForm>
        )}
      />
    );
  }
}

export default withStyles(
  combineStyles(LayoutStyles, TypographyStyles, ButtonStyles, SpacingStyles)
)(CancelInsuranceModal);
