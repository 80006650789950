import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { newAlert } from '../../../actions/alert_actions';
import Insurance from './Insurance';

import {
  setNextStep,
  toggleBaseErrorModal,
  persistInsurance,
  clearInsuranceStore
} from '../../../actions/insurance_actions';

import {
  resetStepper,
  setSteps,
  setOptionalSteps,
  persistStepper,
  setCurrentStep
} from '../../../actions/stepper_actions';

import {
  generateRoutes,
  getSteps,
  getOptionals,
  getRoutes
} from '../../../helpers/stepperFunctions';

function mapStateToProps(state) {
  return {
    routes: state.stepper.routes,
    process: state.stepper.process,
    steps: state.stepper.steps,
    insuranceNext: state.stepper.insuranceNext,
    activeStep: state.stepper.activeStep,
    nextStep: state.stepper.nextStep,
    currentUser: state.authentication.currentUser,
    loadingParameters: state.requestState.requestFetchGlobalParametersSend,
    server_errors: state.insurances.errors,
    baseErrorOpen: state.insurances.baseErrorOpen,
    baseErrorText: state.insurances.baseErrorText,
    baseErrorTitle: state.insurances.baseErrorTitle,
    baseErrorRoute: state.insurances.baseErrorRoute
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearInsuranceStore: bindActionCreators(clearInsuranceStore, dispatch),
    resetStepper: bindActionCreators(resetStepper, dispatch),
    setSteps: bindActionCreators(setSteps, dispatch),
    getSteps: bindActionCreators(getSteps, dispatch),
    persistStepper: bindActionCreators(persistStepper, dispatch),
    toggleBaseErrorModal: bindActionCreators(toggleBaseErrorModal, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    persistInsurance: bindActionCreators(persistInsurance, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Insurance);
