import {
  INSURANCES_SET,
  SET_INSURANCE_INFO,
  SET_INSURANCE_ERROR,
  SET_INSURANCE_CONTRACT_VALID,
  SET_INSURANCE_CLIENT_VALID,
  SET_NEXT_STEP,
  INSURANCE_TOGGLE_BASE_MODAL,
  STEP_LOADING,
  STEP_NOT_LOADING,
  SET_INSURANCE_DETAIL,
  SET_INSURANCE_CLIENT_DETAIL,
  SET_INSURANCE_SECONDINSURED_DETAIL,
  SET_INSURANCE_BENEFICIARIES_DETAIL,
  SET_INSURANCE_MOVEMENTS_DETAILS,
  SET_INSURANCE_CANCELLATION_CAUSALS,
  INSURANCE_PERSIST,
  CLEAR_INSURANCE_INFO,
  SET_INSURANCE_AFFILIATES_DETAIL
} from '../constants/action_constants';

import { newAlert, serverStatusError } from './alert_actions';
import { sendRequest, completeRequest } from './request_state';
// import AxiosService from '../services/axios_node_request';
import RequestService from '../services/request_service';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';

const CACHE_KEY = 'insurance';
const requestService = new RequestService('node');

export const persistInsurance = () => dispatch => {
  const cache = sessionStorage.getItem(CACHE_KEY) || null;
  if (cache) {
    const data = JSON.parse(cache);
    dispatch({ type: INSURANCE_PERSIST, data });
  }
};

export const cacheInsurance = state => {
  const { insurances, stepper } = state;
  sessionStorage.setItem(CACHE_KEY, JSON.stringify(insurances));
  sessionStorage.setItem('stepper', JSON.stringify(stepper));
};

export const clearCache = () => {
  sessionStorage.removeItem(CACHE_KEY);
};

function clearInsuranceInfo() {
  return {
    type: CLEAR_INSURANCE_INFO
  };
}

function setInsurances(items, pagi) {
  return {
    type: INSURANCES_SET,
    data: items,
    meta: pagi
  };
}

function setInsuranceDetail(data) {
  return {
    type: SET_INSURANCE_DETAIL,
    data: data
  };
}

function setClientDetail(data) {
  return {
    type: SET_INSURANCE_CLIENT_DETAIL,
    data: data
  };
}

function setAffiliatesDetail(data) {
  return {
    type: SET_INSURANCE_AFFILIATES_DETAIL,
    data: data
  };
}

function setSecondInsuredDetail(data) {
  return {
    type: SET_INSURANCE_SECONDINSURED_DETAIL,
    data: data
  };
}

function setBeneficiariesDetail(data) {
  return {
    type: SET_INSURANCE_BENEFICIARIES_DETAIL,
    data: data
  };
}

function setMovementsDetails(data) {
  return {
    type: SET_INSURANCE_MOVEMENTS_DETAILS,
    data: data
  };
}

function setInsuranceCancellationCausals(data) {
  return {
    type: SET_INSURANCE_CANCELLATION_CAUSALS,
    data: data
  };
}

function setInsuranceInfo(data) {
  return {
    type: SET_INSURANCE_INFO,
    data: data
  };
}

function setInsuranceError(errors) {
  return {
    type: SET_INSURANCE_ERROR,
    errors
  };
}

function setInsuranceContractValid(data) {
  return {
    type: SET_INSURANCE_CONTRACT_VALID,
    data: data
  };
}

function setInsuranceClientValid(data) {
  return {
    type: SET_INSURANCE_CLIENT_VALID,
    data: data
  };
}

export function setNextStep(process, nextStep) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_NEXT_STEP,
      process,
      nextStep,
      nextStepTitle: process
    });
    cacheInsurance(getState());
  };
}

export function toggleBaseErrorModal(open, text, title, route) {
  return {
    type: INSURANCE_TOGGLE_BASE_MODAL,
    open,
    text,
    title,
    route
  };
}

function stepLoading() {
  return {
    type: STEP_LOADING
  };
}

function stopStepLoading() {
  return {
    type: STEP_NOT_LOADING
  };
}

export const clearInsuranceStore = () => async dispatch => {
  dispatch(clearInsuranceInfo());
};

export const fetchInsurances = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/insurances?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get('insurance/index', params);
    dispatch(setInsurances(payload.data.list, payload.meta));
  } catch (err) {
    err.json().then(e => {
      dispatch(serverStatusError(e));
    });
  } finally {
    dispatch(completeRequest());
  }
};

export const fetchInsuranceInfo = id => async dispatch => {
  dispatch(sendRequest());
  try {
    const payload = await requestService.get(`insurance/${id}`);
    if (payload.data.valid) {
      dispatch(setInsuranceDetail(payload.data.insurance));
      dispatch(setClientDetail(payload.data.insuranceDetail));
      dispatch(setAffiliatesDetail(payload.data.insuranceDetail.affiliates || {}));
      dispatch(setMovementsDetails(payload.data.movements));
      dispatch(setBeneficiariesDetail(Object.values(payload.data.insuranceDetail.beneficiaries) || []));
      dispatch(setSecondInsuredDetail(payload.data.insuranceDetail.secondInsured || {}));
    }
  } catch (err) {
    dispatch(serverStatusError(err));
  } finally {
    dispatch(completeRequest());
  }
};

export const fetchCancellationCausals = () => async dispatch => {
  dispatch(sendRequest());
  try {
    const payload = await requestService.post('insurance/causals', {});
    if (payload.data.valid) {
      dispatch(setInsuranceCancellationCausals(payload.data.causals));
    }
  } catch (err) {
    err.json().then(e => {
      dispatch(serverStatusError(e));
    });
  } finally {
    dispatch(completeRequest());
  }
};

export const editInsurance = (params, id) => async dispatch => {
  dispatch(sendRequest());
  try {
    const payload = await requestService.put(`insurance/${id}/edit`, params);
    if (payload.data.valid) {
      dispatch(fetchInsuranceInfo(id));
    }
  } catch (err) {
    err.json().then(e => {
      dispatch(stopStepLoading());
      if (e.errors) {
        dispatch(setInsuranceError(e.errors));
        if (e.errors.base) {
          dispatch(toggleBaseErrorModal(true, e.errors.base, 'Servicios'));
        }
      } else {
        dispatch(
          newAlert(
            'error',
            'ERROR:',
            'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
          )
        );
      }
    });
  } finally {
    dispatch(completeRequest());
  }
};

export const cancelInsurance = (params, id) => async dispatch => {
  dispatch(sendRequest());
  try {
    const payload = await requestService.put(`insurance/${id}/cancel`, params);
    if (payload.data.valid) {
      dispatch(fetchInsuranceInfo(id));
    }
  } catch (err) {
    err.json().then(e => {
      dispatch(serverStatusError(e));
    });
  } finally {
    dispatch(completeRequest());
  }
};

export const validateInsuranceContract = (
  params,
  route,
  currentLocation,
  process
) => async dispatch => {
  dispatch(stepLoading());
  try {
    const payload = await requestService.post(
      'insurance/validateContract',
      params
    );
    if (payload.data.valid) {
      dispatch(setInsuranceInfo(params));
      dispatch(setNextStep(process));
      dispatch(setInsuranceContractValid(true));
      history.push({ pathname: route, state: { from: currentLocation } });
    }
    dispatch(stopStepLoading());
  } catch (err) {
    err.json().then(e => {
      dispatch(stopStepLoading());
      if (e.errors) {
        dispatch(setInsuranceError(e.errors));
        if (e.errors.base) {
          dispatch(toggleBaseErrorModal(true, e.errors.base, 'Servicios'));
        }
      } else {
        dispatch(
          newAlert(
            'error',
            'ERROR:',
            'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
          )
        );
      }
    });
  }
};

export const validateInsuranceClient = (
  params,
  route,
  currentLocation,
  process
) => async dispatch => {
  dispatch(stepLoading());
  try {
    const payload = await requestService.post(
      'insurance/validateClient',
      params
    );
    if (payload.data.valid) {
      dispatch(setInsuranceInfo(payload.data.clientInfo));
      dispatch(setNextStep(process));
      dispatch(setInsuranceClientValid(true));
      history.push({ pathname: route, state: { from: currentLocation } });
    }
    dispatch(stopStepLoading());
  } catch (err) {
    err.json().then(e => {
      dispatch(stopStepLoading());
      if (e.errors) {
        dispatch(setInsuranceError(e.errors));
        if (e.errors.base) {
          dispatch(toggleBaseErrorModal(true, e.errors.base, 'Servicios'));
        }
      } else {
        dispatch(
          newAlert(
            'error',
            'ERROR:',
            'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
          )
        );
      }
    });
  }
};

export const createInsurance = (params, process) => async dispatch => {
  dispatch(stepLoading());
  try {
    const payload = await requestService.post(
      'insurance/createInsurance',
      params
    );
    if (payload.data.valid) {
      dispatch(setInsuranceInfo(payload.data.clientInfo));
      dispatch(setNextStep(process));
      dispatch(setInsuranceClientValid(payload.data.valid));
      history.push(`/insurances/${payload.data.id}`);
    }
    payload.data.status &&
      dispatch(newAlert('success', 'success', payload.data.status));
    dispatch(stopStepLoading());
  } catch (err) {
    err.json().then(e => {
      dispatch(stopStepLoading());
      if (e.errors) {
        dispatch(setInsuranceError(e.errors));
        if (e.errors.base) {
          dispatch(toggleBaseErrorModal(true, e.errors.base, 'Servicios'));
        }
      } else {
        dispatch(
          newAlert(
            'error',
            'ERROR:',
            'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
          )
        );
      }
    });
  }
};
