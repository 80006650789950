import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  contract: Yup.number()
    .required('El campo es requerido')
    .min(1, 'Ingresa un contrato válido.'),
  identificationType: Yup.string().required('El campo es requerido'),
  identification: Yup.number().required('El campo es requerido'),
  birthdate: Yup.string()
    .required('El campo es requerido')
    .nullable()
});

export default validationSchema;
