import moment from 'moment-timezone';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
export const columns = [
  {
    title: 'Nombre',
    name: 'pretty_name'
  },
  {
    title: 'Rango fechas',
    name: 'pretty_date'
  },
  {
    title: 'Valor',
    name: 'pretty_value'
  },
  {
    title: 'Categoria',
    name: 'pretty_category_name'
  },
  {
    title: 'Ubicación Geografica',
    name: 'pretty_geographic_location_name'
  },
  {
    title: 'Estrato Social',
    name: 'pretty_contract_stratum_name'
  }
];

export const mapData = data =>
  data.map(datum => {
    const val = Number.parseInt(datum.value || '0', 10);
    return {
      ...datum,
      value: val,
      pretty_name: datum.name || '--',
      pretty_value:
        datum.option === 'V' ? `${_moneyFormat(datum.value)}` : `%${val}`,
      pretty_category_name: datum.category ? datum.category.name : '--',
      pretty_date: `${moment(datum.start_at)
        .locale('es')
        .format('DD MMM YYYY') || '--'} - ${moment(datum.end_at)
        .locale('es')
        .format('DD MMM YYYY') || '--'}`,
      pretty_contract_stratum_name: datum.contract_stratum ? datum.contract_stratum.name : '--',
      pretty_geographic_location_name: datum.geographic_location ? datum.geographic_location.description : '--',
      suppliers: datum.suppliers.length
      ? datum.suppliers.map(s => ({
          id: s.id,
          name: s.name
        }))
      : []
    };
  });
