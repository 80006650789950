import {
    STEP_LOADING,
    STEP_SUCCESS,
    STEP_ERROR,
    TOGGLE_BASE_MODAL,
    SET_NEXT_STEP,
} from '../constants/action_constants'
import {
    SET_EXCEPTION_CONFIGURATION_LIST_DATA,
    OPEN_EXCEPTION_CONFIGURATION_FORM_MODAL,
    CLOSE_EXCEPTION_CONFIGURATION_FORM_MODAL
} from '../constants/location_exception_configurations_constants';
import { sendRequest, completeRequest } from './request_state';
import { handleRequestError } from './session_actions';
import { serverStatusError, newAlert } from './alert_actions';
import { cacheFinancing } from './portal_financing_actions';
import { createSanitizer, updateSanitizer, locationCheckSanitizier } from '../helpers/sanitizers/locationExceptionConfigurationSanitizer';
import RequestService from '../services/request_service';
import snakecaseKeys from 'snakecase-keys';
import { history } from '../helpers/history';
import _get from 'lodash/get';


const requestService = new RequestService('node');

function stepLoading() {
    return {
        type: STEP_LOADING
    };
}

export function stepSuccess(data) {
    return (dispatch, getState) => {
        dispatch({
            type: STEP_SUCCESS,
            data
        });
        cacheFinancing(getState());
    };
}


export function setNextStep(process, nextStep) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_NEXT_STEP,
            process,
            nextStep,
            nextStepTitle: process
        });
        cacheFinancing(getState());
    };
}

function stepError(errors) {
    return {
        type: STEP_ERROR,
        errors
    };
}

export function toggleBaseErrorModal(open, text, title, route) {
    return {
        type: TOGGLE_BASE_MODAL,
        open,
        text,
        title,
        route
    };
}

export const fetchExceptionConfigurationList = params => (
    async dispatch => {
        dispatch(sendRequest('ExceptionConfigurationList'));
        try {
            const payload = await requestService.get(
                'locationException',
                params
            );
            dispatch(setExceptionConfigurationListData(payload));
        } catch (errors) {
            const e = await payload.json();
            if (e.errors) {
                if ('base' in e.errors) {
                    dispatch(newAlert('error', 'ERROR:', e.errors['base']));
                } else {
                    dispatch(errorOnRequest('ExceptionConfigurationList', e.errors));
                }
            } else {
                dispatch(serverStatusError(errors));
            }
        } finally {
            dispatch(completeRequest('ExceptionConfigurationList'));
        }
    }
);

export const createExceptionConfiguration = params => (
    async dispatch => {
        dispatch(sendRequest('ExceptionConfigurationCreate'));
        try {
            await requestService.post('locationException', createSanitizer(params.fields))
            dispatch(_closeExceptionConfigurationFormModal());
            await fetchExceptionConfigurationList({ page: 1, perPage: params.perPage })(dispatch);
        } catch (payload) {
            const e = await payload.json();
            if (e.errors) {
                if ('base' in e.errors) {
                    dispatch(newAlert('error', 'ERROR:', e.errors['base']));
                } else {
                    dispatch(errorOnRequest('ExceptionConfigurationCreate', e.errors));
                }
            } else {
                dispatch(serverStatusError(errors));
            }
        } finally {
            dispatch(completeRequest('ExceptionConfigurationCreate'));
        }
    }
);

export const updateExceptionConfiguration = params => (
    async dispatch => {
        dispatch(sendRequest('ExceptionConfigurationUpdate'));
        try {
            await requestService.put(`locationException/${params.fields.id}`, updateSanitizer(params.fields))
            dispatch(_closeExceptionConfigurationFormModal());
            await fetchExceptionConfigurationList({ page: params.page, perPage: params.perPage })(dispatch);
        } catch (payload) {
            const e = await payload.json();
            if (e.errors) {
                if ('base' in e.errors) {
                    dispatch(newAlert('error', 'ERROR:', e.errors['base']));
                } else {
                    dispatch(errorOnRequest('ExceptionConfigurationUpdate', e.errors));
                }
            } else {
                dispatch(serverStatusError(errors));
            }
        } finally {
            dispatch(completeRequest('ExceptionConfigurationUpdate'));
        }
    }
);

export const deleteExceptionConfiguration = params => (
    async dispatch => {
        dispatch(sendRequest('ExceptionConfigurationDelete'));
        try {
            await requestService.delete(`locationException/${params.id}`)
            dispatch(_closeExceptionConfigurationFormModal());
            await fetchExceptionConfigurationList({ page: params.page, perPage: params.perPage })(dispatch);
        } catch (errors) {
            const e = await payload.json();
            if (e.errors) {
                if ('base' in e.errors) {
                    dispatch(newAlert('error', 'ERROR:', e.errors['base']));
                } else {
                    dispatch(errorOnRequest('ExceptionConfigurationDelete', e.errors));
                }
            } else {
                dispatch(serverStatusError(errors));
            }
        } finally {
            dispatch(completeRequest('ExceptionConfigurationDelete'));
        }
    }
);

export function locationValidityCheck(params = {}, process){
   return dispatch => {
        dispatch(stepLoading());
        return requestService
            .post('locationException/locationCheck', { ...locationCheckSanitizier(params), process }, null)
            .then(response => {
                const data = response.data;
                if (!data.canSell) {
                    dispatch(stepError());
                    dispatch(
                        toggleBaseErrorModal(
                            true,
                            'El proveedor no está autorizado para realizar una venta en la ubicación del contrato.',
                            ''
                        )
                    );
                }
                return data.canSell;
            },
                error => {
                    error &&
                        error.json().then(e => {
                            handleRequestError({
                                beforeHandle: () => dispatch(stepError(e.errors)),
                                handleRequest: () => {
                                    _get(e, 'errors.base') &&
                                        dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
                                },
                                response: e
                            })(dispatch);
                        });
                }
            );
    }
}

export const openExceptionConfigurationFormModal = () => (
    dispatch => {
        dispatch(_openExceptionConfigurationFormModal());
    }
)

export const closeExceptionConfigurationFormModal = () => (
    dispatch => {
        dispatch(_closeExceptionConfigurationFormModal());
    }
)


const setExceptionConfigurationListData = payload => {
    return {
        type: SET_EXCEPTION_CONFIGURATION_LIST_DATA,
        data: payload.data.list,
        meta: payload.meta
    };
};

const _openExceptionConfigurationFormModal = () => {
    return {
        type: OPEN_EXCEPTION_CONFIGURATION_FORM_MODAL
    };
}

const _closeExceptionConfigurationFormModal = () => {
    return {
        type: CLOSE_EXCEPTION_CONFIGURATION_FORM_MODAL
    };
}