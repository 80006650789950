export const columns = [
  {
    title: 'Nombre',
    name: 'name'
  },
  {
    title: 'Usuario',
    name: 'username'
  },
  {
    title: 'Correo Eléctronico',
    name: 'email'
  },
  {
    title: 'Identificación',
    name: 'identification'
  },
  {
    title: 'Aliado',
    name: 'supplierName'
  },
  {
    title: 'Tiene lector biométrico',
    name: 'hasBiometricReader'
  },
  {
    title: 'Serial',
    name: 'serial'
  },
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      name: datum.firstName + ' ' + datum.lastName || '--',
      username: datum.username || '--',
      email: datum.email || '--',
      identification: datum.identification || '--',
      supplierName: datum.supplier ? datum.supplier.name : '--',
      hasBiometricReader: datum.hasBiometricReader ? 'SI' : 'NO',
      serial: datum.biometricReader ? datum.biometricReader.serial : '--'
    };
  });
