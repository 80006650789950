import {
  QUERY_MEGA_QUOTAS,
  OPEN_QUERY_MEGA_QUOTA_MODAL,
  CLOSE_QUERY_MEGA_QUOTA_MODAL,
  RESET_QUERY_MEGA_QUOTA_DATA
} from '../constants/query_mega_quota_constants';

const initialState = {
  megaQuotas: [],
  usedQuota: 0,
  availableQuota: 0,
  cosignerIsRequired: false,
  isQueryMegaQuotaModalOpen: false,
  hasSearched: false,
  scoringQuota: 0,
  isScoringUsed: 0,
};

export function queryMegaQuotas(state = initialState, action) {
  switch (action.type) {
    case QUERY_MEGA_QUOTAS:
      return {
        ...state,
        megaQuotas: action.data.megaQuotas,
        cosignerIsRequired: action.data.cosignerIsRequired,
        usedQuota: action.data.usedQuota,
        availableQuota: action.data.availableQuota,
        scoringQuota: action.data.scoringQuota,
        isScoringUsed: action.data.isScoringUsed,
        hasSearched: true
      };
    case OPEN_QUERY_MEGA_QUOTA_MODAL:
      return {
        ...state,
        isQueryMegaQuotaModalOpen: true
      };
    case CLOSE_QUERY_MEGA_QUOTA_MODAL:
      return initialState;
    case RESET_QUERY_MEGA_QUOTA_DATA:
      return {
        ...state,
        megaQuotas: [],
        usedQuota: 0,
        availableQuota: 0,
        hasSearched: false
      };
    default:
      return state;
  }
}
