import {
  QUERY_QUOTAS,
  OPEN_QUERY_QUOTA_MODAL,
  CLOSE_QUERY_QUOTA_MODAL,
  RESET_QUERY_DATA,
  CLIENT_IS_ROLLOVER
} from '../constants/query_quota_constants';

const initialState = {
  quotas: [],
  isQueryQuotaModalOpen: false,
  hasSearched: false,
  clientIsRollover: null
};

export function queryQuotas(state = initialState, action) {
  switch (action.type) {
    case QUERY_QUOTAS:
      return {
        ...state,
        quotas: action.data,
        hasSearched: true
      };
    case OPEN_QUERY_QUOTA_MODAL:
      return {
        ...state,
        isQueryQuotaModalOpen: true
      };
    case CLOSE_QUERY_QUOTA_MODAL:
      return {
        ...state,
        isQueryQuotaModalOpen: false
      };
    case RESET_QUERY_DATA:
      return {
        ...state,
        quotas: initialState.promissories,
        hasSearched: initialState.hasSearched
      };
    case CLIENT_IS_ROLLOVER:
      return {
        ...state,
        clientIsRollover: action.data
      };
    default:
      return state;
  }
}
