import React, { Fragment, Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import StepperButtons from '../../shared/StepperButtons';
import SegmentHeader from '../../shared/SegmentHeader';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import DateField from '../../shared/Inputs/DateField';
import { Formik, FieldArray, Field, getIn } from 'formik';
import { validationSchema } from './InsuranceClientValidations';
import { doctypes, relationships } from '../../../constants/enums';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { history } from '../../../helpers/history';
import OurDatePicker from '../../shared/CustomDatePicker';
import Masks from '../../shared/Inputs/Masks';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';
import uuid from 'uuid/v4';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class InsuranceClientValidation extends Component {
  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  getNewRecipient = () => {
    return {
      key: uuid(),
      name: '',
      relationship: '',
      age: ''
    };
  };

  initialValues = store => {
    return {
      firstName: store.firstName || '',
      lastName: store.lastName || '',
      identification: store.identification || '',
      typeOfIdentification: store.typeOfIdentification || '',
      birthDate: store.birthDate || null,
      expeditionDate: store.expeditionDate || null,
      email: store.email || '',
      mobile: store.mobile || '',
      type: store.type || null,
      affiliates: store.affiliates || []
    };
  };

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
  }

  toCamelCase(key, value) {
    if (value && typeof value === 'object') {
      for (const k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }

  onSubmit = values => {
    const { process, routes } = this.props;

    this.props.validateInsuranceClient(
      values,
      routes[this.nextStep],
      this.props.location.pathname,
      process
    );
  };

  render() {
    const { classes, type, insuranceStore, serverErrors = {} } = this.props;
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            ref={ref => (this.formik = ref)}
            enableReinitialize={true}
            initialValues={this.initialValues(insuranceStore)}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            // eslint-disable-next-line complexity
            render={({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
              setFieldValue
            }) => (
              <form autoComplete="off" className={classes.form}>
                <SegmentHeader className={classes.segmentHeader}>
                  INFORMACIÓN DE CLIENTE
                </SegmentHeader>
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item sm={6} xs={12}>
                    <OutlinedTextField
                      label="Primer nombre"
                      name="firstName"
                      type={'text'}
                      error={
                        (touched.firstName && !!errors.firstName) ||
                        (serverErrors && !!serverErrors.firstName)
                      }
                      helperText={
                        (touched.firstName && errors.firstName) ||
                        (serverErrors && serverErrors.firstName)
                      }
                      value={values.firstName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OutlinedTextField
                      label="Primer apellido"
                      name="lastName"
                      type={'text'}
                      error={
                        (touched.lastName && !!errors.lastName) ||
                        (serverErrors && !!serverErrors.lastName)
                      }
                      helperText={
                        (touched.lastName && errors.lastName) ||
                        (serverErrors && serverErrors.lastName)
                      }
                      value={values.lastName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} className={classes.IdField}>
                    <SimpleSelect
                      allowMargin={false}
                      value={values.typeOfIdentification}
                      onChange={handleChange}
                      name="typeOfIdentification"
                      label="Tipo de identificación"
                      error={
                        (touched.typeOfIdentification &&
                          !!errors.typeOfIdentification) ||
                        (serverErrors && !!serverErrors.typeOfIdentification)
                      }
                      helperText={
                        (touched.typeOfIdentification &&
                          errors.typeOfIdentification) ||
                        (serverErrors && serverErrors.typeOfIdentification)
                      }
                      options={mapListToOptions(
                        doctypes,
                        { withId: false },
                        true
                      )}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OutlinedTextField
                      label="Número de identificación"
                      name="identification"
                      type={'number'}
                      error={
                        (touched.identification && !!errors.identification) ||
                        (serverErrors && !!serverErrors.identification)
                      }
                      helperText={
                        (touched.identification && errors.identification) ||
                        (serverErrors && serverErrors.identification)
                      }
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 15);
                      }}
                      value={values.identification}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OurDatePicker
                      name={'birthDate'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="Fecha inválida"
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha de nacimiento"
                      value={values.birthDate}
                      onChange={e => {
                        setFieldValue('birthDate', e);
                      }}
                      error={
                        (touched.birthDate && !!errors.birthDate) ||
                        (serverErrors && serverErrors.birthDate)
                      }
                      helperText={
                        (touched.birthDate && errors.birthDate) ||
                        (serverErrors && serverErrors.debtor_birthDate)
                      }
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OurDatePicker
                      name={'expeditionDate'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="Fecha inválida"
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha de expedición"
                      value={values.expeditionDate}
                      onChange={e => {
                        setFieldValue('expeditionDate', e);
                      }}
                      error={
                        (touched.expeditionDate && !!errors.expeditionDate) ||
                        (serverErrors && serverErrors.expeditionDate)
                      }
                      helperText={
                        (touched.expeditionDate && errors.expeditionDate) ||
                        (serverErrors && serverErrors.expeditionDate)
                      }
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OutlinedTextField
                      label="Correo electrónico"
                      name="email"
                      type={'email'}
                      error={
                        (touched.email && !!errors.email) ||
                        (serverErrors && !!serverErrors.email)
                      }
                      helperText={
                        (touched.email && errors.email) ||
                        (serverErrors && serverErrors.email)
                      }
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OutlinedTextField
                      label="Número de celular"
                      name="mobile"
                      type={'number'}
                      error={
                        (touched.mobile && !!errors.mobile) ||
                        (serverErrors && !!serverErrors.mobile)
                      }
                      helperText={
                        (touched.mobile && errors.mobile) ||
                        (serverErrors && serverErrors.mobile)
                      }
                      value={values.mobile}
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12} alignItems="flex-start">
                    <FieldArray
                      name="affiliates"
                      render={arrayHelpers => (
                        <Fragment>
                          <div className={classes.addArticleBar}>
                            <PrimaryButton
                              onClick={() =>
                                arrayHelpers.push(this.getNewRecipient())
                              }
                              className={classes.addArticleButton}
                            >
                              Agregar afiliado
                            </PrimaryButton>
                          </div>
                          {values.affiliates.map((item, idx) => (
                            <Grid
                              container
                              classes={{
                                container:
                                  idx == 0
                                    ? classes.articleItemContainerShade
                                    : null
                              }}
                              key={item.key}
                              spacing={24}
                              alignItems="center"
                            >
                              <Grid
                                classes={{ item: classes.articleItem }}
                                item
                                xs={2}
                                sm={5}
                              >
                                <div className={classes.articleFieldMargin}>
                                  <OutlinedTextField
                                    name={`affiliates.${idx}.name`}
                                    label="Nombre de afiliado"
                                    value={item.name}
                                    onChange={handleChange}
                                    helperText={
                                      getIn(errors, `affiliates.${idx}.name`) ||
                                      ' '
                                    }
                                    error={getIn(
                                      errors,
                                      `affiliates.${idx}.name`
                                    )}
                                  />
                                </div>
                              </Grid>
                              <Grid
                                classes={{ item: classes.articleItem }}
                                item
                                xs={2}
                                sm={4}
                              >
                                <div className={classes.articleFieldMargin}>
                                  <SimpleSelect
                                    allowMargin={true}
                                    value={item.relationship}
                                    onChange={handleChange}
                                    name={`affiliates.${idx}.relationship`}
                                    label="Parentesco"
                                    helperText={
                                      getIn(
                                        errors,
                                        `affiliates.${idx}.relationship`
                                      ) || ' '
                                    }
                                    error={getIn(
                                      errors,
                                      `affiliates.${idx}.relationship`
                                    )}
                                    options={mapListToOptions(
                                      relationships,
                                      { withId: false },
                                      true
                                    )}
                                  />
                                </div>
                              </Grid>
                              <Grid
                                classes={{ item: classes.articleItem }}
                                item
                                xs={1}
                                sm={2}
                              >
                                <div className={classes.articleFieldMargin}>
                                  <OutlinedTextField
                                    name={`affiliates.${idx}.age`}
                                    label="Edad"
                                    value={item.age}
                                    type={'number'}
                                    onChange={handleChange}
                                    helperText={
                                      getIn(errors, `affiliates.${idx}.age`) ||
                                      ' '
                                    }
                                    error={getIn(
                                      errors,
                                      `affiliates.${idx}.age`
                                    )}
                                    onInput={e => {
                                      e.target.value = Math.max(
                                        0,
                                        parseInt(e.target.value)
                                      )
                                        .toString()
                                        .slice(0, 3);
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid
                                classes={{ item: classes.iconArticleItem }}
                                item
                                xs={1}
                                sm={1}
                              >
                                <div className={classes.articleFieldMargin}>
                                  <IconButton
                                    onClick={() => arrayHelpers.remove(idx)}
                                    classes={{ root: classes.iconButton }}
                                  >
                                    <CloseIcon
                                      classes={{ root: classes.icon }}
                                    />
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                          ))}
                        </Fragment>
                      )}
                    />
                  </Grid>
                </Grid>
                <div className={classes.spacer} />
                <StepperButtons
                  onSubmit={handleSubmit}
                  process={'insurance'}
                  goBack={() => history.go(-1)}
                />
              </form>
            )}
          />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {},
  infoFieldGrid: {
    alignItems: 'flex-end',
    marginBottom: 5 * theme.spacing.unit
  },
  buttonsGrid: {
    marginBottom: 2 * theme.spacing.unit
  },
  form: {
    paddingTop: 30
  },
  actionBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  button: {
    alignSelf: 'flex-end',
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  bigButton: {
    width: '100%',
    fontSize: '1rem',
    color: '#db4945'
  },
  observations: {
    marginTop: 5 * theme.spacing.unit
  },
  IdField: {
    paddingTop: '28px !important'
  }
});

export default withStyles(styles)(InsuranceClientValidation);
