import _get from 'lodash/get';

export const columns = [
  {
    title: 'ID',
    name: 'id'
  },
  {
    title: 'Plan',
    name: 'plan'
  },
  {
    title: 'Categoría',
    name: 'category'
  },
  {
    title: 'Tipo Contrato',
    name: 'contractType'
  },
  {
    title: 'Ubicación',
    name: 'location'
  },
  {
    title: 'Canal Venta',
    name: 'saleChannel'
  },
  {
    title: 'Estrato',
    name: 'stratum'
  }
];

export const mapData = data =>
  data.map(datum => {
    const location =
      _get(datum, 'geographicLocation.id', null) === -1
        ? 'NINGUNO'
        : _get(datum, 'geographicLocation.description', 'TODOS');

    const contractType =
      _get(datum, 'contractType.id', null) === -1
        ? 'NINGUNO'
        : _get(datum, 'contractType.name', 'TODOS');

    return {
      ...datum,
      id: datum.id,
      plan: _get(datum, 'financialPlan.description', 'TODOS'),
      planID: _get(datum, 'financialPlan.id', null),
      category: _get(datum, 'category.name', 'TODOS'),
      categoryID: _get(datum, 'category.id', null),
      contractType,
      contractTypeID: _get(datum, 'contractType.id', null),
      location,
      locationID: _get(datum, 'geographicLocation.id', null),
      saleChannel: _get(datum, 'saleChannel.name', 'TODOS'),
      saleChannelID: _get(datum, 'saleChannel.id', null),
      stratum: _get(datum, 'contractStratum.name', 'TODOS'),
      stratumID: _get(datum, 'contractStratum.id', null)
    };
  });
