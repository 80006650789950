const styles = theme => ({
  title: {
    fontFamily: theme.typography.font.second,
    fontSize: '34.54px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '41px',
  },
  titleContainer: {
    marginBottom: 20,
  },
  saveModalContent: {
    minWidth: 500,
  },
  categoriesSubtitle: {
    marginTop: 20
  }
});
export default styles;