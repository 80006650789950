import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _isFunction from 'lodash/isFunction';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {},
  header: {
    position: 'relative'
  },
  headerSpan: {
    height: 20,
    width: '100%',
    marginTop: 15
  },
  filterHeader: {
    position: 'relative',
    '@media (min-width:900px)': {
      display: 'none'
    }
  },
  bodyRoot: {
    marginTop: theme.spacing.unit * 2
  },
  body: {},
  expandButton: {
    color: 'rgba(12,31,44,0.87)',
    position: 'absolute',
    top: '-8px',
    right: '-12px',
    padding: theme.spacing.unit,
    margin: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
});

export class CollapseWrapper extends Component {
  static propTypes = {
    header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    expanded: PropTypes.bool,
    body: PropTypes.node.isRequired,
    filter: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: props.filter
        ? window.innerWidth >= 900
        : props.expanded || false,
      filter: props.filter || false
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }
  _handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes, header, body } = this.props;

    return (
      <div className={classes.root}>
        <div
          className={
            this.state.filter && this.state.expanded
              ? classes.filterHeader
              : classes.header
          }
        >
          {header ? (
            _isFunction(header) ? (
              header({ ...this.props, ...this.state })
            ) : (
              header
            )
          ) : (
            <div className={classes.headerSpan} />
          )}
          <IconButton
            onClick={this._handleExpandClick}
            className={classnames(classes.expandButton, {
              [classes.expandOpen]: this.state.expanded
            })}
            aria-expanded={this.state.expanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Collapse
          in={this.state.expanded}
          timeout="auto"
          unmountOnExit
          className={classnames(classes.bodyRoot, classes.body)}
        >
          {body}
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(CollapseWrapper);
