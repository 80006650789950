import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment'; // Registered
import NotInterestedIcon from '@material-ui/icons/NotInterested'; // Cancelled
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning'; // EXCEPTION
import SpacingStyles from '../../../../styles/helpers/spacing';
import TypographyStyles from '../../../../styles/typography';
import combineStyles from '../../../../helpers/combineStyles';
import classnames from 'classnames';
import moment from 'moment-timezone';

import { STATUSES } from '../Status/constants';

import _get from 'lodash/get';

const styles = theme => ({
  iconContainer: {
    position: 'absolute',
    left: 0,
    top: -8,
    width: '32px',
    height: '32px',
    backgroundColor: 'rgba(242,161,56,0.9)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconRoot: {
    color: ['#FFF', '!important'],
    fontSize: '18px'
  },
  activityDetail: {
    position: 'relative',
    display: 'inline-block',
    alignSelf: 'stretch'
  },
  activityName: {
    marginTop: [theme.spacing.unit * 1.5, '!important'],
    marginBottom: [theme.spacing.unit * 0.625, '!important']
  },

  itemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: theme.spacing.unit * 5,
    paddingLeft: theme.spacing.unit * 6,
    position: 'relative'
  },
  verticalLine: {
    width: 1,
    height: '100%',
    borderRight: '1px dashed rgba(12,31,44,0.12);',
    position: 'absolute',
    left: 16
  }
});

export class Item extends PureComponent {
  static propTypes = {
    activity: PropTypes.object.isRequired
  };

  render() {
    const { classes, activity } = this.props;

    const ICONS = {
      [STATUSES.registered]: AssignmentIcon,
      [STATUSES.cancelled]: NotInterestedIcon,
      [STATUSES.modified]: EditIcon,
      [STATUSES.exception]: WarningIcon
    };

    const label = STATUSES[activity.title];
    const Icon = ICONS[label] || WarningIcon;
    const causal = activity.causal;

    return (
      <div className={classes.itemContainer}>
        <div className={classes.verticalLine} />
        <div className={classes.iconContainer}>
          <Icon color="primary" classes={{ root: classes.iconRoot }} />
        </div>
        <div className={classes.activityDetail}>
          <h4
            className={classnames(
              classes.noMargin,
              classes.caption,
              classes.boldWeight
            )}
          >
            {`${label} ${causal ? `- ${causal}` : ''}`}
          </h4>

          <h5 className={classnames(classes.subtitle3, classes.activityName)}>
            {moment(activity.date)
              .locale('es')
              .format('DD MMM YYYY [a las] h:mm A')}
          </h5>
          {activity.user && <p className={classes.body2}>{activity.user}</p>}
        </div>
      </div>
    );
  }
}

export default withStyles(
  combineStyles(styles, TypographyStyles, SpacingStyles)
)(Item);
