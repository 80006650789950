import React, { PureComponent, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';
import { compose } from 'redux';
import List from '@material-ui/core/List';
import ContactListItem from '../../shared/ListItem/ContactListItem';
import Divider from '@material-ui/core/Divider';
import TypographyStyles from '../../../styles/typography';
import { history } from '../../../helpers/history';

class PointsOfSaleList extends PureComponent {
  _handleClick = id => {
    if (this.props.canViewPOS) {
      history.push(`/points-of-sale/${id}`);
    }
  }

  render() {
    const { classes, pointsOfSale } = this.props;

    return (
      <List classes={{ root: classes.list }}>
        {pointsOfSale.map((pos, i) => {
          const address = pos.address !== '-' && pos.address;
          return (
            <Fragment key={pos.id}>
              <ContactListItem
                heading={`${pos.name}`.replace(
                  /_/,
                  ' '
                )}
                onClick={() => this._handleClick(pos.id)}
              >
                <div className={classes.extraInfo}>
                  <p>{address}</p>
                  <p>{pos.email}</p>
                  <p>{pos.phone}</p>
                  <p>Estado: {pos.active ? `ACTIVO` : `INACTIVO`}</p>
                </div>
              </ContactListItem>
              {i < pointsOfSale.length - 1 && (
                <Divider component="li" />
              )}
            </Fragment>
          );
        })}
      </List>
    );
  }
}

const styles = theme => ({
  list: {
    width: '100%',
    padding: '0 !important',
    backgroundColor: theme.palette.background.paper
  },
  extraInfo: {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      margin: 0
    }
  }
});

export default compose(
  withStyles(combineStyles(styles, TypographyStyles), { withTheme: true })
)(PointsOfSaleList);
