import { geographicLocation } from "../../../reducers/geographic_location_reducer";

export const columns = [
  {
    title: 'Punto de venta',
    name: 'pointOfSaleName'
  },
  {
    title: 'Categoría',
    name: 'categoryName'
  },
  {
    title: 'zona geográfica',
    name: 'geographicLocationName'
  }
];

export const mapData = data => (
  data.map(datum => {
    return {
      id: datum.id,
      pointOfSaleName: datum.point_of_sale.name,
      categoryName: datum.category.name,
      geographicLocationName: datum.geographic_location.description,
      pointOfSaleId: datum.point_of_sale.id,
      categoryId: datum.category.id,
      geographicLocationId: datum.geographic_location.id
    };
  })
);