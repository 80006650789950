import { STATUS_ARRAY } from '../constants/system_status_constants'
const initialState = {
  statuses: []
}

export function systemStatus(state= initialState, action) {
  if(action.type===STATUS_ARRAY) {
    return {
      ...state,
      statuses: action.data
    };
  } else {
    return state;
  }
}