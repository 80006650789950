import SupplierList from './SupplierList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchSuppliers } from '../../../actions/suppliers_actions';
import { fetchCorporationNames } from '../../../actions/corporations_actions';

function mapStateToProps(state) {
  return {
    suppliers: state.suppliers.suppliers,
    meta: state.suppliers.meta,
    corporations: state.corporations.corporationNames,
    requestSend:
      state.requestState.requestSuppliersSend ||
      state.requestState.requestCorporationNamesSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSuppliers: bindActionCreators(fetchSuppliers, dispatch),
    fetchCorporationNames: bindActionCreators(fetchCorporationNames, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SupplierList);
