import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ApproveSale from './ApproveSale';
import { approveSale, toggleSaleAction } from '../../../actions/sales_actions';

function mapStateToProps(state) {
  return {
    open: state.sales[`actionApprove-SaleOpen`],
    requestSend: state.requestState[`requestApprove-SaleSend`]
  };
}

function mapDispatchToProps(dispatch) {
  return {
    approveSale: bindActionCreators(approveSale, dispatch),
    toggleSaleAction: bindActionCreators(toggleSaleAction, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveSale);
