import React, { PureComponent } from 'react';
import combineStyles from '../../../../helpers/combineStyles';
import TypographyStyles from '../../../../styles/typography';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { PROMISSORY_STATUSES } from '../../../../constants/enums';

const styles = theme => ({
  root: {},
  statusLabel: {
    position: 'absolute',
    left: 100,
    color: 'rgba(12,31,44,0.6)',
    backgroundColor: 'rgba(12,31,44,0.1)',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    borderRadius: '18px',
    fontFamily: theme.typography.font.main,
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '20px'
  },
  [PROMISSORY_STATUSES.approved]: {
    backgroundColor: 'rgba(0,195,150,0.08)',
    color: '#00C396'
  },
  [PROMISSORY_STATUSES.rejected]: {
    backgroundColor: 'rgba(219,73,69,0.08)',
    color: theme.palette.primary.main
  },
  [PROMISSORY_STATUSES.canceled]: {
    backgroundColor: 'rgba(219,73,69,0.08)',
    color: theme.palette.primary.main
  },
  [PROMISSORY_STATUSES.exeption]: {
    backgroundColor: 'rgba(219,73,69,0.08)',
    color: theme.palette.primary.main
  }
});

export class StatusLabel extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  };

  render() {
    const { label, classes, type } = this.props;
    const textColor = _get(classes, type, {});
    return (
      <span
        className={classnames(classes.statusLabel, textColor, classes.root)}
      >
        {label}
      </span>
    );
  }
}

export default withStyles(combineStyles(styles, TypographyStyles))(StatusLabel);
