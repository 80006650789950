import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileUploader from './FileUploader';
import { SUPPLIER_PROPOSAL_DOCUMENT_TYPES } from '../../../constants/enums'
import ProgressButton from '../../shared/Buttons/ProgressButton';
import Divider from '@material-ui/core/Divider';

const SupplierDataStep = props => {
  const {
    classes,
    allowFileReplace,
    fileMaxSize,
    fileSizeUnit,
    supplierFormDocExtensions,
    extensions,
    newAlert,
    setFieldValue,
    values,
    updateDocument,
    loadingDocumentIds,
    onViewOtherComments,
    onAddComment,
    canAcceptOrRejectFile,
    showCommentSection,
    canCreateDocuments,
    onAddDocument,
    allowAddComment,
    handleFileChange,
    handleFileError,
    handleFileAcceptanceChange,
    getDocByDocType,
    proposalRejected,
    fetchFileBase64,
  } = props;
  const legalRepresentativeIdentificationDoc = getDocByDocType(
    SUPPLIER_PROPOSAL_DOCUMENT_TYPES.LegalRepresentativeIdentification,
    values.documents
  );
  const rutDoc = getDocByDocType(
    SUPPLIER_PROPOSAL_DOCUMENT_TYPES.Rut,
    values.documents
  );
  const chamberOfCommerceDoc = getDocByDocType(
    SUPPLIER_PROPOSAL_DOCUMENT_TYPES.ChamberOfCommerce,
    values.documents
  );
  const otherDocuments = _getOtherDocuments(values.documents);
  const showAddDocumentSection = (
    canCreateDocuments &&
    !proposalRejected &&
    !values.configurationTemplatesSentAt
  );
  return (
    <Grid container item className={classes.supplierDataStepContainer}>
      <Grid container item>
        <Grid item xs={12}>
          <Typography variant='h3'>Registro de aliado / proveedor</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            Tu propuesta ha sido aceptada. Adjunta los siguientes documentos en formato PDF para continuar con el proceso.
          </Typography>
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12}>
        {
          showAddDocumentSection && (
            <Grid container item justify='center'>
              <Grid item xs={12} className={classes.dividerContainer}>
                <Divider />
              </Grid>
              <Grid container item xs={8} className={classes.addDocumentButtonContainer}>
                <Grid item xs={6}>
                  <Typography variant='body1'>
                    ¿Consideras que hace falta algún documento por adjuntar?
                  </Typography>
                </Grid>
                <Grid container item justify='flex-end' xs={6}>
                  <ProgressButton
                    variant="contained"
                    color="secondary"
                    onClick={onAddDocument(SUPPLIER_PROPOSAL_DOCUMENT_TYPES.AdditionalInSupplierDataStep)}
                  >
                    Agregar documento
                  </ProgressButton>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.dividerContainer}>
                <Divider />
              </Grid>
            </Grid>
          )
        }
        <Grid container item xs={8} className={classes.supplierDataStepFileUploaderContainer}>
          <FileUploader
            allowFileReplace={allowFileReplace(legalRepresentativeIdentificationDoc)}
            fileName={legalRepresentativeIdentificationDoc.originalFileName}
            fetchFileBase64={fetchFileBase64({document: legalRepresentativeIdentificationDoc})}
            label={legalRepresentativeIdentificationDoc.name}
            description={legalRepresentativeIdentificationDoc.description}
            maxSize={fileMaxSize}
            sizeUnit={fileSizeUnit}
            extensions={extensions}
            onChange={
              handleFileChange({
                values,
                document: legalRepresentativeIdentificationDoc,
                newAlert,
                setFieldValue,
                updateDocument
              })
            }
            onError={handleFileError({ newAlert, fileMaxSize, fileSizeUnit })}
            submitting={loadingDocumentIds.includes(legalRepresentativeIdentificationDoc.id)}
            comments={legalRepresentativeIdentificationDoc.comments}
            onViewOtherComments={
              comments => onViewOtherComments(comments)
            }
            onAddComment={
              onAddComment({
                documentId: legalRepresentativeIdentificationDoc.id,
                setFieldValue,
                values
              })
            }
            canAcceptOrRejectFile={canAcceptOrRejectFile}
            showCommentSection={showCommentSection(legalRepresentativeIdentificationDoc)}
            onFileAcceptanceChange={
              handleFileAcceptanceChange({
                documentId: legalRepresentativeIdentificationDoc.id,
                setFieldValue,
                values,
              })
            }
            fileAccepted={legalRepresentativeIdentificationDoc.accepted}
            allowAddComment={allowAddComment(legalRepresentativeIdentificationDoc)}
          />
        </Grid>
        <Grid container item xs={8} className={classes.supplierDataStepFileUploaderContainer}>
          <FileUploader
            allowFileReplace={allowFileReplace(rutDoc)}
            fileName={rutDoc.originalFileName}
            fetchFileBase64={fetchFileBase64({document: rutDoc})}
            label={rutDoc.name}
            description={rutDoc.description}
            maxSize={fileMaxSize}
            sizeUnit={fileSizeUnit}
            extensions={extensions}
            onChange={
              handleFileChange({
                values,
                document: rutDoc,
                newAlert,
                setFieldValue,
                updateDocument
              })
            }
            onError={handleFileError({ newAlert, fileMaxSize, fileSizeUnit })}
            submitting={loadingDocumentIds.includes(rutDoc.id)}
            comments={rutDoc.comments}
            onViewOtherComments={
              comments => onViewOtherComments(comments)
            }
            onAddComment={
              onAddComment({
                documentId: rutDoc.id,
                setFieldValue,
                values
              })
            }
            canAcceptOrRejectFile={canAcceptOrRejectFile}
            showCommentSection={showCommentSection(rutDoc)}
            onFileAcceptanceChange={
              handleFileAcceptanceChange({
                documentId: rutDoc.id,
                setFieldValue,
                values,
              })
            }
            fileAccepted={rutDoc.accepted}
            allowAddComment={allowAddComment(rutDoc)}
          />
        </Grid>
        <Grid container item xs={8} className={classes.supplierDataStepFileUploaderContainer}>
          <FileUploader
            allowFileReplace={allowFileReplace(chamberOfCommerceDoc)}
            fileName={chamberOfCommerceDoc.originalFileName}
            fetchFileBase64={fetchFileBase64({document: chamberOfCommerceDoc})}
            label={chamberOfCommerceDoc.name}
            description={chamberOfCommerceDoc.description}
            maxSize={fileMaxSize}
            sizeUnit={fileSizeUnit}
            extensions={extensions}
            onChange={
              handleFileChange({
                values,
                document: chamberOfCommerceDoc,
                newAlert,
                setFieldValue,
                updateDocument
              })
            }
            onError={handleFileError({ newAlert, fileMaxSize, fileSizeUnit })}
            submitting={loadingDocumentIds.includes(chamberOfCommerceDoc.id)}
            comments={chamberOfCommerceDoc.comments}
            onViewOtherComments={
              comments => onViewOtherComments(comments)
            }
            onAddComment={
              onAddComment({
                documentId: chamberOfCommerceDoc.id,
                setFieldValue,
                values
              })
            }
            canAcceptOrRejectFile={canAcceptOrRejectFile}
            showCommentSection={showCommentSection(chamberOfCommerceDoc)}
            onFileAcceptanceChange={
              handleFileAcceptanceChange({
                documentId: chamberOfCommerceDoc.id,
                setFieldValue,
                values,
              })
            }
            fileAccepted={chamberOfCommerceDoc.accepted}
            allowAddComment={allowAddComment(chamberOfCommerceDoc)}
          />
        </Grid>
        {
          otherDocuments.length > 0 && (
            <Grid container item justify='center'>
              <Grid item xs={12} className={classes.dividerContainer}>
                <Divider />
              </Grid>
              <Grid container item xs={12}>
                <Typography variant='h5'>
                  Documentos adicionales
                </Typography>
              </Grid>
              {
                otherDocuments.map(
                  document => {
                    return (
                      <Grid key={document.id} container item xs={8} className={classes.companySalesStepFileUploaderContainer}>
                        <FileUploader
                          allowFileReplace={allowFileReplace(document)}
                          fileName={document.originalFileName}
                          fetchFileBase64={fetchFileBase64({document: document})}
                          label={document.name}
                          description={document.description}
                          maxSize={fileMaxSize}
                          sizeUnit={fileSizeUnit}
                          extensions={extensions}
                          onChange={
                            handleFileChange({
                              values,
                              document,
                              newAlert,
                              setFieldValue,
                              updateDocument
                            })
                          }
                          onError={handleFileError({ newAlert, fileMaxSize, fileSizeUnit })}
                          submitting={loadingDocumentIds.includes(document.id)}
                          comments={document.comments}
                          onViewOtherComments={
                            comments => onViewOtherComments(comments)
                          }
                          onAddComment={
                            onAddComment({
                              documentId: document.id,
                              setFieldValue,
                              values
                            })
                          }
                          canAcceptOrRejectFile={canAcceptOrRejectFile}
                          showCommentSection={showCommentSection(document)}
                          onFileAcceptanceChange={
                            handleFileAcceptanceChange({
                              documentId: document.id,
                              setFieldValue,
                              values,
                            })
                          }
                          fileAccepted={document.accepted}
                          allowAddComment={allowAddComment(document)}
                        />
                      </Grid>
                    );
                  }
                )
              }
            </Grid>
          )
        }
      </Grid>
    </Grid>
  );
};

const _getOtherDocuments = documents => {
  return documents.filter(
    doc => doc.docType === SUPPLIER_PROPOSAL_DOCUMENT_TYPES.AdditionalInSupplierDataStep
  );
}

export default SupplierDataStep;