import React from 'react';
import NumberFormat from 'react-number-format';

const AVAILABLE_MASKS = {
  phone: '(###) ###-####',
  zipCode: '#####',
  date: '##/##/####',
  yearDays: '###',
  percentage: '##'
};

export const NumberMaskGenerator = type => {
  const NumberMask = props => {
    const { inputRef, value, ...other } = props;
    if (type === 'currency') {
      return (
        <NumberFormat
          {...other}
          value={value}
          getInputRef={inputRef}
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
        />
      );
    }

    if (type === 'amount') {
      return (
        <NumberFormat
          {...other}
          value={value}
          getInputRef={inputRef}
          allowNegative={false}
        />
      );
    }

    return (
      <NumberFormat
        {...other}
        value={value}
        getInputRef={inputRef}
        format={AVAILABLE_MASKS[type]}
        mask=""
      />
    );
  };

  return NumberMask;
};

export const format = (value, pattern) => {
  if (!value) {
    return '';
  }
  let index = 0;
  return AVAILABLE_MASKS[pattern].replace(/#/g, _ => value.toString()[index++]);
};

export const formatBack = (value, type) => {
  if (!value) return '';
  let formatback = value + "";
  if (type == 'currency') {
    formatback = formatback.replace(/\./g, '');
    formatback = formatback.replace(/,/g, '.');
  }
  return formatback;
};

const Masks = {
  phone: NumberMaskGenerator('phone'),
  zipCode: NumberMaskGenerator('zipCode'),
  date: NumberMaskGenerator('date'),
  yearDays: NumberMaskGenerator('yearDays'),
  currency: NumberMaskGenerator('currency'),
  amount: NumberMaskGenerator('amount'),
  percentage: NumberMaskGenerator('percentage'),
  default: 'input'
};

export default Masks;
