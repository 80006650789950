import SubSegmentList from './SubSegmentList';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  fetchSubSegmentsList,
  updateSubSegment,
  closeSubSegmentsFormModal,
  openSubSegmentsFormModal,
} from '../../../actions/sub_segment_actions';

function mapStateToProps(state) {
  return {
    configs: state.subSegment.configs,
    meta: state.subSegment.meta,
    loading: state.requestState.requestSubSegmentListSend,
    canUpdateSubsegments: state.authentication.currentUser.canUpdateSubsegments,
    modalSubmitLoading: (
      state.requestState.requestSubSegmentUpdateSend
    ),
    formModalIsOpen: state.subSegment.formModalIsOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSubSegmentsList: bindActionCreators(fetchSubSegmentsList, dispatch),
    openFormModal: bindActionCreators(openSubSegmentsFormModal, dispatch),
    closeFormModal: bindActionCreators(closeSubSegmentsFormModal, dispatch),
    updateConfig: bindActionCreators(updateSubSegment, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SubSegmentList);