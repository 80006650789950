import _pickBy from 'lodash/pickBy';

const queryData = filters => {
  const { queryType, contract, identification, identificationType } = filters;

  const sanitizedFilters = {
    searchType: queryType,
    contractId: queryType === 'contract' ? contract.toString() : null,
    identification: queryType === 'id' ? identification.toString() : null,
    typeOfIdentification: queryType === 'id' ? identificationType : null
  };

  return _pickBy(sanitizedFilters, filter => !!filter);
};

export default queryData;
