import {
    SET_SCORING_CONFIGURATION_LIST_DATA,
    OPEN_SCORING_CONFIGURATION_FORM_MODAL,
    CLOSE_SCORING_CONFIGURATION_FORM_MODAL
} from '../constants/scoring_configurations_constants';
import { sendRequest, completeRequest } from './request_state';
import { serverStatusError, newAlert } from './alert_actions';
import { listSanitizer, createSanitizer, updateSanitizer } from '../helpers/sanitizers/scoringConfigurationSanitizer';
import AxiosRequest from '../services/axios_request';

const axios = new AxiosRequest();

export const fetchScoringConfigurationList = params => (
    async dispatch => {
        dispatch(sendRequest('ScoringConfigurationList'));
        try {
            const payload = await axios.get(
                '/scoring_exclusions',
                listSanitizer(params)
            );
            dispatch(setScoringConfigurationListData(payload));
        } catch (errors) {
            dispatch(serverStatusError(errors));
        } finally {
            dispatch(completeRequest('ScoringConfigurationList'));
        }
    }
);

export const createScoringConfiguration = params => (
    async dispatch => {
        dispatch(sendRequest('ScoringConfigurationCreate'));
        try {
            await axios.post(
                '/scoring_exclusions',
                createSanitizer(params.fields)
            );
            dispatch(_closeScorigConfigurationFormModal());
            await fetchScoringConfigurationList({ page: 1, perPage: params.perPage })(dispatch);
        } catch (payload) {
            dispatch(newAlert('error', 'ERROR:', payload.errors.base[0]));
        } finally {
            dispatch(completeRequest('ScoringConfigurationCreate'));
        }
    }
);

export const updateScoringConfiguration = params => (
    async dispatch => {
        dispatch(sendRequest('ScoringConfigurationUpdate'));
        try {
            await axios.put(
                `/scoring_exclusions/${params.fields.id}`,
                updateSanitizer(params.fields)
            );
            dispatch(_closeScorigConfigurationFormModal());
            await fetchScoringConfigurationList({ page: params.page, perPage: params.perPage })(dispatch);
        } catch (payload) {
            dispatch(newAlert('error', 'ERROR:', payload.errors.base[0]));
        } finally {
            dispatch(completeRequest('ScoringConfigurationUpdate'));
        }
    }
);

export const deleteScoringConfiguration = params => (
    async dispatch => {
        dispatch(sendRequest('ScoringConfigurationDelete'));
        try {
            await axios.delete(
                `/scoring_exclusions/${params.id}`
            );
            dispatch(_closeScorigConfigurationFormModal());
            await fetchScoringConfigurationList({ page: params.page, perPage: params.perPage })(dispatch);
        } catch (errors) {
            dispatch(serverStatusError(errors));
        } finally {
            dispatch(completeRequest('ScoringConfigurationDelete'));
        }
    }
);

export const openScoringConfigurationFormModal = () => (
    dispatch => {
        dispatch(_openScoringConfigurationFormModal());
    }
)

export const closeScoringConfigurationFormModal = () => (
    dispatch => {
        dispatch(_closeScorigConfigurationFormModal());
    }
)


const setScoringConfigurationListData = payload => {
    return {
        type: SET_SCORING_CONFIGURATION_LIST_DATA,
        data: payload.data.scoring_exclusions,
        meta: payload.meta
    };
};

const _openScoringConfigurationFormModal = () => {
    return {
        type: OPEN_SCORING_CONFIGURATION_FORM_MODAL
    };
}

const _closeScorigConfigurationFormModal = () => {
    return {
        type: CLOSE_SCORING_CONFIGURATION_FORM_MODAL
    };
}
