import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const SubmitConfirmationModal = props => {
  const { open, onClose, onSubmit, onSave } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Propuesta Finalizada</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Deseas enviar la propuesta a revisión o guardarla para enviarla después?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color="secondary">
          Enviar
        </Button>
        <Button onClick={onSave} color="secondary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitConfirmationModal;