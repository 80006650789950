import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';

import formatMoney from '../../../helpers/formatMoney';
import InfoField from '../../shared/InfoField';
import SegmentHeader from '../../shared/SegmentHeader';
import StepperButtons from '../../shared/StepperButtons';
import { Formik, Form, Field } from 'formik';
import { validationSchema } from './TeleshoppingEndPageValidation';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import ConfirmDialog from '../../shared/Modals/ConfirmDialog';

class TeleshoppingEndPage extends Component {
  state = {
    salesman: '',
    sellerDataAuthorization: true,
    disabledNext: false,
    blockedModalNotification: true,
    isSellerBlocked: false,
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  initialStep = 0;
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.baseErrorOpen !== this.props.baseErrorOpen &&
      !this.props.baseErrorOpen
    ) {
      this.formik.resetForm(this.state);
    }
  }

  initialValues = {
    contract: this.props.contract,
    client_name: this.props.client_name,
    document: this.props.document,
    verification_sale: this.props.verification_sale,
    product_name: this.props.product_name,
    new_sale_id: this.props.new_sale_id,
    price: this.props.price,
    iva: this.props.iva,
    instalments: this.props.instalments,
    observations: '',
    sold_at: this.props.sold_at,
    reference_sale: this.props.reference_sale,
    reference_sale_supplier: this.props.reference_sale_supplier,
    reference_sale_pointOfSale: this.props.reference_sale_pointOfSale,
    reference_sale_salesman: this.props.reference_sale_salesman,
    salesmanName: this.props.salesmanName || this.props.reference_sale_salesman,
    salesmanId: this.props.salesmanId || this.props.reference_sale_salesman_id,
    reference_sale_registered_at: this.props.reference_sale_registered_at
  };

  onSubmit = values => {
    const {
      contract,
      verification_sale,
      observations,
      price,
      instalments,
      salesmanId,
      salesmanName
    } = values;

    const { process, routes } = this.props;
    const params = {
      contract,
      verification_sale,
      observations,
      price,
      instalments,
      salesmanId,
      salesmanName
    };

    this.props.createTeleshopping(params, process, routes, this.nextStep);
  };

  _getSuggestions = async (array, value) => {
    const items = array.map(item => ({
      label: _get(item, 'attributes.firstName', '') + ' ' + _get(item, 'attributes.lastName', ''),
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  handleCloseDataAuthorizationModalNotification = () => this.setState({ sellerDataAuthorization: true });

  handleCloseModalModalNotification = () => this.setState({ blockedModalNotification: false });

  render() {
    const {
      classes,
      process,
      sellers,
      orgSellerDataAuthorization,
      isBlackListActive
    } = this.props;

    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            ref={ref => (this.formik = ref)}
            enableReinitialize={true}
            initialValues={this.initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            render={({ values, handleChange, errors, handleSubmit, setFieldValue, touched }) => (
              <form autoComplete="off" className={classes.form}>
                <SegmentHeader>RESUMEN DE TELEVENTA</SegmentHeader>
                <Grid container className={classes.infoFieldGrid}>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Número de contrato:">
                      {values.contract}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Venta de verificación:">
                      {values.verification_sale}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Cliente:">{values.client_name}</InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Venta de referencia:">
                      {values.reference_sale}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Documento de identificación:">
                      {values.document}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Nombre del producto:">
                      {values.product_name}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Valor del producto:">
                      {formatMoney(values.price)}
                    </InfoField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InfoField label="Número de cuotas:">
                      {values.instalments}
                    </InfoField>
                  </Grid>
                </Grid>
                <SegmentHeader>DETALLE VENTA DE REFERENCIA:</SegmentHeader>
                <Grid container className={classes.infoFieldGrid}>
                  {values.reference_sale_supplier && (
                    <Grid item md={6} xs={12}>
                      <InfoField label="Proveedor: ">
                        {values.reference_sale_supplier}
                      </InfoField>
                    </Grid>
                  )}
                  {values.reference_sale_pointOfSale && (
                    <Grid item md={6} xs={12}>
                      <InfoField label="Punto de venta:">
                        {values.reference_sale_pointOfSale}
                      </InfoField>
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    {process === 'remoteTeleshopping' ? (
                      <Field
                        render={({ field, form }) => {
                          return (
                            <AutocompleteInput
                              id="salesmanId"
                              name="salesmanId"
                              label="Vendedor:"
                              margin="normal"
                              variant="outlined"
                              error={(touched.salesmanId && !!errors.salesmanId)}
                              helperText={(touched.salesmanId && errors.salesmanId)}
                              value={this.state.salesman || values.salesmanName || ''}
                              onChange={change => {
                                setFieldValue('salesmanId', '');
                                setFieldValue('salesmanName', '');
                                this.setState({ salesman: change });
                              }}
                              suggestions={sellers}
                              getSuggestions={value => this._getSuggestions(sellers, value)}
                              onSuggestionSelected={item => {
                                setFieldValue('salesmanName', item.label);
                                setFieldValue('salesmanId', item.value);

                                const sellerSelected = sellers.filter(seller => seller.id == item.value);
                                const sellerPersonalDataHandling = sellerSelected[0].attributes.personalDataHandling;

                                this.setState({
                                  disabledNext: sellerPersonalDataHandling === null,
                                  sellerDataAuthorization: sellerPersonalDataHandling,
                                  isSellerBlocked: sellerSelected[0].attributes.blocked,
                                  blockedModalNotification: sellerSelected[0].attributes.blocked
                                });
                              }}
                              field={field}
                              form={form}
                              classes={{ root: classes.autocompleteRoot }}
                            />
                          );
                        }}
                      />
                    ) : (
                      <InfoField label="Vendedor:">
                        {values.reference_sale_salesman || ''}
                      </InfoField>
                    )}
                  </Grid>
                  {values.reference_sale_registered_at && (
                    <Grid item md={6} xs={12}>
                      <InfoField label="Fecha de registro:">
                        {values.reference_sale_registered_at}
                      </InfoField>
                    </Grid>
                  )}
                </Grid>
                <OutlinedTextField
                  multiline
                  rows={4}
                  name="observations"
                  label="Observaciones"
                  value={values.observations}
                  onChange={handleChange}
                  className={classes.observations}
                />

                <div className={classes.actionBar}>
                  <StepperButtons
                    onSubmit={handleSubmit}
                    goBack={this.props.goBack}
                    disabledNext={
                      (this.state.disabledNext && orgSellerDataAuthorization) ||
                      (isBlackListActive && this.state.isSellerBlocked)
                    }
                  />
                </div>
              </form>
            )}
          />
          {this.state.sellerDataAuthorization === null &&
            orgSellerDataAuthorization && (
              <ConfirmDialog
                title={
                  'Notificación del uso de tratamiento de datos personales'
                }
                text={`El vendedor seleccionado no ha dado respuesta a la autorización de tratamiento de datos personales`}
                buttonTextConfirm={'Aceptar'}
                disableBackdropClick={true}
                onClose={this.handleCloseDataAuthorizationModalNotification}
                onConfirm={this.handleCloseDataAuthorizationModalNotification}
              />
            )}
          {this.state.blockedModalNotification &&
            isBlackListActive && this.state.isSellerBlocked && (
              <ConfirmDialog
                title={
                  'Notificación de bloqueo'
                }
                text={`Lo sentimos, tiene deshabilitada esta función. Por favor, comuniquese con el area comercial.`}
                buttonTextConfirm={'Aceptar'}
                disableBackdropClick={true}
                onClose={this.handleCloseModalModalNotification}
                onConfirm={this.handleCloseModalModalNotification}
              />
            )}
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {},
  infoFieldGrid: {
    alignItems: 'flex-end',
    marginBottom: 5 * theme.spacing.unit
  },
  buttonsGrid: {
    marginBottom: 2 * theme.spacing.unit
  },
  form: {
    paddingTop: 30
  },
  grayContainer: {
    backgroundColor: '#EFF1F4',
    padding: 20,
    marginBottom: theme.spacing.unit * 2
  },
  actionBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  carrierInfoContainer: {
    marginBottom: 0,
    width: '50%',

    '@media (max-width:940px)': {
      width: '100%'
    }
  },
  button: {
    alignSelf: 'flex-end',
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  bigButton: {
    width: '100%',
    fontSize: '1rem',
    color: '#db4945'
  },
  detailText: {
    color: 'rgba(12,31,44,0.87)',
    margin: 0,
    marginBottom: 5,
    fontSize: 17,
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0.15
  },
  observations: {
    marginTop: 5 * theme.spacing.unit
  },
  autocompleteRoot: {
    paddingLeft: 3 * theme.spacing.unit,
    paddingRight: 5 * theme.spacing.unit
  }
});

TeleshoppingEndPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TeleshoppingEndPage);
