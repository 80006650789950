import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { newAlert } from '../../../actions/alert_actions';
import SupplierDetail from './SupplierDetail';
import {
  fetchSupplierInfo,
  updateSupplierConfig
} from '../../../actions/suppliers_actions';

function mapStateToProps(state) {
  return {
    permissions: state.authentication.permissions,
    locationNames: state.globalSetting.orgData.geographic_locations,
    currentUser: state.authentication.currentUser,
    supplier: state.suppliers.supplierInfo,
    supplierLoading: state.requestState.requestSupplierInfoSend,
    supplierUpdating: state.suppliers.loadingUpdate,
    supplierGeographicLocationsIds: state.globalSetting.orgData.supplier_geographic_locations_ids,
    canUpdateSupplierGeographicLocations: state.authentication.currentUser.canUpdateSupplierGeographicLocations,
    saleChannels:
      state.globalSetting.orgData.sale_channels &&
      state.globalSetting.orgData.sale_channels.filter(
        sale_channel => sale_channel.active
      )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    fetchSupplierInfo: bindActionCreators(fetchSupplierInfo, dispatch),
    updateSupplierConfig: bindActionCreators(updateSupplierConfig, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierDetail);
