import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withStyles } from '@material-ui/core/styles';
import FieldValidationPopup from '../Poppers/FieldValidation';

class SimpleSelect extends Component {
  state = {
    labelWidth: 0,
    anchorEl: null
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    });
  }

  _handleShowError = event => {
    const { helperText } = this.props;
    if (helperText) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };
  _handleHideError = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      label,
      name,
      value,
      onChange,
      options,
      inputProps,
      helperText,
      popperError = false,
      allowMargin = true,
      error = null,
      errorStyle = {},
      endAdornment,
      ...props
    } = this.props;
    const { anchorEl } = this.state;
    const formControl = allowMargin ? classes.formControl : '';
    return (
      <FormControl
        variant="outlined"
        fullWidth
        {...props}
        className={formControl}
        error={error}
      >
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor={`outlined-${name}-simple`}
        >
          {label}
        </InputLabel>
        <Select
          inputProps={{ ...inputProps, name }}
          value={value}
          onChange={onChange}
          endAdornment={endAdornment}
          input={
            <OutlinedInput
              labelWidth={this.state.labelWidth}
              name={name}
              id={`outlined-${name}-simple`}
              onMouseEnter={this._handleShowError}
              onFocus={this._handleShowError}
              onMouseOut={this._handleHideError}
            />
          }
        >
          {options.map(item => (
            <MenuItem
              key={item.key}
              value={item.value}
              disabled={item.disabled}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {popperError ? (
          <FieldValidationPopup anchorEl={anchorEl} message={helperText} />
        ) : (
          helperText && <FormHelperText style={errorStyle}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

const styles = () => ({
  formControl: {
    marginTop: '16px',
    marginBottom: '8px'
  }
});

SimpleSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  helperText: PropTypes.string
};

export default withStyles(styles)(SimpleSelect);
