import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import SegmentHeader from '../../shared/SegmentHeader';
import StepperButtons from '../../shared/StepperButtons';
import { history } from '../../../helpers/history';
import DocumentItem from '../SaleDetailPage/Document/Item';
import ConfirmDialog from '../../shared/Modals/ConfirmDialog';
import {
  isDigitalSale,
  isRemoteDigitalSale
} from '../../../helpers/stepperFunctions';
import _get from 'lodash/get';
const adblockDetector = require('adblock-detect');

class PortalEndSalePage extends Component {
  state = {
    dialogAdBlock: false
  };
  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  initialStep = 0;

  componentDidMount() {
    !this.props.companyCode && this.props.fetchSessionData();
    this.props.setCurrentStep(this.currentStep);
    this.props.setNextStep(this.props.process, this.initialStep);
    this._getPDFs();
    adblockDetector(adBlockDetected => {
      if (adBlockDetected) {
        this.setState({ dialogAdBlock: true });
      }
    });
  }

  _onSubmit = (/* values */) => {
    if (this.props.saleNumber) {
      history.push(`/sales/${this.props.saleNumber}`);
    } else {
      history.push(this.props.routes[this.initialStep]);
    }
  };

  _handleCloseDialog = () => {
    this.setState({ dialogAdBlock: false });
  };

  _getPDFs = () => {
    const {
      canGenPromissoryPDF,
      canGenInvoicePDF,
      canGenVoucherPDF,
      canGenSecurePDF,
      saleNumber,
      canGenEnsuranceRequestPDF,
      cardifArticleLayerOne,
      voluntaryInsuranceLayerTwoArticleId,
      articles
    } = this.props;
    if (saleNumber) {
      //remove layer 1 while sbb-1521 is merged
      const hasCardifArticleLayerOne = articles.find(article => article.id === _get(cardifArticleLayerOne, 'id'));
      const hasCardifArticleLayerTwo = articles.find(article => article.id === voluntaryInsuranceLayerTwoArticleId);

      canGenPromissoryPDF && this.props.pdfPromissory(saleNumber);
      canGenVoucherPDF && this.props.pdfVoucher(saleNumber);
      canGenInvoicePDF && this.props.showBin && this.props.pdfBin(saleNumber);
      canGenSecurePDF && this.props.isSecureSale && this.props.pdfSecure(saleNumber);
      canGenEnsuranceRequestPDF && hasCardifArticleLayerOne && this.props.pdfRequestCardif(saleNumber);
      canGenEnsuranceRequestPDF && hasCardifArticleLayerTwo && this.props.pdfRequestCardifLayerTwo(saleNumber);
    }
  };

  captureVoucher = () => {
    const { saleNumber } = this.props;
    if (saleNumber) {
      history.push(`/digital-sale/capture-voucher/${this.props.saleNumber}`);
    }
  };

  render() {
    const {
      classes,
      showBin,
      isSecureSale,
      requestVoucherUrl,
      requestBinUrl,
      requestSecureUrl,
      requestPromissoryUrl,
      canGenPromissoryPDF,
      canGenInvoicePDF,
      canGenVoucherPDF,
      canGenSecurePDF,
      cardifArticleLayerOne,
      requestCardifUrl,
      requestCardifUrlLayerTwoUrl,
      canGenEnsuranceRequestPDF,
      voluntaryInsuranceLayerTwoArticleId,
      articles,
      voluntaryInsuranceLayerTwoName
    } = this.props;
    const initialValues = {};

    const hasCardifArticleLayerOne = articles.find(article => article.id === _get(cardifArticleLayerOne, 'id'));
    const hasCardifArticleLayerTwo = articles.find(article => article.id === voluntaryInsuranceLayerTwoArticleId);

    return (
      <div>
        <Paper className={classes.container}>
          <Formik
            initialValues={initialValues}
            validationSchema={{}}
            onSubmit={this._onSubmit}
            render={({ handleSubmit }) => (
              <Form className={classes.form}>
                <SegmentHeader className={classes.segmentHeader}>
                  VENTA FINALIZADA
                </SegmentHeader>
                <Grid container spacing={24} justify="center" direction="row">
                  {canGenVoucherPDF && (
                    <Grid item xs={12} md={3}>
                      <DocumentItem
                        name={
                          isRemoteDigitalSale(this.props.process) ||
                            isDigitalSale(this.props.process)
                            ? 'Visualizar Voucher'
                            : 'Imprimir Voucher'
                        }
                        url={this.props.voucherUrl}
                        loading={requestVoucherUrl || !this.props.voucherUrl}
                      />
                    </Grid>
                  )}
                  {canGenPromissoryPDF && (
                    <Grid item xs={12} md={3}>
                      <DocumentItem
                        name={
                          isRemoteDigitalSale(this.props.process) ||
                            isDigitalSale(this.props.process)
                            ? 'Visualizar Pagaré'
                            : 'Imprimir Pagaré'
                        }
                        url={this.props.promissoryUrl}
                        loading={
                          requestPromissoryUrl || !this.props.promissoryUrl
                        }
                      />
                    </Grid>
                  )}
                  {showBin && canGenInvoicePDF && (
                    <Grid item xs={12} md={3}>
                      <DocumentItem
                        name="Generar BIN"
                        url={this.props.binUrl}
                        loading={requestBinUrl || !this.props.binUrl}
                      />
                    </Grid>
                  )}
                  {isSecureSale && canGenSecurePDF && (
                    <Grid item xs={12} md={3}>
                      <DocumentItem
                        name={
                          isRemoteDigitalSale(this.props.process) ||
                            isDigitalSale(this.props.process)
                            ? 'Visualizar Fianza'
                            : 'Imprimir Fianza'
                        }
                        url={this.props.secureUrl}
                        loading={
                          requestSecureUrl || !this.props.secureUrl
                        }
                      />
                    </Grid>
                  )}
                   {canGenEnsuranceRequestPDF && hasCardifArticleLayerOne && !isRemoteDigitalSale(this.props.process) && (
                    <Grid item xs={12} md={3}>
                      <DocumentItem
                        name={"Seguro cardif"}
                        url={this.props.cardifUrl}
                        loading={
                          requestCardifUrl || !this.props.cardifUrl
                        }
                      />
                    </Grid>
                  )}
                  {canGenEnsuranceRequestPDF && hasCardifArticleLayerTwo && !isRemoteDigitalSale(this.props.process) && (
                    <Grid item xs={12} md={3}>
                      <DocumentItem
                        name={voluntaryInsuranceLayerTwoName}
                        url={this.props.cardifLayerTwoUrl}
                        loading={
                          requestCardifUrlLayerTwoUrl || !this.props.cardifLayerTwoUrl
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                <StepperButtons onSubmit={handleSubmit} />
              </Form>
            )}
          />
        </Paper>
        <ConfirmDialog
          open={this.state.dialogAdBlock}
          title="¡Bloqueador de ventanas emergentes detectado!"
          text="Recuerda desactivar tu adblocker para poder visualizar los documentos de la venta."
          buttonTextConfirm="de acuerdo"
          onConfirm={this._handleCloseDialog}
          onClose={this._handleCloseDialog}
        />
      </div>
    );
  }
}
const styles = theme => ({
  container: {},
  infoFieldGrid: {
    minHeight: 200,
    alignItems: 'center'
  },
  segmentHeader: {
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  gridItem: {
    paddingTop: '0 !important',
    paddingBottom: '12 !important'
  },
  table: {
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 3 * theme.spacing.unit
  },
  actionBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  mainButton: {
    alignSelf: 'flex-end',
    marginTop: 4 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  registerDateContainer: {
    marginTop: theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit
  },
  dialog: {
    width: '80%'
  },
  dialogButton: {
    color: theme.palette.text.primary,
    margin: 1 * theme.spacing.unit,
    marginTop: 0,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  }
});

PortalEndSalePage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(PortalEndSalePage);
