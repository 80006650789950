const styles = theme => ({
  headerContainer: {
    marginTop: 20,
    marginBottom: 50
  },
  emptyStateMessageContainer: {
    marginTop: 20,
    marginBottom: 20
  }
});
export default styles;