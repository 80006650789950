import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import PortalRegisterFinancingPage from './PortalRegisterFinancingPage';
import { newAlert } from '../../../actions/alert_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import {
  checkFirstStepRegisterSale,
  checkFirstStepRegisterPromissory
} from '../../../actions/portal_financing_actions';
import _get from 'lodash/get';
import { locationValidityCheck } from '../../../actions/location_exception_configuration_actions';
import { resetQueryCampaign } from '../../../actions/campaign_actions';

function mapStateToProps(state) {
  const orgData = _get(state, 'globalSetting.orgData');
  return {
    serverErrors: state.portalFinancing.errors,
    baseErrorOpen: state.portalFinancing.baseErrorOpen,
    routes: state.stepper.routes,
    process: state.stepper.process,
    remoteDigitalSaleNext: state.stepper.remoteDigitalSaleNext,
    gnpSupplierId: _get(orgData, 'gnp_supplier_id'),
    userSupplierId:
      state.authentication.currentUser.supplier &&
      state.authentication.currentUser.supplier.id,
    isBlocked: state.authentication.currentUser.blocked,
    isBlackListActive: orgData.get_blacklist_active
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    checkFirstStepRegisterSale: bindActionCreators(
      checkFirstStepRegisterSale,
      dispatch
    ),
    checkFirstStepRegisterPromissory: bindActionCreators(
      checkFirstStepRegisterPromissory,
      dispatch
    ),
    resetQueryCampaign: bindActionCreators(resetQueryCampaign, dispatch),
    locationValidityCheck: bindActionCreators(locationValidityCheck, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PortalRegisterFinancingPage);
