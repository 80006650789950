import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = () => ({
  root: {},
  notificationCircleRoot: {
    width: 13,
    height: 13,
    borderRadius: '50%',
    backgroundColor: '#FEB00B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 11,
    right: 9
  },
  notificationCircle: {
    width: 9,
    height: 9,
    borderRadius: '50%',
    backgroundColor: '#FEB00B'
  },
  icon: {}
});

export const NotificationsCircle = ({ classes }) => (
  <div className={classnames(classes.notificationCircleRoot, classes.root)}>
    <div className={classnames(classes.notificationCircle, classes.icon)} />
  </div>
);

export default withStyles(styles)(NotificationsCircle);
