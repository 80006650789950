import _pickBy from 'lodash/pickBy';

const commissionFilters = filters => {
  const {
    category_name,
    supplier_name,
    article_name,
    sale_channel_name,
    status,
    type,
    page,
    per_page
  } = filters;

  const sanitizedFilters = {
    category_name_cont: category_name,
    supplier_name_cont: supplier_name,
    article_name_cont: article_name,
    sale_channel_name_cont: sale_channel_name,
    page,
    per_page
  };

  if (status === 'active') {
    sanitizedFilters.active_eq = true;
  }

  if (status === 'inactive') {
    sanitizedFilters.active_not_eq = true;
  }

  if (type === 'sale') {
    sanitizedFilters.commission_type_code_eq = 'sale';
  }

  if (type === 'advertisement') {
    sanitizedFilters.commission_type_code_eq = 'advertisement';
  }

  return _pickBy(sanitizedFilters, filter => !!filter);
};

export default commissionFilters;
