import {
  DASHBOARD_SELLERS_SUMMARY,
  DASHBOARD_SELLERS_SALES,
  DASHBOARD_TOP_RANKING,
  DASHBOARD_DATA_SALESMAN,
  DASHBOARD_DATA_SUPPLIER,
  SUPPLIER_FILTER,
  FILTER_SELECTION,
  DASHBOARD_COUNTER_SUMMARY
} from '../constants/dashboard_sellers_constants';
import { sendRequest, completeRequest } from './request_state';
import RequestService from '../services/request_service';
import { salesmanDataFilters } from '../helpers/sanitizers/salesmanDataFilters';
import { salesPerSellerFilters, dataSalesmanSupplierSanitizer } from '../helpers/sanitizers/salesPerSellerFilters';
import { serverStatusError, newAlert } from './alert_actions';
import snakecaseKeys from 'snakecase-keys';

const requestService = new RequestService();
const requestServiceNode = new RequestService('node');

function setDashboardSellersSummary(data) {
  return {
    type: DASHBOARD_SELLERS_SUMMARY,
    data
  };
}

function setDashboardSellersSales(data) {
  return {
    type: DASHBOARD_SELLERS_SALES,
    data
  };
}

export function setSupplierFilter(data) {
  return {
    type: SUPPLIER_FILTER,
    data
  };
}

export function setFilterSelection(data) {
  return {
    type: FILTER_SELECTION,
    data
  };
}

function setDashboardTopRanking(data) {
  return {
    type: DASHBOARD_TOP_RANKING,
    data
  };
}

function setDashboardSalesmanHistory(data) {
  return {
    type: DASHBOARD_DATA_SALESMAN,
    data
  };
}

function setDashboardSupplierHistory(data) {
  return {
    type: DASHBOARD_DATA_SUPPLIER,
    data
  };
}

function setDashboardCounterSummary(data) {
  return {
    type: DASHBOARD_COUNTER_SUMMARY,
    data
  };
}

//* VIEW ACTION FUNCTIONS
export const fetchSellersSummary = (params = {}) => async dispatch => {
  dispatch(sendRequest('DashboardSellersSummary'));
  try {
    const payload = await requestServiceNode.post(
      'dashboard/sellersSummary',
      salesPerSellerFilters(params)
    );
    dispatch(setDashboardSellersSummary(payload.data));
  } catch (errors) {
    if (errors.statusText === 'Service Unavailable') {
      dispatch(
        newAlert('error', 'ERROR:', 'Error de conexión con servicio ODS.')
      );
    } else {
      dispatch(serverStatusError(errors));
      if (errors) {
        const e = await errors.json();

        if (e.errors.base[0]) {
          const message = e.errors.base[0].base[0];
          dispatch(newAlert('error', 'ERROR:', message));
        }
      }
    }
  } finally {
    dispatch(completeRequest('DashboardSellersSummary'));
  }
};

export const fetchCounterSummary = (params = {}) => async dispatch => {
  dispatch(sendRequest('DashboardCounterSummary'));
  try {
    const payload = await requestServiceNode.post(
      'dashboard/counterByState',
      salesmanDataFilters(params),
    );
    dispatch(setDashboardCounterSummary(snakecaseKeys(payload.data)));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      newAlert('error', 'ERROR:', e.errors.base)
    }

    if (errors.statusText === 'Service Unavailable') {
      dispatch(
        newAlert('error', 'ERROR:', 'Error de conexión con servicio ODS.')
      );
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('DashboardCounterSummary'));
  }
};

export const fetchSellersSales = (params = {}) => async dispatch => {
  dispatch(sendRequest('DashboardSellersSales'));
  try {
    const payload = await requestServiceNode.post(
      'dashboard/sellersSales',
      salesPerSellerFilters(params)
    );
    dispatch(setDashboardSellersSales(payload.data));
  } catch (errors) {
    if (errors.statusText === 'Service Unavailable') {
      dispatch(
        newAlert('error', 'ERROR:', 'Error de conexión con servicio ODS.')
      );
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('DashboardSellersSales'));
  }
};

export const fetchSupplierFilter = data => async dispatch => {
  dispatch(setSupplierFilter(data));
};

export const fetchFilterSelection = data => async dispatch => {
  dispatch(setFilterSelection(data));
};

export const fetchTopRanking = (params = {}) => async dispatch => {
  dispatch(sendRequest('DashboardTopRanking'));
  try {
    const payload = await requestServiceNode.post(
      'dashboard/topRanking',
      salesPerSellerFilters(params)
    );
    dispatch(setDashboardTopRanking(payload.data));
  } catch (errors) {
    if (errors.statusText === 'Service Unavailable') {
      dispatch(
        newAlert('error', 'ERROR:', 'Error de conexión con servicio ODS.')
      );
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('DashboardTopRanking'));
  }
};

export const fetchDataSalesman = (params = {}) => async dispatch => {
  dispatch(sendRequest('DashboardDataSalesman'));
  try {
    const payload = await requestServiceNode.post(
      'dashboard/dataSalesman',
      dataSalesmanSupplierSanitizer(params)
    );
    dispatch(setDashboardSalesmanHistory(payload.data));
  } catch (errors) {
  } finally {
    dispatch(completeRequest('DashboardDataSalesman'));
  }
};

export const fetchDataSupplier = (params = {}) => async dispatch => {
  dispatch(sendRequest('DashboardDataSupplier'));
  try {
    const payload = await requestServiceNode.post(
      'dashboard/dataSupplier',
      dataSalesmanSupplierSanitizer(params)
    );
    dispatch(setDashboardSupplierHistory(payload.data));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest('DashboardDataSupplier'));
  }
};
