import _pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

const queryData = filters => {
    const {
        cache_params,
        cosigner_expedition_date,
        cosigner_was_solidary,
        debtor_expedition_date,
        supplier_id,
        process
    } = filters;

    const sanitizedFilters = {
        debtorContractNumber: cache_params.debtor_contract_number.toString(),
        debtorIdentification: cache_params.debtor_identification.toString(),
        debtorIdentificationType: cache_params.debtor_identification_type,
        debtorBirthdate: moment(cache_params.debtor_birthdate).format('YYYY/MM/DD'),
        debtorExpeditionDate: moment(debtor_expedition_date).format('YYYY/MM/DD'),
        cosignerContractNumber: cache_params.cosigner_contract_number ? cache_params.cosigner_contract_number.toString() : null,
        cosignerIdentification: cache_params.cosigner_identification ? cache_params.cosigner_identification.toString() : null,
        cosignerIdentificationType: cache_params.cosigner_identification_type ? cache_params.cosigner_identification_type : null,
        cosignerBirthdate: cache_params.cosigner_birthdate ? moment(cache_params.cosigner_birthdate).format('YYYY/MM/DD') : null,
        cosignerExpeditionDate: cosigner_expedition_date ? moment(cosigner_expedition_date).format('YYYY/MM/DD') : null,
        supplierId: supplier_id,
        process,
        cosignerWasSolidary: cosigner_was_solidary
    };

    return _pickBy(sanitizedFilters, filter => !!filter);
};

export default queryData;