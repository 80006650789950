import PromissoryList from './PromissoryList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchPromissories } from '../../../actions/promissory_actions';

function mapStateToProps(state) {
  const { promissories, meta } = state.promissory;
  return {
    promissories,
    meta,
    requestSend: state.requestState.requestSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPromissories: bindActionCreators(fetchPromissories, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PromissoryList);
