import {
  QUERY_PROMISSORIES,
  OPEN_QUERY_PROMISSORY_MODAL,
  CLOSE_QUERY_PROMISSORY_MODAL,
  RESET_QUERY_DATA,
  SET_AVAILABLE_QUOTA
} from '../constants/query_promissory_constants';

const initialState = {
  promissories: [],
  isQueryPromissoryModalOpen: false,
  availableQuota:  '--',
  hasSearched: false
};

export function queryPromissories(state = initialState, action) {
  switch (action.type) {
    case QUERY_PROMISSORIES:
      return {
        ...state,
        promissories: action.data,
        hasSearched: true
      };
    case OPEN_QUERY_PROMISSORY_MODAL:
      return {
        ...state,
        isQueryPromissoryModalOpen: true
      }
    case CLOSE_QUERY_PROMISSORY_MODAL:
      return {
        ...state,
        isQueryPromissoryModalOpen: false
      }
    case RESET_QUERY_DATA:
      return {
        ...state,
        promissories: initialState.promissories,
        availableQuota: initialState.availableQuota,
        hasSearched: initialState.hasSearched
      }
    case SET_AVAILABLE_QUOTA:
      return {
        ...state,
        availableQuota: action.availableQuota
      }
    default:
      return state;
  }
}
