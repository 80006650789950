import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Teleshopping from './Teleshopping';
import { newAlert } from '../../../actions/alert_actions';
import {
  persistTeleshopping,
  setNextStep,
  toggleBaseErrorModal,
  clearTeleshopping,
} from '../../../actions/teleshopping_actions';
import {
  resetStepper,
  setSteps,
  persistStepper,
  setCurrentStep
} from '../../../actions/stepper_actions';
import { getSteps } from '../../../helpers/stepperFunctions';

function mapStateToProps(state) {
  return {
    routes: state.stepper.routes,
    process: state.stepper.process,
    steps: state.stepper.steps,
    teleshoppingCompleted: state.teleshopping.teleshoppingCompleted,
    teleshoppingNext: state.stepper.teleshoppingNext,
    activeStep: state.stepper.activeStep,
    nextStep: state.stepper.nextStep,
    currentUser: state.authentication.currentUser,
    loadingParameters: state.requestState.requestFetchGlobalParametersSend,
    server_errors: state.teleshopping.errors,
    baseErrorOpen: state.teleshopping.baseErrorOpen,
    baseErrorText: state.teleshopping.baseErrorText,
    baseErrorTitle: state.teleshopping.baseErrorTitle,
    baseErrorRoute: state.teleshopping.baseErrorRoute
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetStepper: bindActionCreators(resetStepper, dispatch),
    setSteps: bindActionCreators(setSteps, dispatch),
    getSteps: bindActionCreators(getSteps, dispatch),
    persistStepper: bindActionCreators(persistStepper, dispatch),
    toggleBaseErrorModal: bindActionCreators(toggleBaseErrorModal, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    persistTeleshopping: bindActionCreators(persistTeleshopping, dispatch),
    clearTeleshopping: bindActionCreators(clearTeleshopping, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Teleshopping);
