/* eslint-disable*/
import { withStyles, Grid } from '@material-ui/core';

import _capitalize from 'lodash/capitalize';
import _debounce from 'lodash/debounce';
import _every from 'lodash/every';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _join from 'lodash/join';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import combineStyles from '../../../../helpers/combineStyles';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';

import CustomDialog from '../../../shared/Modals/CustomDialog';

import BulkLoad from '../../../shared/BulkLoad/BulkLoad';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';


class GracePeriod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      option: '',
    };
  }

  _handleSave = () => {
    this.props
      .createGracePeriodMassive({ file: this.state.file, option: this.state.option })
      .then(r => {
        this.file = '';

        if (r.success) {
          this._handleClose();
        }
      });
  };

  _handleClose = () => {
    const { requestSend } = this.props;
    this.setState({ option: '' });
    if (!requestSend) this.props.onClose();
  };

  handleChangeFile = file =>  this.setState({ file });

  _disableSubmit = () => {
    return !this.state.file || !this.state.option;
  };
  _handleChange = (e) => {
    this.setState({ option: e.value });
  }

  render() {
    const {
      classes,
      requestSend,
      requestSendMassive,
    } = this.props;

    return (
      <div>
        <CustomDialog
          open={true}
          loading={requestSend || requestSendMassive}
          disabled={
            this._disableSubmit() ||
            requestSend ||
            requestSendMassive
          }
          disableBackdropClick={true}
          headerSecondary={<div />}
          onClose={this._handleClose}
          onConfirm={() => this._handleSave(false)}
          title={'Cargue Masivo'}
          buttonText={'Guardar'}
          classes={{
            root: classes.dialogRoot
          }}
        >
          <Grid item xs={12} md={4}>
            <SimpleSelect
              label="Tipo de Cargue"
              options={[{key: 1, value: 1, label: "Periodo de Gracia"}]}
              value={this.state.option}
              onChange={e => this._handleChange(e.target)}
            />
          </Grid>
        <BulkLoad
            templatePath={'/docs/plantilla-creacion-masiva-periodos-de-gracia.csv'}
            handleChangeFile={this.handleChangeFile}
        />
        </CustomDialog>
      </div>
    );
  }
}

const styles = theme => ({
  dialogRoot: {
    width: 800
  }
});

GracePeriod.propTypes = {
  open: PropTypes.bool
};

export default withStyles(
  combineStyles(styles, TypographyStyles, ButtonStyles),
  {
    withTheme: true
  }
)(GracePeriod);
