import PointUserAssociationList from './PointUserAssociationList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchPointUserAssociation, pointUserAssociationRevision, pointUserAssociationRevoke } from '../../../actions/point_user_association_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';
import { fetchPointsOfSaleNames } from '../../../actions/points_of_sale_actions';

function mapStateToProps(state) {
  return {
    currentUser: state.authentication.currentUser,
    canApproveSeller: state.authentication.currentUser.canApproveSeller,
    canRevokeSeller: state.authentication.currentUser.canRevokeSeller,
    causals: state.globalSetting.orgData.causals,
    pointUserAssociation: state.pointUserAssociation.pointUserAssociation,
    meta: state.pointUserAssociation.meta,
    requestSend: state.requestState.requestpointUserAssociationSend,
    causalsLoading: state.requestState.requestOrgDataSend,
    pointUserAssociationUpdated: state.pointUserAssociation.saved,
    updatePointUserAssociationApprove: state.requestState.requestPointUserAssociationRevisionSend,
    updatePointUserAssociationRevoke: state.requestState.requestPointUserAssociationRevokeSend,
    pointsOfSale: state.pointOfSale.pointsOfSaleNames,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPointUserAssociation: bindActionCreators(fetchPointUserAssociation, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    pointUserAssociationRevision: bindActionCreators(pointUserAssociationRevision, dispatch),
    fetchPointsOfSaleNames: bindActionCreators(fetchPointsOfSaleNames, dispatch),
    pointUserAssociationRevoke: bindActionCreators(pointUserAssociationRevoke, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PointUserAssociationList);
