import { CAMPAIGN_VALIDATION_RESPONSE, LIST_CAMPAIGN, SAVED_CAMPAIGN, RESET_QUERY_CAMPAIGN } from '../constants/campaign_constants';

const initialState = {
  list: [],
  saved: false,
  validationResponse: {}
};

export function campaign(state = initialState, action) {
  switch (action.type) {
    case LIST_CAMPAIGN:
      return {
        ...state,
        list: action.data,
        metadata: action.meta,
        saved: false,
      };
    case SAVED_CAMPAIGN:
      return {
        ...state,
        saved: true,
      };
    case CAMPAIGN_VALIDATION_RESPONSE:
      return {
        ...state,
        validationResponse: action,
      };
    case RESET_QUERY_CAMPAIGN:
      return {
        ...state,
        validationResponse: {}
      };
    default:
      return state;
  }
}
