import React, { Fragment } from 'react';
import CollapseWrapper from '../../../shared/CollapseWrapper';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '../../../shared/Table/Table';

import SpacingStyles from '../../../../styles/helpers/spacing';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import collapseWrapperStyles from '../../../../styles/components/collapseWrapper';
import { salesColumns, salesMapData } from './SalesTableMetadata';

export const Sales = ({
  classes,
  sales,
  expanded = false,
  toSaleDetail = () => null,
  currentUser
}) => (
  <CollapseWrapper
    expanded={expanded}
    classes={{
      root: classes.root,
      header: classes.panelHeader,
      expandButton: classes.expandButton,
      bodyRoot: classes.collapsePanelBodyRoot
    }}
    header={() => (
      <div className={classes.titleWrapper}>
        <h2 className={classnames(classes.headline8, classes.title)}>
          {'Ventas'}
        </h2>
      </div>
    )}
    body={
      <Fragment>
        <Divider />
        <div className={classes.body}>
          <Grid container spacing={16}>
            {sales && (
              <Table
                columns={salesColumns}
                data={salesMapData(sales, currentUser)}
                loading={false}
                withSelection={false}
                onClickOnRow={props => toSaleDetail(props)}
                classes={{
                  root: classes.tableRoot,
                  tableHeadRowRoot: classes.tableHeadRowRoot,
                  tableCellHead: classes.tableCellHead
                }}
                customContents={classes.contents}
                withToolbar={false}
                withColumnChooser={false}
                withPagination={false}
              />
            )}
          </Grid>
        </div>
      </Fragment>
    }
  />
);

const styles = () => ({
  tableRoot: {
    boxShadow: 'none',
    width: '100%'
  },
  tableCellHead: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1
  }
});

export default withStyles(
  combineStyles(
    collapseWrapperStyles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    styles
  )
)(Sales);
