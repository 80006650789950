import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import routes from './routes';
import UserDetail from '../UserDetail';
import Page404 from '../Page404';
import _get from 'lodash/get';

export class RoutesRenderer extends Component {
  render() {
    const { currentUser, menuFunctions, siteState, siteFlag } = this.props;
    const routeList = routes(currentUser, menuFunctions);

    if (
      currentUser.mobileConfirmedAt &&
      siteState === true &&
      siteFlag === false
    ) {
      return routeList.map((route, index) => {
        return (
          route.isPermitted && (
            <Route
              key={index}
              exact
              path={route.path}
              component={route.component}
            />
          )
        );
      });
    } else if (
      !currentUser.mobileConfirmedAt &&
      siteState === true &&
      siteFlag === false
    ) {
      return (
        <Switch>
          <Route exact path={'/my-profile'} component={UserDetail} />
          <Redirect from="/" to="/my-profile" />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route exact path={'/404'} component={Page404} />
          <Redirect from="/" to="/404" />
        </Switch>
      );
    }
  }
}

export default RoutesRenderer;
