import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import _capitalize from 'lodash/capitalize';

class CategorySelector extends Component {
  constructor(props) {
    super(props);
    const values = {};
    props.categories.forEach(
      category => {
        values[category.id] = {};
        values[category.id].disabled = props.categoryIsDisabled(category);
        values[category.id].checked = (
          values[category.id].disabled || (
            props.selectedCategoryIds.map(
              categoryId => categoryId.toString()
            ).includes(category.id.toString())
          )
        );
      }
    );
    this.state = { values };
  }

  render() {
    const { categories, onChange } = this.props;
    const { values } = this.state;
    return (
      <List>
        {
          categories.map(
            category => {
              return (
                <div key={category.id}>
                  <ListItem>
                    <Checkbox
                      checked={values[category.id].checked}
                      disabled={values[category.id].disabled}
                      onChange={
                        event => {
                          const newValue = event.target.checked;
                          const newValues = {};
                          Object.keys(values).forEach(
                            categoryId => {
                              const value = {...values[categoryId]};
                              if (categoryId === category.id) {
                                value.checked = newValue;
                              }
                              newValues[categoryId] = value;
                            }
                          );
                          const selectedCategoryIds = [];
                          Object.keys(newValues).forEach(
                            categoryId => {
                              const categoryIsSelected = (
                                newValues[categoryId].checked &&
                                !newValues[categoryId].disabled
                              );
                              if (categoryIsSelected){
                                selectedCategoryIds.push(categoryId);
                              }
                            }
                          );
                          onChange({
                            categoryIds: selectedCategoryIds
                          });
                          this.setState({values: newValues});
                        }
                      }
                    />
                    <ListItemText>
                      { _capitalize(category.name) }
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </div>
              );
            }
          )
        }
        <Divider />
      </List>
    );
  }
}

const styles = theme => ({

});

export default withStyles(
  styles, { withTheme: true }
)(CategorySelector);