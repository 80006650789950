import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog from './CustomDialog';
import { SampleNextArrow, SamplePrevArrow } from '../SliderArrow';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

class SliderModal extends React.Component {
  state = {
    announcementShouldOpen: true,
    seenIds: [0]
  };
  _handleClose = () => {
    if (this.props.open) {
      this.props.toggleAnnouncementAction('Open-Announcement', false);
      this.setState({ announcementShouldOpen: false });
      this.state.seenIds.map(id => {
        if (!this.props.list[id].seen) {
          this.props.markAsSeen(this.props.list[id].announcement.id, true);
        }
      });
    }
  };
  render() {
    const {
      open = false,
      loading,
      disabled,
      list,
      classes,
      customWidth
    } = this.props;
    const innerWidthViewPort = window.innerWidth;
    const sliderSettings = {
      dots: true,
      speed: innerWidthViewPort > 600 ? 500 : 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <SamplePrevArrow />,
      nextArrow: <SampleNextArrow />,
      adaptiveHeight: true,
      variableWidth: true,
      centerMode: true,
      beforeChange: (current, next) => {
        if (this.state.seenIds.indexOf(next) === -1) {
          this.setState({ seenIds: [...this.state.seenIds, next] });
        }
      }
    };
    return (
      <CustomDialog
        open={open && this.state.announcementShouldOpen}
        className={classes.sliderPopUp}
        loading={loading}
        disabled={disabled}
        onClose={this._handleClose}
        onConfirm={this._handleClose}
        customWidth={customWidth}
        maxWidth={innerWidthViewPort < 1000 ? '100%' : 1000}
        title={`Anuncios`}
      >
        {list.length == 1 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: list[0].announcement.content
            }}
          />
        ) : (
          <Slider {...sliderSettings}>
            {list.map(function(slide) {
              return (
                <div
                  key={slide.id}
                  style={{ padding: 10 }}
                  dangerouslySetInnerHTML={{
                    __html: slide.announcement.content
                  }}
                />
              );
            })}
          </Slider>
        )}
      </CustomDialog>
    );
  }
}

SliderModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  list: PropTypes.array.isRequired
};

export default SliderModal;
