import { LIST_EXTRA_QUOTA, SAVED_EXTRA_QUOTUM } from '../constants/extra_quotum_constants';

const initialState = {
  list: [],
  saved: false,
};

export function extraQuotum(state = initialState, action) {
  switch (action.type) {
    case LIST_EXTRA_QUOTA:
      return {
        ...state,
        list: action.data,
        metadata: action.meta,
        saved: false,
      };
    case SAVED_EXTRA_QUOTUM:
      return {
        ...state,
        saved: true,
      };
    default:
      return state;
  }
}
