import _pickBy from 'lodash/pickBy';

export const sendOtpSanitizer = values => {
  const {
    mobile,
    entityType,
    entityId,
    customMessage
  } = values;
  const sanitizedFilters = {
    mobile,
    entityType,
    entityId,
    customMessage
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};

export const validateUniqueMobileSanitizer = values => {
  const {
    mobile,
    debtorIdentification,
  } = values;
  const sanitizedFilters = {
    mobile,
    debtorIdentification,
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return params;
};

export const validateOtpSanitizer = values => {
  const {
    mobile,
    otp
  } = values;
  const sanitizedFilters = {
    mobile,
    otp
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};
