const styles = () => ({
  formContainer: {
    background: '#FFF',
    padding: '56px',
    height: '100%'
  },
  loginForm: {
    padding: 0,
    margin: 0,
    width: '100%',
    maxWidth: '528px'
  },
  emailInput: {
    maxWidth: '514px',
    width: 'calc(100% + 14px)',
    marginBottom: '16px'
  },
  passwordInput: {
    maxWidth: '500px',
    marginBottom: '32px'
  },
  loginButton: {
    maxWidth: '528px',
    width: 'calc(100% + 28px)',
    boxShadow: 'none'
  },
  recoverPasswordWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '528px',
    width: 'calc(100% + 28px)'
  },
  recoverPasswordLink: {
    textDecoration: 'none',
    textAlign: 'end',
    color: 'black'
  },
  warningAlert: {
    maxWidth: '500px',
    width: '100%',
    background: 'rgb(255, 244, 229)',
    marginBottom: '16px',
    padding: '6px 16px',
    borderRadius: '4px',
    color: 'rgb(102, 60, 0)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  wrapper: {
    position: 'relative',
    maxWidth: '528px',
    width: '100%',
    marginBottom: '8px'
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

export default styles;
