import _pickBy from 'lodash/pickBy';

export const verifyInvoiceSanitizer = values => {
  const {
    contract,
    invoice1,
    invoice2,
    validateWithOneInvoice,
    isGnp,
  } = values;
  const sanitizedFilters = {
    contractId: contract,
    firstInvoice: invoice1,
    secondInvoice: invoice2,
    validateWithOneInvoice,
    isGnp
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};

export const questionsInvoiceSanitizer = values => {
  const {
    invoice1,
    invoice2,
  } = values;
  const sanitizedFilters = {
    invoice1,
    invoice2,
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};

export const checkInvoiceSanitizer = values => {
  const {
    contract,
    debtorIdentification,
    invoice1,
    invoice2,
    validateWithOneInvoice,
    questions,
    isGnp,
  } = values;
  const sanitizedFilters = {
    contractId: contract,
    validateWithOneInvoice,
    debtorIdentification,
    questions,
    firstInvoice: invoice1,
    secondInvoice: invoice2,
    isGnp,
  };
  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
}